import TableDeleteIcon from "./TableDeleteIcon";
import TableEditIcon from "./TableEditIcon";
import { useDeleteTableRow } from "../useDeleteTableRow";
import TableReorderIcon from "./TableReorderIcon";
import { TemplateTableActionsProps } from "../interfaces";
import * as S from "../styles";

export const TemplateTableActions = ({
  row,
  template,
  onEditRow,
}: TemplateTableActionsProps) => {
  const { id, internal_key } = row;
  const { slug: template_slug } = template || {};

  const { deleteTableRow } = useDeleteTableRow({
    deleteUrl: `/api/v1/templates/${template_slug}/columns/${id}`,
    resourceLabel: internal_key,
  });

  return (
    <S.TableActionsWrapper justifyEnd w100 className="shouldBeVisibleOnHover">
      <S.ActionsContainer pl={26} dflex data-cy="column-action-button">
        <TableEditIcon onClick={() => onEditRow(row)} data-cy="edit-icon" />
        <S.ActionWrapper>
          <TableDeleteIcon onClick={deleteTableRow} />
        </S.ActionWrapper>
        <TableReorderIcon dragAndDropHandler={row.dragAndDropHandler} />
      </S.ActionsContainer>
    </S.TableActionsWrapper>
  );
};

import { useDebounce } from "fuse-shared-ui";
import React from "react";
import styled, { css } from "styled-components";
import { Div, DivCSSProps } from "fuse-shared-ui";

export const HTMLTextearea = styled.textarea`
  outline: none;
  color: #757575;
  font-size: 14px;
  color: #060f3b;
  flex-grow: 1;
  resize: none;
  border: 1px solid #e1e7ed;
  border-radius: 8px;
  padding-top: 42px;
  padding-left: 14px;
  padding-right: 14px;
  height: 90px;
  line-height: 22px;
`;

type TextAreaLabelProps = {
  hasError: boolean;
};
export const TextAreaLabel = styled(Div)<TextAreaLabelProps>`
  color: #212c52;
  font-size: 18px;
  ${(props: any) =>
    props.hasError &&
    css`
      color: #ce2d08;
    `}
`;

export const TextAreaLabelWrapper = styled(Div)`
  width: calc(100% - 24px);
  height: 36px;
  padding-bottom: 5px;
  background-color: white;
  /* border: solid 1px black; */
  position: absolute;
  top: 1px;
  ${(props: any) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `};
`;

type Props = {
  label?: any;
  placeholder?: string;
  value: any;
  onChange: (v: string) => void;
  hasError?: boolean;
  onBlur?: any;
} & DivCSSProps;

const Textarea = ({
  label = null,
  placeholder = "",
  value,
  onChange,
  hasError = false,
  onBlur = null,
  ...props
}: Props) => {
  const { _value, set_Value } = useDebounce(value, onChange);

  return (
    <Div dflex alignCenter w100 pRelative {...props}>
      {label && (
        <TextAreaLabelWrapper alignEnd ml={14}>
          <TextAreaLabel bold hasError={hasError}>
            {label}
          </TextAreaLabel>
        </TextAreaLabelWrapper>
      )}
      <HTMLTextearea
        onBlur={onBlur}
        placeholder={placeholder}
        value={_value}
        onChange={(evt) => {
          set_Value(evt.target.value);
        }}
      />
    </Div>
  );
};

export default Textarea;

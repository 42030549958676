import { Div } from "fuse-shared-ui";
import styled, { css } from "styled-components";
import PlanDetails from "./components/PlanDetails";

export const LogoContainer = styled(Div)`
  ${({ theme }) => theme.css.pRelative};
  ${({ theme }) => theme.css.justifyCenter};
  margin-top: 56px;
`;

export const Wrapper = styled(Div)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.gray10};
`;

export const Header = styled(Div)`
  display: flex;
  flex-direction: column;
`;

export const GoBackButton = styled(Div)`
  ${({ theme }) => theme.css.centered}
  ${({ theme }) => theme.css.label2}
  cursor: pointer;
  height: 100%;
  color: ${({ theme }) => theme.colors.black};
  height: 24px;
  width: 51px;
`;

export const Container = styled(Div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  @media all and (max-width: 1051px) {
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding-top: 20px;
  }
`;

export const StyledPlanDetails = styled(PlanDetails)`
  display: flex;
`;

const paneCss = css`
  padding-top: 7.5%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media all and (max-width: 1051px) {
    width: 100%;
    padding-left: 0;
    padding-bottom: 60px;
    align-items: center;
    ${StyledPlanDetails} {
      width: auto;
    }
    ${GoBackButton} {
      position: absolute;
      top: -10px;
      margin-bottom: 0 !important;
    }
  }
`;

export const LeftPane = styled(Div)`
  ${paneCss}
  padding-left: 160px;
  width: 40%;
`;

export const RightPane = styled(Div)`
  ${paneCss}
  background-color: ${({ theme }) => theme.colors.orange50};
  width: 60%;
  display: flex;
`;

export const fuseLogoInline = (
  <svg
    width="275"
    height="40"
    viewBox="4 0 275 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.95066 35.4438L24.2422 35.4438C24.433 35.4438 24.6124 35.3504 24.7255 35.1922L35.3249 20.3622C35.4792 20.1463 35.4807 19.8526 35.3286 19.6351L24.7839 4.5562L4.70258 34.9612C4.56714 35.1663 4.7098 35.4438 4.95066 35.4438Z"
      fill="#FD395E"
    />
    <path
      d="M4.70258 5.03895L14.5839 20.0001L24.7839 4.55639L4.95066 4.55639C4.7098 4.55639 4.56714 4.83389 4.70258 5.03895Z"
      fill="url(#paint0_linear_2118_45540)"
    />
    <path
      d="M18.7842 4.55638L14.5842 20.0001L24.7842 4.55638L18.7842 4.55638Z"
      fill="#8A0E25"
    />
    <path
      d="M49.1914 28.4696H53.5756V21.6502H59.9162V18.4468H53.5756V14.7338H60.1991V11.5304H49.1914V28.4696Z"
      fill="black"
    />
    <path
      d="M65.2353 28.4696H76.408V25.1206H69.6195V11.5304H65.2353V28.4696Z"
      fill="black"
    />
    <path
      d="M79.8179 28.4696H84.2728L85.5692 24.8537H91.7448L92.9705 28.4696H97.779L91.4384 11.5304H86.0642L79.8179 28.4696ZM90.7077 21.6502H86.5827L88.7041 15.025H88.7513L90.7077 21.6502Z"
      fill="black"
    />
    <path
      d="M103.553 28.4696H107.937V14.8794H111.991V11.5304H99.4987V14.8794H103.553V28.4696Z"
      fill="black"
    />
    <path d="M116.863 28.4696H121.247V11.5304H116.863V28.4696Z" fill="black" />
    <path
      d="M127.234 28.4696H131.618V21.7716H132.537C134.163 21.7716 134.729 22.4996 135.625 25.4118L136.568 28.4696H141.14L139.538 23.8587C138.713 21.7959 138.618 20.5096 137.204 20.1213V20.0728C139.325 19.5632 140.363 18.0585 140.363 15.8016C140.363 12.8894 137.982 11.5304 135.059 11.5304H127.234V28.4696ZM131.618 14.7338H132.773C134.446 14.7338 135.978 15.025 135.978 16.6024C135.978 18.5196 134.187 18.5682 132.773 18.5682H131.618V14.7338Z"
      fill="black"
    />
    <path
      d="M145.289 20C145.289 25.3876 148.164 28.7609 153.539 28.7609C158.913 28.7609 161.788 25.3876 161.788 20C161.788 14.5639 158.96 11.2391 153.539 11.2391C148.117 11.2391 145.289 14.5639 145.289 20ZM149.814 20C149.814 17.0635 150.875 14.5882 153.539 14.5882C156.202 14.5882 157.263 17.0635 157.263 20C157.263 22.7909 156.202 25.4118 153.539 25.4118C150.875 25.4118 149.814 22.7909 149.814 20Z"
      fill="black"
    />
    <path
      d="M167.147 28.4696H171.177L171.036 16.8937H171.083L176.834 28.4696H182.279V11.5304H178.272L178.343 23.0335H178.296L172.733 11.5304H167.147V28.4696Z"
      fill="black"
    />
    <path
      d="M187.92 28.0328C189.382 28.5182 191.291 28.7609 192.823 28.7609C196.476 28.7609 200.059 27.5717 200.059 23.1791C200.059 17.0635 192.281 18.9079 192.281 16.1656C192.281 14.6852 194.119 14.5882 195.227 14.5882C196.476 14.5882 197.749 14.8308 198.881 15.3647L199.164 11.8701C198.056 11.4818 196.5 11.2391 194.921 11.2391C191.621 11.2391 187.755 12.3555 187.755 16.4326C187.755 22.5967 195.534 20.6067 195.534 23.7616C195.534 25.1206 194.214 25.4118 192.823 25.4118C191.008 25.4118 189.429 24.9265 188.297 24.2955L187.92 28.0328Z"
      fill="black"
    />
    <path
      d="M205.49 28.4696H209.874V21.6502H216.215V18.4468H209.874V14.7338H216.498V11.5304H205.49V28.4696Z"
      fill="#FD395E"
    />
    <path
      d="M235.724 11.5304H231.34V21.2862C231.34 23.5189 230.75 25.4118 228.511 25.4118C226.272 25.4118 225.683 23.5189 225.683 21.2862V11.5304H221.298V22.0385C221.298 26.6738 224.221 28.7609 228.511 28.7609C232.801 28.7609 235.724 26.6738 235.724 22.0385V11.5304Z"
      fill="#FD395E"
    />
    <path
      d="M241.043 28.0328C242.505 28.5182 244.414 28.7609 245.946 28.7609C249.6 28.7609 253.182 27.5717 253.182 23.1791C253.182 17.0635 245.404 18.9079 245.404 16.1656C245.404 14.6852 247.243 14.5882 248.35 14.5882C249.6 14.5882 250.872 14.8308 252.004 15.3647L252.287 11.8701C251.179 11.4818 249.623 11.2391 248.044 11.2391C244.744 11.2391 240.878 12.3555 240.878 16.4326C240.878 22.5967 248.657 20.6067 248.657 23.7616C248.657 25.1206 247.337 25.4118 245.946 25.4118C244.131 25.4118 242.552 24.9265 241.421 24.2955L241.043 28.0328Z"
      fill="#FD395E"
    />
    <path
      d="M258.494 28.4696H270.35V25.2662H262.878V21.4318H269.69V18.2284H262.878V14.7338H270.091V11.5304H258.494V28.4696Z"
      fill="#FD395E"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2118_45540"
        x1="2.27084"
        y1="4.55639"
        x2="25.3553"
        y2="12.1422"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.03125" stopColor="#CA405A" stopOpacity="0.958429" />
        <stop offset="1" stopColor="#A2243B" />
      </linearGradient>
    </defs>
  </svg>
);

import { Placement } from "@popperjs/core";
import {
  Autocomplete,
  colors,
  Div,
  TextInput,
  TextInputProps,
} from "fuse-shared-ui";
import { ReactNode } from "react";
import DatePicker from "../DatePicker";
import Checkbox from "../inputs/Checkbox";
import { RadioButton, RadioButtonProps } from "../inputs/RadioButton";
import Textarea from "../inputs/Textarea";
import { FormikField, FormikFieldProps, useFormikField } from "./fieldHelpers";

export type FormikTextInputPropsTypes = {
  name: string;
  onErrors?: (a: boolean, b: any) => void;
  placeholder?: string;
  label?: JSX.Element | string;
  type?: string;
  className?: string;
  withDebounce?: boolean;
  icon?: JSX.Element | null;
  autoFocus?: boolean;
  isDisabled?: boolean;
  labelTooltip?: string;
  inputPadding?: string;
} & FormikFieldProps;

export const FormikTextInput = ({
  onErrors = null,
  name,
  placeholder = "",
  label = "",
  type = "text",
  className = "",
  withDebounce = true,
  autoFocus = false,
  icon = null,
  isDisabled = false,
  labelTooltip,
  inputPadding,
  ...props
}: FormikTextInputPropsTypes): JSX.Element => {
  const { value, setValue, setTouched, hasError } = useFormikField(name);

  if (onErrors) {
    onErrors(hasError, value);
  }

  const onChange = (value) => {
    setTouched(true);
    setValue(value);
  };

  const inputProps = {
    icon,
    hasError,
    withDebounce,
    value,
    placeholder,
    label,
    type,
    autoFocus,
    isDisabled,
    id: name,
    labelTooltip,
    inputPadding,
  };

  const valueIsOnlySpaces = value && value.trim().length === 0;

  return (
    <FormikField
      name={name}
      helperText={valueIsOnlySpaces && "Space added"}
      className={className}
      {...props}
    >
      <TextInput
        onBlur={() => setTouched(true)}
        onChange={onChange}
        {...inputProps}
      />
    </FormikField>
  );
};

type FormikAutocompleteProps = {
  name?: string;
  options?: any;
  placement?: Placement;
  placeholder?: string;
  labelTooltip?: string;
  label?: string;
  className?: string;
  isDisabled?: boolean;
  id?: string;
};

export const FormikAutocomplete = ({
  name,
  options,
  placement = "bottom",
  placeholder = "",
  label = "",
  className = "",
  isDisabled = false,
  labelTooltip,
  id,
}: FormikAutocompleteProps) => {
  const { value, setValue } = useFormikField(name);

  return (
    <FormikField {...{ name, className }}>
      <Autocomplete
        onChange={(v) => setValue(v)}
        isDisabled={isDisabled}
        placement={placement}
        {...{
          value,
          placeholder,
          label,
          labelTooltip,
          options,
          id,
        }}
      />
    </FormikField>
  );
};

export const FormikTextarea = ({
  name,
  placeholder = "",
  label = "",
  type = "text",
  className = "",
}) => {
  const { value, hasError, setValue, setTouched } = useFormikField(name);
  return (
    <FormikField {...{ name, className }}>
      <Textarea
        onBlur={() => setTouched(true)}
        onChange={(v) => setValue(v)}
        {...{
          hasError,
          value,
          placeholder,
          label,
          type,
        }}
      />
    </FormikField>
  );
};

export const FormikCheckbox = ({
  name,
  label = null,
  className = "",
  ...props
}) => {
  const { value, setValue } = useFormikField(name);
  const { icon } = props;

  const toggle = () => setValue(!value);
  return (
    <FormikField {...{ name, className }}>
      <Div dInline onClick={toggle}>
        <Div dflex clickable h={32} alignCenter>
          <Div
            tabIndex={0}
            onKeyDown={({ code }) => {
              if (code === "Space" || code === "Enter") toggle();
            }}
          >
            <Checkbox isChecked={value} icon={icon} />
          </Div>
          <Div wbody3 ml={8} alignCenter c={colors.text}>
            {label}
          </Div>
        </Div>
      </Div>
    </FormikField>
  );
};

export const FormikDatePicker = ({
  name,
  className = "",
  ...inputProps
}: TextInputProps) => {
  const { value, setValue, setTouched } = useFormikField(name);

  const onChange = (value) => {
    setTouched(true);
    setValue(value);
  };

  return (
    <FormikField name={name} className={className}>
      <DatePicker
        onBlur={() => setTouched(true)}
        onChange={onChange}
        value={value}
        {...inputProps}
      />
    </FormikField>
  );
};

interface FormikRadioButtonProps extends Omit<RadioButtonProps, "onChange"> {
  name: string;
  label?: ReactNode | null;
  className?: string;
  description?: ReactNode | null;
  value: string | number | readonly string[] | undefined;
}

export const FormikRadioButton: React.FC<FormikRadioButtonProps> = ({
  name,
  label = null,
  className = "",
  description = null,
  value,
  ...props
}) => {
  const { setValue } = useFormikField(name);

  return (
    <FormikField {...{ name, className }}>
      <RadioButton
        name={name}
        label={label}
        description={description}
        onChange={(v) => setValue(v)}
        value={value}
        {...props}
      />
    </FormikField>
  );
};

import {
  Div,
  ModalScrollArea,
  TallModal,
  TallModalLayout,
} from "fuse-shared-ui";
import styled from "styled-components";

const ErrorDetailsContainer = styled.pre`
  background: #313131;
  border-radius: 12px;
  color: #ffffff;
  margin: 15px 25px 24px;
  max-width: 554px;
  padding: 24px;
  white-space: pre-wrap;
  word-wrap: break-word;

  ${({ theme }) => theme.textStyles.body3};
`;

export const formatContent = (content: React.ReactNode | string) => {
  if (!content) return "No error message was provided.";

  return content
    .toString()
    .split("\n")
    .map((line: string, index: number) => <Div key={index}>{line}</Div>);
};

type ErrorDetailsModalProps = {
  children: React.ReactNode;
  onClose: () => void;
};

export const ErrorDetailsModal = ({
  children,
  onClose,
}: ErrorDetailsModalProps) => {
  return (
    <TallModal isOpen onClose={onClose}>
      <TallModalLayout>
        <ModalScrollArea withShadow={false}>
          <Div
            data-cy="error-detail-pop-up-heading"
            h5
            mt={48}
            dflex
            justifyCenter
          >
            Webhook error details
          </Div>

          <ErrorDetailsContainer data-cy="error-message">
            {formatContent(children)}
          </ErrorDetailsContainer>
        </ModalScrollArea>
      </TallModalLayout>
    </TallModal>
  );
};

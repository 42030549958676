import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { areEqual, FixedSizeGrid } from "react-window";
import { getTableHeight } from "../../..";
import { TableActions } from "../../../../../common/Table/TableActions";
import { TableViewColumn } from "../../../../../common/Table/TableView/types";
import { Div, DivCSSProps } from "../../../../../styled/utils";
import { Record } from "fuse-importer";
import useWindowDimensions from "../../../../../utils/hooks/useWindowSize";
import { useImporterContext } from "../../../../contexts/ImporterContextProvider";
import {
  spreadsheetHeaderHeightPx,
  spreadsheetRowHeightPx,
} from "../../common";
import { useDataSetContext } from "../../DataSetContextProvider";
import { useFilteredDataContext } from "../../FilteredDataContextProvider";
import { useSpreadsheetContext } from "../../SpreadsheetContextProvider";
import * as S from "../../styles";
import { useValidationContext } from "../../ValidationContextProvider";
import { FieldHeader } from "../FieldHeader";
import GlobalStyle from "../GlobalStyle";
import { inputTypes } from "../inputs";
import { SpreadsheetRowCheckboxes } from "../SpreadsheetRowCheckboxes";
import { CellContextProvider } from "./CellContextProvider";

const getItemKey = (record: Record) => record._meta.id;

const SpreadsheetData = (props: DivCSSProps) => {
  const {
    fields,
    filteredDataIds,
    isReadOnly,
    options,
    selectedRows,
    setSelectedRows,
    checkboxColumnWidth,
  } = useSpreadsheetContext();

  const { isFiltering, handleSortByFieldErrors } = useFilteredDataContext();
  const { validationPercentage } = useValidationContext();

  const { dataSet } = useDataSetContext();
  const { currentStepIndex } = useImporterContext();
  const { isOnSmallScreens } = useWindowDimensions();

  const canShowCheckboxOnCheckboxRowHover = currentStepIndex === 2;
  const rowNumbersGridRef = useRef<any>();
  const rowCheckboxesGridRef = useRef<any>();

  useEffect(() => {
    if (filteredDataIds.length > 0 && options.withSingleRowSelect)
      setSelectedRows([filteredDataIds?.[0]]);
  }, [options.withSingleRowSelect, filteredDataIds]);

  const rowWidthScalingFactor = (() => {
    // Spreadsheet will display 6 columns on the viewport and a small portion of the
    // 7th column in the right side to let the user know they can scroll horizontally
    let visibleColumnsOnTableViewport = 6;
    // by multiplying by horizontalScrollVisibilityRatio the final rowWidth for the spreadsheet
    // will be reduced in order to partially display the last column on the right side
    // 0.95 was chosen because it's the ratio that looks better on the UI on desktop
    // 0.9 was chosen because it's the ratio that looks better on the UI on mobile
    let horizontalScrollVisibilityRatio = 0.95;

    if (isOnSmallScreens) {
      // When the screen is too small the Spreadsheet will display 3 columns on the viewport
      // and a small portion of the  4th column in the right side to let the user know they can scroll horizontally
      visibleColumnsOnTableViewport = 3;
      horizontalScrollVisibilityRatio = 0.9;
    }

    const res = fields.length / visibleColumnsOnTableViewport;
    if (res <= 1) return 1;

    /*
    multiply the rowWidthScalingFactor by 0.95/0.9 to let the user know they can scroll
    horizontally (will display partial column on the right)
    */
    return res * horizontalScrollVisibilityRatio;
  })();

  const columns: TableViewColumn[] = useMemo(() => {
    return fields.map((field) => ({
      Header: () => <FieldHeader field={field} />,
      Content: (record) => {
        const Input = inputTypes[field.type];
        const { rowIndex, colIndex, columnWidth, ...recordProp } = record;

        return (
          <CellContextProvider rowIndex={rowIndex} colIndex={colIndex}>
            <Input
              id={`${field.name}-${rowIndex}`}
              fieldType={field.type}
              name={field.name}
              options={field.options}
              record={recordProp}
              columnWidth={columnWidth}
            />
          </CellContextProvider>
        );
      },
    }));
  }, [fields, handleSortByFieldErrors, dataSet.current]);

  const onTableScroll = useCallback((scrollTop) => {
    if (rowNumbersGridRef.current) {
      const grid = rowNumbersGridRef.current as FixedSizeGrid;
      grid.scrollTo({ scrollTop });
    }

    if (rowCheckboxesGridRef.current) {
      const grid = rowCheckboxesGridRef.current as FixedSizeGrid;
      grid.scrollTo({ scrollTop });
    }
  }, []);

  const dataLength = filteredDataIds.length;
  const spreadsheetHeight = useMemo(
    () =>
      getTableHeight({
        headerHeight: spreadsheetHeaderHeightPx,
        rowHeight: spreadsheetRowHeightPx,
        dataCount: dataLength,
        minimumTableHeight: 450,
        maxVisibleRows: 12,
      }),
    [dataLength]
  );

  const showValidationProgress = !isReadOnly && validationPercentage < 100;
  const isReviewStep = currentStepIndex === 2;

  return (
    <S.SpreadSheetData h100 flexColumn className={props.className}>
      <GlobalStyle />
      <S.SpreadSheetContainer flexColumn w100 h100>
        <Div w100 justifyCenter>
          {isReviewStep && <TableActions />}
        </Div>

        <S.SpreadSheetWrapper
          canShowCheckboxOnHover={
            !showValidationProgress && canShowCheckboxOnCheckboxRowHover
          }
          shouldShowOnlyCheckbox={
            (!showValidationProgress && selectedRows.length > 0) ||
            currentStepIndex === 0
          }
          dflex
          h100
        >
          {options?.showCheckboxColumn && (
            <SpreadsheetRowCheckboxes
              gridRef={rowCheckboxesGridRef}
              height={spreadsheetHeight}
              isLoadingData={showValidationProgress}
            />
          )}
          <S.TableView
            rowCount={filteredDataIds.length}
            tableHeadersHeight={spreadsheetHeaderHeightPx}
            rowHeight={spreadsheetRowHeightPx}
            h={null}
            rowWidthScalingFactor={rowWidthScalingFactor}
            dataRefIds={{
              ref: dataSet,
              ids: filteredDataIds,
            }}
            columns={columns}
            onTableScroll={onTableScroll}
            getItemKey={getItemKey}
            checkboxColumnWidth={checkboxColumnWidth}
            isFiltering={isFiltering}
            shouldHideTable={dataLength === 0}
          />
        </S.SpreadSheetWrapper>
      </S.SpreadSheetContainer>
    </S.SpreadSheetData>
  );
};

export default React.memo(SpreadsheetData, areEqual);

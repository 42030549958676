import { Div } from "../../../../../styled";
import { Button, Modal, addToast } from "../../../../../common";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../../../common/inputs/TextInput";
import { Checkbox as CheckboxBase } from "../../../../../common";
import { useEffect, useRef, useState } from "react";
import { useDataSetManipulation } from "../../SpreadsheetContextProvider/useDataSetManipulation";
import { useFilteredDataContext } from "../../FilteredDataContextProvider";
import styled, { css } from "styled-components";

const Checkbox = styled(CheckboxBase)(
  ({ theme: { colors } }) => css`
    ${(props: any) =>
      (props.isChecked || props.isIndeterminate) &&
      css`
        border: 1px solid white;
        background-color: ${colors.black};
      `};
  `
);

type FindAndReplaceModalProps = {
  initialText: string;
  onClose: () => void;
};

export const FindAndReplaceModal = ({
  initialText,
  onClose,
}: FindAndReplaceModalProps) => {
  const { t } = useTranslation("general");
  const [searchText, setSearchText] = useState(initialText);
  const [replaceText, setReplaceText] = useState("");
  const { findAndReplaceInSpreadsheet } = useDataSetManipulation();
  const [useRegex, setUseRegex] = useState(false);
  const [matchCase, setMatchCase] = useState(true);
  const { setFilteredInputValue } = useFilteredDataContext();
  const findRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!findRef.current) return;
    findRef.current.focus();
  }, [findRef.current]);

  const findAndReplace = async () => {
    const records = await findAndReplaceInSpreadsheet(
      searchText,
      replaceText,
      matchCase,
      useRegex
    );
    if (records.length > 0) {
      addToast(
        t("find_and_replace_modal.records_replaced", {
          length: records.length,
        }),
        "success"
      );
      onClose();
      setFilteredInputValue("");
    } else {
      addToast(t("find_and_replace_modal.no_records_found"), "error");
    }
  };

  return (
    <Modal isOpen={true} onClose={() => onClose()}>
      <Div p="17px 20px" w={500}>
        <Div data-cy="find-replace-title" textAlignCenter h5>
          {t("find_and_replace_modal.title")}
        </Div>
        <Div
          data-cy="find-replace-description"
          mt={0}
          mb={20}
          textAlignCenter
          body1
        >
          {t("find_and_replace_modal.description")}
        </Div>
        <Div mb={20} w={500} textAlignCenter body1>
          <TextInput
            setInputRef={findRef}
            data-cy="find-input"
            mb={20}
            name={t("find_and_replace_modal.find")}
            label={t("find_and_replace_modal.find")}
            value={searchText}
            onChange={(value) => setSearchText(value)}
          />
          <TextInput
            data-cy="replace-input"
            label={t("find_and_replace_modal.replace_with")}
            value={replaceText}
            onChange={(value) => setReplaceText(value)}
          />
        </Div>
        <Div mb={12}>
          <Checkbox
            data-cy="use-regex"
            label={t("find_and_replace_modal.use_regex")}
            isChecked={useRegex}
            onChange={(v) => setUseRegex(v)}
          />
        </Div>
        <Div mb={12}>
          <Checkbox
            data-cy="case-sensitive"
            label={t("find_and_replace_modal.use_case_sensitive")}
            isChecked={matchCase}
            onChange={(v) => setMatchCase(v)}
          />
        </Div>
        <Div dflex mt={40} justifyEnd>
          <Button variant="secondary" onClick={() => onClose()} w={160} mr={16}>
            {t("find_and_replace_modal.cancel")}
          </Button>
          <Button
            data-cy="find-replace-submit"
            variant="primary"
            onClick={() => findAndReplace()}
          >
            {t("find_and_replace_modal.submit")}
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};

import React, {
  useCallback,
  useRef,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { AnalyticsBrowser, Context } from "@segment/analytics-next";
import { useHistory } from "react-router";
import { set, get } from "lodash";

const segmentWriteKey = process.env.REACT_APP_SEGMENT_WRITE_KEY;
const isSegmentEnabled = !!segmentWriteKey;
const analytics = isSegmentEnabled
  ? AnalyticsBrowser.load({ writeKey: segmentWriteKey })
  : null;

export let segmentProvidedValue: SegmentProviderValue;

export type SegmentProviderValue = {
  identify(userId: any, properties: { [key: string]: any });
  track(event: string, properties: { [key: string]: any });
};

export const SegmentContext = React.createContext<SegmentProviderValue>(
  undefined
);

export const SegmentContextProvider: React.FC = ({ children }) => {
  const identifyIdRef = useRef(null);
  const history = useHistory();

  const safeCallSegment = (
    callback: (analytics: AnalyticsBrowser) => Promise<Context>
  ) => {
    try {
      if (analytics) callback(analytics).then();
    } catch (e) {
      console.error(e);
    }
  };

  const pushToDataLayer = (event: any) => {
    try {
      if (window && window["dataLayer"]) {
        const dataLayer = window["dataLayer"] as any[];
        dataLayer.push(event);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const identify = useCallback(
    (userId: any, properties: { [key: string]: any }) => {
      const isUserAlreadyIdentified = identifyIdRef.current?.userId === userId;
      const canIdentify = userId && !isUserAlreadyIdentified;
      if (canIdentify) {
        safeCallSegment((analytics) => analytics.identify(userId, properties));
        identifyIdRef.current = {
          userId,
          ...properties,
        };
      }
    },
    []
  );

  const track = useCallback(
    (event: string, properties: { [key: string]: any }) => {
      safeCallSegment((analytics) => analytics.track(event, properties));
      pushToDataLayer({
        event,
        ...properties,
      });
    },
    []
  );

  useEffect(() => {
    safeCallSegment((segment) => {
      segment.addSourceMiddleware(({ next, payload }) => {
        set(payload, "obj.context.traits", {
          ...(get(payload, "obj.context.traits") || {}),
          ...(identifyIdRef.current || {}),
        });
        next(payload);
      });
      return segment.page();
    });
    return history.listen(() => {
      safeCallSegment((segment) => segment.page());
    });
  }, []);

  const value = useMemo(() => {
    segmentProvidedValue = { identify, track };
    return segmentProvidedValue;
  }, [identify, track]);

  return (
    <SegmentContext.Provider value={value}>{children}</SegmentContext.Provider>
  );
};

export const useSegment = () => {
  return useContext(SegmentContext);
};

export default SegmentContextProvider;

import { DownloadIcon } from "./icons";
import { TooltipProvider } from "../../../common/TooltipProvider";
import { ActionButton } from "./TableActions.styles";
import { useTranslation } from "react-i18next";
import { useExportDataToFile } from "../../../Importer/hooks/useExportDataToFile";
import { useDataSetContext } from "../../../Importer/common/Spreadsheet/DataSetContextProvider";

export const ExportData = ({ testId = "ta-export-data" }) => {
  const { onExport } = useExportDataToFile();
  const { dataSet } = useDataSetContext();
  const { t } = useTranslation("review");

  return (
    <TooltipProvider
      tooltip={t("table_actions.export_your_data.tooltip")}
      placement="right"
      flip={false}
      centered
      data-testid={testId}
    >
      <ActionButton onClick={() => onExport(dataSet?.current, "csv")}>
        <DownloadIcon />
      </ActionButton>
    </TooltipProvider>
  );
};

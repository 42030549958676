import Form from "components/common/form/Form";
import {
  yupRequired,
  yupRequiredUrl,
} from "components/common/form/validations";
import * as yup from "yup";
import DragAndDropForm from "./components/DragAndDropForm";
import TemplateForm, { templateNameField } from "./components/TemplateForm";
import TemplateFormButtons from "./components/TemplateFormButtons";
import {
  UseTemplateFormArgs,
  useCreateColumnsFromFile,
  useTemplateForm,
} from "./hooks";
import * as S from "./styles";

type ModalProps = {
  isOpen: boolean;
  templateId?: string;
  onSubmit?: (data: any) => void;
} & Omit<UseTemplateFormArgs, "onSubmit">;

const fields = [
  templateNameField,
  {
    label: "Hosting",
    name: "hosting_mode",
    initialValue: "self_hosted",
    validation: yupRequired,
  },
  {
    label: "Persistence",
    name: "persistence",
    initialValue: true,
  },
  {
    label: "Webhook URL",
    name: "webhook_url",
    initialValue: null,
    validation: yup
      .string()
      .when("hosting_mode", {
        is: "fuse_hosted",
        then: yupRequiredUrl,
      })
      .nullable(),
  },
];

const CreateTemplateFormModal = ({
  isOpen,
  hideModal,
  onSubmit,
  templateId,
}: ModalProps) => {
  const {
    onClose,
    onCancel,
    onFormSubmit,
    isLoading,
    activeTabIndex,
    isManualCreation,
    setIsManualCreation,
  } = useTemplateForm({
    hideModal,
    onSubmit,
  });

  const { onCreateColumns, isCreatingColumns } = useCreateColumnsFromFile({
    hideModal,
  });

  const handleOnComplete = async (data: any, fileName: string) => {
    let templateSlug = templateId;

    // if templateId is not present, it means that we are creating a new template
    if (!templateId) {
      const cleanedFileName = fileName.replace(/\.[^/.]+$/, "");

      const createTemplatePayload = {
        name: cleanedFileName,
        hosting_mode: "self_hosted",
        webhook_url: null,
        persistence: true,
      };

      const createdTemplate = await onFormSubmit(createTemplatePayload, false);
      templateSlug = createdTemplate?.slug;
    }

    if (templateSlug) {
      await onCreateColumns(data, templateSlug);

      if (!isCreatingColumns && onSubmit) {
        onSubmit(templateSlug);
      }
    }
  };

  return (
    <S.Modal isOpen={isOpen} onClose={onClose} minW={"0px"}>
      <Form fields={fields} onSubmit={onFormSubmit}>
        <S.ModalContent maxw={isCreatingColumns ? 630 : 425} mr={0}>
          {isManualCreation ? (
            <>
              <TemplateForm />
              <TemplateFormButtons
                activeIndex={activeTabIndex}
                isLoading={isLoading}
                onCancel={onCancel}
              />
            </>
          ) : (
            <DragAndDropForm
              handleOnComplete={handleOnComplete}
              onSelectManual={() => setIsManualCreation(true)}
              isLoading={isCreatingColumns}
              shouldShowManualCreationButton={!templateId}
            />
          )}
        </S.ModalContent>
      </Form>
    </S.Modal>
  );
};

export default CreateTemplateFormModal;

import { Div } from "fuse-shared-ui";
import styled from "styled-components";
import { NavigationItem } from "../NavigationItem";
import { DesktopLogo } from "../icons";
import { useNavigation } from "./useNavigation";

const LogoContainer = styled(Div)`
  padding: 30px 0;
`;

export const DesktopUI = () => {
  const { navItems } = useNavigation();

  return (
    <Div dflex flexColumn alignCenter>
      <LogoContainer>
        <DesktopLogo />
      </LogoContainer>

      <Div dflex flexColumn>
        {navItems.map((item) => (
          <NavigationItem
            data-cy={item?.dataCy}
            label={item.label}
            path={item.path}
            icon={item.icon}
            onClick={item.onClick}
            key={item.label}
          />
        ))}
      </Div>
    </Div>
  );
};

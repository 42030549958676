import { Button } from "fuse-shared-ui";
import { handleIntercom } from "../common/utils/handleIntercom";

const CancellationSection = () => {
  const onClick = () => {
    handleIntercom(
      "showNewMessage",
      "Hello! I am writing to request the cancellation of my subscription."
    );
  };

  return (
    <Button variant="tertiary" onClick={onClick}>
      Cancel subscription
    </Button>
  );
};

export default CancellationSection;

import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { uniqueId, omit } from "lodash";

export type HideModalFn = () => void;
export type HideModalRenderProps = { hideModal: HideModalFn; isOpen: boolean };
export type RenderModalFn = (state: HideModalRenderProps) => React.ReactNode;
export type ModalsState = {
  [key: string]: { render: RenderModalFn; hide: HideModalFn; isOpen: boolean };
};
export type State = {
  modals: ModalsState;
  showModal: (render: RenderModalFn) => { key: string; hideModal: HideModalFn };
  hideModal: (key: string) => void;
};

const AppModalContext = createContext<State>({} as State);
const hideModalAnimationDelay = 0;

export const AppModalContextProvider = ({ children }) => {
  const [modals, setModals] = useState<ModalsState>({});

  const hideModal = useCallback((modalId) => {
    if (hideModalAnimationDelay > 0) {
      setModals((modals) => ({
        ...modals,
        [modalId]: {
          ...modals[modalId],
          isOpen: false,
        },
      }));
    }
    setTimeout(() => {
      setModals((modals) => ({ ...omit(modals, [modalId]) }));
    }, hideModalAnimationDelay);
  }, []);

  const showModal = useCallback((render: RenderModalFn) => {
    const uniqueModalId = uniqueId("modal-");
    const hide = () => hideModal(uniqueModalId);
    setModals((modals) => ({
      ...modals,
      [uniqueModalId]: {
        render,
        hide,
        isOpen: true,
      },
    }));
    return { key: uniqueModalId, hideModal: hide };
  }, []);

  const value = useMemo(() => {
    return {
      modals,
      showModal,
      hideModal,
    };
  }, []);

  function renderModal(modalKey: string) {
    const { render, hide, isOpen } = modals[modalKey];
    return (
      <React.Fragment key={modalKey}>
        {render({ hideModal: hide, isOpen })}
      </React.Fragment>
    );
  }

  return (
    <AppModalContext.Provider value={value}>
      {children}
      {Object.keys(modals).map(renderModal)}
    </AppModalContext.Provider>
  );
};

export const useAppModalContext = () => useContext(AppModalContext);

import { BlankState } from "components/common/Table/BlankState";
import {
  Div,
  P,
  Table,
  TableDataProvider,
  chevronLeftIcon,
  colors,
  useTableContext,
} from "fuse-shared-ui";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Header, MainContent } from "./components";
import { LogsTableProvider } from "./contexts/LogsTableContext";
import { ImportsIcon, TemplatesIcon } from "./icons";
import { TestId } from "./interfaces";
import { InitialValues, useQueryLogs } from "./useQueryLogs";

const ContentContainer = styled(Div)`
  padding: 32px;

  @media all and (${({ theme }) => theme.media.xs}) {
    padding: 21px 12px;
  }
`;

const WebhookLogsRoutes = ({ testId }: TestId) => {
  const history = useHistory();

  const storedState = history?.location?.state as InitialValues;

  const {
    templates,
    imports,
    queryState,
    handleFilterTemplate,
    handleFilterImport,
  } = useQueryLogs(storedState);

  const handleGoBack = () => {
    history.goBack();
  };

  const filters = [
    {
      icon: <TemplatesIcon />,
      label: "All Importers",
      data: templates,
      keyToRender: "name",
      onFilter: handleFilterTemplate,
      activeFilter: queryState.selectedTemplate,
      filterType: "importers",
      groupLabel: "Importers",
    },
    {
      icon: <ImportsIcon />,
      label: "All File Import Slugs",
      data: imports,
      keyToRender: "slug",
      onFilter: handleFilterImport,
      activeFilter: queryState.selectedImport,
      filterType: "file imports",
      groupLabel: "File Import Slugs",
    },
  ];

  return (
    <ContentContainer bg={colors.white} h100>
      <Header handleGoBack={handleGoBack} filters={filters} />

      <Div mt={24} h100>
        <TableDataProvider
          defaultPageSize={20}
          dataUrl={`/api/v1/organizations/webhooks/logs${queryState.query}`}
        >
          <LogsTableProvider>
            <MainContent testId={testId} />
          </LogsTableProvider>
        </TableDataProvider>
      </Div>
    </ContentContainer>
  );
};

export default WebhookLogsRoutes;

import { TooltipProvider } from "fuse-shared-ui";
import { Div } from "fuse-shared-ui";
import { reorderIcon } from "../../../../../TeamMembers/common/icons";
import * as S from "../styles";

const TableReorderIcon = ({ dragAndDropHandler }) => {
  return (
    <S.ActionWrapper {...dragAndDropHandler}>
      <Div h={40}>
        <TooltipProvider
          tooltip="Reorder"
          placement="left"
          flip={false}
          centered
        >
          <S.ReorderIcon centered>{reorderIcon}</S.ReorderIcon>
        </TooltipProvider>
      </Div>
    </S.ActionWrapper>
  );
};

export default TableReorderIcon;

export const EmptyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={88}
    height={64}
    fill="none"
    {...props}
  >
    <rect
      width={50.156}
      height={64}
      x={18.601}
      fill="url(#a)"
      rx={4}
      style={{
        mixBlendMode: "darken",
      }}
    />
    <rect
      width={48.695}
      height={10}
      y={16}
      fill="url(#b)"
      rx={2}
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <rect
      width={42.315}
      height={10}
      x={45.685}
      y={38}
      fill="url(#c)"
      rx={2}
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <defs>
      <linearGradient
        id="a"
        x1={43.679}
        x2={20.626}
        y1={0}
        y2={84.968}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6BEEFF" />
        <stop offset={1} stopColor="#05C2B7" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={12.135}
        x2={18.436}
        y1={25.492}
        y2={6.926}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0A617" />
        <stop offset={1} stopColor="#FEE18F" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={56.231}
        x2={63.247}
        y1={47.492}
        y2={29.528}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0A617" />
        <stop offset={1} stopColor="#FEE18F" />
      </linearGradient>
    </defs>
  </svg>
);

import { useMemo, useState } from "react";
import { ActionPlus } from "../icons";
import { Div } from "../../../../styled";
import styled from "styled-components";
import { SelectMenu } from "../../../../common/inputs";
import { MenuItem, addToast } from "../../../index";
import { Record } from "fuse-importer";
import { useImporterContext } from "../../../../Importer/contexts/ImporterContextProvider";
import { useTranslation } from "react-i18next";
import { useBatchProcessing } from "../../../../Importer/common/Spreadsheet/SpreadsheetContextProvider/useBatchProcessing";
import { useDataSetContext } from "../../../../Importer/common/Spreadsheet/DataSetContextProvider";
import { useValidationContext } from "../../../../Importer/common/Spreadsheet/ValidationContextProvider";
import { useSpreadsheetContext } from "../../../../Importer";

const Container = styled(Div)`
  cursor: pointer;
`;

const StyledSelectMenu = styled(SelectMenu)`
  .selectMenu[data-popper-placement^="bottom"] {
    width: 320px !important;
    transform: translate(-199px, 21px);
  }
`;

export const CustomActions = () => {
  const { customActions } = useImporterContext();
  const { selectedRows } = useSpreadsheetContext();
  const { getRecord } = useDataSetContext();
  const { updateRecordAndRevalidate } = useValidationContext();
  const [referenceElement, setReferenceElement] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation("review");

  const filteredOptions = useMemo(() => {
    return customActions.map((action) => action.name);
  }, [customActions]);

  const processBatch = async (batch, action) => {
    const promises = [];

    for (let currentRecord of batch) {
      if (action.actionType === "record") {
        // Wrapping the handler call with Promise.resolve() to ensure it's always treated as a promise.
        let handlerPromise = Promise.resolve().then(() =>
          action.handler(currentRecord)
        );

        let handledPromise = handlerPromise.catch((error) => {
          addToast(
            t("table_actions.custom_actions.error_toast", {
              actionName: action.name,
            }),
            "error"
          );
          // Return null to maintain promise chain even in case of error
          return null;
        });

        // Push the handled promise to the promises array
        promises.push(handledPromise);
      }
    }

    const results = await Promise.all(promises);
    await updateRecordAndRevalidate(results);
  };

  const handleCustomAction = async (actionKey: string) => {
    const recordsToRunAction = selectedRows.map((recordId) => {
      return getRecord(recordId);
    });

    const action = customActions.find((action) => action.name === actionKey);

    if (!action || Object.keys(action).length === 0) {
      addToast(t("table_actions.custom_actions.general_error_toast"), "error");
      return;
    }

    const { processRecordsInBatches } = useBatchProcessing();
    await processRecordsInBatches(
      recordsToRunAction,
      async (batch: Record[]) => {
        await processBatch(batch, action);
      }
    );

    const toastMessage = t("table_actions.custom_actions.success_toast", {
      actionName: action.name,
    });
    addToast(toastMessage, "success");
  };

  const onSelect = (value: string) => {
    setIsMenuOpen(false);
    handleCustomAction(value);
  };

  const handleMenuVisibility = () => {
    setIsMenuOpen((oldValue) => !oldValue);
  };

  if (!filteredOptions.length || !selectedRows.length) return null;

  return (
    <>
      <Container
        onClick={handleMenuVisibility}
        dflex
        alignCenter
        minw={152}
        w100
        pl={20}
        pr={20}
        pt={6}
        pb={6}
        ref={setReferenceElement}
        data-testid="ta-custom-actions"
      >
        <ActionPlus />

        <Div pl={4} buttonTextSmall>
          {t("table_actions.custom_actions.label")}
        </Div>
      </Container>

      {isMenuOpen && (
        <StyledSelectMenu
          referenceElement={referenceElement}
          placement="bottom"
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          selectMenuClassName="selectMenu"
        >
          {filteredOptions.map((option, index) => (
            <Div key={index} data-test-id="autocomplete-menu-item">
              <MenuItem
                id={`custom-action-option-${option}`}
                onClick={() => onSelect(option)}
              >
                {option}
              </MenuItem>
            </Div>
          ))}
        </StyledSelectMenu>
      )}
    </>
  );
};

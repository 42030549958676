import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { useReviewContext } from "../../../Importer";
import { useDataSetContext } from "../../../Importer/common/Spreadsheet/DataSetContextProvider";
import { useDuplicateDataContext } from "../../../Importer/common/Spreadsheet/DuplicateDataContextProvider";
import { useFilteredDataContext } from "../../../Importer/common/Spreadsheet/FilteredDataContextProvider";
import { useValidationContext } from "../../../Importer/common/Spreadsheet/ValidationContextProvider";
import { Div } from "../../../styled";
import { useEventManagerContext } from "../../EventManagerContextProvider";

type FilterContainerProps = {
  isSelected?: boolean;
};
const FilterContainer = styled(Div)<FilterContainerProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) => p.theme.css.body3};
  ${(p) =>
    p.isSelected &&
    css`
      background: ${(p) => p.theme.colors.black};
      color: ${(p) => p.theme.colors.white};
      border-radius: 4px;
    `}
`;

const Container = styled(Div)`
  background-color: ${(p) => p.theme.colors.backgroundPrimary};
  border-radius: 4px;
  display: flex;
`;

const Wrapper = styled(Div)<{ isDisabled?: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;

      > div:first-child {
        opacity: 0.7;
        pointer-events: none;
      }
    `}
`;

const FilteredDataCount = styled(Div)`
  background: ${(p) => p.theme.colors.highlight01};
  color: ${(p) => p.theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  margin-left: -3px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

type FilterProps = {
  label: string;
  count?: number;
  isSelected?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
};
const Filter = ({
  label,
  count,
  isSelected,
  onClick,
  isDisabled,
}: FilterProps) => {
  const { filteredInputValue, filteredDataIds } = useFilteredDataContext();

  const labelToId = (label: string) => {
    return label.toLowerCase().replace(/\s/g, "_") + "_filter";
  };

  return (
    <Div
      disabledWhen={isDisabled}
      dflex
      clickable
      onClick={() => !isDisabled && onClick()}
      data-test-id={labelToId(label)}
    >
      <FilterContainer isSelected={isSelected}>
        {label}
        {count >= 0 && <>&nbsp;({count.toLocaleString()})</>}
      </FilterContainer>
    </Div>
  );
};

export const RowFilters = () => {
  const {
    spreadsheetFilter,
    filterRecordsByRowType,
  } = useFilteredDataContext();
  const {
    duplicateRecordIds,
    calculateDuplicateRecords,
  } = useDuplicateDataContext();
  const { invalidRowsCountRef } = useValidationContext();
  const { hasTransformedRecords } = useReviewContext();

  const [invalidRowsCount, setInvalidRowsCount] = useState(
    invalidRowsCountRef.current
  );
  const { dataSetLength, emptyRecordIds } = useDataSetContext();
  const { on, off, EVENTS } = useEventManagerContext();
  const { t } = useTranslation("review");
  const all_rows = t("table_actions.filters_by_rows.all_rows");
  const invalid_rows = t("table_actions.filters_by_rows.invalid_rows");
  const duplicates = t("table_actions.filters_by_rows.duplicates");
  const [recordsWithValuesCount, setRecordsWithValuesCount] = useState(0);

  const calculateRecordsWithValuesCount = () => {
    setRecordsWithValuesCount(
      dataSetLength.current - emptyRecordIds.current.size
    );
  };

  useEffect(() => {
    const listener = () => {
      setInvalidRowsCount(invalidRowsCountRef.current);
    };

    on(EVENTS.UPDATE_TOTAL_ERROR_COUNT, listener);

    return () => {
      off(EVENTS.UPDATE_TOTAL_ERROR_COUNT, listener);
    };
  }, []);

  useEffect(() => {
    calculateRecordsWithValuesCount();

    on(EVENTS.UPDATE_TOTAL_ROW_COUNT, calculateRecordsWithValuesCount);

    return () => {
      off(EVENTS.UPDATE_TOTAL_ROW_COUNT, calculateRecordsWithValuesCount);
    };
  }, []);

  const onDuplicatesSelected = () => {
    calculateDuplicateRecords();
    filterRecordsByRowType("duplicates");
  };

  const onAllSelected = () => {
    if (spreadsheetFilter.byRow === "duplicates") {
      calculateDuplicateRecords();
    }

    filterRecordsByRowType("all");
  };

  const onInvalidSelected = () => {
    if (spreadsheetFilter.byRow === "duplicates") {
      calculateDuplicateRecords();
    }

    filterRecordsByRowType("invalid");
  };

  return (
    <Wrapper isDisabled={hasTransformedRecords}>
      <Container>
        <Filter
          isSelected={spreadsheetFilter.byRow === "all"}
          label={all_rows}
          count={recordsWithValuesCount}
          onClick={() => onAllSelected()}
          data-test-id="all_rows_filter"
        />
        <Filter
          isSelected={spreadsheetFilter.byRow === "invalid"}
          label={invalid_rows}
          count={invalidRowsCount}
          onClick={() => onInvalidSelected()}
          data-test-id="invalid_filter"
        />
        <Filter
          isSelected={spreadsheetFilter.byRow === "duplicates"}
          label={duplicates}
          count={duplicateRecordIds.size}
          onClick={() => onDuplicatesSelected()}
          data-test-id="duplicates_filter"
        />
      </Container>
    </Wrapper>
  );
};

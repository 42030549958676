import { colors, Div } from "fuse-shared-ui";
import * as S from "../styles";
import { LabelCellContentProps } from "../interfaces";

export const LabelCellContent = ({
  label,
  internalKey,
  isRequired,
}: LabelCellContentProps) => {
  return (
    <Div dflex flexColumn p="8px 0px">
      <Div dflex alignCenter>
        <S.LabelWithLimitedWidth isRequired={isRequired} data-cy="label" wbody3>
          {label}
        </S.LabelWithLimitedWidth>

        {isRequired && (
          <S.RequiredPill data-cy="required" ml={10}>
            *Required
          </S.RequiredPill>
        )}
      </Div>

      <Div mt={10} c={colors.gray700} wbody3 alignCenter>
        key:{" "}
        <S.KeyWithLimitedWidth data-cy="internal_key" ml={2}>
          {internalKey}
        </S.KeyWithLimitedWidth>
      </Div>
    </Div>
  );
};

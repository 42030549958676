export const warningIcon = (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4137_1150)">
      <rect width="68" height="68" fill="white" />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M28.5668 8.05854C29.6943 6.10577 32.5129 6.10576 33.6403 8.05854L59.1466 52.2368C60.274 54.1895 58.8648 56.6305 56.6099 56.6305H5.59726C3.34239 56.6305 1.93309 54.1895 3.06053 52.2368L28.5668 8.05854Z"
          fill="url(#paint0_linear_4137_1150)"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M34.4374 9.68867C35.5649 7.73589 38.3835 7.73589 39.5109 9.68867L65.8159 55.2503C66.9434 57.2031 65.5341 59.6441 63.2792 59.6441H10.6691C8.41426 59.6441 7.00496 57.2031 8.13239 55.2503L34.4374 9.68867Z"
          fill="url(#paint1_linear_4137_1150)"
        />
      </g>
      <rect
        x="29.0371"
        y="21.2632"
        width="6.43183"
        height="21.3972"
        rx="2.92916"
        fill="#FFFDF8"
      />
      <rect
        x="29.0371"
        y="44.7898"
        width="6.43183"
        height="5.61436"
        rx="2.80718"
        fill="#FFFDF8"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4137_1150"
        x1="10.0099"
        y1="28.8136"
        x2="30.2646"
        y2="54.4823"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD5C39" />
        <stop offset="1" stopColor="#FFB869" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4137_1150"
        x1="18.8006"
        y1="74.0811"
        x2="85.2566"
        y2="33.8684"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E7C551" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
      <clipPath id="clip0_4137_1150">
        <rect width="68" height="68" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

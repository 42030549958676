import { Placement } from "@popperjs/core";
import { Options } from "@popperjs/core/lib/modifiers/offset";
import { Div, DivCSSProps } from "fuse-shared-ui";
import { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled, { FlattenSimpleInterpolation } from "styled-components";

export const DropdownBackdrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownContainer = styled(Div)<{
  customStyles?: FlattenSimpleInterpolation;
}>`
  background-color: white;
  border: solid 1px#F9FAFB;
  position: absolute;
  border-radius: 4px;
  overflow-y: auto;
  ${(p) => p.theme.css.scrollbarDark};
  z-index: 1000000000000;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  ${({ customStyles }) => customStyles};
`;

type Props = {
  onClose?: any;
  isOpen?: boolean;
  children: any;
  placement?: Placement;
  customStyles?: FlattenSimpleInterpolation;
} & DivCSSProps &
  Partial<Options>;
export const Dropdown = ({
  onClose,
  isOpen = false,
  children,
  placement = "auto",
  offset = [2, 3],
  customStyles,
  ...props
}: Props) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset,
          },
        },
      ],
    }
  );

  useEffect(() => {
    if (update) update();
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Div ref={setReferenceElement} {...props}>
          <DropdownBackdrop
            onClick={onClose}
            data-cy="dropdown-background"
          ></DropdownBackdrop>
          <DropdownContainer
            ref={setPopperElement}
            style={styles.popper}
            customStyles={customStyles}
            {...attributes.popper}
          >
            {children}
          </DropdownContainer>
        </Div>
      )}
    </>
  );
};

import { useAppContext } from "components/AppContextProvider";
import { useAppModalContext } from "components/AppModalContextProvider";
import { useUserContext } from "components/UserContextProvider";
import { api, useTableContext } from "fuse-shared-ui";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { launchImporter } from "../common/utils";
import ColumnFormModal from "./ColumnFormModal";
import { useTableColumns } from "./components/TableColumns";
import { Template, URLParams } from "./types";

export function useTemplateColumns() {
  const [template, setTemplate] = useState<Template>(null);

  const [isLoading, setIsLoading] = useState(false);

  const { data, getData } = useTableContext();

  const { slug } = useParams<URLParams>();

  const { setIsLoadingApp, isLoadingApp } = useAppContext();

  const { showModal } = useAppModalContext();

  const {
    user: { active_organization },
  } = useUserContext();

  const apiToken = active_organization.api_token;

  const showColumnFormModal = useCallback(
    (initialValues = {}) => {
      showModal(({ hideModal, isOpen }) => (
        <ColumnFormModal
          initialValues={initialValues}
          getData={getData}
          isOpen={isOpen}
          hideModal={hideModal}
          template={template}
        />
      ));
    },
    [template]
  );

  const launchTemplate = useCallback(() => {
    launchImporter(template, apiToken);
  }, [template]);

  const updateTemplate = useCallback(async (newValues) => {
    setIsLoading(true);
    const { data } = await api.put(
      `/api/v1/templates/${slug || newValues?.slug}`,
      newValues,
      {
        successMsg: "Importer was updated successfully",
      }
    );
    setTemplate(data as Template);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const getTemplate = async () => {
      setIsLoadingApp(true);

      try {
        const { data } = await api.get(`/api/v1/templates/${slug}`);
        setTemplate(data);
      } catch (error) {
        console.error(error);
      }
      setIsLoadingApp(false);
    };

    slug && getTemplate();
  }, []);

  const tableColumns = useTableColumns({
    template,
    onEditRow: showColumnFormModal,
  });

  return {
    tableColumns,
    isLoadingApp,
    data,
    getData,
    template,
    isLoading,
    showColumnFormModal,
    launchTemplate,
    updateTemplate,
  };
}

import * as React from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import styled from "styled-components";

const Tabs = styled(TabsPrimitive.Root)`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
`;

// ==============================
// TabsPrimitive.List
// ==============================
const StyledTabsList = styled(TabsPrimitive.List)`
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e5dbdb;
`;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => <StyledTabsList ref={ref} {...props} />);
TabsList.displayName = TabsPrimitive.List.displayName;

// ==============================
// TabsPrimitive.Trigger
// ==============================
const StyledTabsTrigger = styled(TabsPrimitive.Trigger)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  outline: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 12px 16px;
  ${({ theme }) => theme.textStyles.body1}

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &[data-state="active"] {
    border-bottom: 2px solid black;
    ${({ theme }) => theme.textStyles.body2}
  }
`;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => <StyledTabsTrigger ref={ref} {...props} />);
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

// ==============================
// TabsPrimitive.Content
// ==============================
const StyledTabsContent = styled(TabsPrimitive.Content)`
  outline: none;
  &:focus-visible {
    outline: none;
  }
`;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => <StyledTabsContent ref={ref} {...props} />);
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };

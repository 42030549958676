export const DeleteIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 5.16675H18.5L17.9875 15.4164C17.8545 18.0775 15.6581 20.1667 12.9938 20.1667H9.00625C6.34188 20.1667 4.14554 18.0775 4.01248 15.4164L3.5 5.16675Z"
      stroke="#262626"
      strokeWidth="2"
    />
    <path
      d="M6.83331 5.16675V4.83341C6.83331 3.17656 8.17646 1.83341 9.83331 1.83341H12.1666C13.8235 1.83341 15.1666 3.17656 15.1666 4.83341V5.16675"
      stroke="#262626"
      strokeWidth="2"
    />
    <path
      d="M1.83331 5.16675H20.1666"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M13.5 10.1667V14.3334"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M8.5 10.1667V14.3334"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const ConfirmationDeleteIcon = (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="68" height="68" fill="white" />
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M8.79639 4.52687C8.79639 3.70291 9.73698 3.23251 10.3962 3.72676L33.7964 21.2699C33.9246 21.3661 34.0001 21.517 34.0001 21.6772L34.0001 46.015C34.0001 46.4345 33.5212 46.674 33.1856 46.4224L9.59669 28.7376C9.0929 28.3599 8.79639 27.7671 8.79639 27.1374L8.79639 4.52687Z"
        fill="url(#paint0_linear_2363_5281)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M8.7959 63.1264C8.7959 63.9504 9.73649 64.4208 10.3957 63.9266L33.7959 46.3834C33.9241 46.2873 33.9996 46.1363 33.9996 45.9761L33.9996 21.6383C33.9996 21.2188 33.5207 20.9794 33.1851 21.231L9.5962 38.9157C9.09241 39.2934 8.7959 39.8863 8.7959 40.5159L8.7959 63.1264Z"
        fill="url(#paint1_linear_2363_5281)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M59.2039 4.87257C59.2039 4.04862 58.2633 3.57821 57.604 4.07246L34.1528 21.6539C34.0567 21.726 34.0002 21.839 34.0002 21.9591L34.0002 46.6159C34.0002 46.9302 34.359 47.1097 34.6104 46.9211L58.4036 29.0833C58.9073 28.7056 59.2039 28.1128 59.2039 27.4831L59.2039 4.87257Z"
        fill="url(#paint2_linear_2363_5281)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M59.2041 63.4722C59.2041 64.2961 58.2635 64.7665 57.6043 64.2723L34.1531 46.6908C34.057 46.6188 34.0004 46.5057 34.0004 46.3856L34.0004 21.7288C34.0004 21.4145 34.3592 21.2351 34.6107 21.4236L58.4038 39.2614C58.9076 39.6391 59.2041 40.232 59.2041 40.8616L59.2041 63.4722Z"
        fill="url(#paint3_linear_2363_5281)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2363_5281"
        x1="28.828"
        y1="2.27186"
        x2="10.8931"
        y2="6.63443"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF710A" />
        <stop offset="1" stopColor="#FD9827" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2363_5281"
        x1="28.8275"
        y1="65.3815"
        x2="10.8926"
        y2="61.0189"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF710A" />
        <stop offset="1" stopColor="#FD9827" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2363_5281"
        x1="41.7498"
        y1="25.1258"
        x2="70.7926"
        y2="29.9741"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDD172" />
        <stop offset="1" stopColor="#D0A617" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2363_5281"
        x1="41.7501"
        y1="43.2189"
        x2="70.7929"
        y2="38.3706"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDD172" />
        <stop offset="1" stopColor="#D0A617" />
      </linearGradient>
    </defs>
  </svg>
);

export const SearchIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 21L18 18"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DownloadIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0834 18C9.66675 16.75 9.66675 15.5 9.66675 14.6303C9.66675 11.7319 12.2621 8.83337 15.4637 8.83337C17.6094 8.83337 19.4828 9.99914 20.4852 11.7319H21.2607C23.6619 11.7319 25.9167 13.4167 25.9167 15.5C25.9167 16.3334 25.9167 17.1667 25.5001 18"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1807 23.2764L17.5834 26.5416L20.9862 23.2764"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5833 25.6827L17.5833 16.4375"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="53"
    height="53"
    viewBox="0 0 53 53"
    fill="none"
  >
    <rect width="53" height="53" fill="white" />
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M40.6023 5.38665C41.3381 4.56281 42.6024 4.49142 43.4263 5.22719L51.5083 12.4453C52.3321 13.181 52.4035 14.4454 51.6677 15.2692L23.539 46.7647C22.8032 47.5885 21.5389 47.6599 20.7151 46.9242L12.6331 39.7061C11.8092 38.9703 11.7378 37.706 12.4736 36.8822L40.6023 5.38665Z"
        fill="url(#paint0_linear_643_341)"
      />
    </g>
    <g style={{ mixBlendMode: "darken" }}>
      <rect
        x="32.0707"
        y="37.1587"
        width="14.836"
        height="29.7113"
        rx="2"
        transform="rotate(131.582 32.0707 37.1587)"
        fill="url(#paint1_linear_643_341)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_643_341"
        x1="15.4625"
        y1="39.0861"
        x2="31.139"
        y2="48.4231"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0A617" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_643_341"
        x1="39.4887"
        y1="37.1587"
        x2="29.9147"
        y2="74.1394"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6BEEFF" />
        <stop offset="1" stopColor="#08CABE" />
      </linearGradient>
    </defs>
  </svg>
);

export const ActionPlus = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Actions Plus">
      <path
        id="Vector 4"
        d="M8 2.91602V14.916"
        stroke="#909090"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 5"
        d="M2 8.91602L14 8.91602"
        stroke="#909090"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const ReplaceIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 15.6666V6.33334C19.5 5.04467 18.4553 4 17.1666 4H10.75M19.5 15.6666L16 12.1667M19.5 15.6666L23 12.1667M5.5 8.66665V18C5.5 19.2887 6.54469 20.3333 7.83332 20.3333H14.25M5.5 8.66665L9.00001 12.1667M5.5 8.66665L2 12.1667"
      stroke="#262626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import ActionCable from "actioncable";
import React, {
  MutableRefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CustomAction,
  FieldTypes,
  FormatRecord,
  ImporterOptions,
  Integration,
  OnSubmit,
  OnValidateRecord,
  Organization,
  Record,
  TemplateHeader,
  TemplateInfo,
} from "fuse-importer";
import { FuseApi } from "../common/FuseApi";
import { Theme, getCustomTheme, theme } from "../common/theme";
import { getColumnType, verifyDuplicateValue } from "../common/utils";
import { baseWsUrls } from "../common";

export type HeaderMatchings = {
  // maps to uploadedData header
  [templateHeaderLabel: string]: string;
};

export type EnumFieldValueMatchings = {
  [templateHeaderLabel: string]: {
    // maps to enum field option
    [uploadedDataValue: string]: string;
  };
};

export type UploadedDataEnumFieldValues = {
  [templateHeaderLabel: string]: string[];
};

export type SelectedRow = {
  tableRow: number;
  dataRowIndex: number;
};

export type State = {
  currentStepIndex: number;
  templateError: string | null;
  uploadedData: any[];
  initialRecords: Record[];
  enumFieldValueMatchings: EnumFieldValueMatchings;
  templateHeaders: TemplateHeader[];
  userDynamicColumns: string[];
  templateHeaderMatchings: HeaderMatchings;
  uploadedDataHeaders: string[];
  matcherSubstep: number;
  uploadedDataEnumFieldValues: UploadedDataEnumFieldValues;
  templateSlug: string;
  apiToken: string;
  inTrialMode: boolean;
  hasValidSubmitFn: boolean;
  previewFirstTime: boolean;
  fuseApi: FuseApi;
  importerOptions: ImporterOptions;
  theme: any;
  logoURL: string;
  integrations: Integration[];
  onSubmit: OnSubmit;
  headerRowIndex: number;
  setHeaderRowIndex: React.Dispatch<React.SetStateAction<number>>;
  resetImport: () => void;
  onClose: () => void;
  setCurrentStepIndex: React.Dispatch<number>;
  setEnumFieldValueMatchings: React.Dispatch<
    React.SetStateAction<EnumFieldValueMatchings>
  >;
  setUploadedData: React.Dispatch<any[]>;
  setHeadersMappings: React.Dispatch<any[]>;
  setTemplateHeaderMatchings: React.Dispatch<
    React.SetStateAction<HeaderMatchings>
  >;
  setPreviewFirstTime: React.Dispatch<boolean>;
  formatRecord: FormatRecord;
  onValidateRecord?: OnValidateRecord;
  setMatcherSubstep: React.Dispatch<number>;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  isUsingManualImport: React.MutableRefObject<boolean>;
  resetImporterWhenUsingManualImport: () => void;
  manualDataImportSelected: () => void;
  customActions: CustomAction[];
  headerMappingsLoaded: boolean;
  valueMappingsLoaded: boolean;
  headersMappings: HeaderMappings[];
  isUploading: boolean;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
  fileName: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
  layoutRef: MutableRefObject<HTMLDivElement>;
  isUsingPersistence: boolean;
  createCableConsumer: () => ActionCable.Cable;
};

export type Props = {
  templateSlug: string;
  apiToken: string;
  initialRecords?: Record[];
  options: ImporterOptions;
  children: JSX.Element;
  onClose: () => void;
  onSubmit: OnSubmit;
  formatRecord: FormatRecord;
  onValidateRecord?: OnValidateRecord;
  dynamicColumns: TemplateHeader[] | null;
  customActions: CustomAction[];
};

type HeaderMappings = {
  [key: string]: string;
};

const ImporterContext = createContext<State>({} as State);

export const ImporterContextProvider = ({
  templateSlug,
  initialRecords,
  apiToken,
  children,
  options,
  onSubmit,
  onClose,
  formatRecord,
  onValidateRecord,
  dynamicColumns,
  customActions,
}: Props) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(
    initialRecords ? 2 : 0
  );
  const [matcherSubstep, setMatcherSubstep] = useState(0);
  const [templateError, setTemplateError] = useState(null);
  const [templateHeaders, setTemplateHeaders] = useState<TemplateHeader[]>(
    null
  );
  const [integrations, setIntegrations] = useState<Integration[]>(null);
  const [uploadedData, setUploadedData] = useState(null);
  const [organization, setOrganization] = useState<Organization>(null);
  const [previewFirstTime, setPreviewFirstTime] = useState(null);
  const [headersMappings, setHeadersMappings] = useState<HeaderMappings[]>(
    null
  );
  const [style, setStyle] = useState<{ logoURL: string; theme: Theme }>({
    theme: theme,
    logoURL: null,
  });
  const [templateHeaderMatchings, setTemplateHeaderMatchings] = useState<
    HeaderMatchings
  >(null);
  const [enumFieldValueMatchings, setEnumFieldValueMatchings] = useState<
    EnumFieldValueMatchings
  >({});
  const [
    uploadedDataEnumFieldValues,
    setUploadedDataEnumFieldValues,
  ] = useState<UploadedDataEnumFieldValues>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const isUsingManualImport = useRef<boolean>(false);
  const [headerRowIndex, setHeaderRowIndex] = useState(0);
  const [headerMappingsLoaded, setHeaderMappingsLoaded] = useState(false);
  const [valueMappingsLoaded, setValueMappingsLoaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [isUsingPersistence, setIsUsingPersistence] = useState(false);
  const layoutRef = useRef<HTMLDivElement>(null);
  const [isClosed, setIsClosed] = useState(false);

  const fuseApi = useMemo(() => new FuseApi(options.env, apiToken), [
    options.env,
    apiToken,
  ]);

  const createCableConsumer = useCallback(() => {
    return ActionCable.createConsumer(
      process.env.REACT_APP_WEBSOCKET_BASE_URL || baseWsUrls[options.env]
    );
  }, []);

  const fetchColumnMappings = async (userDynamicColumns: string[]) => {
    const {
      data: { mappings },
    } = await fuseApi.post(
      `/api/v1/importer/templates/${templateSlug}/column_mappings`,
      {
        file_headers: uploadedDataHeaders,
        dynamic_columns: userDynamicColumns,
      }
    );
    return mappings;
  };

  const fetchValueMappings = async (
    _uploadedDataEnumFieldValues: UploadedDataEnumFieldValues,
    userDynamicValues: {
      [key: string]: string[];
    }
  ) => {
    const {
      data: { mappings },
    } = await fuseApi.post(
      `/api/v1/importer/templates/${templateSlug}/value_mappings`,
      {
        file_values: _uploadedDataEnumFieldValues,
        dynamic_values: userDynamicValues,
      }
    );
    return mappings;
  };

  const uploadedDataHeaders: string[] | null = useMemo(() => {
    if (!uploadedData) return null;

    if (isUsingManualImport.current) {
      return templateHeaders.reduce((acc, curr) => {
        acc.push(curr.internal_key);
        return acc;
      }, []);
    }

    return uploadedData[headerRowIndex]?.map((ud) =>
      typeof ud === "number" ? ud.toString() : ud
    );
  }, [uploadedData, headerRowIndex]);

  const userDynamicColumns = dynamicColumns.map((value) => value.label);

  // use the API to match selected headers to the template
  const loadHeaderMappings = async () => {
    setHeaderMappingsLoaded(false);
    try {
      const mappings = await fetchColumnMappings(userDynamicColumns);
      setHeadersMappings(mappings);
    } catch (e) {
      setHeadersMappings([]);
      console.error("Error occurred while fetching header mappings", e);
    }

    setHeaderMappingsLoaded(true);
  };

  useEffect(() => {
    (async () => {
      if (uploadedDataHeaders) {
        loadHeaderMappings();
      }
    })();
  }, [uploadedDataHeaders]);

  // Values mappings for enum columns
  const loadValueMappings = async (
    _uploadedDataEnumFieldValues: UploadedDataEnumFieldValues
  ) => {
    setValueMappingsLoaded(false);

    if (
      !!_uploadedDataEnumFieldValues &&
      Object.keys(_uploadedDataEnumFieldValues).length > 0
    ) {
      try {
        const dynamicValues = userDynamicColumns.reduce((acc, column) => {
          const values = templateHeaders.find(
            (header) => header.label === column
          )?.values;
          if (values) {
            acc[column] = values;
          }
          return acc;
        }, {});

        const mappings = await fetchValueMappings(
          _uploadedDataEnumFieldValues,
          dynamicValues
        );
        setEnumFieldValueMatchings(mappings);
      } catch (e) {
        setEnumFieldValueMatchings({});
        console.error("Error occurred while fetching value mappings", e);
      }
    }

    setValueMappingsLoaded(true);
  };

  useEffect(() => {
    const setHeadersFromDataset = (): void => {
      const _templateHeaderMatchings = {};
      templateHeaders.forEach((templateHeader, _index) => {
        const mappedHeader = headersMappings.find((headerMapping) => {
          const matchedColumn = Object.values(headerMapping)[0];
          if (matchedColumn) {
            return (
              matchedColumn.toLowerCase() === templateHeader.label.toLowerCase()
            );
          } else {
            return false;
          }
        });
        if (mappedHeader) {
          _templateHeaderMatchings[templateHeader.label] = Object.keys(
            mappedHeader
          )[0];
        } else {
          _templateHeaderMatchings[templateHeader.label] = null;
        }
      });

      setTemplateHeaderMatchings(_templateHeaderMatchings);
    };
    if (uploadedDataHeaders && headersMappings) {
      setHeadersFromDataset();
    }
  }, [uploadedDataHeaders, headersMappings]);

  useEffect(() => {
    if (
      !templateHeaderMatchings ||
      !uploadedDataHeaders ||
      !headerMappingsLoaded
    )
      return;
    const _uploadedDataEnumFieldValues = {};

    const templateLabels = Object.keys(templateHeaderMatchings);
    const enumFieldsLabels = templateLabels.filter(
      (templateLabel) =>
        getColumnType({ templateLabel, templateHeaders }) === FieldTypes.enum
    );

    enumFieldsLabels.forEach((templateLabel) => {
      const uploadedDataHeader = templateHeaderMatchings[templateLabel];
      const enumFieldValues = new Set<string>();
      const columnIndex = uploadedDataHeaders.indexOf(uploadedDataHeader);
      if (columnIndex === -1) return;
      uploadedData?.slice(headerRowIndex + 1).forEach((row) => {
        const uploadedValue = row[columnIndex];
        enumFieldValues.add(uploadedValue);
      });
      _uploadedDataEnumFieldValues[templateLabel] = Array.from(
        enumFieldValues.values()
      ).filter((element) => {
        return element !== undefined;
      });
    });

    setUploadedDataEnumFieldValues(_uploadedDataEnumFieldValues);

    const valueLimitPerEnumField = Math.ceil(40 / enumFieldsLabels.length);
    const enumFieldsLabelsLimited = {};
    Object.keys(_uploadedDataEnumFieldValues).forEach((key) => {
      enumFieldsLabelsLimited[key] = _uploadedDataEnumFieldValues[key].slice(
        0,
        valueLimitPerEnumField
      );
    });

    loadValueMappings(enumFieldsLabelsLimited);
  }, [headerMappingsLoaded, matcherSubstep]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: {
            organization,
            columns,
            importer_style_preferences,
            integrations,
            preview_first_time,
            persistence,
          },
        } = await fuseApi.get<TemplateInfo>(
          `/api/v1/importer/templates/${templateSlug}`
        );

        setOrganization(organization);
        setTemplateHeaders(() => {
          // user has created dynamic columns
          if (dynamicColumns && dynamicColumns?.length >= 0) {
            const allColumns = [...columns, ...dynamicColumns];

            const columnsOrderedByPosition = allColumns.sort(
              (a, b) => a?.position - b?.position
            );

            verifyDuplicateValue(
              columnsOrderedByPosition,
              "internal_key",
              true,
              "There is a duplicated 'internal_key' between your columns."
            );

            verifyDuplicateValue(
              columnsOrderedByPosition,
              "label",
              true,
              "There is a duplicated 'label' between your columns."
            );

            return columnsOrderedByPosition;
          }

          // user has not created dynamic columns
          const columnsOrderedByPosition = columns.sort(
            (a, b) => a.position - b.position
          );

          return columnsOrderedByPosition;
        });
        setIntegrations(integrations);
        setPreviewFirstTime(preview_first_time);
        setIsUsingPersistence(persistence);
        if (organization.plan && organization.plan.allow_style_customization) {
          setStyle(getCustomTheme(importer_style_preferences));
        }
      } catch (error) {
        const errorMsg =
          error.response?.data?.message ||
          "There was an error preventing the data importer from loading. Check your API Key and try again.";
        setTemplateError(errorMsg);
      }
    })();
  }, [dynamicColumns]);

  const resetImport = (): void => {
    setUploadedData(null);
    setTemplateHeaderMatchings(null);
    setHeadersMappings(null);
    setEnumFieldValueMatchings({});
    setUploadedDataEnumFieldValues(null);
    setSelectedRows([]);
    setHeaderRowIndex(0);
  };

  const resetImporterWhenUsingManualImport = () => {
    setCurrentStepIndex(0);
    isUsingManualImport.current = false;
    resetImport();
  };

  const manualDataImportSelected = () => {
    if (templateHeaders) {
      isUsingManualImport.current = true;
      setUploadedData([]);
      setTemplateHeaderMatchings({});
      setCurrentStepIndex(2);
    }
  };

  const inTrialMode = organization?.trial_mode;
  const hasValidSubmitFn = typeof onSubmit === "function";

  const handleOnClose = () => {
    setIsClosed(true);
    onClose();
  };

  const value = {
    initialRecords,
    currentStepIndex,
    templateHeaders,
    userDynamicColumns: userDynamicColumns,
    enumFieldValueMatchings,
    templateHeaderMatchings,
    uploadedDataEnumFieldValues,
    importerOptions: options,
    uploadedData,
    uploadedDataHeaders,
    templateError,
    matcherSubstep,
    apiToken,
    templateSlug,
    inTrialMode,
    hasValidSubmitFn,
    fuseApi,
    theme: style.theme,
    logoURL: style.logoURL,
    integrations,
    previewFirstTime,
    headerRowIndex,
    setHeaderRowIndex,
    setUploadedData,
    setHeadersMappings,
    formatRecord,
    setCurrentStepIndex,
    setEnumFieldValueMatchings,
    setTemplateHeaderMatchings,
    resetImport,
    onValidateRecord,
    setMatcherSubstep,
    onClose: handleOnClose,
    setPreviewFirstTime,
    onSubmit,
    selectedRows,
    setSelectedRows,
    isUsingManualImport,
    resetImporterWhenUsingManualImport,
    manualDataImportSelected,
    customActions,
    headerMappingsLoaded,
    valueMappingsLoaded,
    headersMappings,
    fileName,
    setFileName,
    isUploading,
    setIsUploading,
    layoutRef,
    isUsingPersistence,
    createCableConsumer,
  };

  if (isClosed) return null; // Force react to unmount the component to avoid memory leaks

  return (
    <ImporterContext.Provider value={value}>
      {children}
    </ImporterContext.Provider>
  );
};

export const useImporterContext = () => useContext(ImporterContext);

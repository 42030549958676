import { Plan } from "components/UserContextProvider";
import { chevronLeftIcon, Div, Span } from "fuse-shared-ui";
import { useUpdatePlan } from "../../../common";
import PaymentMethodsForm from "../../../common/PaymentMethodsForm";
import * as S from "./styles";

interface PropsTypes {
  onClose: (canceled?: boolean) => void;
  plan: Plan;
}

const SelectPlanForm = ({ onClose, plan }: PropsTypes): JSX.Element => {
  const updatePlan = useUpdatePlan();

  const onPaymentMethodCreated = async () => {
    updatePlan(plan);
  };

  return (
    <S.Wrapper>
      <S.Container>
        <S.LeftPane mt={16}>
          <S.Header mb={40.39}>
            <S.GoBackButton onClick={() => onClose(true)} mb={16.39}>
              {chevronLeftIcon}
              <Span ml={10}>Back</Span>
            </S.GoBackButton>
            <Div dflex h={44.22} alignCenter>
              {S.fuseLogoInline}
            </Div>
          </S.Header>
          <S.StyledPlanDetails plan={plan} />
        </S.LeftPane>
        <S.RightPane>
          <Div>
            <PaymentMethodsForm
              buttonLabel={"Upgrade Plan"}
              onClose={onClose}
              onPaymentMethodCreated={onPaymentMethodCreated}
            />
          </Div>
        </S.RightPane>
      </S.Container>
    </S.Wrapper>
  );
};

export default SelectPlanForm;

import { FormikSumbitButton as FormikSubmitButton } from "components/common/form/Form";
import { Form, Formik } from "formik";
import { Div, colors, theme } from "fuse-shared-ui";
import { isEqual } from "lodash";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { AppLoading } from "../../../AppLoading";
import { useUserContext } from "../../../UserContextProvider";
import { Header } from "../../../common/Header";
import { SectionContainer } from "../../../common/SectionContainer";
import ColorPickers from "./ColorPickers";
import ImageFileInput from "./ImageFileInput";
import ImporterStylePreferencesPreview from "./ImporterStylePreferencesPreview";
import { UpgradeTooltip } from "./UpgradeTooltip";
import { useImporterStylePreferences } from "./useImporterStylePreferences";

const Wrapper = styled(Div)`
  display: flex;
  gap: 10px;
  height: 100%;

  @media all and (${theme.media.mobile}) {
    display: flex !important;
    flex-direction: column !important;
  }
`;

const LeftSectionWrapper = styled(SectionContainer)`
  border-right: 1px solid #e5dbdb;
  flex: 1;
  height: fit-content;
  margin: 0 0 0 36px;
  padding: 40px 36px 40px 0;
`;

const RightSectionWrapper = styled(SectionContainer)`
  flex: 1.7;

  @media all and (${theme.media.mobile}) {
    padding-bottom: 24px;
  }
`;

const LeftSection = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RightSection = styled(Div)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledFormikSubmitButton = styled(FormikSubmitButton)`
  width: 100%;
`;

export type FormValues = {
  primary_color: string;
  secondary_color: string;
  highlight1: string;
  highlight2: string;
  background: string;
  logo: File;
  logo_url: string;
};

const ImporterStylePreferences = (): JSX.Element => {
  const {
    user: { active_organization: activeOrg },
    canOrganizationCustomize,
  } = useUserContext();
  const { importerStylePreferences, onSubmit } = useImporterStylePreferences(
    activeOrg,
    canOrganizationCustomize
  );
  const initialValuesRef = useRef(null);

  const defaultValues = {
    primary_color: colors.black,
    secondary_color: colors.blue500,
    highlight1: colors.blue500,
    highlight2: colors.blue100,
    background: colors.orange50,
    logo: null,
    logo_url: null,
  };

  if (!importerStylePreferences) return <AppLoading />;

  const headerUI = (
    <>
      <Helmet>
        <title>Flatirons Fuse - Branding</title>
      </Helmet>

      <Div dflex flexColumn gap={12}>
        <Header testID="brandingTitle" title="Customize Importer" />

        <Div flexColumn dflex gap={12}>
          {!canOrganizationCustomize && <UpgradeTooltip />}
          <Div data-cy="description-text" wbody1>
            Customize the styling of the importer to make this experience feel
            like an extension of your brand.
          </Div>
        </Div>
      </Div>
    </>
  );

  return (
    <Formik initialValues={importerStylePreferences} onSubmit={onSubmit}>
      {({ resetForm, values, initialValues }) => {
        if (!initialValuesRef.current) initialValuesRef.current = initialValues;

        const disableSubmit =
          !canOrganizationCustomize ||
          isEqual(values, initialValuesRef.current);

        return (
          <Form style={{ height: "100%" }}>
            <Wrapper>
              <LeftSectionWrapper c={colors.gray800}>
                <LeftSection>
                  <Div>
                    {headerUI}

                    <Div w100>
                      <Div data-cy="brand-logo-text" label2 mt={40}>
                        Brand Logo
                      </Div>
                      <Div data-cy="brand-logo-description" wbody3 mt={8}>
                        Upload an image of your logo. Maximum upload file size:
                        1MB
                      </Div>
                      <ImageFileInput
                        disableButton={!canOrganizationCustomize}
                      />
                    </Div>
                    <Div mt={46} dflex spaceBetween w100>
                      <Div data-cy="color-style" label2 alignCenter>
                        Color Styles
                      </Div>
                      <Div
                        data-cy="revert-original"
                        link
                        bold
                        c={colors.black}
                        onClick={() => resetForm({ values: defaultValues })}
                      >
                        Revert to original
                      </Div>
                    </Div>
                    <ColorPickers />
                  </Div>

                  <StyledFormikSubmitButton
                    variant={"primary"}
                    mt={24}
                    disableOnErrors={true}
                    disabledWhen={disableSubmit}
                    isDisabled={disableSubmit}
                  >
                    <Div data-cy="apply-changes">Apply changes</Div>
                  </StyledFormikSubmitButton>
                </LeftSection>
              </LeftSectionWrapper>

              <RightSectionWrapper c={colors.gray800}>
                <RightSection flexColumn centered>
                  <Div />
                  <ImporterStylePreferencesPreview />
                  <Div data-cy="preview-section" label2>
                    This is a preview
                  </Div>
                </RightSection>
              </RightSectionWrapper>
            </Wrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ImporterStylePreferences;

import { useAppModalContext } from "components/AppModalContextProvider";
import { useFormikField } from "components/common/form/fieldHelpers";
import { useSegment } from "components/SegmentContextProvider";
import { useUserContext } from "components/UserContextProvider";
import { useFormikContext } from "formik";
import {
  api,
  Transformation,
  transformationsByColumnType,
  transformationsByType,
  Validation,
  validationsByColumnType,
  validationsByType,
} from "fuse-shared-ui";
import { patternsColumnTypes } from "./fields";

import { get } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ColumnAddedModal from "./components/ColumnAddedModal";
import {
  ColumnFormValue,
  ColumnTransformation,
  ColumnValidation,
} from "./types";
import { FieldTypes } from "fuse-importer";

const saveColumn = async ({ values, template_id, id }) => {
  const isCreating = !id;
  const baseUrl = `/api/v1/templates/${template_id}/columns`;
  const method = isCreating ? api.post : api.put;
  const url = isCreating ? baseUrl : `${baseUrl}/${id}`;
  const { data } = await method(url, values);
  return data;
};

const removeEmptyTypeValidations = (validations?: ColumnValidation[]) => {
  return validations?.filter((v) => !!v.validation_type);
};

const removeEmptyTypeTransformations = (
  transformations?: ColumnTransformation[]
) => {
  return transformations?.filter((v) => !!v.transformation_type);
};

type UseColumnFormProps = {
  initialValues: any;
  hideModal: () => void;
};

type UseColumnFormSubmitProps = {
  getData: () => void;
  template: any;
  isEditing: boolean;
  initialValues?: any;
} & UseColumnFormProps;

const getInitialValidationsFor = (columnType: string) => {
  if (!columnType) {
    return {
      initialValidations: [],
      canAddMoreValidations: false,
    };
  }

  const allowedValidations = validationsByColumnType[columnType] || [];
  const defaultValidations = allowedValidations.filter(
    (v) => v.isDefaultValidation
  );
  const availableValidations = allowedValidations.filter(
    (v) => !v.isDefaultValidation
  );
  const newValidations: ColumnValidation[] = defaultValidations.map((v) => ({
    validation_type: v.value,
    message: v.label,
    is_default: v.isDefaultValidation,
  }));

  const canAddMoreValidations = availableValidations.length > 0;

  if (newValidations.length === 0 && canAddMoreValidations) {
    newValidations.push({ validation_type: null });
  }

  return {
    initialValidations: newValidations,
    canAddMoreValidations,
  };
};

const getInitialTransformationsFor = (columnType: string) => {
  if (!columnType) {
    return {
      initialTransformations: [],
      canAddMoreTransformations: false,
    };
  }

  const allowedTransformations = transformationsByColumnType[columnType] || [];
  const defaultTransformations = allowedTransformations.filter(
    (v) => v.isDefaultTransformation
  );
  const newTransformations: ColumnTransformation[] = defaultTransformations.map(
    (v) => ({
      transformation_type: v.value,
      message: v.label,
      is_default: v.isDefaultTransformation,
    })
  );

  if (newTransformations.length === 0) {
    newTransformations.push({ transformation_type: null });
  }

  return {
    initialTransformations: newTransformations,
  };
};

export function useColumnForm({
  initialValues,
  hideModal,
}: UseColumnFormProps) {
  const { values, setValues } = useFormikContext<ColumnFormValue>();
  const { column_type: columnType, transformations } = values;

  const [canAddMoreValidations, setCanAddMoreValidations] = useState(true);
  const [canAddMoreTransformations, setCanAddMoreTransformations] = useState(
    true
  );
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const columnTypeRef = useRef(columnType);

  const resetValidation = useCallback(() => {
    const wasColumnTypeChanged =
      get(initialValues, "column_type") !== columnType;

    const initialValidations = get(initialValues, "validations");
    const initialTransformations = get(initialValues, "transformations");

    const {
      canAddMoreValidations,
      initialValidations: newInitialValidations,
    } = getInitialValidationsFor(columnType);

    const {
      initialTransformations: newInitialTransformations,
    } = getInitialTransformationsFor(columnType);

    const validations = wasColumnTypeChanged
      ? newInitialValidations
      : initialValidations;

    const transformations = wasColumnTypeChanged
      ? newInitialTransformations
      : initialTransformations;

    setCanAddMoreValidations(canAddMoreValidations);
    setValues((values) => ({ ...values, validations, transformations }));
  }, [columnType, initialValues]);

  const handleCancel = useCallback(() => {
    switch (activeTabIndex) {
      case 0:
        return hideModal();
      case 1:
        setActiveTabIndex(0);
        return resetValidation();
      case 2:
        setActiveTabIndex(1);
        return resetValidation();
      default:
        return hideModal();
    }
  }, [activeTabIndex, hideModal]);

  const handleNext = useCallback((step: number) => {
    setActiveTabIndex(step);
  }, []);

  const showPatternField = useMemo(
    () => patternsColumnTypes.includes(columnType),
    [columnType]
  );

  useEffect(() => {
    const wasColumnTypeChanged = columnTypeRef.current !== columnType;
    const isInitialColumnType =
      get(initialValues, "column_type") === columnType;
    const initialValidations = get(initialValues, "validations");
    const initialTransformations = get(initialValues, "transformations");

    const isEnum = columnType === FieldTypes.enum;

    const {
      canAddMoreValidations,
      initialValidations: newInitialValidations,
    } = getInitialValidationsFor(columnType);

    const {
      initialTransformations: newInitialTransformations,
    } = getInitialTransformationsFor(columnType);

    setCanAddMoreValidations(canAddMoreValidations);

    setValues((value) => {
      const isValidationsEmpty =
        !value.validations || value.validations.length === 0;

      const shouldReinitializeValidations =
        wasColumnTypeChanged || isValidationsEmpty;
      const shouldReinitializeValues = wasColumnTypeChanged && isEnum;
      const shouldReinitializePattern = wasColumnTypeChanged;

      const isTransformationsEmpty =
        !value.transformations || value.transformations.length === 0;

      const shouldReinitializeTransformations =
        wasColumnTypeChanged || isTransformationsEmpty;

      columnTypeRef.current = columnType;

      const validations = isInitialColumnType
        ? initialValidations
        : shouldReinitializeValidations
        ? newInitialValidations
        : value.validations;

      const transformations = isInitialColumnType
        ? initialTransformations
        : shouldReinitializeTransformations
        ? newInitialTransformations
        : value.transformations;

      return {
        ...value,
        validations,
        transformations,
        pattern: shouldReinitializePattern ? null : values.pattern,
        values: shouldReinitializeValues ? [""] : isEnum ? value.values : [],
      };
    });
  }, [columnType, showPatternField]);

  useEffect(() => {
    if (transformations?.length === 0) {
      const availableTransformations =
        transformationsByColumnType[columnType] || [];

      const transformationTypes = transformations?.map(
        (transformation) => transformation.transformation_type
      );
      const allTransformationsWereSelected = availableTransformations?.every(
        (item) => transformationTypes?.includes(item.value)
      );

      setCanAddMoreTransformations(!allTransformationsWereSelected);
    }
  }, [transformations]);

  return {
    activeTabIndex,
    columnType,
    showPatternField,
    canAddMoreValidations,
    canAddMoreTransformations,
    handleNext,
    handleCancel,
  };
}

export function useColumnFormSubmit({
  template,
  hideModal,
  isEditing,
  getData,
  initialValues,
}: UseColumnFormSubmitProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { showModal } = useAppModalContext();
  const { updateOnboarding } = useUserContext();
  const { track } = useSegment();

  const showColumnAdded = useCallback(() => {
    hideModal();
    showModal(({ isOpen, hideModal: hideNested }) => (
      <ColumnAddedModal
        isOpen={isOpen}
        onClose={hideNested}
        isEditing={isEditing}
      />
    ));
  }, [showModal]);

  const onSubmit = useCallback(
    async (values: ColumnFormValue) => {
      try {
        setIsLoading(true);
        const data = await saveColumn({
          values: {
            ...values,
            validations: removeEmptyTypeValidations(values.validations),
            transformations: removeEmptyTypeTransformations(
              values.transformations
            ),
          },
          template_id: template.slug,
          id: initialValues?.id,
        });
        if (data) {
          getData();
          setIsLoading(false);
          showColumnAdded();
          updateOnboarding({ onboarding_create_a_template_step: true });
          if (!isEditing) {
            track("user_added_column_to_template", {
              template_name: template.name,
              template_id: template.slug,
              ...values,
            });
          }
        }
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    },
    [setIsLoading, track, isEditing, getData, showColumnAdded, updateOnboarding]
  );

  return { onSubmit, isLoading };
}

export function useValidationForm({ name }: { name: string }) {
  const { value: validationType } = useFormikField<string>(
    `${name}.validation_type`
  );

  const validationTypeRef = useRef(validationType);
  const { setValue: setMessage } = useFormikField<string>(`${name}.message`);

  const validation = useMemo<Validation>(
    () => validationsByType[validationType],
    [validationType]
  );

  const hasOptions = validation && validation.options;

  useEffect(() => {
    if (validationType !== validationTypeRef.current) {
      setMessage(validation?.label);
    }
    validationTypeRef.current = validationType;
  }, [validationType, validation]);

  return { hasOptions, validation, isDefault: validation?.isDefaultValidation };
}

export function useTransformationForm({ name }: { name: string }) {
  const { value: transformationType } = useFormikField<string>(
    `${name}.transformation_type`
  );

  const transformation = useMemo<Transformation>(
    () => transformationsByType[transformationType],
    [transformationType]
  );

  const hasOptions = transformation && transformation.options;

  return { hasOptions, transformation };
}

import {
  Div,
  ModalScrollArea,
  Span,
  TallModal,
  TallModalLayout,
  colors,
} from "fuse-shared-ui";
import { DateTime } from "luxon";
import styled from "styled-components";
import { useLogsTableContext } from "../contexts/LogsTableContext";
import { Log } from "../interfaces";
import { getErrorDetails } from "../modals/functions";
import { ErrorIcon, SuccessIcon } from "./icons";

type Status = number | "Success" | "Failed";

const isSuccessStatus = (status: Status) => {
  const parsedStatus = status.toString();
  const positiveStatus = parsedStatus.startsWith("2") || status === "Success";
  return positiveStatus;
};

const getStatusColor = (status: Status) => {
  const color = isSuccessStatus(status) ? "#00D085" : "#F44B38";
  return color;
};

export const getStatusIcon = (status: Status) =>
  isSuccessStatus(status) ? <SuccessIcon /> : <ErrorIcon />;

export const StatusLabel = styled(Div)<{ status: Status }>`
  border-radius: 24px;
  border: 1px solid
    ${(props: { status: Status }) => getStatusColor(props.status)};
  padding: 4px 8px 4px 8px;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  font-family: "DM Sans";
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 9px;
`;

const ContentWrapper = styled(Div)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-items: center;
  display: -webkit-box;
  overflow: hidden;
`;

export const tableColumns = [
  {
    width: 0.15,
    header: "Status",
    Content: ({ exception_message, response_status }) => {
      const isSuccess = exception_message === null && response_status === 200;
      const message = isSuccess ? "Success" : "Failed";
      return (
        <StatusLabel status={message}>
          {getStatusIcon(message)}
          {message}
        </StatusLabel>
      );
    },
  },
  {
    width: 0.15,
    header: "Response",
    Content: ({ exception_message, response_status }) => {
      const isSuccess = exception_message === null && response_status === 200;
      const message = isSuccess ? "Success" : "Failed";
      return (
        <StatusLabel status={message}>
          {response_status === 0 ? "N/A" : response_status}
        </StatusLabel>
      );
    },
  },
  {
    width: 0.2,
    header: "Error Details",
    Content: ({ exception_message, response_status, ...rest }) => {
      const log = { ...rest, exception_message, response_status } as Log;
      const isSuccess = exception_message === null && response_status === 200;
      const { openErrorDetailModal } = useLogsTableContext();

      const logErrorDetails = getErrorDetails(log);

      const toggleModalVisibility = () => {
        openErrorDetailModal(logErrorDetails);
      };

      if (isSuccess || !logErrorDetails) return <></>;

      return (
        <>
          <Div title={exception_message}>
            <Div
              data-cy="error-details"
              extraBold
              c={colors.text}
              dInline
              clickable
              onClick={(e) => {
                e.stopPropagation();
                toggleModalVisibility();
              }}
            >
              View error details
            </Div>
          </Div>
        </>
      );
    },
  },
  {
    width: 0.23,
    header: "Timestamp",
    Content: ({ timestamp }) => {
      const date = DateTime.fromISO(timestamp, { zone: "utc" });
      const formatted = `${date.toFormat("yyyy-MM-dd HH:mm:ss")} UTC`;

      return (
        <ContentWrapper dflex truncateText={formatted}>
          {formatted}
        </ContentWrapper>
      );
    },
  },
  {
    width: 0.4,
    header: "File Import Slug",
    Content: ({ import_slug }) => (
      <ContentWrapper pr={8}>{import_slug}</ContentWrapper>
    ),
  },
  {
    width: 0.15,
    header: "Importer",
    Content: ({ template_name }) => (
      <ContentWrapper>{template_name}</ContentWrapper>
    ),
  },
];

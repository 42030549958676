/* eslint-disable import/no-anonymous-default-export */
export default {
  preview: {
    title: "Vous êtes sur le point de prévisualiser votre importateur.",
    description:
      "Téléchargez notre exemple CSV pour essayer votre importateur. Nous l'avons généré aléatoirement afin que vous puissiez voir comment Fuse transforme les données..",
    download: "Télécharger CSV",
    note: {
      title: "Remarque:",
      description:
        "Cet écran n'existera plus une fois que vous aurez installé l'importateur sur votre site Web.",
    },
  },
  upload: {
    headers_preview: {
      title: "Sélectionnez une ligne d'en-tête",
      description:
        "Le fichier doit contenir des en-têtes de colonne pour continuer.",
      cancel: "Non, re-télécharger le fichier",
      continue: "Oui, continuer",
    },
    error: {
      title: "Quelque chose ne va pas",
    },
    header: {
      title:
        "Assurez-vous que votre document contient des données similaires à celles-ci:",
      required_headers: "En-têtes requis",
      optional_headers: "En-têtes facultatifs",
    },
    title: "Téléchargez un fichier pour importer vos données",
    description: `
    Téléchargez tout fichier de tableur .csv, .xls, .xlsx avec n'importe quel ensemble de
    colonnes tant qu'il a une seule ligne par ligne.
  `,
    loading: {
      title: "Téléchargement en cours...",
    },
    dropzone: {
      title: "Glissez et déposez votre fichier ici",
      description: "ou",
      button: "Parcourir les fichiers",
      enter_data_manually: "Entrer les données manuellement",
    },
  },
  match: {
    title: "Associez vos colonnes aux nôtres",
    description:
      "Nous avons pu associer {{numberOfMatchedHeaders}} des {{totalNumberOfHeaders}} colonnes correctement!",
    enum_description: `
    Notre système n'accepte que certaines valeurs pour certaines colonnes. Ci-dessous, vous pouvez
    associez les valeurs de votre tableur aux valeurs que nous acceptons.
  `,
    columns: {
      uploaded_columns: "Colonnes téléchargées",
      supported_columns: "Colonnes prises en charge",
      uploaded_values: "Valeurs téléchargées",
      supported_values: "Valeurs prises en charge",
      status: "Statut",
      placeholder: "Sélectionnez une option...",
      values_for_the_column: "Valeurs pour la colonne {{column}}",
      matched: "Assorti",
      unmatched: "Non apparié",
    },
  },
  review: {
    title: "Revoir et Envoyer",
    manual_importer_title: "Saisir Des Données et Envoyer",
    export: "Exporter",
    description:
      "Certaines cellules du document peuvent avoir des erreurs de format ou des informations manquantes. Veuillez les examiner pour continuer.",
    table_actions: {
      filters_by_rows: {
        all_rows: "Toutes les lignes",
        invalid_rows: "Lignes invalides",
        duplicates: "Doublons",
      },
      search_rows: {
        tooltip: "Rechercher quelque chose",
        placeholder: "Rechercher quelque chose...",
      },
      filter_by_errors: {
        search_or_select: "Rechercher ou sélectionner",
        filter_by_errors: "Filtrer par erreurs",
      },
      delete_rows: {
        confirmation_modal: "Voulez-vous vraiment supprimer {{length}} ligne",
        modal_title: "Supprimer la ligne sélectionnée",
        yes_delete: "Oui, supprimer",
        select_rows: "Sélectionnez les lignes à supprimer",
        delete_row: "Supprimer la ligne",
        deleted_message: "{{length}} lignes supprimées",
      },
      export_your_data: {
        tooltip: "Exporter vos données",
      },
      transformed_records: {
        auto_corrections_done: "cellules corrigées automatiquement",
        example: "Exemple",
        auto_corrections_amount:
          "Nous avons corrigé automatiquement {{amount}} problèmes.",
      },
      custom_actions: {
        label: "Plus d'actions",
        general_error_toast:
          "Il n'a pas été possible d'exécuter l'action. Veuillez réessayer.",
        error_toast: `Une erreur est survenue lors de l'exécution de l'action "{{actionName}}" sur les lignes sélectionnées. Veuillez vérifier la mise en œuvre du code et réessayer.`,
        success_toast: `L'action "{{actionName}}" a été exécutée avec succès sur les lignes sélectionnées.`,
      },
    },
    persistence: {
      loader_modal: {
        title: "Soumission de l'importation...",
        subtitle:
          "Veuillez patienter. La fenêtre se fermera une fois le traitement terminé",
        leave_warning:
          "Êtes-vous sûr de vouloir partir ? Vos modifications peuvent ne pas être enregistrées.",
      },
      response_modal: {
        success_header: "Données soumises avec succès !",
        success_default:
          "Vos données ont été soumises avec succès. Cliquez ci-dessous pour continuer.",
        error_default:
          "Certains enregistrements ont rencontré des erreurs lors de l'importation. Veuillez vérifier et soumettre à nouveau les enregistrements comportant des erreurs.",
        close_modal_button: "OK, fermer",
      },
      retry_modal: {
        title:
          "Quelque chose s'est mal passé lors de la soumission de l'importation",
        description:
          "Ne vous inquiétez pas, veuillez réessayer de soumettre l'importation.",
        cancel: "Revenir en arrière",
        try_again: "Réessayer",
      },
    },
  },
  general: {
    autocomplete: {
      placeholder: "Sélectionnez une option...",
    },
    steps: {
      upload: "Télécharger",
      match: "Correspondre",
      review: "Revoir",
    },
    stepper: {
      go_back: "Retour",
      continue: "Continuer",
      submit: "Soumettre",
    },
    unmatched_headers_modal: {
      title: "En-têtes de colonne non appariés",
      description_singular:
        "Il y a {{count}} en-têtes de colonne non appariés. Vous devrez remplir ces informations manuellement si vous continuez.",
      description_plural:
        "Il y a {{count}} en-têtes de colonne non appariés. Vous devrez remplir ces informations manuellement si vous continuez.",
      assign_headers: "Attribuer des en-têtes",
      skip: "Sauter",
    },
    submit_modal: {
      title: "Prêt à soumettre?",
      description:
        "Nous n'avons rien trouvé de mal avec votre fichier. Vous êtes prêt à partir!",
      submit: "Oui, soumettre",
      cancel: "Retour",
    },
    confirm_close_modal: {
      title: "Êtes-vous sûr?",
      description: "Fermer l'importateur fera perdre toutes les modifications.",
      close: "Fermer",
      cancel: "Annuler",
    },
    preview_mode_banner: {
      title: "Vous êtes actuellement en mode Aperçu.",
    },
    warnings_banner: {
      trial_mode: {
        info_text:
          "Ce compte est en mode d'essai. Les imports sont limités à 100 enregistrements.",
        button_text: "Sélectionner un plan",
      },
      invalid_submit_fn: {
        info_text:
          "L'envoi est désactivé. Désactivez le mode incognito ou ajoutez un hook onSubmit pour activer l'envoi.",
        button_text: "Comment faire ?",
      },
    },
  },
};

import Color from "color";

import { theme } from "../../styled/theme";
import { ImporterStylePreferences } from "fuse-importer";

export type Theme = typeof theme;

const getCustomTheme = (importerStylePreferences: ImporterStylePreferences) => {
  const {
    primary_color,
    secondary_color,
    highlight1,
    highlight2,
    background,
  } = importerStylePreferences;
  const customTheme: Theme = {
    ...theme,
    colors: {
      ...theme.colors,
      pink500: Color(primary_color).hex(),
      pink700: Color(primary_color).darken(0.2).hex(),
      black: Color(secondary_color).hex(),
      blue500: Color(secondary_color).hex(),
      blue100: Color(highlight2).hex(),
      backgroundPrimary: Color(background).hex(),
    },
  };

  return { theme: customTheme, logoURL: importerStylePreferences.logo_url };
};

export { theme, getCustomTheme };

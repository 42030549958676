import { useState } from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import { useSpreadsheetContext } from "../../../Importer";
import { useDataSetContext } from "../../../Importer/common/Spreadsheet/DataSetContextProvider";
import { useFilteredDataContext } from "../../../Importer/common/Spreadsheet/FilteredDataContextProvider";
import { useValidationContext } from "../../../Importer/common/Spreadsheet/ValidationContextProvider";
import { ConfirmationModal } from "../../../common/ConfirmationModal";
import { useEventManagerContext } from "../../../common/EventManagerContextProvider";
import { TooltipProvider } from "../../../common/TooltipProvider";
import { Div } from "../../../styled/utils";
import { addToast } from "../../Toast";
import { ActionButton } from "./TableActions.styles";
import { ConfirmationDeleteIcon, DeleteIcon } from "./icons";
import { useDataSetManipulation } from "../../../Importer/common/Spreadsheet/SpreadsheetContextProvider/useDataSetManipulation";

interface DeleteSelectedRowsProps {
  testId?: string;
}

const StyledTooltipProvider = styled(TooltipProvider)`
  width: fit-content;
`;

export const DeleteSelectedRows = ({
  testId = "ta-delete-rows",
}: DeleteSelectedRowsProps) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const {
    setFilteredInputValue,
    setRecalculateFilters,
  } = useFilteredDataContext();
  const { selectedRows, setSelectedRows } = useSpreadsheetContext();
  const {
    errors,
    warnings,
    calculateColumnErrorCounts,
    calculateColumnWarningCounts,
  } = useValidationContext();
  const { deleteRecord, emptyRecordIds } = useDataSetContext();
  const { t } = useTranslation("review");
  const { emit, EVENTS } = useEventManagerContext();
  const { deleteRecordsFromSpreadsheet } = useDataSetManipulation();

  const toggleModalVisibility = () => {
    setIsConfirmationModalOpen((oldValue) => !oldValue);
  };

  const deleteRecords = () => {
    setSelectedRows([]);
    toggleModalVisibility();
    setFilteredInputValue("");
    deleteRecordsFromSpreadsheet(selectedRows);

    addToast(
      t("table_actions.delete_rows.deleted_message", {
        length: selectedRows.length,
      }),
      "success"
    );

    emit(EVENTS.UPDATE_COLUMN_ERROR_COUNT);
    emit(EVENTS.UPDATE_TOTAL_ERROR_COUNT);
    emit(EVENTS.UPDATE_TOTAL_ROW_COUNT);
  };

  const DescriptionUI = () => (
    <>
      <Div>
        {t("table_actions.delete_rows.confirmation_modal", {
          length: selectedRows.length,
        })}
        {selectedRows.length > 1 ? "s" : ""}?
      </Div>
    </>
  );

  const confirmationModalUI = (
    <ConfirmationModal
      isOpen={isConfirmationModalOpen}
      handleClose={toggleModalVisibility}
      title={`${t("table_actions.delete_rows.modal_title")}${
        selectedRows.length > 1 ? "s" : ""
      }`}
      description={<DescriptionUI />}
      onSubmit={deleteRecords}
      buttonText={t("table_actions.delete_rows.yes_delete")}
      icon={ConfirmationDeleteIcon}
    />
  );

  const isDeleteButtonDisabled = selectedRows.length <= 0;

  return (
    <>
      {confirmationModalUI}

      <StyledTooltipProvider
        tooltip={
          isDeleteButtonDisabled
            ? t("table_actions.delete_rows.select_rows")
            : t("table_actions.delete_rows.delete_row")
        }
        placement="right"
        flip={false}
        centered
        data-testid={testId}
      >
        <ActionButton
          onClick={toggleModalVisibility}
          disabled={isDeleteButtonDisabled}
        >
          <DeleteIcon />
        </ActionButton>
      </StyledTooltipProvider>
    </>
  );
};

import styled from "styled-components";
import { Button } from "../../common";
import { Div, Span, colors } from "../../styled";
import { Error, Information, Success, Warning } from "./icons";

type BannerType = "warning" | "error" | "success" | "information";

type Theme = {
  icon: JSX.Element;
  background: string;
  border: string;
};

interface BannerProps {
  infoText: string;
  btnText: string;
  btnOnClick: () => void;
  type?: BannerType;
}

const themes: Record<BannerType, Theme> = {
  warning: {
    icon: <Warning />,
    background: colors.orange100,
    border: colors.orange1200,
  },
  error: {
    icon: <Error />,
    background: colors.red75,
    border: colors.red950,
  },
  success: {
    icon: <Success />,
    background: colors.green50,
    border: colors.green900,
  },
  information: {
    icon: <Information />,
    background: colors.blue100,
    border: colors.blue900,
  },
};

const getTheme = (type: BannerType) => themes[type];

const Container = styled(Div)<{ scheme: Theme }>`
  align-items: center;
  background: ${({ scheme }) => scheme.background};
  box-shadow: inset 0px -0.5px 0px 0px ${({ scheme }) => scheme.border};
  display: flex;
  gap: 16px;
  height: 60px;
  padding-left: 32px;
  padding-right: 32px;
`;

export const Banner = ({
  infoText,
  btnText,
  btnOnClick,
  type = "warning",
  ...props
}: BannerProps) => {
  const theme = getTheme(type);

  return (
    <Container scheme={theme} {...props}>
      <Div dflex gap={8} alignCenter>
        <Div>{theme.icon}</Div>

        <Span>{infoText}</Span>
      </Div>

      {btnText && (
        <Button
          w={120}
          br={8}
          h={36}
          variant={"secondary"}
          onClick={btnOnClick}
        >
          {btnText}
        </Button>
      )}
    </Container>
  );
};

import Form from "components/common/form/Form";
import {
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
} from "components/Routes/PrivacyAndTermsRoutes";
import { useHistory } from "react-router";
import { SIGN_IN_ROUTE } from "../..";
import { flatironsFuseLogo } from "../../common/assets/images";
import { PasswordValidation } from "../../common/PasswordValidation";
import { ExternalAuthLoginButton } from "../../common/ExternalAuthLoginButton";
import { FieldsType } from "../types";
import FieldsUI from "../FieldsUI";
import NameFieldsUI from "../NameFieldsUI";

import {
  FormContentButtonsWrapper,
  FormContentWrapper,
  FormikSumbitButton,
  LogoWrapper,
  Subtitle,
  TextLink,
  TextSignIn,
  TextTermsAndPrivacy,
  Title,
} from "../styles";

type FormUIProps = {
  fields: FieldsType;
  onSubmit: (values: any) => Promise<void>;
  isLoading: boolean;
  companyName: string;
  invitedUserEmail: string;
};

const FormUI = ({
  fields,
  onSubmit,
  isLoading,
  companyName,
  invitedUserEmail,
}: FormUIProps) => {
  const history = useHistory();

  return (
    <FormContentWrapper>
      <LogoWrapper>{flatironsFuseLogo}</LogoWrapper>
      <Title>Create an Account</Title>
      <Subtitle>
        Hey there! You’ve been invited to join <strong>{companyName}’s</strong>{" "}
        workspace. Create an account to continue.
      </Subtitle>
      <Form fields={fields} onSubmit={onSubmit}>
        <>
          <NameFieldsUI />
          <FieldsUI emailDefaultValue={invitedUserEmail} />

          <PasswordValidation />
          <FormContentButtonsWrapper>
            <FormikSumbitButton isDisabled={isLoading}>
              Sign Up
            </FormikSumbitButton>
            Or
            <ExternalAuthLoginButton type="sign-up" isDisabled={isLoading} />
          </FormContentButtonsWrapper>
          <TextTermsAndPrivacy>
            By clicking &quot;Sign Up&quot;, you agree to the
            <TextLink
              dflex
              onClick={() => window.open(TERMS_AND_CONDITIONS_ROUTE)}
            >
              Terms
            </TextLink>
            and
            <TextLink dflex onClick={() => window.open(PRIVACY_POLICY_ROUTE)}>
              Privacy Policy
            </TextLink>
          </TextTermsAndPrivacy>
          <TextSignIn>
            Already have an account?
            <TextLink onClick={() => history.push(SIGN_IN_ROUTE)}>
              Sign In
            </TextLink>
          </TextSignIn>
        </>
      </Form>
    </FormContentWrapper>
  );
};

export default FormUI;

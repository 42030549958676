import { useUserContext } from "components/UserContextProvider";
import { Div } from "fuse-shared-ui";
import styled from "styled-components";
import { useBillingContext } from "../BillingContextProvider";
import { BillingSection } from "../common";
import TrialModeBanner from "./TrialModeBanner";

const PlanTitle = styled(Div)`
  text-transform: capitalize;
`;

type CurrentPlanInfoProps = {
  formattedPriceId?: string;
};

const CurrentPlanInfo = ({
  formattedPriceId,
}: CurrentPlanInfoProps): JSX.Element => {
  const {
    user: { active_organization },
  } = useUserContext();

  const {
    plan: { title },
    trial_mode,
  } = active_organization;

  const formattedPlanTitle = title.split("_")[0];
  const {
    subscriptionData,
    currentPlanPrice,
    currentStripePlan,
  } = useBillingContext();

  return (
    <>
      {subscriptionData && (
        <BillingSection spaceBetween>
          <Div flexColumn justifyCenter>
            <Div label2>YOUR PLAN</Div>
            <PlanTitle data-cy="selectedPlan" h5 mt={12}>
              {trial_mode ? "Test Mode" : formattedPlanTitle}
            </PlanTitle>
          </Div>
          <Div justifyEnd flexColumn wbody4>
            {trial_mode ? (
              "Free"
            ) : (
              <Div wbody4 dflex>
                <Div data-testid={formattedPriceId}>
                  ${currentPlanPrice.toFixed(2).replace(/[.,]00$/, "")}
                </Div>
                <Div wbody3>/{currentStripePlan?.interval}</Div>
              </Div>
            )}
          </Div>
        </BillingSection>
      )}
    </>
  );
};

const CurrentPlan = ({
  formattedPriceId,
}: CurrentPlanInfoProps): JSX.Element => {
  const {
    user: { active_organization },
  } = useUserContext();
  return (
    <>
      {active_organization.trial_mode ? (
        <TrialModeBanner />
      ) : (
        <CurrentPlanInfo formattedPriceId={formattedPriceId} />
      )}
    </>
  );
};

export default CurrentPlan;

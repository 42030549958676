import { Importer, ImporterProps } from "fuse-shared-ui";
import { en } from "fuse-shared-ui";
import { useEffect, useState } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { CustomAction } from "fuse-importer";

// used for testing importer component with cypress without iframe
const ImporterTest = () => {
  const [apiToken, setApiToken] = useState("");
  const [templateSlug, setTemplateSlug] = useState("");
  const [isShowingImporter, setIsShowingImporter] = useState(false);

  const [isI18nReady, setIsI18nReady] = useState(false);

  useEffect(() => {
    const initI18n = async () => {
      const language = "en";

      await i18n.use(initReactI18next).init({
        lng: language,
        fallbackLng: "en",
        resources: { en },
      });
      setIsI18nReady(true);
    };
    initI18n();
  }, []);

  const formatRecord = (record) => {
    if (window["formatRecord"]) return window["formatRecord"](record);
    const newRecord = { ...record };

    return newRecord;
  };

  const customActions: CustomAction[] = [
    {
      actionType: "record",
      name: "Change first column to FirstTestE2E",
      handler: (record) => {
        const columns = Object.keys(record);
        const firstColumn = columns.filter((column) => column !== "_meta")[0];

        record[firstColumn] = "FirstTestE2E";

        return record;
      },
    },
    {
      actionType: "record",
      name: "Change first column to SecondTestE2E",
      handler: (record) => {
        const columns = Object.keys(record);
        const secondColumn = columns.filter((column) => column !== "_meta")[0];

        record[secondColumn] = "SecondTestE2E";

        return record;
      },
    },
    {
      actionType: "record",
      name: "Throw error on second column",
      handler: (record) => {
        const columns = Object.keys(record);
        const secondColumn = columns.filter((column) => column !== "_meta")[1];

        record[secondColumn] = "Invalid value";

        return record;
      },
    },
  ];

  const onValidateRecord = async (record) => {
    if (window["onValidateRecord"]) return window["onValidateRecord"](record);

    return { errors: {}, warnings: {} };
  };

  const onSubmit = async (records) => {
    if (window["onSubmit"]) {
      const result = window["onSubmit"](records);

      return result;
    }

    return {
      message: "Custom Test Success Message",
      errors: {},
    };
  };

  const showImporter = () => {
    if (!apiToken || !templateSlug) return;
    setIsShowingImporter(true);
  };

  const onClose = () => {
    setIsShowingImporter(false);
  };

  const importerProps: ImporterProps = {
    apiToken,
    templateSlug,
    options: {
      env: "local",
      zIndex: 1000,
      cypress: true,
      batchValidationDelayMs: 500,
    },
    dynamicColumns: [],
    customActions,
    onSubmit,
    formatRecord,
    onValidateRecord,
    onClose,
  };

  return (
    <>
      {isShowingImporter && isI18nReady && (
        <div id="fuse-importer-root">
          <Importer {...importerProps} />
        </div>
      )}
      <div style={{ fontSize: "14px", margin: "100px" }}>
        <div>
          <div style={{ width: "100px" }}>Api Key:</div>
          <input
            id="organization-api-key"
            style={{ width: "400px" }}
            value={apiToken}
            onChange={({ target: { value } }) => setApiToken(value)}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          <div style={{ width: "100px" }}>Importer Slug:</div>
          <input
            style={{ width: "400px" }}
            value={templateSlug}
            id="template-slug"
            onChange={({ target: { value } }) => setTemplateSlug(value)}
          />
        </div>
        <button onClick={showImporter}>Show Importer</button>
      </div>
    </>
  );
};

export default ImporterTest;

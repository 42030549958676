import { FieldArray, useFormikContext } from "formik";
import { Div, TooltipProvider } from "fuse-shared-ui";
import styled from "styled-components";
import { FormikTextInput } from "components/common/form/fields";

const RemoveItemIcon = styled(Div)`
  box-sizing: border-box;
  background: transparent;
  display: flex;
  width: 24px;
  height: 24px;
  border: 2px solid ${(p) => p.theme.colors.gray300};
  border-radius: 2em;
  justify-content: center;
  margin: 0 0px;
`;

const AddItemIcon = styled(Div)`
  border: 2px solid ${(p) => p.theme.colors.black};
  box-sizing: border-box;
  border-radius: 24px;
  color: ${(p) => p.theme.colors.black};
  ${(props) => props.theme.textStyles.wbody3}
  font-weight: bold;
  letter-spacing: 0.02em;
  justify-content: space-between;
  height: 36px;
  width: 92px;
  padding: 6px 20px;
`;

const DropdownValueFields = () => {
  const {
    values: { values: structuredDataOptions },
  } = useFormikContext<any>();

  const fieldArrayRender = ({ push, remove }) => {
    const RemoveItemButton = ({ index }) => (
      <>
        {structuredDataOptions.length > 1 && (
          <Div alignCenter>
            <TooltipProvider tooltip="Remove value" placement="top" centered>
              <Div clickable alignCenter ml={16} onClick={() => remove(index)}>
                <RemoveItemIcon mt={5}>
                  <Div>{minusIcon}</Div>
                </RemoveItemIcon>
              </Div>
            </TooltipProvider>
          </Div>
        )}
      </>
    );

    return (
      <>
        {structuredDataOptions?.map((value, index) => {
          return (
            <Div key={index} dflex mb={24}>
              <FormikTextInput
                placeholder="Enter Value"
                data-cy="dropdownField"
                name={`values.${index}`}
              />
              <Div alignCenter h={68}>
                <RemoveItemButton index={index} />
              </Div>
            </Div>
          );
        })}
        <AddItemIcon
          data-cy="addButton"
          dflex
          clickable
          onClick={() => {
            push("");
          }}
        >
          Add {plusIcon}
        </AddItemIcon>
      </>
    );
  };

  return (
    <>
      <Div dflex flexColumn ml={40}>
        <Div data-cy="dropdownLabel" label2 mt={24} mb={16}>
          Dropdown Values
        </Div>
        {/* @ts-ignore */}
        <FieldArray name="values" render={fieldArrayRender} />
      </Div>
    </>
  );
};

export default DropdownValueFields;

const plusIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2V14"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 8L14 8"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const minusIcon = (
  <svg
    width="14"
    height="7"
    viewBox="0 0 14 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L13 1"
      stroke="#cac4bf"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

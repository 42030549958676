import {
  yupEmail,
  yupPassword,
  yupPasswordConfirmation,
  yupRequiredWithLabel,
} from "../../../../common/form/validations";

export const nameFields = [
  {
    label: "First Name*",
    name: "first_name",
    placeholder: "Enter your first name",
    initialValue: "",
    validation: yupRequiredWithLabel("First name"),
  },
  {
    label: "Last Name*",
    name: "last_name",
    placeholder: "Enter your last name",
    initialValue: "",
    validation: yupRequiredWithLabel("Last name"),
  },
];

export const emailField = {
  label: "Email Address*",
  name: "email",
  placeholder: "Enter your email address",
  initialValue: "",
  validation: yupEmail,
};

export const passwordFields = [
  {
    label: "Current Password",
    name: "current_password",
    placeholder: "Enter your current password",
    initialValue: "",
    inputProps: {
      type: "password",
    },
    validation: yupPassword,
  },
  {
    label: "New Password",
    name: "password",
    placeholder: "Enter a new password",
    initialValue: "",
    inputProps: {
      type: "password",
    },
    validation: yupPassword,
  },
  {
    label: "Confirm New Password",
    name: "password_confirmation",
    placeholder: "Confirm new password",
    initialValue: "",
    inputProps: {
      type: "password",
    },
    validation: yupPasswordConfirmation,
  },
];

export const fields = [emailField, ...passwordFields, ...nameFields];

import { Autocomplete } from "../../../common/inputs/Autocomplete";
import { Div } from "../../../styled/utils";
import { useImporterContext } from "../../contexts/ImporterContextProvider";
import { MatchedIndicatorUI, UnmatchedIndicatorUI } from "../common/index";
import { MatcherStatus } from "../common/types";

export const tableColumns = [
  {
    header: "match:columns.uploaded_columns",
    width: 1,
    Content: ({ yourColumns, ourColumns }) => {
      const {
        templateHeaderMatchings,
        setTemplateHeaderMatchings,
        uploadedDataHeaders,
      } = useImporterContext();

      const onChange = (selected) => {
        const newTemplateHeaderMatchings = { ...templateHeaderMatchings };
        newTemplateHeaderMatchings[ourColumns] = selected;
        setTemplateHeaderMatchings(newTemplateHeaderMatchings);
      };

      return (
        <Autocomplete
          w="92%"
          onChange={onChange}
          value={yourColumns}
          placement="bottom"
          id={`your-column-${ourColumns}`}
          options={uploadedDataHeaders.map((h) => ({
            value: h,
            label: h,
          }))}
        />
      );
    },
  },
  {
    header: "match:columns.supported_columns",
    width: 1,
    Content: ({ ourColumns, ourColumnsDescription, rowIndex }) => {
      const { templateHeaders } = useImporterContext();
      const isRequired = templateHeaders.find((th) => th.label === ourColumns)
        ?.required;
      return (
        <Div body1 id={`our-column-${ourColumns}`}>
          {ourColumns}
          {isRequired ? "*" : ""}
          <Div
            helperText
            opacity5
            truncateText
            data-cy={`row-${rowIndex}-importer-description`}
          >
            {ourColumnsDescription}
          </Div>
        </Div>
      );
    },
  },
  {
    header: "match:columns.status",
    width: 1,
    Content: ({ status }) => {
      return (
        <>
          {status === MatcherStatus.matched ? (
            <MatchedIndicatorUI />
          ) : (
            <UnmatchedIndicatorUI />
          )}
        </>
      );
    },
  },
];

import {
  Field,
  FieldTransformation,
  Record,
  TransformedRecord,
} from "fuse-importer";
import { fieldTransformations } from "./transformators";

type TransformRecordArgs = {
  record: Record;
  fields: Field[];
};

const runTransformation = (
  fieldValue: any,
  transformation: FieldTransformation,
  field: Field
) => {
  const transformationType = transformation.transformation_type;
  const transformerFunction = fieldTransformations[transformationType];
  return transformerFunction(fieldValue, transformation, field);
};

export const transformRecord = ({ record, fields }: TransformRecordArgs) => {
  const transformedRecords: TransformedRecord = {} as TransformedRecord;

  for (const currentField of fields) {
    const fieldTransformations = currentField.transformations;
    const fieldToBeTransformed = record[currentField.name];

    if (
      !fieldTransformations ||
      fieldTransformations.length === 0 ||
      fieldToBeTransformed === undefined
    ) {
      continue;
    }

    let transformedField = {
      original: fieldToBeTransformed,
      transformed: fieldToBeTransformed,
    };

    let fieldWasTransformed = false;

    for (let i = 0; i < fieldTransformations.length; i++) {
      const currentTransformation = fieldTransformations[i];

      const transformed = runTransformation(
        transformedField.transformed,
        currentTransformation,
        currentField
      );

      if (transformed.transformationWasNeeded) {
        fieldWasTransformed = true;
        transformedField = {
          original: transformedField.original,
          transformed: transformed.transformed,
        };
      }
    }

    if (!fieldWasTransformed) {
      continue;
    }

    // the data have changed
    record[currentField.name] = transformedField.transformed;

    transformedRecords[currentField.name] = {
      original: transformedField.original,
      transformed: transformedField.transformed,
    };
  }

  const isTransformedRecordsEmpty =
    Object.keys(transformedRecords).length === 0;

  return isTransformedRecordsEmpty ? {} : transformedRecords;
};

import { useState } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { api, Div } from "fuse-shared-ui";
import Form, {
  FormikSumbitButton as FormikSumbitButtonBase,
} from "components/common/form/Form";
import { FormikTextInput } from "components/common/form/fields";
import {
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
} from "components/Routes/PrivacyAndTermsRoutes";
import { SIGN_IN_ROUTE } from "..";
import { GithubLoginButton as GithubLoginButtonBase } from "../common/GithubLoginButton";
import {
  flatironsFuseLogo,
  background1,
  elipse1,
  rectangle1,
  circle1,
  circle2,
  elipse2,
} from "../common/assets/images";
import { fields, nameFields, textFields } from "./fields";
import { PasswordValidation } from "../common/PasswordValidation";
import { useSegment } from "components/SegmentContextProvider";
import { ExternalAuthLoginButton } from "../common/ExternalAuthLoginButton";
import { URLParams } from "components/Routes/AccountRoutes/TemplateRoutes/TemplateColumns/types";

const Wrapper = styled(Div)`
  ${(props) => {
    const c = props.theme.css;
    return [c.grow, c.justifyCenter, c.yscroll, c.pRelative];
  }};
`;

const PreContentContainer = styled(Div)`
  position: relative;
  margin: 80px 160px;
  min-height: 1052px;
  max-width: 1120px;
  width: 100%;
  @media all and (max-width: 980px) {
    margin: 80px;
  }
  @media all and (max-width: 840px) {
    margin: 24px;
  }
`;

const ContentContainer = styled(Div)`
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 40px rgba(135, 70, 70, 0.08);
`;

const FormContentWrapper = styled(Div)`
  padding: 64px;
  max-width: 560px;
  @media all and (max-width: 1220px) {
    max-width: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media all and (max-width: 840px) {
    padding: 24px;
  }
`;

const LogoWrapper = styled(Div)`
  width: 100%;
  @media all and (max-width: 1220px) {
    display: flex;
    justify-content: center;
  }
`;

const Title = styled(Div)`
  margin-top: 40px;
  ${(props) => props.theme.textStyles.wh1};
  color: ${(props) => props.theme.colors.black};
  @media all and (max-width: 1220px) {
    text-align: center;
  }
`;

const FormContentNameFieldsWrapper = styled(Div)`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 720px) {
    flex-direction: column;
    & > div:not(:first-child) {
      margin-top: 24px;
    }
  }
`;

const FormContentItem = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media all and (max-width: 720px) {
    max-width: unset;
    display: block;
    margin-right: unset;
    margin-left: unset;
  }
`;

const FormikSumbitButton = styled(FormikSumbitButtonBase)`
  margin-right: 16px;
  @media all and (max-width: 900px) {
    margin-right: unset;
    margin-bottom: 16px;
  }
`;

const StyledExternalAuthLoginButton = styled(ExternalAuthLoginButton)`
  margin-left: 16px;
  @media all and (max-width: 900px) {
    margin-left: unset;
    margin-top: 16px;
  }
`;

const FormContentButtonsWrapper = styled(Div)`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 900px) {
    flex-direction: column;
  }
`;

const BackgroundImage1 = styled(Div)`
  position: absolute;
  right: -68px;
  top: -33px;
  @media all and (max-width: 1220px) {
    display: none;
  }
`;

const ImagesContainer = styled(Div)`
  @media all and (max-width: 1220px) {
    display: none;
  }
`;

const Elipse1 = styled(Div)`
  margin-top: 134px;
  margin-right: -1px;
  position: absolute;
  right: 0;
  top: 0;
`;

const Elipse2 = styled(Div)`
  margin-top: 302px;
  margin-right: -70px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 85%;
`;

const Rectangle1 = styled(Div)`
  margin-top: 302px;
  position: absolute;
  right: 0;
  top: 0;
`;

const Circle1 = styled(Div)`
  margin-top: 339.79px;
  margin-right: 32.21px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  opacity: 50%;
`;

const Circle2 = styled(Div)`
  margin-top: 375.66px;
  margin-right: 74.45px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  opacity: 50%;
`;

const TextTermsAndPrivacy = styled(Div)`
  margin-top: 24px;
  color: ${(props) => props.theme.colors.textSecondary};
  ${(props) => props.theme.textStyles.wbody3};
  display: flex;
  & > div {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  @media all and (max-width: 900px) {
    justify-content: center;
    flex-wrap: wrap;
    white-space: nowrap;
  }
`;

const TextLink = styled(Div)`
  margin: 0 5px;
  ${(props) => {
    const c = props.theme.css;
    return [c.link, c.justifyEnd];
  }};
  color: ${(props) => props.theme.colors.pink500};
`;

const TextSignIn = styled(Div)`
  margin-top: 40px;
  display: flex;
  color: ${(props) => props.theme.colors.textSecondary};
  ${(props) => props.theme.textStyles.wlink};
  :hover {
    cursor: text;
    text-decoration-line: none;
    opacity: unset;
  }
  & > div {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  @media all and (max-width: 720px) {
    justify-content: center;
  }
`;

type SignUpFormProps = {
  onCompleteForm: (email: string) => void;
};

const SignUpForm = ({ onCompleteForm }: SignUpFormProps) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const { identify, track } = useSegment();

  const { auth_provider: authProvider } = useParams<URLParams>();

  const onSubmit = async (values) => {
    setIsLoading(true);
    const { company_name, email, first_name, last_name } = values;
    const trackData = {
      company_name,
      email,
      name: `${first_name} ${last_name}`,
      source: "fuse_registration",
    };
    try {
      const {
        data: { id },
      } = await api.post("/users/registrations", values);
      track("user_registered", trackData);
      identify(id, trackData);
      onCompleteForm(values.email);
    } catch (err) {
      track("user_registration_failed", {
        ...trackData,
        error: err?.response?.data?.message,
      });
      console.error(err);
    }
    setIsLoading(false);
  };

  const nameFieldsUI = (
    <FormContentNameFieldsWrapper mt={40}>
      <FormContentItem w50 mr={12}>
        <FormikTextInput
          {...{
            label: nameFields[0].label,
            placeholder: nameFields[0].placeholder,
            name: nameFields[0].name,
          }}
        />
      </FormContentItem>
      <FormContentItem w50 ml={12}>
        <FormikTextInput
          {...{
            label: nameFields[1].label,
            placeholder: nameFields[1].placeholder,
            name: nameFields[1].name,
          }}
        />
      </FormContentItem>
    </FormContentNameFieldsWrapper>
  );

  const fieldsUI = (
    <>
      {textFields.map(({ name, label, placeholder, inputProps }) => (
        <FormContentItem key={name} mt={24}>
          <FormikTextInput
            {...{ label, placeholder, name }}
            type={inputProps?.type || "text"}
          />
        </FormContentItem>
      ))}
    </>
  );

  const formUI = (
    <FormContentWrapper>
      <LogoWrapper>{flatironsFuseLogo}</LogoWrapper>
      <Title data-cy="sign-up-title">Create an Account</Title>
      <Form fields={fields} onSubmit={onSubmit} id="sign-up">
        <>
          {nameFieldsUI}
          {fieldsUI}

          <PasswordValidation />
          <FormContentButtonsWrapper>
            <FormikSumbitButton
              isDisabled={isLoading}
              isButtonElement
              data-cy="SignUp"
            >
              Sign Up
            </FormikSumbitButton>
            Or
            <StyledExternalAuthLoginButton
              type="sign-up"
              provider={authProvider}
              isDisabled={isLoading}
            />
          </FormContentButtonsWrapper>
          <TextTermsAndPrivacy>
            By clicking &quot;Sign Up&quot;, you agree to the
            <TextLink
              data-cy="terms-condition"
              dflex
              onClick={() => window.open(TERMS_AND_CONDITIONS_ROUTE)}
            >
              Terms
            </TextLink>
            and
            <TextLink
              data-cy="privacy-policy"
              dflex
              onClick={() => window.open(PRIVACY_POLICY_ROUTE)}
            >
              Privacy Policy
            </TextLink>
          </TextTermsAndPrivacy>
          <TextSignIn data-cy="already-have-account">
            Already have an account?
            <TextLink
              data-cy="sign-in"
              onClick={() => {
                const route = authProvider
                  ? `${SIGN_IN_ROUTE}/${authProvider}`
                  : SIGN_IN_ROUTE;
                history.push(route);
              }}
            >
              Sign In
            </TextLink>
          </TextSignIn>
        </>
      </Form>
    </FormContentWrapper>
  );
  const imagesUI = (
    <ImagesContainer>
      <Elipse1>{elipse1}</Elipse1>
      <Elipse2>{elipse2}</Elipse2>
      <Rectangle1>{rectangle1}</Rectangle1>
      <Circle1>{circle1}</Circle1>
      <Circle2>{circle2}</Circle2>
    </ImagesContainer>
  );

  return (
    <Wrapper>
      <PreContentContainer>
        <ContentContainer br={16}>
          {formUI}
          {imagesUI}
        </ContentContainer>
        <BackgroundImage1>{background1}</BackgroundImage1>
      </PreContentContainer>
    </Wrapper>
  );
};

export default SignUpForm;

import styled from "styled-components";
import { Div, P } from "fuse-shared-ui";

const Badge = styled(Div)<{ fillColor: string }>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 24px;
  border: 1px solid;
  border-color: ${({ fillColor }) => fillColor};
`;

const DangerIcon = ({ fillColor }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5ZM4.05806 4.05806C4.30214 3.81398 4.69786 3.81398 4.94194 4.05806L6 5.11612L7.05806 4.05806C7.30214 3.81398 7.69786 3.81398 7.94194 4.05806C8.18602 4.30214 8.18602 4.69786 7.94194 4.94194L6.88388 6L7.94194 7.05806C8.18602 7.30214 8.18602 7.69786 7.94194 7.94194C7.69786 8.18602 7.30214 8.18602 7.05806 7.94194L6 6.88388L4.94194 7.94194C4.69786 8.18602 4.30214 8.18602 4.05806 7.94194C3.81398 7.69786 3.81398 7.30214 4.05806 7.05806L5.11612 6L4.05806 4.94194C3.81398 4.69786 3.81398 4.30214 4.05806 4.05806Z"
      fill={fillColor}
    />
  </svg>
);

const WarningIcon = ({ fillColor }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5ZM6 2.875C6.34518 2.875 6.625 3.15482 6.625 3.5V6C6.625 6.34518 6.34518 6.625 6 6.625C5.65482 6.625 5.375 6.34518 5.375 6V3.5C5.375 3.15482 5.65482 2.875 6 2.875ZM6 7.375C6.34518 7.375 6.625 7.65482 6.625 8V8.25C6.625 8.59518 6.34518 8.875 6 8.875C5.65482 8.875 5.375 8.59518 5.375 8.25V8C5.375 7.65482 5.65482 7.375 6 7.375Z"
      fill={fillColor}
    />
  </svg>
);

const SuccessIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5ZM4.94194 5.53291C4.69786 5.28883 4.30214 5.28883 4.05806 5.53291C3.81398 5.77699 3.81398 6.17272 4.05806 6.4168L5.11872 7.47746C5.3628 7.72153 5.75852 7.72153 6.0026 7.47746L8.12392 5.35613C8.368 5.11206 8.368 4.71633 8.12392 4.47225C7.87984 4.22817 7.48412 4.22817 7.24004 4.47225L5.56066 6.15163L4.94194 5.53291Z"
      fill="#00D085"
    />
  </svg>
);

export type PillType = "error" | "alert" | "success";

type PillProps = {
  status: string;
  variant: PillType;
  customIcon?: () => JSX.Element | null;
};

const getIconAndColor = (status: PillType) => {
  switch (status) {
    case "error": {
      return { icon: DangerIcon, color: "#F44B38" };
    }
    case "alert": {
      return { icon: WarningIcon, color: "#FF9A34" };
    }
    case "success": {
      return { icon: SuccessIcon, color: "#00D085" };
    }
    default: {
      return { icon: WarningIcon, color: "#FF9A34" };
    }
  }
};

export const Pill = ({
  status,
  variant,
  customIcon: CustomIcon,
}: PillProps) => {
  const { color, icon: Icon } = getIconAndColor(variant);
  return (
    <Badge fillColor={color}>
      {CustomIcon !== null && CustomIcon ? (
        <CustomIcon />
      ) : (
        <Icon fillColor={color} />
      )}

      <P m={0} label3>
        {status}
      </P>
    </Badge>
  );
};

import { Placement } from "@popperjs/core";
import React, { useMemo, useState } from "react";
import { usePopper } from "react-popper";
import styled, { css } from "styled-components";
import { Div, DivCSSProps } from "../styled/utils";

type TooltipContentProps = {
  isActionable?: boolean;
  shouldCenterText?: boolean;
};
export const TooltipContent = styled(Div)<TooltipContentProps>`
  ${(p) => p.theme.css.body4};
  color: ${(p) => p.theme.colors.white};
  background: ${(p) => p.theme.colors.black};
  border-radius: 4px;
  padding: 10px;
  pointer-events: none;
  z-index: 1000;

  ${({ shouldCenterText }) =>
    shouldCenterText &&
    css`
      text-align: center;
    `}
`;

export type TooltipProps = {
  tooltip: JSX.Element | string;
  placement?: Placement;
  flip?: boolean;
  isDisabled?: boolean;
  isOpen?: boolean;
  tooltipMaxWidth?: number;
  tooltipMinWidth?: number;
  offset?: number[];
  shouldCenterText?: boolean;
  icon?: JSX.Element;
} & DivCSSProps;

export const TooltipProvider: React.FC<TooltipProps> = ({
  tooltip,
  children,
  tooltipMaxWidth,
  tooltipMinWidth,
  placement = "top-start",
  flip = true,
  isDisabled = false,
  isOpen = false,
  offset = [0, 4],
  shouldCenterText = false,
  ...props
}) => {
  const [isDisplayingTooltip, setIsDisplayingTooltip] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const options = useMemo(
    () => ({
      placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: offset,
          },
        },
        {
          name: "flip",
          enabled: flip,
        },
      ],
    }),
    [placement, offset]
  );

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    options
  );

  const showTooltip = isOpen || (isDisplayingTooltip && !!tooltip);

  return (
    <Div
      dim={"100%"}
      ref={setReferenceElement}
      onMouseOver={() => isDisabled || setIsDisplayingTooltip(true)}
      onMouseOut={() => isDisabled || setIsDisplayingTooltip(false)}
      {...props}
    >
      {children}
      {showTooltip && (
        <TooltipContent
          data-cy="tool-tip-content"
          ref={setPopperElement}
          style={styles.popper}
          maxw={tooltipMaxWidth}
          minw={tooltipMinWidth}
          shouldCenterText={shouldCenterText}
          {...attributes.popper}
        >
          {tooltip}
        </TooltipContent>
      )}
    </Div>
  );
};

export const DragAndDropSvg = () => (
  <svg
    width="160"
    height="96"
    viewBox="0 0 160 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10_43515)">
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M116 93.183C116 94.7388 114.739 96 113.183 96L46.817 96C45.2612 96 44 94.7388 44 93.183L44 2.81705C44 1.26125 45.2612 1.67559e-05 46.8171 1.68908e-05L78.8547 4.4093e-06C79.5891 4.47296e-06 80.2944 0.286771 80.8204 0.799199L115.149 34.2404C115.693 34.7707 116 35.4984 116 36.2583L116 93.183Z"
          fill="url(#paint0_linear_10_43515)"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M79.5742 0.00195312L114.931 34.3586H81.4522C80.415 34.3586 79.5742 33.5178 79.5742 32.4806L79.5742 0.00195312Z"
          fill="#6FE7E2"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M69.1452 79.4867C69.3821 80.371 68.8574 81.28 67.9731 81.5169L20.9472 94.1174C20.0629 94.3544 19.154 93.8296 18.9171 92.9453L1.14015 26.601C0.903207 25.7167 1.42799 24.8078 2.31228 24.5708L25.1565 18.4497C25.5844 18.335 26.0403 18.3964 26.4227 18.6201L57.159 36.6034C57.5356 36.8238 57.8101 37.1837 57.9231 37.6051L69.1452 79.4867Z"
          fill="url(#paint1_linear_10_43515)"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M25.5293 18.3496L56.864 36.6299L33.7132 42.8332C32.8766 43.0574 32.0167 42.5609 31.7925 41.7243L25.5293 18.3496Z"
          fill="#E8CF81"
        />
      </g>
      <g style={{ mixBlendMode: "darken" }}>
        <path
          d="M144.089 93.3915C143.85 94.2831 142.933 94.8122 142.042 94.5733L95.0425 81.9798C94.1509 81.7409 93.6218 80.8245 93.8607 79.9329L111.631 13.615C111.869 12.7234 112.786 12.1943 113.677 12.4332L136.503 18.5493C136.934 18.6649 137.302 18.9483 137.523 19.3364L155.144 50.2687C155.362 50.6509 155.42 51.1036 155.306 51.5286L144.089 93.3915Z"
          fill="url(#paint2_linear_10_43515)"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M137.176 18.7305L155.172 50.229L131.762 43.9562C131.069 43.7705 130.657 43.0581 130.843 42.365L137.176 18.7305Z"
          fill="#68CECA"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_10_43515"
        x1="80"
        y1="96"
        x2="101.2"
        y2="10.6413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24D2EA" />
        <stop offset="1" stopColor="#10F9EB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_10_43515"
        x1="39.368"
        y1="18.4264"
        x2="-7.28222"
        y2="55.6751"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0A617" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_10_43515"
        x1="118.542"
        y1="88.2766"
        x2="170.717"
        y2="13.3583"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#006BA8" />
        <stop offset="1" stopColor="#01FFD7" />
      </linearGradient>
      <clipPath id="clip0_10_43515">
        <rect width="160" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const UploadingIndicator = () => (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M1 79.3761L95.7523 79.3762L48.3762 32L1 79.3761Z"
        fill="url(#paint0_linear_1630_46301)"
      />
    </g>
    <g>
      <path
        d="M1 55.3761L95.7523 55.3762L48.3762 8L1 55.3761Z"
        fill="url(#paint1_linear_1630_46301)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1630_46301"
        x1="48.3761"
        y1="32"
        x2="40.1872"
        y2="94.8016"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#88CDF3" />
        <stop offset="1" stopColor="#00C7A8" stopOpacity="0.895833" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1630_46301"
        x1="78.6058"
        y1="-24.6165"
        x2="64.3686"
        y2="81.3108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0098B9" />
        <stop offset="1" stopColor="#00A8CD" stopOpacity="0.895833" />
      </linearGradient>
    </defs>
  </svg>
);

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../common";
import { Div } from "../../styled";
import { submitIcon } from "../Stepper/common/icons";
import { usePersistenceContext } from "../common/Spreadsheet/PersistenceContextProvider";
import { SpreadsheetSubmissionModalProps } from "./SpreadsheetSubmissionModal";

type SubmissionModalProps = Pick<
  SpreadsheetSubmissionModalProps,
  "onCloseModal"
>;

interface SubmitModalProps extends SubmissionModalProps {
  callNextModal: () => void;
  onSubmit: () => void;
}

export const SubmitModal = ({
  onCloseModal,
  onSubmit,
  callNextModal,
}: SubmitModalProps) => {
  const { importer, isCloseRetryState } = usePersistenceContext();

  const { t } = useTranslation("general");

  useEffect(() => {
    if (importer?.current?.persistence && !isCloseRetryState.current) {
      callNextModal();
    }
  }, [importer.current, isCloseRetryState.current]);

  return (
    <Div centered flexColumn p={60}>
      <Div>{submitIcon}</Div>
      <Div h5 mt={20} data-cy="submit-modal-title">
        {t("submit_modal.title")}
      </Div>
      <Div
        body1
        mt={12}
        w={448}
        textAlignCenter
        data-cy="submit-modal-description"
      >
        {t("submit_modal.description")}
      </Div>
      <Div dflex mt={40} w100 centered>
        <Button
          w={115}
          variant="secondary"
          mr={10}
          onClick={() => onCloseModal()}
          data-test-id="submit-modal-go-back"
        >
          {t("submit_modal.cancel")}
        </Button>
        <Button data-cy="yes-submit" w={140} ml={10} onClick={onSubmit}>
          {t("submit_modal.submit")}
        </Button>
      </Div>
    </Div>
  );
};

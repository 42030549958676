export enum PlanOptions {
  standard = "standard",
  professional = "professional",
  enterprise = "enterprise",
}

export const specs = {
  [PlanOptions.standard]: [
    "1 importer",
    "Unlimited Team Members",
    "Fully Featured",
  ],
  [PlanOptions.professional]: [
    "Unlimited Importers",
    "Unlimited Team Members",
    "Fully Featured",
    "White Labelling",
  ],
  [PlanOptions.enterprise]: [
    "Custom Data Retention",
    "SSO/SAML",
    "Custom Agreements",
    "Service Level Agreement",
  ],
};

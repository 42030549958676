import { getScrollBarCss } from "components/common/styles";
import { BaseButton, Div } from "fuse-shared-ui";
import styled, { css } from "styled-components";

type LimitedWidthProps = {
  isRequired?: boolean;
};

export const GenericPill = styled(Div)`
  ${({ theme }) => theme.textStyles.wlabel3};
  align-items: center;
  background: ${({ theme }) => theme.colors.white50};
  border-radius: 24px;
  display: flex;
  gap: 8px;
  letter-spacing: 1px;
  overflow: hidden;
  padding: 4px 8px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  display: inline-block;
`;

export const RequiredPill = styled(GenericPill)`
  background: ${({ theme }) => theme.colors.blue50};
`;

export const PillListingContainer = styled(Div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px 9px;
  max-width: 411px;
  max-height: 60px;
  overflow: auto;
  ${getScrollBarCss()}
`;

export const TableActionsWrapper = styled(Div)`
  height: 100%;
  opacity: 0;
  visibility: hidden;
`;

export const ActionsContainer = styled(Div)`
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 5.15%);
  height: 98%;
  align-self: center;
`;

export const ActionWrapper = styled(Div)`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  width: 100%;
`;

export const EditButton = styled(Div)`
  border-radius: 4px;
  padding: 6px;

  :hover {
    background-color: ${(props) => props.theme.colors.blue100};
    cursor: pointer;
  }
`;

export const ReorderIcon = styled(BaseButton)`
  border-radius: 4px;
  padding: 6px;

  :hover {
    background-color: ${(props) => props.theme.colors.blue100};
    cursor: pointer;
  }
`;

export const DeleteButton = styled(BaseButton)`
  border-radius: 4px;
  padding: 6px;

  :hover {
    background-color: ${(props) => props.theme.colors.blue100};
    cursor: pointer;
  }

  :disabled {
    opacity: 0.6;

    :hover {
      background-color: transparent !important;
      cursor: not-allowed;
    }
  }
`;

export const Link = styled(Div)`
  font-weight: 800;
  margin-left: 15px;
`;

export const LimitedWidthBase = styled(Div)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const limitedWidthMixin = (width: number, isRequired: boolean) => css`
  max-width: calc(${width}px - ${isRequired ? 80 : 0}px);
`;

export const LabelWithLimitedWidth = styled(LimitedWidthBase)<
  LimitedWidthProps
>`
  ${({ isRequired }) => limitedWidthMixin(390, isRequired)}

  @media screen and (max-width: 2350px) {
    ${({ isRequired }) => limitedWidthMixin(300, isRequired)}
  }

  @media screen and (max-width: 1920px) {
    ${({ isRequired }) => limitedWidthMixin(235, isRequired)}
  }

  @media screen and (max-width: 1590px) {
    ${({ isRequired }) => limitedWidthMixin(190, isRequired)}
  }
`;

export const KeyWithLimitedWidth = styled(LimitedWidthBase)`
  max-width: 365px;

  @media screen and (max-width: 2350px) {
    max-width: 275px;
  }

  @media screen and (max-width: 1920px) {
    max-width: 210px;
  }

  @media screen and (max-width: 1590px) {
    max-width: 135px;
  }
`;

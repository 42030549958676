import { useParams } from "react-router";
import { Button, Div, Span } from "fuse-shared-ui";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import {
  PRIVACY_POLICY_ROUTE,
  TERMS_AND_CONDITIONS_ROUTE,
} from "components/Routes/PrivacyAndTermsRoutes";
import { flatironsFuseLogo } from "./common/assets/images";
import { URLParams } from "../AccountRoutes/TemplateRoutes/TemplateColumns/types";
import { capitalize } from "utils";

const Wrapper = styled(Div)`
  ${(props) => {
    const c = props.theme.css;
    return [c.grow, c.justifyCenter, c.yscroll, c.pRelative];
  }};
`;

const PreContentContainer = styled(Div)`
  position: relative;
  margin: 80px 160px;
  min-height: 740px;
  max-height: 740px;
  max-width: 1120px;
  width: 100%;
  @media all and (max-width: 980px) {
    margin: 80px;
    max-height: unset;
    overflow-y: hidden;
  }
  @media all and (max-width: 840px) {
    margin: 24px;
  }
`;

const ContentContainer = styled(Div)`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 40px rgba(135, 70, 70, 0.08);
`;

const FormContentWrapper = styled(Div)`
  padding: 64px;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media all and (max-width: 1330px) {
    max-width: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media all and (max-width: 840px) {
    padding: 24px;
  }
`;

const LogoWrapper = styled(Div)`
  width: 100%;
  display: flex;
  justify-content: center;
  @media all and (max-width: 1330px) {
    display: flex;
    justify-content: center;
  }
`;

const FormContentButtonsWrapper = styled(Div)`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Title = styled(Div)`
  margin-top: 40px;
  ${(props) => props.theme.textStyles.wh1};
  @media all and (max-width: 1330px) {
    text-align: center;
  }
`;

const TextLink = styled(Span)`
  margin: 0 5px;
  ${(props) => props.theme.textStyles.wlink};
  ${(props) => props.theme.textStyles.wbody2};
  color: ${(props) => props.theme.colors.blue500};
`;

const TextTermsAndPrivacy = styled(Div)`
  margin-top: 24px;
  text-align: center;
  color: ${(props) => props.theme.colors.textSecondary};
  ${(props) => props.theme.textStyles.wbody1};
`;

const ExternalAuthAcceptInvitation = (): JSX.Element => {
  const { auth_provider: authProvider } = useParams<URLParams>();

  const goToAuthorizationPage = () => {
    const authorizeUri = `${process.env.REACT_APP_EXTERNAL_AUTH_BASE_URL}/${authProvider}`;

    window.open(authorizeUri, "_self");
  };

  const formUI = (
    <FormContentWrapper>
      <LogoWrapper>{flatironsFuseLogo}</LogoWrapper>
      <Title>Sign In with {capitalize(authProvider)}</Title>

      <TextTermsAndPrivacy>
        By Signing in you are agreeing to the
        <TextLink onClick={() => window.open(PRIVACY_POLICY_ROUTE)}>
          Privacy Policy
        </TextLink>
        and
        <TextLink onClick={() => window.open(TERMS_AND_CONDITIONS_ROUTE)}>
          The terms and conditons,
        </TextLink>
        which outlines how we collect, use, and protect your data.
      </TextTermsAndPrivacy>

      <FormContentButtonsWrapper mt={40}>
        <Button onClick={goToAuthorizationPage}>Sign In</Button>
      </FormContentButtonsWrapper>
    </FormContentWrapper>
  );

  return (
    <>
      <Helmet>
        <title>Sign In - Flatirons Fuse</title>
        <meta name="title" content="Sign In - Flatirons Fuse" />
        <meta
          name="description"
          content="Sign In to Flatirons Fuse to start making beautiful, custom branded CSV and spreadsheet imports within minutes."
        />
      </Helmet>
      <Wrapper>
        <PreContentContainer>
          <ContentContainer br={16}>{formUI}</ContentContainer>
        </PreContentContainer>
      </Wrapper>
    </>
  );
};

export default ExternalAuthAcceptInvitation;

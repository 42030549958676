import styled from "styled-components";
import { Div, DivCSSProps } from "../../../../styled/utils";
import { theme } from "../../../../styled/theme";

type HeaderListItemProps = {
  isLast: boolean;
};

export const HeaderCardWrapper = styled(Div)`
  height: fit-content;
`;

export const HeaderCardContainer = styled(Div)`
  border: 1px solid ${theme.colors.gray200};
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  overflow-y: auto;
  ${(p) => p.theme.css.scrollbarDark};
  background: ${(p) => p.theme.colors.white};
  max-height: 596px;
  width: 332px;

  @media (max-width: 1050px) {
    width: 100%;
  }
`;

export const HeaderListItem = styled(Div)<HeaderListItemProps>`
  margin-bottom: ${(p) => (p.isLast ? 0 : 8)}px;
`;

import { AppLoading } from "components/AppLoading";
import { useUserContext } from "components/UserContextProvider";
import { api } from "fuse-shared-ui";
import { useQueryParams } from "hooks";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { SIGN_IN_ROUTE } from "..";
import { ChangePasswordWithData } from "./ChangePasswordWithData";
import { PasswordChanged } from "./PasswordChanged";

const ChangePassword = () => {
  const history = useHistory();
  const { setUser } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const [resetPasswordComplete, setResetPasswordComplete] = useState(false);

  const { reset_password_token: resetPasswordToken } = useQueryParams([
    "reset_password_token",
  ]);

  useEffect(() => {
    const confirmResetPasswordToken = async () => {
      try {
        const { data } = await api.post("/users/reset_passwords", {
          reset_password_token: resetPasswordToken,
        });
        setUser(data);
        setIsValidToken(true);
      } catch (err) {
        setIsValidToken(false);
      }
      setIsLoading(false);
    };

    if (resetPasswordToken) confirmResetPasswordToken();
    else history.push(SIGN_IN_ROUTE);
  }, []);

  return (
    <>
      <Helmet>
        <title>Flatirons Fuse - Change Password</title>
      </Helmet>
      {isLoading ? (
        <AppLoading />
      ) : resetPasswordComplete ? (
        <PasswordChanged />
      ) : (
        <ChangePasswordWithData
          {...{ isValidToken, resetPasswordToken }}
          onCompleteResetPassword={() => setResetPasswordComplete(true)}
        />
      )}
    </>
  );
};

export default ChangePassword;

import React from "react";
import { Route, Switch } from "react-router";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";

export const PRIVACY_AND_TERMS = {
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
};

export const PRIVACY_AND_TERMS_VIEW_PATH = "/privacy-and-terms";
const p = (path) => `${PRIVACY_AND_TERMS_VIEW_PATH}${path}`;

export const PRIVACY_POLICY_ROUTE = p(`${PRIVACY_AND_TERMS.PRIVACY_POLICY}`);
export const TERMS_AND_CONDITIONS_ROUTE = p(
  `${PRIVACY_AND_TERMS.TERMS_AND_CONDITIONS}`
);

const PrivacyAndTermsRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path={PRIVACY_POLICY_ROUTE}>
          <PrivacyPolicy />
        </Route>
        <Route exact path={TERMS_AND_CONDITIONS_ROUTE}>
          <TermsAndConditions />
        </Route>
      </Switch>
    </>
  );
};

export default PrivacyAndTermsRoutes;

import {
  Dropdown as DropdownBase,
  DropdownContainer,
} from "components/common/Dropdown";
import { Div, Divider, colors } from "fuse-shared-ui";
import { useState } from "react";
import styled from "styled-components";
import { buildingIcon } from "../../icons";
import { DropdownItem as DropdownItemBase } from "./DropdownItem";
import { OrganizationsListing } from "./OrganizationsListing";

const DropdownItem = styled(DropdownItemBase)`
  border-radius: 8px;
`;

const Dropdown = styled(DropdownBase)`
  margin-left: 15px;
  margin-top: -60px;

  ${DropdownContainer} {
    max-height: 300px;
    overflow-y: auto;
    overflow: auto !important;
    width: 240px;
  }
`;

const Submenu = ({ isOpen, setIsOpen, children }) => {
  return (
    <Div pAbsolute>
      <Dropdown
        placement="right-start"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        offset={({ popper: { width } }) => [10, -(width + width * 0.15)]}
      >
        <Div maxh={400} dim="100%">
          {children}
        </Div>
      </Dropdown>
    </Div>
  );
};

export const OrganizationDropdownItem = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownItem
      onClick={() => {
        if (!isOpen) setIsOpen(true);
      }}
      label="Switch Organization"
      icon={buildingIcon}
      subMenu={
        <Submenu {...{ isOpen, setIsOpen }}>
          <Div p={16}>
            <Div label3 c={colors.gray900} pl={18} mb={10}>
              Organizations
            </Div>
            <Divider m="5px 0" />
            <OrganizationsListing />
          </Div>
        </Submenu>
      }
    />
  );
};

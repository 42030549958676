import { useUserContext } from "components/UserContextProvider";
import Form, { FormikSumbitButton } from "components/common/form/Form";
import {
  Button,
  Div,
  Modal,
  P,
  TextInput,
  TooltipProvider,
  addToast,
  api,
  colors,
  useTableContext,
} from "fuse-shared-ui";
import { useState } from "react";
import styled from "styled-components";
import { ActionWrapper } from "../../TemplateRoutes/TemplateColumns/components/TableColumns/styles";
import { copyIcon } from "../../TemplateRoutes/Templates/common/icons";

const FormButtonsWrapper = styled(Div)`
  margin-top: 28px;
  display: flex;
  justify-content: center;
`;

const ClipboardActionWrapper = styled(ActionWrapper)`
  background: ${({ theme }) => theme.colors.gray900};
  color: white;
  cursor: pointer;
  height: 68px;
`;

type ImportModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateCredentialsModal = ({ isOpen, onClose }: ImportModalProps) => {
  const {
    user: { active_organization },
  } = useUserContext();
  const [credentialsName, setCredentialsName] = useState();
  const { getData } = useTableContext();
  const [token, setToken] = useState();

  const onSubmit = async () => {
    try {
      const { data } = await api.post(
        `/api/v1/organizations/${active_organization.id}/api_credentials`,
        {
          name: credentialsName,
        }
      );

      setToken(data.token);

      addToast("Your new API key was created", "success");
      if (getData) getData();
    } catch (error) {
      addToast(
        "An error occurred and the API key could not be created",
        "error"
      );
      console.error(error);
    }
  };

  const field = (
    <TextInput
      data-cy="label"
      label="API key label"
      placeholder="e.g. Staging or Production"
      name="name"
      type={"text"}
      onChange={(v) => setCredentialsName(v)}
    />
  );

  const onCopyToClipboard = () => {
    addToast(`Copied ${token} to clipboard`, "success");
    navigator.clipboard.writeText(token);
  };

  const contentUI = (
    <>
      <Div data-cy="generate-api-key-pop-up" textAlignCenter mb={30}>
        <Div data-cy="pop-up-title" h5 c={colors.textPrimary}>
          Generate API key
        </Div>
        {token ? (
          <Div data-cy="generated-api-description" wbody3 maxw={513}>
            Please copy the API Key below. You will not be able to access it
            once you close this window.
          </Div>
        ) : (
          <Div data-cy="pop-up-description" wbody3>
            Enter the name of the key you want to generate.
          </Div>
        )}
      </Div>

      {token && (
        <Div w100>
          <Div flexColumn>
            <P data-cy="label" wbody4 mb={8}>
              API Key
            </P>

            <TooltipProvider
              alignCenter
              tooltip="Copy to clipboard"
              placement="auto"
            >
              <ClipboardActionWrapper
                w100
                onClick={onCopyToClipboard}
                data-cy="generate-api-copytoclipboard-button"
              >
                <Div spaceBetween w100 m={0}>
                  <P
                    c={colors.white}
                    wbody1
                    m={0}
                    grow
                    data-cy={"api-key-token"}
                  >
                    {token}
                  </P>
                  <Div c={colors.white} centered>
                    {copyIcon}
                  </Div>
                </Div>
              </ClipboardActionWrapper>
            </TooltipProvider>
          </Div>

          <Div w100 textAlignRight justifyEnd>
            <Button
              data-cy="generate-api-close-modal-button"
              w={104}
              mt={50}
              textAlignRight
              variant="primary"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
          </Div>
        </Div>
      )}

      {!token && (
        <Form fields={[field]} onSubmit={onSubmit}>
          <>
            {!token && field}
            <FormButtonsWrapper>
              <Button
                data-cy="generate-api-cancel-button"
                mr={16}
                w={104}
                variant="secondary"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <FormikSumbitButton
                data-cy="generate-api-submit-button"
                w={200}
                disableOnErrors={true}
                isDisabled={!credentialsName}
              >
                <Div>Generate API Key</Div>
              </FormikSumbitButton>
            </FormButtonsWrapper>
          </>
        </Form>
      )}
    </>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Div p={22}>{contentUI}</Div>
    </Modal>
  );
};

export default CreateCredentialsModal;

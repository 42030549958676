import React from "react";
import Helmet from "react-helmet";

const isGoogleTagManagerEnabled =
  process.env.REACT_APP_ENABLE_GOOGLE_TAG_MANAGER === "true";

const Analytics = () => {
  const gtm = (
    <script type="text/javascript">
      {`
        <!-- Google Tag Manager -->
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MVLR9V6');
        <!-- End Google Tag Manager -->
      `}
    </script>
  );

  return <Helmet>{isGoogleTagManagerEnabled ? gtm : null}</Helmet>;
};

export default Analytics;

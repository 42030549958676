import FuseImporter, {
  EnvironmentType,
  Record,
  ValidationErrors,
  ValidationResult,
} from "fuse-importer";
import { segmentProvidedValue } from "../../../../SegmentContextProvider";
import LocalFuseImporter from "./LocalFuseImporter";

export const launchImporter = (
  template,
  apiToken,
  onCloseCallback = () => {}
) => {
  const env: EnvironmentType = process.env
    .REACT_APP_IMPORTER_PREVIEW_ENVIRONMENT as EnvironmentType;
  const trackData = {
    template_name: template.name,
    template_id: template.slug,
  };
  if (segmentProvidedValue) {
    segmentProvidedValue.track("user_launching_template", trackData);
  }
  const importer =
    process.env.REACT_APP_CYPRESS === "true"
      ? new LocalFuseImporter(apiToken, template.slug, {
          env: env,
        })
      : new FuseImporter(apiToken, template.slug, {
          env: env,
        });
  importer.onSubmit = window?.["cypressOnSubmit"];
  const onValidateRecord = async (
    record: Record
  ): Promise<ValidationResult> => {
    const errors: ValidationErrors = {};
    const warnings: ValidationErrors = {};
    return { errors: errors, warnings: warnings };
  };
  importer.onValidateRecord = onValidateRecord;
  importer.onClose = onCloseCallback;
  importer.show();
};

import {
  yupEmail,
  yupPassword,
  yupRequiredWithLabel,
} from "components/common/form/validations";

export const nameFields = [
  {
    name: "first_name",
    label: "First Name*",
    placeholder: "Enter your first name",
    initialValue: "",
    validation: yupRequiredWithLabel("First Name"),
  },
  {
    name: "last_name",
    label: "Last Name*",
    placeholder: "Enter your last name",
    initialValue: "",
    validation: yupRequiredWithLabel("Last Name"),
  },
];

export const textFields = [
  {
    name: "email",
    label: "Email Address",
    placeholder: "Enter your email address",
    initialValue: "",
    validation: null,
  },
  {
    name: "password",
    label: "Password*",
    placeholder: "Enter your password",
    initialValue: "",
    inputProps: {
      type: "password",
    },
    validation: yupPassword,
  },
];

export const fields = [...textFields, ...nameFields];

import { Div, colors } from "fuse-shared-ui";
import { CellContentProps } from "../interfaces";

export const CellContent = ({
  value,
  dataCy,
}: CellContentProps): JSX.Element => {
  return (
    <Div dflex alignCenter>
      <Div data-cy={dataCy || value} c={colors.text} wbody3 truncateText2>
        {value}
      </Div>
    </Div>
  );
};

import { BtnProps, Button, ButtonTypes, Div } from "fuse-shared-ui";
import { useCallback, useState } from "react";
import { Plan } from "../../../../../UserContextProvider";
import { useBillingContext } from "../../BillingContextProvider";
import { useUpdatePlan } from "../../common";
import SelectPlanForm from "./SelectPlanForm";
import { useAppContext } from "components/AppContextProvider";

type ButtonProps = {
  text: string;
  variant: ButtonTypes;
};

const getButtonProps = (currentPlan: Plan, plan: Plan): ButtonProps => {
  const buttonProps: ButtonProps = {
    variant: "secondary",
    text: "Choose Plan",
  };

  if (!currentPlan && plan.lookup_key?.includes("pro")) {
    buttonProps.variant = "primary";
  }

  if (
    currentPlan &&
    (currentPlan.price.cents > plan.price.cents ||
      currentPlan.lookup_key?.includes("enterprise")) &&
    currentPlan.recurrence_period === plan.recurrence_period
  ) {
    buttonProps.variant = "secondary";
    buttonProps.text = "Downgrade Plan";
  } else if (currentPlan && currentPlan.price.cents < plan.price.cents) {
    buttonProps.variant = "primary";
    buttonProps.text = "Upgrade Plan";
  }

  if (plan.lookup_key?.includes("enterprise")) {
    buttonProps.text = "Contact us";
  }

  if (
    currentPlan?.id === plan.id ||
    (currentPlan?.lookup_key === plan.lookup_key &&
      currentPlan?.recurrence_period === plan.recurrence_period)
  ) {
    buttonProps.variant = "secondary";
    buttonProps.text = "Your Plan";
  }

  return buttonProps;
};

type ChangePlanButtonProps = { plan: Plan } & BtnProps;

type Window = typeof window & {
  Intercom: any;
};

const ChangePlanButton = ({ plan, ...props }: ChangePlanButtonProps) => {
  const updatePlan = useUpdatePlan();
  const { isLoadingApp, setIsLoadingApp } = useAppContext();

  const { paymentMethod, currentPlan } = useBillingContext();
  const [isUpdatePlanFormOpen, setIsUpdatePlanFormOpen] = useState(false);

  const openIntercomForCustomPlan = () => {
    const _window = window as Window;
    if (_window?.Intercom && _window?.Intercom.booted) {
      _window.Intercom(
        "showNewMessage",
        "Hello! I am writing to request more information about the enterprise plan."
      );
    }
  };

  const onClick = useCallback(async () => {
    const isEnterprisePlan = plan.lookup_key.includes("enterprise");
    if (isEnterprisePlan) {
      openIntercomForCustomPlan();
      return;
    }

    setIsLoadingApp(true);

    if (!paymentMethod) {
      setIsUpdatePlanFormOpen(true);
    } else {
      updatePlan(plan);
    }
  }, [plan, paymentMethod]);

  const currentPlanId = currentPlan?.id;
  const buttonProps = getButtonProps(currentPlan, plan);

  const isDisabled =
    currentPlanId === plan.id ||
    isLoadingApp ||
    (currentPlan?.recurrence_period === "year" &&
      plan?.recurrence_period === "month");

  return (
    <>
      <Button
        isSmall
        br={8}
        variant={buttonProps.variant}
        data-cy="planButton"
        isDisabled={isDisabled}
        onClick={onClick}
        {...props}
      >
        {buttonProps.text}
      </Button>
      {isUpdatePlanFormOpen && (
        <SelectPlanForm
          onClose={(canceled) => {
            if (canceled) {
              setIsLoadingApp(false);
            }
            setIsUpdatePlanFormOpen(false);
          }}
          plan={plan}
        />
      )}
    </>
  );
};

export default ChangePlanButton;

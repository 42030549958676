import { BtnProps, Button, Div } from "fuse-shared-ui";
import styled from "styled-components";

export const GoToTemplatesLink = styled(Div)`
  font-size: 14px;
  margin-top: 22px;
  color: ${(props) => props.theme.colors.text};
  ${(props) => {
    const c = props.theme.css;
    return [c.link, c.justifyEnd];
  }};
`;

export const blankStateIcon = (
  <svg
    width="89"
    height="64"
    viewBox="0 0 89 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "darken" }}>
      <rect
        x="19"
        width="50"
        height="64"
        rx="4"
        fill="url(#paint0_linear_480_22675)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <rect
        x="0.457031"
        y="16"
        width="48.5434"
        height="10"
        rx="2"
        fill="url(#paint1_linear_480_22675)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <rect
        x="46"
        y="38"
        width="42.1829"
        height="10"
        rx="2"
        fill="url(#paint2_linear_480_22675)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_480_22675"
        x1="44"
        y1="-9.77512e-07"
        x2="20.8846"
        y2="84.931"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6BEEFF" />
        <stop offset="1" stopColor="#05C2B7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_480_22675"
        x1="12.5546"
        y1="25.4923"
        x2="18.8712"
        y2="6.9379"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0A617" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_480_22675"
        x1="56.5125"
        y1="47.4923"
        x2="63.5447"
        y2="29.5425"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0A617" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
    </defs>
  </svg>
);

export const AddColumnButton = (props: Omit<BtnProps, "children">) => (
  <Button data-cy="addAColumn" w={161} {...props} iconPlacement="left">
    Add a Column
  </Button>
);

import { HideModalFn } from "components/AppModalContextProvider";
import { Div, Button, Modal } from "fuse-shared-ui";

type Props = {
  isEditing: boolean;
  isOpen: boolean;
  onClose: HideModalFn;
};

const ColumnAddedModal = ({ isEditing, isOpen, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Div flexColumn centered m="48px 56px" w={448}>
        {columnAddedIcon}
        <Div h5 mt={20} data-cy="confirmation-text">
          Column {isEditing ? "Edited" : "Added"}!
        </Div>
        <Div wbody1 mt={12} data-cy="column-confirmation-description">
          Column was successfully {isEditing ? "edited in" : "added to"} your
          importer.
        </Div>
        <Button
          data-cy="gotItButton"
          w={98}
          buttonText
          mt={40}
          onClick={onClose}
        >
          Got it!
        </Button>
      </Div>
    </Modal>
  );
};

export default ColumnAddedModal;

const columnAddedIcon = (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" fill="white" />
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M49.3064 6.19482C50.0422 5.37098 51.3065 5.29959 52.1304 6.03536L62.509 15.3045C63.3328 16.0403 63.4042 17.3046 62.6684 18.1285L28.1487 56.78C27.4129 57.6038 26.1486 57.6752 25.3247 56.9394L14.9461 47.6703C14.1223 46.9345 14.0509 45.6702 14.7867 44.8463L49.3064 6.19482Z"
        fill="url(#paint0_linear_2341_5202)"
      />
    </g>
    <g style={{ mixBlendMode: "darken" }}>
      <rect
        x="38.7266"
        y="44.8711"
        width="17.9152"
        height="35.8777"
        rx="2"
        transform="rotate(131.582 38.7266 44.8711)"
        fill="url(#paint1_linear_2341_5202)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2341_5202"
        x1="18.6723"
        y1="47.1981"
        x2="37.6026"
        y2="58.473"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0A617" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2341_5202"
        x1="47.6842"
        y1="44.8711"
        x2="36.1231"
        y2="89.5271"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6BEEFF" />
        <stop offset="1" stopColor="#08CABE" />
      </linearGradient>
    </defs>
  </svg>
);

import SessionRoutes, {
  SESSION_VIEW_PATH,
  SIGN_IN_ROUTE,
} from "components/Routes/SessionRoutes";
import { useUserContext } from "components/UserContextProvider";
import intercomComponent from "components/common/IntercomComponent";
import { isEqual } from "lodash";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import AccountRoutes from "./AccountRoutes";
import { ACCOUNT_HOME_ROUTE, ACCOUNT_VIEW_PATH } from "./AccountRoutes/common";
import PrivacyAndTermsRoutes, {
  PRIVACY_AND_TERMS_VIEW_PATH,
} from "./PrivacyAndTermsRoutes";
import TestRoutes, { CYPRESS_TEST_PATHS } from "./TestRoutes";

const Routes = () => {
  const { isAuthenticated } = useUserContext();

  return (
    <>
      <RouterScrollToTop />
      <Switch>
        <Route path={CYPRESS_TEST_PATHS}>
          <TestRoutes />
        </Route>
        <Route path={SESSION_VIEW_PATH}>
          <SessionRoutes />
        </Route>
        <Route path={PRIVACY_AND_TERMS_VIEW_PATH}>
          <PrivacyAndTermsRoutes />
        </Route>
        <ProtectedRoute path={ACCOUNT_VIEW_PATH}>
          <AccountRoutes />
          {intercomComponent}
        </ProtectedRoute>
        <Route
          component={() => (
            <Redirect
              to={isAuthenticated ? ACCOUNT_HOME_ROUTE : SIGN_IN_ROUTE}
            />
          )}
        />
      </Switch>
    </>
  );
};

const ProtectedRoute = ({ path, children, exact = false }) => {
  const { isAuthenticated: isLoggedIn } = useUserContext();
  return (
    <>
      {isLoggedIn ? (
        <Route exact={exact} path={path}>
          {children}
        </Route>
      ) : (
        <Redirect to={SIGN_IN_ROUTE} />
      )}
    </>
  );
};

const ScrollToTop = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};

const RouterScrollToTop = withRouter(ScrollToTop);

export default React.memo(Routes, isEqual);

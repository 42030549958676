import {
  Form as FForm,
  Formik,
  FormikProps,
  FormikValues,
  useFormikContext,
} from "formik";
import { BtnProps, Button, ButtonTypes } from "fuse-shared-ui";
import * as yup from "yup";
import { FormikErrorFocus } from "./fieldHelpers";

export const getFormikProps = (fields) => {
  const initialValues = fields.reduce(
    (initialValues, f) => ({
      ...initialValues,
      [f.name]: f.initialValue,
    }),
    {}
  );

  const validationSchema = yup.object().shape(
    fields.reduce(
      (validations, f) => ({
        ...validations,
        [f.name]: f.validation,
      }),
      {}
    )
  );
  return { initialValues, validationSchema };
};

export type SubmitButtonProps = {
  variant?: ButtonTypes;
  isDisabled?: boolean;
  children: any;
  className?: string;
  disableOnErrors?: boolean;
} & BtnProps;

export const FormikSumbitButton = ({
  variant = undefined,
  isDisabled = false,
  children,
  className = "",
  disableOnErrors = false,
  ...props
}: SubmitButtonProps): JSX.Element => {
  const { submitForm, errors, isValid, isSubmitting } = useFormikContext();
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <Button
      {...{ className, variant, ...props }}
      isDisabled={
        (hasErrors && disableOnErrors) || !isValid || isDisabled || isSubmitting
      }
      onClick={isDisabled ? null : submitForm}
    >
      {children}
    </Button>
  );
};

type FormProps = {
  fields: any[];
  onSubmit: (values: any) => Promise<any>;
  children: JSX.Element;
  formikRef?: React.MutableRefObject<FormikProps<any>>;
  id?: string;
  [key: string]: any;
};

const Form = ({
  fields,
  onSubmit,
  children,
  id,
  formikRef,
  ...props
}: FormProps): JSX.Element => {
  function renderForm(form) {
    if (formikRef) formikRef.current = form;
    return (
      <FForm id={id ? id : undefined} {...props}>
        {children}
        <FormikErrorFocus />
      </FForm>
    );
  }

  return (
    <Formik
      {...getFormikProps(fields)}
      onSubmit={onSubmit}
      enableReinitialize
      {...props}
    >
      {renderForm}
    </Formik>
  );
};

export default Form;

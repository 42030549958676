import { Div, Span, theme } from "fuse-shared-ui";
import { useMemo } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useBillingContext } from "../BillingContextProvider";
import { exclamationPointIcon } from "./icons";
import { formatDate } from "./PaymentMethodCard";

const Container = styled(Div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden;
  }
  #root {
    padding-top: 60px;
  }
`;

export const PlanCancellationBanner = () => {
  const { subscriptionData } = useBillingContext();

  const cancellationDate = useMemo(() => {
    if (!subscriptionData || !subscriptionData.subscription) return null;

    const { cancel_at } = subscriptionData.subscription;

    if (!cancel_at) return null;

    const dateAfterMultiplication = cancel_at * 1000;

    return formatDate(dateAfterMultiplication);
  }, [subscriptionData]);

  if (!cancellationDate) return null;

  return (
    <Container bg={theme.colors.orange100} h={60} dflex alignCenter>
      <GlobalStyle />
      <Div ml={33} mr={8}>
        {exclamationPointIcon}
      </Div>
      <Span mr={16}>
        Your current plan will be canceled at the end of your billing period (
        {cancellationDate}).
      </Span>
    </Container>
  );
};

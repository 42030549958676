import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const isGoogleAdsTrackingEnabled =
  process.env.REACT_APP_ENABLE_GOOGLE_ADS_TRACKING === "true";
const isCapterraPixelEnabled =
  process.env.REACT_APP_ENABLE_CAPTERRA_PIXEL === "true";
const googleTrackingId = process.env.REACT_APP_GOOGLE_ADS_TRACKING_ID;
const capterraConversionKey = process.env.REACT_APP_CAPTERRA_CONVERSION_KEY;

const CapterraImage = styled.img`
  display: none;
`;

type ConversionTagsProps = {
  email: string;
};

const ConversionTags = ({ email }: ConversionTagsProps) => {
  const googleAsyncLoader = (
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=AW-499335256`}
    ></script>
  );

  const googleTrackingComponent = (
    <script>{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-499335256'); gtag('event', 'conversion', { 'send_to': 'AW-499335256/${googleTrackingId}', 'transaction_id': '${email}' });`}</script>
  );

  return (
    <>
      {isGoogleAdsTrackingEnabled && (
        <Helmet>
          {googleTrackingId && googleAsyncLoader}
          {googleTrackingId && googleTrackingComponent}
        </Helmet>
      )}
      {isCapterraPixelEnabled && capterraConversionKey && (
        <CapterraImage
          src={`https://ct.capterra.com/capterra_tracker.gif?vid=2208285&vkey=${capterraConversionKey}`}
        />
      )}
    </>
  );
};

export default ConversionTags;

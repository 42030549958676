import styled from "styled-components";
import PersonalInformationForm from "./PersonalInformationForm";
import PasswordInformationForm from "./PasswordInformationForm";
import CustomizeImporter from "./CustomizeImporter";
import { Div } from "fuse-shared-ui";

const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0 92px;
`;

const Account = () => {
  return (
    <Wrapper>
      <Div w={800}>
        <Div wh1>My Account</Div>
        <PersonalInformationForm />
        <PasswordInformationForm />
        <CustomizeImporter />
      </Div>
    </Wrapper>
  );
};

export default Account;

import styled, { css } from "styled-components";
import { Autocomplete } from "../../../../common/inputs/Autocomplete";
import { textStyles } from "../../../../styled/theme/textStyles";

type StyledAutocompleteProps = {
  inputWidth: string;
  isDisabled: boolean;
};

export const StyledAutocomplete = styled(Autocomplete)<StyledAutocompleteProps>`
  &,
  * {
    box-sizing: border-box !important;

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        cursor: not-allowed !important;
      `}
  }

  div[data-popper-placement="bottom"] > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    outline: 1px solid red;
    padding: 8px;

    > div div {
      padding: 6px 12px;
    }

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        cursor: not-allowed;
      `}
  }

  input {
    ${textStyles.body3};
    background-color: ${({ theme }) => theme.colors.orange50};
    border: 1px solid transparent;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    padding: 6px 12px;
    width: ${({ inputWidth }) => inputWidth};

    ::placeholder {
      color: ${({ theme }) => theme.colors.black};
    }

    :focus {
      border: none;
      box-shadow: none;
    }
  }
`;

import React from "react";
import { Div } from "fuse-shared-ui";

import { FormikAutocomplete } from "components/common/form/fields";
import { allowedPatterns } from "../../fields";

type Props = {
  columnType: string;
};

const PatternField = ({ columnType }: Props) => {
  return (
    <Div dflex flexColumn ml={40} mt={24} mb={16}>
      <FormikAutocomplete
        name="pattern"
        label="Pattern*"
        placeholder="Select option..."
        options={allowedPatterns[columnType]}
      />
    </Div>
  );
};

export default PatternField;

import { Button, Div } from "fuse-shared-ui";
import styled from "styled-components";

export const ModalContent = styled(Div)`
  text-align: center;
  padding: 10px;
`;

export const DowngradeButton = styled(Button)`
  border-radius: 8px;
  height: 48px;
`;

export const CancelButton = styled(Button)`
  height: 48px;
  border: 2px solid #000000;
`;

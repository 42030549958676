import ActionCable, { Channel } from "actioncable";
import axios from "axios";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Record, RecordDataSet } from "fuse-importer";
import { useImporterContext } from "../../../contexts/ImporterContextProvider";
import { useBatchProcessing } from "../SpreadsheetContextProvider/useBatchProcessing";
import { useQueue } from "./useQueue";

export type State = {
  isCloseRetryState: React.MutableRefObject<boolean>;
  setIsCloseRetryState: React.Dispatch<React.SetStateAction<boolean>>;
  stopQueue: () => void;
  playQueue: () => void;
  isRetryModalOpen: boolean;
  setIsRetryModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  createImporter: (rowCount: number) => void;
  persistRecords: (records: Record[], onFinish: () => void) => void;
  persistDataSet: (dataSet: RecordDataSet, onFinish: () => void) => void;
  importer: React.MutableRefObject<{
    id: number;
    persistence: boolean;
    slug: string;
    status: string;
  }>;
  persisting: boolean;
  persistenceProgress: number;
  resetImporter: () => void;
  setValidatingPersistence: React.Dispatch<React.SetStateAction<boolean>>;
  validatingPersistence: boolean;
};

/**
 * This variable needs to be the same as the one in the backend
 * @see app/models/import.rb:64
 */
const WEBHOOK_BATCH_SIZE =
  Number(process.env.REACT_APP_WEBHOOK_BATCH_SIZE) || 1000;

export const PersistenceContext = createContext<State>({} as State);

export const PersistenceContextProvider = ({ children }) => {
  const {
    fuseApi,
    templateSlug,
    fileName,
    createCableConsumer,
  } = useImporterContext();
  const importer = useRef(null);

  const {
    processRecordsInBatches,
    processDataSetInBatches,
  } = useBatchProcessing(WEBHOOK_BATCH_SIZE);

  const cable = useRef<ActionCable.Cable>();
  const [persisting, setPersisting] = useState(false);
  const [persistenceProgress, setPersistenceProgress] = useState(0);
  const [validatingPersistence, setValidatingPersistence] = useState(false);
  const webhookSubscription = useRef<Channel>();
  const axiosCancelToken = useRef(null);

  const {
    mainQueue,
    parallelQueue,
    isQueuePaused,
    playQueue,
    isRetryModalOpen,
    setIsRetryModalOpen,
    isCloseRetryState,
    setIsCloseRetryState,
    stopQueue,
    resetQueues,
    enqueue,
  } = useQueue();

  const updateImportStatus = async (status: string) => {
    const result = await fuseApi.put(
      `/api/v1/importer/templates/${templateSlug}/imports/${importer?.current?.slug}`,
      {
        status: status,
        slug: importer?.current?.slug,
      }
    );
    importer.current.status = status;
    return result;
  };

  const createImporter = async (recordCount: number) => {
    setIsCloseRetryState(false);

    return new Promise(async (resolve, reject) => {
      enqueue(mainQueue, async () => {
        try {
          const result = await fuseApi.post(
            `/api/v1/importer/templates/${templateSlug}/imports`,
            { original_file_name: fileName, row_count: recordCount }
          );
          importer.current = result.data;
          subscribeToImporter();
          resolve(importer.current);
        } catch (e) {
          console.error("Error creating importer", e);
          reject(e);
        }
      });
    });
  };

  const resetImporter = () => {
    if (webhookSubscription.current) {
      webhookSubscription.current.unsubscribe();
    }

    if (cable.current) {
      cable.current.disconnect();
      cable.current = null;
    }

    webhookSubscription.current = null;
    importer.current = null;
    setPersistenceProgress(0);
  };

  const subscribeToImporter = () => {
    if (webhookSubscription.current) return;

    cable.current = createCableConsumer();

    if (importer.current && cable.current) {
      webhookSubscription.current = cable.current.subscriptions.create(
        {
          channel: "ImportChannel",
          slug: importer?.current?.slug,
        },
        {
          received: (data) => {
            if (importer.current.status === "failed") {
              return;
            }

            const isFailed = "failed" === data.status;

            if (data.status) {
              importer.current.status = data.status;
            }

            if (isFailed) {
              axiosCancelToken.current.cancel();
              isQueuePaused.current = true;
              setPersisting(false);
              setIsRetryModalOpen(true);
              setPersistenceProgress(0);
              return;
            }

            if (data?.progress) {
              setPersistenceProgress((c) =>
                c > data.progress ? c : data.progress
              );
            }
            if (["completed", "completed_with_issues"].includes(data.status)) {
              setPersisting(false);
            }
          },
        }
      );
    }
  };

  const saveRecord = async (records: Record[]) => {
    if (importer.current.status === "failed") {
      return;
    }
    await fuseApi.post(
      `/api/v1/importer/records`,
      {
        records: records,
        import_slug: importer?.current?.slug,
      },
      {
        cancelToken: axiosCancelToken.current.token,
        ignoreError: true,
      }
    );
  };

  const createProcessor = (processFunction) => {
    resetQueues();
    return (data) => {
      return new Promise(async (resolve) => {
        try {
          axiosCancelToken.current = axios.CancelToken.source();
          parallelQueue.current.on("idle", function () {
            if (parallelQueue.current.empty()) {
              resolve(true);
            }
          });
          setPersisting(true);
          await processFunction(data, (batch) => {
            enqueue(parallelQueue, () => saveRecord(batch));
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      });
    };
  };

  const persistRecords = async (records: Record[], onFinish) => {
    if (!records) return;
    try {
      if (importer.current.status === "failed") {
        await stopQueue(false);
      }
      const recordsProcessor = createProcessor(processRecordsInBatches);
      setIsCloseRetryState(false);
      await enqueue(mainQueue, () => updateImportStatus("submitting"));
      await enqueue(mainQueue, async () => await recordsProcessor(records));
      if (importer.current.status === "failed") {
        return;
      }
      await enqueue(mainQueue, () => updateImportStatus("submitted"));
      await enqueue(mainQueue, () => onFinish());
    } catch (error) {
      console.error(error);
    }
  };

  const persistDataSet = async (dataSet: RecordDataSet, onFinish) => {
    if (!dataSet) return;
    const dataSetProcessor = createProcessor(processDataSetInBatches);
    enqueue(mainQueue, () => updateImportStatus("submitting"));
    enqueue(mainQueue, () => dataSetProcessor(dataSet));
    enqueue(mainQueue, () => updateImportStatus("submitted"));
    enqueue(mainQueue, () => onFinish());
  };

  useEffect(() => {
    return () => {
      resetImporter();
    };
  }, []);

  const value = {
    isCloseRetryState,
    setIsCloseRetryState,
    stopQueue,
    playQueue,
    isRetryModalOpen,
    setIsRetryModalOpen,
    createImporter,
    persistRecords,
    persistDataSet,
    importer,
    persisting,
    persistenceProgress,
    resetImporter,
    validatingPersistence,
    setValidatingPersistence,
  };

  return (
    <PersistenceContext.Provider value={value}>
      {children}
    </PersistenceContext.Provider>
  );
};

export const usePersistenceContext = () => useContext(PersistenceContext);

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { Div } from "../../styled/utils";
import { useReviewContext } from "../Review/ReviewContextProvider";
import SpreadsheetBase from "../common/Spreadsheet";
import { useImporterContext } from "../contexts/ImporterContextProvider";
import { HeaderButtons } from "./HeaderButtons";

const Spreadsheet = styled(SpreadsheetBase)(
  () => css`
    /* rows will be removed for match and review steps */
    .before-selected-row {
      opacity: 0.2;
    }
  `
);

const HeaderContainer = styled(Div)`
  margin-left: 32px;

  @media (max-width: 1050px) {
    margin-left: 0px;

    > div:first-of-type {
      text-align: center;
    }

    div:last-of-type {
      margin-left: 0px;
    }
  }
`;

const DescriptionAndButtons = styled(Div)`
  @media (max-width: 1050px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const HeadersPreview = (): JSX.Element => {
  const {
    previewFields: fields,
    previewInitialDataSet: initialDataSet,
  } = useReviewContext();

  const { t } = useTranslation("upload");
  const {
    selectedRows,
    setSelectedRows,
    setHeaderRowIndex,
  } = useImporterContext();

  useEffect(() => {
    if (initialDataSet && selectedRows[0]) {
      const headerRowIndex = Object.keys(initialDataSet).findIndex(
        (key) => key === selectedRows[0]
      );
      setHeaderRowIndex(headerRowIndex);
    }
  }, [initialDataSet, selectedRows]);

  return (
    <Div flexColumn h100>
      <HeaderContainer dflex flexColumn gap={16} mt={24} mb={24}>
        <Div data-cy="select-header-title" h2>
          {t("headers_preview.title")}
        </Div>
        <DescriptionAndButtons
          data-cy="select-header-description"
          body1
          alignCenter
        >
          {t("headers_preview.description")}

          <HeaderButtons />
        </DescriptionAndButtons>
      </HeaderContainer>
      <Div w100 h100 justifyCenter>
        {initialDataSet && (
          <Spreadsheet
            isReadOnly
            initialDataSet={initialDataSet}
            fields={fields}
            options={{
              showCheckboxColumn: true,
              withSingleRowSelect: true,
            }}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        )}
      </Div>
    </Div>
  );
};

export default HeadersPreview;

import { Div, P } from "fuse-shared-ui";
import styled from "styled-components";

type OrganizationAvatarProps = {
  name: string;
};

const getInitials = (name: string) => {
  const allNames = name.trim().split(" ");

  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }

    return acc;
  }, "");

  return initials;
};

const Container = styled(Div)`
  align-items: center;
  // TODO: shouldn't be hardcoded. It will vary depending on the current org
  background-color: ${({ theme }) => theme.colors.blue900};
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
`;

export const OrganizationAvatar = ({ name }: OrganizationAvatarProps) => {
  return (
    <Container>
      <P label1>{getInitials(name)}</P>
    </Container>
  );
};

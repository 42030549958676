import React, { createContext, useContext, useRef, useState } from "react";

export type State = {
  findAndReplaceModalOpen: boolean;
  setFindAndReplaceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openSearchInput: () => void;
  closeSearchInput: () => void;
  searchInputRef: React.MutableRefObject<HTMLInputElement>;
};

export const SearchContext = createContext<State>({} as State);
export const SearchContextProvider = ({ children }) => {
  const [findAndReplaceModalOpen, setFindAndReplaceModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const openSearchInput = () => {
    if (!isOpen) {
      setIsOpen(true);

      if (searchInputRef?.current) {
        searchInputRef.current?.focus();
      }
    }
  };

  const closeSearchInput = () => {
    setIsOpen(false);
  };

  const value = {
    findAndReplaceModalOpen,
    setFindAndReplaceModalOpen,
    isOpen,
    setIsOpen,
    openSearchInput,
    searchInputRef,
    closeSearchInput,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);

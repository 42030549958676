import {
  MutableRefObject,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useDataSetContext } from "../DataSetContextProvider";
import { Record } from "fuse-importer";
import { useImporterContext } from "../../../../Importer/contexts/ImporterContextProvider";

type RecordDiff = {
  oldRecord: Record;
  newRecord: Record;
};

type Change = {
  type: string;
  changes: RecordDiff[];
  undo: any;
  redo: any;
};

type ContextArgs = {
  children: any;
};

type State = {
  trackRecordChanges: (records: Record[], undo: any, redo: any) => void;
  trackRecordDeletion: (recordIds: string[], undo: any, redo: any) => void;
  undoTrackingEnabled: MutableRefObject<boolean>;
  undo: () => void;
  redo: () => void;
};

export const UndoRedoContext = createContext<State>({} as State);

export const UndoRedoContextProvider = ({ children }: ContextArgs) => {
  const changeStack = useRef<Change[]>([]);
  const { getRecord } = useDataSetContext();
  const stackPointer = useRef<number>(0);
  const undoTrackingEnabled = useRef(false);
  const { layoutRef } = useImporterContext();
  const changeStackSizeLimit = 15;

  const undo = () => {
    const topItem = changeStack.current[stackPointer.current];
    if (!topItem) return;

    const records = [];
    for (const key in topItem.changes) {
      const change = topItem.changes[key];
      records.push(change.oldRecord);
    }

    undoTrackingEnabled.current = false;
    topItem.undo(records);
    undoTrackingEnabled.current = true;

    stackPointer.current -= 1;
  };

  const redo = () => {
    if (stackPointer.current >= changeStack.current.length - 1) {
      return;
    }

    stackPointer.current += 1;
    const topItem = changeStack.current[stackPointer.current];

    const records = [];
    for (const key in topItem.changes) {
      const change = topItem.changes[key];
      records.push(change.newRecord);
    }

    undoTrackingEnabled.current = false;
    topItem.redo(records);
    undoTrackingEnabled.current = true;
  };

  const trackRecordChanges = (records: Record[], undo: any, redo: any) => {
    if (!undoTrackingEnabled.current) return;

    const recordChanges = [];
    for (const key in records) {
      recordChanges.push({
        oldRecord: { ...getRecord(records[key]._meta.id) },
        newRecord: { ...records[key] },
      });
    }

    const change = {
      type: "records_changed",
      changes: recordChanges,
      undo: undo,
      redo: redo,
    };

    trackChange(change);
    stackPointer.current = changeStack.current.length - 1;
  };

  const trackRecordDeletion = (recordIds: string[], undo: any, redo: any) => {
    const recordChanges = [];
    recordIds.forEach((recordId) => {
      recordChanges.push({
        oldRecord: { ...getRecord(recordId) },
        newRecord: { ...getRecord(recordId) },
      });
    });
    const change = {
      type: "records_deleted",
      changes: recordChanges,
      undo: undo,
      redo: redo,
    };

    trackChange(change);
    stackPointer.current = changeStack.current.length - 1;
  };

  const trackChange = (change: Change) => {
    changeStack.current.push(change);
    // limit the number of items we keep in memory
    changeStack.current = changeStack.current.slice(
      Math.max(changeStack.current.length - changeStackSizeLimit, 0)
    );
  };

  const value = {
    trackRecordChanges,
    trackRecordDeletion,
    undoTrackingEnabled,
    undo,
    redo,
  };

  return (
    <UndoRedoContext.Provider value={value}>
      {children}
    </UndoRedoContext.Provider>
  );
};

export const useUndoRedoContext = () => useContext(UndoRedoContext);

import { useState } from "react";
import { DateTime } from "luxon";
import styled, { css } from "styled-components";
import { Avatar, Div, colors } from "fuse-shared-ui";
import { AvatarPlaceholder } from "components/common/AvatarPlaceholder";
import TableDeleteIcon from "../../TemplateRoutes/TemplateColumns/components/TableColumns/components/TableDeleteIcon";
import { statusActiveIcon, statusPendingIcon } from "../common/icons";
import { ResendInvite } from "./ResendInvite";
import DeleteUserModal from "./DeleteUserModal";
import { useUserContext } from "components/UserContextProvider";

const statusOptions = {
  active: {
    icon: statusActiveIcon,
    label: "Active",
  },
  invited: {
    icon: statusPendingIcon,
    label: "Pending",
  },
};

const StatusWrapper = styled(Div)<{ status: string }>`
  padding: 0px 8px;
  border-radius: 5px;
  ${({ status }) => {
    return (
      status === "invited" &&
      css`
        border: 1px solid ${(p) => p.theme.colors.warning01};
        box-sizing: border-box;
        border-radius: 24px;
      `
    );
  }};
  ${(p) => {
    const c = p.theme.css;
    return [c.label3, c.alignCenter, c.selectNone];
  }};
`;

const ActionWrapper = styled.div`
  width: 100%;
  text-align: right;
  padding-right: 8px;
`;

export const Status = ({ status }) => (
  <StatusWrapper h={29} status={status}>
    <Div alignCenter>{statusOptions[status]?.icon}</Div>
    <Div ml={6} uppercase>
      {statusOptions[status]?.label}
    </Div>
  </StatusWrapper>
);

export const tableColumns = [
  {
    header: "Team Member",
    width: 2,
    Content: ({ user: { first_name, last_name, email, profile_url } }) => (
      <Div alignCenter>
        <Div>
          {profile_url ? (
            <Avatar src={profile_url} />
          ) : (
            <AvatarPlaceholder user={{ first_name, last_name }} />
          )}
        </Div>
        <Div ml={14} mt={-3}>
          <Div wbody4 c={colors.textPrimary}>
            {first_name} {last_name}
          </Div>
          <Div helperText c={colors.textSecondary}>
            {email}
          </Div>
        </Div>
      </Div>
    ),
  },
  {
    width: 1,
    header: "Date Joined",
    Content: ({ status, updated_at }) => (
      <>{status === "active" && DateTime.fromISO(updated_at).toFormat("D")}</>
    ),
  },
  {
    header: "Status",
    width: 0.5,
    Content: (props) => <Status {...props} />,
  },
  {
    header: "",
    width: 0.5,
    Content: (props: any) =>
      props.status === "invited" && <ResendInvite {...props} />,
  },
  {
    header: "",
    width: 0.5,
    preventRowClick: false,
    Content: ({ id, user }) => {
      const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
      const { user: currentUser } = useUserContext();
      const isDisabled = user.id === currentUser.id;
      return (
        <ActionWrapper>
          <TableDeleteIcon
            isDisabled={isDisabled}
            onClick={() => setIsDeleteModalOpen(true)}
          />
          <DeleteUserModal
            isOpen={isDeleteModalOpen}
            id={id}
            user={user}
            onClose={() => setIsDeleteModalOpen(false)}
          />
        </ActionWrapper>
      );
    },
  },
];

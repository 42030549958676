import { Pill, PillType } from "components/common/Pill";
import { Div, colors } from "fuse-shared-ui";
import { DateTime } from "luxon";
import styled from "styled-components";
import { DownloadButton } from "components/common/DownloadButton";

const Actions = styled(Div)`
  align-items: center;
  display: flex;
  gap: 8px;
  text-align: right;
  justify-content: flex-end;
  justify-self: flex-end;
  width: 100%;
  padding-right: 8px;
`;

const TableContent = ({ data }) => (
  <Div dflex alignCenter centered>
    <Div c={colors.text} wbody3 truncateText>
      {data}
    </Div>
  </Div>
);

type Status = "paid" | "unpaid" | "pending";

const getStatusVariant = (status: Status): PillType => {
  switch (status) {
    case "paid": {
      return "success";
    }
    case "unpaid": {
      return "error";
    }
    case "pending": {
      return "alert";
    }
    default: {
      return "alert";
    }
  }
};

export const tableColumns = [
  {
    header: "Invoice number",
    width: 1,
    Content: ({ number }) => <TableContent data={number} />,
  },
  {
    header: "Invoice Date",
    width: 1,
    Content: ({ created_at }) => (
      <TableContent data={DateTime.fromISO(created_at).toFormat("D")} />
    ),
  },
  {
    header: "Status",
    width: 1,
    Content: ({ status }: { status: Status }) => (
      <Pill status={status} variant={getStatusVariant(status)} />
    ),
  },
  {
    header: "Amount",
    width: 1,
    Content: ({ total }) => (
      <TableContent data={`$${total.toFixed(2).replace(/[.,]00$/, "")}`} />
    ),
  },
  {
    header: "",
    width: 0.1,
    preventRowClick: true,
    Content: (row) => <TableActions row={row} />,
  },
];

const TableActions = ({ row }) => {
  return (
    <>
      <Actions>
        <DownloadButton
          target="__blank"
          href={row.invoice_pdf}
          showText={false}
        />
      </Actions>
    </>
  );
};

export const checkIcon = (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 7.31372L6.65685 12.9706L17.9706 1.65687"
      stroke="#7C97D7"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

import { useFormikContext } from "formik";
import { Button, Div } from "fuse-shared-ui";
import { TemplateFormValue } from "../SelectHostingForm/types";
import * as S from "./styles";

type Props = {
  activeIndex?: number;
  isLoading: boolean;
  onCancel: () => void;
};

const TemplateFormButtons = ({ activeIndex, isLoading, onCancel }: Props) => {
  const { errors, values } = useFormikContext<TemplateFormValue>();
  const isContinueDisabled = !!errors.name || !values.name;

  return (
    <Div w={448} mt={24} justifyEnd>
      <Div dflex>
        <Button
          w={104}
          mr={16}
          variant="secondary"
          data-cy="cancelButton"
          onClick={onCancel}
        >
          {activeIndex === 0 ? "Cancel" : "Go back"}
        </Button>
        <S.CreateTemplateButton
          data-cy="createImporterPopupButton"
          isDisabled={isLoading || isContinueDisabled}
          disableOnErrors={true}
        >
          Save
        </S.CreateTemplateButton>
      </Div>
    </Div>
  );
};

export default TemplateFormButtons;

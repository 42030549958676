import { FormikTextInput } from "components/common/form/fields";
import { Div } from "fuse-shared-ui";
import { useValidationForm } from "../../hooks";
import ValidationTypeSelect from "../ValidationTypeSelect";
import ValidationOptions from "./ValidationOption";

type ValidationFormProps = {
  name: string;
  columnType: string;
};

const ValidationForm = ({ name, columnType }: ValidationFormProps) => {
  const { validation, isDefault, hasOptions } = useValidationForm({ name });

  return (
    <Div w100 flexColumn>
      <Div w100 dflex alignStart mb={16}>
        <Div grow>
          <ValidationTypeSelect
            isDisabled={isDefault}
            includeDefaultOptions={isDefault}
            name={name}
            type={columnType}
          />
        </Div>
        {hasOptions ? (
          <Div w="38%">
            <ValidationOptions
              name={`${name}.options`}
              options={validation.options}
            />
          </Div>
        ) : null}
      </Div>
      {validation ? (
        <Div grow dflex alignEnd>
          <FormikTextInput
            label="Edit error message"
            placeholder="Edit error message"
            name={`${name}.message`}
            labelTooltip="This error message will appear if the user does not meet the validation requirements"
          />
        </Div>
      ) : null}
    </Div>
  );
};

export default ValidationForm;

import { EnvironmentType } from "fuse-importer";
import {
  defaultTableHeaderHeightPx,
  defaultTableRowHeightPx,
} from "../../common/Table/TableView/common";

type Environments = {
  [K in EnvironmentType]?: any;
};
export const baseUrls: Environments = {
  local: "http://localhost:3000",
  staging: "https://staging-fuse-aws.flatirons.com",
  production: "https://fuse.flatirons.com",
};
export const baseWsUrls: Environments = {
  local: "ws://localhost:3000/cable",
  staging: "wss://staging-fuse-aws.flatirons.com/cable",
  production: "wss://fuse.flatirons.com/cable",
};

const MINIMUM_TABLE_HEIGHT = 100;
type GetTableHeightArgs = {
  headerHeight: number;
  rowHeight: number;
  maxVisibleRows: number;
  minimumTableHeight?: number;
  dataCount: number;
};
export const getTableHeight = ({
  headerHeight = defaultTableHeaderHeightPx,
  rowHeight = defaultTableRowHeightPx,
  maxVisibleRows = 7,
  minimumTableHeight = MINIMUM_TABLE_HEIGHT,
  dataCount,
}: GetTableHeightArgs) =>
  Math.max(
    headerHeight + rowHeight * Math.min(dataCount, maxVisibleRows + 0.5),
    minimumTableHeight
  );

import { createContext, useCallback, useContext, useState } from "react";
import { ErrorDetail, Log, LogsTableContextState } from "../interfaces";

const LogsTableContext = createContext<LogsTableContextState>(
  {} as LogsTableContextState
);

export const LogsTableProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [
    errorDetailToDisplay,
    setErrorDetailToDisplay,
  ] = useState<ErrorDetail | null>(null);
  const [logToDisplay, setLogToDisplay] = useState<Log | null>(null);

  const closeErrorDetail = useCallback(() => {
    setErrorDetailToDisplay(null);
  }, []);

  const openErrorDetailModal = (message: string) => {
    setErrorDetailToDisplay({ message });
  };

  const closeLogDetailModal = useCallback(() => {
    setLogToDisplay(null);
  }, []);

  const openLogDetailModal = useCallback(
    (log: Log) => setLogToDisplay(log),
    []
  );

  return (
    <LogsTableContext.Provider
      value={{
        errorDetailToDisplay,
        closeErrorDetail,
        openErrorDetailModal,
        logToDisplay,
        closeLogDetailModal,
        openLogDetailModal,
      }}
    >
      {children}
    </LogsTableContext.Provider>
  );
};

export const useLogsTableContext = () => useContext(LogsTableContext);

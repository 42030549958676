import { Div } from "fuse-shared-ui";
import styled from "styled-components";

export const ImagesContainer = styled(Div)`
  @media all and (max-width: 1220px) {
    display: none;
  }
`;

export const Elipse1 = styled(Div)`
  margin-top: 134px;
  margin-right: -1px;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Elipse2 = styled(Div)`
  margin-top: 302px;
  margin-right: -70px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 85%;
`;

export const Rectangle1 = styled(Div)`
  margin-top: 302px;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Circle1 = styled(Div)`
  margin-top: 339.79px;
  margin-right: 32.21px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  opacity: 50%;
`;

export const Circle2 = styled(Div)`
  margin-top: 375.66px;
  margin-right: 74.45px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  opacity: 50%;
`;

import { Redirect, Route, Switch } from "react-router";
import Account from "./Account";
import Billing from "./Billing";
import ImporterStylePreferences from "./ImporterStylePreferences";
import {
  ACCOUNT_ROUTE,
  ACCOUNT_TEAM_ROUTE,
  // ACCOUNT_HOME_ROUTE,
  ACCOUNT_TEMPLATES_ROUTE,
  API_CREDENTIALS_ROUTE,
  BILLING_ROUTE,
  DEVELOPERS_ROUTE,
  IMPORTER_STYLE_PREFERENCES_ROUTE,
  IMPORTS_ROUTE,
  ORGANIZATION_SETTINGS_ROUTE,
  WEBHOOK_LOGS_ROUTE,
} from "./common";
import { NavigationLayout } from "./common/NavigationLayout";
// import Home from "./Home";
import DevelopersRoutes from "./DevelopersRoutes";
import ImportsRoute from "./ImportsRoutes";
import OrganizationSettings from "./OrganizationSettings";
import TeamMembers from "./TeamMembers";
import TemplateRoutes from "./TemplateRoutes";
import { NavigationContextProvider } from "./common/NavigationLayout/NavigationContextProvider";

const AccountRoutes = (): JSX.Element => {
  return (
    <Switch>
      <NavigationContextProvider>
        <NavigationLayout>
          <Switch>
            <Route path={IMPORTER_STYLE_PREFERENCES_ROUTE}>
              <ImporterStylePreferences />
            </Route>
            <Route path={ACCOUNT_TEMPLATES_ROUTE}>
              <TemplateRoutes />
            </Route>
            <Route path={IMPORTS_ROUTE}>
              <ImportsRoute />
            </Route>
            <Route exact path={ACCOUNT_TEAM_ROUTE}>
              <TeamMembers />
            </Route>
            <Route exact path={ACCOUNT_ROUTE}>
              <Account />
            </Route>
            <Route exact path={API_CREDENTIALS_ROUTE}>
              <DevelopersRoutes customDefaultTab="apikeys" />
            </Route>
            <Route exact path={DEVELOPERS_ROUTE}>
              <DevelopersRoutes />
            </Route>
            <Route exact path={BILLING_ROUTE}>
              <Billing />
            </Route>
            <Route exact path={WEBHOOK_LOGS_ROUTE}>
              <DevelopersRoutes customDefaultTab="logs" />
            </Route>
            <Route exact path={ORGANIZATION_SETTINGS_ROUTE}>
              <OrganizationSettings />
            </Route>
            <Route
              component={() => <Redirect to={ACCOUNT_TEMPLATES_ROUTE} />}
            />
          </Switch>
        </NavigationLayout>
      </NavigationContextProvider>
    </Switch>
  );
};

export default AccountRoutes;

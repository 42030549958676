import { colors, Div, P } from "fuse-shared-ui";
import styled from "styled-components";

type Props = {
  title: string;
  rightContent?: React.ReactNode;
  testID?: string;
};

const HeaderContainer = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  width: 100%;
`;

const LeftWrapper = styled(Div)``;
const RightWrapper = styled(Div)`
  padding: 18px 0;
`;

const HeaderTitle = styled(Div)`
  ${({ theme }) => theme.textStyles.wh1};
  ${({ theme }) => theme.colors.text};

  @media all and (${({ theme }) => theme.media.md}) {
    ${({ theme }) => theme.textStyles.wh2};
  }

  @media all and (${({ theme }) => theme.media.xs}) {
    ${({ theme }) => theme.textStyles.wh4};
  }
`;

export const Header = ({ title, rightContent, testID }: Props) => {
  return (
    <HeaderContainer>
      <LeftWrapper>
        <HeaderTitle as="h1" data-cy={testID} m={0}>
          {title}
        </HeaderTitle>
      </LeftWrapper>

      <RightWrapper>{rightContent}</RightWrapper>
    </HeaderContainer>
  );
};

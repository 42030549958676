export type ThrottleFunction<T extends (...args: any[]) => void> = (
  func: T,
  delay: number
) => T;

export function throttle<T extends (...args: any[]) => void>(
  func: T,
  delay: number
): T {
  let lastCall = 0;
  return function (...args: Parameters<typeof func>) {
    const now = Date.now();

    if (now - lastCall >= delay) {
      func.apply(self, args);
      lastCall = now;
    }
  } as T;
}

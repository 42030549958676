import Form, { FormikSumbitButton } from "components/common/form/Form";
import * as yup from "yup";

import {
  FormikAutocomplete,
  FormikRadioButton,
  FormikTextInput,
} from "components/common/form/fields";
import {
  yupRequiredUrl,
  yupRequiredWithLabel,
} from "components/common/form/validations";
import { useFormikContext } from "formik";
import {
  Button,
  Div,
  FormInputLabel,
  ModalScrollArea,
  Span,
  Spinner,
  TallModal,
  TallModalLayout,
  addToast,
  api,
  colors,
  useTableContext,
} from "fuse-shared-ui";
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

const FormButtonsWrapper = styled(Div)`
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-right: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: end;
  right: 0px;
  width: 100%;
`;

const IconButton = styled(Div)`
  display: flex;
  padding: 6px 20px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  ${({ disabledWhen }) =>
    disabledWhen &&
    css`
      opacity: 0.5;
      user-select: none;
    `}
`;

type ImportModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isEditMode?: boolean;
  initialValues?: any;
};

enum WebhookType {
  RECORDS = "records",
  STATUS = "status",
}

const SpinnerWrapper = styled(Div)`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const textFields = [
  {
    label: "Webhook Name",
    name: "name",
    placeholder: "Name your webhook",
    initialValue: "",
    validation: yupRequiredWithLabel("Name"),
  },
  {
    label: "Endpoint URL",
    name: "url",
    placeholder: "https://example.com/webhook",
    initialValue: "",
    type: "url",
    validation: yupRequiredUrl,
  },
  {
    label: (
      <Div gap={8} dflex>
        <Div>Secret Key (Optional)</Div>
        <Div>
          <a
            data-cy="secret-key-documentation"
            href="https://fuse-docs.flatirons.com/accessing-data/webhook-security"
            target="__blank"
          >
            What is this?
          </a>
        </Div>
      </Div>
    ),
    name: "secret_token",
    placeholder: "*******",
    initialValue: "",
    type: "password",
  },
];

const fields = [
  ...textFields,
  {
    label: "Webhook Type*",
    name: "webhook_type",
    placeholder: "Webhook type",
    initialValue: "",
    validation: yupRequiredWithLabel("Webhook Type"),
  },
  {
    label: "Select an importer*",
    name: "template_id",
    placeholder: "Choose Importers to receive data from",
    initialValue: "",
    validation: yupRequiredWithLabel("Importer"),
  },
  {
    label: "Rate Limit",
    name: "rate_limit",
    placeholder: "Rate limit",
    initialValue: 3,
    validation: yup.string().when("webhook_type", {
      is: (val: string) => val === WebhookType.RECORDS,
      then: yupRequiredWithLabel("Rate limit"),
    }),
  },
];

const RATE_LIMIT_OPTIONS = [
  { value: 1, label: "1" },
  { value: 3, label: "3" },
  { value: 5, label: "5" },
];

const RateLimitField = () => {
  const { values } = useFormikContext<any>();

  return (
    <Div mt={16} mb={8}>
      {values.webhook_type === WebhookType.RECORDS && (
        <FormikAutocomplete
          data-cy="rate-limit-input"
          label="Rate Limit"
          name="rate_limit"
          placeholder="Maximum number of requests sent in parallel to the webhook"
          options={RATE_LIMIT_OPTIONS}
          labelTooltip="Number of requests sent simultaneously"
        />
      )}
    </Div>
  );
};

const ENABLE_RECORD_WEBHOOK =
  process.env.REACT_APP_ENABLE_RECORD_WEBHOOK === "true";

const TestButton = ({ onClick, disabled }) => {
  const { isValid } = useFormikContext();

  return (
    <Div flexColumn mt={24} mb={40}>
      <FormInputLabel data-cy="test-webhook-text">Test Webhook</FormInputLabel>
      <Div body3 mt={8} dflex spaceBetween>
        <Span data-cy="test-webhook-description">
          Send a test webhook to see if the URL can receive records from Fuse
        </Span>
        <IconButton
          data-cy={"test-webhook-button"}
          onClick={onClick}
          buttonText
          disabledWhen={disabled || !isValid}
        >
          {sendIcon}
          Send test
        </IconButton>
      </Div>
    </Div>
  );
};

const CreateModal = ({
  isOpen,
  onClose,
  initialValues = {},
  isEditMode = false,
}: ImportModalProps) => {
  const { getData } = useTableContext();
  const [templateOptions, setTemplateOptions] = useState([]);
  const formRef = useRef(null);
  const [isSendingTest, setIsSendingTest] = useState(false);

  const onSubmit = async (value) => {
    try {
      const url = initialValues.id
        ? `/api/v1/organizations/webhooks/${initialValues.id}`
        : `/api/v1/organizations/webhooks`;
      const method = isEditMode ? api.put : api.post;
      await method(url, {
        ...value,
      });
      isEditMode
        ? addToast("Webhook edited", "success")
        : addToast("Webhook created", "success");
      getData();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const onTest = async () => {
    try {
      if (!formRef.current) return;
      if (!formRef.current.isValid) {
        formRef.current.validateForm();
        return;
      }

      const { values } = formRef.current;
      if (!values.url) {
        return;
      }

      setIsSendingTest(true);

      await api.post(`/api/v1/organizations/webhooks_test`, values);

      addToast("Test sent", "success");
    } catch (error) {
      console.error(error);
    } finally {
      setIsSendingTest(false);
    }
  };

  const getImporters = async () => {
    try {
      const { data } = await api.get(`/api/v1/templates/all`);
      const options = data.map((t) => {
        return { value: t.id, label: t.name, ...t };
      });
      setTemplateOptions(options);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImporters();
  }, []);

  const disableOnEdit = ["secret_token"];

  const headerTextUI = (
    <Div data-cy="webhooks-pop-up" centered mt={30}>
      <Div
        alignSelfCenter
        textAlignCenter
        mb={20}
        gap={8}
        flexColumn
        maxw={553}
      >
        <Div data-cy="webhooks-pop-up-title" h5 c={colors.textPrimary}>
          {isEditMode
            ? `Edit Webhook of “${initialValues.template_name}” Importer`
            : "Create Webhook"}
        </Div>
        <Div data-cy="webhooks-pop-up-description" body3>
          Select an Importer to receive data from. Remember that you can create
          only one Webhook type per Importer.
        </Div>
      </Div>
    </Div>
  );

  return (
    <Form
      formikRef={formRef}
      fields={fields}
      onSubmit={onSubmit}
      autoComplete="off"
      initialValues={initialValues}
    >
      <TallModal isOpen={isOpen} onClose={onClose}>
        <TallModalLayout>
          <></>
          <ModalScrollArea withShadow={false}>
            {headerTextUI}
            {isEditMode ? null : (
              <Div flexColumn gap={8} maxw={654} mb={50}>
                <FormInputLabel data-cy="webhook-type-label">
                  Webhook type
                </FormInputLabel>
                {ENABLE_RECORD_WEBHOOK && (
                  <FormikRadioButton
                    name="webhook_type"
                    label={"Records"}
                    data-cy={"records-radio-button"}
                    value={WebhookType.RECORDS}
                    description={
                      <>
                        Send submitted records to your API in batches.
                        Recommended if you want us to push data to you, or if
                        you want the option to communicate back-end validation
                        issues to users.{" "}
                        <a
                          data-cy="record-link"
                          href="https://fuse-docs.flatirons.com/accessing-data/record-webhooks"
                          target="__blank"
                        >
                          Learn more
                        </a>
                      </>
                    }
                  />
                )}
                <FormikRadioButton
                  name="webhook_type"
                  label={"Status"}
                  data-cy={"status-radio-button"}
                  value={WebhookType.STATUS}
                  description={
                    <>
                      Status webhooks are sent when a new import has been
                      submitted and is available for consumption via the API.{" "}
                      <a
                        data-cy="status-link"
                        href="https://fuse-docs.flatirons.com/accessing-data/status-webhooks"
                        target="__blank"
                      >
                        Learn more
                      </a>
                    </>
                  }
                />
              </Div>
            )}

            {isEditMode ? null : (
              <FormikAutocomplete
                data-cy={"select-template-input"}
                name="template_id"
                options={templateOptions}
                label="Select an Importer"
                placeholder="Choose Importers to receive data from"
                id="select-template"
              />
            )}

            <Div>
              {textFields.map(
                ({ label, placeholder, name, type = "text" }, index) => (
                  <Div key={index} w100 mt={16}>
                    <FormikTextInput
                      {...{
                        label,
                        placeholder,
                        name,
                        autocomplete: "off",
                        isDisabled: disableOnEdit.includes(name) && isEditMode,
                      }}
                      type={type}
                    />
                  </Div>
                )
              )}

              <RateLimitField />
            </Div>
            <TestButton onClick={onTest} disabled={isSendingTest} />
          </ModalScrollArea>
          <Div h={85} centered>
            <FormButtonsWrapper>
              <Button
                data-cy="webhook-pop-up-cancel-button"
                mr={16}
                w={104}
                variant="secondary"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <FormikSumbitButton
                w={200}
                disableOnErrors={true}
                data-cy={"create-webhook-submit-button"}
              >
                <Div>{isEditMode ? "Edit Webhook" : "Create Webhook"}</Div>
              </FormikSumbitButton>
            </FormButtonsWrapper>
          </Div>
        </TallModalLayout>
        {isSendingTest && (
          <SpinnerWrapper centered>
            <Spinner />
          </SpinnerWrapper>
        )}
      </TallModal>
    </Form>
  );
};

export default CreateModal;

const sendIcon = (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.56054 8.83685C1.73279 8.51849 1.69835 7.36013 2.50571 6.99314L13.4625 2.0128C14.3036 1.63045 15.169 2.49579 14.7866 3.33697L9.80629 14.2937C9.4393 15.1011 8.28094 15.0666 7.96258 14.2389L6.62156 10.7522C6.51998 10.4881 6.31128 10.2794 6.04719 10.1779L2.56054 8.83685Z"
      stroke="#333333"
      strokeWidth="2"
    />
  </svg>
);

type WindowProps = typeof window & {
  Intercom: any;
};

// Reference from
// https://developers.intercom.com/installing-intercom/web/methods
type ActionProps = string;
type ArgumentProps = string | number;

export const handleIntercom = (
  action: ActionProps = "showNewMessage",
  argument: ArgumentProps
) => {
  const _window = window as WindowProps;
  if (!_window || !argument) return;

  if (_window?.Intercom && _window?.Intercom.booted) {
    _window.Intercom(action, argument);
  }
};

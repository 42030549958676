export const statusActiveIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5ZM4.94194 5.53291C4.69786 5.28883 4.30214 5.28883 4.05806 5.53291C3.81398 5.77699 3.81398 6.17272 4.05806 6.4168L5.11872 7.47746C5.3628 7.72153 5.75852 7.72153 6.0026 7.47746L8.12392 5.35614C8.368 5.11206 8.368 4.71633 8.12392 4.47225C7.87984 4.22817 7.48412 4.22817 7.24004 4.47225L5.56066 6.15163L4.94194 5.53291Z"
      fill="#00D085"
    />
  </svg>
);

export const statusPendingIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5ZM6 2.875C6.34518 2.875 6.625 3.15482 6.625 3.5V6C6.625 6.34518 6.34518 6.625 6 6.625C5.65482 6.625 5.375 6.34518 5.375 6V3.5C5.375 3.15482 5.65482 2.875 6 2.875ZM6 7.375C6.34518 7.375 6.625 7.65482 6.625 8V8.25C6.625 8.59518 6.34518 8.875 6 8.875C5.65482 8.875 5.375 8.59518 5.375 8.25V8C5.375 7.65482 5.65482 7.375 6 7.375Z"
      fill="#FF9A34"
    />
  </svg>
);

export const deleteIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 5.16666H18.5L17.9875 15.4163C17.8545 18.0774 15.6581 20.1667 12.9938 20.1667H9.00625C6.34188 20.1667 4.14554 18.0774 4.01248 15.4163L3.5 5.16666Z"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M6.83301 5.16666V4.83332C6.83301 3.17647 8.17615 1.83332 9.83301 1.83332H12.1663C13.8232 1.83332 15.1663 3.17647 15.1663 4.83332V5.16666"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M1.83301 5.16666H20.1663"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M13.5 10.1667V14.3333"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M8.5 10.1667V14.3333"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const editIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7951 5.90318L7.79396 19.9044L2.97278 20.4829L3.55132 15.6617L17.5525 1.66054C17.5891 1.64879 17.632 1.63592 17.6805 1.62278C17.8837 1.56774 18.1757 1.51068 18.5188 1.50595C19.1849 1.49676 20.0653 1.68066 20.9201 2.53553C21.775 3.3904 21.9589 4.27079 21.9497 4.93692C21.945 5.27995 21.8879 5.57197 21.8329 5.77516C21.8198 5.82368 21.8069 5.86655 21.7951 5.90318Z"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M14.2671 3.53162L19.6546 8.9191"
      stroke="#333333"
      strokeWidth="2"
    />
  </svg>
);

export const reorderIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M3 15h18v-2H3v2zm0 4h18v-2H3v2zm0-8h18V9H3v2zm0-6v2h18V5H3z" />
  </svg>
);

export const greenCheckIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM9.70711 11.2426C9.31658 10.8521 8.68342 10.8521 8.29289 11.2426C7.90237 11.6331 7.90237 12.2663 8.29289 12.6568L10.4142 14.7781L11.1213 15.4852L11.8284 14.7781L16.0711 10.5355C16.4616 10.145 16.4616 9.5118 16.0711 9.12128C15.6805 8.73076 15.0474 8.73076 14.6569 9.12128L11.1213 12.6568L9.70711 11.2426Z"
      fill="#00D085"
    />
  </svg>
);

export const deleteImageIcon = (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="68" height="68" fill="white" />
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M8.79639 4.52699C8.79639 3.70303 9.73698 3.23263 10.3962 3.72688L33.7964 21.27C33.9246 21.3662 34.0001 21.5171 34.0001 21.6774L34.0001 46.0152C34.0001 46.4346 33.5212 46.6741 33.1856 46.4225L9.59669 28.7378C9.0929 28.3601 8.79639 27.7672 8.79639 27.1375L8.79639 4.52699Z"
        fill="url(#paint0_linear_3023_1210)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M8.7959 63.1268C8.7959 63.9508 9.73649 64.4212 10.3957 63.9269L33.7959 46.3838C33.9241 46.2876 33.9996 46.1367 33.9996 45.9764L33.9996 21.6386C33.9996 21.2192 33.5207 20.9797 33.1851 21.2313L9.5962 38.9161C9.09241 39.2937 8.7959 39.8866 8.7959 40.5163L8.7959 63.1268Z"
        fill="url(#paint1_linear_3023_1210)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M59.2039 4.8727C59.2039 4.04874 58.2633 3.57833 57.604 4.07258L34.1528 21.654C34.0567 21.7261 34.0002 21.8392 34.0002 21.9592L34.0002 46.616C34.0002 46.9304 34.359 47.1098 34.6104 46.9213L58.4036 29.0835C58.9073 28.7058 59.2039 28.1129 59.2039 27.4832L59.2039 4.8727Z"
        fill="url(#paint2_linear_3023_1210)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M59.2041 63.4725C59.2041 64.2965 58.2635 64.7669 57.6043 64.2726L34.1531 46.6912C34.057 46.6191 34.0004 46.5061 34.0004 46.386L34.0004 21.7292C34.0004 21.4149 34.3592 21.2354 34.6107 21.424L58.4038 39.2618C58.9076 39.6394 59.2041 40.2323 59.2041 40.862L59.2041 63.4725Z"
        fill="url(#paint3_linear_3023_1210)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3023_1210"
        x1="28.828"
        y1="2.27198"
        x2="10.8931"
        y2="6.63455"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF710A" />
        <stop offset="1" stopColor="#FD9827" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3023_1210"
        x1="28.8275"
        y1="65.3818"
        x2="10.8926"
        y2="61.0193"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF710A" />
        <stop offset="1" stopColor="#FD9827" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3023_1210"
        x1="41.7498"
        y1="25.126"
        x2="70.7926"
        y2="29.9743"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDD172" />
        <stop offset="1" stopColor="#D0A617" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3023_1210"
        x1="41.7501"
        y1="43.2192"
        x2="70.7929"
        y2="38.3709"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDD172" />
        <stop offset="1" stopColor="#D0A617" />
      </linearGradient>
    </defs>
  </svg>
);

import { Div, TextInput, colors } from "fuse-shared-ui";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { capitalize } from "../../../../../../utils";
import { Dropdown } from "../../../../../common/Dropdown";
import { ArrowDown, SearchIcon } from "./icons";
import { CommonQueryData } from "./interfaces";

export type FilterProps = {
  icon: JSX.Element;
  label: string;
  data: CommonQueryData[];
  keyToRender: string;
  onFilter: (payload: string) => void;
  activeFilter: string;
  filterType: string;
};

const DropdownContainer = styled(Div)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 250px;
  max-width: max-content;
  min-width: 232px;
  overflow-y: auto;
  padding: 0px 16px 16px 16px;

  ${({ theme }) => theme.css.scrollbarDark};
`;

const DropdownItem = styled(Div)<{ isSelected?: boolean }>`
  ${({ theme }) => theme.textStyles.body1};
  cursor: pointer;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.highlight01 : theme.colors.text};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 12px 16px;
  transition: background 0.25s;

  :hover,
  :focus {
    background: ${({ theme }) => theme.colors.gray5};
  }
`;

const StyledInput = styled(TextInput)`
  input {
    border: none;

    &,
    :hover {
      background: #fafafa;
    }

    &,
    ::placeholder {
      color: ${({ theme }) => theme.colors.gray700};
      ${({ theme }) => theme.textStyles.body1};
    }
  }
`;

const dropdownStyles = css`
  background: none;
  border-radius: none;
  border: none;
`;

export const Filter = ({
  icon,
  label,
  data,
  keyToRender,
  onFilter,
  activeFilter,
  filterType,
}: FilterProps) => {
  const labelBasedOnActiveFilter = data.find((d) => d.slug === activeFilter)?.[
    keyToRender
  ];
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filteredText, setFilteredText] = useState("");

  const toggleFilter = () => {
    setIsFilterOpen((oldState) => !oldState);
  };

  const filteredData = useMemo(() => {
    if (!data) return [];
    if (!filteredText) return data;

    return data.filter(
      (d) =>
        d.slug.includes(filteredText) ||
        d?.[keyToRender]?.includes(filteredText)
    );
  }, [data, filteredText]);

  const debouncedSetFilteredText = debounce(
    (setText, value) => setText(value),
    350
  );

  return (
    <Div dflex flexColumn>
      <Div
        dflex
        alignCenter
        gap={12}
        p="6px 20px"
        clickable
        onClick={toggleFilter}
      >
        {icon}

        <Div buttonTextSmall c={colors.text} data-cy={`label-${filterType}`}>
          {labelBasedOnActiveFilter || label}
        </Div>

        <ArrowDown />
      </Div>

      <Dropdown
        placement={"bottom-end"}
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        offset={[2, 7]}
        customStyles={dropdownStyles}
      >
        <DropdownContainer>
          <Div pSticky top={0} bg={colors.white} pt={16}>
            <StyledInput
              placeholder={capitalize(`Select ${filterType}`)}
              data-cy="option"
              value={filteredText}
              onChange={(v: string) => {
                debouncedSetFilteredText(setFilteredText, v);
              }}
              icon={<SearchIcon />}
              iconPlacement="left"
              transparentBgOnlyOnHover={false}
              inputPadding="0px 16px 0px 35px"
              height={41}
            />
          </Div>

          {filteredData.length === 0 && (
            <Div p="8px 0px" c={colors.text}>
              No results found
            </Div>
          )}

          {filteredData.map((item, i) => (
            <DropdownItem
              key={item.id}
              isSelected={activeFilter === item.slug}
              onClick={() => onFilter(item.slug)}
              data-cy={`${filterType}-option-${i}`}
            >
              {item[keyToRender]}
            </DropdownItem>
          ))}
        </DropdownContainer>
      </Dropdown>
    </Div>
  );
};

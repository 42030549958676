import styled, { css } from "styled-components";
import { Div } from "../../../../styled/utils";
import { TableViewColumn } from "../types";

export const defaultTableRowHeightPx = 60;
export const defaultTableHeaderHeightPx = 60;

export const getColumnWidthsPx = (
  columns: TableViewColumn[],
  rowWidth: number
) => {
  const round4 = (n) => Number((Math.floor(n * 10000) / 10000).toFixed(4));

  const totalColumnWidthCount = columns.reduce((sum, c) => {
    return sum + (c.width ? c.width : 1);
  }, 0);

  let remainingPercentage = 1;
  const percentages = columns.map((column, index) => {
    if (index === columns.length - 1) {
      // for the last column, just use the remaining percentage
      // to avoid floating point errors
      return round4(remainingPercentage);
    }

    let percentage = (column.width || 1) / totalColumnWidthCount;

    percentage = round4(percentage);
    remainingPercentage -= percentage;
    remainingPercentage = round4(remainingPercentage);

    return percentage;
  });

  const res = percentages.map((p) => Math.floor(p * rowWidth));
  return res.map((r) => r + "px");
};

export const TableViewCellContainer = styled(Div)`
  display: flex;
  padding-left: 16px;
  align-items: center;
  color: ${(p) => p.theme.colors.textPrimary};

  ${({ onClick }) =>
    onClick &&
    css`
      :hover {
        cursor: pointer;
      }
    `}
  ${({ theme }) => theme.css.body3};
`;

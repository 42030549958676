import styled, { css } from "styled-components";
import { WhiteCheckIcon } from "../../common";
import { Div } from "../../styled";

type StepIndexWrapperProps = {
  isSelected: boolean;
};
const StepIndexWrapper = styled(Div)<StepIndexWrapperProps>`
  height: 22px;
  width: 22px;
  color: ${(p) => p.theme.colors.gray400};
  border-radius: 50%;
  background-color: none;
  border: 1px solid ${(p) => p.theme.colors.gray400};

  ${(p) =>
    p.isSelected &&
    css`
      height: 32px;
      width: 32px;
      border: solid 1px ${(p) => p.theme.colors.blue500};
      color: ${(p) => p.theme.colors.white};
      background-color: ${(p) => p.theme.colors.blue500};
    `};
`;

export const StepIndex = ({ index, isSelected, isCompleted }) => (
  <>
    {isCompleted ? (
      <Div centered body2 bold mr={8}>
        <WhiteCheckIcon />
      </Div>
    ) : (
      <StepIndexWrapper centered body2 bold mr={8} isSelected={isSelected}>
        {index}
      </StepIndexWrapper>
    )}
  </>
);

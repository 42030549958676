export const TemplatesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <rect
      x="1.6665"
      y="1.66675"
      width="5.33333"
      height="5.33333"
      rx="1"
      stroke="black"
      strokeWidth="2"
    />
    <rect
      x="11"
      y="1.66675"
      width="5.33333"
      height="5.33333"
      rx="1"
      stroke="black"
      strokeWidth="2"
    />
    <rect
      x="1.6665"
      y="11"
      width="5.33333"
      height="5.33333"
      rx="1"
      stroke="black"
      strokeWidth="2"
    />
    <rect
      x="11"
      y="11"
      width="5.33333"
      height="5.33333"
      rx="1"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);

export const ImportsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M1.6665 13.0001L8.99984 16.3334L16.3332 13.0001M1.6665 8.66675L8.99984 12.0001L16.3332 8.66675M16.3332 4.33341L8.99984 1.66675L1.6665 4.33341L8.99984 7.66675L16.3332 4.33341Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.6177 6.66675L7.97556 11.3089L3.33345 6.66675"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M9.23846 17.1234C13.3532 17.1234 16.6888 13.7878 16.6888 9.67303C16.6888 5.5583 13.3532 2.22266 9.23846 2.22266C5.12373 2.22266 1.78809 5.5583 1.78809 9.67303C1.78809 13.7878 5.12373 17.1234 9.23846 17.1234Z"
      stroke="#262626"
      strokeWidth="1.65564"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5167 17.9503L15.0332 15.4668"
      stroke="#262626"
      strokeWidth="1.65564"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React from "react";
import styled, { css } from "styled-components";
import { appCss } from "./theme";

export const themeProps = css`
  ${(props: any) =>
    Object.keys(props)
      .map((p) => props.theme.css[p])
      .filter((c) => c)};
`;

export type CSSProps = {
  [K in keyof typeof appCss]?: any;
};

// may be used to avoid adding styled components prop types
// keeps component prop references to css snippets in theme
export type CSSAnyProps = CSSProps | { [key: string]: any };

export const Div = styled.div<CSSProps>`
  ${themeProps};
`;

export const HTMLButton = styled.button<CSSProps>`
  ${themeProps};
`;

export const Span = styled.span<CSSProps>`
  ${themeProps};
`;

export const Label = styled.label<CSSProps>`
  ${themeProps};
`;

export const P = styled.p<CSSProps>`
  ${themeProps};
`;

export const BaseButton = styled.button<CSSProps>`
  ${themeProps};
  outline: none;
  margin: none;
  padding: none;
  background: transparent;
  border: none;
`;

export type DivProps = React.HTMLAttributes<HTMLDivElement>;
export type HTMLButtonProps = React.HTMLAttributes<HTMLButtonElement>;
export type HTMLInputProps = React.InputHTMLAttributes<HTMLInputElement>;
export type HTMLTextAreaProps = React.TextareaHTMLAttributes<
  HTMLTextAreaElement
>;
export type HTMLLabelProps = React.InputHTMLAttributes<HTMLLabelElement>;
export type DivCSSProps = DivProps & CSSProps;
export type HTMLButtonCSSProps = HTMLButtonProps & CSSProps;
export type HTMLInputCSSProps = HTMLInputProps & CSSProps;
export type HTMLTextAreaCSSProps = HTMLTextAreaProps & CSSProps;

import styled from "styled-components";
import { api, Button, Div } from "fuse-shared-ui";

const ResendButton = styled(Button)`
  color: ${(p) => p.theme.colors.blue500};
`;

type ResendInviteProps = {
  organization_id: number;
  user: any;
};

export const ResendInvite = ({
  organization_id,
  user: { email },
}: ResendInviteProps) => {
  const onResendInvite = async (email: string, organization_id: number) => {
    await api.post(
      "/users/invitation",
      {
        email,
        organization_id,
      },
      {
        successMsg: (data) => {
          if (data) return `Invitation sent to ${email}`;
        },
      }
    );
  };

  return (
    <Div alignCenter>
      <ResendButton
        variant="tertiary"
        w={94}
        onClick={() => onResendInvite(email, organization_id)}
      >
        Resend
      </ResendButton>
    </Div>
  );
};

import { Div, TooltipProvider } from "fuse-shared-ui";
import React from "react";
import { OrganizationAvatar } from "./OrganizationAvatar";
import { useUserMenu } from "./useUserMenu";

type DesktopUIProps = {
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DesktopUI = ({ setIsDropdownOpen }: DesktopUIProps) => {
  const { organizationName } = useUserMenu();

  return (
    <Div
      centered
      onClick={() => setIsDropdownOpen(true)}
      data-cy="open-user-menu"
    >
      <TooltipProvider
        tooltip={organizationName?.split(" ")[0]}
        placement="right"
        flip={false}
        centered
        offset={[0, -20]}
      >
        <Div p="16px 24px">
          <OrganizationAvatar name={organizationName} />
        </Div>
      </TooltipProvider>
    </Div>
  );
};

import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Div } from "fuse-shared-ui";

import { flatironsFuseLogo } from "./common/assets/images";
import { laptopIcon } from "./common/icons";

const Container = styled(Div)`
  align-items: center;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 4px 40px rgba(135, 70, 70, 0.08);
  display: flex;
  flex-direction: column;
  margin: 24px;
  padding: 40px 24px;
`;

const MainContent = styled(Div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 116px;
`;

const TextTitle = styled.h2`
  ${(props) => props.theme.css.DM4}
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 0px;
  margin-top: 24px;
  text-align: center;
`;

const TextSubtitle = styled.p`
  ${(props) => props.theme.textStyles.wbody1}
  color: ${(props) => props.theme.colors.gray700};
  display: flex;
  margin-top: 12px;
  text-align: center;
`;

const ImproveYourExperience = () => (
  <>
    <Helmet>
      <title>Flatirons Fuse - Improve your experience</title>
    </Helmet>
    <Container>
      {flatironsFuseLogo}

      <MainContent>
        {laptopIcon}
        <TextTitle>Improve your experience</TextTitle>
        <TextSubtitle>
          Flatirons Fuse is meant to be used on a laptop or desktop computer.
          Please switch devices to continue.
        </TextSubtitle>
      </MainContent>
    </Container>
  </>
);

export default ImproveYourExperience;

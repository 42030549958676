import {
  MutableRefObject,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";
import {
  DuplicateRecordHash,
  DuplicateRecordIds,
  DuplicateValuesCollection,
  Field,
  Record,
  RecordDataSet,
} from "fuse-importer";
import { useDataSetContext } from "../DataSetContextProvider";
import { RecordHashCalculator } from "../SpreadsheetContextProvider/hashCalculators";

type DuplicateDataProps = {
  fields: Field[];
  isReadOnly: boolean;
  children: any;
};

export type State = {
  duplicateRecordIds: Set<string>;
  duplicateValues: MutableRefObject<DuplicateValuesCollection>;
  fieldsWithUniquenessConstraints: MutableRefObject<Field[]>;
  calculateDuplicateRecords: () => void;
  updateDuplicateValues: (
    oldRecord: RecordDataSet,
    newRecord: RecordDataSet,
    initialValidation: boolean,
    updateRecordAndRevalidate: (
      record: Record | Record[],
      initialValidation: boolean,
      loadingIndicator: boolean
    ) => void
  ) => void;
  resetDuplicateProvider: () => void;
};

export const DuplicateDataContext = createContext<State>({} as State);

export const DuplicateDataContextProvider = ({
  fields,
  isReadOnly,
  children,
}: DuplicateDataProps) => {
  const [duplicateRecordIds, setDuplicateRecordIds] = useState<
    DuplicateRecordIds
  >(new Set());
  const { dataSet, getRecord } = useDataSetContext();

  const duplicateValues = useRef<DuplicateValuesCollection>({});
  const fieldsWithUniquenessConstraints = useRef([]);

  const reset = () => {
    fieldsWithUniquenessConstraints.current = [];
    duplicateValues.current = {};
    setDuplicateRecordIds(new Set());
  };

  const resetDuplicateProvider = () => {
    reset();
    calculateDuplicateRecords();
    calculateFieldsWithUniquenessConstraints();
  };

  const calculateFieldsWithUniquenessConstraints = () => {
    const uniqueFields = fields.filter((f) => {
      if (!f.validations) return;

      return (
        f.validations.find((v) => {
          return (
            ["unique_case_sensitive", "unique_case_insensitive"].indexOf(
              v.validation_type
            ) >= 0
          );
        }) !== undefined
      );
    });

    fieldsWithUniquenessConstraints.current = uniqueFields;
  };

  const updateDuplicateValues = (
    oldRecord: RecordDataSet,
    newRecord: RecordDataSet,
    initialValidation: boolean,
    updateRecordAndRevalidate: (
      records: Record | Record[],
      initialValidation: boolean,
      loadingIndicator: boolean
    ) => void
  ) => {
    const recordsToUpdate = [];
    fieldsWithUniquenessConstraints.current.forEach(async (f) => {
      duplicateValues.current[f.name] = duplicateValues.current[f.name] || {};
      let duplicatesForField = duplicateValues.current[f.name];
      const isCaseInsensitive =
        f.validations.find(
          (v) => v.validation_type === "unique_case_insensitive"
        ) !== undefined;

      const recordId = oldRecord._meta.id;
      const oldValue = oldRecord[f.name];
      const newValue = newRecord[f.name];

      if (newValue === oldValue && !initialValidation) {
        return;
      }

      if (oldValue && !initialValidation) {
        let oldValueAccessor = isCaseInsensitive
          ? String(oldValue).toLowerCase()
          : oldValue;
        if (duplicatesForField[oldValueAccessor]) {
          const oldIndex = duplicatesForField[oldValueAccessor].indexOf(
            recordId
          );
          if (oldIndex >= 0) {
            duplicatesForField[oldValueAccessor].splice(oldIndex, 1);
          }

          if (duplicatesForField[oldValueAccessor].length === 1) {
            const remainingRecordId = duplicatesForField[oldValueAccessor][0];
            recordsToUpdate.push(getRecord(remainingRecordId));
          }
        }
      }

      if (newValue) {
        let newValueAccessor = isCaseInsensitive
          ? String(newValue).toLowerCase()
          : newValue;
        duplicatesForField[newValueAccessor] =
          duplicatesForField[newValueAccessor] || [];
        if (!duplicatesForField[newValueAccessor].includes(recordId)) {
          duplicatesForField[newValueAccessor].push(recordId);
        }

        if (duplicatesForField[newValueAccessor].length === 2) {
          const firstRecordIdToHaveValue =
            duplicatesForField[newValueAccessor][0];
          recordsToUpdate.push(getRecord(firstRecordIdToHaveValue));
        }
      }
    });

    if (recordsToUpdate.length > 0) {
      updateRecordAndRevalidate(recordsToUpdate, false, false);
    }
  };

  const calculateDuplicateRecords = async () => {
    const { computeHashesForRecords } = RecordHashCalculator();
    await computeHashesForRecords(
      dataSet.current,
      fields,
      (hashes: DuplicateRecordHash[]) => {
        const duplicateIds = hashes.map((hash) => hash["id"]);
        setDuplicateRecordIds(new Set(duplicateIds));
      }
    );
  };

  const value = {
    duplicateRecordIds,
    duplicateValues,
    fieldsWithUniquenessConstraints,
    calculateDuplicateRecords,
    updateDuplicateValues,
    resetDuplicateProvider,
  };

  return (
    <DuplicateDataContext.Provider value={value}>
      {children}
    </DuplicateDataContext.Provider>
  );
};

export const useDuplicateDataContext = () => useContext(DuplicateDataContext);

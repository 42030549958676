import { useAppContext } from "components/AppContextProvider";
import { Plan, useUserContext } from "components/UserContextProvider";
import { api } from "fuse-shared-ui";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  StripeCoupon,
  StripePaymentMethodData,
  StripePlan,
  StripeSubscription,
} from "stripe";

export type SubscriptionData = {
  subscription: StripeSubscription;
  attached_coupon?: StripeCoupon;
};

export type State = {
  currentPlanPrice: number;
  paymentMethod: StripePaymentMethodData;
  subscriptionData: SubscriptionData;
  isLoadingPaymentMethods: boolean;
  getPaymentMethods: () => void;
  getSubscriptionData: () => void;
  currentSubscriptionPeriodEnd: any;
  plans: Plan[] | null;
  currentPlan?: Plan;
  currentStripePlan?: StripePlan;
  selectedRecurrencePeriod: "month" | "year";
  setSelectedRecurrencePeriod: React.Dispatch<
    React.SetStateAction<"month" | "year">
  >;
};

type PropsTypes = {
  children: React.ReactNode;
};

export const BillingContext = createContext<State>({} as State);

export const BillingContextProvider = ({
  children,
}: PropsTypes): JSX.Element => {
  const [paymentMethod, setPaymentMethod] = useState<StripePaymentMethodData>(
    null
  );
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionData>(
    null
  );
  const [plans, setPlans] = useState<Plan[]>(null);
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(false);
  const {
    user: { active_organization },
  } = useUserContext();

  const [selectedRecurrencePeriod, setSelectedRecurrencePeriod] = useState<
    "month" | "year"
  >(active_organization?.plan?.recurrence_period ?? "year");

  const currentPlan: Plan = active_organization?.plan;

  const { setIsLoadingApp } = useAppContext();

  const getPaymentMethods = async () => {
    setIsLoadingPaymentMethods(true);
    const { data: paymentMethodsData }: any = await api.get(
      "/api/v1/organizations/payment_methods",
      {
        params: { organization_id: active_organization.id },
      }
    );
    setIsLoadingPaymentMethods(false);
    const paymentMethod = paymentMethodsData.data as StripePaymentMethodData;
    setPaymentMethod(paymentMethod);
    return paymentMethod;
  };

  const getSubscriptionData = async () => {
    setIsLoadingApp(true);
    try {
      const { data }: any = await api.get(
        "/api/v1/organizations/subscriptions",
        {
          params: { organization_id: active_organization.id },
        }
      );
      setSubscriptionData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingApp(false);
    }
  };

  const getPlans = async () => {
    const payload = await api.get<Plan[]>(`/api/v1/organizations/plans`);
    const { data: plans } = payload;
    setPlans(plans);
  };

  useEffect(() => {
    getPaymentMethods();
    getPlans();
    getSubscriptionData();
  }, []);

  const currentSubscriptionPeriodEnd =
    subscriptionData?.subscription?.current_period_end * 1000;

  const currentStripePlan = useMemo(() => {
    const subscription = subscriptionData?.subscription;
    const items = subscription?.items?.data || [];
    return items.find((i) => i.plan.usage_type === "licensed")?.plan;
  }, [subscriptionData]);

  const currentPlanPrice = useMemo(() => {
    const priceInCents = currentStripePlan?.amount || 0;
    const price = priceInCents / 100;
    return price;
  }, [currentStripePlan]);

  const value = {
    currentSubscriptionPeriodEnd,
    paymentMethod,
    isLoadingPaymentMethods,
    subscriptionData,
    getPaymentMethods,
    getSubscriptionData,
    plans,
    currentPlan,
    currentPlanPrice,
    currentStripePlan,
    selectedRecurrencePeriod,
    setSelectedRecurrencePeriod,
  };

  return (
    <BillingContext.Provider value={value}>{children}</BillingContext.Provider>
  );
};

export const useBillingContext = (): State => useContext(BillingContext);

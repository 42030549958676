import { useTheme } from "styled-components";

export const closeIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6.00003L18.7742 18.7742"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 18.7742L18.7742 6.00001"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const closeIconCondensed = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6.00003L18.7742 18.7742"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 18.7742L18.7742 6.00001"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const checkIcon = (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.37805 5.80142L3.94469 8.28708L10.6827 1.52003"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const greenCheckIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM9.70711 11.2426C9.31658 10.8521 8.68342 10.8521 8.29289 11.2426C7.90237 11.6331 7.90237 12.2663 8.29289 12.6568L10.4142 14.7781L11.1213 15.4852L11.8284 14.7781L16.0711 10.5355C16.4616 10.145 16.4616 9.5118 16.0711 9.12128C15.6805 8.73076 15.0474 8.73076 14.6569 9.12128L11.1213 12.6568L9.70711 11.2426Z"
      fill="#025F40"
    />
  </svg>
);

export const redXIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L10.5858 12L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L12 10.5858L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L13.4142 12L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L12 13.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071Z"
      fill="#5C1008"
    />
  </svg>
);

export const warningIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 6C12.5523 6 13 6.44772 13 7V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V7C11 6.44772 11.4477 6 12 6ZM12 15C12.5523 15 13 15.4477 13 16V16.5C13 17.0523 12.5523 17.5 12 17.5C11.4477 17.5 11 17.0523 11 16.5V16C11 15.4477 11.4477 15 12 15Z"
      fill="#78430D"
    />
  </svg>
);

export const warningModalIcon = (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3852_5793)">
      <rect width="68" height="68" fill="white" />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M28.5667 8.05781C29.6942 6.10503 32.5127 6.10503 33.6402 8.05781L59.1465 52.236C60.2739 54.1888 58.8646 56.6298 56.6098 56.6298H5.59714C3.34227 56.6298 1.93297 54.1888 3.06041 52.236L28.5667 8.05781Z"
          fill="url(#paint0_linear_3852_5793)"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M34.4372 9.68867C35.5647 7.73589 38.3833 7.73589 39.5107 9.68867L65.8157 55.2503C66.9432 57.2031 65.5339 59.6441 63.279 59.6441H10.6689C8.41407 59.6441 7.00478 57.2031 8.13221 55.2503L34.4372 9.68867Z"
          fill="url(#paint1_linear_3852_5793)"
        />
      </g>
      <rect
        x="29.0371"
        y="21.2637"
        width="6.43183"
        height="21.3972"
        rx="2.92916"
        fill="#FFFDF8"
      />
      <rect
        x="29.0371"
        y="44.7891"
        width="6.43183"
        height="5.61436"
        rx="2.80718"
        fill="#FFFDF8"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3852_5793"
        x1="10.0097"
        y1="28.8128"
        x2="30.2645"
        y2="54.4815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD5C39" />
        <stop offset="1" stopColor="#FFB869" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3852_5793"
        x1="18.8004"
        y1="74.0811"
        x2="85.2564"
        y2="33.8684"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E7C551" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
      <clipPath id="clip0_3852_5793">
        <rect width="68" height="68" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const infoIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17.5C12.3117 17.5 12.5729 17.3946 12.7837 17.1838C12.9946 16.9729 13.1 16.7117 13.1 16.4V12C13.1 11.6883 12.9946 11.4271 12.7837 11.2163C12.5729 11.0054 12.3117 10.9 12 10.9C11.6883 10.9 11.4271 11.0054 11.2163 11.2163C11.0054 11.4271 10.9 11.6883 10.9 12V16.4C10.9 16.7117 11.0054 16.9729 11.2163 17.1838C11.4271 17.3946 11.6883 17.5 12 17.5ZM12 8.7C12.3117 8.7 12.5729 8.59458 12.7837 8.38375C12.9946 8.17292 13.1 7.91167 13.1 7.6C13.1 7.28833 12.9946 7.02708 12.7837 6.81625C12.5729 6.60542 12.3117 6.5 12 6.5C11.6883 6.5 11.4271 6.60542 11.2163 6.81625C11.0054 7.02708 10.9 7.28833 10.9 7.6C10.9 7.91167 11.0054 8.17292 11.2163 8.38375C11.4271 8.59458 11.6883 8.7 12 8.7ZM12 23C10.4783 23 9.04833 22.7113 7.71 22.1337C6.37167 21.5562 5.2075 20.7725 4.2175 19.7825C3.2275 18.7925 2.44375 17.6283 1.86625 16.29C1.28875 14.9517 1 13.5217 1 12C1 10.4783 1.28875 9.04833 1.86625 7.71C2.44375 6.37167 3.2275 5.2075 4.2175 4.2175C5.2075 3.2275 6.37167 2.44375 7.71 1.86625C9.04833 1.28875 10.4783 1 12 1C13.5217 1 14.9517 1.28875 16.29 1.86625C17.6283 2.44375 18.7925 3.2275 19.7825 4.2175C20.7725 5.2075 21.5562 6.37167 22.1337 7.71C22.7113 9.04833 23 10.4783 23 12C23 13.5217 22.7113 14.9517 22.1337 16.29C21.5562 17.6283 20.7725 18.7925 19.7825 19.7825C18.7925 20.7725 17.6283 21.5562 16.29 22.1337C14.9517 22.7113 13.5217 23 12 23Z"
      fill="#025B5B"
    />
  </svg>
);

export const notificationCheckIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 12.3137L9.65685 17.9706L20.9706 6.65687"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const defaultCheckIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" />
    <path
      d="M9 11.9497L11.1213 14.071L15.364 9.82839"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const WhiteCheckIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM8.70711 10.2426C8.31658 9.85208 7.68342 9.85208 7.29289 10.2426C6.90237 10.6331 6.90237 11.2663 7.29289 11.6568L9.41421 13.7781L10.1213 14.4852L10.8284 13.7781L15.0711 9.53549C15.4616 9.14497 15.4616 8.5118 15.0711 8.12128C14.6805 7.73076 14.0474 7.73076 13.6569 8.12128L10.1213 11.6568L8.70711 10.2426Z"
        fill={theme.colors.blue500}
      />
    </svg>
  );
};

export const chevronLeftIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
  >
    <path
      d="M6.22266 1.75L1.00028 6.97238L6.22266 12.1948"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChevronRightIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L7.96317 7.96317L1 14.9263"
        stroke={theme.colors.black || "#3333"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const weatherStarsIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0159 4.5609C13.1442 4.0594 13.8565 4.0594 13.9847 4.5609L14.3163 5.85753C14.8596 7.98189 16.5184 9.64072 18.6428 10.184L19.9394 10.5155C20.4409 10.6438 20.4409 11.3561 19.9394 11.4844L18.6428 11.816C16.5184 12.3592 14.8596 14.018 14.3163 16.1424L13.9847 17.439C13.8565 17.9405 13.1442 17.9405 13.0159 17.439L12.6843 16.1424C12.1411 14.018 10.4823 12.3592 8.3579 11.816L7.06127 11.4844C6.55977 11.3561 6.55977 10.6438 7.06127 10.5155L8.3579 10.184C10.4823 9.64072 12.1411 7.98189 12.6843 5.85754L13.0159 4.5609Z"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M3.5 1.83337V5.16671"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.16634 3.5H1.83301"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.33301 16.8334V20.1667"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.00033 18.5H2.66699"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const chevronDownIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9258 10L11.9626 16.9632L4.99944 10"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlusActionIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2V14"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 8L14 8"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

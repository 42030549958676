import { Plan } from "components/UserContextProvider";
import { Div, DivCSSProps } from "fuse-shared-ui";
import styled from "styled-components";
import { specs } from "../specs";

const SpecWrapper = styled(Div)`
  color: ${(p) => p.theme.colors.black};
`;

type Props = {
  plan: Plan;
} & DivCSSProps;

const PlanSpecs = ({ plan, ...props }: Props) => {
  const formattedPlanTitle = plan.title.split("_")[0].toLowerCase();

  return (
    <Div mt={32} {...props}>
      {specs[formattedPlanTitle].map((element, i) => {
        return (
          <Div dflex mt={12} wbody3 key={i} alignCenter>
            <Div alignCenter mr={12}>
              {checkIcon}
            </Div>
            <SpecWrapper>{element}</SpecWrapper>
          </Div>
        );
      })}
    </Div>
  );
};

export default PlanSpecs;

const checkIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99984 15.3334C12.0499 15.3334 15.3332 12.0501 15.3332 8.00002C15.3332 3.94993 12.0499 0.666687 7.99984 0.666687C3.94975 0.666687 0.666504 3.94993 0.666504 8.00002C0.666504 12.0501 3.94975 15.3334 7.99984 15.3334ZM6.70694 7.25938C6.31642 6.86886 5.68325 6.86886 5.29273 7.25938C4.90221 7.64991 4.90221 8.28307 5.29273 8.6736L6.70694 10.0878L7.41405 10.7949L8.12116 10.0878L10.9496 7.25938C11.3401 6.86886 11.3401 6.2357 10.9496 5.84517C10.5591 5.45465 9.9259 5.45465 9.53537 5.84517L7.41405 7.96649L6.70694 7.25938Z"
      fill="#0EBEBE"
    />
  </svg>
);

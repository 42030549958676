import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { Span } from "fuse-shared-ui";
import React from "react";
import { columnTypesLabel } from "../../fields";
import { ColumnFormValue, ColumnValidation } from "../../types";
import Header from "../../../../components/Header";
import * as S from "./styles";
import ValidationForm from "./ValidationForm";

type Props = {
  isEditing: boolean;
  canAddMoreValidations: boolean;
};

const ValidationsForm = ({ isEditing, canAddMoreValidations }: Props) => {
  const { values, isValid } = useFormikContext<ColumnFormValue>();
  const { column_type: columnType, validations } = values;

  const suffix = isEditing ? "editing" : "creating";
  const columnLabel = columnTypesLabel[columnType];

  const isValidationsEmpty = !validations || validations.length === 0;
  const lastValidationIndex = validations ? validations.length - 1 : -1;
  const hasLastValidationTypeFilled =
    validations && validations[lastValidationIndex]?.validation_type;
  const isAddButtonDisabled = isValidationsEmpty
    ? false
    : !hasLastValidationTypeFilled || !isValid;

  function renderValidationForm(
    validation: ColumnValidation,
    index: number,
    remove: (index: number) => void
  ) {
    const showDeleteButton = !validation.is_default;
    const showDivider = index < lastValidationIndex;

    return (
      <React.Fragment key={`validations-${index}`}>
        <ValidationForm name={`validations.${index}`} columnType={columnType} />
        {showDeleteButton ? (
          <S.DeleteValidationButton mt={16} onClick={() => remove(index)} />
        ) : null}
        {showDivider ? <S.Divider /> : null}
      </React.Fragment>
    );
  }

  function renderFieldArray({ push, remove }: FieldArrayRenderProps) {
    return (
      <>
        {validations &&
          validations.map((validation, index) =>
            renderValidationForm(validation, index, remove)
          )}
        {canAddMoreValidations && (
          <S.AddValidationButton
            isDisabled={isAddButtonDisabled}
            mt={32}
            onClick={() => (isAddButtonDisabled ? null : push({}))}
          />
        )}
      </>
    );
  }

  function renderHeader() {
    return (
      <Header
        title={"Add validations"}
        subTitle={
          <>
            {`Set validations to the column type `}
            <Span extraBold>{`(${columnLabel})`}</Span>
            {` you’re ${suffix}.`}
          </>
        }
      />
    );
  }

  return (
    <S.Container>
      {renderHeader()}
      {/* @ts-ignore */}
      <FieldArray name="validations" render={renderFieldArray} />
    </S.Container>
  );
};

export default ValidationsForm;

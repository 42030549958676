import { Div, P } from "fuse-shared-ui";
import styled from "styled-components";
import { useBillingContext } from "../BillingContextProvider";

const PlanTitle = styled(Div)`
  text-transform: capitalize;
`;

const Wrapper = styled(Div)`
  height: 100px;
  border-color: ${(p) => p.theme.colors.gray200};
`;

const TrialModeBanner = () => {
  const { paymentMethod } = useBillingContext();

  return (
    <>
      <Wrapper spaceBetween border br={8} pl={26} pr={32} bg={"white"} mb={40}>
        <Div flexColumn justifyCenter>
          <Div dflex label2 data-cy="yourPlanText" mb={2}>
            Your Plan
          </Div>
          <PlanTitle wbody2 data-cy="planType" m={0} h5>
            Trial Mode
          </PlanTitle>
        </Div>
        <Div flexColumn justifyCenter>
          <P wbody2 m={0}>
            Free
          </P>
        </Div>
      </Wrapper>

      {paymentMethod ? null : (
        <Wrapper spaceBetween border br={8} pl={26} pr={32} bg={"white"}>
          <Div flexColumn justifyCenter>
            <Div dflex label2 mb={2}>
              Payment Method
            </Div>
            <PlanTitle wbody3 m={0}>
              No payment method selected
            </PlanTitle>
          </Div>
        </Wrapper>
      )}
    </>
  );
};

export default TrialModeBanner;

import { css } from "../themeType";

const truncateTextCss = css`
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  @supports (overflow-wrap: anywhere) {
    overflow-wrap: anywhere;
  }
  @supports not (overflow-wrap: anywhere) {
    word-wrap: break-word;
  }
`;

export const textCss = {
  truncateText: css`
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    ${truncateTextCss}
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;

    @supports (overflow-wrap: anywhere) {
      overflow-wrap: anywhere;
    }

    @supports not (overflow-wrap: anywhere) {
      word-wrap: break-word;
    }
  `,
  truncateText2: css`
    ${truncateTextCss}
    -webkit-line-clamp: 2;
  `,
  truncateText3: css`
    ${truncateTextCss}
    -webkit-line-clamp: 3;
  `,
  dInline: css`
    display: inline-block;
  `,
  flexWrap: css`
    display: flex;
    flex-wrap: wrap;
  `,
  underline: css`
    text-decoration: underline;
  `,
  textAlignCenter: css`
    text-align: center;
  `,
  textAlignRight: css`
    text-align: right;
  `,
  textAlignLeft: css`
    text-align: left;
  `,
  uppercase: css`
    text-transform: uppercase;
  `,
  capitalize: css`
    text-transform: capitalize;
  `,
  lowercase: css`
    text-transform: lowercase;
  `,
};

import { Div } from "fuse-shared-ui";
import { Filter } from "../Filter";
import { FilterComponentProps } from "../interfaces";

export const DesktopFilters = ({ filters }: FilterComponentProps) => {
  return (
    <Div id="desktop-filter" dflex alignCenter>
      {filters.map((filter) => (
        <Filter
          key={filter.label}
          icon={filter.icon}
          label={filter.label}
          data={filter.data}
          keyToRender={filter.keyToRender}
          onFilter={filter.onFilter}
          activeFilter={filter.activeFilter}
          filterType={filter.filterType}
        />
      ))}
    </Div>
  );
};

import React from "react";
import { areEqual } from "react-window";
import { useImporterContext } from "../contexts/ImporterContextProvider";
import { DragAndDropImporter } from "./DragAndDropImporter";
import HeadersPreview from "./HeadersPreview";

const Uploader = () => {
  const { uploadedData } = useImporterContext();
  return <>{uploadedData ? <HeadersPreview /> : <DragAndDropImporter />}</>;
};

export default React.memo(Uploader, areEqual);

import Bottleneck from "bottleneck";
import { useEffect, useRef, useState } from "react";

const ONE_SECOND = 1000;

export const useQueue = () => {
  const mainQueue = useRef<Bottleneck>(null);
  const parallelQueue = useRef<Bottleneck>(null);

  const [isRetryModalOpen, setIsRetryModalOpen] = useState(false);
  const isCloseRetryState = useRef(false);
  const isQueuePaused = useRef(false);
  const isQueueStopping = useRef(false);

  const enqueue = (queue, func) => {
    return queue.current.schedule(wrapFunctionToQueue(func));
  };

  const resetQueues = () => {
    mainQueue.current = new Bottleneck({
      maxConcurrent: 1,
      rejectOnDrop: false,
    });
    parallelQueue.current = new Bottleneck({
      maxConcurrent: 5,
      rejectOnDrop: false,
    });
    isQueuePaused.current = false;
    isQueueStopping.current = false;
    mainQueue.current.on("failed", () => {
      if (isQueuePaused.current) return ONE_SECOND;
      isQueuePaused.current = true;
      setIsRetryModalOpen(true);
      return ONE_SECOND; // wait 1 second before retrying
    });
    parallelQueue.current.on("failed", () => {
      if (isQueuePaused.current) return ONE_SECOND;
      isQueuePaused.current = true;
      setIsRetryModalOpen(true);
      return ONE_SECOND;
    });
  };

  const stopQueue = async (reset = true) => {
    try {
      const jobs = [];
      if (mainQueue.current) {
        jobs.push(
          mainQueue.current.stop({
            dropWaitingJobs: true,
          })
        );
      }

      if (parallelQueue.current) {
        jobs.push(
          parallelQueue.current.stop({
            dropWaitingJobs: true,
          })
        );
      }
      isQueueStopping.current = true;
      isQueuePaused.current = false;

      await Promise.all(jobs);

      if (reset) {
        resetQueues();
      }
    } catch (e) {
      console.error(e);
    } finally {
      isQueueStopping.current = false;
    }
  };

  // Checks if the mainQueue is paused and if so, rejects the promise to "pause" the mainQueue
  const wrapFunctionToQueue = (func) => () => {
    if (isQueuePaused.current) return Promise.reject("Queue is paused");
    if (isQueueStopping.current) return Promise.resolve();
    return func();
  };

  const playQueue = () => {
    isQueuePaused.current = false;
  };

  const pauseQueue = () => {
    isQueuePaused.current = true;
  };

  const setIsCloseRetryState = (isClosed) => {
    isCloseRetryState.current = isClosed;
  };

  useEffect(() => {
    resetQueues();
  }, []);

  return {
    isQueuePaused,
    playQueue,
    pauseQueue,
    mainQueue,
    parallelQueue,
    stopQueue,
    resetQueues,
    isRetryModalOpen,
    setIsRetryModalOpen,
    isCloseRetryState,
    setIsCloseRetryState,
    enqueue,
  };
};

export const ACCOUNT_ROUTES = {
  HOME: "/home",
  TEAM: "/team-members",
  TEMPLATES: "/importers",
  IMPORTS: "/imports",
  WEBHOOKS: "/webhooks",
  ACCOUNT: "/",
  BILLING: "/billing",
  IMPORTER_STYLE_PREFERENCES: "/importer-style-preferences",
  USER_INTENTS: "/user-intents",
  API_CREDENTIALS: "/developers/api-credentials",
  ORGANIZATION_SETTINGS: "/organization-settings",
  DEVELOPERS: "/developers",
  WEBHOOK_LOGS: "/developers/webhooks/logs",
};

export const ACCOUNT_VIEW_PATH = "/account";
const p = (path) => `${ACCOUNT_VIEW_PATH}${path}`;

export const ACCOUNT_HOME_ROUTE = p(ACCOUNT_ROUTES.HOME);
export const ACCOUNT_TEAM_ROUTE = p(ACCOUNT_ROUTES.TEAM);
export const ACCOUNT_TEMPLATES_ROUTE = p(ACCOUNT_ROUTES.TEMPLATES);
export const IMPORTS_ROUTE = p(ACCOUNT_ROUTES.IMPORTS);
export const ACCOUNT_ROUTE = p(ACCOUNT_ROUTES.ACCOUNT);
export const BILLING_ROUTE = p(ACCOUNT_ROUTES.BILLING);
export const API_CREDENTIALS_ROUTE = p(ACCOUNT_ROUTES.API_CREDENTIALS);
export const ORGANIZATION_SETTINGS_ROUTE = p(
  ACCOUNT_ROUTES.ORGANIZATION_SETTINGS
);
export const DEVELOPERS_ROUTE = p(ACCOUNT_ROUTES.DEVELOPERS);
export const IMPORTER_STYLE_PREFERENCES_ROUTE = p(
  ACCOUNT_ROUTES.IMPORTER_STYLE_PREFERENCES
);

export const WEBHOOK_LOGS_ROUTE = p(ACCOUNT_ROUTES.WEBHOOK_LOGS);

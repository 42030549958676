import { ValidationTypes } from "fuse-importer";

export const isString = (variable) => {
  return typeof variable === "string" || variable instanceof String;
};

export const sleep = (n: number) =>
  new Promise((resolve) => setTimeout(resolve, n));

// Add dynamic columns
export const addValidation = (value: ValidationTypes, label: string) => {
  return {
    validation_type: value as any,
    message: label,
  };
};

export const getDefaultValidations = (columnType: string) => {
  switch (columnType) {
    case "email":
      return [addValidation("email", "Must be an email")];
    case "url":
      return [addValidation("url", "Must be a URL")];
    case "boolean":
      return [addValidation("boolean", "Must be True or False")];
    case "integer":
      return [addValidation("integer", "Must be an integer")];
    case "float":
      return [addValidation("float", "Must be a float")];
    default:
      return [];
  }
};

export const truncateString = (str: string, maxLength = 10) =>
  str?.length > maxLength ? `${str?.slice(0, maxLength)}...` : str;

import { FormikSumbitButton } from "components/common/form/Form";
import { Div, Button } from "fuse-shared-ui";
import { useFormikContext } from "formik";

type ColumnFormModalButtonsProps = {
  isEditing: boolean;
  activeTabIndex: number;
  isLoading: boolean;
  onCancel: () => void;
  onNext: (step: number) => void;
  isNextStepDisabled: boolean;
};

const ColumnFormModalButtons = ({
  isEditing,
  activeTabIndex,
  onCancel,
  onNext,
  isLoading,
  isNextStepDisabled,
}: ColumnFormModalButtonsProps) => {
  const { isValid } = useFormikContext();
  const isInvalid = !isValid;
  const validationsStepButtonLabel = isEditing
    ? "Edit Transformations"
    : isNextStepDisabled
    ? "Add Column"
    : "Add Transformations";
  const transformationsStepButtonLabel = isEditing
    ? "Edit Column"
    : "Add Column";
  const validationsLabel = isEditing ? "Edit Validations" : "Add Validations";

  return (
    <Div h={100} dflex centered>
      <Button
        data-cy="back-button"
        variant="secondary"
        w={104}
        mr={18}
        centered
        onClick={onCancel}
      >
        Back
      </Button>
      {activeTabIndex === 0 && (
        <Button
          data-cy="addValidationButton"
          centered
          w={173}
          onClick={isInvalid ? null : () => onNext(1)}
          isDisabled={isInvalid}
          disabledWhen={isInvalid}
        >
          Continue
        </Button>
      )}
      {activeTabIndex === 1 && !isNextStepDisabled && (
        <Button
          w={147}
          centered
          isDisabled={isLoading || isInvalid}
          onClick={isInvalid ? null : () => onNext(2)}
          disabledWhen={isInvalid}
          data-cy="addTransformationButton"
        >
          <Div>Continue</Div>
        </Button>
      )}
      {(activeTabIndex === 2 || isNextStepDisabled) && (
        <FormikSumbitButton
          w={147}
          isDisabled={isLoading}
          disableOnErrors={true}
          data-cy="addColumnButton"
        >
          <Div>Continue</Div>
        </FormikSumbitButton>
      )}
    </Div>
  );
};

export default ColumnFormModalButtons;

import { Div, Modal as ModalBase, ModalWrapper } from "fuse-shared-ui";
import styled, { css } from "styled-components";

type StyledModalProps = {
  minW?: any;
};

export const ModalContent = styled(Div)`
  padding: 47px 40px;
`;

export const Modal = styled(ModalBase)<StyledModalProps>(
  ({ minW }) => css`
    ${ModalWrapper} {
      display: flex;
      flex-direction: column;
      min-width: ${minW || "calc(640px - 112px)"};
      max-height: 100%;
      overflow: auto;
      @media all and ${(props) => props.theme.media.mobile} {
        min-width: auto;
        min-height: auto;
      }
    }
  `
);

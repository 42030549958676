import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { useValidationContext } from "../../Importer/common/Spreadsheet/ValidationContextProvider";
import { Button, ChevronRightIcon, closeIconCondensed } from "../../common";
import { useEventManagerContext } from "../../common/EventManagerContextProvider";
import { Div } from "../../styled/utils";
import { ColumnType } from "../Matcher/common/types";
import { useReviewContext } from "../Review/ReviewContextProvider";
import { useDataSetContext } from "../common/Spreadsheet/DataSetContextProvider";
import { useImporterContext } from "../contexts/ImporterContextProvider";
import { ConfirmCloseModal } from "./ConfirmCloseModal";
import { Step } from "./Step";
import { UnmatchedHeadersModal } from "./UnmatchedHeadersModal";

type StepperButtonContainerProps = {
  align?: "left" | "right";
};

const StepperWrapper = styled(Div)`
  background-color: ${(p) => p.theme.colors.white};
  border-bottom: 1px solid ${(p) => p.theme.colors.gray300};
  user-select: none;
`;

const StepperContainer = styled(Div)`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
`;

const Steps = styled(Div)`
  @media (max-width: 630px) {
    display: none;
  }
`;

const Logo = styled.img`
  max-height: 70px;
  max-width: 300px;
`;

const StepperButtonContainer = styled(Div)<StepperButtonContainerProps>`
  align-items: center;
  display: flex;
  flex: 1;
  gap: 32px;
  justify-content: ${({ align }) =>
    align === "left" ? "flex-start" : "flex-end"};
`;

const GoBackButtonWrapper = styled(Div)`
  @media (max-width: 740px) {
    display: none;
  }
`;

const CloseIcon = styled(Div)(() => css``);

type StepperProps = {
  stepLabels: string[];
  logoURL?: string;
};

export const Stepper = ({ stepLabels, logoURL }: StepperProps) => {
  const [
    isUnmatchedHeadersModalOpen,
    setIsUnmatchedHeadersModalOpen,
  ] = useState(false);
  const { t } = useTranslation("general");
  const [isConfirmCloseModalOpen, setIsConfirmCloseModalOpen] = useState(false);
  const { dataSetLength, emptyRecordIds } = useDataSetContext();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const { appResponse, setIsSubmissionModalOpen } = useReviewContext();
  const {
    headerMappingsLoaded,
    hasValidSubmitFn,
    isUsingPersistence,
  } = useImporterContext();
  const { totalErrorCount, spreadsheetInitialized } = useValidationContext();

  const { on, off, EVENTS } = useEventManagerContext();

  const updateSpreadsheetDisabled = () => {
    const isSpreadsheetEmpty =
      dataSetLength.current === emptyRecordIds.current.size;
    const isDisabled =
      totalErrorCount.current > 0 ||
      !spreadsheetInitialized ||
      isSpreadsheetEmpty ||
      (!isUsingPersistence && !hasValidSubmitFn);
    setIsSubmitDisabled(isDisabled);
  };

  useEffect(() => {
    on(EVENTS.UPDATE_TOTAL_ERROR_COUNT, updateSpreadsheetDisabled);

    return () => {
      off(EVENTS.UPDATE_TOTAL_ERROR_COUNT, updateSpreadsheetDisabled);
    };
  }, [appResponse, spreadsheetInitialized]);

  useEffect(() => {
    updateSpreadsheetDisabled();
  }, [spreadsheetInitialized]);

  const {
    initialRecords,
    currentStepIndex,
    templateHeaderMatchings,
    matcherSubstep,
    templateHeaders,
    setMatcherSubstep,
    setCurrentStepIndex,
    onClose,
    isUsingManualImport,
    resetImporterWhenUsingManualImport,
    uploadedData,
  } = useImporterContext();
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);

  const templateHeaderLabels = Object.keys(templateHeaderMatchings || []);

  const unmatchedHeadersCount = templateHeaderLabels.filter(
    (templateHeaderLabel) => !templateHeaderMatchings[templateHeaderLabel]
  ).length;

  const allColumnsUnmatched = unmatchedHeadersCount === templateHeaders.length;

  const isLastSubMatchStep = matcherSubstep === 1;
  useEffect(() => {
    setIsContinueDisabled(
      (allColumnsUnmatched && currentStepIndex > 0) ||
        (currentStepIndex === 1 && !headerMappingsLoaded)
    );
  }, [currentStepIndex, headerMappingsLoaded, allColumnsUnmatched]);

  const setStep = () => {
    const templateEnumFields = templateHeaders?.filter(
      (header) => header.column_type === ColumnType.enum
    );
    const templateHasEnumFields = !!templateEnumFields?.length;

    if (templateHasEnumFields) {
      const templateEnumFieldsWithHeaderMatchings = templateEnumFields.filter(
        (header) => !!templateHeaderMatchings[header.label]
      );

      if (templateEnumFieldsWithHeaderMatchings.length > 0) {
        setMatcherSubstep(1);
      } else {
        setCurrentStepIndex(2);
      }
    } else {
      setCurrentStepIndex(2);
    }
  };

  const openUnmatchedModal = () => {
    unmatchedHeadersCount ? setIsUnmatchedHeadersModalOpen(true) : setStep();
  };

  const continueToNextStep = () => {
    if (isVerifyStep) {
      setCurrentStepIndex(1);
    } else if (isLastSubMatchStep) {
      setIsUnmatchedHeadersModalOpen(false);
      setCurrentStepIndex(2);
    } else {
      openUnmatchedModal();
    }
  };

  const goBack = () => {
    if (isUsingManualImport.current) {
      return resetImporterWhenUsingManualImport();
    }

    const isMatchingColumnValues = matcherSubstep === 1;
    const isInMachStep = currentStepIndex === 1;
    const shouldBackOnlyMatcherSubStep = isMatchingColumnValues && isInMachStep;

    if (shouldBackOnlyMatcherSubStep) {
      setMatcherSubstep(matcherSubstep - 1);
    } else {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const handleOnClose = () => {
    setIsConfirmCloseModalOpen(true);
  };

  const isVerifyStep = currentStepIndex === 0 && !!uploadedData;
  const isMatchStep = currentStepIndex === 1;
  const isReviewStep = currentStepIndex === 2;

  const stepsUI = (
    <Div dflex centered>
      {!initialRecords &&
        stepLabels.map((label, index) => {
          const isLast = index === stepLabels.length - 1;
          const isCompleted = index < currentStepIndex;

          return (
            <Div key={index} centered>
              <Step
                index={index}
                label={label}
                isCompleted={isCompleted}
                isSelected={index === currentStepIndex}
              />
              {!isLast && (
                <Div ml={18} mr={18} dflex>
                  <ChevronRightIcon />
                </Div>
              )}
            </Div>
          );
        })}
    </Div>
  );

  const leftButtonUI = (
    <Div style={{ flex: 1 }}>
      {!initialRecords && (
        <StepperButtonContainer align="left">
          {logoURL && (
            <Div forDesktop>
              <Logo src={logoURL} alt="" />
            </Div>
          )}
          {currentStepIndex > 0 && !appResponse && (
            <GoBackButtonWrapper>
              <Button data-cy="go-back" variant="secondary" onClick={goBack}>
                {t("stepper.go_back")}
              </Button>
            </GoBackButtonWrapper>
          )}
        </StepperButtonContainer>
      )}
    </Div>
  );
  const rightButtonUI = (
    <StepperButtonContainer align="right">
      {(isMatchStep || isVerifyStep) && (
        <Button
          data-cy="continue-button"
          isDisabled={isContinueDisabled}
          variant="primary"
          onClick={continueToNextStep}
        >
          {t("stepper.continue")}
        </Button>
      )}
      {isReviewStep && (
        <Button
          variant="primary"
          isDisabled={isSubmitDisabled}
          data-cy={"submit_disabled_" + isSubmitDisabled}
          onClick={() => setIsSubmissionModalOpen(true)}
        >
          {t("stepper.submit")}
        </Button>
      )}
      <CloseIcon
        data-test-id="close-importer"
        centered
        clickable
        onClick={handleOnClose}
      >
        {closeIconCondensed}
      </CloseIcon>
    </StepperButtonContainer>
  );

  return (
    <Div data-cy="stepper">
      <StepperWrapper pRelative alignCenter w100 h={72} centered>
        <StepperContainer>
          {leftButtonUI}
          <Steps alignCenter>{stepsUI}</Steps>
          {rightButtonUI}
        </StepperContainer>
      </StepperWrapper>
      <UnmatchedHeadersModal
        isUnmatchedHeadersModalOpen={isUnmatchedHeadersModalOpen}
        unmatchedHeadersCount={unmatchedHeadersCount}
        onSetStep={() => setStep()}
        onCloseUnmatchedHeadersModal={() =>
          setIsUnmatchedHeadersModalOpen(false)
        }
      />
      <ConfirmCloseModal
        isOpen={isConfirmCloseModalOpen}
        onClose={() => setIsConfirmCloseModalOpen(false)}
        onCloseImporter={onClose}
      />
    </Div>
  );
};

import { Header } from "components/common/Header";
import { Div, Tabs, TabsContent, TabsList, TabsTrigger } from "fuse-shared-ui";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import ApiCredentialsRoutes from "./ApiCredentials";
import Webhooks from "./Webhooks";
import WebhookLogsRoutes from "./Webhooks/Logs";

const ScrollArea = styled(Div)`
  background: ${(p) => p.theme.colors.white};
  margin: 0 32px 32px 32px;
  overflow-y: auto;
  ${(p) => p.theme.css.scrollbarDark};
  overflow-y: auto;
  scrollbar-width: auto !important;

  @media all and (${({ theme }) => theme.media.xs}) {
    margin: 0px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const HeaderContainer = styled(Div)`
  padding: 32px;

  @media all and (${({ theme }) => theme.media.xs}) {
    padding: 16px;
  }
`;

const TabsSection = ({ defaultTab }) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);

  useEffect(() => {
    setCurrentTab(defaultTab);
  }, [defaultTab]);

  const headerUI = (
    <Div data-cy="developer-title" spaceBetween>
      <Helmet>
        <title>Developers</title>
      </Helmet>
      <Header title="Developers" />
    </Div>
  );

  return (
    <Tabs
      value={currentTab}
      onValueChange={setCurrentTab}
      activationMode="manual"
    >
      <HeaderContainer>
        {headerUI}
        <TabsList>
          <TabsTrigger value="webhooks">Webhooks</TabsTrigger>
          <TabsTrigger value="apikeys">API keys</TabsTrigger>
        </TabsList>
      </HeaderContainer>
      <ScrollArea>
        <TabsContent value="webhooks">
          <Webhooks />
        </TabsContent>
        <TabsContent value="apikeys">
          <ApiCredentialsRoutes />
        </TabsContent>
        <TabsContent value="logs">
          <WebhookLogsRoutes />
        </TabsContent>
      </ScrollArea>
    </Tabs>
  );
};

const DevelopersRoutes = ({
  testId,
  customDefaultTab,
}: {
  testId?: string;
  customDefaultTab?: string;
}) => {
  const defaultTab = customDefaultTab || "webhooks";

  return <TabsSection defaultTab={defaultTab} />;
};

export default DevelopersRoutes;

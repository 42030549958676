import { Div, logo } from "fuse-shared-ui";
import React, { useContext } from "react";
import styled, { css, ThemeContext, ThemeProvider } from "styled-components";

const Graphics = styled(Div)`
  margin-top: 30px;
  right: ${(props: any) => props.graphicsOffset}px;
  top: 80px;
  position: absolute;

  ${(props: any) =>
    props.withAnimation &&
    css`
      animation: slide-in 0.4s ease-in-out;
      -webkit-animation: slide-in 0.4s ease-in-out;

      @keyframes slide-in {
        0% {
          transform: translate(-100%, 90%);
        }
        100% {
          transform: translate(0%, 0%);
        }
      }
    `}
`;

type LeftSectionProps = {
  src: string;
};
const LeftSection = styled(Div)<LeftSectionProps>`
  height: 100%;
  max-width: 580px;
  width: 30vw;
  position: relative;
  background-image: url(${(props: any) => props.src});
  background-size: 100% 100%;
  background-position: 0% 0%;
  background-size: cover;
`;

const Container = styled(Div)`
  height: 100vh;
  overflow-y: hidden;
`;

const Logo = styled(Div)`
  margin-top: 30px;
  margin-left: 8%;
`;

const Navigation = styled(Div)`
  position: absolute;
  right: 2px;
  top: 1vw;
  margin-right: 2%;
`;

type ContentContainerProps = {
  isCentered: boolean;
};
const ContentContainer = styled(Div)<ContentContainerProps>`
  margin-top: 130px;
  width: 100%;
  ${(props: any) =>
    props.isCentered &&
    css`
      margin-top: 0;
      ${(props) => props.theme.css.centered};
    `}
  ${(props) => props.theme.css.justifyCenter};
  @media all and ${(props) => props.theme.media.mobile} {
    ${(p) => p.theme.css.mcontainer};
  }
`;

const ContentWrapper = styled(Div)`
  @media all and (max-width: 630px) {
    width: 100%;
  }
`;

const Wrapper = styled(Div)`
  ${(props) => {
    const c = props.theme.css;
    return [c.grow, c.justifyCenter, c.yscroll, c.pRelative];
  }};
  overflow-x: hidden;
`;

export const MobileGraphics = styled(Div)`
  position: absolute;
  right: -50px;
  top: -50px;
`;

export const SessionLayout = styled(Div)`
  display: flex;
  background-color: ${(props) => props.theme.colors.orange50};
  height: 100vh;
  width: 100vw;
`;

const Layout = (props) => {
  const themeBase = useContext(ThemeContext);
  const theme = { ...themeBase };
  theme.media = { ...theme.media, mobile: "(max-width: 1280px)" };
  theme.media = { ...theme.media, desktop: "(min-width: 1281px)" };
  return (
    <ThemeProvider theme={theme}>
      <LayoutWithContext {...props} />
    </ThemeProvider>
  );
};

const LayoutWithContext = ({
  backgroundPath,
  graphics,
  mobileGraphics = null,
  graphicsOffset = 0,
  children,
  contentWidth = 467,
  withAnimation = false,
  navigation = null,
  isCentered = false,
  ...props
}) => {
  const leftSectionUI = (
    <>
      <LeftSection src={backgroundPath} forDesktop>
        <Logo>{logo}</Logo>
        <Graphics {...{ graphicsOffset, withAnimation }}>{graphics}</Graphics>
      </LeftSection>
    </>
  );

  return (
    <Container dflex h100 w100 {...props}>
      {leftSectionUI}
      <Wrapper h100>
        <MobileGraphics forMobile>{mobileGraphics}</MobileGraphics>
        <Navigation forDesktop>{navigation}</Navigation>
        <Div w100 pAbsolute>
          <Div forMobile mt={25} mcontainer>
            {logo}
          </Div>
        </Div>
        <ContentContainer isCentered={isCentered}>
          <ContentWrapper w={contentWidth}>
            {children}
            <Div h={150} />
          </ContentWrapper>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

export default Layout;

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  #fuse-importer-root > *,
  body > * {
    font-size: 17px;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    font-family: DM Sans;
    color-scheme: normal;
    color: black;
  }
`;

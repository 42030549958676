import { Button, chevronLeftIcon, Div } from "fuse-shared-ui";
import { useHistory } from "react-router";
import styled from "styled-components";
import { TEMPLATES_ROUTE } from "../../../";
import { AddColumnButton, GoToTemplatesLink } from "../../styles";
import { Template } from "../../types";
import TemplateNameInput from "../TemplateNameInput";

type Props = {
  isLoading?: boolean;
  hasColumns?: boolean;
  template?: Template;
  onAddColumnClick?: () => void;
  onLaunchTemplate?: () => void;
  getData: () => Promise<void>;
  updateTemplate: (values: Partial<Template>) => Promise<void>;
};

const SelfHostedButtons = styled(Div)`
  align-self: center;
  height: 48px;
  min-width: fit-content;
`;

const TemplateInfoContainer = styled(Div)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const Header = ({
  isLoading,
  hasColumns,
  template,
  onLaunchTemplate,
  onAddColumnClick,
  getData,
  updateTemplate,
}: Props) => {
  const history = useHistory();

  const selfHostedOptionsUI = (
    <SelfHostedButtons dflex>
      <Button
        data-cy="preview-importer-btn"
        mr={16}
        variant="secondary"
        isDisabled={!hasColumns}
        onClick={onLaunchTemplate}
      >
        Import File
      </Button>
      <AddColumnButton onClick={onAddColumnClick} />
    </SelfHostedButtons>
  );

  return (
    <Div>
      <Div alignStart>
        <GoToTemplatesLink
          alignCenter
          onClick={() => history.push(TEMPLATES_ROUTE)}
        >
          <Div mr={8} alignCenter>
            {chevronLeftIcon}
          </Div>
          <Div>Importers</Div>
        </GoToTemplatesLink>
      </Div>
      <TemplateInfoContainer mb={26} spaceBetween>
        {template && (
          <TemplateNameInput
            getData={getData}
            updateTemplate={updateTemplate}
            template={template}
            isLoading={isLoading}
          />
        )}
        {selfHostedOptionsUI}
      </TemplateInfoContainer>
    </Div>
  );
};

export default Header;

import { useMemo, useRef } from "react";
import { useSpreadsheetField } from "..";
import { FieldTypes, Record } from "fuse-importer";
import { useReviewContext } from "../../../../../Review/ReviewContextProvider";
import { spreadsheetRowHeightPx } from "../../../common";
import { AVERAGE_CHAR_SIZE, getFieldContentWidth } from "../utils";

export const useSpreadsheetInput = (
  name: string,
  record: Record,
  fieldType: FieldTypes,
  columnWidth: string
) => {
  const {
    value,
    setValue,
    validationError,
    validationWarning,
  } = useSpreadsheetField(name, record, fieldType);
  const textAreaRef = useRef(null);
  const { hasTransformedRecords } = useReviewContext();

  const defaultWidthAsNumber = Number.parseInt(columnWidth.replace("px", ""));
  const _fieldContentWidth = getFieldContentWidth({
    record,
    fieldType,
    isTooltipOpened: hasTransformedRecords,
    name,
  });

  const isHighlighted =
    hasTransformedRecords && record._meta?.transformedRow?.[name]?.transformed;
  const isMultiline = `${value}`?.includes("\n");
  const lineCount = (`${value}`?.match(/\n/g) || []).length;
  const fieldHeight = (lineCount + 1) * spreadsheetRowHeightPx;

  const expandedFieldWidth = useMemo(() => {
    // if the value is falsy, returns the default width
    if (!value) return columnWidth;

    const inputPadding = 24; // 12 left + 12 right
    const transformedValueSize = value.length * AVERAGE_CHAR_SIZE;

    const totalInputSize =
      transformedValueSize + _fieldContentWidth + inputPadding;

    return totalInputSize > defaultWidthAsNumber
      ? `${totalInputSize}px`
      : `${defaultWidthAsNumber}px`;
  }, [value, record, columnWidth]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && event.altKey) {
      const cursorPosition = textAreaRef.current?.selectionStart || 0;

      const newText =
        value.substring(0, cursorPosition) +
        "\n" +
        value.substring(cursorPosition);

      setValue(newText);

      event.preventDefault();
    }
  };

  return {
    validationError,
    validationWarning,
    isHighlighted,
    defaultWidthAsNumber,
    expandedFieldWidth,
    isMultiline,
    fieldHeight,
    value,
    hasTransformedRecords,
    setValue,
    handleKeyDown,
    textAreaRef,
    _fieldContentWidth,
  };
};

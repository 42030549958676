import { Div } from "../../styled";
import { Button, Modal } from "../../common";
import { errorIcon } from "./common/icons";
import { useTranslation } from "react-i18next";

type ConfirmCloseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCloseImporter: () => void;
};

export const ConfirmCloseModal = ({
  isOpen,
  onClose,
  onCloseImporter,
}: ConfirmCloseModalProps) => {
  const { t } = useTranslation("general");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Div centered flexColumn p={60}>
        <Div textAlignCenter mb={10} mt={5}>
          {errorIcon}
        </Div>
        <Div h5 mt={10} data-test-id="close-confirmation-title">
          {t("confirm_close_modal.title")}
        </Div>
        <Div
          body1
          mt={12}
          w={448}
          textAlignCenter
          data-test-id="close-confirmation-description"
        >
          {t("confirm_close_modal.description")}
        </Div>
        <Div dflex mt={40} w100 centered>
          <Button
            w={115}
            variant="secondary"
            mr={10}
            onClick={onClose}
            data-test-id="confirm-close-modal-cancel"
          >
            {t("confirm_close_modal.cancel")}
          </Button>
          <Button
            data-test-id="confirm-close"
            w={140}
            ml={10}
            onClick={() => {
              onCloseImporter();
              onClose();
            }}
          >
            {t("confirm_close_modal.close")}
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};

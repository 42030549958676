import React, { createContext, useContext, useState } from "react";
import { AppLoading } from "./AppLoading";

export type State = {
  isLoadingApp: boolean;
  setIsLoadingApp: React.Dispatch<React.SetStateAction<boolean>>;
};
export const AppContext = createContext<State>({} as State);

export const AppContextProvider = ({ children }) => {
  const [isLoadingApp, setIsLoadingApp] = useState(false);

  const value = {
    isLoadingApp,
    setIsLoadingApp,
  };

  return (
    <AppContext.Provider value={value}>
      {isLoadingApp && <AppLoading />}
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

import { FormikAutocomplete } from "components/common/form/fields";
import { Div } from "fuse-shared-ui";
import { columnTypes } from "../../fields";

export const ColumnTypeSelect = () => {
  return (
    <Div dflex flexColumn>
      <FormikAutocomplete
        name="column_type"
        label="Column type*"
        labelTooltip="Used for validations and formatting"
        placeholder="Select option..."
        options={columnTypes}
      />
    </Div>
  );
};

export default ColumnTypeSelect;

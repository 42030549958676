import React from "react";
import { Div, theme } from "fuse-shared-ui";
import styled from "styled-components";
import { css } from "cypress/types/jquery";

export const TemplateItemContainer = styled(Div)`
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 16px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-content: center;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  background-color: ${(props) => props.theme.colors.orange50};
  ${(props) => props.theme.textStyles.wbody1};
`;

export const TemplateListContainer = styled(Div)`
  max-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 2px;
`;

export const DeleteButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  display: flex;
  margin-top: 3;
  cursor: pointer;
`;

export const DeleteIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 5.16675H18.5L17.9875 15.4164C17.8545 18.0775 15.6581 20.1667 12.9938 20.1667H9.00625C6.34188 20.1667 4.14554 18.0775 4.01248 15.4164L3.5 5.16675Z"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M6.83325 5.16675V4.83341C6.83325 3.17656 8.1764 1.83341 9.83325 1.83341H12.1666C13.8234 1.83341 15.1666 3.17656 15.1666 4.83341V5.16675"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M1.83325 5.16675H20.1666"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M13.5 10.1667V14.3334"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M8.5 10.1667V14.3334"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

import { Button, Div } from "fuse-shared-ui";

import { githubIcon } from "./icons";

type GithubLoginButtonProps = {
  text: string;
  className?: string;
  isButtonElement?: boolean;
  isDisabled?: boolean;
};

export const GithubLoginButton = ({
  text,
  className,
  isButtonElement,
  isDisabled,
}: GithubLoginButtonProps) => (
  <Button
    {...{ className, variant: "secondary", isButtonElement }}
    isDisabled={isDisabled}
    onClick={() =>
      window.open(
        `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&scope=user`,
        "_self"
      )
    }
  >
    <Div mr={10}>{text}</Div>
    {githubIcon}
  </Button>
);

import { colors, Div, Modal, Button } from "../";

export const ConfirmationModal = ({
  isOpen,
  handleClose,
  onSubmit,
  title,
  description,
  buttonText,
  icon = null,
  hasCancelButton = true,
}) => {
  const headerUI = (
    <Div>
      {icon && (
        <Div centered mb={20}>
          {icon}
        </Div>
      )}
      <Div mb={12}>
        <Div h5 dflex centered>
          {title}
        </Div>
      </Div>
    </Div>
  );

  const bodyUI = (
    <>
      <Div c={colors.gray800} body1 flexColumn centered>
        {description}
      </Div>
      <Div mt={40} dflex centered>
        {hasCancelButton && (
          <Button variant="secondary" mr={16} w={104} onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button w={135} onClick={onSubmit}>
          {buttonText}
        </Button>
      </Div>
    </>
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <Div p="48px 56px">
          {headerUI}
          {bodyUI}
        </Div>
      </Modal>
    </>
  );
};

import React, { createContext, useContext, useState } from "react";
import { AppLoadingUI } from "./AppLoadingUI";

export type State = {
  isLoadingApp: boolean;
  setIsLoadingApp: React.Dispatch<React.SetStateAction<boolean>>;
};
export const AppLoadingContext = createContext<State>({} as State);

export const AppLoadingContextProvider = ({ children }) => {
  const [isLoadingApp, setIsLoadingApp] = useState(false);

  const value = {
    isLoadingApp,
    setIsLoadingApp,
  };

  return (
    <AppLoadingContext.Provider value={value}>
      {isLoadingApp && <AppLoadingUI />}
      {children}
    </AppLoadingContext.Provider>
  );
};

export const useAppLoadingContext = () => useContext(AppLoadingContext);

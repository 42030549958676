export const DragAndDropSvg = (
  <svg
    width="164"
    height="96"
    viewBox="0 0 164 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M70.5229 74.0865C70.7599 74.9708 70.2351 75.8798 69.3508 76.1167L20.8309 89.1176C19.9466 89.3545 19.0377 88.8297 18.8007 87.9455L1.0238 21.6011C0.786859 20.7168 1.31164 19.8079 2.19593 19.5709L25.801 13.246C26.2207 13.1335 26.6678 13.1904 27.0461 13.4043L58.5194 31.2049C58.9065 31.4238 59.1894 31.7891 59.3045 32.2187L70.5229 74.0865Z"
        fill="url(#paint0_linear_1658_47819)"
      />
    </g>
    <g>
      <path
        d="M26.1516 13.1523L58.22 31.2361L34.3354 37.6359C33.4988 37.8601 32.6389 37.3636 32.4148 36.527L26.1516 13.1523Z"
        fill="#E8CF81"
      />
    </g>
    <g>
      <path
        d="M145.75 88.0883C145.511 88.9799 144.594 89.509 143.703 89.2701L95.2092 76.2763C94.3176 76.0374 93.7885 75.121 94.0274 74.2294L111.797 7.9115C112.036 7.0199 112.953 6.49079 113.844 6.72969L137.431 13.0496C137.854 13.163 138.216 13.4381 138.438 13.8155L156.789 44.9582C157.016 45.3445 157.079 45.8061 156.963 46.2392L145.75 88.0883Z"
        fill="url(#paint1_linear_1658_47819)"
      />
    </g>
    <g>
      <path
        d="M138.09 13.2272L156.82 44.9224L132.676 38.4529C131.983 38.2672 131.571 37.5548 131.757 36.8617L138.09 13.2272Z"
        fill="#68CECA"
      />
    </g>
    <g>
      <path
        d="M118.113 93.183C118.113 94.7388 116.852 96.0001 115.296 96.0001L49.5348 96.0001C47.979 96.0001 46.7177 94.7388 46.7177 93.183L46.7177 2.81711C46.7177 1.2613 47.979 6.91772e-05 49.5348 6.87584e-05L81.2634 6.02178e-05C82.0018 6.00191e-05 82.7108 0.290033 83.2376 0.807552L117.271 34.2418C117.81 34.7715 118.113 35.4956 118.113 36.2514L118.113 93.183Z"
        fill="url(#paint2_linear_1658_47819)"
      />
    </g>
    <g>
      <path
        d="M81.9936 0.00131226L117.054 34.3579H83.8716C82.8344 34.3579 81.9936 33.5171 81.9936 32.4799L81.9936 0.00131226Z"
        fill="#6FE7E2"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1658_47819"
        x1="40.3734"
        y1="13.1259"
        x2="-7.01613"
        y2="51.8154"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0A617" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1658_47819"
        x1="119.456"
        y1="82.7732"
        x2="171.105"
        y2="7.1263"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#006BA8" />
        <stop offset="1" stopColor="#01FFD7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1658_47819"
        x1="82.4156"
        y1="96.0001"
        x2="103.774"
        y2="10.7256"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24D2EA" />
        <stop offset="1" stopColor="#10F9EB" />
      </linearGradient>
    </defs>
  </svg>
);

export const UploadIndicatorSvg = (
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M1 79.3761L95.7523 79.3762L48.3762 32L1 79.3761Z"
        fill="url(#paint0_linear_1630_46301)"
      />
    </g>
    <g>
      <path
        d="M1 55.3761L95.7523 55.3762L48.3762 8L1 55.3761Z"
        fill="url(#paint1_linear_1630_46301)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1630_46301"
        x1="48.3761"
        y1="32"
        x2="40.1872"
        y2="94.8016"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#88CDF3" />
        <stop offset="1" stopColor="#00C7A8" stopOpacity="0.895833" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1630_46301"
        x1="78.6058"
        y1="-24.6165"
        x2="64.3686"
        y2="81.3108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0098B9" />
        <stop offset="1" stopColor="#00A8CD" stopOpacity="0.895833" />
      </linearGradient>
    </defs>
  </svg>
);

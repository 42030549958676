import React from "react";
import styled from "styled-components";

import { Div, Modal, Button } from "fuse-shared-ui";
import { warningIcon } from "./assets/icons";

type RevokedInvitationModalProps = {
  isOpen: boolean;
  hide: () => void;
};

const ModalContent = styled(Div)`
  text-align: center;
  padding: 10px;
`;

const DismissButton = styled(Button)`
  margin: 40px auto 35px;
  border-radius: 24px;
`;

export const RevokedInvitationModal: React.FC<RevokedInvitationModalProps> = ({
  isOpen,
  hide,
}) => {
  const modalContentUI = (
    <ModalContent>
      <Div mt={9}>{warningIcon}</Div>
      <Div h5 m={16}>
        Something’s wrong
      </Div>
      <Div maxw={448} wbody1 mt={12}>
        It seems you’ve been removed from this team or your invitation has been
        revoked. Contact the organization to resolve this issue.
      </Div>
      <DismissButton onClick={hide}>Dismiss</DismissButton>
    </ModalContent>
  );

  return (
    <Modal isOpen={isOpen} onClose={hide}>
      {modalContentUI}
    </Modal>
  );
};

export default RevokedInvitationModal;

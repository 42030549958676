import styled, { css } from "styled-components";

import {
  TableView as TableViewBase,
  TableViewCellContainer,
  TableViewRow,
} from "../../../common/Table/TableView";
import { Div } from "../../../styled/utils";
import { spreadsheetRowHeightPx } from "./common";

interface SpreadSheetWrapperProps {
  canShowCheckboxOnHover?: boolean;
  shouldShowOnlyCheckbox?: boolean;
}

export const SpreadSheetData = styled(Div)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const SpreadSheetWrapper = styled(Div)<SpreadSheetWrapperProps>`
  border-bottom: solid 1px ${(p) => p.theme.colors.gray300};
  border-top: solid 1px ${(p) => p.theme.colors.gray300};
  background: ${(p) => p.theme.colors.white};
  overflow: hidden !important;
  flex-grow: 1;

  ${({ canShowCheckboxOnHover, shouldShowOnlyCheckbox }) => css`
    .spreadsheet-row-container {
      ${shouldShowOnlyCheckbox &&
      css`
        .row-numbers-cell {
          visibility: hidden;
        }

        .checkbox-cell {
          visibility: visible;
        }
      `}

      ${!shouldShowOnlyCheckbox &&
      canShowCheckboxOnHover &&
      css`
        &:hover {
          .row-numbers-cell {
            visibility: hidden;
          }

          .checkbox-cell {
            visibility: visible;
          }
        }
      `}
    }
  `}
`;

export const SpreadSheetContainer = styled(Div)``;

export const TableView = styled((props) => <TableViewBase {...props} />)`
  width: 100%;
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  overflow: hidden;
  background-color: ${(p) => p.theme.colors.gray50};
  ${TableViewRow} {
    border-bottom: none;
    height: ${spreadsheetRowHeightPx}px;
  }
  ${TableViewCellContainer} {
    padding: 0 !important;
    box-shadow: inset 0 -1px 0 ${(p) => p.theme.colors.gray300},
      inset -1px 0 0 ${(p) => p.theme.colors.gray300};
    &.last-column {
      box-shadow: inset 0 -1px 0 ${(p) => p.theme.colors.gray300},
        inset 1px 0 0 transparent, inset -1px 0 0 transparent !important;
    }
  }
`;

import {
  yupCardHolder,
  yupEmail,
  yupPhone,
} from "components/common/form/validations";

const cardHolderField = {
  label: "Card Holder*",
  name: "cardHolder",
  placeholder: "Enter the card holder",
  autoFocus: true,
  initialValue: "",
  validation: yupCardHolder,
};

const emailField = {
  label: "Email*",
  name: "email",
  placeholder: "Enter your email",
  initialValue: "",
  validation: yupEmail,
};

const phoneField = {
  label: "Phone number*",
  name: "phone",
  type: "tel",
  placeholder: "Enter your phone number",
  initialValue: "",
  validation: yupPhone,
};

const fields = [cardHolderField, emailField, phoneField];
export default fields;

import { DivCSSProps } from "../../../../styled";
import {
  Field,
  OnValidateRecord,
  Record,
  RecordDataSet,
  SpreadsheetOptions,
} from "fuse-importer";
import { OnDataSetChanged } from "../SpreadsheetContextProvider";

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export type SortBy = {
  field: string;
  direction: SortOrder;
};

export type SpreadsheetProps = {
  initialDataSetUpdatedAt?: number;
  initialDataSet: RecordDataSet;
  isReadOnly?: boolean;
  fields?: Field[];
  options?: SpreadsheetOptions;
  onValidateRecord?: OnValidateRecord;
  onDataSetChanged?: OnDataSetChanged;
  selectedRows?: string[];
  setSelectedRows?: React.Dispatch<React.SetStateAction<string[]>>;
  getSortCompareFn?: (sortBy: SortBy) => (a: Record, b: Record) => number;
} & DivCSSProps;

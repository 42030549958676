import React from "react";
import { SpreadsheetField, useSpreadsheetField } from "..";
import { Option } from "../../../../../../common/inputs/Autocomplete";
import { FieldTypes, Record } from "fuse-importer";
import { useReviewContext } from "../../../../../Review/ReviewContextProvider";
import * as S from "./SpreadsheetAutocomplete.styles";
import { ChevronDownIcon } from "./icons";

export type AutoCompleteOption = Option;
export type SpreadsheetAutocompleteProps = {
  name: string;
  record: Record;
  options: AutoCompleteOption[];
  isInputDisabled?: boolean;
  fieldType: FieldTypes;
  id?: string;
  columnWidth?: string;
};

export const SpreadsheetAutocomplete: React.FC<SpreadsheetAutocompleteProps> = (
  props
) => {
  const {
    name,
    record,
    options,
    fieldType,
    isInputDisabled = false,
    id,
  } = props;

  const {
    value,
    setValue,
    validationError,
    validationWarning,
  } = useSpreadsheetField(name, record, fieldType);
  const { hasTransformedRecords } = useReviewContext();

  const isHighlighted =
    hasTransformedRecords && record._meta?.transformedRow?.[name]?.transformed;

  return (
    <SpreadsheetField
      validationError={validationError}
      validationWarning={validationWarning}
      isHighlighted={isHighlighted}
    >
      <S.Autocomplete
        placement="bottom"
        isInputDisabled={isInputDisabled}
        hasError={!!validationError}
        hasWarning={!!validationWarning}
        options={options}
        value={value}
        icon={<ChevronDownIcon />}
        onChange={(v) => !hasTransformedRecords && setValue(v)}
        disabledWhen={hasTransformedRecords}
        id={id}
      />
    </SpreadsheetField>
  );
};

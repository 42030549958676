import { Div } from "fuse-shared-ui";
import {
  Organization,
  useUserContext,
} from "../../../../../../../UserContextProvider";
import { checkIcon } from "../icons";
import { MenuItem } from "./DropdownItem";

export const OrganizationsListing = () => {
  const { user, switchToOrganizationById } = useUserContext();
  const { organizations, active_organization } = user;

  const renderOrganization = (organization: Organization) => {
    const id = organization.id;
    const label = organization.name;
    const isSelected = organization.id === active_organization.id;

    return (
      <Div key={id}>
        <MenuItem
          onClick={() => {
            if (!isSelected) {
              switchToOrganizationById(id);
            }
          }}
        >
          <Div spaceBetween w100>
            <Div
              truncateText
              {...(isSelected ? { bold: true } : { regular: true })}
            >
              {label}
            </Div>
            <Div centered dim={24} ml={10} mr={-5}>
              {isSelected && checkIcon}
            </Div>
          </Div>
        </MenuItem>
      </Div>
    );
  };

  return <>{organizations.map(renderOrganization)}</>;
};

import { Div, TransformationOption } from "fuse-shared-ui";
import { NumberOfDecimalsOption } from "./inputs/NumberOfDecimalsOption";
import { FormikTextInput } from "components/common/form/fields";

type TransformationOptionsProps = {
  name: string;
  options: TransformationOption[];
};

const TransformationOptions = ({
  name,
  options,
}: TransformationOptionsProps) => {
  function renderOption(option: TransformationOption) {
    return (
      <Div key={`${name}.${option.key}`}>
        {option.key === "number_of_decimals" ? (
          <NumberOfDecimalsOption name={`${name}.${option.key}`} />
        ) : (
          <FormikTextInput
            w100={false}
            placeholder="Enter value"
            label="Pattern"
            name={`${name}.${option.key}`}
            type={option.type}
          />
        )}
      </Div>
    );
  }
  return <>{options.map(renderOption)}</>;
};

export default TransformationOptions;

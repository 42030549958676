import { useState } from "react";
import { useQueryParams } from "hooks";

import { background1 } from "../../common/assets/images";
import RevokedInvitationModal from "../RevokedInvitationModal";
import FormUI from "./FormUI";
import ImagesUI from "./ImagesUI";
import { Values } from "../types";
import { fields } from "../fields";
import {
  BackgroundImage1,
  ContentContainer,
  PreContentContainer,
  Wrapper,
} from "../styles";
import { api } from "fuse-shared-ui";
import { useUserContext } from "components/UserContextProvider";
import { SIGN_IN_ROUTE } from "../..";
import { useHistory } from "react-router";

const AcceptInvitationWithData = () => {
  const history = useHistory();
  const { signIn } = useUserContext();

  const [isLoading, setIsLoading] = useState(false);
  const [
    isRevokeInvitationModalOpen,
    setIsRevokeInvitationModalOpen,
  ] = useState(false);

  const {
    invitation_token: invitationToken,
    invitation_id: invitationId,
    company_name: companyName,
    email,
  } = useQueryParams([
    "invitation_token",
    "invitation_id",
    "company_name",
    "email",
  ]);

  const onSubmit = async (values: Values) => {
    values.email = email.replace(" ", "+");

    setIsLoading(true);
    try {
      await api.put(
        `/users/invitation`,
        {
          user: {
            ...{
              invitation_token: invitationToken,
              invitation_id: invitationId,
            },
            ...values,
          },
        },
        { successMsg: "Invitation Accepted!" }
      );

      const { email, password } = values;
      try {
        await signIn(email, password);
        setIsLoading(false);
        history.replace("/");
      } catch (err) {
        history.push(SIGN_IN_ROUTE);
      }
    } catch (error) {
      setIsRevokeInvitationModalOpen(true);
    }
    setIsLoading(false);
  };

  return (
    <Wrapper>
      <PreContentContainer>
        <ContentContainer br={16}>
          <RevokedInvitationModal
            isOpen={isRevokeInvitationModalOpen}
            hide={() => setIsRevokeInvitationModalOpen(false)}
          />
          <FormUI
            fields={fields}
            onSubmit={onSubmit}
            isLoading={isLoading}
            companyName={companyName}
            invitedUserEmail={email.replace(" ", "+")}
          />
          <ImagesUI />
        </ContentContainer>
        <BackgroundImage1>{background1}</BackgroundImage1>
      </PreContentContainer>
    </Wrapper>
  );
};

export default AcceptInvitationWithData;

import { get } from "lodash";
import { DateTime } from "luxon";
import { SortBy } from "../../../common";
import { Record } from "fuse-importer";
import { useImporterContext } from "../../contexts/ImporterContextProvider";

export const useFieldSorting = () => {
  const { templateHeaders } = useImporterContext();

  const getDefaultSortCompareFn = (sortBy: SortBy) => {
    return (a: Record, b: Record) => {
      const aField = get(a, sortBy.field);
      const bField = get(b, sortBy.field);

      const fieldTypes = templateHeaders.reduce((acc, header) => {
        const type = header.column_type;
        const pattern = header.pattern || null;
        acc[header.internal_key] = { type, pattern };
        return acc;
      }, {});

      const fieldType = fieldTypes[sortBy.field];

      const isAEmpty = aField === undefined || aField === null || aField === "";
      const isBEmpty = bField === undefined || bField === null || bField === "";

      // sort empty values to the end
      if (isAEmpty && isBEmpty) {
        if (a._meta.rowIndex === b._meta.rowIndex) return 0;
        return a._meta.rowIndex < b._meta.rowIndex ? -1 : 1;
      }
      if (isAEmpty) return 1;
      if (isBEmpty) return -1;

      if (fieldType && fieldType.type === "boolean") {
        const aString = aField ? "true" : "false";
        const bString = bField ? "true" : "false";

        if (aString === bString) {
          if (a._meta.rowIndex === b._meta.rowIndex) return 0;
          return a._meta.rowIndex < b._meta.rowIndex ? -1 : 1;
        }
        if (aString === "true") return sortBy.direction === "asc" ? -1 : 1;
        if (bString === "true") return sortBy.direction === "asc" ? 1 : -1;
      } else if (
        fieldType &&
        (fieldType.type === "date" ||
          fieldType.type === "datetime" ||
          fieldType.type === "time")
      ) {
        const aDateTime = aField
          ? DateTime.fromFormat(aField, fieldType.pattern)
          : null;
        const bDateTime = bField
          ? DateTime.fromFormat(bField, fieldType.pattern)
          : null;

        // sort invalid date values to the end before empty values
        if (aDateTime && !aDateTime.isValid) return 1;
        if (bDateTime && !bDateTime.isValid) return -1;

        if (aDateTime && bDateTime && aDateTime.equals(bDateTime)) {
          if (a._meta.rowIndex === b._meta.rowIndex) return 0;
          return a._meta.rowIndex < b._meta.rowIndex ? -1 : 1;
        }

        if (sortBy.direction === "asc") {
          return aDateTime?.valueOf() - bDateTime?.valueOf();
        }

        return bDateTime?.valueOf() - aDateTime?.valueOf();
      } else {
        if (aField === bField) {
          if (a._meta.rowIndex === b._meta.rowIndex) return 0;
          return a._meta.rowIndex < b._meta.rowIndex ? -1 : 1;
        }
        if (sortBy.direction === "asc") {
          return aField > bField ? 1 : -1;
        }
        return aField < bField ? 1 : -1;
      }
    };
  };

  return { getDefaultSortCompareFn };
};

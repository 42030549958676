import { editIcon } from "components/Routes/AccountRoutes/TeamMembers/common/icons";
import { Div, TooltipProvider } from "fuse-shared-ui";
import { useState } from "react";
import styled from "styled-components";
import { EditModal } from "../../../Templates/CreateTemplateFormModal/components/EditTemplateModal";
import { Template } from "../../types";

const Wrapper = styled(Div)`
  align-items: center;
  display: flex;
  gap: 12px;
  width: 100%;
  max-width: clamp(min(10vw, 150px), 45vw, 50vw);

  @media all and (max-width: 1470px) {
    max-width: clamp(min(10vw, 150px), 30vw, 40vw);
  }

  @media all and (max-width: 1066px) {
    max-width: 100%;
  }
`;

const Paragraph = styled.p`
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) => props.theme.textStyles.wh1};

  @media all and (max-width: 1470px) {
    font-size: 32px;
    line-height: 56px;
  }
`;

export const EditButton = styled.button`
  border: none;
  background: none;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
`;

const StyledTooltipProvider = styled(TooltipProvider)`
  width: fit-content;
`;

type Props = {
  template: Template;
  isLoading: boolean;
  getData: () => Promise<void>;
  updateTemplate: (values: Partial<Template>) => Promise<void>;
};

const TemplateNameInput = ({ template, getData, updateTemplate }: Props) => {
  const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);

  return (
    <Wrapper>
      <Paragraph data-cy="template-name-input">{template.name}</Paragraph>

      <StyledTooltipProvider tooltip="Edit" placement="auto" centered>
        <EditButton
          data-cy="template-edit-button"
          onClick={() => setIsEditTemplateModalOpen(true)}
        >
          {editIcon}
        </EditButton>
      </StyledTooltipProvider>

      <EditModal
        getData={getData}
        updateTemplate={updateTemplate}
        row={template}
        isOpen={isEditTemplateModalOpen}
        hideModal={() => setIsEditTemplateModalOpen(false)}
      />
    </Wrapper>
  );
};

export default TemplateNameInput;

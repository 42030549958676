import { useReducer } from "react";

export type SpreadsheetFilter = {
  type: "rowBased" | "columnBased";
  byRow?: "all" | "invalid" | "duplicates";
  byColumn?: {
    type: "fieldWithErrors";
    field: string;
  };
};
export const useSpreadsheetFilter = () => {
  type Action =
    | { type: "FILTER_BY_ROW_TYPE"; payload: SpreadsheetFilter["byRow"] }
    | {
        type: "FILTER_BY_FIELD_ERRORS";
        payload: SpreadsheetFilter["byColumn"]["field"];
      }
    | { type: "RESTORE_FILTERS" };

  const filtersInitialState: SpreadsheetFilter = {
    type: "rowBased",
    byRow: "all",
  };

  function reducer(
    state: SpreadsheetFilter,
    action: Action
  ): SpreadsheetFilter {
    switch (action.type) {
      case "FILTER_BY_ROW_TYPE":
        return { type: "rowBased", byRow: action.payload, byColumn: null };
      case "FILTER_BY_FIELD_ERRORS":
        return {
          type: "columnBased",
          byColumn: {
            type: "fieldWithErrors",
            field: action.payload,
          },
          byRow: null,
        };
      case "RESTORE_FILTERS":
        return filtersInitialState;
      default:
        return state;
    }
  }

  const [spreadsheetFilter, filtersDispatch] = useReducer(
    reducer,
    filtersInitialState
  );

  const resetSpreadsheetFilter = () => {
    filtersDispatch({ type: "RESTORE_FILTERS" });
  };

  const filterRecordsByRowType = (filterByRow: SpreadsheetFilter["byRow"]) => {
    filtersDispatch({ type: "FILTER_BY_ROW_TYPE", payload: filterByRow });
  };

  const filterRecordsByFieldErrors = (filterByColumn: string) => {
    filtersDispatch({
      type: "FILTER_BY_FIELD_ERRORS",
      payload: filterByColumn,
    });
  };

  return {
    spreadsheetFilter,
    filterRecordsByRowType,
    filterRecordsByFieldErrors,
    resetSpreadsheetFilter,
  };
};

import { Pill, PillType } from "./Pill";

const EditIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2485 0.751523C10.0242 -0.472801 8.61896 0.167187 8.61896 0.167187L7.77406 1.01209L10.9879 4.22594L11.8328 3.38104C11.8328 3.38104 12.4728 1.97585 11.2485 0.751523ZM9.5737 5.64016L6.35985 2.4263L0.438252 8.3479L0 12L3.6521 11.5617L9.5737 5.64016Z"
      fill="#FF9A34"
    />
  </svg>
);

const SubmittedIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5ZM4.94194 5.53291C4.69786 5.28883 4.30214 5.28883 4.05806 5.53291C3.81398 5.77699 3.81398 6.17272 4.05806 6.4168L5.11872 7.47746C5.3628 7.72153 5.75852 7.72153 6.0026 7.47746L8.12392 5.35613C8.368 5.11206 8.368 4.71633 8.12392 4.47225C7.87984 4.22817 7.48412 4.22817 7.24004 4.47225L5.56066 6.15163L4.94194 5.53291Z"
      fill="#00D085"
    />
  </svg>
);

export type StatusBadgeType =
  | "DRAFT"
  | "PROCESSING"
  | "SUBMITTED"
  | "COMPLETED"
  | "FAILED"
  | "COMPLETED WITH ISSUES";

type StatusBadgeProps = {
  status: StatusBadgeType;
};

const getIconAndVariant = (status: StatusBadgeType) => {
  switch (status) {
    case "DRAFT": {
      return { customIcon: EditIcon, variant: "warning" };
    }
    case "PROCESSING": {
      return { customIcon: EditIcon, variant: "warning" };
    }
    case "SUBMITTED": {
      return { customIcon: SubmittedIcon, variant: "success" };
    }
    case "COMPLETED": {
      return { customIcon: SubmittedIcon, variant: "success" };
    }
    case "COMPLETED WITH ISSUES": {
      return { variant: "warning" };
    }
    case "FAILED": {
      return { variant: "error" };
    }
    default: {
      return { customIcon: EditIcon, variant: "warning" };
    }
  }
};

export const StatusBadge = ({ status }: StatusBadgeProps) => {
  const { customIcon, variant } = getIconAndVariant(status);
  return (
    <Pill
      status={status}
      variant={variant as PillType}
      customIcon={customIcon}
    />
  );
};

import { useEffect, useState } from "react";
import { css } from "fuse-shared-ui";
import styled from "styled-components";
import { CSSAnyProps, Div } from "fuse-shared-ui";
import { useNavigationContext } from "../NavigationContextProvider";

export const NavigationContent = styled(Div)<CSSAnyProps>`
  ${(props) => props.theme.css.alignCenter};
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.tableHeader};
    border-radius: 8px;
  }
  ${(p: any) =>
    p.isOpen
      ? css`
          ${p.theme.css.fadeIn};
          padding-left: 23px;
        `
      : css`
          ${p.theme.css.justifyCenter};
        `};
`;

// used for appropriately displaying text with side panel animation
export const useDisplayText = () => {
  const { isOpen } = useNavigationContext();
  const [displayText, setDisplayText] = useState(false);

  useEffect(() => {
    if (isOpen)
      setTimeout(() => {
        setDisplayText(true);
      }, 100);
    else setDisplayText(false);
  }, [isOpen]);

  return { displayText };
};

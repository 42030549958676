import { Div, colors } from "fuse-shared-ui";
import styled from "styled-components";
import { useWindowDimensions } from "../../../../../hooks";
import { Navigation } from "./Navigation";
import { DesktopLogo, HamburgerMenu } from "./Navigation/icons";
import { useNavigationContext } from "./NavigationContextProvider";

export const Container = styled(Div)`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: ${(p) => p.theme.colors.backgroundPrimary};
`;

const Content = styled(Div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  ${(p) => p.theme.css.scrollbarDark};
`;

const LogoWrapper = styled(Div)`
  svg {
    height: 27px;
    width: 26px;
  }
`;

type Props = {
  children: JSX.Element;
};

export const NavigationLayout = ({ children }: Props) => {
  const { isOpen, setIsOpen } = useNavigationContext();
  const { width } = useWindowDimensions();

  const isOnMobileDevice = width < 600;

  return (
    <Container>
      {width < 600 && !isOpen && (
        <Div bg={colors.white} p="12px 16px" dflex alignCenter spaceBetween>
          <LogoWrapper>
            <DesktopLogo />
          </LogoWrapper>

          <Div w="fit-content" h="fit-content" onClick={() => setIsOpen(true)}>
            <HamburgerMenu />
          </Div>
        </Div>
      )}

      <Div dflex h100>
        <Navigation isOnMobileDevice={isOnMobileDevice} isOpen={isOpen} />
        <Content>{children}</Content>
      </Div>
    </Container>
  );
};

import { deleteImageIcon } from "components/Routes/AccountRoutes/TeamMembers/common/icons";
import { Button, Div, Modal } from "fuse-shared-ui";
import styled from "styled-components";

const Content = styled(Div)`
  width: 448px;
  height: 280px;
  margin: 48px 40px;
`;

const Text = styled(Div)`
  margin-top: 12px;
  ${(p) => p.theme.textStyles.body1};
  justify-content: center;
`;

const FormButtonsWrapper = styled(Div)`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

type DeleteModalProps = {
  isOpen: boolean;
  onDelete: () => void;
  onCancel: () => void;
};

const DeleteModal = ({ isOpen, onDelete, onCancel }: DeleteModalProps) => {
  const contentUI = (
    <Content data-cy="delete-pop-up">
      <Div textAlignCenter>
        {deleteImageIcon}
        <Div data-cy="webhook-delete-title" mt={20} h5>
          Delete Webhook
        </Div>
        <Text data-cy="webhook-delete-description">
          Are you sure you want delete this webhook? Data will no longer be sent
          to it.
        </Text>
      </Div>
      <FormButtonsWrapper>
        <Button
          data-cy="webhook-delete-cancel-button"
          mr={16}
          w={104}
          variant="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button data-cy="webhook-yes-delete-button" w={135} onClick={onDelete}>
          Yes, delete
        </Button>
      </FormButtonsWrapper>
    </Content>
  );

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      {contentUI}
    </Modal>
  );
};

export default DeleteModal;

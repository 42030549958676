import React from "react";
import { TemplateItem } from "../../types";
import {
  TemplateItemContainer,
  DeleteIcon,
  DeleteButton,
  TemplateListContainer,
} from "./TemplateList.styles";

type Props = {
  templates?: TemplateItem[];
  onDelete?: (template: TemplateItem) => void;
};

export const TemplateList = ({ templates, onDelete }: Props) => {
  const renderTemplate = (template: TemplateItem) => {
    const handleDelete = () => {
      onDelete && onDelete(template);
    };
    return (
      <TemplateItemContainer key={template.id}>
        {template.name}
        <DeleteButton data-cy="delete-icon" onClick={handleDelete}>
          {DeleteIcon}
        </DeleteButton>
      </TemplateItemContainer>
    );
  };
  return (
    <TemplateListContainer data-cy="importer-list">
      {templates ? templates.map(renderTemplate) : null}
    </TemplateListContainer>
  );
};

import {
  Div,
  ModalScrollArea,
  TallModal,
  TallModalLayout,
  transformationsByColumnType,
} from "fuse-shared-ui";
import { get } from "lodash";
import { useMemo } from "react";
import WizardHeader from "./WizardHeader";
import ColumnForm from "./components/ColumnForm";
import ColumnFormModalButtons from "./components/ColumnFormModalButtons";
import TransformationsForm from "./components/TransformationsForm";
import ValidationsForm from "./components/ValidationsForm";
import { StyledForm } from "./components/styles";
import { fields } from "./fields";
import { useColumnForm, useColumnFormSubmit } from "./hooks";

type ColumnFormWithContextProps = {
  initialValues?: any;
  hideModal: () => void;
  isLoading: boolean;
  isEditing: boolean;
};

type ColumnFormModalProps = {
  isOpen: boolean;
  getData: () => void;
  hideModal: () => void;
  template: any;
  initialValues?: any;
};

const ColumnFormModal = ({
  template,
  hideModal,
  getData,
  initialValues = {},
  isOpen,
}: ColumnFormModalProps) => {
  const isEditing = useMemo(() => !!get(initialValues, "id"), [initialValues]);
  const { isLoading, onSubmit } = useColumnFormSubmit({
    hideModal,
    getData,
    template,
    initialValues,
    isEditing,
  });

  const fieldsWithInitialValue = useMemo(() => {
    return fields.map((field) => ({
      ...field,
      initialValue: get(initialValues, field.name, field.initialValue),
    }));
  }, [initialValues]);

  return (
    <TallModal isOpen={isOpen} onClose={hideModal}>
      <StyledForm fields={fieldsWithInitialValue} onSubmit={onSubmit}>
        <ColumnFormWithContext
          isLoading={isLoading}
          initialValues={initialValues}
          hideModal={hideModal}
          isEditing={isEditing}
        />
      </StyledForm>
    </TallModal>
  );
};

const ColumnFormWithContext = ({
  hideModal,
  initialValues,
  isLoading,
  isEditing,
}: ColumnFormWithContextProps) => {
  const {
    activeTabIndex,
    columnType,
    showPatternField,
    canAddMoreValidations,
    canAddMoreTransformations,
    handleCancel,
    handleNext,
  } = useColumnForm({ initialValues, hideModal });

  const columnTypeHasTransformations =
    columnType &&
    typeof transformationsByColumnType[columnType] !== "undefined";

  const disabledSteps = useMemo(() => {
    const steps = [];

    if (columnTypeHasTransformations === false) {
      steps.push(2);
    }

    return steps;
  }, [columnTypeHasTransformations]);

  const wizardStepsUI = (
    <ModalScrollArea>
      {activeTabIndex === 0 && (
        <ColumnForm
          isEditing={isEditing}
          columnType={columnType}
          showPatternField={showPatternField}
        />
      )}
      {activeTabIndex === 1 && (
        <ValidationsForm
          isEditing={isEditing}
          canAddMoreValidations={canAddMoreValidations}
        />
      )}
      {activeTabIndex === 2 && (
        <TransformationsForm
          isEditing={isEditing}
          canAddMoreTransformations={canAddMoreTransformations}
          shouldBeDisabled={!columnTypeHasTransformations}
        />
      )}
    </ModalScrollArea>
  );

  return (
    <TallModalLayout>
      <Div p=" 50px 22px 10px 30px">
        <WizardHeader
          steps={["Add a Column", "Add Validations", "Add Transformations"]}
          activeIndex={activeTabIndex}
          disabledSteps={disabledSteps}
        />
      </Div>
      {wizardStepsUI}
      <ColumnFormModalButtons
        isEditing={isEditing}
        isLoading={isLoading}
        onCancel={handleCancel}
        onNext={handleNext}
        activeTabIndex={activeTabIndex}
        isNextStepDisabled={
          disabledSteps && disabledSteps.includes(activeTabIndex + 1)
        }
      />
    </TallModalLayout>
  );
};

export default ColumnFormModal;

import TableDeleteIcon from "components/Routes/AccountRoutes/TemplateRoutes/TemplateColumns/components/TableColumns/components/TableDeleteIcon";
import TableEditIcon from "components/Routes/AccountRoutes/TemplateRoutes/TemplateColumns/components/TableColumns/components/TableEditIcon";
import { useDeleteTableRow } from "components/Routes/AccountRoutes/TemplateRoutes/TemplateColumns/components/TableColumns/useDeleteTableRow";
import { Div } from "fuse-shared-ui";
import { useState } from "react";
import styled from "styled-components";
import CreateModal from "../CreateModal";
import DeleteModal from "./DeleteModal";

const TypeChip = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  background: ${(props) => props.theme.colors.white50};
  border-radius: 24px;
`;

export const tableColumns = [
  {
    header: "Name",
    width: 0.5,
    Content: ({ name }) => (
      <Div w100 overflowHidden truncateText title={name}>
        {name}
      </Div>
    ),
  },
  {
    width: 0.5,
    header: "Importer",
    Content: ({ template_name }) => {
      return (
        <Div w100 overflowHidden truncateText title={template_name}>
          {template_name}
        </Div>
      );
    },
  },
  {
    width: 0.5,
    header: "URL",
    Content: ({ url }) => {
      return (
        <Div w100 overflowHidden truncateText title={url}>
          {url}
        </Div>
      );
    },
  },
  {
    header: "Type",
    width: 0.5,
    Content: ({ webhook_type }) => <TypeChip>{webhook_type}</TypeChip>,
  },
  {
    width: 0.5,
    header: "Secret Key",
    Content: ({ secret_token }) => {
      return <Div w100>{secret_token}</Div>;
    },
  },
  {
    width: 0.5,
    header: "",
    Content: (data) => {
      const [isEditModalOpen, setIsEditModalOpen] = useState(false);
      const [deleteModalOpen, setDeleteModalOpen] = useState(false);
      const { deleteTableRow } = useDeleteTableRow({
        deleteUrl: `/api/v1/organizations/webhooks/${data.id}`,
        customMessage: "The webhook has been deleted",
      });

      const onDelete = () => {
        deleteTableRow();
        setDeleteModalOpen(false);
      };

      return (
        <>
          <Div dflex m={0}>
            {isEditModalOpen && (
              <CreateModal
                isEditMode={true}
                initialValues={data}
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
              />
            )}
            {deleteModalOpen && (
              <DeleteModal
                isOpen={deleteModalOpen}
                onDelete={() => onDelete()}
                onCancel={() => setDeleteModalOpen(false)}
              />
            )}

            <TableEditIcon onClick={() => setIsEditModalOpen(true)} />
            <TableDeleteIcon onClick={() => setDeleteModalOpen(true)} />
          </Div>
        </>
      );
    },
  },
];

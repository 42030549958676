export const Warning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 6C12.5523 6 13 6.44772 13 7V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V7C11 6.44772 11.4477 6 12 6ZM12 15C12.5523 15 13 15.4477 13 16V16.5C13 17.0523 12.5523 17.5 12 17.5C11.4477 17.5 11 17.0523 11 16.5V16C11 15.4477 11.4477 15 12 15Z"
      fill="#FF9A34"
    />
  </svg>
);

export const Error = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L10.5858 12L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L12 10.5858L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L13.4142 12L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L12 13.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071Z"
      fill="#5C1008"
    />
  </svg>
);

export const Success = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM9.70711 11.2426C9.31658 10.8521 8.68342 10.8521 8.29289 11.2426C7.90237 11.6331 7.90237 12.2663 8.29289 12.6568L10.4142 14.7781L11.1213 15.4852L11.8284 14.7781L16.0711 10.5355C16.4616 10.145 16.4616 9.5118 16.0711 9.12128C15.6805 8.73076 15.0474 8.73076 14.6569 9.12128L11.1213 12.6568L9.70711 11.2426Z"
      fill="#0F4519"
    />
  </svg>
);

export const Information = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 17.5C12.3117 17.5 12.5729 17.3946 12.7837 17.1838C12.9946 16.9729 13.1 16.7117 13.1 16.4V12C13.1 11.6883 12.9946 11.4271 12.7837 11.2163C12.5729 11.0054 12.3117 10.9 12 10.9C11.6883 10.9 11.4271 11.0054 11.2163 11.2163C11.0054 11.4271 10.9 11.6883 10.9 12V16.4C10.9 16.7117 11.0054 16.9729 11.2163 17.1838C11.4271 17.3946 11.6883 17.5 12 17.5ZM12 8.7C12.3117 8.7 12.5729 8.59458 12.7837 8.38375C12.9946 8.17292 13.1 7.91167 13.1 7.6C13.1 7.28833 12.9946 7.02708 12.7837 6.81625C12.5729 6.60542 12.3117 6.5 12 6.5C11.6883 6.5 11.4271 6.60542 11.2163 6.81625C11.0054 7.02708 10.9 7.28833 10.9 7.6C10.9 7.91167 11.0054 8.17292 11.2163 8.38375C11.4271 8.59458 11.6883 8.7 12 8.7ZM12 23C10.4783 23 9.04833 22.7113 7.71 22.1337C6.37167 21.5562 5.2075 20.7725 4.2175 19.7825C3.2275 18.7925 2.44375 17.6283 1.86625 16.29C1.28875 14.9517 1 13.5217 1 12C1 10.4783 1.28875 9.04833 1.86625 7.71C2.44375 6.37167 3.2275 5.2075 4.2175 4.2175C5.2075 3.2275 6.37167 2.44375 7.71 1.86625C9.04833 1.28875 10.4783 1 12 1C13.5217 1 14.9517 1.28875 16.29 1.86625C17.6283 2.44375 18.7925 3.2275 19.7825 4.2175C20.7725 5.2075 21.5562 6.37167 22.1337 7.71C22.7113 9.04833 23 10.4783 23 12C23 13.5217 22.7113 14.9517 22.1337 16.29C21.5562 17.6283 20.7725 18.7925 19.7825 19.7825C18.7925 20.7725 17.6283 21.5562 16.29 22.1337C14.9517 22.7113 13.5217 23 12 23Z"
      fill="#025B5B"
    />
  </svg>
);

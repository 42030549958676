import { PaymentMethodsIds } from "__data__";
import { Div, Modal } from "fuse-shared-ui";
import { useState } from "react";
import { useBillingContext } from "./BillingContextProvider";
import PaymentMethodCard from "./common/PaymentMethodCard";
import PaymentMethodsForm from "./common/PaymentMethodsForm";

type PaymentMethodsProps = {
  testId?: string;
  testIds?: PaymentMethodsIds;
};

const PaymentMethods = ({
  testId,
  testIds,
}: PaymentMethodsProps): JSX.Element => {
  const {
    paymentMethod,
    isLoadingPaymentMethods,
    currentSubscriptionPeriodEnd,
  } = useBillingContext();
  const [isOpenPaymentMethodModal, setIsOpenPaymentMethodModal] = useState(
    false
  );

  return (
    <>
      {paymentMethod && (
        <Div mt={40} data-testid={testId}>
          <PaymentMethodCard
            isLoading={isLoadingPaymentMethods}
            card={paymentMethod?.card}
            cardHolder={paymentMethod?.billing_details?.name}
            nextBillingDate={currentSubscriptionPeriodEnd}
            onChangePaymentMethod={() => setIsOpenPaymentMethodModal(true)}
            testIds={testIds}
          />
        </Div>
      )}
      <Modal
        isOpen={isOpenPaymentMethodModal}
        onClose={() => setIsOpenPaymentMethodModal(false)}
        testId={testIds?.modalTestId}
      >
        <PaymentMethodsForm
          headerText="Change Payment Method"
          buttonLabel="Change Card"
          onClose={() => setIsOpenPaymentMethodModal(false)}
        />
      </Modal>
    </>
  );
};

export default PaymentMethods;

import Form from "components/common/form/Form";

import styled from "styled-components";

export const StyledForm = styled(Form)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

import { FormikTextInput } from "components/common/form/fields";
import { textFields } from "./fields";
import { FormContentItem } from "./styles";

type FieldsUIProps = {
  emailDefaultValue: string;
};

const FieldsUI = ({ emailDefaultValue }: FieldsUIProps) => {
  textFields.map((field) => {
    if (field.name === "email") {
      field.placeholder = emailDefaultValue;
    }
    return field;
  });

  return (
    <>
      {textFields.map(({ name, label, placeholder, inputProps }) => (
        <FormContentItem key={name} mt={24}>
          <FormikTextInput
            {...{
              label,
              placeholder,
              name,
              isDisabled: name === "email",
            }}
            type={inputProps?.type || "text"}
          />
        </FormContentItem>
      ))}
    </>
  );
};

export default FieldsUI;

import { DateTime } from "luxon";
import * as yup from "yup";
import {
  ColumnRequiredProps,
  ColumnValidations,
  FieldTypes,
  FieldTypesAsUnion,
  ValidationOptionKeys,
  ValidationTypes,
  mutableTransformationTypes,
  mutableValidationTypes,
} from "fuse-importer";
import {
  allowedPatterns,
  datePatterns,
  dateTimePatterns,
  timePatterns,
} from "./datePatterns";

export type ValidationOption = {
  type: "text" | "number" | "date";
  key: ValidationOptionKeys;
  tooltip: string;
};

export type Validation = {
  value: ValidationTypes;
  label: string;
  isDefaultValidation?: boolean;
  options?: ValidationOption[];
};

const isValidISO = (date: string | Date) => {
  if (!date) return false;

  const isAJsDate = typeof (date as Date)?.getMonth === "function";

  const functionToUse: (date: string | Date) => DateTime = isAJsDate
    ? DateTime.fromJSDate
    : DateTime.fromISO;

  const result = functionToUse(date).invalidReason;

  // if typeof result is a string, it's an invalid ISO 8601 date
  return typeof result !== "string";
};

const validationTypeDoesNotMatchOptions = (
  options: Record<string, string | number | Date>,
  validation_type: string
) => {
  const optionKeys = Object.keys(options);

  return !optionKeys.includes(validation_type);
};

const commonValidations: Validation[] = [
  {
    value: "regex",
    label: "Must Match Regex",
    options: [
      {
        type: "text",
        key: "pattern",
        tooltip: "Regex Pattern",
      },
    ],
  },
];

const uniqueValidation: Validation[] = [
  { value: "unique_case_sensitive", label: "Must be Unique (case sensitive)" },
  {
    value: "unique_case_insensitive",
    label: "Must be Unique (case insensitive)",
  },
];

const containsValidations: Validation[] = [
  {
    value: "cannot_contain",
    label: "Cannot Contain",
    options: [
      {
        type: "text",
        key: "pattern",
        tooltip: "It must be a word that you want to exclude",
      },
    ],
  },
  {
    value: "contain",
    label: "Must Contain",
    options: [
      {
        type: "text",
        key: "pattern",
        tooltip: "It must be a word to be included mandatorily",
      },
    ],
  },
];

const stringValidations: Validation[] = [
  ...containsValidations,
  {
    value: "max_length",
    label: "Length (shorter than)",
    options: [
      {
        type: "number",
        key: "max_length",
        tooltip: "Number of characters",
      },
    ],
  },
  {
    value: "min_length",
    label: "Length (longer than)",
    options: [
      {
        type: "number",
        key: "min_length",
        tooltip: "Number of characters",
      },
    ],
  },
  {
    value: "length_exactly",
    label: "Length (exactly)",
    options: [
      {
        type: "number",
        key: "length",
        tooltip: "Number of characters",
      },
    ],
  },
  ...uniqueValidation,
  ...commonValidations,
];

const numericValidations: Validation[] = [
  {
    value: "less_than",
    label: "Must Be Less Than",
    options: [
      {
        type: "number",
        key: "max",
        tooltip: "The max allowed value",
      },
    ],
  },
  {
    value: "greater_than",
    label: "Must Be Greater Than",
    options: [
      {
        type: "number",
        key: "min",
        tooltip: "The minimum allowed value",
      },
    ],
  },
  { value: "even", label: "Must be Even" },
  { value: "odd", label: "Must be Odd" },
  ...uniqueValidation,
  ...commonValidations,
];

const emailValidations: Validation[] = [
  { value: "email", label: "Must be an email", isDefaultValidation: true },
  ...stringValidations,
];

const urlValidations: Validation[] = [
  { value: "url", label: "Must be a URL", isDefaultValidation: true },
  ...stringValidations,
];

const booleanValidations: Validation[] = [
  {
    value: "boolean",
    label: "Must be True or False",
    isDefaultValidation: true,
  },
];

const structuredDataValidations: Validation[] = [...uniqueValidation];

const dateValidations: Validation[] = [
  ...uniqueValidation,
  {
    value: "before_date",
    label: "Must Be Before Date",
    options: [
      {
        type: "date",
        key: "max",
        tooltip: "The maximum allowed date",
      },
    ],
  },
  {
    value: "after_date",
    label: "Must Be After Date",
    options: [
      {
        type: "date",
        key: "min",
        tooltip: "The minimum allowed date",
      },
    ],
  },
];

export const validationsByColumnType: { [key: string]: Validation[] } = {
  string: stringValidations,
  integer: [
    {
      value: "integer",
      label: "Must be an integer",
      isDefaultValidation: true,
    },
    ...numericValidations,
  ],
  float: [
    {
      value: "float",
      label: "Must be a float",
      isDefaultValidation: true,
    },
    ...numericValidations,
  ],
  email: emailValidations,
  enum: structuredDataValidations,
  url: urlValidations,
  boolean: booleanValidations,
  date: dateValidations,
  datetime: dateValidations,
  time: uniqueValidation,
};

export const validationsByType = Object.values(validationsByColumnType)
  .reduce((acc, arr) => acc.concat(arr), [])
  .reduce((result, p) => {
    result[p.value] = p;
    return result;
  }, {});

export const availableColumnTypes = Object.keys(validationsByColumnType);

export const patternsColumnTypes = Object.keys(allowedPatterns);

const validationsSchema = yup
  .mixed()
  .test("validations", "Invalid validations", function (value, context) {
    const parentRecord = context.parent;

    const isAnArray = Array.isArray(value);
    if (isAnArray) {
      for (const validation of value) {
        const { validation_type, options } = validation;
        const optionsPropertyIsNotRequiredFor = [
          "unique_case_insensitive",
          "unique_case_sensitive",
          "integer",
          "boolean",
          "odd",
          "even",
          "email",
          "url",
          "float",
        ];

        if (mutableValidationTypes.includes(validation_type)) {
          if (optionsPropertyIsNotRequiredFor.includes(validation_type)) {
            continue;
          }

          if (!options) {
            return this.createError({
              path: "validations",
              message: "Options is required for this validation type",
            });
          }

          const allowedTypes = ["date", "datetime"];

          // making sure that we validate the below options only for dates
          if (!allowedTypes.includes(parentRecord.column_type)) {
            continue;
          }

          if (validationTypeDoesNotMatchOptions(options, validation_type)) {
            return this.createError({
              path: "validations",
              message: `The property you provided as an option for the validation_type ${validation_type} is invalid.`,
            });
          }

          const { max, before_date, min, after_date } = options;

          const minValidator = min || after_date;
          const maxValidator = max || before_date;

          const minOptionDoesNotMatchISO = !isValidISO(minValidator);
          const maxOptionDoesNotMatchISO = !isValidISO(maxValidator);

          const generateISO8601Error = (option: string) => {
            return this.createError({
              path: "validations",
              message: `Invalid ISO 8601 format for ${option} on column ${parentRecord.internal_key}`,
            });
          };

          if (!maxValidator && minValidator && minOptionDoesNotMatchISO) {
            return generateISO8601Error("after_date");
          }

          if (!minValidator && maxValidator && maxOptionDoesNotMatchISO) {
            return generateISO8601Error("before_date");
          }
        } else {
          return this.createError({
            path: "validations",
            message: `${validation_type} is not a valid validation type. Please check the values you inserted.`,
          });
        }
      }

      return true;
    }

    const arrayIsNotDefined = !["undefined", "null"].includes(typeof value);
    if (arrayIsNotDefined && !isAnArray) {
      return this.createError({
        path: "validations",
        message: "Validations must be an array",
      });
    }

    return true;
  });

const transformationsOptionsPropIsRequiredFor = [
  "prefix",
  "number_of_decimals",
];

const transformationsSchema = yup
  .array()
  .of(
    yup.object().shape({
      transformation_type: yup
        .string()
        .oneOf(mutableTransformationTypes)
        .required(),
      options: yup.mixed().when("transformation_type", {
        is: (type: string) =>
          transformationsOptionsPropIsRequiredFor.includes(type),
        then: yup
          .object()
          .required("Options is required for this transformation type"),
      }),
    })
  )
  .test("transformations", "Invalid transformations", function (
    value,
    context
  ) {
    const isAnArray = Array.isArray(value);

    if (isAnArray) {
      for (const transformation of value) {
        const { transformation_type } = transformation;

        // if user is selecting autoformat (for date, datetime and time) but has not
        // defined pattern, it should throw an error
        if (transformation_type === "autoformat") {
          const parentRecord = context.parent;

          if (!parentRecord?.pattern) {
            const columnType = parentRecord.column_type;

            let availablePatternsForColumnType = null;

            const getAvailablePatterns = (patterns: typeof datePatterns) => {
              const patternValues = patterns.map((pattern) => pattern.value);

              return patternValues.join(", ");
            };

            switch (columnType) {
              case "date":
                availablePatternsForColumnType = getAvailablePatterns(
                  datePatterns
                );
                break;
              case "datetime":
                availablePatternsForColumnType = getAvailablePatterns(
                  dateTimePatterns
                );
                break;
              case "time":
                availablePatternsForColumnType = getAvailablePatterns(
                  timePatterns
                );
                break;
            }

            return this.createError({
              path: "transformations",
              message: `The selected transformation type requires you to specify a pattern to your column. Available patterns for your column_type: ${availablePatternsForColumnType}`,
            });
          }
        }

        if (
          !(mutableTransformationTypes as string[]).includes(
            transformation_type
          )
        ) {
          return this.createError({
            path: "transformations",
            message: `${transformation_type} is not a valid transformation type. Please check the values you inserted.`,
          });
        }
      }

      return true;
    }

    const arrayIsNotDefined = !["undefined", "null"].includes(typeof value);
    if (arrayIsNotDefined && !isAnArray) {
      return this.createError({
        path: "validations",
        message: "Validations must be an array",
      });
    }

    return true;
  });

const columnSchema = yup.object().shape({
  column_type: yup.string().oneOf(availableColumnTypes).required(),
  internal_key: yup.string().required(),
  label: yup.string().required(),
  required: yup.boolean().required(),
  validations: validationsSchema,
  transformations: transformationsSchema,
});

const dateColumnSchema = columnSchema.shape({
  pattern: yup.string().oneOf(datePatterns.map((p) => p.value)),
});

const dateTimeColumnSchema = columnSchema.shape({
  pattern: yup.string().oneOf(dateTimePatterns.map((p) => p.value)),
});

const timeColumnSchema = columnSchema.shape({
  pattern: yup.string().oneOf(timePatterns.map((p) => p.value)),
});

const enumColumnSchema = columnSchema.shape({
  values: yup.array().of(yup.string()).min(1).required(),
});

const deprecatedOptions = ["min", "max"];

const validateDeprecatedOptions = <T extends FieldTypesAsUnion>(
  validations: ColumnValidations<T>[] = []
) => {
  validations.forEach((validation) => {
    if (!validation || typeof validation !== "object" || !validation?.options)
      return;

    const validationOptions = Object.keys(validation.options);
    const deprecatesFound = validationOptions?.filter?.((o) =>
      deprecatedOptions.includes(o)
    );

    if (deprecatesFound.length) {
      const deprecatesAsString = deprecatesFound.join(", ");
      const warnMessage = `You have deprecated options for your validations: ${deprecatesAsString}. Please review the documentation to update.`;

      console.warn(warnMessage);
    }
  });
};

export const validateColumn = async <T extends FieldTypesAsUnion>(
  columnData: ColumnRequiredProps<T>
) => {
  const schema =
    columnData.column_type === FieldTypes.date
      ? dateColumnSchema
      : columnData.column_type === FieldTypes.datetime
      ? dateTimeColumnSchema
      : columnData.column_type === FieldTypes.time
      ? timeColumnSchema
      : columnData.column_type === FieldTypes.enum
      ? enumColumnSchema
      : columnSchema;

  validateDeprecatedOptions<T>(columnData.validations);

  try {
    await schema.validate(columnData, { abortEarly: true });
  } catch (error) {
    throw new Error(`Error on custom column creation: ${error.message}`);
  }
};

import Form from "components/common/form/Form";
import { useCallback, useState } from "react";
import Header from "../../../../components/Header";
import { UseTemplateFormArgs } from "../../hooks";
import * as S from "../../styles";
import { TemplateFromContent, templateNameField } from "../TemplateForm";
import TemplateFormButtons from "../TemplateFormButtons";

export const EditTemplateContent = () => {
  return (
    <>
      <Header mt={0} mb={40} title="Edit Importer" subTitle="" />
      <TemplateFromContent />
    </>
  );
};

type ModalProps = {
  row: any;
  isOpen: boolean;
  onSubmit?: (data: any) => void;
  getData: () => void;
  updateTemplate: (data: any) => void;
} & Omit<UseTemplateFormArgs, "onSubmit">;

export const EditModal = ({
  row,
  isOpen,
  hideModal,
  getData,
  updateTemplate,
}: ModalProps) => {
  const fields = [
    { ...templateNameField, initialValue: row.name },
    {
      label: "persistence",
      name: "persistence",
      initialValue: row.persistence,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(async (data, shouldRedirect = true) => {
    await updateTemplate({ ...row, ...data });
    await getData();
    hideModal();
  }, []);

  return (
    <S.Modal isOpen={isOpen} onClose={hideModal} minW={"0px"}>
      <Form fields={fields} onSubmit={onSubmit}>
        <S.ModalContent mr={0}>
          <EditTemplateContent />
          <TemplateFormButtons
            activeIndex={0}
            isLoading={isLoading}
            onCancel={hideModal}
          />
        </S.ModalContent>
      </Form>
    </S.Modal>
  );
};

import { createContext, useContext, useEffect } from "react";
import { useImporterContext } from "../../../../Importer/contexts/ImporterContextProvider";
import { useSearchContext } from "../SearchContextProvider";
import { useUndoRedoContext } from "../UndoRedoContextProvider";
import { fuseImporterId } from "fuse-importer";

export type State = {};

export const KeyboardEventContext = createContext<State>({} as State);

export const KeyboardEventContextProvider = ({ children }) => {
  const { layoutRef } = useImporterContext();
  const { undo, redo } = useUndoRedoContext();
  const { setFindAndReplaceModalOpen } = useSearchContext();
  const { openSearchInput } = useSearchContext();

  const onKeyDown = (e) => {
    // cmd+y
    if (e.keyCode === 89 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      redo();
    }

    // cmd+shift+z
    if (e.keyCode === 90 && (e.ctrlKey || e.metaKey) && e.shiftKey) {
      e.preventDefault();
      redo();
    }

    // cmd+z
    if (e.keyCode === 90 && (e.ctrlKey || e.metaKey) && !e.shiftKey) {
      e.preventDefault();
      undo();
    }

    // cmd+f
    if ((e.ctrlKey || e.metaKey) && !e.shiftKey && e.keyCode === 70) {
      e.preventDefault();
      openSearchInput();
    }

    // cmd+shift+f
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 70) {
      e.preventDefault();
      setFindAndReplaceModalOpen(true);
    }
  };

  useEffect(() => {
    const iframeElement = document.getElementById(
      fuseImporterId
    ) as HTMLIFrameElement;
    if (iframeElement?.contentDocument) {
      iframeElement.contentDocument.body.addEventListener("keydown", onKeyDown);
    } else {
      // this is used in testing
      layoutRef?.current?.addEventListener?.("keydown", onKeyDown);
    }

    return () => {
      if (iframeElement?.contentDocument) {
        iframeElement.contentDocument.body.removeEventListener(
          "keydown",
          onKeyDown
        );
      } else {
        layoutRef?.current?.removeEventListener?.("keydown", onKeyDown);
      }
    };
  }, [layoutRef.current]);

  const value = {};

  return (
    <KeyboardEventContext.Provider value={value}>
      {children}
    </KeyboardEventContext.Provider>
  );
};

export const useDataSetContext = () => useContext(KeyboardEventContext);

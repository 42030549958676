import { Div, colors } from "fuse-shared-ui";
import React from "react";
import styled from "styled-components";

interface ContentSectionContainerProps {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
}

const Container = styled(Div)`
  width: 100%;
  margin-top: 40px;
  padding: 40px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.red50};
  box-sizing: border-box;
  border-radius: 12px;
`;

export const ContentSectionContainer = ({
  children,
  title,
  subtitle,
}: ContentSectionContainerProps) => {
  return (
    <Container>
      {title && (
        <Div data-cy="section-title" h6>
          {title}
        </Div>
      )}
      {subtitle && (
        <Div data-cy="section-sub-title" wbody1 mt={8} color={colors.gray700}>
          {subtitle}
        </Div>
      )}

      {children}
    </Container>
  );
};

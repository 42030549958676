import { useEffect } from "react";
import styled from "styled-components";
import { Div } from "fuse-shared-ui";
import { identifyInspectlet } from "utils";
import { mailIcon } from "./icons";
import {
  flatironsFuseLogo,
  checkYourEmailBackgroundPart1,
  checkYourEmailBackgroundPart2,
  checkYourEmailBackgroundPart3,
} from "./assets/images";
import ConversionTags from "./ConversionTags";

const Wrapper = styled(Div)`
  width: 100vw;
  ${(props) => {
    const c = props.theme.css;
    return [c.grow, c.justifyCenter, c.yscroll, c.pRelative];
  }};
`;

const Container = styled(Div)`
  margin: 80px 160px;
  min-height: 740px;
  max-height: 740px;
  height: 100%;
  max-width: 1120px;
  width: 100%;
  position: relative;
  @media all and (max-width: 980px) {
    margin: 80px;
  }
  @media all and (max-width: 840px) {
    margin: 24px;
  }
`;

const ContentWrapper = styled(Div)`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 40px ${(props) => props.theme.colors.red2000};
  border-radius: 16px;
  position: relative;
  z-index: 10;
`;

const Content = styled(Div)`
  padding: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > svg:not(:first-child) {
    margin-top: 150px;
  }
  @media all and (max-width: 840px) {
    padding: 24px;
  }
`;

const LogoWrapper = styled(Div)`
  max-width: 220px;
  max-height: 32px;
`;

const TextWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextTitle = styled(Div)`
  color: ${(props) => props.theme.colors.black};
  ${(props) => props.theme.textStyles.wh1};
  @media all and (max-width: 720px) {
    text-align: center;
  }
`;

const TextSubtitle = styled(Div)`
  color: ${(props) => props.theme.colors.textSecondary};
  ${(props) => props.theme.textStyles.wbody1};
  display: flex;
  word-break: break-all;
  @media all and (max-width: 1120px) {
    flex-direction: column;
    text-align: center;
  }
  @media all and (max-width: 720px) {
    margin-top: 12px;
    justify-content: center;
    flex-wrap: wrap;
    white-space: nowrap;
  }
`;

const TextUserEmail = styled(Div)`
  text-align: center;
  white-space: normal;
  margin-left: 5px;
  ${(props) => props.theme.textStyles.wbody2};
  @media all and (max-width: 1120px) {
    margin-left: unset;
  }
`;

// const TextResendEmail = styled(Div)`
//   margin-top: 40px;
//   display: flex;
//   color: ${(props) => props.theme.colors.gray700};
//   ${(props) => props.theme.textStyles.wlink};
//   :hover {
//     cursor: text;
//     text-decoration-line: none;
//     opacity: unset;
//   }
//   @media all and (max-width: 720px) {
//     justify-content: center;
//     flex-wrap: wrap;
//     white-space: nowrap;
//   }
// `;

// const TextResendEmailLink = styled(Div)`
//   margin-left: 5px;
//   color: ${(props) => props.theme.colors.pink500};
//   ${(props) => props.theme.textStyles.wlink};
// `;

const BackgroundPart1 = styled(Div)`
  margin-top: -46px;
  margin-bottom: -33.58px;
  margin-left: -126px;
  max-width: 698px;
  min-width: 698px;
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  @media all and (max-width: 840px) {
    display: none;
  }
`;

const BackgroundPart2 = styled(Div)`
  margin-top: 371.19px;
  max-width: 534px;
  min-width: 534px;
  width: 100%;
  max-height: 373px;
  min-height: 373px;
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  @media all and (max-width: 840px) {
    display: none;
  }
`;

const BackgroundPart3 = styled(Div)`
  margin-top: 557px;
  margin-left: 262.88px;
  max-width: 907px;
  width: 100%;
  opacity: 80%;
  display: flex;
  position: absolute;
  z-index: 30;
  left: 0;
  top: 0;
  @media all and (max-width: 1240px) {
    display: none;
  }
`;

const backgroundImagesList = [
  <BackgroundPart1 key={1}>{checkYourEmailBackgroundPart1}</BackgroundPart1>,
  <BackgroundPart2 key={2}>{checkYourEmailBackgroundPart2}</BackgroundPart2>,
  <BackgroundPart3 key={3}>{checkYourEmailBackgroundPart3}</BackgroundPart3>,
];

type CheckYourEmailProps = {
  email: string;
};

const CheckYourEmail = ({ email }: CheckYourEmailProps) => {
  useEffect(() => {
    if (email) identifyInspectlet({ email });
  }, [email]);

  const textUI = (
    <TextWrapper>
      <TextTitle>Check your email!</TextTitle>
      <TextSubtitle>
        We’ve sent a confirmation link to<TextUserEmail>{email}</TextUserEmail>
      </TextSubtitle>
    </TextWrapper>
  );

  return (
    <Wrapper>
      <ConversionTags email={email} />
      <Container>
        {backgroundImagesList.map((item) => item)}
        <ContentWrapper>
          <Content>
            <LogoWrapper>{flatironsFuseLogo}</LogoWrapper>
            {mailIcon}
            {textUI}
          </Content>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default CheckYourEmail;

import React from "react";
import { areEqual } from "react-window";
import styled, { css } from "styled-components";
import { Div, DivCSSProps } from "../../../../styled/utils";
import { TableViewCellContainer, getColumnWidthsPx } from "../common";
import { TableViewProps } from "../types";

export const Cell = React.memo((props: any) => {
  const { children, ...cellProps } = props;
  return (
    <TableViewCellContainer data-cy="cell-column" {...cellProps}>
      {children}
    </TableViewCellContainer>
  );
}, areEqual);

type TableViewRowProps = {
  isHighlighted: boolean;
  isLast: boolean;
};
export const TableViewRow = styled(Div)<TableViewRowProps>`
  background-color: white;
  ${(p) =>
    !p.isLast &&
    css`
      box-shadow: inset 0 -1px 0 ${(p) => p.theme.colors.gray300};
    `}
  ${(p) =>
    p.isHighlighted &&
    css`
      background: ${p.theme.colors.blue100};
      box-shadow: inset 0 0 0 3px ${p.theme.colors.blue500};

      .input-content {
        background: transparent;
        border: none;
        box-shadow: none;

        &,
        input {
          color: ${p.theme.colors.gray1000};
        }
      }
    `}

  &:hover {
    .shouldBeVisibleOnHover {
      opacity: 1;
      transition: opacity 0.25s ease;
      visibility: visible;
    }
  }
`;

type Props = Pick<TableViewProps, "columns" | "onRowClick"> & {
  row: any;
  rowWidth: number;
  indexI: number;
  isLast?: boolean;
  getItemKey?: (id: string) => string;
  isRowSelected?: boolean;
  alignColumnsWithHeader?: boolean;
} & DivCSSProps;

export const TableRow = ({
  row,
  indexI,
  columns,
  isLast,
  onRowClick,
  getItemKey,
  rowWidth,
  isRowSelected = false,
  alignColumnsWithHeader,
  ...props
}: Props) => {
  const isHighlighted = isRowSelected;

  const key = getItemKey ? getItemKey(row) : `${indexI}`;
  return (
    <TableViewRow
      data-test-id={`row-${indexI}`}
      data-cy="rows"
      id={`row-id-${key}`}
      dflex
      {...{ isHighlighted, isLast }}
      {...props}
    >
      {columns.map((column, indexJ) => {
        const { Content } = column;
        const resolvedKey = `column-${indexJ}-row-${key}`;

        const columnWidthPercentages = getColumnWidthsPx(columns, rowWidth);
        const columnWidthPercentage = columnWidthPercentages[indexJ];

        const isLastColumn = indexJ === columns.length - 1;

        const optionalCellProps = {
          ...(alignColumnsWithHeader && {
            overflowHidden: true,
          }),
        };

        return (
          <Cell
            row={row}
            key={resolvedKey}
            onClick={
              onRowClick && !column.preventRowClick
                ? () => onRowClick(row)
                : null
            }
            isLast={isLastColumn}
            className={isLastColumn ? "last-column" : ""}
            w={columnWidthPercentage}
            {...optionalCellProps}
          >
            {Content ? (
              <Content
                {...row}
                columnWidth={columnWidthPercentage}
                colIndex={indexJ}
                rowIndex={indexI}
              />
            ) : (
              <Div truncateText>{row[column.dataAccessor] || "-"}</Div>
            )}
          </Cell>
        );
      })}
    </TableViewRow>
  );
};

import { EventEmitter } from "eventemitter3";
import React, { createContext, useContext, useRef } from "react";

const EVENTS = {
  ON_LAST_ROW_CHANGED: "ON_LAST_ROW_CHANGED",
  UPDATE_COLUMN_ERROR_COUNT: "UPDATE_COLUMN_ERROR_COUNT",
  UPDATE_TOTAL_ERROR_COUNT: "UPDATE_TOTAL_ERROR_COUNT",
  UPDATE_TOTAL_ROW_COUNT: "UPDATE_TOTAL_ROW_COUNT",
};

type CallbackType = EventEmitter.EventListener<
  EventEmitter.ValidEventTypes,
  any
>;

export type State = {
  on: (event: string, callback: CallbackType) => void;
  off: (event: string, callback: CallbackType) => void;
  emit: (event: string, value?: any) => void;
  emitter: React.MutableRefObject<EventEmitter>;
  EVENTS: typeof EVENTS;
};

export const EventManagerContext = createContext<State>({} as State);

export const EventManagerContextProvider = ({ children }) => {
  const emitter = useRef(new EventEmitter());

  const on = (event: string, callback: CallbackType) => {
    return emitter.current.on(event, callback);
  };

  const off = (event: string, callback: CallbackType) => {
    return emitter.current.off(event, callback);
  };

  const emit = (event: string, value: any = null) => {
    return emitter.current.emit(event, value);
  };

  const value = { on, off, emit, emitter, EVENTS };

  return (
    <EventManagerContext.Provider value={value}>
      {children}
    </EventManagerContext.Provider>
  );
};

export const useEventManagerContext = () => useContext(EventManagerContext);

import { TooltipProvider } from "fuse-shared-ui";
import { Div } from "fuse-shared-ui";
import { deleteIcon } from "../../../../../TeamMembers/common/icons";
import * as S from "../styles";

type TableDeleteIconProps = {
  onClick: () => void;
  isDisabled?: boolean;
};

const TableDeleteIcon = ({
  onClick,
  isDisabled = false,
}: TableDeleteIconProps) => {
  return (
    <Div justifyEnd alignCenter>
      <Div h={40}>
        <TooltipProvider
          isDisabled={isDisabled}
          tooltip="Delete"
          placement="left"
          flip={false}
          centered
        >
          <S.DeleteButton
            data-test-id="delete-button"
            onClick={onClick}
            disabled={isDisabled}
            centered
          >
            {deleteIcon}
          </S.DeleteButton>
        </TooltipProvider>
      </Div>
    </Div>
  );
};

export default TableDeleteIcon;

import { Div } from "fuse-shared-ui";
import styled, { css } from "styled-components";

type DragAndDropCardProps = {
  isDragActive: boolean;
};

type ContainerProps = {
  isDragActive: boolean;
  shouldHaveNegativeMargin?: boolean;
};

export const Container = styled(Div)<ContainerProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: ${({ isDragActive }) => (isDragActive ? "399px" : "auto")};
  margin: ${({ shouldHaveNegativeMargin }) =>
    shouldHaveNegativeMargin && "auto -40px -47px -40px"};
`;

export const DragAndDropCard = styled(Div)<DragAndDropCardProps>`
  border-radius: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  background: #ffffff;

  @media (max-width: 1050px) {
    padding: 24px;
    width: auto;
  }

  ${(props) =>
    props.isDragActive &&
    css`
      background: ${(p) => p.theme.colors.blue100};
      border: 1px solid ${(p) => p.theme.colors.blue500};
      justify-content: center;
    `}
`;

export const DropzoneUploadingContainer = styled(Div)(
  ({ theme: { colors } }) => css`
    align-items: flex-start;
    background: ${colors.blue100};
    border-radius: 16px;
    border: 1px solid ${colors.blue500};
    display: flex;
    height: 399px;
    position: absolute;
    width: 100%;

    > div:first-of-type {
      padding-top: 60px;
    }
  `
);

export const DropzoneLoadingContainer = styled(Div)`
  width: 559px;
`;

export const SpinnerContainer = styled(Div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Spinner = styled.div`
  --_m: conic-gradient(#0000, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask-composite: source-out;
  -webkit-mask: var(--_m);
  animation: load 1.2s linear infinite;
  aspect-ratio: 1;
  background: #0ebebe;
  border-radius: 50%;
  box-sizing: border-box;
  mask-composite: subtract;
  mask: var(--_m);
  padding: 17px;
  width: 169px;

  @keyframes load {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const SpinnerText = styled(Div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SkeletonContainer = styled(Div)`
  gap: 13px;
`;

export const BottomSkeletons = styled(Div)`
  gap: 9px;
`;

import { Route, Switch } from "react-router";
import { ACCOUNT_TEMPLATES_ROUTE } from "../common";
import Templates from "./Templates/";
import TemplateColumns from "./TemplateColumns";

export const TEMPLATE_ROUTES = {
  TEMPLATES: "/",
  TEMPLATE_COLUMNS: "/:slug/columns",
};
const p = (path: string) => `${ACCOUNT_TEMPLATES_ROUTE}${path}`;

export const TEMPLATES_ROUTE = p(TEMPLATE_ROUTES.TEMPLATES);
export const TEMPLATE_COLUMNS_ROUTE = p(TEMPLATE_ROUTES.TEMPLATE_COLUMNS);

const TemplateRoutes = (): JSX.Element => (
  <Switch>
    <Route exact path={TEMPLATES_ROUTE}>
      <Templates />
    </Route>
    <Route exact path={TEMPLATE_COLUMNS_ROUTE}>
      <TemplateColumns />
    </Route>
  </Switch>
);

export default TemplateRoutes;

import { useEffect, useState } from "react";
import { useSpreadsheetContext } from ".";
import { Field } from "fuse-importer";
import { useImporterContext } from "../../../contexts/ImporterContextProvider";
import { useDataSetContext } from "../DataSetContextProvider";
import { useFilteredDataContext } from "../FilteredDataContextProvider";
import { useValidationContext } from "../ValidationContextProvider";
import { parseValue } from "../common";
import { useDataSetManipulation } from "./useDataSetManipulation";
import { fuseImporterId } from "fuse-importer";

export type SelectedCellRect = {
  start: { rowIndex: number; colIndex: number };
  end: { rowIndex: number; colIndex: number };
  initialCell: { rowIndex: number; colIndex: number };
  isDashed?: boolean;
  editMode?: boolean;
};

export const defaultSelectionRect: SelectedCellRect = {
  start: { rowIndex: 0, colIndex: 0 },
  end: { rowIndex: 0, colIndex: 0 },
  initialCell: { rowIndex: 0, colIndex: 0 },
};

const getIframeBody = (layoutRef: React.RefObject<HTMLDivElement>) => {
  const iframeElement = document.getElementById(
    fuseImporterId
  ) as HTMLIFrameElement;

  const isCypressTestingEnv = !iframeElement?.contentDocument;
  if (isCypressTestingEnv) return layoutRef.current;

  return iframeElement?.contentDocument?.body;
};

export type CellSelectionState = {
  selectedCellRect: SelectedCellRect;
  setSelectedCellRect: React.Dispatch<React.SetStateAction<SelectedCellRect>>;
};
export const useCellSelection = (fields?: Field[]): CellSelectionState => {
  const { getRecord } = useDataSetContext();
  const { filteredDataIds, isSortingOrFiltering } = useFilteredDataContext();
  const { updateRecordAndRevalidate } = useValidationContext();
  const { layoutRef } = useImporterContext();
  const [selectedCellRect, setSelectedCellRect] = useState<SelectedCellRect>(
    defaultSelectionRect
  );
  const { enumFieldValueMatchings } = useImporterContext();
  const { isReadOnly } = useSpreadsheetContext();
  const {
    insertRecordsIntoSpreadsheet,
    addNewRecord,
  } = useDataSetManipulation();

  useEffect(() => {
    const iframeBody = getIframeBody(layoutRef);
    iframeBody.addEventListener("paste", onPaste, true);

    return () => {
      iframeBody.removeEventListener("paste", onPaste, true);
    };
  }, [
    selectedCellRect,
    filteredDataIds,
    isSortingOrFiltering,
    enumFieldValueMatchings,
    insertRecordsIntoSpreadsheet,
    updateRecordAndRevalidate,
    fields,
  ]);

  useEffect(() => {
    const handleCopy = () => handleCopyCutOperation(false);
    const handleCut = () => handleCopyCutOperation(true);

    const iframeBody = getIframeBody(layoutRef);
    iframeBody?.addEventListener("copy", handleCopy);
    iframeBody?.addEventListener("cut", handleCut);

    return () => {
      iframeBody?.removeEventListener("copy", handleCopy);
      iframeBody?.removeEventListener("cut", handleCut);
    };
  }, [selectedCellRect]);

  const onPaste = async (event) => {
    if (isReadOnly) return;
    const text = event.clipboardData.getData("text");
    if (!text.includes("\t") && !text.includes("\n")) {
      return;
    }

    const rows = text.split(/\r\n|\n|\r/);
    const maxCells = rows.reduce(
      (max, row) => Math.max(max, row.split("\t").length),
      0
    );

    const structuredData = rows.map((row) => {
      const cells = row.split("\t");
      while (cells.length < maxCells) {
        cells.push("");
      }
      return cells;
    });

    const startRowIndex = selectedCellRect.start.rowIndex;
    const startColIndex = selectedCellRect.start.colIndex;

    const pastedRecordIds = filteredDataIds.slice(
      startRowIndex,
      startRowIndex + structuredData.length
    );
    if (!isSortingOrFiltering) {
      const isPastingInLastRow =
        startRowIndex + structuredData.length >= filteredDataIds.length;
      while (
        pastedRecordIds.length < structuredData.length ||
        (isPastingInLastRow && pastedRecordIds.length === structuredData.length)
      ) {
        const newRecordId = addNewRecord();
        pastedRecordIds.push(newRecordId);
      }
    }

    const pastedRecordValues = pastedRecordIds.map((id) => ({
      ...getRecord(id),
    }));
    structuredData.forEach((row, startRowIndex) => {
      row.forEach((value, colIndex) => {
        const field = fields[startColIndex + colIndex];
        if (!field) return;
        const pastedRecordValue = pastedRecordValues[startRowIndex];
        if (pastedRecordValue) {
          const enumSet =
            Object.keys(enumFieldValueMatchings).length > 0
              ? enumFieldValueMatchings[field.label]
              : field.options.reduce((acc, option) => {
                  acc[option] = option;
                  return acc;
                }, {});
          pastedRecordValue[field.name] = parseValue(
            field.type,
            value,
            enumSet
          );
        }
      });
    });

    if (!isSortingOrFiltering) {
      await insertRecordsIntoSpreadsheet(pastedRecordValues);
    } else {
      await updateRecordAndRevalidate(pastedRecordValues);
    }
    setSelectedCellRect({
      start: { rowIndex: startRowIndex, colIndex: startColIndex },
      end: {
        rowIndex: startRowIndex + structuredData.length - 1,
        colIndex: startColIndex + maxCells - 1,
      },
      initialCell: { rowIndex: startRowIndex, colIndex: startColIndex },
    });
  };

  const handleCopyCutOperation = (isCut: boolean) => {
    const { start, end } = selectedCellRect;
    const selectedCellsCount =
      (end.rowIndex - start.rowIndex + 1) * (end.colIndex - start.colIndex + 1);
    if (selectedCellsCount <= 1) return;

    const updatedRecords = [];
    const data = [];
    for (let i = start.rowIndex; i <= end.rowIndex; i++) {
      const rowData = [];
      for (let j = start.colIndex; j <= end.colIndex; j++) {
        const recordId = filteredDataIds[i];
        const record = getRecord(recordId);
        const cellData = record[fields[j].name];
        rowData.push(cellData);
        if (isCut) {
          record[fields[j].name] = "";
          updatedRecords.push(record);
        }
      }
      data.push(rowData);
    }

    if (isCut) updateRecordAndRevalidate(updatedRecords);
    const clipboardText = data.map((row) => row.join("\t")).join("\n");
    navigator.clipboard.writeText(clipboardText);
    setSelectedCellRect({ ...selectedCellRect, isDashed: true });
  };

  useEffect(() => {
    setSelectedCellRect(defaultSelectionRect);
  }, [filteredDataIds]);

  return {
    selectedCellRect,
    setSelectedCellRect,
  };
};

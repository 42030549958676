import { TooltipProvider as BaseProvider, Div } from "fuse-shared-ui";
import { useHistory } from "react-router";
import styled from "styled-components";
import { BILLING_ROUTE } from "../common";
import { ArrowGoingUp } from "./icons";

const TooltipProvider = styled(BaseProvider)`
  height: auto;
  width: fit-content;
`;

const UpgradePillContainer = styled(Div)`
  background-color: ${({ theme }) => theme.colors.blue100};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.blue900};
  cursor: pointer;
  display: flex;
  gap: 8px;
  padding: 6px 8px;
  user-select: none;
  ${({ theme }) => theme.textStyles.label3}
`;

export const UpgradeTooltip = () => {
  const history = useHistory();

  const tooltip = (
    <Div textAlignCenter>
      Upgrade plan to use this function. <br />
      Click here to obtain more information
    </Div>
  );

  const handleUpgradeClick = () => {
    history.push(BILLING_ROUTE);
  };

  return (
    <TooltipProvider
      tooltip={tooltip}
      placement="bottom"
      flip={false}
      centered
      data-testid="upgrade-plan-tooltip"
    >
      <UpgradePillContainer onClick={handleUpgradeClick}>
        Upgrade
        <ArrowGoingUp />
      </UpgradePillContainer>
    </TooltipProvider>
  );
};

import { useEffect, useRef, useState } from "react";

export const useDebounce = (value, onChange, timeoutMs = 300) => {
  const [_value, set_Value] = useState(value);
  const tid = useRef(null);

  useEffect(() => {
    if (_value !== value) {
      clearTimeout(tid.current);
      tid.current = setTimeout(() => onChange(_value), timeoutMs);
    }
  }, [_value]);

  useEffect(() => {
    set_Value(value);
  }, [value]);

  return { _value, set_Value };
};

import { useUserContext } from "../../../../../../UserContextProvider";
import { useNavigationContext } from "../../NavigationContextProvider";
import { useDisplayText } from "../common";

export const useUserMenu = () => {
  const { user } = useUserContext();

  const { first_name, last_name, active_organization } = user;
  const organizationName = active_organization?.name;

  const { isOpen } = useNavigationContext();
  const { displayText } = useDisplayText();

  return {
    firstName: first_name,
    lastName: last_name,
    organizationName,
    isOpen,
    displayText,
  };
};

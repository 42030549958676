export const flatironsFuseLogo = (
  <svg
    width="220"
    height="32"
    viewBox="0 0 220 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.96044 28.355L19.3937 28.355C19.5463 28.355 19.6898 28.2803 19.7803 28.1537L28.2598 16.2897C28.3833 16.117 28.3845 15.882 28.2628 15.708L19.827 3.64491L3.76198 27.9689C3.65363 28.133 3.76776 28.355 3.96044 28.355Z"
      fill="#FD395E"
    />
    <path
      d="M3.76198 4.03111L11.667 16L19.827 3.64507L3.96044 3.64507C3.76775 3.64507 3.65363 3.86706 3.76198 4.03111Z"
      fill="url(#paint0_linear_1502_38864)"
    />
    <path
      d="M15.0273 3.64506L11.6673 16L19.8273 3.64506L15.0273 3.64506Z"
      fill="#8A0E25"
    />
    <path
      d="M39.3532 22.7756H42.8606V17.3201H47.9331V14.7574H42.8606V11.7869H48.1593V9.22419H39.3532V22.7756Z"
      fill="black"
    />
    <path
      d="M52.1884 22.7756H61.1265V20.0964H55.6957V9.22419H52.1884V22.7756Z"
      fill="black"
    />
    <path
      d="M63.8544 22.7756H67.4183L68.4554 19.8828H73.3959L74.3765 22.7756H78.2233L73.1508 9.22419H68.8514L63.8544 22.7756ZM72.5662 17.3201H69.2663L70.9634 12.0199H71.0011L72.5662 17.3201Z"
      fill="black"
    />
    <path
      d="M82.8424 22.7756H86.3498V11.9034H89.5932V9.22419H79.5991V11.9034H82.8424V22.7756Z"
      fill="black"
    />
    <path d="M93.4902 22.7756H96.9976V9.22419H93.4902V22.7756Z" fill="black" />
    <path
      d="M101.787 22.7756H105.294V17.4172H106.03C107.331 17.4172 107.783 17.9996 108.5 20.3294L109.254 22.7756H112.912L111.63 19.0868C110.97 17.4366 110.895 16.4076 109.763 16.097V16.0581C111.46 15.6504 112.29 14.4467 112.29 12.6412C112.29 10.3114 110.386 9.22419 108.047 9.22419H101.787V22.7756ZM105.294 11.7869H106.218C107.557 11.7869 108.783 12.0199 108.783 13.2818C108.783 14.8156 107.35 14.8544 106.218 14.8544H105.294V11.7869Z"
      fill="black"
    />
    <path
      d="M116.231 15.9999C116.231 20.31 118.532 23.0086 122.831 23.0086C127.13 23.0086 129.431 20.31 129.431 15.9999C129.431 11.651 127.168 8.99121 122.831 8.99121C118.494 8.99121 116.231 11.651 116.231 15.9999ZM119.852 15.9999C119.852 13.6507 120.7 11.6704 122.831 11.6704C124.962 11.6704 125.81 13.6507 125.81 15.9999C125.81 18.2326 124.962 20.3294 122.831 20.3294C120.7 20.3294 119.852 18.2326 119.852 15.9999Z"
      fill="black"
    />
    <path
      d="M133.717 22.7756H136.942L136.829 13.5148H136.866L141.468 22.7756H145.823V9.22419H142.618L142.674 18.4267H142.637L138.186 9.22419H133.717V22.7756Z"
      fill="black"
    />
    <path
      d="M150.336 22.4262C151.505 22.8144 153.033 23.0086 154.258 23.0086C157.181 23.0086 160.047 22.0573 160.047 18.5432C160.047 13.6507 153.825 15.1262 153.825 12.9324C153.825 11.7481 155.296 11.6704 156.182 11.6704C157.181 11.6704 158.2 11.8646 159.105 12.2917L159.331 9.49599C158.445 9.18536 157.2 8.99121 155.937 8.99121C153.297 8.99121 150.204 9.88428 150.204 13.1459C150.204 18.0773 156.427 16.4853 156.427 19.0092C156.427 20.0964 155.371 20.3294 154.258 20.3294C152.806 20.3294 151.543 19.9411 150.638 19.4363L150.336 22.4262Z"
      fill="black"
    />
    <path
      d="M164.392 22.7756H167.9V17.3201H172.972V14.7574H167.9V11.7869H173.198V9.22419H164.392V22.7756Z"
      fill="#FD395E"
    />
    <path
      d="M188.579 9.22419H185.072V17.0289C185.072 18.815 184.6 20.3294 182.809 20.3294C181.018 20.3294 180.546 18.815 180.546 17.0289V9.22419H177.039V17.6307C177.039 21.3389 179.377 23.0086 182.809 23.0086C186.241 23.0086 188.579 21.3389 188.579 17.6307V9.22419Z"
      fill="#FD395E"
    />
    <path
      d="M192.835 22.4262C194.004 22.8144 195.531 23.0086 196.757 23.0086C199.68 23.0086 202.546 22.0573 202.546 18.5432C202.546 13.6507 196.323 15.1262 196.323 12.9324C196.323 11.7481 197.794 11.6704 198.68 11.6704C199.68 11.6704 200.698 11.8646 201.603 12.2917L201.829 9.49599C200.943 9.18536 199.699 8.99121 198.435 8.99121C195.795 8.99121 192.703 9.88428 192.703 13.1459C192.703 18.0773 198.926 16.4853 198.926 19.0092C198.926 20.0964 197.87 20.3294 196.757 20.3294C195.305 20.3294 194.042 19.9411 193.137 19.4363L192.835 22.4262Z"
      fill="#FD395E"
    />
    <path
      d="M206.795 22.7756H216.28V20.2129H210.302V17.1454H215.752V14.5826H210.302V11.7869H216.073V9.22419H206.795V22.7756Z"
      fill="#FD395E"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1502_38864"
        x1="1.81659"
        y1="3.64507"
        x2="20.2841"
        y2="9.7137"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.03125" stopColor="#CA405A" stopOpacity="0.958429" />
        <stop offset="1" stopColor="#A2243B" />
      </linearGradient>
    </defs>
  </svg>
);

export const background1 = (
  <svg
    width="239"
    height="339"
    viewBox="0 0 239 339"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "darken" }}>
      <path
        d="M169.62 2.42974e-06L0.000129287 169.62L169.212 338.832C214.218 293.793 239.157 232.387 238.546 168.117C237.935 103.847 215.489 45.8694 169.62 2.42974e-06Z"
        fill="url(#paint0_linear_1507_39164)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1507_39164"
        x1="296.645"
        y1="211.399"
        x2="-128.503"
        y2="-77.3824"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8CFFDD" />
        <stop offset="1" stopColor="#02FFF0" stopOpacity="0.760417" />
      </linearGradient>
    </defs>
  </svg>
);

export const circle1 = (
  <svg
    width="129"
    height="129"
    viewBox="0 0 129 129"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "overlay" }}>
      <circle
        cx="64.8903"
        cy="64.6833"
        r="63.8971"
        fill="url(#paint0_linear_1507_39161)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1507_39161"
        x1="-8.2817"
        y1="107.455"
        x2="190.263"
        y2="34.9839"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const circle2 = (
  <svg
    width="195"
    height="195"
    viewBox="0 0 195 195"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M194.547 97.7861C194.547 151.43 151.06 194.917 97.416 194.917C43.7721 194.917 0.285156 151.43 0.285156 97.7861C0.285156 44.1422 43.7721 0.655273 97.416 0.655273C151.06 0.655273 194.547 44.1422 194.547 97.7861Z"
        fill="url(#paint0_linear_1507_39160)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1507_39160"
        x1="-13.8137"
        y1="162.805"
        x2="287.997"
        y2="52.6397"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const elipse1 = (
  <svg
    width="239"
    height="339"
    viewBox="0 0 239 339"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "darken" }}>
      <path
        d="M69.2124 338.832L238.832 169.213L69.6195 8.14626e-05C24.6141 45.0389 -0.325131 106.445 0.285702 170.715C0.896535 234.985 23.343 292.963 69.2124 338.832Z"
        fill="url(#paint0_linear_1507_39157)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1507_39157"
        x1="-79.6862"
        y1="34.4947"
        x2="155.708"
        y2="350.865"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6BEEFF" />
        <stop offset="1" stopColor="#0DDFD3" stopOpacity="0.760417" />
      </linearGradient>
    </defs>
  </svg>
);

export const elipse2 = (
  <svg
    width="239"
    height="339"
    viewBox="0 0 239 339"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M169.677 338.687L0.415626 169.426L169.27 0.571057C214.181 45.5147 239.067 106.791 238.458 170.925C237.848 235.059 215.449 292.914 169.677 338.687Z"
        fill="url(#paint0_linear_1507_39158)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1507_39158"
        x1="265.52"
        y1="92.8979"
        x2="115.69"
        y2="338.561"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#006BA8" />
        <stop offset="1" stopColor="#00D5B3" stopOpacity="0.895833" />
      </linearGradient>
    </defs>
  </svg>
);

export const rectangle1 = (
  <svg
    width="274"
    height="588"
    viewBox="0 0 274 588"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "darken" }}>
      <path
        d="M9.58867 265.661C-1.87745 277.148 -2.12983 295.67 9.01908 307.464L274 587.786V0.786133L9.58867 265.661Z"
        fill="url(#paint0_linear_1507_39159)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1507_39159"
        x1="59.8839"
        y1="0.786164"
        x2="477.383"
        y2="1089.14"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8CFFDD" />
        <stop offset="1" stopColor="#02FFF0" stopOpacity="0.760417" />
      </linearGradient>
    </defs>
  </svg>
);

export const checkYourEmailBackgroundPart1 = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 698 823"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "darken" }}>
      <path
        d="M0 411.309C0 184.149 184.149 0 411.309 0H642.664C651.557 0 657.305 9.43391 653.195 17.3199C596.218 126.641 295.772 716.434 445.679 716.434C610.898 716.434 698 822.617 698 822.617H411.309C184.149 822.617 0 638.468 0 411.309Z"
        fill="url(#paint0_linear_1507_39299)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1507_39299"
        x1="331.147"
        y1="-2.62752e-05"
        x2="46.3445"
        y2="1295.79"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6BEEFF" />
        <stop offset="1" stopColor="#0DDFD3" stopOpacity="0.760417" />
      </linearGradient>
    </defs>
  </svg>
);

export const checkYourEmailBackgroundPart2 = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 534 373"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "darken" }}>
      <path
        d="M533.128 369.346C471.759 399.456 394.729 414.557 325.676 415.604C256.622 416.65 188.25 402.503 125.902 374.267C63.5547 346.032 8.96475 305.32 -33.7264 252.84C-75.9872 200.889 -105.882 140.898 -119.943 76.6275L-0.43692 0.194824C-0.43692 89.2875 40.0877 138.907 70.1586 175.313C100.229 211.719 138.751 241.022 182.711 260.93C296.962 312.671 425.693 281.388 533.128 369.346Z"
        fill="url(#paint0_linear_1507_39302)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1507_39302"
        x1="-835.297"
        y1="-257.203"
        x2="-720.297"
        y2="1342.67"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8CFFDD" />
        <stop offset="1" stopColor="#02FFF0" stopOpacity="0.760417" />
      </linearGradient>
    </defs>
  </svg>
);

export const checkYourEmailBackgroundPart3 = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 907 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M906.476 219.617L0.715193 219.617L881.698 1.47779C894.311 -1.64533 906.507 7.90038 906.505 20.8944L906.476 219.617Z"
        fill="url(#paint0_linear_1507_39303)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1507_39303"
        x1="868.171"
        y1="304.186"
        x2="224.844"
        y2="-371.507"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#006BA8" />
        <stop offset="1" stopColor="#00D5B3" stopOpacity="0.895833" />
      </linearGradient>
    </defs>
  </svg>
);

import {
  MutableRefObject,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SpreadsheetOptions } from "fuse-importer";
import { useAppLoadingContext } from "../../../../common/AppLoadingContextProvider";
import { useEventManagerContext } from "../../../../common/EventManagerContextProvider";
import {
  BatchValidationErrors,
  CalculateColumnCount,
  ColumnWithCount,
  Field,
  OnValidateRecord,
  Record,
  SpreadsheetValidationErrors,
  ValidationErrors,
} from "fuse-importer";
import { useImporterContext } from "../../../contexts/ImporterContextProvider";
import { useDataSetContext } from "../DataSetContextProvider";
import { useDuplicateDataContext } from "../DuplicateDataContextProvider";
import { useBatchProcessing } from "../SpreadsheetContextProvider/useBatchProcessing";
import { selectValidatorsFor, validateRecord } from "../validation";
import { useUndoRedoContext } from "../UndoRedoContextProvider";

type UseValidationStateArgs = {
  onValidateRecord?: OnValidateRecord;
  fields: Field[];
  options: SpreadsheetOptions;
  isReadOnly: boolean;
  children: any;
};

export type ValidationState = {
  isValid: boolean;
  isValidationCompleted: boolean;
  validationPercentage: number;
  errors: MutableRefObject<BatchValidationErrors>;
  warnings: MutableRefObject<BatchValidationErrors>;
  totalErrorCount: MutableRefObject<number>;
  invalidRowsCountRef: MutableRefObject<number>;
  columnsWithErrorCounts: MutableRefObject<ColumnWithCount>;
  columnsWithWarningCounts: MutableRefObject<ColumnWithCount>;
  calculateColumnErrorCounts?: CalculateColumnCount;
  calculateColumnWarningCounts?: CalculateColumnCount;
};

type UseValidationStateReturnValue = ValidationState & {
  updateRecordAndRevalidate: (
    record: Record | Record[],
    initialValidation?: boolean,
    loadingIndicator?: boolean
  ) => Promise<void>;
  spreadsheetInitialized: boolean;
};

// this is the fastest check for empty object as per
// https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object/785768#785768
const isObjectEmpty = (obj) => {
  for (var i in obj) {
    return false;
  }
  return true;
};

const checkIfHasErrors = (_errors: ValidationErrors) => {
  return _errors && !isObjectEmpty(_errors);
};

const calculateFieldCountChangeInRecord = (
  prevCountsInRecord = {},
  currCountsInRecord = {},
  globalFieldCounts,
  totalGlobalFieldCount = null
) => {
  const allFieldsInRecord = new Set([
    ...Object.keys(prevCountsInRecord),
    ...Object.keys(currCountsInRecord),
  ]);

  allFieldsInRecord.forEach((fieldName) => {
    const countChangeInRecord =
      (currCountsInRecord[fieldName] ? 1 : 0) -
      (prevCountsInRecord[fieldName] ? 1 : 0);
    if (!countChangeInRecord) return;

    globalFieldCounts.current = {
      ...globalFieldCounts.current,
      [fieldName]:
        (globalFieldCounts.current[fieldName] || 0) + countChangeInRecord,
    };
    if (totalGlobalFieldCount)
      totalGlobalFieldCount.current += countChangeInRecord;
  });
};

export const ValidationContext = createContext<UseValidationStateReturnValue>(
  {} as UseValidationStateReturnValue
);

export const ValidationContextProvider = ({
  fields,
  isReadOnly,
  onValidateRecord,
  children,
}: UseValidationStateArgs) => {
  const errors = useRef<SpreadsheetValidationErrors>({});
  const warnings = useRef<SpreadsheetValidationErrors>({});
  const [validationPercentage, setValidationPercentage] = useState(0);
  const validatorsCache = useMemo(() => selectValidatorsFor(fields), [fields]);
  const { setIsLoadingApp } = useAppLoadingContext();
  const columnsWithErrorCounts = useRef<ColumnWithCount>({});
  const columnsWithWarningCounts = useRef<ColumnWithCount>({});
  const totalErrorCount = useRef(0);
  const invalidRowsCountRef = useRef(0);
  const { emit, EVENTS } = useEventManagerContext();
  const { getRecord, setRecord, dataSet, dataSetLength } = useDataSetContext();
  const {
    duplicateValues,
    updateDuplicateValues,
    resetDuplicateProvider,
  } = useDuplicateDataContext();
  const { processRecordsInBatches } = useBatchProcessing(10000);
  const [spreadsheetInitialized, setSpreadsheetInitialized] = useState(false);
  const { currentStepIndex, templateSlug } = useImporterContext();
  const [dataSetChanged, setDataSetChanged] = useState(false);
  const { trackRecordChanges, undoTrackingEnabled } = useUndoRedoContext();

  const reset = () => {
    columnsWithErrorCounts.current = {};
    columnsWithWarningCounts.current = {};
    totalErrorCount.current = 0;
    invalidRowsCountRef.current = 0;
    errors.current = {};
    warnings.current = {};
    setSpreadsheetInitialized(false);
    setDataSetChanged((prev) => !prev);
  };

  const calculateColumnErrorCounts = (oldErrors = {}, newErrors = {}) => {
    if (
      Object.keys(oldErrors).length === 0 &&
      Object.keys(newErrors).length > 0
    )
      invalidRowsCountRef.current += 1;
    if (
      Object.keys(oldErrors).length > 0 &&
      Object.keys(newErrors).length === 0
    )
      invalidRowsCountRef.current -= 1;
    calculateFieldCountChangeInRecord(
      oldErrors,
      newErrors,
      columnsWithErrorCounts,
      totalErrorCount
    );
  };

  const calculateColumnWarningCounts = (oldWarnings = {}, newWarnings = {}) => {
    calculateFieldCountChangeInRecord(
      oldWarnings,
      newWarnings,
      columnsWithWarningCounts
    );
  };

  const batchValidationDelayMs = 100;
  const sleep = (n) => new Promise((resolve) => setTimeout(resolve, n));

  const getBackendErrors = (record: Record) => {
    const recordBackendErrors = record?._meta?.backendErrors;
    return recordBackendErrors || null;
  };

  const getBackendWarnings = (record: Record) => {
    const recordBackendWarnings = record?._meta?.backendWarnings;
    return recordBackendWarnings || null;
  };

  const _validateRecord = async (record, initialValidation = false) => {
    if (!record) return;

    const recordId: string = record._meta.id;
    const oldRecord = getRecord(recordId);

    updateDuplicateValues(
      oldRecord,
      record,
      initialValidation,
      updateRecordAndRevalidate
    );

    const newRecord = setRecord(recordId, { ...record });
    const backendErrors = getBackendErrors(record);
    const backendWarnings = getBackendWarnings(record);

    const {
      errors: recordErrors,
      warnings: recordWarnings,
    } = await validateRecord({
      validatorsCache,
      onValidateRecord,
      fields,
      record: newRecord,
      duplicateValues,
      backendErrors,
      backendWarnings,
    });

    calculateColumnErrorCounts(errors.current[recordId], recordErrors);
    calculateColumnWarningCounts(warnings.current[recordId], recordWarnings);

    const hasError = checkIfHasErrors(recordErrors);
    const hasWarning = checkIfHasErrors(recordWarnings);

    newRecord._meta.isInvalid = hasError;
    newRecord._meta.hasWarning = hasWarning;

    if (hasError) {
      errors.current[recordId] = recordErrors;
    } else {
      delete errors.current[recordId];
    }

    if (hasWarning) {
      warnings.current[recordId] = recordWarnings;
    } else {
      delete warnings.current[recordId];
    }

    if (backendErrors && spreadsheetInitialized) {
      record._meta.backendErrors = {};
    }

    if (backendWarnings && spreadsheetInitialized) {
      record._meta.backendWarnings = {};
    }

    emit(recordId, newRecord);
  };

  const updateValidationPercentage = (recordsValidated, totalRecords) => {
    const validationPercentage =
      (Math.min(recordsValidated, totalRecords) / totalRecords) * 100;

    setValidationPercentage(
      Math.round((validationPercentage + Number.EPSILON) * 10) / 10
    );
  };

  // Validate record and update dataSet
  const updateRecordAndRevalidate = async (
    records: Record | Record[],
    initialValidation = false,
    loadingIndicator = true
  ) => {
    const recordsArray = Array.isArray(records) ? records : [records];

    trackRecordChanges(
      recordsArray,
      updateRecordAndRevalidate,
      updateRecordAndRevalidate
    );

    const length = recordsArray.length;

    if (loadingIndicator && length > 10000) {
      setIsLoadingApp(true);
      // wait for the first render to complete
      // before blocking main thread with review step logic
      await sleep(200);
    }

    let recordsProcessed = 0;
    await processRecordsInBatches(recordsArray, async (batch: Record[]) => {
      for (let i = 0; i < batch.length; i++) {
        const record = batch[i];
        await _validateRecord(record, initialValidation);
        recordsProcessed += 1;
      }

      updateValidationPercentage(recordsProcessed, length);
      emit(EVENTS.UPDATE_COLUMN_ERROR_COUNT);
      emit(EVENTS.UPDATE_TOTAL_ERROR_COUNT);
      emit(EVENTS.UPDATE_TOTAL_ROW_COUNT);
      await sleep(batchValidationDelayMs);
    });

    if (loadingIndicator) {
      setIsLoadingApp(false);
    }
  };

  // Initial data validation and update dataSet
  useEffect(() => {
    if (dataSet.current && !isReadOnly) {
      resetDuplicateProvider();
    }
    reset();
  }, [dataSet.current]);

  useEffect(() => {
    const isDataSetInvalid = !dataSet.current || dataSetLength.current === 0;
    if (
      isDataSetInvalid ||
      isReadOnly ||
      currentStepIndex !== 2 ||
      fields?.length === 0
    )
      return;

    const initialValidation = async () => {
      const initialData = Object.values(dataSet.current);
      if (initialData.length > 0) {
        setIsLoadingApp(true);
        undoTrackingEnabled.current = false;
        await updateRecordAndRevalidate(initialData, true);
        undoTrackingEnabled.current = true;
      }
    };

    (async () => {
      await initialValidation();
      setIsLoadingApp(false);
      setSpreadsheetInitialized(true);
    })();
  }, [currentStepIndex, dataSetChanged]);

  const isValidationCompleted = validationPercentage === 100;
  const isValid = isValidationCompleted && totalErrorCount.current === 0;

  const value = {
    errors,
    warnings,
    totalErrorCount,
    invalidRowsCountRef,
    columnsWithErrorCounts,
    columnsWithWarningCounts,
    calculateColumnErrorCounts,
    calculateColumnWarningCounts,
    validationPercentage,
    isValidationCompleted,
    isValid,
    updateRecordAndRevalidate,
    spreadsheetInitialized,
  };

  return (
    <ValidationContext.Provider value={value}>
      {children}
    </ValidationContext.Provider>
  );
};

export const useValidationContext = () => useContext(ValidationContext);

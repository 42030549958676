import { useDropzone } from "react-dropzone";
import { addToast } from "../Toast";
import Papa from "papaparse";
import * as XLSX from "xlsx";

export interface DropzoneProps {
  onComplete: (results: any) => void;
  saveFileName: (fileName: string) => void;
  setIsLoading?: React.Dispatch<boolean>;
}

interface UseDragAndDropProps extends DropzoneProps {
  validHeadersCount?: (headersLength: number) => boolean;
}

export const useDragAndDrop = ({
  onComplete,
  saveFileName,
  setIsLoading,
  validHeadersCount,
}: UseDragAndDropProps) => {
  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (file.size <= 0) {
        addToast("Cannot upload an empty file.", "error");
        return;
      }

      setIsLoading && setIsLoading(true);
      saveFileName(file.name);
      const reader = new FileReader();
      const isCsvFile = file.type === "text/csv";

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => {
        addToast("An error occurred while reading the file.", "error");
        setIsLoading && setIsLoading(false);
      };

      reader.onload = (event) => {
        const results = event.target.result;
        if (isCsvFile) {
          Papa.parse(results, {
            worker: true,
            complete: (parserResults) => {
              onComplete(parserResults.data);
              setIsLoading && setIsLoading(false);
            },
            error: (error) => {
              addToast(
                `An error occurred while parsing the CSV: ${error.message}`,
                "error"
              );
              setIsLoading && setIsLoading(false);
            },
          });
          return;
        }

        const arr = new Uint8Array(event.target.result as ArrayBuffer).subarray(
          0,
          4
        );
        let header = "";
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        // Check the file signature
        const isExcelFile = header === "504b34" || header === "d0cf11e0"; // .xlsx or .xls
        if (!isExcelFile) {
          addToast("The file is not a valid Excel file.", "error");
          setIsLoading && setIsLoading(false);
          return;
        }

        try {
          const getDataFromFile = (fileResults) => {
            // rounded value to 20MB
            const isLargeFile = file.size > 20_000_000;
            const workBook = XLSX.read(fileResults, {
              type: "array",
              codepage: 65001,
              raw: true,
              dense: isLargeFile,
            });
            const workBookName = workBook.SheetNames[0];
            const sheet = workBook.Sheets[workBookName];

            return XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });
          };

          const fileData: any[] = getDataFromFile(results);
          if (!fileData.length) {
            addToast(
              "The file appears to be empty or cannot be parsed.",
              "error"
            );
            setIsLoading && setIsLoading(false);
            return;
          }

          const headersCount = fileData[0]?.length;
          if (validHeadersCount && !validHeadersCount(headersCount)) {
            addToast("Invalid header count in file.", "error");
            setIsLoading && setIsLoading(false);
          } else {
            onComplete(fileData);
            setIsLoading && setIsLoading(false);
          }
        } catch (error) {
          addToast(
            `An error occurred while processing the file: ${error.message}`,
            "error"
          );
          setIsLoading && setIsLoading(false);
        }
      };

      if (isCsvFile) {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    });
  };

  const onDropRejected = (rejectedFiles) => {
    const errorMessages: [] = rejectedFiles.map((file) => {
      if (file.errors[0].code === "file-too-large") {
        return "Please upload a CSV file less than 100mb or an XLSX file less than 50mb.";
      } else {
        return file.errors[0].message;
      }
    });
    const uniqueMessages: never[] = Array.from(new Set(errorMessages));
    uniqueMessages.forEach((message) => {
      addToast(message, "error");
    });
  };

  const validator = (file: File) => {
    const isCsvFile = file.type === "text/csv";
    const isExcelFile = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ].includes(file.type);

    if (isCsvFile && file.size > 100_000_000) {
      return {
        code: "file-too-large",
        message: "Maximum file size is 100MB",
      };
    } else if (isExcelFile && file.size > 50_000_000) {
      return {
        code: "file-too-large",
        message: "Maximum file size is 50MB",
      };
    }

    return null;
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    accept: ".csv, .xls, .xlsx",
    maxFiles: 1,
    validator,
  });

  return {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
  };
};

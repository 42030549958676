import { TooltipProvider } from "fuse-shared-ui";
import { Div } from "fuse-shared-ui";
import { editIcon } from "../../../../../TeamMembers/common/icons";
import * as S from "../styles";

const TableEditIcon = ({ onClick }) => {
  return (
    <S.ActionWrapper data-cy={"table-edit-button"}>
      <Div h={40}>
        <TooltipProvider tooltip="Edit" placement="auto" centered>
          <S.EditButton clickable onClick={onClick} centered>
            {editIcon}
          </S.EditButton>
        </TooltipProvider>
      </Div>
    </S.ActionWrapper>
  );
};

export default TableEditIcon;

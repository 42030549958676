import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isRecordEmpty } from "../../Importer/common/Spreadsheet/common";
import { useAppLoadingContext } from "../../common/AppLoadingContextProvider";
import {
  AppResponse,
  Field,
  OnValidateRecord,
  Record,
  RecordDataSet,
} from "fuse-importer";
import { throttle } from "../../utils/hooks/throttle";
import { AppLoading } from "../common/AppLoading";
import { OnDataSetChanged } from "../common/Spreadsheet";
import { usePersistenceContext } from "../common/Spreadsheet/PersistenceContextProvider";
import { useImporterContext } from "../contexts/ImporterContextProvider";
import {
  getPreviewDataSet,
  getReviewDataSet,
  getTemplateFields,
} from "../data";
import { transformToRecords } from "../data/index";
import { useSubmitToServer } from "../hooks/useSubmitToServer";

type State = {
  previewInitialDataSet: RecordDataSet;
  previewFields: Field[];
  reviewInitialDataSet: RecordDataSet;
  reviewFields: Field[];
  appResponse: AppResponse;
  isSubmitting: boolean;
  importWasSuccessful: boolean;
  options: { batchValidationDelayMs: number; batchValidationSize: number };
  initialDataSetUpdatedAt: number;
  onValidateRecord: OnValidateRecord;
  onDataSetChanged: OnDataSetChanged;
  onSubmit: (
    dataSet: RecordDataSet,
    filteredDataIds: string[]
  ) => Promise<void>;
  setReviewInitialDataSetDeprecated: (dataSet: any) => void;
  setInitialDataSetUpdatedAt: React.Dispatch<any>;
  webhooksProcessing?: boolean;
  setIsSubmissionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmissionModalOpen: boolean;
  hasTransformedRecords: boolean;
  setHasTransformedRecords: React.Dispatch<React.SetStateAction<boolean>>;
};

export type ExportDataType = "csv" | "excel";

type Props = {
  initialRecords?: Record[];
  children: JSX.Element;
};

const ReviewContext = createContext<State>({} as State);

export const ReviewContextProvider = ({ initialRecords, children }: Props) => {
  const {
    inTrialMode,
    templateHeaderMatchings,
    enumFieldValueMatchings,
    templateHeaders,
    uploadedDataHeaders,
    headerRowIndex,
    uploadedData,
    currentStepIndex,
    setSelectedRows,
    importerOptions: {
      batchValidationDelayMs,
      batchValidationSize,
      loadingComponent,
      trackUploadedRecords,
    },
    onValidateRecord,
    formatRecord,
  } = useImporterContext();

  const [hasTransformedRecords, setHasTransformedRecords] = useState(false);

  const {
    appResponse,
    isSubmitting,
    setIsSubmitting,
    importWasSuccessful,
    onSubmitToServer,
    webhooksProcessing,
  } = useSubmitToServer(setHasTransformedRecords);

  const { importer, createImporter } = usePersistenceContext();

  const { setIsLoadingApp } = useAppLoadingContext();

  const [previewFields, setPreviewFields] = useState<Field[]>([]);
  const [previewInitialDataSet, setPreviewInitialDataSet] = useState<
    RecordDataSet
  >(null);

  const [reviewFields, setReviewFields] = useState<Field[]>([]);
  const reviewInitialDataSet = useRef<RecordDataSet>(null);
  const [initialDataSetUpdatedAt, setInitialDataSetUpdatedAt] = useState(null);
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);

  const [_, setInefficientRerender] = useState(false);
  const setReviewInitialDataSetDeprecated = (dataSet: RecordDataSet) => {
    reviewInitialDataSet.current = dataSet;
    setInefficientRerender((prev) => !prev);
  };

  const fields = getTemplateFields({ templateHeaders });

  useEffect(() => {
    if (currentStepIndex === 1) setReviewInitialDataSetDeprecated(null);
  }, [currentStepIndex]);

  const reviewDataSetRef = useRef<RecordDataSet>(null);

  const options = useMemo(
    () => ({
      batchValidationDelayMs,
      batchValidationSize,
    }),
    [batchValidationDelayMs, batchValidationSize]
  );

  const onDataSetChanged = useCallback((dataSet: RecordDataSet) => {
    reviewDataSetRef.current = dataSet;
  }, []);

  useEffect(() => {
    if (!uploadedData && currentStepIndex === 0) {
      //Reset data to clean the memory
      setPreviewFields([]);
      setReviewFields([]);
      setReviewInitialDataSetDeprecated(null);
      reviewDataSetRef.current = null;
      return;
    }

    const sleep = (n: number) =>
      new Promise((resolve) => setTimeout(resolve, n));

    if (currentStepIndex === 0) {
      //Uploader spreadsheet data
      const { fields, initialDataSet } = getPreviewDataSet({ uploadedData });
      setPreviewFields(fields);
      setPreviewInitialDataSet(initialDataSet);
      setInitialDataSetUpdatedAt(Date.now());
    } else if (currentStepIndex === 2) {
      setIsLoadingApp(true);
      (async () => {
        // this is a hack to get the UI to respond with large datasets
        // needs to be fixed
        if (uploadedData && uploadedData.length > 1000) {
          // wait for the first render to complete before blocking main thread with review step logic
          await sleep(200);
        }

        const generateRecords = async () =>
          await transformToRecords({
            templateHeaderMatchings,
            enumFieldValueMatchings,
            templateHeaders,
            uploadedDataHeaders,
            uploadedData: uploadedData.slice(headerRowIndex + 1),
            formatRecord,
          });

        //Review spreadsheet data
        const records: Record[] = initialRecords ?? (await generateRecords());

        const { isDatasetTransformed, initialDataSet } = getReviewDataSet({
          records,
          fields,
          trackUploadedRecords,
        });
        if (isDatasetTransformed) setHasTransformedRecords(true);
        setSelectedRows([]);
        setReviewFields(fields);
        setReviewInitialDataSetDeprecated(initialDataSet);
        setInitialDataSetUpdatedAt(Date.now());
      })();
    }
  }, [currentStepIndex, templateHeaders, uploadedData]);

  const getSubmittableRecords = (records: Record[]) => {
    return records.filter(
      (record) => !record._meta.isInvalid && !isRecordEmpty(record, fields)
    );
  };

  const extractValuesFromDataSet = (
    dataSet: RecordDataSet,
    ids: string[],
    maxAmount: number
  ): RecordDataSet => {
    if (!maxAmount) return dataSet;
    const extractedDataSet: RecordDataSet = {};
    for (let i = 0; i < Math.min(maxAmount, ids.length); i++) {
      const index = ids[i];
      extractedDataSet[index] = dataSet[index];
    }
    return extractedDataSet;
  };

  const prepareSubmissionData = (
    dataSet: RecordDataSet,
    filteredDataIds: string[]
  ) => {
    const currentDataSet = inTrialMode
      ? extractValuesFromDataSet(dataSet, filteredDataIds, 100)
      : dataSet;

    const dataSetValues = Object.values(currentDataSet);
    const submittableRecords = getSubmittableRecords(dataSetValues);

    return { currentDataSet, submittableRecords };
  };

  const onSubmit = useCallback(
    throttle(async (dataSet: RecordDataSet, filteredDataIds: string[]) => {
      setIsSubmitting(false);

      const { currentDataSet, submittableRecords } = prepareSubmissionData(
        dataSet,
        filteredDataIds
      );

      if (!importer.current) {
        await createImporter(submittableRecords.length);
      }
      // passing a callback to the onSubmit function (from hook) to get
      // access to the functions declared inside the context provider
      await onSubmitToServer(
        currentDataSet,
        submittableRecords,
        (invalidDataSet: RecordDataSet) => {
          setReviewInitialDataSetDeprecated(invalidDataSet);
          setInitialDataSetUpdatedAt(Date.now());
        }
      );
    }, 1000),
    [
      setReviewInitialDataSetDeprecated,
      setInitialDataSetUpdatedAt,
      onSubmitToServer,
    ]
  );

  const value = {
    previewInitialDataSet,
    previewFields,
    reviewInitialDataSet: reviewInitialDataSet.current,
    reviewFields,
    importWasSuccessful,
    appResponse,
    isSubmitting,
    options,
    initialDataSetUpdatedAt,
    onSubmit,
    onValidateRecord,
    onDataSetChanged,
    setReviewInitialDataSetDeprecated,
    setInitialDataSetUpdatedAt,
    webhooksProcessing,
    isSubmissionModalOpen,
    setIsSubmissionModalOpen,
    hasTransformedRecords,
    setHasTransformedRecords,
  };

  function renderLoading() {
    return isSubmitting ? (
      loadingComponent ? (
        loadingComponent()
      ) : (
        <AppLoading />
      )
    ) : null;
  }

  return (
    <ReviewContext.Provider value={value}>
      {!webhooksProcessing && renderLoading()}
      {children}
    </ReviewContext.Provider>
  );
};

export const useReviewContext = () => useContext(ReviewContext);

import { useState } from "react";
import styled from "styled-components";
import { Button, Div, Modal } from "fuse-shared-ui";
import { useDeleteTableRow } from "../../TemplateRoutes/TemplateColumns/components/TableColumns/useDeleteTableRow";
import { deleteImageIcon } from "../common/icons";
import { User } from "components/UserContextProvider";
import { useMemo } from "react";

const Content = styled(Div)`
  width: 448px;
  height: 280px;
  margin: 48px 40px;
`;

const Text = styled(Div)`
  margin-top: 12px;
  ${(p) => p.theme.textStyles.wbody1};
  display: flex;
  justify-content: center;
  word-break: break-all;
  flex-wrap: wrap;
  white-space: nowrap;
`;

const TextInner = styled(Div)`
  margin: 0 5px;
  ${(p) => p.theme.textStyles.wbody2};
`;

const FormButtonsWrapper = styled(Div)`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

type DeleteUserModalProps = {
  isOpen: boolean;
  id: number;
  user: User;
  onClose: () => void;
};

const DeleteUserModal = ({
  isOpen,
  id,
  user,
  onClose,
}: DeleteUserModalProps) => {
  const userName = useMemo(
    () =>
      user?.first_name ? `${user.first_name} ${user.last_name}` : user.email,
    [user]
  );

  const { deleteTableRow } = useDeleteTableRow({
    deleteUrl: `/api/v1/organizations/invitations/${id}`,
    customMessage: (
      <Div dflex wbody1>
        <Div wbody2 mr={5}>
          {userName}
        </Div>
        was removed from the team
      </Div>
    ),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDelete = async () => {
    setIsLoading(true);

    await deleteTableRow();

    setIsLoading(false);
    onClose();
  };

  const contentUI = (
    <Content>
      <Div textAlignCenter>
        {deleteImageIcon}
        <Div mt={20} h5>
          Delete Team Member
        </Div>
        <Text>
          Are you sure you want to remove <TextInner>{userName}</TextInner>from
          this organization?
        </Text>
      </Div>
      <FormButtonsWrapper>
        <Button
          mr={16}
          w={104}
          variant="secondary"
          onClick={onClose}
          isDisabled={isLoading}
        >
          Cancel
        </Button>
        <Button w={135} onClick={onDelete} isDisabled={isLoading}>
          Yes, delete
        </Button>
      </FormButtonsWrapper>
    </Content>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {contentUI}
    </Modal>
  );
};

export default DeleteUserModal;

import styled from "styled-components";

import { Div } from "fuse-shared-ui";
import { FormikSumbitButton as FormikSumbitButtonBase } from "components/common/form/Form";
import { GithubLoginButton as GithubLoginButtonBase } from "../common/GithubLoginButton";

export const Wrapper = styled(Div)`
  ${(props) => {
    const c = props.theme.css;
    return [c.grow, c.justifyCenter, c.yscroll, c.pRelative];
  }};
`;

export const PreContentContainer = styled(Div)`
  position: relative;
  margin: 80px 160px;
  min-height: 1052px;
  max-width: 1120px;
  width: 100%;
  @media all and (max-width: 980px) {
    margin: 80px;
  }
  @media all and (max-width: 840px) {
    margin: 24px;
  }
`;

export const ContentContainer = styled(Div)`
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 40px rgba(135, 70, 70, 0.08);
`;

export const FormContentWrapper = styled(Div)`
  padding: 64px;
  max-width: 560px;
  @media all and (max-width: 1220px) {
    max-width: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media all and (max-width: 840px) {
    padding: 24px;
  }
`;

export const LogoWrapper = styled(Div)`
  width: 100%;
  @media all and (max-width: 1220px) {
    display: flex;
    justify-content: center;
  }
`;

export const Title = styled(Div)`
  margin-top: 40px;
  ${(props) => props.theme.textStyles.wh1};
  color: ${(props) => props.theme.colors.black};
  @media all and (max-width: 1220px) {
    text-align: center;
  }
`;

export const Subtitle = styled(Div)`
  margin-top: 12px;
  ${(props) => props.theme.textStyles.wbody1};
  color: ${(props) => props.theme.colors.gray700};

  strong {
    font-weight: 700;
  }

  @media all and (max-width: 1220px) {
    text-align: center;
  }
`;

export const FormContentNameFieldsWrapper = styled(Div)`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 720px) {
    flex-direction: column;
    & > div:not(:first-child) {
      margin-top: 24px;
    }
  }
`;

export const FormContentItem = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media all and (max-width: 720px) {
    max-width: unset;
    display: block;
    margin-right: unset;
    margin-left: unset;
  }
`;

export const FormikSumbitButton = styled(FormikSumbitButtonBase)`
  margin-right: 16px;
  @media all and (max-width: 900px) {
    margin-right: unset;
    margin-bottom: 16px;
  }
`;

export const GithubLoginButton = styled(GithubLoginButtonBase)`
  margin-left: 16px;
  @media all and (max-width: 900px) {
    margin-left: unset;
    margin-top: 16px;
  }
`;

export const FormContentButtonsWrapper = styled(Div)`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const BackgroundImage1 = styled(Div)`
  position: absolute;
  right: -68px;
  top: -33px;
  @media all and (max-width: 1220px) {
    display: none;
  }
`;

export const TextTermsAndPrivacy = styled(Div)`
  margin-top: 24px;
  color: ${(props) => props.theme.colors.textSecondary};
  ${(props) => props.theme.textStyles.wbody3};
  display: flex;
  & > div {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  @media all and (max-width: 900px) {
    justify-content: center;
    flex-wrap: wrap;
    white-space: nowrap;
  }
`;

export const TextLink = styled(Div)`
  margin: 0 5px;
  ${(props) => {
    const c = props.theme.css;
    return [c.link, c.justifyEnd];
  }};
  color: ${(props) => props.theme.colors.pink500};
`;

export const TextSignIn = styled(Div)`
  margin-top: 40px;
  display: flex;
  color: ${(props) => props.theme.colors.textSecondary};
  ${(props) => props.theme.textStyles.wlink};
  :hover {
    cursor: text;
    text-decoration-line: none;
    opacity: unset;
  }
  & > div {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  @media all and (max-width: 720px) {
    justify-content: center;
  }
`;

export const passwordValidationsList = [
  {
    rule: /.{6,}$/,
    message: "At least 6 characters",
  },
  {
    rule: /^(?=.*?[A-Z])/,
    message: "Add uppercase letters",
  },
  {
    rule: /^(?=.*?[0-9])/,
    message: "Add numbers",
  },
  {
    rule: /^(?=.*?[#?!@$%^&*-])/,
    message: "Add least 1 special character",
  },
];

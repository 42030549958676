import { Div } from "../../styled/index";
import { TooltipProvider, TooltipProps } from "../index";
import styled from "styled-components";

export const TooltipButton = styled(Div)`
  border-radius: 4px;
  :hover {
    cursor: pointer;
  }
`;

export const Tooltip = ({
  tooltip,
  placement,
  centered,
  tooltipMaxWidth = 300,
  tooltipMinWidth,
  ...divProps
}: TooltipProps) => {
  return (
    <Div alignCenter {...divProps}>
      <TooltipProvider
        tooltip={tooltip}
        placement={placement}
        centered={centered}
        tooltipMaxWidth={tooltipMaxWidth}
        tooltipMinWidth={tooltipMinWidth}
      >
        <TooltipButton clickable centered>
          {tooltipIcon}
        </TooltipButton>
      </TooltipProvider>
    </Div>
  );
};

export default Tooltip;

const tooltipIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12.4818 6C11.6149 5.99984 10.8274 6.31882 10.3038 6.59014C10.0325 6.7307 9.80855 6.8709 9.65073 6.97706C9.57143 7.03041 9.50776 7.07589 9.46203 7.10952C9.43914 7.12635 9.42066 7.14028 9.40688 7.1508L9.38974 7.164L9.38386 7.16859L9.38161 7.17037L9.38065 7.17112L9.38021 7.17146C9.38001 7.17163 9.37981 7.17178 10 7.95623L9.37981 7.17178C8.94657 7.51431 8.87303 8.14319 9.21555 8.57643C9.55736 9.00876 10.1843 9.0829 10.6175 8.74283L10.6209 8.74018C10.6255 8.7367 10.6342 8.73007 10.6469 8.72076C10.6723 8.70208 10.7131 8.67283 10.7671 8.63652C10.8758 8.56335 11.0338 8.46444 11.2239 8.36591C11.6231 8.15906 12.0765 7.99992 12.4814 8C13.3769 8.00017 13.8894 8.48905 13.974 9.05964C14.0504 9.57477 13.9484 9.91665 13.8027 10.1614C13.6461 10.4244 13.3957 10.6453 13.0845 10.8261C12.1445 11.372 10.9853 12.4174 10.9853 13.9151V14.1739C10.9853 14.7262 11.433 15.1739 11.9853 15.1739C12.5376 15.1739 12.9853 14.7262 12.9853 14.1739V13.9151C12.9853 13.5131 13.3403 12.9903 14.0889 12.5556C14.5928 12.2629 15.139 11.8265 15.5212 11.1846C15.9141 10.5246 16.0929 9.71422 15.9524 8.76635C15.69 6.99666 14.1299 6.00031 12.4818 6ZM12.9853 17.5217C12.9853 16.9695 12.5376 16.5217 11.9853 16.5217C11.433 16.5217 10.9853 16.9695 10.9853 17.5217V18C10.9853 18.5523 11.433 19 11.9853 19C12.5376 19 12.9853 18.5523 12.9853 18V17.5217Z"
      fill="black"
    />
  </svg>
);

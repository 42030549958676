import { transformRecord } from "../../Importer/common/Spreadsheet/transformation";
import {
  Field,
  FieldTypes,
  FormatRecord,
  Record,
  RecordDataSet,
  TemplateHeader,
  TransformedRecord,
} from "fuse-importer";
import { parseValue } from "../common/Spreadsheet/common";
import {
  EnumFieldValueMatchings,
  HeaderMatchings,
} from "../contexts/ImporterContextProvider";

const MINIMUM_ROW_COUNT = 20;

const getRowId = (index: number) => {
  return index.toString();
};

export const addEmptyRows = (data: Record[], fields: Field[]) => {
  const emptyRecord = fields.reduce(
    (obj, f) => ({ ...obj, [f.name]: null }),
    {}
  );

  const newData = Array.from(
    { length: MINIMUM_ROW_COUNT - data.length },
    (_, index) => ({
      ...emptyRecord,
      _meta: {
        id: getRowId(data.length + index),
        rowIndex: data.length + index,
      },
    })
  );

  return [...data, ...newData];
};

export const addMeta = (
  record: any,
  index: number,
  uploadedRow: any = null,
  transformedRow: TransformedRecord = {}
) => {
  const rId = getRowId(index);
  const meta = record._meta ?? {};
  return {
    ...record,
    _meta: {
      ...meta,
      id: rId,
      rowIndex: index,
      uploadedRow,
      transformedRow,
    },
  } as Record;
};

export const recordListToDataSet = (
  data: Record[],
  fields: Field[],
  withTransformations = true,
  trackUploadedRecords = false
): { isDatasetTransformed: boolean; dataSet: RecordDataSet } => {
  const fieldWithTransformations = fields.filter(
    (field) => field?.transformations?.length > 0
  );
  let initialData = data;
  if (data.length < MINIMUM_ROW_COUNT) {
    initialData = addEmptyRows(data, fields);
  }

  let isDatasetTransformed = false;
  const res = initialData.reduce((dataSet, record, i) => {
    const uploadedRecord = trackUploadedRecords ? { ...record } : undefined;

    if (trackUploadedRecords) {
      delete uploadedRecord?._meta;
    }

    let transformedRecord = {};
    if (withTransformations && fieldWithTransformations.length > 0) {
      transformedRecord = transformRecord({
        record,
        fields: fieldWithTransformations,
      });
    }

    if (JSON.stringify(transformedRecord) !== "{}") isDatasetTransformed = true;
    const recordWithMeta = addMeta(
      record,
      i,
      uploadedRecord,
      transformedRecord
    );
    dataSet[recordWithMeta._meta.id] = recordWithMeta;
    return dataSet;
  }, {});
  return { isDatasetTransformed, dataSet: res };
};

type transformToRecordsArgs = {
  templateHeaderMatchings: HeaderMatchings;
  enumFieldValueMatchings: EnumFieldValueMatchings;
  uploadedDataHeaders: string[];
  uploadedData: any[];
  templateHeaders: TemplateHeader[];
  formatRecord: FormatRecord;
};

const compareFormattedRecord = (
  originalRecord: Record,
  formattedRecord: Record
) => {
  const comparisonResult = {};

  for (const key in formattedRecord) {
    if (formattedRecord.hasOwnProperty(key)) {
      if (formattedRecord[key] !== originalRecord[key]) {
        comparisonResult[key] = {
          original: originalRecord[key],
          transformed: formattedRecord[key],
        };
      }
    }
  }

  return comparisonResult;
};

export const transformToRecords = async ({
  templateHeaderMatchings,
  enumFieldValueMatchings,
  templateHeaders,
  uploadedDataHeaders,
  uploadedData,
  formatRecord,
}: transformToRecordsArgs) => {
  const matchedColumnsLabels = Object.keys(templateHeaderMatchings)
    .filter((label) => !!templateHeaderMatchings[label])
    .map((label) => ({
      label,
      index: uploadedDataHeaders.indexOf(templateHeaderMatchings[label]),
    }));

  const templateHeadersByLabel: {
    [key: string]: TemplateHeader;
  } = templateHeaders.reduce(
    (byLabel, header) => ({
      ...byLabel,
      [header.label]: header,
    }),
    {}
  );

  const getRecord = async (row: any, index: number): Promise<Record> => {
    const record: Record = addMeta({}, index, row);

    matchedColumnsLabels.forEach(({ label, index }) => {
      const enumSet = enumFieldValueMatchings
        ? enumFieldValueMatchings[label]
        : null;

      const header = templateHeadersByLabel[label];

      if (!header) return;

      const key = header.internal_key;
      const value = row[index];

      if (value === undefined) return;

      record[key] = parseValue(FieldTypes[header.column_type], value, enumSet);
    });

    if (formatRecord) {
      const formattedRecord = await formatRecord(record);
      const comparisonResult = compareFormattedRecord(record, formattedRecord);
      record._meta.transformedRow = comparisonResult;
      return formattedRecord;
    }

    return record;
  };

  const getRecordPromises = uploadedData.map(getRecord);
  return await Promise.all(getRecordPromises);
};

export const getPreviewDataSet = ({
  uploadedData,
}: {
  uploadedData: any[];
}) => {
  let rowLen = 0;
  const previewSize = Math.min(100, uploadedData.length);
  for (let i = 0; i < previewSize; i++)
    rowLen = Math.max(rowLen, uploadedData[i].length);

  const fields = new Array(rowLen).fill(null).map((_, i) => {
    const key = String.fromCharCode(65 + i);
    return {
      label: key,
      name: key,
      type: FieldTypes.string,
      isRequired: false,
    };
  });

  const getInitialDataSet = () => {
    const data: Record[] = uploadedData
      .slice(0, previewSize)
      .map((row, index) => {
        const entry: Record = addMeta({}, index);
        fields.forEach((field, index) => {
          entry[field.name] = row[index];
        });
        return entry;
      });
    return recordListToDataSet(data, fields).dataSet;
  };

  return { fields, initialDataSet: getInitialDataSet() };
};

export const getTemplateFields = ({
  templateHeaders,
}: {
  templateHeaders: TemplateHeader[];
}) => {
  return templateHeaders.map((templateHeader) => ({
    label: templateHeader.label,
    name: templateHeader.internal_key,
    description: templateHeader.description,
    type: FieldTypes[templateHeader.column_type] || FieldTypes.string,
    isRequired: templateHeader.required,
    options: templateHeader.values,
    pattern: templateHeader.pattern,
    validations: templateHeader.validations,
    transformations: templateHeader.transformations,
  }));
};

type GetReviewDataSetArgs = {
  records: Record[];
  fields: Field[];
  trackUploadedRecords?: boolean;
};

export const getReviewDataSet = ({
  records,
  fields,
  trackUploadedRecords,
}: GetReviewDataSetArgs) => {
  const {
    isDatasetTransformed,
    dataSet: baseInitialDataSet,
  } = recordListToDataSet(
    addEmptyRows(records, fields),
    fields,
    undefined,
    trackUploadedRecords
  );

  let highestRowIndex = 0;

  Object.keys(baseInitialDataSet).forEach((key) => {
    const record = baseInitialDataSet[key];
    if (record._meta.rowIndex > highestRowIndex) {
      highestRowIndex = record._meta.rowIndex;
    }
  });

  const emptyRowAtBottom = addMeta({}, highestRowIndex + 1);

  const initialDataSet = {
    ...baseInitialDataSet,
    [emptyRowAtBottom._meta.id]: emptyRowAtBottom,
  };

  return { isDatasetTransformed, initialDataSet };
};

import React from "react";
import styled from "styled-components";
import { colors, Div } from "fuse-shared-ui";

type Props = {
  title: string;
  subTitle: React.ReactNode;
  mt?: number;
  mb?: number;
  subTitleWidth?: number;
};

const HeaderBase = styled(Div)`
  text-align: center;
`;

const Header = ({
  title,
  subTitle,
  mt = 32,
  mb = 24,
  subTitleWidth,
}: Props) => {
  return (
    <HeaderBase mt={mt} mb={mb}>
      <Div h5 c={colors.text}>
        {title}
      </Div>
      <Div centered>
        <Div wbody3 mt={6} w={subTitleWidth || undefined}>
          {subTitle}
        </Div>
      </Div>
    </HeaderBase>
  );
};

export default Header;

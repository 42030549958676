import { useTranslation } from "react-i18next";
import { Button, Div, colors, warningModalIcon } from "..";

export const RetryModal = ({
  handleClose,
  onSubmit,
  icon = warningModalIcon,
}) => {
  const { t } = useTranslation("review");
  const titleMessage = t("persistence.retry_modal.title");
  const descriptionMessage = t("persistence.retry_modal.description");
  const cancelMessage = t("persistence.retry_modal.cancel");
  const tryAgainMessage = t("persistence.retry_modal.try_again");

  const headerUI = (
    <Div maxw={440}>
      {icon && (
        <Div centered mb={20}>
          {icon}
        </Div>
      )}
      <Div
        data-cy="retry-modal-title"
        h5
        mb={12}
        style={{ textAlign: "center" }}
      >
        {titleMessage}
      </Div>
    </Div>
  );

  const bodyUI = (
    <Div maxw={440}>
      <Div
        data-cy="retry-modal-description"
        c={colors.gray800}
        body1
        flexColumn
        centered
        maxw={440}
      >
        {descriptionMessage}
      </Div>
      <Div mt={40} dflex centered>
        <Button
          data-cy="retry-modal-cancel"
          variant="secondary"
          mr={16}
          w={104}
          onClick={handleClose}
        >
          {cancelMessage}
        </Button>

        <Button w={135} onClick={onSubmit} data-cy="try_again_button">
          {tryAgainMessage}
        </Button>
      </Div>
    </Div>
  );

  return (
    <>
      <Div p="48px 56px" maxw={640}>
        {headerUI}
        {bodyUI}
      </Div>
    </>
  );
};

import {
  ColumnRequiredProps,
  CustomAction,
  CustomActionTypes,
  FieldTypesAsUnion,
  FormatRecord,
  ImporterOptions,
  OnSubmit,
  OnValidateRecord,
  Record,
  TemplateHeader,
  fuseImporterId,
} from "fuse-importer";
import i18n from "i18next";
import ReactDOM from "react-dom";
import {
  Importer,
  buildDynamicColumns,
  configureI18n,
  de,
  en,
  es,
  fr,
} from "fuse-shared-ui";

class LocalFuseImporter {
  apiToken: string;
  templateSlug: string;
  options: ImporterOptions;
  records: Record[];

  public constructor(
    apiToken: string,
    templateSlug: string,
    options: ImporterOptions = {}
  ) {
    this.apiToken = apiToken;
    this.templateSlug = templateSlug;
    this.options = {
      env: "production",
      zIndex: 1000,
      batchValidationDelayMs: 500,
      ...options,
    };
  }

  private dynamicColumns: TemplateHeader[] = [];

  private customActions: CustomAction[] = [];

  private cleanupIframe: (() => void) | null = null;

  public formatRecord: FormatRecord;

  public onValidateRecord: OnValidateRecord;

  public onSubmit: OnSubmit;

  public onClose: () => void;

  private windowScrollPosition = {
    x: 0,
    y: 0,
  };

  close() {
    this.onClose?.();

    if (this.cleanupIframe) {
      this.cleanupIframe();
      this.cleanupIframe = null;
    }

    const iframe = document.getElementById(fuseImporterId);
    document.body.removeChild(iframe);

    // allow the scroll when the importer is closed
    document.body.style.overflow = "auto";
    window.scrollTo(this.windowScrollPosition.x, this.windowScrollPosition.y);
  }

  public async show() {
    await this.configureI18n();
    this.renderImporterComponent();
    i18n.reloadResources();
  }

  public addColumn<T extends FieldTypesAsUnion>(
    columnData: ColumnRequiredProps<T>
  ) {
    this.dynamicColumns.push(buildDynamicColumns(columnData));
  }

  public addCustomAction<T extends CustomActionTypes>(
    actions: CustomAction<T>[] | CustomAction<T>
  ) {
    if (!Array.isArray(actions)) actions = [actions];
    this.customActions.push(...actions);
  }

  private renderImporterComponent() {
    const importerDiv = this.createDivComponent();
    const importerComponent = this.createImporterComponent();
    ReactDOM.render(importerComponent, importerDiv);
  }

  private createDivComponent() {
    const div = document.createElement("div");
    div.setAttribute("id", fuseImporterId);
    document.body.appendChild(div);
    return div;
  }

  private createImporterComponent() {
    return (
      <Importer
        apiToken={this.apiToken}
        templateSlug={this.templateSlug}
        options={this.options}
        formatRecord={this.formatRecord}
        onValidateRecord={this.onValidateRecord}
        dynamicColumns={this.dynamicColumns}
        customActions={this.customActions}
        onSubmit={this.onSubmit}
        onClose={() => {
          this.close();
        }}
      />
    );
  }

  private async configureI18n() {
    configureI18n(this.options.language, { es, en, de, fr });
  }
}

export default LocalFuseImporter;

import { Div } from "fuse-shared-ui";
import { LinkEl, ListItem, PrivacyAndTermsWrapper, Section } from "./common";

const PrivacyPolicy = () => (
  <PrivacyAndTermsWrapper>
    <Div wh3 mb={20}>
      Flatirons Fuse, Inc. Privacy Policy
    </Div>

    <Section title="Introduction">
      At Flatirons Fuse, Inc., your privacy and data security are our top
      priorities. This Privacy Policy outlines our practices concerning the
      collection, use, and safeguarding of your personal information, including
      sensitive data you may choose to import into our system. Adhering to the
      Health Insurance Portability and Accountability Act (HIPAA) and other
      applicable laws, we are committed to protecting the privacy of our users
      and ensuring the confidentiality of their data.
    </Section>

    <Section title="Information We Collect">
      We collect information in two ways: information you provide us and
      information we collect through automated means. This includes:
      <ul>
        <ListItem isLabelBold label="Personal Identifiers:">
          Such as name, address, email address, and phone number.
        </ListItem>

        <ListItem isLabelBold label="Sensitive Data:">
          You control the sensitive data imported into our system. This may
          include health information, financial records, or other personal data
          deemed sensitive under applicable laws.
        </ListItem>

        <ListItem isLabelBold label="Device Information:">
          Such as IP address, browser type, and operating system.
        </ListItem>

        <ListItem isLabelBold label="Usage Data:">
          Information on how you interact with our services.
        </ListItem>
      </ul>
    </Section>

    <Section title="How We Use Your Information">
      We use the information collected for several purposes:
      <ul>
        <ListItem>To provide and improve our services.</ListItem>

        <ListItem>
          To communicate with you about your account or our services.
        </ListItem>

        <ListItem>
          For customer support and to respond to your inquiries.
        </ListItem>

        <ListItem>For internal research and development purposes.</ListItem>

        <ListItem>To ensure the security of our services.</ListItem>

        <ListItem>
          To comply with applicable legal requirements and our policies.
        </ListItem>
      </ul>
    </Section>

    <Section title="Data Retention">
      We retain your personal information as long as necessary to provide the
      services you have requested, or for other essential purposes such as
      complying with our legal obligations. Our platform now offers &quot;custom
      data retention periods,&quot; allowing you to specify how long your data
      should be retained within our system.
    </Section>

    <Section title="HIPAA Compliance">
      Our commitment to data protection includes compliance with HIPAA, which
      governs the privacy and security of certain health information. Our
      practices include:
      <ul>
        <ListItem>
          Implementing stringent security measures to ensure the
          confidentiality, integrity, and availability of the sensitive health
          information we handle.
        </ListItem>

        <ListItem>
          Employing HIPAA-compliant policies and procedures governing the
          access, use, and disclosure of protected health information.
        </ListItem>

        <ListItem>
          Utilizing secure hosting setups, including our partnership with AWS,
          which aligns with HIPAA&apos;s security provisions and ensures the
          safeguarding of your data on our platform.
        </ListItem>
      </ul>
    </Section>

    <Section title="Your Rights and Choices">
      You have certain rights regarding the personal information we hold about
      you, including the right to access, correct, or delete your information,
      subject to certain exceptions. You may also have the right to specify your
      data retention period as per our custom data retention feature.
    </Section>

    <Section title="GDPR Compliance">
      Flatirons Fuse, Inc. is committed to ensuring the privacy and protection
      of personal data of individuals within the European Economic Area (EEA).
      In accordance with the General Data Protection Regulation (GDPR), we
      acknowledge the following rights of data subjects:
      <ul>
        <ListItem isLabelBold label="Right to Access:">
          You have the right to request access to your personal data that we
          hold.
        </ListItem>

        <ListItem isLabelBold label="Right to Rectification:">
          You have the right to request correction of inaccurate personal data.
        </ListItem>

        <ListItem isLabelBold label="Right to Erasure (Right to be Forgotten):">
          You may request the deletion of your data, subject to certain
          exceptions.
        </ListItem>

        <ListItem isLabelBold label="Right to Restriction of Processing:">
          You have the right to request that we restrict the processing of your
          personal data under certain circumstances.
        </ListItem>

        <ListItem isLabelBold label="Right to Data Portability:">
          You have the right to receive your personal data in a structured,
          commonly used, and machine-readable format, and to request the
          transfer of that data to another controller.
        </ListItem>

        <ListItem isLabelBold label="Right to Object:">
          You have the right to object to the processing of your personal data,
          based on your particular situation, at any time.
        </ListItem>

        <ListItem isLabelBold label="Right to Withdraw Consent:">
          Where the processing of personal data is based on consent, you have
          the right to withdraw consent at any time.
        </ListItem>

        <ListItem
          isLabelBold
          label="Right to Lodge a Complaint with a Supervisory Authority:"
        >
          If you believe that our processing of your personal data infringes the
          GDPR, you have the right to lodge a complaint with a supervisory
          authority in the Member State of your habitual residence, place of
          work, or place of the alleged infringement.
        </ListItem>
      </ul>
    </Section>

    <Section title="Data Transfer Outside the EEA">
      Flatirons Fuse, Inc. takes care to ensure that personal data transferred
      outside the EEA is subject to adequate safeguards, as defined by the GDPR,
      to ensure the security and privacy of your data. This may involve using
      data protection agreements that include standard contractual clauses
      approved by the European Commission or relying on certification schemes
      such as the Privacy Shield for data transfer to countries that are not
      deemed to offer an adequate level of data protection.
    </Section>

    <Section title="Legal Basis for Processing">
      We process personal data under the following legal bases:
      <ul>
        <ListItem isLabelBold label="Consent:">
          We may process data based on consent for specific purposes.
        </ListItem>

        <ListItem isLabelBold label="Contractual Necessity:">
          Processing is necessary for the performance of a contract or to take
          steps to enter into a contract.
        </ListItem>

        <ListItem isLabelBold label="Legal Obligations:">
          Processing is necessary to comply with legal obligations.
        </ListItem>

        <ListItem isLabelBold label="Legitimate Interests:">
          We process data based on legitimate interests, including providing a
          secure service, unless such interests are overridden by your data
          protection interests or fundamental rights and freedoms.
        </ListItem>
      </ul>
    </Section>

    <Section title="Changes to This Privacy Policy">
      We may update this policy from time to time to reflect changes in our
      practices or for other operational, legal, or regulatory reasons. We will
      notify you of any material changes by posting the updated policy on our
      website and updating the effective date.
    </Section>

    <Section title="Contact Us">
      If you have any questions about this Privacy Policy or our data practices,
      please contact us at <LinkEl>info@flatirons.com</LinkEl>
    </Section>
  </PrivacyAndTermsWrapper>
);

export default PrivacyPolicy;

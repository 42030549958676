import React from "react";
import { areEqual } from "react-window";
import { SpreadsheetContextProvider } from "./SpreadsheetContextProvider";
import { SpreadsheetProps } from "./common/types";
import SpreadsheetData from "./components/SpreadsheetData";

const SpreadsheetWrapper = ({
  initialDataSet,
  initialDataSetUpdatedAt,
  ...props
}: SpreadsheetProps) => {
  return (
    <>
      {initialDataSet && (
        <Spreadsheet
          key={initialDataSetUpdatedAt || 0}
          initialDataSet={initialDataSet}
          {...props}
        />
      )}
    </>
  );
};

const Spreadsheet = (props: SpreadsheetProps) => {
  return (
    <SpreadsheetContextProvider {...props}>
      <SpreadsheetData className={props.className} />
    </SpreadsheetContextProvider>
  );
};

export default React.memo(SpreadsheetWrapper, areEqual);

export * from "./SpreadsheetContextProvider";

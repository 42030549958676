import styled from "styled-components";

const SpinnerContainer = styled.div`
  animation: spin 1s linear infinite;
  background: transparent;
  border-radius: 50%;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
  z-index: 0;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinBlocker = styled.div`
  background: black;
  border-radius: 50%;
  height: 80%;
  left: 10%;
  position: absolute;
  top: 10%;
  width: 80%;
`;

const Spin = styled.div`
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.85) 50%,
    rgba(0, 0, 0, 0) 150%
  );
  height: 50%;
  left: 0;
  position: absolute;
  top: 50%;
  width: 50%;
  z-index: -1;
`;

export const CircleSpinner = () => {
  return (
    <SpinnerContainer>
      <SpinBlocker />
      <Spin />
    </SpinnerContainer>
  );
};

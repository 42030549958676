import {
  FormikDatePicker,
  FormikTextInput,
} from "components/common/form/fields";
import { Div, Tooltip, ValidationOption } from "fuse-shared-ui";

type ValidationOptionsProps = {
  name: string;
  options: ValidationOption[];
};

const ValidationOptions = ({ name, options }: ValidationOptionsProps) => {
  function renderOption(option: ValidationOption, index: number) {
    const isDate = option.type === "date";
    return (
      <Div key={`${name}.${option.key}`}>
        {isDate ? (
          <FormikDatePicker
            w100={false}
            ml={8}
            label=" "
            name={`${name}.${option.key}`}
          />
        ) : (
          <FormikTextInput
            w100={false}
            ml={8}
            placeholder="Enter value"
            label=" "
            icon={
              <Tooltip
                placement="top"
                textAlignCenter
                tooltipMinWidth={175}
                tooltip={option.tooltip}
              />
            }
            name={`${name}.${option.key}`}
            type={option.type}
          />
        )}
      </Div>
    );
  }
  return <>{options.map(renderOption)}</>;
};

export default ValidationOptions;

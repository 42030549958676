import { Div } from "fuse-shared-ui";
import styled from "styled-components";
import { useWindowDimensions } from "../../../../../../../hooks";
import { DesktopUI } from "./Desktop";
import { MobileUI } from "./Mobile";

const OptionsContainer = styled(Div)``;

export const OptionsUI = () => {
  const { width } = useWindowDimensions();

  return (
    <OptionsContainer>
      {width > 599 && <DesktopUI />}

      {width <= 599 && <MobileUI />}
    </OptionsContainer>
  );
};

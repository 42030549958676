export const greenCheckIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99996 15.3337C12.05 15.3337 15.3333 12.0504 15.3333 8.00033C15.3333 3.95024 12.05 0.666992 7.99996 0.666992C3.94987 0.666992 0.666626 3.95024 0.666626 8.00033C0.666626 12.0504 3.94987 15.3337 7.99996 15.3337ZM6.70707 7.25969C6.31654 6.86917 5.68338 6.86917 5.29285 7.25969C4.90233 7.65021 4.90233 8.28338 5.29285 8.6739L6.70707 10.0881L7.41417 10.7952L8.12128 10.0881L10.9497 7.25969C11.3402 6.86917 11.3402 6.236 10.9497 5.84548C10.5592 5.45495 9.92602 5.45495 9.53549 5.84548L7.41417 7.9668L6.70707 7.25969Z"
      fill="#0EBEBE"
    />
  </svg>
);

export const logoIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5C1 3.89543 1.89543 3 3 3H21C22.1046 3 23 3.89543 23 5V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5Z"
      stroke="black"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 21L16.2221 9.3844C16.9399 8.60681 18.1391 8.52294 18.9582 9.19305L23 12.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 10L8.75 17.75" stroke="black" strokeWidth="2" />
    <path
      d="M12.5 13C12.5 12 10.7091 10 8.5 10C6.29086 10 4.5 12 4.5 13"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ArrowGoingUp = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.77637 3.62402L12.2427 3.7574L12.376 10.2237"
      stroke="#025B5B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="11.6602"
      y1="4.4873"
      x2="4.36137"
      y2="11.7861"
      stroke="#025B5B"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

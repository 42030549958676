import axios from "axios";
import { Integration, Record, TemplateHeader } from "fuse-importer";

type IsEnumFieldArgs = {
  templateLabel: string;
  templateHeaders: TemplateHeader[];
};

export const getColumnType = ({
  templateLabel,
  templateHeaders,
}: IsEnumFieldArgs) =>
  templateHeaders.find((th) => th.label === templateLabel)?.column_type;

export const sendRecordsToIntegrations = async (
  reviewedData: Record[],
  integrations: Integration[]
) => {
  for (const record of reviewedData) {
    for (const integration of integrations) {
      const { url, trigger_type } = integration;
      if (trigger_type === "webhook") {
        try {
          const instance = axios.create({
            baseURL: url,
            timeout: 1000,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          });
          await instance.post("", record);
        } catch (error) {
          console.error(error);
        }
      }
    }
  }
};

type GenericRecord<T> = {
  [key: string]: T;
};

export const verifyDuplicateValue = (
  array: GenericRecord<any>[],
  key: string,
  shouldThrow = false,
  message?: string
) => {
  const uniqueValues: GenericRecord<boolean> = {};

  for (let i = 0; i < array.length; i++) {
    const currentValue = array[i][key];

    if (uniqueValues[currentValue]) {
      if (shouldThrow) {
        throw new Error(
          message || `There is a duplicated '${key}' between your columns.`
        );
      }

      return true;
    }

    uniqueValues[currentValue] = true;
  }

  return false;
};

import styled from "styled-components";
import { api, Div } from "fuse-shared-ui";
import { useUserContext } from "components/UserContextProvider";
import Form, { FormikSumbitButton } from "components/common/form/Form";
import { FormikTextInput } from "components/common/form/fields";
import { yupPassword } from "components/common/form/validations";
import { PasswordValidation } from "../common/PasswordValidation";
import {
  flatironsFuseLogo,
  checkYourEmailBackgroundPart1,
  checkYourEmailBackgroundPart2,
  checkYourEmailBackgroundPart3,
} from "../common/assets/images";

const BackgroundPart1 = styled(Div)`
  margin-top: -46px;
  margin-bottom: -33.58px;
  margin-left: -126px;
  max-width: 698px;
  min-width: 698px;
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  @media all and (max-width: 840px) {
    display: none;
  }
`;

const BackgroundPart2 = styled(Div)`
  margin-top: 371.19px;
  max-width: 534px;
  min-width: 534px;
  width: 100%;
  max-height: 373px;
  min-height: 373px;
  pointer-events: none;
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  @media all and (max-width: 1240px) {
    display: none;
  }
`;

const BackgroundPart3 = styled(Div)`
  margin-top: 557px;
  margin-left: 262.88px;
  max-width: 907px;
  width: 100%;
  opacity: 80%;
  display: flex;
  pointer-events: none;
  position: absolute;
  z-index: 30;
  left: 0;
  top: 0;
  @media all and (max-width: 1240px) {
    display: none;
  }
`;

const Wrapper = styled(Div)`
  width: 100vw;
  overflow-x: hidden;
  ${(props) => {
    const c = props.theme.css;
    return [c.grow, c.justifyCenter, c.yscroll, c.pRelative];
  }};
`;

const Container = styled(Div)`
  margin: 80px 160px;
  min-height: 740px;
  max-height: 740px;
  height: 100%;
  max-width: 1120px;
  width: 100%;
  position: relative;
  @media all and (max-width: 980px) {
    margin: 80px;
  }
  @media all and (max-width: 840px) {
    margin: 24px;
  }
`;

const ContentWrapper = styled(Div)`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 40px ${(props) => props.theme.colors.red2000};
  border-radius: 16px;
  position: relative;
  z-index: 10;
`;

const ContentHeader = styled(Div)`
  padding: 64px 80px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media all and (max-width: 1240px) {
    padding: 64px 0 80px;
  }
  @media all and (max-width: 768px) {
    padding: 40px 54px 58px 53px;
  }
`;

const Content = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media all and (max-width: 840px) {
    padding: 24px;
  }
`;

const TextWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextTitle = styled(Div)`
  ${(props) => props.theme.textStyles.wh1}
  @media all and (max-width: 1220px) {
    text-align: center;
  }
  @media all and (max-width: 768px) {
    ${(props) => props.theme.textStyles.wh2}
  }
`;

const TextSubtitle = styled(Div)`
  margin-top: 12px;
  max-width: 400px;
  color: ${(props) => props.theme.colors.gray700};
  ${(props) => props.theme.textStyles.wbody1}
  display: flex;
  text-align: center;
`;

const FormContentWrapper = styled(Div)`
  margin-top: 40px;
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media all and (max-width: 720px) {
    margin-top: unset;
  }
`;

const FormContentItem = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media all and (max-width: 720px) {
    max-width: unset;
    margin-top: 40px;
  }
`;

const passwordFile = {
  name: "password",
  label: "New Password*",
  placeholder: "Enter new password",
  initialValue: "",
  inputProps: {
    type: "password",
  },
  validation: yupPassword,
};

type ChangePasswordWithDataProps = {
  isValidToken: boolean;
  resetPasswordToken: any;
  onCompleteResetPassword: () => void;
};

export const ChangePasswordWithData = ({
  isValidToken,
  resetPasswordToken,
  onCompleteResetPassword,
}: ChangePasswordWithDataProps) => {
  const { user } = useUserContext();

  const onSubmit = async (values) => {
    await api.put(`/users/reset_passwords/${user.id}`, {
      ...values,
      reset_password_token: resetPasswordToken,
    });
    onCompleteResetPassword();
  };

  const backgroundImagesListUI = (
    <>
      <BackgroundPart1 key={1}>{checkYourEmailBackgroundPart1}</BackgroundPart1>
      <BackgroundPart2 key={2}>{checkYourEmailBackgroundPart2}</BackgroundPart2>
      <BackgroundPart3 key={3}>{checkYourEmailBackgroundPart3}</BackgroundPart3>
    </>
  );

  const textUI = (
    <TextWrapper>
      <TextTitle data-cy="reset-password-form-title">Change Password</TextTitle>
      <TextSubtitle data-cy="reset-password-form-sub-title">
        Enter your new password.
      </TextSubtitle>
    </TextWrapper>
  );

  const formUI = (
    <FormContentWrapper>
      <Form fields={[passwordFile]} onSubmit={onSubmit}>
        <>
          <FormContentItem>
            <FormikTextInput
              label={passwordFile.label}
              placeholder={passwordFile.placeholder}
              name={passwordFile.name}
              type={passwordFile.inputProps.type}
            />
          </FormContentItem>
          <PasswordValidation />
          <FormikSumbitButton data-cy="change-password" mt={40}>
            Change Password
          </FormikSumbitButton>
        </>
      </Form>
    </FormContentWrapper>
  );

  return (
    <Wrapper>
      <Container>
        {backgroundImagesListUI}
        <ContentWrapper>
          <ContentHeader>{flatironsFuseLogo}</ContentHeader>
          <Content>
            {textUI}
            {isValidToken && user ? (
              formUI
            ) : (
              <Div data-cy="invalid-token-description">
                Oops! There was a problem validating your token
              </Div>
            )}
          </Content>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

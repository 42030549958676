import React from "react";
import {
  CalendarContainer as BaseCalendarContainer,
  default as BaseDatePicker,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import {
  colors,
  Div,
  Span,
  TextInput,
  TextInputProps,
  theme,
} from "fuse-shared-ui";
import { DateTime } from "luxon";

const StyledDatePicker = styled(BaseDatePicker)``;

const StyledCalendarContainer = styled(BaseCalendarContainer)`
  background: ${colors.white};
  min-width: 254px;
  border: none;
  box-shadow: 4px 4px 24px rgba(215, 223, 228, 0.6);
  border-radius: 8px;
  padding: 24px;
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__header {
    padding: 0;
    background: ${colors.white};
    border: none;
  }
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    ${theme.textStyles.wlabel3}
    padding: 20px 0;
    margin-bottom: 0;
  }
  .react-datepicker__month {
    ${theme.textStyles.wbody3}
  }
  .react-datepicker__day--selected {
    color: ${colors.white};
    background: ${colors.pink500};
  }
  .react-datepicker__day--keyboard-selected {
    color: inherit;
    background: inherit;
    font-weight: bold;
  }
  .react-datepicker__day {
    border-radius: 50%;
    :hover {
      color: ${colors.white};
      background: ${colors.pink500};
    }
  }
`;

const CalendarContainer = ({ className, children }) => {
  return (
    <StyledCalendarContainer className={className}>
      {children}
    </StyledCalendarContainer>
  );
};

const Header = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const parsed = DateTime.fromJSDate(date);
  return (
    <Div>
      <Div flexColumn w100 alignStart>
        <Span label2>{parsed.year}</Span>
        <Span wbody1>
          {parsed.weekdayShort} {parsed.day} {parsed.monthShort}
        </Span>
      </Div>
      <Div mb={16} mt={16} divider />
      <Div dflex centered p={"0 4px"}>
        <Div
          clickable
          onClick={prevMonthButtonDisabled ? null : decreaseMonth}
          disabledWhen={prevMonthButtonDisabled}
        >
          {arrowLeft}
        </Div>
        <Span wbody4 grow centered>
          {parsed.monthLong} {parsed.year}
        </Span>
        <Div
          clickable
          onClick={nextMonthButtonDisabled ? null : increaseMonth}
          disabledWhen={nextMonthButtonDisabled}
        >
          {arrowRight}
        </Div>
      </Div>
    </Div>
  );
};

const Input = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    return (
      <TextInput
        {...props}
        icon={calendarIcon}
        setInputRef={ref}
        onChange={(event) => {
          props.onChange({ target: { value: event } });
        }}
      />
    );
  }
);

const formatWeekDay = (week: string) => {
  return week.substring(0, 3);
};

const DatePicker = (props: TextInputProps) => {
  const { onChange, value, ...inputProps } = props;

  return (
    <StyledDatePicker
      customInput={<Input {...inputProps} />}
      showPopperArrow={false}
      calendarContainer={CalendarContainer}
      renderCustomHeader={Header}
      selected={value ? new Date(value) : null}
      onChange={onChange}
      formatWeekDay={formatWeekDay}
      disabled={inputProps.isDisabled}
    />
  );
};

export default DatePicker;

const calendarIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="3"
      width="20"
      height="19"
      rx="3"
      stroke="#333333"
      strokeWidth="2"
    />
    <path
      d="M7 1V3"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 1V3"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 8H22"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 13H9.5"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 13H16.5"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 17H9.5"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 17H16.5"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const arrowRight = (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.816895 8.825L4.6419 5L0.816895 1.175L2.00023 1.0345e-07L7.00023 5L2.00023 10L0.816895 8.825Z"
      fill="#808080"
    />
  </svg>
);

const arrowLeft = (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.18311 1.175L2.35811 5L6.18311 8.825L4.99977 10L-0.000227928 5L4.99977 0L6.18311 1.175Z"
      fill="#808080"
    />
  </svg>
);

import { Div } from "fuse-shared-ui";
import { useRouteMatch } from "react-router";
import styled, { css } from "styled-components";
import { useNavigationContext } from "../NavigationContextProvider";
import { NavigationContent, useDisplayText } from "./common";

type SelectedIndicatorProps = {
  isSelected: boolean;
};

const SelectedIndicator = styled(Div)<SelectedIndicatorProps>`
  border-radius: 0px 8px 8px 0px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 8px;
  z-index: 1;

  ${(p) =>
    p.isSelected &&
    css`
      background: ${({ theme }) => theme.colors.black};
    `}
`;

type LabelProps = {
  isSelected: boolean;
};

const Label = styled(Div)<LabelProps>`
  ${(p) => p.theme.css.label2};
  color: ${(p) => p.theme.colors.text};
  letter-spacing: 0;
  text-transform: unset;

  @media all and (${({ theme }) => theme.media.xs}) {
    ${(p) => p.theme.css.subtitle};
  }
`;

type NavigationItemProps = {
  onClick?: () => void;
  icon: JSX.Element;
  label: string;
  path: string;
  isDisable?: boolean;
  isSelected?: boolean;
};

const NavigationItemWrapper = styled(NavigationContent)<NavigationItemProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 19.5px 16px;
  user-select: none;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-radius: 0px 8px 8px 0px;
      box-shadow: 0px 0px 0px 1px #e5dbdb;
    `}

  @media all and (${({ theme }) => theme.media.xs}) {
    align-items: center;
    flex-direction: row;
    gap: 12px;
    justify-content: flex-start;
    padding: 16px 32px;
  }
`;

const IconWrapper = styled(Div)`
  @media all and (${({ theme }) => theme.media.xs}) {
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

export const NavigationItem = ({
  icon,
  label,
  path,
  onClick,
  isDisable,
  ...props
}: NavigationItemProps) => {
  const { isOpen } = useNavigationContext();
  const { displayText } = useDisplayText();

  const match = useRouteMatch({ path });
  const isSelected = !!match;

  return (
    <NavigationItemWrapper
      pRelative
      {...{ isOpen, isSelected, onClick, isDisable }}
      {...props}
    >
      <SelectedIndicator isSelected={isSelected} />

      <IconWrapper centered>{icon}</IconWrapper>

      {isOpen && displayText && <Label isSelected={isSelected}>{label}</Label>}
    </NavigationItemWrapper>
  );
};

import {
  circle1,
  circle2,
  elipse1,
  elipse2,
  rectangle1,
} from "../../common/assets/images";
import {
  ImagesContainer,
  Elipse1,
  Elipse2,
  Rectangle1,
  Circle1,
  Circle2,
} from "./styles";

const ImagesUI = () => {
  return (
    <ImagesContainer>
      <Elipse1>{elipse1}</Elipse1>
      <Elipse2>{elipse2}</Elipse2>
      <Rectangle1>{rectangle1}</Rectangle1>
      <Circle1>{circle1}</Circle1>
      <Circle2>{circle2}</Circle2>
    </ImagesContainer>
  );
};

export default ImagesUI;

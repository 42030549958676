import { api, css, Div } from "fuse-shared-ui";
import { useAppContext } from "components/AppContextProvider";
import { Plan, useUserContext } from "components/UserContextProvider";
import { useBillingContext } from "../BillingContextProvider";
import styled from "styled-components";
import { useSegment } from "components/SegmentContextProvider";
import { useAppModalContext } from "components/AppModalContextProvider";
import { TemplateItem } from "./types";
import DowngradePlanModal from "./DowngradePlanModal";

const getTemplates = async () => {
  const { data } = await api.get(`/api/v1/templates/all`);
  return data as TemplateItem[];
};

export const useUpdatePlan = () => {
  const { setIsLoadingApp } = useAppContext();
  const { setUser, user } = useUserContext();
  const { track } = useSegment();
  const { showModal } = useAppModalContext();
  const { getSubscriptionData } = useBillingContext();
  const {
    active_organization: { plan_id },
  } = user;

  const askToUserToDeleteTemplates = (
    plan: Plan,
    templates: TemplateItem[]
  ) => {
    return new Promise<TemplateItem[]>((resolve) => {
      showModal(({ hideModal, isOpen }) => {
        const hide = (templatesToDelete: TemplateItem[]) => {
          resolve(templatesToDelete);
          hideModal();
        };
        return (
          <DowngradePlanModal
            hide={() => hide(null)}
            onDowngrade={hide}
            isOpen={isOpen}
            plan={plan}
            templates={templates}
          />
        );
      });
    });
  };

  const updatePlanBase = async (
    plan: Plan,
    templatesToDelete: TemplateItem[] = []
  ) => {
    const ids = templatesToDelete?.map((template) => template.id);
    const { data: updatedUser } = await api.put(
      `/api/v1/organizations/subscriptions/${plan.id}`,
      {
        templates_ids_to_delete: ids,
      },
      { successMsg: "Plan updated successfully" }
    );
    track("user_changed_plan", { old_plan_id: plan_id, new_plan_id: plan.id });
    setUser({ ...user, ...updatedUser });
    await getSubscriptionData();
  };

  const updatePlanWithTemplatesLimit = async (plan: Plan) => {
    const templates = await getTemplates();
    const templatesCount = templates.length;
    if (templatesCount > plan.allowed_templates) {
      const templatesToDelete = await askToUserToDeleteTemplates(
        plan,
        templates
      );
      const hasTemplatesToDelete =
        templatesToDelete && templatesToDelete.length > 0;
      return hasTemplatesToDelete
        ? await updatePlanBase(plan, templatesToDelete)
        : false;
    }
    return await updatePlanBase(plan);
  };

  const updatePlan = async (plan: Plan) => {
    try {
      if (plan.has_templates_limit) {
        return await updatePlanWithTemplatesLimit(plan);
      } else {
        setIsLoadingApp(true);
        return await updatePlanBase(plan);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingApp(false);
    }
  };

  return updatePlan;
};

export const BillingSection = styled(Div)(
  ({ theme: { colors, css: c } }) => css`
    background: ${colors.white};
    border: solid 1px ${colors.outlineInputs};
    border-radius: 8px;
    padding: 24px;
  `
);

import { useAppContext } from "components/AppContextProvider";
import { useUserContext } from "components/UserContextProvider";
import {
  Button,
  ConfirmationModal,
  Div,
  TooltipProvider,
  addToast,
  api,
  colors,
  useTableContext,
} from "fuse-shared-ui";
import { DateTime } from "luxon";
import { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { FloatingTableActions } from "../../../../common/Table/TableActions";
import { WEBHOOK_LOGS_ROUTE } from "../../common";
import TableDeleteIcon from "../TemplateColumns/components/TableColumns/components/TableDeleteIcon";
import TableEditIcon from "../TemplateColumns/components/TableColumns/components/TableEditIcon";
import { useTemplateColumns } from "../TemplateColumns/hooks";
import { launchImporter } from "../common/utils";
import { EditModal } from "./CreateTemplateFormModal/components/EditTemplateModal";
import { confirmationDeleteIcon, copyIcon, warningIcon } from "./common/icons";

const Actions = styled(Div)`
  align-items: center;
  display: flex;
  gap: 8px;
  text-align: right;
  justify-content: flex-end;
  justify-self: flex-end;
  width: 100%;
  padding-right: 8px;
`;

const ActionWrapper = styled(Div)`
  border-radius: 4px;
  margin-right: 8px;
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.blue100};
  }
  :hover {
    svg rect,
    svg path {
      stroke: black;
    }
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  width: max-content;
`;

const TableContent = ({ data, dataId }: { data: any; dataId?: string }) => (
  <Div dflex alignCenter centered {...(dataId && { "data-cy": dataId })}>
    <Div c={colors.text} wbody3 truncateText>
      {data}
    </Div>
  </Div>
);

export const tableColumns = [
  {
    header: "Name",
    width: 1,
    Content: ({ name }) => <TableContent data={name} dataId="template-name" />,
  },
  {
    header: "ID",
    width: 1.2,
    preventRowClick: true,
    Content: ({ slug }) => {
      const onClick = () => {
        addToast(`Copied ${slug} to clipboard`, "notification", {
          position: "bottom-left",
        });
        navigator.clipboard.writeText(slug);
      };
      return (
        <TooltipProvider
          data-cy="copy-clipboard"
          alignCenter
          tooltip="Copy to clipboard"
          placement="auto"
        >
          <ActionWrapper w100 onClick={onClick}>
            <Div spaceBetween>
              <Div c={colors.text} wbody3 w="10px" grow>
                <Div truncateText>{slug}</Div>
              </Div>
              <Div centered ml={8}>
                {copyIcon}
              </Div>
            </Div>
          </ActionWrapper>
        </TooltipProvider>
      );
    },
  },
  {
    header: "Columns",
    width: 0.65,
    Content: ({ columns_count }) => <TableContent data={columns_count} />,
  },
  {
    header: "Created",
    width: 1,
    Content: ({ created_at }) => (
      <TableContent data={DateTime.fromISO(created_at).toFormat("D")} />
    ),
  },
  {
    header: "Created By",
    width: 1,
    Content: ({ created_by }) => (
      <Div dflex alignCenter>
        <TableContent
          data={`${created_by?.first_name} ${created_by?.last_name}`}
        />
      </Div>
    ),
  },
  {
    header: "",
    width: 0.01,
    preventRowClick: true,
    Content: (row) => <TableActions row={row} />,
  },
];

const TableActions = ({
  row: {
    slug,
    name,
    persistence,
    columns_count,
    webhook_url,
    webhook_logs_count,
    ...rest
  },
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<
    boolean
  >(false);
  const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState<
    boolean
  >(false);
  const [
    isUnableToPreviewTemplateModal,
    setIsUnableToPreviewTemplateModalOpen,
  ] = useState<boolean>(false);
  const { setIsLoadingApp } = useAppContext();
  const { getData } = useTableContext();
  const { updateTemplate } = useTemplateColumns();

  const {
    user: { active_organization },
  } = useUserContext();
  const apiToken = active_organization.api_token;

  const successMsgUI = (
    <Div>
      <Div wbody2 dInline>
        {name} Importer&nbsp;
      </Div>
      deleted successfully
    </Div>
  );

  const history = useHistory();
  const handleViewLog = () => {
    history.push(WEBHOOK_LOGS_ROUTE, {
      from: "templates",
      payload: slug,
    });
  };

  const openDeleteTemplateModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const openEditTemplateModal = () => {
    setIsEditTemplateModalOpen(true);
  };

  const archiveTemplate = async () => {
    setIsLoadingApp(true);

    const { data } = await api.put(`/api/v1/templates/${slug}`, {
      archived: true,
    });

    if (data) {
      getData();
      setIsLoadingApp(false);
      setIsConfirmationModalOpen(false);
      addToast(successMsgUI, "success");
    }
  };

  const descriptionUI = (
    <>
      <Div data-cy="delete-importer-pop-up-description">
        Are you sure you want to delete the&nbsp;
        <Div wbody2 dInline>
          {name}
        </Div>
      </Div>
      <Div>importer? This action can’t be undone.</Div>
    </>
  );

  const unableToPreviewTemplateModalDescriptionUI = (
    <>
      <Div>We’re unable to preview {name} importer because it has&nbsp;</Div>
      <Div>no columns. Add columns to preview template.</Div>
    </>
  );

  const confirmationModalUI = (
    <ConfirmationModal
      isOpen={isConfirmationModalOpen}
      handleClose={() => setIsConfirmationModalOpen(false)}
      title={"Delete Importer"}
      description={descriptionUI}
      onSubmit={archiveTemplate}
      buttonText={"Yes, delete"}
      icon={confirmationDeleteIcon}
    />
  );

  const editTemplateModal = (
    <EditModal
      getData={getData}
      updateTemplate={updateTemplate}
      row={{ slug, name, persistence, columns_count, webhook_url, ...rest }}
      isOpen={isEditTemplateModalOpen}
      hideModal={() => setIsEditTemplateModalOpen(false)}
    />
  );

  const unableToPreviewTemplateModalUI = (
    <ConfirmationModal
      isOpen={isUnableToPreviewTemplateModal}
      handleClose={() => setIsUnableToPreviewTemplateModalOpen(false)}
      title={"Unable To Preview Importer"}
      description={unableToPreviewTemplateModalDescriptionUI}
      buttonText={"Dismiss"}
      icon={warningIcon}
      onSubmit={() => setIsUnableToPreviewTemplateModalOpen(false)}
      hasCancelButton={false}
    />
  );

  const TableLaunchLink = (
    <StyledButton
      variant="tertiary"
      isSmall
      data-cy="launch-importer"
      onClick={() => launchImporter({ slug, name, webhook_url }, apiToken)}
      iconPlacement="left"
    >
      Import Data
    </StyledButton>
  );

  const ViewLogs = (
    <StyledButton
      variant="tertiary"
      isSmall
      data-cy="view-logs"
      onClick={handleViewLog}
    >
      View Logs
    </StyledButton>
  );

  return (
    <>
      {confirmationModalUI}
      {editTemplateModal}
      {unableToPreviewTemplateModalUI}

      <FloatingTableActions>
        <Div pr={16} textAlignRight alignCenter w100>
          {webhook_logs_count > 0 ? ViewLogs : <Div p="6px 20px"></Div>}

          {TableLaunchLink}

          <TableEditIcon onClick={() => openEditTemplateModal()} />
          <TableDeleteIcon onClick={() => openDeleteTemplateModal()} />
        </Div>
      </FloatingTableActions>
    </>
  );
};

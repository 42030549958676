import { useState } from "react";
import { Helmet } from "react-helmet";

import SignUpForm from "./SignUpForm";
import CheckYourEmail from "../common/CheckYourEmail";

const SignUp = () => {
  const [isSignUpComplete, setIsSignUpComplete] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");

  return (
    <>
      <Helmet>
        <title>Sign Up - Flatirons Fuse</title>
        <meta name="title" content="Sign Up - Flatirons Fuse" />
        <meta
          name="description"
          content="Sign Up to Flatirons Fuse to start making beautiful, custom branded CSV and spreadsheet imports within minutes."
        />
      </Helmet>
      {isSignUpComplete ? (
        <CheckYourEmail email={newUserEmail} />
      ) : (
        <SignUpForm
          onCompleteForm={(email: string) => {
            setIsSignUpComplete(true);
            setNewUserEmail(email);
          }}
        />
      )}
    </>
  );
};

export default SignUp;

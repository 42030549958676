import { BlankState } from "components/common/Table/BlankState";
import { Div, Table, useTableContext } from "fuse-shared-ui";
import { EmptyIcon } from "../../../ApiCredentials/icons";
import { useLogsTableContext } from "../contexts/LogsTableContext";
import { TestId } from "../interfaces";
import { ErrorDetailsModal, LogModal } from "../modals";
import { tableColumns } from "../tableColumns";

export const MainContent = ({ testId }: TestId) => {
  const { data } = useTableContext();
  const {
    closeErrorDetail,
    closeLogDetailModal,
    logToDisplay,
    openLogDetailModal,
    errorDetailToDisplay,
  } = useLogsTableContext();

  return (
    <Div pt={data.length === 0 && "15vh"} data-cy="log-info" h100>
      {errorDetailToDisplay && (
        <ErrorDetailsModal onClose={closeErrorDetail}>
          {String(errorDetailToDisplay.message)}
        </ErrorDetailsModal>
      )}

      {logToDisplay && (
        <LogModal onClose={closeLogDetailModal} log={logToDisplay} />
      )}

      {data.length ? (
        <Table
          columns={tableColumns}
          data-testid={testId}
          paginationProps={{ pb: 0 }}
          alignColumnsWithHeader
          rowHeight={90}
          onRowClick={openLogDetailModal}
        />
      ) : (
        <BlankState
          title="No logs yet"
          description="Logs will appear here once you send a webhook."
          icon={<EmptyIcon />}
        />
      )}
    </Div>
  );
};

import { Route, Switch } from "react-router";
import { Div, WebappIframeInterface } from "fuse-shared-ui";
import ImporterIframeTest from "../ImporterIframeTest";
import ImporterTest from "../ImporterTest";

export const CYPRESS_TEST_PATHS = "/cypress-test";
const p = (path) => `${CYPRESS_TEST_PATHS}${path}`;

const TestRoutes = () => {
  return (
    <Div>
      <Switch>
        {/* for testing Importer component iframe integration */}
        <Route path={p("/importer-iframe-test")}>
          <ImporterIframeTest />
        </Route>
        {/* for cypress test related to /importer-iframe-test */}
        <Route path={p("/importer-embed")}>
          <WebappIframeInterface />
        </Route>

        {/* for testing Importer component with cypress */}
        <Route path={p("/importer-test")}>
          <ImporterTest />
        </Route>
      </Switch>
    </Div>
  );
};

export default TestRoutes;

import { useEffect, useState } from "react";

type WindowDimentions = {
  width: number | undefined;
  height: number | undefined;
};

type IsOnMobileDevice = boolean;

interface UseWindowDimensions {
  isOnSmallScreens: IsOnMobileDevice;
  windowDimensions: WindowDimentions;
}

const useWindowDimensions = (): UseWindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimentions>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isOnSmallScreens = windowDimensions.width
    ? windowDimensions.width <= 1050
    : false;

  return { windowDimensions, isOnSmallScreens };
};

export default useWindowDimensions;

import { Div } from "fuse-shared-ui";
import styled from "styled-components";

type TableActionsProps = {
  children: React.ReactNode;
};

export const FloatingTableActions = ({ children }: TableActionsProps) => {
  return (
    <TableActionsWrapper justifyEnd w100 className="shouldBeVisibleOnHover">
      <ActionsContainer pl={26} dflex data-cy="column-action-button">
        {children}
      </ActionsContainer>
    </TableActionsWrapper>
  );
};

const TableActionsWrapper = styled(Div)`
  height: 100%;
  opacity: 0;
  visibility: hidden;
`;

const ActionsContainer = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 5.15%);
  height: 98%;
  align-self: center;
`;

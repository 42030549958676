import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import styled from "styled-components";

type Props = {
  items: string[];
  value: string;
  setValue: (value: string) => void;
};

const StyledToggleItem = styled(ToggleGroupPrimitive.Item)`
  background-color: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.black};
  border: 0;
  border-radius: 4px;
  padding: 6px 21px;

  &[data-state="on"] {
    background-color: ${(p) => p.theme.colors.black};
    color: ${(p) => p.theme.colors.orange50};
  }

  ${(p) => p.theme.css.body4}
`;

export const ToggleGroup = ({ items, value, setValue }: Props) => {
  return (
    <ToggleGroupPrimitive.Root
      type="single"
      value={value}
      onValueChange={(value) => {
        if (value) setValue(value);
      }}
    >
      {items.map((item) => (
        <StyledToggleItem
          value={item}
          key={item}
          data-cy={`${item.toLowerCase()}-toggle-item`}
        >
          {item}
        </StyledToggleItem>
      ))}
    </ToggleGroupPrimitive.Root>
  );
};

import { useUserContext } from "components/UserContextProvider";
import { Div } from "fuse-shared-ui";
import { DateTime } from "luxon";
import { useState } from "react";
import TableDeleteIcon from "../../../TemplateRoutes/TemplateColumns/components/TableColumns/components/TableDeleteIcon";
import { useDeleteTableRow } from "../../../TemplateRoutes/TemplateColumns/components/TableColumns/useDeleteTableRow";
import DeleteModal from "./DeleteModal";

export const tableColumns = [
  {
    header: "Label",
    width: 0.5,
    Content: ({ name }) => <>{name}</>,
  },
  {
    header: "Last used",
    width: 0.5,
    Content: ({ last_used_at }) => (
      <>
        {last_used_at
          ? DateTime.fromISO(last_used_at).toFormat("yyyy-MM-dd HH:mm")
          : "-"}
      </>
    ),
  },
  {
    width: 0.5,
    header: "API Key",
    Content: ({ token }) => {
      return <>{token}</>;
    },
  },
  {
    width: 0.25,
    header: "",
    Content: ({ id }) => {
      const {
        user: { active_organization },
      } = useUserContext();
      const [deleteModalOpen, setDeleteModalOpen] = useState(false);
      const { deleteTableRow } = useDeleteTableRow({
        deleteUrl: `/api/v1/organizations/${active_organization.id}/api_credentials/${id}`,
        hardDelete: true,
        customMessage: "The credentials have been deleted",
      });

      const onDelete = () => {
        deleteTableRow();
        setDeleteModalOpen(false);
      };

      return (
        <Div pr={16} w100>
          {deleteModalOpen && (
            <DeleteModal
              isOpen={deleteModalOpen}
              onDelete={() => onDelete()}
              onCancel={() => setDeleteModalOpen(false)}
            />
          )}

          <TableDeleteIcon onClick={() => setDeleteModalOpen(true)} />
        </Div>
      );
    },
  },
];

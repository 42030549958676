import { DateTime } from "luxon";
import { MutableRefObject } from "react";
import {
  DuplicateValuesCollection,
  Field,
  FieldValidation,
} from "fuse-importer";
import {
  isBoolean,
  isEmail,
  isEmpty,
  isEven,
  isFloat,
  isInteger,
  isNullOrUndefined,
  isUrl,
} from "../common";

export type FieldValidator = (
  field: Field,
  fieldValue: any,
  validation?: FieldValidation,
  duplicateValues?: MutableRefObject<DuplicateValuesCollection>,
  customErrorMessage?: string
) => null | string;

export type FieldValidations = Partial<{
  [key: string]: FieldValidator;
}>;

export const fieldValidations: FieldValidations = {
  email: (field, fieldValue, validator) => {
    if (!isNullOrUndefined(fieldValue) && !isEmail(fieldValue)) {
      return validator.message ?? `${field.label} is not a valid email`;
    }
  },
  url: (field, fieldValue, validator) => {
    if (!isNullOrUndefined(fieldValue) && !isUrl(fieldValue)) {
      return validator.message ?? `${field.label} is not a valid url`;
    }
  },
  integer: (field, fieldValue, validator) => {
    if (!isNullOrUndefined(fieldValue) && !isInteger(fieldValue)) {
      return validator.message ?? `${field.label} is not a valid integer`;
    }
  },
  float: (field, fieldValue, validator) => {
    if (!isNullOrUndefined(fieldValue) && !isFloat(fieldValue)) {
      return validator.message ?? `${field.label} is not a valid float`;
    }
  },
  cannot_contain: (field, fieldValue, validator) => {
    const pattern = validator?.options?.pattern;
    if (
      !isNullOrUndefined(fieldValue) &&
      !isNullOrUndefined(pattern) &&
      `${fieldValue}`.includes(pattern)
    ) {
      return validator.message ?? `${field.label} cannot contain ${pattern}`;
    }
  },
  contain: (field, fieldValue, validator) => {
    const pattern = validator?.options?.pattern;
    if (
      !isNullOrUndefined(fieldValue) &&
      !isNullOrUndefined(pattern) &&
      !`${fieldValue}`.includes(pattern)
    ) {
      return validator.message ?? `${field.label} must contain ${pattern}`;
    }
  },
  max_length: (field, fieldValue, validator) => {
    const max_length = validator?.options?.max_length;
    if (
      !isNullOrUndefined(fieldValue) &&
      !isNullOrUndefined(max_length) &&
      `${fieldValue}`.length > parseInt(max_length, 10)
    ) {
      return (
        validator.message ??
        `${field.label} must have at most ${max_length} characters`
      );
    }
  },
  min_length: (field, fieldValue, validator) => {
    const min_length = validator?.options?.min_length;
    if (
      !isNullOrUndefined(fieldValue) &&
      !isNullOrUndefined(min_length) &&
      `${fieldValue}`.length < parseInt(min_length, 10)
    ) {
      return (
        validator.message ??
        `${field.label} must have at least ${min_length} characters`
      );
    }
  },
  length_exactly: (field, fieldValue, validator) => {
    const length_exactly = validator?.options?.length;
    if (
      !isNullOrUndefined(fieldValue) &&
      !isNullOrUndefined(length_exactly) &&
      `${fieldValue}`.length !== parseInt(length_exactly, 10)
    ) {
      return (
        validator.message ??
        `${field.label} must have exactly ${length_exactly} characters`
      );
    }
  },
  boolean: (field, fieldValue, validator) => {
    if (!isNullOrUndefined(fieldValue) && !isBoolean(fieldValue)) {
      return validator.message ?? `${field.label} is not a valid boolean`;
    }
  },
  required: (field, fieldValue) => {
    if (field.isRequired && isEmpty(fieldValue)) {
      return `${field.label} is required`;
    }
  },
  date_pattern: (field, fieldValue) => {
    if (
      !isNullOrUndefined(field.pattern) &&
      !isNullOrUndefined(fieldValue) &&
      !DateTime.fromFormat(fieldValue, field.pattern).isValid
    ) {
      const randomDate = DateTime.fromISO("1990-01-15T14:35:55.150", {
        zone: "utc",
      });
      const transformedValue = randomDate?.toFormat(field.pattern);
      const defaultMessage = `${field.label} must match the pattern ${field.pattern}`;

      return transformedValue
        ? `Must be formatted as "${field.pattern}" (e.g. ${transformedValue})`
        : defaultMessage;
    }
  },
  unique_case_sensitive: (field, fieldValue, validator, duplicateValues) => {
    if (
      !isNullOrUndefined(fieldValue) &&
      !isNullOrUndefined(duplicateValues.current)
    ) {
      fieldValue = String(fieldValue);
      let isDuplicated =
        duplicateValues.current[field.name] &&
        duplicateValues.current[field.name][fieldValue] &&
        duplicateValues.current[field.name][fieldValue].length > 1;

      const message =
        validator.message ?? `${field.label} must be unique case sensitive`;
      return isDuplicated ? message : undefined;
    }
  },
  unique_case_insensitive: (field, fieldValue, validator, duplicateValues) => {
    if (
      !isNullOrUndefined(fieldValue) &&
      !isNullOrUndefined(duplicateValues.current)
    ) {
      fieldValue = String(fieldValue);
      let isDuplicated =
        duplicateValues.current[field.name] &&
        duplicateValues.current[field.name][fieldValue.toLowerCase()] &&
        duplicateValues.current[field.name][fieldValue.toLowerCase()].length >
          1;

      const message =
        validator.message ?? `${field.label} must be unique case insensitive`;
      return isDuplicated ? message : undefined;
    }
  },
  regex: (field, fieldValue, validator) => {
    const pattern = validator?.options?.pattern;
    if (!isNullOrUndefined(fieldValue) && !isNullOrUndefined(pattern)) {
      const isValid = new RegExp(pattern, "g").test(fieldValue);
      const message = validator.message ?? `${field.label} is invalid`;
      return isValid ? undefined : message;
    }
  },
  less_than: (field, fieldValue, validator) => {
    const max = validator?.options?.max || validator?.options?.less_than;
    if (
      !isNullOrUndefined(fieldValue) &&
      !isNullOrUndefined(max) &&
      fieldValue >= max
    ) {
      return validator.message ?? `${field.label} must be lower than ${max}`;
    }
  },
  greater_than: (field, fieldValue, validator) => {
    const min = validator?.options?.min || validator?.options?.greater_than;
    if (
      !isNullOrUndefined(fieldValue) &&
      !isNullOrUndefined(min) &&
      fieldValue <= min
    ) {
      return validator.message ?? `${field.label} must be greater than ${min}`;
    }
  },
  even: (field, fieldValue, validator) => {
    if (!isNullOrUndefined(fieldValue) && !isEven(fieldValue)) {
      return validator.message ?? `${field.label} must be even`;
    }
  },
  odd: (field, fieldValue, validator) => {
    if (!isNullOrUndefined(fieldValue) && isEven(fieldValue)) {
      return validator.message ?? `${field.label} must be odd`;
    }
  },
  after_date: (field, fieldValue, validator) => {
    const min = validator?.options?.min || validator?.options?.after_date;
    if (
      !isNullOrUndefined(fieldValue) &&
      !isNullOrUndefined(field.pattern) &&
      !isNullOrUndefined(min)
    ) {
      const date = DateTime.fromFormat(fieldValue, field.pattern, {
        zone: "utc",
      });
      const minDate = parseValidationDate(min);
      if (!minDate) {
        return "Invalid min date value";
      }
      const isValid = date > minDate;
      const message =
        validator.message ??
        `${field.label} must be greater than ${minDate.toFormat(
          field.pattern
        )}`;
      return isValid ? undefined : message;
    }
  },
  before_date: (field, fieldValue, validator) => {
    const max = validator?.options?.max || validator?.options?.before_date;
    if (
      !isNullOrUndefined(fieldValue) &&
      !isNullOrUndefined(field.pattern) &&
      !isNullOrUndefined(max)
    ) {
      const date = DateTime.fromFormat(fieldValue, field.pattern, {
        zone: "utc",
      });
      const maxDate = parseValidationDate(max);
      if (!maxDate) {
        return "Invalid max date value";
      }
      const isValid = date < maxDate;
      const message =
        validator.message ??
        `${field.label} must be lower than ${maxDate.toFormat(field.pattern)}`;
      return isValid ? undefined : message;
    }
  },
} as const;

const parseValidationDate = (dateInput: Date | number | string) => {
  if (dateInput instanceof Date) {
    return DateTime.fromJSDate(dateInput, { zone: "utc" });
  } else if (typeof dateInput === "number") {
    return DateTime.fromMillis(dateInput, { zone: "utc" });
  } else if (typeof dateInput === "string") {
    return DateTime.fromISO(dateInput, { zone: "utc" });
  } else {
    return null;
  }
};

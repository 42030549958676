import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useQueryParams = (queryParams: string[]): any => {
  const res = {};
  const params = new URLSearchParams(useLocation().search);
  queryParams.forEach((p) => (res[p] = params.get(p)));
  return res;
};

export * from "./useWindowDimensions";

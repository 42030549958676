import styled from "styled-components";
import { theme } from "../../../styled/theme";
import { Div } from "../../../styled/utils";

export const ImporterSectionContainer = styled(Div)`
  display: flex;
  gap: 16px;
  width: 100%;
  height: 100%;

  @media (max-width: 1050px) {
    flex-direction: column;
  }
`;

export const ErrorCardContainer = styled(Div)`
  align-items: center;
  align-self: center;
  background: ${theme.colors.blue100};
  border-radius: 8px;
  border: 1px dashed ${theme.colors.blue500};
  display: flex;
  flex-direction: column;
  height: 550px;
  justify-content: center;
  width: 1120px;
`;

export const LoadingCardWrapper = styled(Div)`
  box-shadow: 0px 0px 12px rgba(33, 75, 167, 0.24);
  border-radius: 8px;
  max-width: 544px;
  height: 102px;
  padding-left: 30px;
  padding-right: 30px;
  background: ${(p) => p.theme.colors.white};
`;

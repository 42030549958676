import { Div, P, chevronLeftIcon, colors } from "fuse-shared-ui";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { HeaderProps } from "../interfaces";
import { DesktopFilters } from "./DesktopFilters";
import { MobileFilters } from "./MobileFilters";

const GoBackButtonLabel = styled(P)`
  color: ${colors.textPrimary};
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`;

const GoBackButton = styled(Div)`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 24px;
  width: fit-content;
`;

const FiltersContainer = styled(Div)`
  #mobile-filter {
    display: none;
  }

  @media all and (${({ theme }) => theme.media.xs}) {
    #mobile-filter {
      display: flex;
    }

    #desktop-filter {
      display: none;
    }
  }
`;

export const Header = ({ handleGoBack, filters }: HeaderProps) => (
  <Div>
    <Helmet>
      <title>Logs</title>
    </Helmet>
    <Div>
      <GoBackButton onClick={handleGoBack}>
        <Div mr={14}>{chevronLeftIcon}</Div>
        <GoBackButtonLabel data-cy="go-back" c={colors.textPrimary}>
          Go back
        </GoBackButtonLabel>
      </GoBackButton>
    </Div>
    <Div dflex spaceBetween alignCenter>
      <P data-cy="log-text" h5 c={colors.textPrimary} m={0}>
        Logs
      </P>

      <FiltersContainer dflex gap={8}>
        <DesktopFilters filters={filters} />

        <MobileFilters filters={filters} />
      </FiltersContainer>
    </Div>
  </Div>
);

import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { Div, DivCSSProps } from "../../../styled/utils";
import { SortDirection } from "../TableDataProvider";
import { chevronDown as chevronDownIcon } from "../icons";
import {
  TableViewCellContainer,
  defaultTableHeaderHeightPx,
  getColumnWidthsPx,
} from "./common";
import { TableViewProps } from "./types";

type TableViewHeaderProps = {
  isLast: boolean;
};
type TVHP = TableViewHeaderProps;
export const TableViewHeader = styled(TableViewCellContainer)<TVHP>`
  border-bottom: none;
  box-shadow: inset 0 -1px 0 ${(p) => p.theme.colors.gray300};
  background-color: ${(p) => p.theme.colors.tableHeader};
  ${(p) => p.theme.css.body2};
  color: ${(p) => p.theme.colors.text};
`;

type SortIndicatorProps = {
  direction: SortDirection;
};
export const SortIndicator = styled(Div)<SortIndicatorProps>`
  margin-left: 10px;
  margin-top: -4px;
  ${(p) =>
    p.direction === SortDirection.asc &&
    css`
      margin-top: 3px;
      transform: rotate(180deg);
    `}
  svg {
    path {
      fill: ${(p) => p.theme.colors.gray300};
    }
  }
`;

export const TableHeadersContainer = styled(Div)`
  overflow-x: hidden;
  background-color: ${(p) => p.theme.colors.tableHeader};
`;

export const TableHeadersContent = styled(Div)`
  /* We may add some padding-right here 
  since TableHeadersContainer has overflow-x: hidden */
  /* Table header scrolling is controlled by the table content,
  we need to add some padding to cover the width of the vertical scrollbars that may 
  be present in the table content. Scrollbars can vary between browsers and OSes,
  Assuming scrollbars won't be wider than 100px: */
  box-shadow: inset 0 -1px 0 ${(p) => p.theme.colors.gray300};
  padding-right: 100px;
`;

type TableHeadersProps = Pick<
  TableViewProps,
  "columns" | "sortBy" | "setSortBy" | "rowHeight" | "rowWidthScalingFactor"
> & {
  scrollLeft: number;
  height: number;
  containerRef: React.MutableRefObject<HTMLDivElement>;
  containerWidth: number;
  rowWidth: number;
} & DivCSSProps;

export const TableHeaders = ({
  scrollLeft = 0,
  columns,
  sortBy,
  rowHeight,
  containerRef,
  setSortBy,
  height = defaultTableHeaderHeightPx,
  containerWidth,
  rowWidth,
  ...props
}: TableHeadersProps) => {
  const { t } = useTranslation();

  return (
    <TableHeadersContainer ref={containerRef} w={containerWidth}>
      <TableHeadersContent dflex w={rowWidth}>
        {columns.map((column, index) => {
          const { header, Header } = column;
          const onHeaderClick = () => {
            if (!setSortBy || column.disableSort) return;
            const field = column.dataAccessor;

            if (
              sortBy?.field === field &&
              sortBy?.direction === SortDirection.asc
            ) {
              setSortBy({
                field,
                direction: SortDirection.desc,
              });
            } else {
              setSortBy({
                field,
                direction: SortDirection.asc,
              });
            }
          };

          const columnWidthPercentages = getColumnWidthsPx(columns, rowWidth);
          const columnWidthPercentage = columnWidthPercentages[index];

          const isLast = index === columns.length - 1;
          return (
            <TableViewHeader
              key={index}
              isLast={isLast}
              className={isLast ? "last-column" : ""}
              w={columnWidthPercentage}
              onClick={onHeaderClick}
              h={height}
              {...props}
            >
              <>{Header ? <Header /> : <Div truncateText>{t(header)}</Div>}</>
              {sortBy?.field === column.dataAccessor && (
                <SortIndicator direction={sortBy?.direction}>
                  {chevronDownIcon}
                </SortIndicator>
              )}
            </TableViewHeader>
          );
        })}
      </TableHeadersContent>
    </TableHeadersContainer>
  );
};

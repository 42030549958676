import { Transformation, Validation } from "./interfaces";

export const mountValidationsListing = (validations: Validation[]) => {
  return validations.map(({ message }) => `${message}`);
};

export const mountTransformationsListing = (
  transformations: Transformation[]
) => {
  return transformations.map(({ transformation_type }) =>
    `${transformation_type}`.replaceAll("_", " ")
  );
};

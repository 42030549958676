import {
  CustomAction,
  FormatRecord,
  ImporterOptions,
  OnSubmit,
  OnValidateRecord,
  TemplateHeader,
} from "fuse-importer";
import { ImporterContextProvider } from "./contexts/ImporterContextProvider";
import { ImporterWithContext } from "./ImporterWithContext";

export const ACCOUNT_ROUTES = {
  TEMPLATES: "/importers",
};
export const ACCOUNT_VIEW_PATH = "/account";
const p = (path) => `${ACCOUNT_VIEW_PATH}${path}`;

export const TEMPLATES_ROUTE = p(ACCOUNT_ROUTES.TEMPLATES);

export type ImporterProps = {
  templateSlug: string;
  apiToken: string;
  options: ImporterOptions;
  previewMode?: boolean;
  formatRecord: FormatRecord;
  onValidateRecord?: OnValidateRecord;
  dynamicColumns: TemplateHeader[];
  customActions: CustomAction[];
  onSubmit?: OnSubmit;
  onClose: () => void;
};

const Importer = ({
  templateSlug,
  apiToken,
  options,
  formatRecord,
  onValidateRecord,
  dynamicColumns,
  customActions,
  onSubmit,
  onClose,
}: ImporterProps) => {
  return (
    <ImporterContextProvider
      initialRecords={options.records}
      options={options}
      templateSlug={templateSlug}
      apiToken={apiToken}
      formatRecord={formatRecord}
      onValidateRecord={onValidateRecord}
      onClose={onClose}
      onSubmit={onSubmit}
      dynamicColumns={dynamicColumns}
      customActions={customActions}
    >
      <ImporterWithContext />
    </ImporterContextProvider>
  );
};

export * from "./common/Spreadsheet";
export * from "./common/utils";
export * from "./Review/ReviewContextProvider";
export { Importer };

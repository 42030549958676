import { useFormikContext } from "formik";
import { colors, Div, MenuItem, SelectMenu } from "fuse-shared-ui";
import { debounce } from "lodash";
import { getLuminance } from "polished";
import { useRef, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "utils";

const backgroundColorOptions = [
  {
    label: "White",
    value: "#FFFFFF",
  },
  {
    label: "Light Blue",
    value: "#F6F8FB",
  },
  {
    label: "Light Tan",
    value: "#FAF8F6",
  },
];

type CircleProps = {
  backgroundColor: string;
};

const LIGHT_COLOR_LUMINANCE = 0.8;

const getBorderColor = (color: string) => {
  if (!color) return colors.black;
  let borderColor = color;
  const luminance = getLuminance(color);

  if (luminance > LIGHT_COLOR_LUMINANCE) {
    borderColor = colors.black;
  }

  return borderColor;
};

const Circle = styled(Div)<CircleProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;
  border: ${({ backgroundColor }) =>
    `1px solid ${getBorderColor(backgroundColor)}`};
  cursor: pointer;
  display: inline-block;
  height: 24px;
  width: 24px;
`;

const DropdownWrapper = styled(Div)`
  margin-left: 5%;
  width: 400px;
`;

const ColorPickerWrapper = styled(Div)`
  align-items: center;
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.gray300};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
`;

const ColorInput = styled.input`
  visibility: hidden;
`;

type ColorPickerProps = {
  formikFieldName: string;
  title: string;
  htmlFor: string;
  onClick?: () => void;
};

const ColorPicker = ({
  formikFieldName,
  title,
  onClick = null,
  htmlFor,
}: ColorPickerProps) => {
  const { values, setFieldValue } = useFormikContext();
  const colorInputRef = useRef<HTMLInputElement>();
  const colorValue = values[formikFieldName];
  const colorInputOnClick = () => {
    colorInputRef.current.click();
  };
  const setValue = debounce(
    (value) => setFieldValue(formikFieldName, value),
    200
  );

  return (
    <ColorPickerWrapper>
      <Div wbody1>
        <label htmlFor={htmlFor} onClick={onClick || colorInputOnClick}>
          {title}
        </label>
      </Div>
      <ColorInput
        type="color"
        name={formikFieldName}
        id={formikFieldName}
        value={colorValue}
        onChange={(event) => setValue(event.target.value)}
        ref={colorInputRef}
      />
      <Circle
        backgroundColor={colorValue}
        onClick={onClick || colorInputOnClick}
        data-cy="color-circle"
      />
    </ColorPickerWrapper>
  );
};

const ColorPickerDropdown = ({
  formikFieldName,
  title,
  backgroundColorOptions,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const colorValue = values[formikFieldName];
  const isPlacementRight = useMediaQuery("(min-width: 1440px)");
  const isPlacementTop = useMediaQuery("(max-width: 1152px)");
  const placement = isPlacementRight
    ? "right"
    : isPlacementTop
    ? "top"
    : "auto";

  const onSelect = (value) => {
    setFieldValue(formikFieldName, value);
    setIsMenuOpen(false);
  };
  return (
    <>
      <ColorPicker
        title={title}
        formikFieldName={formikFieldName}
        onClick={() => setIsMenuOpen(true)}
        htmlFor={""}
      />
      <DropdownWrapper ref={setReferenceElement}>
        <SelectMenu
          referenceElement={referenceElement}
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          placement={placement}
          w={400}
        >
          {backgroundColorOptions.map((o, index) => (
            <Div key={index} data-cy="background-color-options">
              <MenuItem
                isSelected={colorValue === o.value}
                onClick={() => onSelect(o.value)}
                spaceBetween
              >
                {o.label}
                <Circle
                  data-cy="background-color-value"
                  backgroundColor={o.value}
                />
              </MenuItem>
            </Div>
          ))}
        </SelectMenu>
      </DropdownWrapper>
    </>
  );
};

const ColorPickers = () => {
  const colorPickers = [
    {
      title: "Primary Color",
      id: "primary_color",
    },
    {
      title: "Secondary Color",
      id: "secondary_color",
    },
    {
      title: "Border Color",
      id: "highlight1",
    },
    {
      title: "Content Color",
      id: "highlight2",
    },
  ];

  return (
    <Div dflex flexColumn gap={16} mt={22}>
      {colorPickers.map((picker) => (
        <ColorPicker
          key={picker.id}
          title={picker.title}
          formikFieldName={picker.id}
          htmlFor={picker.id}
        />
      ))}

      <ColorPickerDropdown
        title="Background"
        formikFieldName="background"
        backgroundColorOptions={backgroundColorOptions}
      />
    </Div>
  );
};

export default ColorPickers;

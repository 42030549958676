import React, { createContext, useContext, useRef, useState } from "react";
import { css } from "styled-components";
import { useImporterContext } from "../../../../contexts/ImporterContextProvider";
import { parseValue } from "../../common";
import { useDataSetContext } from "../../DataSetContextProvider";
import { useFilteredDataContext } from "../../FilteredDataContextProvider";
import { useSpreadsheetContext } from "../../SpreadsheetContextProvider";
import { useDataSetManipulation } from "../../SpreadsheetContextProvider/useDataSetManipulation";
import { useValidationContext } from "../../ValidationContextProvider";

// 1x1 transparent GIF to prevent drag image from showing
const img = new Image();
img.src =
  "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

type SelectionProps = {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: () => void;
  onDragOver: () => void;
  onBlur: () => void;
  draggable: boolean;
};

type State = {
  isSelected: boolean;
  selectedCellStyles: ReturnType<typeof css>;
  selectionProps: SelectionProps | {};
};
const CellContext = createContext<State>({} as State);

type Props = {
  children: JSX.Element;
  rowIndex: number;
  colIndex: number;
};
export const CellContextProvider = ({
  children,
  rowIndex,
  colIndex,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const {
    isReadOnly,
    selectedCellRect,
    setSelectedCellRect,
  } = useSpreadsheetContext();

  const isSelected = (() => {
    const { start, end } = selectedCellRect;
    const isRowSelected =
      rowIndex >= start.rowIndex && rowIndex <= end.rowIndex;
    const isColSelected =
      colIndex >= start.colIndex && colIndex <= end.colIndex;
    return isRowSelected && isColSelected;
  })();

  const selectedCellsCount = (() => {
    const { start, end } = selectedCellRect;
    return (
      (end.rowIndex - start.rowIndex + 1) * (end.colIndex - start.colIndex + 1)
    );
  })();

  const updateSelectionRect = (newRowIndex, newColIndex) => {
    setSelectedCellRect((prev) => {
      return {
        ...prev,
        start: {
          rowIndex: Math.min(prev.initialCell.rowIndex, newRowIndex),
          colIndex: Math.min(prev.initialCell.colIndex, newColIndex),
        },
        end: {
          rowIndex: Math.max(prev.initialCell.rowIndex, newRowIndex),
          colIndex: Math.max(prev.initialCell.colIndex, newColIndex),
        },
      };
    });
  };

  const onClick = (e) => {
    if (e.shiftKey) {
      updateSelectionRect(rowIndex, colIndex);
    } else {
      setIsFocused(true);
      setSelectedCellRect({
        start: { rowIndex, colIndex },
        end: { rowIndex, colIndex },
        initialCell: { rowIndex, colIndex },
      });
    }
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  const onDragOver = () => {
    if (isUpdatingSelection.current) return;
    isUpdatingSelection.current = true;
    setTimeout(() => {
      isUpdatingSelection.current = false;
      updateSelectionRect(rowIndex, colIndex);
    }, 50);
  };

  const onDragStart = (e) => {
    e.dataTransfer.setDragImage(img, -10, -10);
    setSelectedCellRect({
      start: { rowIndex, colIndex },
      end: { rowIndex, colIndex },
      initialCell: { rowIndex, colIndex },
      editMode: true,
    });
  };

  const isUpdatingSelection = useRef(false);

  const onDragEnd = () => {
    setSelectedCellRect((p) => ({ ...p, editMode: false }));
  };

  const selectedCellStyles = css`
    ${(p) => {
      const { start, end, initialCell } = selectedCellRect;
      const isTopEdge = rowIndex === start.rowIndex;
      const isBottomEdge = rowIndex === end.rowIndex;
      const isLeftEdge = colIndex === start.colIndex;
      const isRightEdge = colIndex === end.colIndex;
      const withDashedBorder = selectedCellRect.isDashed;
      const isInitialCell =
        rowIndex === initialCell?.rowIndex &&
        colIndex === initialCell?.colIndex;

      // add selection rect border
      const boxShadow = [
        isTopEdge ? `inset 0 1px 0 ${p.theme.colors.blue500}` : "",
        isRightEdge
          ? `inset -2px 0 0 ${p.theme.colors.blue500}`
          : `inset -1px 0 0 ${p.theme.colors.gray300}`,
        isBottomEdge ? `inset 0 -1px 0 ${p.theme.colors.blue500}` : "",
        isLeftEdge ? `inset 1px 0 0 ${p.theme.colors.blue500}` : "",
      ]
        .filter(Boolean)
        .join(", ");

      const isInSelectionRect = selectedCellsCount > 1 && isSelected;
      return (
        isInSelectionRect &&
        css`
          background-color: ${p.theme.colors.blue100};
          box-shadow: ${boxShadow};
          ${withDashedBorder &&
          css`
            box-shadow: inset -1px 0 0 ${(p) => p.theme.colors.gray300};
            position: relative;
            &::before {
              content: "";
              position: absolute;
              top: ${isTopEdge ? "0" : "-2.5px"};
              right: ${isRightEdge ? "0" : "-2.5px"};
              bottom: ${isBottomEdge ? "0" : "-2.5px"};
              left: ${isLeftEdge ? "0" : "-2.5px"};
              border: 2.5px dashed ${p.theme.colors.blue500};
            }
          `}
          ${isInitialCell &&
          css`
            box-shadow: inset 0 0 0 2px ${p.theme.colors.blue500};
          `}
        `
      );
    }}
  `;

  const selectionProps: SelectionProps | {} = isReadOnly
    ? {}
    : {
        onClick,
        onDragStart,
        onDragEnd,
        onDragOver,
        onBlur,
        draggable: !isFocused,
      };

  return (
    <CellContext.Provider
      value={{
        isSelected: selectedCellsCount > 1 ? isSelected : false,
        selectionProps,
        selectedCellStyles,
      }}
    >
      {children}
    </CellContext.Provider>
  );
};

export const useCellContext = () => useContext(CellContext);

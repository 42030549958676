import React, {
  MutableRefObject,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Record, RecordDataSet } from "fuse-importer";
import { rowIsEmpty } from "../SpreadsheetContextProvider/utils";
import { useEventManagerContext } from "../../../../common/EventManagerContextProvider";

export type State = {
  getRecord: (recordId: string) => Record;
  setRecord: (recordId: string, record: Record) => Record;
  deleteRecord: (recordId: string) => void;
  dataSet: MutableRefObject<RecordDataSet>;
  dataSetLength: MutableRefObject<number>;
  emptyRecordIds: MutableRefObject<Set<string>>;
};

export const DataSetContext = createContext<State>({} as State);

export const DataSetContextProvider = ({ initialDataSet, children }) => {
  const dataSet = useRef<RecordDataSet>(initialDataSet);
  const dataSetLength = useRef(Object.keys(dataSet.current).length);
  const emptyRecordIds = useRef(new Set<string>());

  const reset = () => {
    emptyRecordIds.current = new Set();
  };

  useEffect(() => {
    reset();
    // without the hook, with the changes on where the context would
    // live, the dataSet was not being correctly updated for the Match step
    dataSet.current = initialDataSet;
    dataSetLength.current = Object.keys(initialDataSet).length;
  }, [initialDataSet]);

  const getRecord = (recordId: string) => {
    return dataSet.current[recordId];
  };

  const deleteRecord = (recordId: string) => {
    if (dataSet.current[recordId]) {
      dataSetLength.current -= 1;
    }
    delete dataSet.current[recordId];
  };

  const setRecord = (recordId: string, record: Record) => {
    if (!dataSet.current[recordId]) {
      dataSetLength.current += 1;
    }

    if (rowIsEmpty(record)) {
      emptyRecordIds.current.add(recordId);
    } else {
      emptyRecordIds.current.delete(recordId);
    }

    dataSet.current[recordId] = record;
    return getRecord(recordId);
  };

  const value = {
    getRecord,
    setRecord,
    deleteRecord,
    dataSet,
    dataSetLength,
    emptyRecordIds,
  };

  return (
    <DataSetContext.Provider value={value}>{children}</DataSetContext.Provider>
  );
};

export const useDataSetContext = () => useContext(DataSetContext);

import { Div, FormInputLabel, Label } from "fuse-shared-ui";
import { ChangeEventHandler, InputHTMLAttributes, ReactNode } from "react";
import styled from "styled-components";

const StyledLabel = styled(Label)`
  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--form-background);
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 18px;
    height: 18px;
    border: 2px solid ${(props) => props.theme.colors.gray500};
    border-radius: 100%;

    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 4px solid black;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: white;
  }

  input[type="radio"]:checked {
    border-color: black !important;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid ${(props) => props.theme.colors.gray500};
    outline-offset: 0px;
  }

  input[type="radio"]:disabled {
    opacity: 0.5;
  }
`;

export interface RadioButtonProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  description?: ReactNode;
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string | number | readonly string[] | undefined;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  description,
  name,
  onChange,
  value,
  ...props
}) => {
  const handleChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <StyledLabel alignStart p={8} gap={8}>
      <input
        {...props}
        name={name}
        type="radio"
        onChange={handleChange}
        value={value}
      />
      <Div flexColumn gap={8}>
        {label && <FormInputLabel ml={8}>{label}</FormInputLabel>}
        {description ? <Div ml={8}>{description}</Div> : null}
      </Div>
    </StyledLabel>
  );
};

import { Div } from "fuse-shared-ui";
import { useNavigationContext } from "../../NavigationContextProvider";
import { NavigationItem } from "../NavigationItem";
import { CloseIcon } from "../icons";
import { useNavigation } from "./useNavigation";

export const MobileUI = () => {
  const { navItems } = useNavigation();
  const { isOpen, setIsOpen } = useNavigationContext();

  if (!isOpen) return null;

  return (
    <Div dflex flexColumn alignCenter>
      <Div w100 dflex justifyEnd>
        <Div p="12px 17px" onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </Div>
      </Div>

      <Div dflex flexColumn gap={12} w100>
        {navItems.map((item) => (
          <NavigationItem
            data-cy={item?.dataCy}
            label={item.label}
            path={item.path}
            icon={item.icon}
            onClick={() => {
              item.onClick();
              setIsOpen(false);
            }}
            key={item.label}
          />
        ))}
      </Div>
    </Div>
  );
};

import { Record } from "fuse-importer";
import { isEmpty } from "../common";

export const hasValueInAnyField = (record: Record): boolean => {
  return Object.entries(record)
    .filter(([key]) => key !== "_meta")
    .some(([key, value]) => {
      if (typeof value === "string") {
        return value.trim() !== "";
      }
      return value !== null && value !== undefined;
    });
};

export const rowIsEmpty = (row: Record) => {
  return Object.keys(row).every((key) => {
    if (key === "_meta") return true;
    return isEmpty(row[key]);
  });
};

import { CardElement } from "@stripe/react-stripe-js";
import { StripeCardCvcElementOptions } from "@stripe/stripe-js";
import { FormikFieldErrorMessage } from "components/common/form/fieldHelpers";
import { Div, FormInputLabel } from "fuse-shared-ui";
import { useState } from "react";
import styled, { css } from "styled-components";

const CardElementContainer = styled(Div)<any>`
  padding: 20px 16px;
  border-radius: 8px;
  -moz-appearance: textfield;
  background-color: ${(p) => p.theme.colors.white};
  border: solid 1px ${(p) => p.theme.colors.gray300};

  ${(p: any) =>
    p.inputFocused &&
    css`
      border: solid 2px ${(p) => p.theme.colors.blue500};
    `}
  ${(p: any) =>
    p.hasError &&
    css`
      border: solid 2px ${(p) => p.theme.colors.error};
    `}
`;

const cardElementOptions: StripeCardCvcElementOptions & {
  hidePostalCode: boolean;
} = {
  style: {
    base: {
      color: "#1C1C1C",
      fontFamily: "Montserrat",
      lineHeight: "22px",
      fontWeight: "400",
      fontSize: "16px",
      "::placeholder": {
        color: "#7f7f7f",
      },
      fontSmoothing: "antialiased",
      iconColor: "#597ACD",
    },
    invalid: {
      iconColor: "#EA2D36",
      color: "#EA2D36",
    },
    complete: {
      iconColor: "#209135",
    },
  },
  hidePostalCode: true,
};

type PropsTypes = {
  onChange: (e: any) => void;
  onBlur: () => void;
  error: any;
};

const CardField = ({
  onChange: handleCardChange,
  onBlur,
  error,
}: PropsTypes): JSX.Element => {
  const [cardFieldFocused, setCardFieldFocused] = useState(false);

  const handleBlur = () => {
    setCardFieldFocused(false);
    onBlur();
  };

  return (
    <>
      <FormInputLabel>Card Information*</FormInputLabel>
      <CardElementContainer
        mt={8}
        hasError={!!error && !cardFieldFocused}
        inputFocused={cardFieldFocused}
        data-cy="cardField"
      >
        <CardElement
          options={cardElementOptions}
          onFocus={() => setCardFieldFocused(true)}
          onBlur={handleBlur}
          onChange={handleCardChange}
        />
      </CardElementContainer>
      <Div h={24} alignCenter>
        {error && error?.type !== "incomplete" && (
          <FormikFieldErrorMessage mt={5} helperText>
            {error.message}
          </FormikFieldErrorMessage>
        )}
        {error?.type === "incomplete" && !cardFieldFocused && (
          <FormikFieldErrorMessage mt={5} helperText>
            {error.message}
          </FormikFieldErrorMessage>
        )}
      </Div>
    </>
  );
};

export default CardField;

import { User } from "components/UserContextProvider";
import { fields } from "./fields";

export const getObjectDiff = (obj1, obj2) => {
  const diff = {};
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) diff[key] = obj2[key];
  }
  return diff;
};

export const concatenateKeys = (listNames) => {
  if (listNames.length === 1) return listNames[0];
  else if (listNames.length === 2) return `${listNames[0]} and ${listNames[1]}`;
  else
    return `${listNames.slice(0, -1).join(", ")}, and ${
      listNames[listNames.length - 1]
    }`;
};

export const getUserValues = ({
  first_name,
  last_name,
  email,
}: Partial<User>) => ({
  first_name,
  last_name,
  email,
});

export const successMessage = (valuesDiff) => {
  let message =
    concatenateKeys(
      Object.keys(valuesDiff).map((item) =>
        fields.find((field) => field.name === item).label.replace("*", "")
      )
    ) + " updated successfully.";
  if (valuesDiff["email"]) message += " Please confirm your new email";
  return message;
};

import styled, { css } from "styled-components";
import { colors, Div } from "../../../styled";
import { useTranslation } from "react-i18next";

export const MAX_VISIBLE_ROWS = 5;

const successIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5ZM4.94194 5.53291C4.69786 5.28883 4.30214 5.28883 4.05806 5.53291C3.81398 5.77699 3.81398 6.17272 4.05806 6.4168L5.11872 7.47746C5.3628 7.72153 5.75852 7.72153 6.0026 7.47746L8.12392 5.35614C8.368 5.11206 8.368 4.71633 8.12392 4.47225C7.87984 4.22817 7.48412 4.22817 7.24004 4.47225L5.56066 6.15163L4.94194 5.53291Z"
      fill={colors.green300}
    />
  </svg>
);

const warningIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5ZM6 2.875C6.34518 2.875 6.625 3.15482 6.625 3.5V6C6.625 6.34518 6.34518 6.625 6 6.625C5.65482 6.625 5.375 6.34518 5.375 6V3.5C5.375 3.15482 5.65482 2.875 6 2.875ZM6 7.375C6.34518 7.375 6.625 7.65482 6.625 8V8.25C6.625 8.59518 6.34518 8.875 6 8.875C5.65482 8.875 5.375 8.59518 5.375 8.25V8C5.375 7.65482 5.65482 7.375 6 7.375Z"
      fill={colors.orange500}
    />
  </svg>
);

const Wrapper = styled(Div)`
  padding: 2px 7px;
  ${(p) => {
    const c = p.theme.css;
    return [c.label3, c.alignCenter, c.selectNone];
  }};
`;

const UnmatchedIndicator = styled(Wrapper)(
  ({ theme: { colors, css: c } }) => css`
    border: 1px solid ${(p) => p.theme.colors.warning01};
    box-sizing: border-box;
    border-radius: 24px;
  `
);

export const MatchedIndicatorUI = () => {
  const { t } = useTranslation("match");
  return (
    <Wrapper>
      <Div centered>{successIcon}</Div>
      <Div ml={8}>{t("columns.matched")}</Div>
    </Wrapper>
  );
};

export const UnmatchedIndicatorUI = () => {
  const { t } = useTranslation("match");
  return (
    <UnmatchedIndicator>
      <Div centered>{warningIcon}</Div>
      <Div ml={8} data-cy="unmatched">
        {t("columns.unmatched")}
      </Div>
    </UnmatchedIndicator>
  );
};

import { useSegment } from "components/SegmentContextProvider";
import { addToast, api, Div } from "fuse-shared-ui";
import { useCallback, useRef, useState } from "react";
import { getHeaders, getValuesOnArrayPosition } from "./utils";

export type UseTemplateFormArgs = {
  hideModal: () => void;
  onSubmit: (data: any) => void;
  templateId?: string;
};

type UseCreateColumnsFromFile = {
  hideModal: () => void;
};

export function useTemplateForm({ onSubmit, hideModal }: UseTemplateFormArgs) {
  const { track } = useSegment();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isManualCreation, setIsManualCreation] = useState(false);
  const templateId = useRef("");

  const onFormSubmit = useCallback(async (values, shouldRedirect = true) => {
    setIsLoading(true);
    try {
      const { data } = await api.post("/api/v1/templates", values, {
        successMsg: "Importer created successfully",
      });

      if (data) {
        const templateSlug = data?.slug;

        if (shouldRedirect) {
          onSubmit(templateSlug);
        }
        track("user_created_template", {
          template_id: templateSlug,
          template_name: values.name,
          template_hosting: values.hosting,
          persistence: values.persistence,
        });

        return data;
      }
    } catch (error) {
      if (error.response?.data?.errors.toLowerCase().includes("current plan")) {
        const message = (
          <Div>
            <a data-cy="upgradeLinkMessage" href="/account/billing">
              {" "}
              Click here
            </a>{" "}
            to upgrade plans.
          </Div>
        );
        addToast(message, "notification");
      }
    } finally {
      setIsLoading(false);
      if (shouldRedirect) {
        hideModal();
      }
    }
  }, []);

  const onClose = useCallback(() => hideModal(), []);

  const onContinue = useCallback(() => setActiveTabIndex(1), []);

  const onCancel = useCallback(
    () => (activeTabIndex === 0 ? onClose() : setActiveTabIndex(0)),
    [onClose, activeTabIndex]
  );

  return {
    activeTabIndex,
    isLoading,
    onFormSubmit,
    onClose,
    onContinue,
    onCancel,
    setIsManualCreation,
    isManualCreation,
    templateId,
  };
}

export function useCreateColumnsFromFile({
  hideModal,
}: UseCreateColumnsFromFile) {
  const [isCreatingColumns, setIsCreatingColumns] = useState(false);

  const onCreateColumns = async (data: string[][], templateSlug: string) => {
    setIsCreatingColumns(true);
    const headers = getHeaders(data);
    const content = data.slice(1, 11);

    const mapHeadersWithContent = headers.map((header, index) => {
      return {
        header,
        content: getValuesOnArrayPosition(content, index),
      };
    });

    await onFormSubmit(mapHeadersWithContent, templateSlug);
  };

  const onFormSubmit = useCallback(async (values, templateId: string) => {
    try {
      await api.post(
        `/api/v1/templates/${templateId}/columns/create_columns_from_file`,
        values
      );
      addToast("Columns added", "success");
    } catch (error) {
      addToast(
        "An error happened when creating columns, try again later.",
        "error"
      );
    } finally {
      setIsCreatingColumns(false);
      hideModal();
    }
  }, []);

  return {
    isCreatingColumns,
    onFormSubmit,
    onCreateColumns,
  };
}

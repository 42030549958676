export const FilterIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0306 3.55469H4.96941C4.19645 3.55469 3.7354 4.40604 4.16417 5.04164L6.70714 8.36688C6.91911 8.6811 7.03222 9.0503 7.03222 9.42794V11.9644C7.03222 12.3904 7.55344 12.6038 7.85827 12.3026L8.82605 11.3461C8.9168 11.2564 8.96778 11.1348 8.96778 11.008V9.42794C8.96778 9.0503 9.08089 8.6811 9.29286 8.36688L11.8358 5.04164C12.2646 4.40604 11.8036 3.55469 11.0306 3.55469Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { useSegment } from "components/SegmentContextProvider";
import { useCallback } from "react";

export const useFuseDocumentation = () => {
  const { track } = useSegment();
  const open = useCallback(() => {
    window.open("https://fuse-docs.flatirons.com");
    track("user_visited_documentation", {});
  }, []);
  return { open };
};

import React from "react";
import styled, { css } from "styled-components";
import { Div, DivCSSProps } from "fuse-shared-ui";

const Container = styled(Div)`
  height: 20px;
  width: 20px;
  border: 1px solid ${(props) => props.theme.colors.gray400};
  border-radius: 4px;
  ${(props: any) =>
    props.isChecked &&
    css`
      border: 1px solid white;
      background-color: ${(props) => props.theme.colors.black};
    `};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

type Props = {
  isChecked: boolean;
  icon?: any;
} & DivCSSProps;
const Checkbox = ({ isChecked, icon = null, ...props }: Props) => {
  return (
    <Container {...{ isChecked, ...props }}>
      <Div centered>{isChecked && (icon || checkIcon)}</Div>
    </Container>
  );
};

const checkIcon = (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.10254 4.74355L5.2051 8.84612L12.8974 1.15381"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Checkbox;

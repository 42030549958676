import { useSegment } from "components/SegmentContextProvider";
import { api, USER_KEY } from "fuse-shared-ui";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { identifyInspectlet } from "utils";
import { AppLoading } from "./AppLoading";
import {
  AppModalContextProvider,
  useAppModalContext,
} from "./AppModalContextProvider";
import { RevokedInvitationModal } from "./Routes/SessionRoutes/AcceptInvitation/RevokedInvitationModal";

export interface Price {
  cents: number;
  currency_iso: string;
}

export interface Plan {
  id: number;
  title: string;
  description: string;
  price: Price;
  monthly_price: Price;
  recurrence_period: "year" | "month";
  stripe_product_id: string;
  lookup_key: string;
  stripe_price_lookup_key: string;
  included_cells: number;
  has_cells_limit: boolean;
  has_templates_limit: boolean;
  allow_style_customization: boolean;
  allowed_templates: number;
  format_price: string;
  format_monthly_price: string;
  version: string;
}

export interface ActiveSubscription {
  id: number;
  stripe_subscription_id: string;
  status: string;
  plan: Plan;
}

export interface Organization {
  id: number;
  name: string;
  website: string;
  api_token: string;
  data_retention_days: number;
  trial_mode: boolean;
  plan: Plan;
  plan_id: number;
  active_subscription: ActiveSubscription;
}

export type OnboardingState = {
  onboarding_create_a_template_step?: boolean;
  onboarding_try_it_out_step?: boolean;
  onboarding_customize_import_step?: boolean;
};

export type User = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  invited_by?: User;
  sign_in_count: number;
  active_organization: Organization;
  organizations: Organization[];
  token: string;
  was_recently_created?: boolean;
  [key: string]: any;
} & OnboardingState;

export type State = {
  user: Partial<User>;
  token: string;
  isAuthenticated: boolean;
  canOrganizationCustomize: boolean;
  confirmUserEmail: (confirmationToken: any) => Promise<User>;
  refreshUser: () => Promise<void>;
  signOut: () => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  setUser: (userData: Partial<User>) => void;
  updateOnboarding: (onboarding: OnboardingState) => void;
  githubSignIn: (code: any) => Promise<void>;
  externalAuthSignIn: (
    authProvider: string,
    code: string,
    state: string
  ) => Promise<void>;
  switchToOrganizationById: (orgId: number) => void;
};

const ls = typeof window !== "undefined" ? localStorage : null;

const getUserData = ({ data, headers }) => {
  const lsUser = JSON.parse(ls.getItem(USER_KEY));
  return {
    ...data,
    ...lsUser,
    token: headers.authorization.replace("Bearer ", ""),
  } as User;
};

const getUserFromLocalStorage = () => {
  return JSON.parse(ls?.getItem(USER_KEY)) as User;
};

export const UserContext = React.createContext<State>({} as State);

const findOrganizationById = (user: Partial<User>, orgId: number) => {
  return user?.organizations?.find((org) => org.id === orgId);
};

export const UserContextProviderBase = ({ children }) => {
  const [user, _setUser] = useState<Partial<User>>();
  const { identify, track } = useSegment();
  const { showModal } = useAppModalContext();
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = !!user?.token;

  useEffect(() => {
    const loadUser = async () => {
      try {
        setIsLoading(true);
        const user = getUserFromLocalStorage();
        if (user && user.token) {
          const currentUser = await getCurrentUser(user.token);
          await setAndCheckUserActiveOrganization({
            ...currentUser,
            token: user.token,
          });
        }
      } catch (e) {
        signOut();
      } finally {
        setIsLoading(false);
      }
    };
    loadUser();
    const handleStorageChange = (event) => {
      if (event.key === USER_KEY) {
        const updatedUser = JSON.parse(event.newValue);
        _setUser(updatedUser);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (user) {
      const userId = user?.id ? `${user?.id}` : null;
      const firstName = user?.first_name;
      const lastName = user?.last_name;
      const identity = {
        name: firstName ? `${firstName} ${lastName}` : undefined,
        email: user?.email,
        company_name: user?.active_organization?.name,
      };
      identifyInspectlet(identity, userId);
      identify(userId, identity);
    }
  }, [user]);

  const setAndCheckUserActiveOrganization = useCallback(async (user: User) => {
    if (!user.active_organization) {
      showRevokedInvitationModal();
      if (user.organizations?.length > 0) {
        await setUserAndSwitchToOrganization(user, user.organizations[0]);
      } else {
        setUser(null);
      }
    } else {
      setUser(user);
    }
  }, []);

  const showRevokedInvitationModal = useCallback(() => {
    showModal(({ isOpen, hideModal }) => {
      return <RevokedInvitationModal isOpen={isOpen} hide={hideModal} />;
    });
  }, []);

  const setUser = (userData: Partial<User>) => {
    ls?.setItem(USER_KEY, userData ? JSON.stringify(userData) : null);
    _setUser(userData);
  };

  const setUserAndActiveOrganization = (
    user: Partial<User>,
    organization?: Organization
  ) => {
    setUser({ ...user, active_organization: organization });
  };

  const githubSignIn = async (code) => {
    try {
      const res = await api.post("/users/sessions", {
        code,
      });
      const userData = getUserData(res);
      if (userData?.was_recently_created) {
        track("user_signup_github", {
          company_name: userData?.active_organization?.name,
          email: userData?.email,
          name: `${userData?.first_name} ${userData?.last_name}`,
        });
      }
      setUser(userData);
    } catch (error) {
      //
    }
  };

  const externalAuthSignIn = async (authProvider, code, state) => {
    try {
      const res = await api.post(
        `/users/auth/${authProvider}/callback?code=${code}&state=${state}`
      );
      const userData = getUserData(res);
      if (userData?.was_recently_created) {
        track(`user_signup_${authProvider}`, {
          company_name: userData?.active_organization?.name,
          email: userData?.email,
          name: `${userData?.first_name} ${userData?.last_name}`,
        });
      }
      setUser(userData);
    } catch (error) {
      //
    }
  };

  const setUserAndSwitchToOrganization = useCallback(
    async (user: Partial<User>, organization: Organization) => {
      if (!user || !organization) return;
      try {
        setIsLoading(true);
        await api.post(
          `/api/v1/organizations/${organization.id}/switches`,
          {},
          {
            authToken: user.token,
          }
        );
        setUserAndActiveOrganization(user, organization);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const switchToOrganizationById = useCallback(
    async (orgId: number) => {
      const organization = findOrganizationById(user, orgId);
      if (organization) {
        setUserAndSwitchToOrganization(user, organization);
      }
    },
    [user]
  );

  const signIn = async (email, password) => {
    try {
      const res = await api.post("/users/sessions", {
        user: { email, password },
      });
      await setAndCheckUserActiveOrganization(getUserData(res));
    } catch (error) {
      //
    }
  };

  const confirmUserEmail = async (confirmationToken) => {
    const res = await api.post(
      `/users/confirmations?confirmation_token=${confirmationToken}`
    );
    const userData = getUserData(res);
    await setAndCheckUserActiveOrganization(userData);
    return userData;
  };

  const getCurrentUser = async (authToken?: string) => {
    const { data } = await api.get("/users/sessions/_me", {
      authToken: authToken,
    });
    return data as User;
  };

  const refreshUser = async () => {
    if (!isAuthenticated) return;
    try {
      const currentUser = await getCurrentUser();
      const data = { ...user, ...currentUser };
      await setAndCheckUserActiveOrganization(data);
    } catch (err) {
      console.error(err);
      signOut();
    }
  };

  const signOut = async () => {
    setUser(null);
  };

  const updateOnboarding = useCallback(
    async (onboarding: OnboardingState) => {
      const hasDifference = Object.keys(onboarding).reduce(
        (hasDiff, key) => hasDiff || onboarding[key] !== user[key],
        false
      );
      if (hasDifference) {
        setUser({ ...user, ...onboarding });
        try {
          await api.put(`/users/${user.id}`, onboarding);
        } catch (err) {
          console.error(err);
        }
      }
    },
    [user]
  );

  const canOrganizationCustomize =
    user?.active_organization?.plan?.allow_style_customization;

  const value = {
    user,
    token: user?.token,
    isAuthenticated,
    canOrganizationCustomize,
    switchToOrganizationById,
    confirmUserEmail,
    refreshUser,
    signOut,
    signIn,
    setUser,
    githubSignIn,
    externalAuthSignIn,
    updateOnboarding,
  };
  return (
    <UserContext.Provider value={value}>
      {isLoading ? <AppLoading /> : children}
    </UserContext.Provider>
  );
};

export const UserContextProvider = ({ children }) => {
  return (
    <AppModalContextProvider>
      <UserContextProviderBase>{children}</UserContextProviderBase>
    </AppModalContextProvider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (typeof context === "undefined") {
    throw new Error("useSession must be used within a SessionContext");
  }
  return context;
};

import { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, colors, Div, Table, TableDataProvider } from "fuse-shared-ui";
import { tableColumns } from "./tableColumns";
import InviteUserModal from "./InviteUserModal";
import { Header } from "components/common/Header";
import { SectionContainer } from "components/common/SectionContainer";

const TeamMembers = ({ testId }: { testId?: string }) => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const headerUI = (
    <>
      <Helmet>
        <title>Flatirons Fuse - Team</title>
      </Helmet>

      <Header
        testID="teamTitle"
        title="Team"
        rightContent={
          <Button
            data-cy="invite-member"
            isSmall
            onClick={() => setIsInviteModalOpen(true)}
          >
            Invite Member
          </Button>
        }
      />
    </>
  );

  return (
    <SectionContainer c={colors.gray800}>
      {headerUI}
      <Div mt={24}>
        <TableDataProvider dataUrl={"/api/v1/organizations/invitations"}>
          <InviteUserModal
            isOpen={isInviteModalOpen}
            onClose={() => setIsInviteModalOpen(false)}
          />
          <Table columns={tableColumns} data-testid={testId} disableSorting />
        </TableDataProvider>
      </Div>
    </SectionContainer>
  );
};

export default TeamMembers;

import { Div, colors } from "fuse-shared-ui";
import React from "react";
import styled from "styled-components";
import { NavigationContent } from "../common";
import { OrganizationAvatar } from "./OrganizationAvatar";
import { useUserMenu } from "./useUserMenu";

type MobileUIProps = {
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserNameContainer = styled(Div)`
  max-width: 50vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MobileUI = ({ setIsDropdownOpen }: MobileUIProps) => {
  const {
    firstName,
    lastName,
    organizationName,
    isOpen,
    displayText,
  } = useUserMenu();

  return (
    <NavigationContent
      p="16px 12px 16px 28px"
      isOpen={isOpen}
      onClick={() => setIsDropdownOpen(true)}
    >
      <Div centered>
        <OrganizationAvatar name={organizationName} />

        {isOpen && displayText && (
          <>
            <Div ml={12} mr={12} c={colors.text}>
              <UserNameContainer wbody4>
                {firstName} {lastName}
              </UserNameContainer>

              <UserNameContainer helperText>
                {organizationName}
              </UserNameContainer>
            </Div>
          </>
        )}
      </Div>
    </NavigationContent>
  );
};

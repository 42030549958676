import { useUserContext } from "components/UserContextProvider";
import { Button, Div } from "fuse-shared-ui";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { IMPORTER_STYLE_PREFERENCES_ROUTE } from "../common";
import { ContentSectionContainer } from "../common/components/ContentSectionContainer";
import { checkSuccessIcon } from "../common/icons";

const OptionText = styled(Div)`
  display: flex;
  align-items: center;
  ${(props) => props.theme.textStyles.wbody3};
  color: ${(props) => props.theme.colors.gray700};
  & > svg {
    margin-right: 12px;
  }
`;

const options = [
  "Add your logo.",
  "Change the colors to the data onboarding experience.",
];

const CustomizeImporter = () => {
  const history = useHistory();
  const { updateOnboarding, user } = useUserContext();

  const goToImporterStylePreferences = () => {
    updateOnboarding({ onboarding_customize_import_step: true });
    history.push(IMPORTER_STYLE_PREFERENCES_ROUTE);
  };

  const sectionTitle = "Customize Importer";
  const sectionSubtitle =
    "Customize the styling of the importer so the data onboarding experience feels no different than the rest of your app.";

  return (
    <ContentSectionContainer title={sectionTitle} subtitle={sectionSubtitle}>
      <Div data-cy="check-info" mt={24}>
        {options.map((item, index) => (
          <OptionText key={index} mt={index !== 0 ? 12 : 0}>
            {checkSuccessIcon}
            {item}
          </OptionText>
        ))}
      </Div>
      <Button
        data-cy="checkout"
        mt={24}
        w={147}
        onClick={goToImporterStylePreferences}
      >
        Check it out
      </Button>
    </ContentSectionContainer>
  );
};

export default CustomizeImporter;

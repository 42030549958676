import { Plan } from "components/UserContextProvider";
import { Div, DivCSSProps, Span, colors } from "fuse-shared-ui";
import styled from "styled-components";
import PlanSpecs from "../../../PlanSpecs";
import { Tag } from "./icons";
import { useBillingContext } from "components/Routes/AccountRoutes/Billing/BillingContextProvider";
import { useMemo } from "react";

type Props = {
  plan: Plan;
} & DivCSSProps;

const StyledPlanSpecs = styled(PlanSpecs)`
  margin-top: 0;
`;

const DiscountTag = ({
  originalPrice,
  discountValue,
}: {
  originalPrice: string;
  discountValue: number;
}) => {
  return (
    <Div flexColumn gap={8}>
      <Span body1 capitalize c={colors.gray700}>
        Original price <Span body2>${originalPrice}</Span>
      </Span>

      <Div flexRow gap={8}>
        <Div
          bg={colors.white50}
          p="8px 12px 8px 12px"
          w={160.3}
          br={24}
          gap={8}
          dflex
          flexRow
          uppercase
          label2
        >
          <Tag />
          discount applied
        </Div>
        <Span body2 c={colors.gray700}>
          ${discountValue.toFixed(2).replace(/\.0+$/, "")}
        </Span>
      </Div>
    </Div>
  );
};

const PlanDetails = ({ plan, ...props }: Props) => {
  const isAnnual = plan?.recurrence_period === "year";
  const { subscriptionData } = useBillingContext();
  const coupon = subscriptionData?.attached_coupon;

  const discountValue = useMemo(() => {
    if (!coupon || !coupon.valid) return 0;

    const percent_off = coupon.percent_off;
    const amount_off = coupon.amount_off;

    if (percent_off && percent_off > 0) {
      return (plan.price.cents / 100) * (percent_off / 100);
    } else if (amount_off && amount_off > 0) {
      return amount_off / 100;
    }

    return 0;
  }, [coupon]);

  const priceWithDiscount = useMemo(() => {
    const value = plan.price.cents / 100 - discountValue;
    if (value < 0) return 0;
    return value;
  }, [plan, discountValue]);

  return (
    <Div w100 h100 gap={32} flexColumn {...props}>
      <Div gap={26} flexColumn>
        <Span data-cy="plan-title-on-stripe" h6 capitalize>
          {plan.title} Plan
        </Span>
        <Div flexColumn gap={24}>
          {discountValue > 0 ? (
            <DiscountTag
              originalPrice={plan.format_price}
              discountValue={discountValue}
            />
          ) : null}

          <Div flexColumn gap={8}>
            <Div gap={12} alignCenter>
              <Span data-cy="total" body1>
                Total
              </Span>
              <Span data-cy="amount" h4 fontweight_700>
                ${priceWithDiscount.toFixed(2).replace(/\.0+$/, "")}
              </Span>
              <Span data-cy="subscription-period" body1 c={colors.gray700}>
                {isAnnual ? "paid annually" : "paid monthly"}
              </Span>
            </Div>

            <Div
              data-cy="plan-description-on-stripe"
              body3
              dangerouslySetInnerHTML={{ __html: plan.description }}
            />
          </Div>
        </Div>
      </Div>

      <Div flexColumn>
        <StyledPlanSpecs data-cy="plan-info-on-stripe" plan={plan} />
      </Div>
      <Div alignCenter mt={32}></Div>
    </Div>
  );
};

export default PlanDetails;

import { api } from "fuse-shared-ui";
import { useEffect, useReducer, useState } from "react";
import { useUserContext } from "../../../../../UserContextProvider";
import { CommonQueryData, TemplateType } from "./interfaces";

interface Action {
  type: "SELECT_TEMPLATE" | "SELECT_IMPORT";
  payload: string;
}

const getTemplates = async () => {
  try {
    const { data } = await api.get<TemplateType[]>(
      "/api/v1/templates/with_logs"
    );
    return data;
  } catch (error) {
    console.error("Error fetching templates:", error);
    return [];
  }
};

const getImports = async (orgId: number) => {
  try {
    const { data } = await api.get<CommonQueryData[]>(
      `/api/v1/organizations/${orgId}/imports/with_logs`
    );
    return data;
  } catch (error) {
    console.error("Error fetching imports:", error);
    return [];
  }
};

type InitialQueryState = {
  selectedTemplate: string;
  selectedImport: string;
  query: string;
};

const queryReducer = (state: InitialQueryState, action: Action) => {
  let selectedTemplate = state.selectedTemplate;
  let selectedImport = state.selectedImport;

  switch (action.type) {
    case "SELECT_TEMPLATE":
      selectedTemplate =
        action.payload === state.selectedTemplate ? "" : action.payload;
      break;
    case "SELECT_IMPORT":
      selectedImport =
        action.payload === state.selectedImport ? "" : action.payload;
      break;
    default:
      return state;
  }

  const query = generateQuery(selectedTemplate, selectedImport);

  return {
    ...state,
    selectedTemplate,
    selectedImport,
    query,
  };
};

const generateQuery = (selectedTemplate: string, selectedImport: string) => {
  const queryParams = [];

  if (selectedTemplate) {
    queryParams.push(`template_slug=${selectedTemplate}`);
  }

  if (selectedImport) {
    queryParams.push(`import_slug=${selectedImport}`);
  }

  return queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
};

const mountInitialQueryState = (initialValues: InitialValues) => {
  if (!initialValues) {
    return {
      selectedTemplate: "",
      selectedImport: "",
      query: "",
    };
  }

  const { from, payload } = initialValues;

  const templateInitial = from === "templates" ? payload : "";
  const importInitial = from === "imports" ? payload : "";

  const initialQueryState = {
    selectedTemplate: templateInitial,
    selectedImport: importInitial,
    query: generateQuery(templateInitial, importInitial),
  };

  return initialQueryState;
};

export type InitialValues = {
  from: string;
  payload: string;
};

export const useQueryLogs = (initialValues: InitialValues) => {
  const initialQueryState = mountInitialQueryState(initialValues);

  const {
    user: {
      active_organization: { id: orgId },
    },
  } = useUserContext();

  const [templates, setTemplates] = useState<TemplateType[]>([]);
  const [imports, setImports] = useState<CommonQueryData[]>([]);

  const [queryState, dispatch] = useReducer(queryReducer, initialQueryState);

  useEffect(() => {
    (async () => {
      const [templates, imports] = await Promise.all([
        getTemplates(),
        getImports(orgId),
      ]);

      setTemplates(templates);
      setImports(imports);
    })();
  }, []);

  const handleFilterTemplate = (payload: string) => {
    dispatch({ payload, type: "SELECT_TEMPLATE" });
  };

  const handleFilterImport = (payload: string) => {
    dispatch({ payload, type: "SELECT_IMPORT" });
  };

  return {
    templates,
    imports,
    queryState,
    handleFilterTemplate,
    handleFilterImport,
  };
};

import { BlankState } from "components/common/Table/BlankState";
import {
  Button,
  Div,
  P,
  Table,
  TableDataProvider,
  colors,
} from "fuse-shared-ui";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useUserContext } from "../../../../UserContextProvider";
import CreateCredentialsModal from "./CreateCredentialsModal";
import { EmptyIcon } from "./icons";
import { tableColumns } from "./tableColumns";

const MainContent = ({ setCreateModalOpen, testId }) => {
  const blankStateUI = (
    <Div data-cy="api-text-info" dflex mt={120} w100>
      <BlankState
        title="No API keys generated"
        description="You have not generated any API key yet"
        icon={<EmptyIcon />}
        button={
          <Button
            data-cy="generate-api-empty-button"
            variant="secondary"
            w={153}
            onClick={() => setCreateModalOpen(true)}
          >
            Generate API Key
          </Button>
        }
      />
    </Div>
  );

  return (
    <Div h100>
      <Table
        blankState={blankStateUI}
        columns={tableColumns}
        data-testid={testId}
        disableSorting
      />
    </Div>
  );
};

const ApiCredentialsRoutesWithProvider = ({ testId }: { testId?: string }) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const headerUI = (
    <Div spaceBetween>
      <Helmet>
        <title>API Credentials</title>
      </Helmet>
      <Div>
        <P data-cy="api-key-text" h5 c={colors.textPrimary} m={0}>
          Generate API keys
        </P>
        <P data-cy="api-key-description" wbody1 m={0} mt={8}>
          API keys provide access to our system&apos;s API endpoints to retrieve
          import data.
        </P>
      </Div>

      <Button
        w={153}
        maxh={36}
        onClick={() => setCreateModalOpen(true)}
        data-cy="generate-api-header-button"
      >
        Generate API key
      </Button>
    </Div>
  );

  return (
    <Div p={32}>
      {headerUI}
      <Div my={40} h100>
        <MainContent setCreateModalOpen={setCreateModalOpen} testId={testId} />
        {createModalOpen && (
          <CreateCredentialsModal
            isOpen={createModalOpen}
            onClose={() => setCreateModalOpen(false)}
          />
        )}
      </Div>
    </Div>
  );
};

const ApiCredentialsRoutes = ({ testId }: { testId?: string }) => {
  const {
    user: { active_organization },
  } = useUserContext();

  return (
    <TableDataProvider
      dataUrl={`/api/v1/organizations/${active_organization.id}/api_credentials`}
    >
      <ApiCredentialsRoutesWithProvider testId={testId} />
    </TableDataProvider>
  );
};

export default ApiCredentialsRoutes;

import { useTranslation } from "react-i18next";
import { colors } from "../../../styled/theme/colorPalette";
import { Div } from "../../../styled/utils";
import {
  closeIconWithCircle,
  errorIcon,
  fileExtensionIcon,
} from "../../common/icons";
import { useImporterContext } from "../../contexts/ImporterContextProvider";
import * as S from "./DragAndDropImporter.styles";
import { ImporterDropzone } from "./ImporterDropzone";
import { HeaderCard } from "./ImporterSectionHeader";

export const ErrorCard = () => {
  const { templateError } = useImporterContext();
  const { t } = useTranslation("upload");

  return (
    <S.ErrorCardContainer>
      <Div mb={28}>{errorIcon}</Div>
      <Div h3 mb={16}>
        {t("error.title")}
      </Div>
      <Div body1>{templateError}</Div>
    </S.ErrorCardContainer>
  );
};

const LoadingCard = () => {
  const { fileName, resetImport } = useImporterContext();
  const { t } = useTranslation("upload");
  const fileExtension = fileName && fileName.slice(fileName.lastIndexOf("."));
  const fileExtensionUI = (
    <Div>
      {fileExtensionIcon}
      <Div c={colors.gray900} helperText mt={-26} ml={7}>
        {fileExtension}
      </Div>
    </Div>
  );

  return (
    <S.LoadingCardWrapper spaceBetween centered>
      {fileExtensionUI}
      <Div mr={30} ml={30}>
        <Div label2>{fileName}</Div>
        <Div helperText c={colors.gray500}>
          {t("loading.title")}
        </Div>
      </Div>
      <Div clickable onClick={resetImport}>
        {closeIconWithCircle}
      </Div>
    </S.LoadingCardWrapper>
  );
};

const ImporterSection = () => {
  const {
    templateHeaders,
    templateError,
    setUploadedData,
    setIsUploading,
    setFileName,
  } = useImporterContext();

  const onComplete = (data: any) => {
    setUploadedData(data);
    setIsUploading(false);
  };

  const saveFileName = (fileName: string) => {
    setFileName(fileName);
  };

  return templateError ? (
    <ErrorCard />
  ) : (
    <>
      {templateHeaders && (
        <S.ImporterSectionContainer>
          <ImporterDropzone
            onComplete={onComplete}
            saveFileName={saveFileName}
            setIsLoading={setIsUploading}
          />
          <HeaderCard templateHeaders={templateHeaders} />
        </S.ImporterSectionContainer>
      )}
    </>
  );
};

const DragAndDropImporter = (): JSX.Element => {
  const { isUploading } = useImporterContext();
  const { t } = useTranslation("upload");

  const HeaderUI = () => (
    <Div dflex flexColumn gap={16} mt={24} mb={24}>
      <Div h2>{t("title")}</Div>
      <Div body1>{t("description")}</Div>
    </Div>
  );

  return (
    <Div flexColumn h100>
      <Div flexColumn pl={32} pr={32} pb={64} h100>
        <HeaderUI />
        {isUploading ? (
          <Div mt={40} justifyCenter>
            <LoadingCard />
          </Div>
        ) : (
          <ImporterSection />
        )}
      </Div>
    </Div>
  );
};

export { DragAndDropImporter };

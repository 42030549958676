import { useEffect } from "react";
import styled from "styled-components";
import { Minus, Plus } from "./icons";
import { FormInputLabel, TextInput, Div } from "fuse-shared-ui";
import { useFormikField } from "components/common/form/fieldHelpers";

const InputWrapper = styled(Div)`
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  height: 68px;
  justify-content: space-between;
  margin: 0px;
  outline: none;
  padding: 20px 16px;
  width: 100%;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) => props.theme.textStyles.wbody1};

  :focus,
  :focus-within {
    border: 2px solid ${(props) => props.theme.colors.blue500};

    /* 3d at the end adds .24 opacity (using 8 digit hex)*/
    box-shadow: 0px 0px 8px ${(props) => props.theme.colors.blue500}3d;
  }

  :hover {
    box-shadow: none;
    background: transparent;
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
`;

type NumberOfDecimalsProps = {
  name: string;
};

export const NumberOfDecimalsOption = ({
  name,
  ...props
}: NumberOfDecimalsProps) => {
  const { value, setValue } = useFormikField(name);

  useEffect(() => {
    // Try to improve this, how can we define a starting
    // value of 1 for this field in a easier way?
    if (!value) setValue(1);
  }, []);

  const handleIncrement = () => {
    if (value < 11) {
      setValue(value + 1);
    }
  };

  const handleDecrement = () => {
    if (value > 1) {
      setValue(value - 1);
    }
  };

  return (
    <Div w100>
      <FormInputLabel mb={8} h={20}>
        <Div dflex>
          <Div centered>Pattern</Div>
        </Div>
      </FormInputLabel>
      <InputWrapper>
        <ActionButton onClick={handleDecrement} type="button">
          <Minus />
        </ActionButton>

        <Div>0.{"0".repeat(value)}</Div>

        <ActionButton onClick={handleIncrement} type="button">
          <Plus />
        </ActionButton>

        <TextInput name={name} value={value} {...props} hidden />
      </InputWrapper>
    </Div>
  );
};

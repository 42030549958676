import { P } from "fuse-shared-ui";
import styled from "styled-components";

const StyledButton = styled.a`
  display: flex;
  gap: 12px;
  padding: 6px 20px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  max-height: 36px;
  color: #000000;
  cursor: pointer;
`;

export const DownloadButton = ({ showText = true, ...props }) => {
  return (
    <StyledButton {...props}>
      <DownloadIcon />
      {showText && <P buttonTextSmall>Download</P>}
    </StyledButton>
  );
};

const DownloadIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66634 9.00008C1.33301 8.00008 1.33301 7.00008 1.33301 6.30432C1.33301 3.98554 3.40932 1.66675 5.97058 1.66675C7.68714 1.66675 9.18588 2.59936 9.98774 3.98554H10.6082C12.5291 3.98554 14.333 5.33341 14.333 7.00008C14.333 7.66675 14.333 8.33341 13.9997 9.00008"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.94434 13.2209L7.66656 15.8331L10.3888 13.2209"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.66699 15.1462L7.66699 7.75005"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

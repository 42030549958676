import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { Span } from "fuse-shared-ui";
import React from "react";
import { columnTypesLabel } from "../../fields";
import { ColumnFormValue } from "../../types";
import Header from "../../../../components/Header";
import * as S from "./styles";
import TransformationForm from "./TransformationForm";

type Props = {
  isEditing: boolean;
  canAddMoreTransformations: boolean;
  shouldBeDisabled?: boolean;
};

const TransformationsForm = ({
  isEditing,
  canAddMoreTransformations,
  shouldBeDisabled,
}: Props) => {
  const { values, isValid } = useFormikContext<ColumnFormValue>();
  const { column_type: columnType, transformations } = values;

  const suffix = isEditing ? "editing" : "creating";
  const columnLabel = columnTypesLabel[columnType];

  const isTransformationsEmpty =
    !transformations || transformations.length === 0;
  const lastTransformationIndex = transformations
    ? transformations.length - 1
    : -1;
  const hasLastTransformationTypeFilled =
    transformations &&
    transformations[lastTransformationIndex]?.transformation_type;
  const isAddButtonDisabled = isTransformationsEmpty
    ? false
    : !hasLastTransformationTypeFilled || !isValid;

  function renderTransformationForm(
    index: number,
    remove: (index: number) => void
  ) {
    const showDivider = index < lastTransformationIndex;

    return (
      <React.Fragment key={`transformations-${index}`}>
        <TransformationForm
          name={`transformations.${index}`}
          columnType={columnType}
        />
        <S.DeleteTransformationButton mt={16} onClick={() => remove(index)} />
        {showDivider ? <S.Divider /> : null}
      </React.Fragment>
    );
  }

  function renderFieldArray({ push, remove }: FieldArrayRenderProps) {
    return (
      <>
        {transformations &&
          transformations.map((_, index) =>
            renderTransformationForm(index, remove)
          )}
        {canAddMoreTransformations && (
          <S.AddTransformationButton
            isDisabled={isAddButtonDisabled}
            mt={32}
            onClick={() => (isAddButtonDisabled ? null : push({}))}
          />
        )}
      </>
    );
  }

  function renderHeader() {
    return (
      <Header
        title={"Add transformations"}
        subTitle={
          <>
            {`Set transformations to the column type `}
            <Span extraBold>{`(${columnLabel})`}</Span>
            {` you’re ${suffix}.`}
          </>
        }
      />
    );
  }

  return (
    <S.Container shouldBeDisabled={shouldBeDisabled}>
      {renderHeader()}
      {/* @ts-ignore */}
      <FieldArray name="transformations" render={renderFieldArray} />
    </S.Container>
  );
};

export default TransformationsForm;

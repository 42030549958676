import React, {
  MutableRefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEventManagerContext } from "../../../../common/EventManagerContextProvider";
import { Record, SpreadsheetValidationErrors } from "fuse-importer";
import { useReviewContext } from "../../../Review/ReviewContextProvider";
import { useDataSetContext } from "../DataSetContextProvider";
import { useDuplicateDataContext } from "../DuplicateDataContextProvider";
import { getFilterConditions } from "../SpreadsheetContextProvider/filterMethods";
import {
  SpreadsheetFilter,
  useSpreadsheetFilter,
} from "../SpreadsheetContextProvider/useSpreadsheetFilter";
import { useValidationContext } from "../ValidationContextProvider";
import { SortBy } from "../common/types";

export type State = {
  isSortingOrFiltering: boolean;
  filteredDataIds: string[];
  filteredInputValue: string;
  setFilteredInputValue: (value: string) => void;
  sortByFieldErrors: string;
  sortByFieldWarnings: string;
  handleSortByFieldErrors: (field: string) => void;
  handleSortByFieldWarnings: (field: string) => void;
  resetSpreadsheetFilter: () => void;
  filterRecordsByRowType: (filterByRow: string) => void;
  filterRecordsByFieldErrors: (filterByColumn: string) => void;
  sortBy: SortBy;
  setSortBy: React.Dispatch<React.SetStateAction<SortBy>>;
  spreadsheetFilter: SpreadsheetFilter;
  isFiltering: boolean;
  lastFilteredRecordId: MutableRefObject<string>;
  setRecalculateFilters: React.Dispatch<React.SetStateAction<boolean>>;
};

const getDefaultSortCompareFn = (sortBy: SortBy) => {
  return (a: Record, b: Record) => {
    const aField = a[sortBy.field];
    const bField = b[sortBy.field];
    if (aField === bField) return a._meta.rowIndex > b._meta.rowIndex ? 1 : -1;
    if (!aField) return 1;
    if (!bField) return -1;

    if (sortBy.direction === "asc") return aField > bField ? 1 : -1;
    return aField < bField ? 1 : -1;
  };
};

type ContextArgs = {
  getSortCompareFn: any;
  children: any;
};

export const FilteredDataContext = createContext<State>({} as State);

export const FilteredDataContextProvider = ({
  getSortCompareFn = getDefaultSortCompareFn,
  children,
}: ContextArgs) => {
  const {
    spreadsheetFilter,
    filterRecordsByRowType,
    filterRecordsByFieldErrors,
    resetSpreadsheetFilter,
  } = useSpreadsheetFilter();
  const [filteredInputValue, setFilteredInputValue] = useState("");
  const [sortByFieldErrors, setSortByFieldErrors] = useState<string>(null);
  const [sortByFieldWarnings, setSortByFieldWarnings] = useState<string>(null);
  const [sortBy, setSortBy] = useState<SortBy | null>(null);
  const [recalculateFilters, setRecalculateFilters] = useState(false);
  const lastFilteredRecordId = useRef(null);
  const { on, off, EVENTS } = useEventManagerContext();
  const { getRecord, dataSet, dataSetLength } = useDataSetContext();
  const { duplicateRecordIds } = useDuplicateDataContext();
  const { errors, warnings } = useValidationContext();
  const { initialDataSetUpdatedAt } = useReviewContext();

  const reset = () => {
    resetSpreadsheetFilter();
    setFilteredInputValue("");
    setSortByFieldErrors(null);
    setSortBy(null);
  };

  const sortByFieldError = (
    a: Record,
    b: Record,
    errors: React.MutableRefObject<SpreadsheetValidationErrors>,
    warnings: React.MutableRefObject<SpreadsheetValidationErrors>,
    sortByFieldErrors: string,
    sortByFieldWarnings: string
  ) => {
    const aRecordId = a._meta.id;
    const bRecordId = b._meta.id;
    const aHasErrors = !!(sortByFieldErrors
      ? errors.current
      : warnings.current)[aRecordId]?.[
      sortByFieldErrors || sortByFieldWarnings
    ];
    const bHasErrors = !!(sortByFieldErrors
      ? errors.current
      : warnings.current)[bRecordId]?.[
      sortByFieldErrors || sortByFieldWarnings
    ];

    if (aHasErrors === bHasErrors) {
      return a._meta.rowIndex > b._meta.rowIndex ? 1 : -1;
    }

    return bHasErrors ? 1 : -1;
  };

  const recalculateFilteredIds = () => {
    const filterConditions = getFilterConditions(
      spreadsheetFilter,
      getRecord,
      errors,
      filteredInputValue,
      duplicateRecordIds
    );

    const ids = [];
    let lastRecordId;
    for (const key in dataSet.current) {
      if (filterConditions.every((condition) => condition(key))) {
        lastRecordId = key;
        ids.push(key);
      }
    }

    lastFilteredRecordId.current = lastRecordId;

    if (sortBy) {
      ids.sort((a, b) => getSortCompareFn(sortBy)(getRecord(a), getRecord(b)));
    } else if (!!sortByFieldErrors || !!sortByFieldWarnings) {
      ids.sort((a, b) =>
        sortByFieldError(
          getRecord(a),
          getRecord(b),
          errors,
          warnings,
          sortByFieldErrors,
          sortByFieldWarnings
        )
      );
    }
    return ids;
  };

  const filteredDataIds = useMemo(() => {
    return recalculateFilteredIds();
  }, [
    filteredInputValue,
    spreadsheetFilter,
    sortBy,
    dataSet.current,
    duplicateRecordIds.size,
    sortByFieldErrors,
    sortByFieldWarnings,
    recalculateFilters,
    initialDataSetUpdatedAt,
  ]);

  useEffect(() => {
    reset();
    setRecalculateFilters((prev) => !prev);
  }, [dataSet.current]);

  useEffect(() => {
    const listener = () => {
      setRecalculateFilters((prev) => !prev);
    };

    on(EVENTS.ON_LAST_ROW_CHANGED, listener);

    return () => {
      off(EVENTS.ON_LAST_ROW_CHANGED, listener);
    };
  }, []);

  const isFiltering = useMemo(() => !!filteredInputValue, [filteredInputValue]);

  const handleSortByFieldErrors = useCallback(
    (field: React.SetStateAction<string>) => {
      setSortByFieldErrors(field === sortByFieldErrors ? null : field);
      setSortByFieldWarnings(null);
      setSortBy(null);
    },
    [sortByFieldErrors]
  );

  const handleSortByFieldWarnings = useCallback(
    (field: React.SetStateAction<string>) => {
      setSortByFieldWarnings(field === sortByFieldWarnings ? null : field);
      setSortByFieldErrors(null);
      setSortBy(null);
    },
    [sortByFieldWarnings]
  );

  const isSortingOrFiltering =
    filteredDataIds.length !== dataSetLength.current ||
    !!sortByFieldErrors ||
    !!sortByFieldWarnings ||
    !!sortBy;

  const value = {
    isSortingOrFiltering,
    filteredDataIds,
    filteredInputValue,
    setFilteredInputValue,
    sortByFieldErrors,
    sortByFieldWarnings,
    handleSortByFieldErrors,
    handleSortByFieldWarnings,
    resetSpreadsheetFilter,
    filterRecordsByRowType,
    filterRecordsByFieldErrors,
    sortBy,
    setSortBy,
    spreadsheetFilter,
    isFiltering,
    lastFilteredRecordId,
    setRecalculateFilters,
  };

  return (
    <FilteredDataContext.Provider value={value}>
      {children}
    </FilteredDataContext.Provider>
  );
};

export const useFilteredDataContext = () => useContext(FilteredDataContext);

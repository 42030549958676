import styled from "styled-components";
import { Button } from "../../common/buttons";
import { Div } from "../../styled/utils";
import { useImporterContext } from "../contexts/ImporterContextProvider";
import { useTranslation } from "react-i18next";

const HeaderButtonsWrapper = styled(Div)`
  > div {
    margin-right: 8px;
  }
`;

export const HeaderButtons = () => {
  const { t } = useTranslation("upload");
  const { resetImport } = useImporterContext();

  return (
    <HeaderButtonsWrapper dflex ml={12}>
      <Button
        data-cy="re-upload"
        isSmall
        variant="secondary"
        onClick={resetImport}
      >
        {t("headers_preview.cancel")}
      </Button>
    </HeaderButtonsWrapper>
  );
};

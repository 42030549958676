import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { useReviewContext } from "../../../Importer";
import { useFilteredDataContext } from "../../../Importer/common/Spreadsheet/FilteredDataContextProvider";
import { FindAndReplaceModal } from "../../../Importer/common/Spreadsheet/components/FindAndReplace";
import { TooltipProvider } from "../../../common/TooltipProvider";
import { useDebounce } from "../../../hooks";
import { Div, Span } from "../../../styled";
import { colors } from "../../../styled/theme/colorPalette";
import { textStyles } from "../../../styled/theme/textStyles";
import { ReplaceIcon, SearchIcon } from "./icons";
import { useImporterContext } from "../../../Importer/contexts/ImporterContextProvider";
import { useSearchContext } from "../../../Importer/common/Spreadsheet/SearchContextProvider";

interface SearchInputProps {
  testId?: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}

const Wrapper = styled(Div)<{ isOpen: boolean }>`
  background: ${({ isOpen }) => (isOpen ? colors.orange50 : "none")};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  height: 24px;
  overflow: hidden;
  padding: 8px;
  position: relative;
  transition: width 0.25s ease-in-out;
  width: ${({ isOpen }) => (isOpen ? "332px" : "24px")};
`;

const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
`;

const ResultsText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray700};
  display: block;
  text-wrap: nowrap;
  text-align: right;
  border-right: solid 1px ${colors.gray300};
  margin-right: 8px;
  padding-right: 8px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: flex-center;
`;

const Input = styled.input<{ isOpen: boolean }>`
  ${textStyles.body3};
  width: 100%;

  background-color: none;
  background: none;
  border: none;
  box-shadow: none;
  color: ${colors.gray700};
  height: 20px;
  line-height: 20px;
  outline: none;
  padding: 1px 2px;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      position: absolute;
      top: -9999px;
    `};
`;

const SearchInput = ({
  testId,
  value,
  onChange,
  isDisabled,
}: SearchInputProps) => {
  const { _value, set_Value } = useDebounce(value, onChange, 300);
  const { filteredDataIds } = useFilteredDataContext();
  const {
    findAndReplaceModalOpen,
    setFindAndReplaceModalOpen,
    openSearchInput,
    closeSearchInput,
    searchInputRef,
    isOpen,
  } = useSearchContext();
  const actionsRef = useRef();

  const { t } = useTranslation("review");

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    set_Value(target.value);
  };

  const handleInputOnBlur = (e) => {
    if (!_value && e.relatedTarget !== actionsRef.current) {
      closeSearchInput();
    }
  };

  return (
    <Div>
      <TooltipProvider
        tooltip={
          !isOpen && !isDisabled && !findAndReplaceModalOpen
            ? t("table_actions.search_rows.tooltip")
            : ""
        }
        placement="right"
        flip={false}
        centered
        w="auto"
        data-testid={testId}
      >
        <Wrapper
          isOpen={isOpen}
          onClick={() => !isDisabled && openSearchInput()}
          disabledWhen={isDisabled}
        >
          <IconWrapper>
            <SearchIcon />
          </IconWrapper>
          <Input
            isOpen={isOpen}
            ref={searchInputRef}
            value={_value}
            onChange={handleInputChange}
            onBlur={handleInputOnBlur}
            type="text"
            placeholder={t("table_actions.search_rows.placeholder")}
          />

          <ActionsWrapper
            data-cy="open-find-replace"
            ref={actionsRef}
            onClick={() => setFindAndReplaceModalOpen(true)}
            tabIndex={0}
          >
            <ResultsText>
              {t("table_actions.search_rows.number_of_results", {
                length: value ? filteredDataIds.length : 0,
              })}
            </ResultsText>
            <IconWrapper>
              <ReplaceIcon />
            </IconWrapper>
          </ActionsWrapper>
        </Wrapper>
      </TooltipProvider>
      <Div>
        {findAndReplaceModalOpen && (
          <FindAndReplaceModal
            initialText={value}
            onClose={() => setFindAndReplaceModalOpen(false)}
          />
        )}
      </Div>
    </Div>
  );
};

export const SearchRows = ({
  testId = "ta-search-rows",
}: {
  testId?: string;
}) => {
  const {
    filteredInputValue,
    setFilteredInputValue,
  } = useFilteredDataContext();
  const { hasTransformedRecords } = useReviewContext();

  const handleChangeInputValue = (value: string) => {
    setFilteredInputValue(value);
  };

  return (
    <>
      <SearchInput
        testId={testId}
        value={filteredInputValue}
        onChange={handleChangeInputValue}
        isDisabled={hasTransformedRecords}
      />
    </>
  );
};

import { DuplicateRecordIds, Record } from "fuse-importer";
import { SpreadsheetFilter } from "./useSpreadsheetFilter";
import { rowIsEmpty } from "./utils";

type FilterCondition = (id: string) => boolean;

export const getFilterConditions = (
  spreadsheetFilter: SpreadsheetFilter,
  getRecord: (recordId: string) => Record,
  errors: { current: Record },
  filteredInputValue: string,
  duplicateRecordIds: DuplicateRecordIds
): FilterCondition[] => {
  const filterConditions = [];

  switch (spreadsheetFilter.type) {
    case "rowBased":
      if (spreadsheetFilter.byRow === "all") {
        filterConditions.push((key) => true);
      }
      if (spreadsheetFilter.byRow === "invalid") {
        filterConditions.push((key) => {
          return (
            getRecord(key)?._meta?.isInvalid && !rowIsEmpty(getRecord(key))
          );
        });
      }
      if (spreadsheetFilter.byRow === "duplicates")
        filterConditions.push((key) => duplicateRecordIds.has(key));
      break;

    case "columnBased":
      if (spreadsheetFilter.byColumn.type === "fieldWithErrors") {
        filterConditions.push(
          (key) =>
            getRecord(key)?._meta?.isInvalid &&
            errors.current[key]?.[spreadsheetFilter.byColumn.field]
        );
      }
      break;
  }

  const includesSearchValue = (value, searchValue) => {
    if (typeof value === "string" || typeof value === "number") {
      const stringValue = String(value).toLowerCase();
      const searchValueLower = searchValue.toLowerCase();
      return stringValue.includes(searchValueLower);
    }
    return false;
  };

  if (filteredInputValue) {
    filterConditions.push((key) => {
      const record = getRecord(key);
      // check if any of the values in the record contain the search input
      for (const fieldName in record) {
        if (includesSearchValue(record[fieldName], filteredInputValue)) {
          return true;
        }
      }
      return false;
    });
  }

  return filterConditions;
};

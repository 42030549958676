import { useEffect, useState } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  ColumnRequiredProps,
  ColumnValidations,
  ColumnTransformations,
  FUSE_INIT,
  IFRAME_READY,
} from "fuse-importer";
import { Importer } from "./Importer";
import { validateColumn } from "./common";
import { es, en, de, fr } from "./locales";
import { getDefaultValidations } from "./utils";

export const configureI18n = async (_language, locales) => {
  const browserLanguage = navigator.language.split("-")[0];
  const language = _language || browserLanguage || "en";

  await i18n.use(initReactI18next).init({
    lng: language,
    fallbackLng: "en",
    resources: {
      ...locales,
    },
  });
};

export const buildDynamicColumns = (columnData: ColumnRequiredProps<any>) => {
  const {
    column_type,
    internal_key,
    label,
    description,
    required,
    pattern,
    position,
    transformations,
    unique,
    validations,
    values,
  } = columnData;
  validateColumn<any>(columnData);

  const treatedValidations: ColumnValidations<any>[] = [
    ...getDefaultValidations(column_type),
    ...(validations || []),
  ];

  const treatedTransformations: ColumnTransformations<any>[] =
    transformations ?? [];

  const column = {
    column_type,
    internal_key,
    label,
    description,
    required,
    pattern: pattern || null,
    position: position || null,
    unique: unique || false,
    values: column_type === "enum" ? values || [] : [],
    validations: treatedValidations,
    transformations: treatedTransformations,
  };
  return column;
};

const createIframeHandler = (messageType) => {
  const messageResolvers = new Map();

  const messageHandler = (message) => {
    const { type, id, payload } = message.data;
    if (type === messageType && messageResolvers.has(id)) {
      const resolve = messageResolvers.get(id);
      resolve(payload);
      messageResolvers.delete(id);
    }
  };
  window.addEventListener("message", messageHandler, false);

  return (...args) => {
    return new Promise((resolve) => {
      const messageId = Math.random().toString(36).substring(2);
      messageResolvers.set(messageId, resolve);
      window.parent.postMessage(
        { type: messageType, id: messageId, payload: args },
        "*"
      );
    });
  };
};

export const WebappIframeInterface = () => {
  const [importerConfig, setImporterConfig] = useState(null);
  const [isI18nReady, setIsI18nReady] = useState(false);

  useEffect(() => {
    const messageHandler = (event) => {
      const response = event.data;
      if (response.type === FUSE_INIT) {
        window.removeEventListener("message", messageHandler, false);
        setImporterConfig(response.payload);
      }
    };

    window.addEventListener("message", messageHandler, false);
    window.parent.postMessage({ type: IFRAME_READY }, "*");
  }, []);

  useEffect(() => {
    if (!importerConfig) return;
    const initI18n = async () => {
      configureI18n(importerConfig.options.language, { es, en, de, fr });
      setIsI18nReady(true);
    };
    initI18n();
  }, [importerConfig]);

  const importerProps = {
    apiToken: importerConfig?.apiToken,
    templateSlug: importerConfig?.templateSlug,
    options: importerConfig?.options,
    dynamicColumns: importerConfig?.dynamicColumns.map(buildDynamicColumns),
    customActions: importerConfig?.customActions.map((ca, index) => ({
      name: ca.name,
      actionType: ca.actionType,
      handler: async (record) => {
        const response = await createIframeHandler("customAction")(
          index,
          record
        );
        return response;
      },
    })),
    onSubmit: importerConfig?.hasOnSubmit && createIframeHandler("onSubmit"),
    formatRecord:
      importerConfig?.hasFormatRecord && createIframeHandler("formatRecord"),
    onValidateRecord:
      importerConfig?.hasOnValidateRecord &&
      (createIframeHandler("onValidateRecord") as any),
    onClose: createIframeHandler("onClose"),
  };

  return (
    <>{importerConfig && isI18nReady && <Importer {...importerProps} />}</>
  );
};

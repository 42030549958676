import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Div } from "../../styled/utils";
import Spreadsheet from "../common/Spreadsheet";
import { useImporterContext } from "../contexts/ImporterContextProvider";
import { useReviewContext } from "./ReviewContextProvider";
import { SpreadsheetSubmissionModal } from "./SpreadsheetSubmissionModal";

const TitleContainer = styled(Div)``;

const ReviewWithContext = () => {
  const {
    reviewFields: fields,
    reviewInitialDataSet: initialDataSet,
    options,
    initialDataSetUpdatedAt,
    onValidateRecord,
    onDataSetChanged,
    onSubmit,
    isSubmissionModalOpen,
    setIsSubmissionModalOpen,
  } = useReviewContext();

  const {
    selectedRows,
    setSelectedRows,
    isUsingManualImport,
  } = useImporterContext();
  const { t } = useTranslation("review");

  const spreadsheetUI = initialDataSet && (
    <Spreadsheet
      initialDataSetUpdatedAt={initialDataSetUpdatedAt}
      options={{
        ...options,
        showCheckboxColumn: true,
      }}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      initialDataSet={initialDataSet}
      fields={fields}
      onDataSetChanged={onDataSetChanged}
      onValidateRecord={onValidateRecord}
    />
  );

  return (
    <Div centered flexColumn h100>
      <SpreadsheetSubmissionModal
        onSubmit={onSubmit}
        shouldOpenModals={isSubmissionModalOpen}
        onCloseModal={() => {
          setIsSubmissionModalOpen(false);
        }}
      />
      <Div w100 h100 flexColumn>
        <TitleContainer flexColumn ml={32}>
          <Div h2 mt={24} mb={24} data-cy="review-submit">
            {t(isUsingManualImport.current ? "manual_importer_title" : "title")}
          </Div>
        </TitleContainer>
        <Div h100 w100 justifyCenter>
          {spreadsheetUI}
        </Div>
      </Div>
    </Div>
  );
};

export default ReviewWithContext;

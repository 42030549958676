import { EnvironmentType } from "fuse-importer";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { api } from "../../api";
import { baseUrls } from "./index";

// copies the axios request config from axios.AxiosInstance type and adds
// baseUrl based on environment and api key
export class FuseApi {
  environment: EnvironmentType;
  apiToken: string;

  constructor(environment, apiToken) {
    this.environment = environment;
    this.apiToken = apiToken;
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api.get(url, {
      ...config,
      toPublicApi: true,
      baseURL: baseUrls[this.environment],
      params: {
        api_token: this.apiToken,
      },
    });
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api.post(url, data, {
      ...config,
      toPublicApi: true,
      baseURL: baseUrls[this.environment],
      params: {
        api_token: this.apiToken,
      },
    });
  }

  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api.patch(url, data, {
      ...config,
      toPublicApi: true,
      baseURL: baseUrls[this.environment],
      params: {
        api_token: this.apiToken,
      },
    });
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api.put(url, data, {
      ...config,
      toPublicApi: true,
      baseURL: baseUrls[this.environment],
      params: {
        api_token: this.apiToken,
      },
    });
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return api.delete(url, {
      ...config,
      toPublicApi: true,
      baseURL: baseUrls[this.environment],
      params: {
        api_token: this.apiToken,
      },
    });
  }
}

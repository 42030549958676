import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Div } from "../../../../styled/utils";

import React from "react";
import { areEqual } from "react-window";
import { useSpreadsheetContext } from "../../../../Importer";
import { addMeta } from "../../../../Importer/data";
import { TableRow } from "./TableRow";

const Row = React.memo<any>(({ data, rowIndex, style }) => {
  const {
    items,
    dataRefIds,
    rowWidth,
    isDraggable,
    onRowClick,
    columns,
    rowHeight,
    getItemKey,
    alignColumnsWithHeader,
  } = data;
  let item;
  const { selectedRows } = useSpreadsheetContext();

  const rowCount = dataRefIds ? dataRefIds.ids.length : items.length;

  const fistSelectedRowId = selectedRows?.[0];
  let dataSetfirstSelectedRowIndex;

  if (dataRefIds) {
    const rowId = dataRefIds.ids[rowIndex];
    const foundRow = dataRefIds.ref.current[rowId];
    item = foundRow || addMeta({}, rowCount);

    if (selectedRows?.length > 0)
      dataSetfirstSelectedRowIndex = dataRefIds.ids.findIndex(
        (id) => fistSelectedRowId === id
      );
  } else {
    item = items[rowIndex];
    if (selectedRows?.length > 0)
      dataSetfirstSelectedRowIndex = items.findIndex(
        (item) => fistSelectedRowId === item?._meta?.id
      );
  }

  const isRowSelected = selectedRows?.includes(item?._meta?.id);

  const Container = isDraggable ? DraggableRow : Div;
  let containerProps: any = { style };
  if (isDraggable)
    containerProps = {
      ...containerProps,
      item,
    };

  return (
    <Container {...containerProps}>
      <TableRow
        rowWidth={rowWidth}
        columns={columns}
        onRowClick={onRowClick}
        h={rowHeight}
        row={item}
        indexI={rowIndex}
        isLast={rowIndex === rowCount - 1}
        pRelative
        getItemKey={getItemKey}
        isRowSelected={isRowSelected || false}
        className={
          selectedRows?.length === 0
            ? ""
            : rowIndex < dataSetfirstSelectedRowIndex
            ? "before-selected-row"
            : ""
        }
        alignColumnsWithHeader={alignColumnsWithHeader}
      />
    </Container>
  );
}, areEqual);

const DraggableRow = React.memo<any>(({ item, style: baseStyle, children }) => {
  const { listeners, setNodeRef, transform } = useSortable({
    id: item.id,
  });
  const dragAndDropStyles = {
    transform: CSS.Transform.toString(transform),
  };
  const dragAndDropHandler = {
    ...listeners,
  };
  item.dragAndDropHandler = dragAndDropHandler;

  return (
    <Div style={{ ...baseStyle, ...dragAndDropStyles }} ref={setNodeRef}>
      {children}
    </Div>
  );
}, areEqual);

export { DraggableRow, Row };

import { FormikTextInput } from "components/common/form/fields";
import { yupRequired } from "components/common/form/validations";
import Toggle from "components/common/inputs/Toggle";
import { useFormikContext } from "formik";
import { P } from "fuse-shared-ui";
import Header from "../../../../components/Header";
import { TemplateFormValue } from "../SelectHostingForm/types";
import * as S from "./styles";

export const templateNameField = {
  label: "Importer Name",
  name: "name",
  placeholder: "E.g. Wedding Guest List, Customer List, etc.",
  initialValue: "",
  validation: yupRequired,
};

export const IncognitoModeSection = () => {
  const { values, setValues } = useFormikContext<TemplateFormValue>();

  const toggleIncognitoMode = () => {
    setValues({
      ...values,
      persistence: !values.persistence,
    });
  };

  const isIncognitoMode = !values.persistence;

  return (
    <S.IncognitoWrapper spaceBetween>
      <S.TextWrapper flexColumn>
        <P wbody4 m={0}>
          Incognito mode
        </P>
        <P wbody3 m={0}>
          Incognito Mode prevents import data from being saved to Fuse. Certain
          features are disabled when Incognito mode is turned on, so it is not
          recommended for most use cases.{" "}
          <a
            data-cy="learn-more"
            href="https://fuse-docs.flatirons.com/accessing-data/incognito-mode"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </P>
      </S.TextWrapper>

      <div>
        <Toggle
          data-cy="toggle-incognito"
          data-value={isIncognitoMode}
          value={isIncognitoMode}
          setValue={toggleIncognitoMode}
        />
      </div>
    </S.IncognitoWrapper>
  );
};

export const TemplateNameForm = () => (
  <S.InputWrapper data-cy="createImporterTitle">
    <FormikTextInput withDebounce={true} {...templateNameField} />
  </S.InputWrapper>
);

export const TemplateFromContent = () => (
  <S.ContentWrapper flexColumn>
    <TemplateNameForm />
    <IncognitoModeSection />
  </S.ContentWrapper>
);

const TemplateForm = () => {
  return (
    <>
      <Header
        mt={0}
        mb={40}
        title="Create an Importer"
        subTitle="Importers define the structure you want incoming data to take on. Create an importer and start importing data."
      />

      <TemplateFromContent />
    </>
  );
};

export default TemplateForm;

import React from "react";
import Helmet from "react-helmet";

const isInspectletEnabled = process.env.REACT_APP_ENABLE_INSPECTLET === "true";

const Inspectlet = () => {
  const inspectlet = (
    <script type="text/javascript">
      {`
        <!-- Begin Inspectlet Asynchronous Code -->
        (function() {
        window.__insp = window.__insp || [];
        __insp.push(['wid', 1700014715]);
        var ldinsp = function(){
        if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=1700014715&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
        setTimeout(ldinsp, 0);
        })();
        <!-- End Inspectlet Asynchronous Code -->
      `}
    </script>
  );

  return <Helmet>{isInspectletEnabled ? inspectlet : null}</Helmet>;
};

export default Inspectlet;

import { get } from "lodash";
import styled, { css, ThemeProps } from "styled-components";
import { CSSProps, Div, HTMLButton } from "../styled/utils";
import { PlusActionIcon } from "./icons";

const types = ["primary", "secondary", "tertiary", "link"] as const;
export type ButtonTypes = typeof types[number];

type ButtonStyles = {
  [K in ButtonTypes]: any;
};

export type BtnProps = {
  onClick?: () => void;
  backgroundColor?: string;
  variant?: ButtonTypes;
  children?: any;
  isDisabled?: boolean;
  isSmall?: boolean;
  isButtonElement?: boolean;
  svgNormalFillColorOverride?: string;
  svgActiveFillColorOverride?: string;
  svgDisabledFillColorOverride?: string;
  svgNormalStrokeColorOverride?: string;
  svgActiveStrokeColorOverride?: string;
  svgDisabledStrokeColorOverride?: string;
  iconPlacement?: "left" | "right";
} & CSSProps;

const getBgColor = (props: ThemeProps<BtnProps>) =>
  get(props, "backgroundColor", get(props.theme, "colors.black"));

const getBorderColor = (props: ThemeProps<BtnProps>) =>
  get(props, "backgroundColor", get(props.theme, "colors.blue500"));

const getSvgFillColorForNormalState = (props: ThemeProps<BtnProps>) =>
  get(props, "svgNormalFillColorOverride", get(props.theme, "colors.black"));

const getSvgFillColorForActiveState = (props: ThemeProps<BtnProps>) =>
  get(props, "svgActiveFillColorOverride", get(props.theme, "colors.white"));

const getSvgFillColorForDisabledState = (props: ThemeProps<BtnProps>) =>
  get(
    props,
    "svgDisabledFillColorOverride",
    get(props.theme, "colors.gray390")
  );

const getSvgStrokeColorForNormalState = (props: ThemeProps<BtnProps>) =>
  get(props, "svgNormalStrokeColorOverride", get(props.theme, "colors.black"));

const getSvgStrokeColorForActiveState = (props: ThemeProps<BtnProps>) =>
  get(props, "svgActiveStrokeColorOverride", get(props.theme, "colors.white"));

const getSvgStrokeColorForDisabledState = (props: ThemeProps<BtnProps>) =>
  get(
    props,
    "svgDisabledStrokeColorOverride",
    get(props.theme, "colors.black")
  );

const withoutBrandingCSS = css`
  text-decoration: underline;
`;

const withBrandingCSS = css`
  :hover {
    background: transparent;
    text-decoration: underline;
  }
`;

const styles: ButtonStyles = {
  primary: css`
    color: ${(props) => props.theme.colors.white};
    background: ${getBgColor};

    border: solid 4px ${getBgColor};
    box-sizing: border-box;

    :hover {
      background: ${getBgColor};
      /* a3 at the end adds 0.64 opacity */
      box-shadow: 0px 0px 8px ${getBgColor}a3;
    }
    :active {
      background: ${getBgColor};
      border: 4px solid ${getBorderColor};
    }
    :focus {
      background: ${getBgColor};
      /* a3 at the end adds 0.64 opacity */
      box-shadow: 0px 0px 8px ${getBgColor}a3;
    }
    ${(props: any) =>
      props.isDisabled &&
      css`
        background: ${getBgColor} !important;
        opacity: 0.4;
        box-shadow: none !important;
        border: none !important;
        cursor: not-allowed !important;
      `}
  `,
  secondary: css`
    background: transparent;
    box-sizing: border-box;
    border: 2px solid ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.text};

    & > svg {
      & > path {
        fill: ${getSvgFillColorForNormalState};
        stroke: ${getSvgStrokeColorForNormalState};
      }
    }

    :active {
      border-color: ${(props) => props.theme.colors.blue500};

      & > svg {
        & > path {
          fill: ${getSvgFillColorForActiveState};
          stroke: ${getSvgStrokeColorForActiveState};
        }
      }
    }
    :focus {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
    }

    ${(props: any) =>
      props.isDisabled &&
      css`
        border: 2px solid ${(props) => props.theme.colors.gray390};
        color: ${(props) => props.theme.colors.gray390};
        & > svg {
          & > path {
            fill: ${getSvgFillColorForDisabledState};
            stroke: ${getSvgStrokeColorForDisabledState};
          }
        }
      `}
  `,
  tertiary: css`
    background: transparent;
    border: solid 1px transparent;
    color: ${(props) => props.theme.colors.text};
    :hover {
      background: ${(props) => props.theme.colors.white50};
    }
    :active {
      background: ${(props) => props.theme.colors.black};
      color: ${(props) => props.theme.colors.white};

      svg path {
        stroke: ${(props) => props.theme.colors.white};
      }
    }
    ${(props: any) =>
      props.isDisabled &&
      css`
        color: ${(props) => props.theme.colors.gray300};
      `}
  `,
  link: css`
    background: transparent;
    border: solid 1px transparent;
    color: ${(props) => props.theme.colors.black};

    :hover {
      background: ${(props) => props.theme.colors.white50};
    }

    ${(props: any) =>
      props.isDisabled &&
      css`
        color: ${(props) => props.theme.colors.gray300};
      `}

    ${({ theme }) =>
      theme.colors.black === "#000000"
        ? withoutBrandingCSS
        : withBrandingCSS}

    :active {
      background: ${(props) => props.theme.colors.black};
      color: ${(props) => props.theme.colors.white};
    }
  `,
};

const commonButtonStyle = css<any>`
  padding: 12px 24px;
  user-select: none;
  width: fit-content;

  border: solid 1px black;
  border-radius: 4px;

  font-family: DM Sans;

  :focus {
    outline: none;
  }
  :hover {
    cursor: pointer;
  }

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
    `}
  ${(props) => {
    const c = props.theme.css;
    return [c.centered, c.buttonText];
  }};

  ${(props) => styles[props.variant || "primary"]};

  ${(props) =>
    props.isSmall &&
    css`
      padding: 6px 20px;
      font-size: 14px;
    `}
`;

export const ButtonWrapper = styled(Div)<BtnProps>`
  ${commonButtonStyle};
`;

export const ButtonElementWrapper = styled(HTMLButton)<BtnProps>`
  ${commonButtonStyle};
`;

export const ContentWrapper = styled(Div)`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
`;

const ButtonContentWrapper = ({ children, iconPlacement }: BtnProps) => {
  return (
    <>
      {iconPlacement === "left" && (
        <Div pr={12} h={20}>
          <PlusActionIcon />
        </Div>
      )}
      {children}
      {iconPlacement === "right" && (
        <Div pl={12} h={20}>
          <PlusActionIcon />
        </Div>
      )}
    </>
  );
};

export const Button = ({
  onClick = null,
  isDisabled = false,
  variant = "primary",
  isSmall = false,
  children,
  isButtonElement = false,
  iconPlacement,
  ...props
}: BtnProps): JSX.Element => {
  return (
    <>
      {isButtonElement ? (
        <ButtonElementWrapper
          isSmall={isSmall}
          isDisabled={isDisabled}
          disabled={isDisabled}
          variant={variant}
          type="submit"
          {...props}
        >
          <ButtonContentWrapper iconPlacement={iconPlacement}>
            {children}
          </ButtonContentWrapper>
        </ButtonElementWrapper>
      ) : (
        <ButtonWrapper
          role="button"
          w={"fit-content"}
          p={12}
          isSmall={isSmall}
          onClick={isDisabled ? null : onClick}
          onKeyDown={({ code }) => {
            if (
              (code === "Space" || (code === "Enter" && onClick)) &&
              !isDisabled
            ) {
              onClick();
            }
          }}
          tabIndex={0}
          {...{ variant, isDisabled, onClick, disabled: isDisabled, ...props }}
        >
          <ButtonContentWrapper iconPlacement={iconPlacement}>
            {children}
          </ButtonContentWrapper>
        </ButtonWrapper>
      )}
    </>
  );
};

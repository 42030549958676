import { CSSProperties, useEffect } from "react";
import toast, {
  ToastBar,
  ToastOptions,
  ToastPosition,
  Toaster,
  useToasterStore,
} from "react-hot-toast";
import styled from "styled-components";
import { colors } from "../styled/theme/colorPalette";
import { Div } from "../styled/utils";
import { CircleSpinner } from "./CircleSpinner";
import {
  greenCheckIcon,
  infoIcon,
  notificationCheckIcon,
  redXIcon,
  warningIcon,
} from "./icons";

const defaultDuration = 5000;
const defaultPosition: ToastPosition = "bottom-right";
const toastBorderRadius = 4;
const toastMaxWidth = "85%";

const LoadingNotifToastContainer = styled(Div)`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

type ToastProps = {
  zIndex?: number;
  toastStyle?: CSSProperties;
};

type ToastTypes = keyof typeof toastTypeStyles;

const genToastOptions = (type: ToastTypes, toastOptions: ToastOptions) => {
  const stylingByType = toastTypeStyles[type];

  const _toastOptions: ToastOptions = {
    duration: defaultDuration,
    position: defaultPosition,
    style: {
      background: stylingByType.backgroundColor,
      color: stylingByType.color,
    },
    icon: stylingByType.icon,
    ...toastOptions,
  };

  return _toastOptions;
};

export const addToast = (
  msg: JSX.Element | string,
  type: ToastTypes = "notification",
  toastOptions: ToastOptions = {}
): void => {
  const _toastOptions = genToastOptions(type, toastOptions);

  toast(msg, _toastOptions);
};

export const addLoadingToast = (
  msg: JSX.Element | string,
  helperMsg: JSX.Element | string,
  toastOptions: ToastOptions = {},
  minW = 320
): void => {
  const _toastOptions = genToastOptions("loadingNotification", toastOptions);

  let message = msg;

  if (msg && helperMsg) {
    message = (
      <LoadingNotifToastContainer minw={minW}>
        <span>{msg}</span> <span>{helperMsg}</span>
      </LoadingNotifToastContainer>
    );
  }

  toast(message, _toastOptions);
};

const toastContainerCss: CSSProperties = {
  padding: 0,
  marginRight: 72,
  borderRadius: toastBorderRadius,
  boxShadow: "none",
  maxWidth: toastMaxWidth,
};

const toastTypeStyles = {
  error: {
    color: colors.black,
    backgroundColor: colors.red250,
    icon: redXIcon,
  },
  warning: {
    color: colors.black,
    backgroundColor: colors.orange1100,
    icon: warningIcon,
  },
  success: {
    color: colors.text,
    backgroundColor: colors.blue50,
    icon: greenCheckIcon,
  },
  information: {
    color: colors.black,
    backgroundColor: colors.blue100,
    icon: infoIcon,
  },
  notification: {
    color: colors.white,
    backgroundColor: colors.black,
    icon: notificationCheckIcon,
  },
  loadingNotification: {
    color: colors.white,
    backgroundColor: colors.black,
    icon: <CircleSpinner />,
  },
};

const ToastWrapper = styled(Div)`
  padding: 10px;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
  border-radius: ${toastBorderRadius}px;
  ${(p) => p.theme.css.alignCenter};
`;

const IconWrapper = styled(Div)`
  margin-right: 10px;
`;

const Toast = ({ zIndex, toastStyle }: ToastProps) => {
  const store = useToasterStore();

  useEffect(() => {
    store.toasts.forEach((t) => {
      toast.dismiss(t.id);
    });
  }, []);

  return (
    <Toaster
      toastOptions={{
        style: toastContainerCss,
      }}
      containerStyle={{
        zIndex,
      }}
    >
      {(t) => {
        return (
          <ToastBar key={t.id} toast={t} style={{ opacity: t.visible ? 1 : 0 }}>
            {({ message, icon: hotToastIcon }) => {
              const { icon, color, backgroundColor } =
                toastTypeStyles[t.type] || {};
              return (
                <ToastWrapper c={color} bg={backgroundColor} style={toastStyle}>
                  <IconWrapper alignCenter>{icon || hotToastIcon}</IconWrapper>
                  <Div alignCenter>{message}</Div>
                  <Div p={5} clickable onClick={() => toast.dismiss(t.id)}>
                    {closeIcon}
                  </Div>
                </ToastWrapper>
              );
            }}
          </ToastBar>
        );
      }}
    </Toaster>
  );
};

export { Toast };

const closeIcon = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 2.5L7.82258 7.82258"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 7.82251L7.82258 2.49993"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import styled, { css } from "styled-components";
import { Div } from "fuse-shared-ui";

export const BillingSection = styled(Div)(
  ({ theme: { colors, css: c } }) => css`
    background: ${colors.white};
    border: solid 1px ${colors.outlineInputs};
    border-radius: 8px;
    padding: 24px;
  `
);

export const Wrapper = styled(BillingSection)`
  padding: 24px;
  margin-top: 48px;
  min-height: 88px;
  ${(p) => p.theme.css.spaceBetween};
`;

export const Title = styled(Div)`
  ${(p) => p.theme.textStyles.wlabel2};
  display: flex;
  align-items: center;
  margin-right: 8px;
  white-space: nowrap;
`;

import { FormikAutocomplete } from "components/common/form/fields";
import { useMemo } from "react";
import { validationsByColumnType } from "fuse-shared-ui";
import { useFormikContext, getIn } from "formik";
import { ColumnFormValue } from "../../types";

type Props = {
  name: string;
  type: string;
  isDisabled?: boolean;
  includeDefaultOptions?: boolean;
};

const validationOptionFilters = {
  excludeOddEvenConflicts: (currentValidationType, validations, options) => {
    if (currentValidationType === "odd" || currentValidationType === "even") {
      return options;
    }
    if (
      validations.some(
        (val) => val.validation_type === "odd" || val.validation_type === "even"
      )
    ) {
      return options.filter(
        (option) => option.value !== "odd" && option.value !== "even"
      );
    }
    return options;
  },
  excludeLengthConflicts: (currentValidationType, validations, options) => {
    const lengthValidations = ["max_length", "min_length", "length_exactly"];

    const hasLengthValidation = validations.some(
      (val) =>
        lengthValidations.includes(val.validation_type) &&
        val.validation_type !== currentValidationType
    );

    if (hasLengthValidation) {
      return options.filter(
        (option) => !lengthValidations.includes(option.value)
      );
    }

    return options;
  },
};

const ValidationTypeSelect = ({
  name,
  type,
  isDisabled,
  includeDefaultOptions,
}: Props) => {
  const { values } = useFormikContext<ColumnFormValue>();
  const validations = values.validations;

  const index = parseInt(name.split(".")[1], 10);
  const currentValidationType = getIn(values, `validations.${index}`)
    .validation_type;

  const options = useMemo(() => {
    const _options = (validationsByColumnType[type] ?? []).filter((v) =>
      includeDefaultOptions ? true : !v.isDefaultValidation
    );
    return Object.values(validationOptionFilters).reduce(
      (filteredOptions, filter) => {
        return filter(currentValidationType, validations, filteredOptions);
      },
      _options
    );
  }, [type, includeDefaultOptions, validations]);

  return (
    <FormikAutocomplete
      name={`${name}.validation_type`}
      label="Select Validation"
      placeholder="Select option"
      isDisabled={isDisabled}
      options={options}
    />
  );
};

export default ValidationTypeSelect;

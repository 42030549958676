import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import styled from "styled-components";
import { addToast, api, chevronLeftIcon, Div } from "fuse-shared-ui";
import Form, { FormikSumbitButton } from "components/common/form/Form";
import { FormikTextInput } from "components/common/form/fields";
import { yupEmail } from "components/common/form/validations";
import { SIGN_IN_ROUTE } from ".";
import {
  flatironsFuseLogo,
  checkYourEmailBackgroundPart1,
  checkYourEmailBackgroundPart2,
  checkYourEmailBackgroundPart3,
} from "./common/assets/images";

const Wrapper = styled(Div)`
  width: 100vw;
  overflow-x: hidden;
  ${(props) => {
    const c = props.theme.css;
    return [c.grow, c.justifyCenter, c.yscroll, c.pRelative];
  }};
`;

const Container = styled(Div)`
  margin: 80px 160px;
  min-height: 740px;
  max-height: 740px;
  height: 100%;
  max-width: 1120px;
  width: 100%;
  position: relative;
  @media all and (max-width: 980px) {
    margin: 80px;
  }
  @media all and (max-width: 840px) {
    margin: 24px;
  }
`;

const ContentWrapper = styled(Div)`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 40px ${(props) => props.theme.colors.red2000};
  border-radius: 16px;
  position: relative;
  z-index: 10;
`;

const ContentHeader = styled(Div)`
  padding: 64px 0 80px 80px;
  width: 50%;
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 1240px) {
    padding: 64px 0 0;
    width: unset;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media all and (max-width: 768px) {
    padding: 24px 24px 58px;
  }
`;

const BackToLogin = styled(Div)`
  display: flex;
  align-items: center;
  ${(props) => props.theme.textStyles.wlink}
  * {
    stroke: ${(props) => props.theme.colors.black};
  }
  @media all and (max-width: 1240px) {
    width: 100%;
    justify-content: left;
  }
`;

const FlatironsFuseLogoWrapper = styled(Div)`
  @media all and (max-width: 1240px) {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
`;

const Content = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media all and (max-width: 840px) {
    padding: 24px;
  }
`;

const TextWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextTitle = styled(Div)`
  ${(props) => props.theme.textStyles.wh1}
  @media all and (max-width: 1220px) {
    text-align: center;
  }
  @media all and (max-width: 768px) {
    ${(props) => props.theme.textStyles.wh2}
  }
`;

const TextSubtitle = styled(Div)`
  margin-top: 12px;
  max-width: 400px;
  color: ${(props) => props.theme.colors.gray700};
  ${(props) => props.theme.textStyles.wbody1}
  display: flex;
  text-align: center;
`;

const FormContentWrapper = styled(Div)`
  margin-top: 40px;
  max-width: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 50;
  @media all and (max-width: 720px) {
    margin-top: unset;
  }
`;

const FormContentItem = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media all and (max-width: 720px) {
    max-width: unset;
    margin-top: 40px;
  }
`;

// const TextResendEmail = styled(Div)`
//   margin-top: 16px;
//   display: flex;
//   justify-content: center;
//   color: ${(props) => props.theme.colors.gray700};
//   ${(props) => props.theme.textStyles.wlink};
//   :hover {
//     cursor: text;
//     text-decoration-line: none;
//     opacity: unset;
//   }
//   @media all and (max-width: 720px) {
//     justify-content: center;
//     flex-wrap: wrap;
//     white-space: nowrap;
//   }
// `;

// const TextResendEmailLink = styled(Div)`
//   margin-left: 5px;
//   color: ${(props) => props.theme.colors.pink500};
//   ${(props) => props.theme.textStyles.wlink};
// `;

const BackgroundPart1 = styled(Div)`
  margin-top: -46px;
  margin-bottom: -33.58px;
  margin-left: -126px;
  max-width: 698px;
  min-width: 698px;
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  @media all and (max-width: 840px) {
    display: none;
  }
`;

const BackgroundPart2 = styled(Div)`
  margin-top: 371.19px;
  max-width: 534px;
  min-width: 534px;
  width: 100%;
  max-height: 373px;
  min-height: 373px;
  pointer-events: none;
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  @media all and (max-width: 840px) {
    display: none;
  }
`;

const BackgroundPart3 = styled(Div)`
  margin-top: 557px;
  margin-left: 262.88px;
  max-width: 907px;
  width: 100%;
  opacity: 80%;
  display: flex;
  position: absolute;
  z-index: 30;
  left: 0;
  top: 0;
  @media all and (max-width: 1240px) {
    display: none;
  }
`;

const emailField = {
  label: "Email Address",
  name: "email",
  placeholder: "Enter your email address",
  initialValue: "",
  validation: yupEmail,
};

const ForgotPassword = () => {
  const history = useHistory();

  const onSubmit = async ({ email }) => {
    const { data, status } = await api.post(
      `/users/reset_password_mailer?email=${encodeURIComponent(email)}`
    );

    if (status === 200)
      addToast("Email sent successfully", "success", {
        position: "bottom-center",
      });
    else addToast(data.message, "error", { position: "bottom-center" });
  };

  const backgroundImagesListUI = (
    <>
      <BackgroundPart1 key={1}>{checkYourEmailBackgroundPart1}</BackgroundPart1>
      <BackgroundPart2 key={2}>{checkYourEmailBackgroundPart2}</BackgroundPart2>
      <BackgroundPart3 key={3}>{checkYourEmailBackgroundPart3}</BackgroundPart3>
    </>
  );

  const textUI = (
    <TextWrapper>
      <TextTitle data-cy="title">Forgot your password?</TextTitle>
      <TextSubtitle data-cy="sub-title">
        No worries, we’ll send you an email with instructions to reset your
        password.
      </TextSubtitle>
    </TextWrapper>
  );

  const formUI = (
    <FormContentWrapper>
      <Form fields={[emailField]} onSubmit={onSubmit}>
        <>
          <FormContentItem>
            <FormikTextInput
              label={emailField.label}
              placeholder={emailField.placeholder}
              name={emailField.name}
              type="text"
            />
          </FormContentItem>
          <FormikSumbitButton data-cy="send-email" mt={40}>
            Send Email
          </FormikSumbitButton>
        </>
      </Form>
    </FormContentWrapper>
  );

  return (
    <>
      <Helmet>
        <title data-cy="forget-password-title">
          Flatirons Fuse - Forgot Password
        </title>
      </Helmet>
      <Wrapper>
        <Container>
          {backgroundImagesListUI}
          <ContentWrapper>
            <ContentHeader>
              <BackToLogin onClick={() => history.push(SIGN_IN_ROUTE)}>
                {chevronLeftIcon}
                <Div data-cy="back-to-login" m={4}>
                  Back to Login
                </Div>
              </BackToLogin>
              <FlatironsFuseLogoWrapper>
                {flatironsFuseLogo}
              </FlatironsFuseLogoWrapper>
            </ContentHeader>
            <Content>
              {textUI}
              {formUI}
            </Content>
          </ContentWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

export default ForgotPassword;

import { createContext, useContext } from "react";

type State = {
  templateHeaderLabel: string;
};
const EnumFieldMatcherContext = createContext<State>({} as State);

type Props = {
  templateHeaderLabel: string;
  children: JSX.Element;
};
export const EnumFieldMatcherContextProvider = ({
  templateHeaderLabel,
  children,
}: Props) => {
  return (
    <EnumFieldMatcherContext.Provider value={{ templateHeaderLabel }}>
      {children}
    </EnumFieldMatcherContext.Provider>
  );
};

export const useEnumFieldMatcherContext = () =>
  useContext(EnumFieldMatcherContext);

import { useState } from "react";
import { useWindowDimensions } from "../../../../../../../hooks";
import { DesktopUI } from "./Desktop";
import { DropdownDesktopUI } from "./Dropdown/Desktop";
import { DropdownMobileUI } from "./Dropdown/Mobile";
import { MobileUI } from "./Mobile";

export const UserMenu = () => {
  const { width } = useWindowDimensions();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <>
      {width > 599 && (
        <>
          <DesktopUI setIsDropdownOpen={setIsDropdownOpen} />

          <DropdownDesktopUI
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
          />
        </>
      )}

      {width <= 599 && (
        <>
          <MobileUI setIsDropdownOpen={setIsDropdownOpen} />

          {isDropdownOpen && (
            <DropdownMobileUI setIsDropdownOpen={setIsDropdownOpen} />
          )}
        </>
      )}
    </>
  );
};

import { colors, Div } from "fuse-shared-ui";
import React from "react";
import styled, { css } from "styled-components";

type WizardHeaderProps = {
  steps: string[];
  activeIndex: number;
  showShadow?: boolean;
  disabledSteps?: number[];
};

type StepHeaderProps = {
  index: number;
  label: string;
  disabled?: boolean;
};

type StepHeaderContainerProps = {
  disabled?: boolean;
};

const StepHeaderContainer = styled(Div)<StepHeaderContainerProps>`
  ${({ disabledWhen }) =>
    disabledWhen &&
    css`
      opacity: 0.5;
      user-select: none;
    `}
`;

const ActiveStepHeader = ({ index, label }: StepHeaderProps) => {
  return (
    <Div dflex centered>
      <Div
        c={colors.white}
        centered
        css={"flex-shrink: 0;"}
        w={32}
        h={32}
        bg={colors.blue500}
        br={50}
        stepper
      >
        {index + 1}
      </Div>
      <Div ml={8} label1 truncateText>
        {label}
      </Div>
    </Div>
  );
};

const InactiveStepHeader = ({ index, label, disabled }: StepHeaderProps) => {
  return (
    <StepHeaderContainer dflex centered disabledWhen={disabled}>
      <Div
        c={colors.gray390}
        centered
        w={24}
        h={24}
        br={50}
        stepper
        css={`
          border: 1px solid ${colors.gray390};
          flex-shrink: 0;
        `}
      >
        {index + 1}
      </Div>
      <Div ml={8} label1 c={colors.gray390} truncateText>
        {label}
      </Div>
    </StepHeaderContainer>
  );
};

const CompletedStepHeader = ({ label }: StepHeaderProps) => {
  return (
    <Div dflex centered>
      <Div
        css={"flex-shrink: 0;"}
        c={colors.white}
        centered
        w={24}
        h={24}
        br={50}
        stepper
      >
        {checkIcon}
      </Div>
      <Div ml={8} label1 truncateText>
        {label}
      </Div>
    </Div>
  );
};

export const WizardHeader = ({
  steps,
  activeIndex,
  showShadow,
  disabledSteps,
}: WizardHeaderProps) => {
  function renderStep(step: string, index: number) {
    const isLast = index === steps.length - 1;
    const isActive = index === activeIndex;
    const isCompleted = activeIndex > index;
    const StepHeader = isActive
      ? ActiveStepHeader
      : isCompleted
      ? CompletedStepHeader
      : InactiveStepHeader;

    return (
      <React.Fragment key={`step-${index}`}>
        <StepHeader
          disabled={disabledSteps && disabledSteps.includes(index)}
          index={index}
          label={step}
        />
        {isLast ? null : (
          <Div ml={15} mr={15}>
            {arrowRightIcon}
          </Div>
        )}
      </React.Fragment>
    );
  }

  return (
    <Div w100 dflex centered>
      {React.Children.map(steps, renderStep)}
    </Div>
  );
};

export default WizardHeader;

const arrowRightIcon = (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 1L8.46317 7.96317L1.5 14.9263"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const checkIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM9.70711 11.2426C9.31658 10.8521 8.68342 10.8521 8.29289 11.2426C7.90237 11.6331 7.90237 12.2663 8.29289 12.6568L10.4142 14.7781L11.1213 15.4852L11.8284 14.7781L16.0711 10.5355C16.4616 10.145 16.4616 9.5118 16.0711 9.12128C15.6805 8.73076 15.0474 8.73076 14.6569 9.12128L11.1213 12.6568L9.70711 11.2426Z"
      fill={colors.blue500}
    />
  </svg>
);

import { AppLoading } from "components/AppLoading";
import { Header } from "components/common/Header";
import { SectionContainer } from "components/common/SectionContainer";
import {
  Button,
  Div,
  Table as TableBase,
  TableDataProvider,
  colors,
  useTableContext,
  TableViewWrapper,
} from "fuse-shared-ui";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useUserContext } from "../../../../components/UserContextProvider";
import { BlankState } from "../../../common/Table/BlankState";
import ImportFileModal from "./ImportFileModal";
import { tableColumns } from "./tableColumns";
import styled, { css } from "styled-components";

const Table = styled(TableBase)(
  () => css`
    ${TableViewWrapper} {
      border: solid 1px ${(p) => p.theme.colors.gray300};
    }
  `
);

const Content = ({ testId, importButton }) => {
  const { data, isLoading } = useTableContext();

  if (isLoading) {
    return <AppLoading />;
  }

  return data?.length ? (
    <Table
      columns={tableColumns}
      data-testid={testId}
      disableSorting
      withoutBorders
    />
  ) : (
    <Div mt={112} w100 centered>
      <BlankState
        title="Import Data"
        description="Choose an Importer to import data and create File Imports"
        button={importButton}
      />
    </Div>
  );
};

const ImportsRoutesWithContext = ({ testId }: { testId?: string }) => {
  const [importModalOpen, setImportModalOpen] = useState(false);
  const { getData } = useTableContext();

  const importButton = (
    <Button
      data-cy="import-file-button"
      isSmall
      onClick={() => setImportModalOpen(true)}
    >
      Import File
    </Button>
  );

  const headerUI = (
    <Div spaceBetween>
      <Helmet>
        <title>File Imports</title>
      </Helmet>

      <Header
        testID="importsTitle"
        title="File Imports"
        rightContent={importButton}
      />
    </Div>
  );

  return (
    <SectionContainer c={colors.gray800} flexColumn>
      {headerUI}
      <Div mt={24} dflex>
        <Content testId={testId} importButton={importButton} />
      </Div>

      {importModalOpen && (
        <ImportFileModal
          isOpen={importModalOpen}
          onClose={() => {
            setImportModalOpen(false);
          }}
          onImportClose={getData}
        />
      )}
    </SectionContainer>
  );
};

const ImportsRoutes = ({ testId }: { testId?: string }) => {
  const {
    user: { active_organization },
  } = useUserContext();

  return (
    <TableDataProvider
      dataUrl={`/api/v1/organizations/${active_organization.id}/imports`}
    >
      <ImportsRoutesWithContext testId={testId} />
    </TableDataProvider>
  );
};

export default ImportsRoutes;

import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Div } from "fuse-shared-ui";
import { useQueryParams } from "hooks";
import Form, {
  FormikSumbitButton as FormikSumbitButtonBase,
} from "components/common/form/Form";
import { FormikTextInput } from "components/common/form/fields";
import { yupEmail, yupRequired } from "components/common/form/validations";
import { useUserContext } from "../../UserContextProvider";
import { useAppContext } from "../../AppContextProvider";
import { FORGOT_PASSWORD_ROUTE, SIGN_UP_ROUTE } from ".";
import { flatironsFuseLogo } from "./common/assets/images";
import {
  containerBackground,
  containerHalfEllipse,
  containerSmallCircle,
  background,
  backgroundCircles,
  backgroundEllipse,
  backgroundQuarterEllipse,
  backgroundYellowHalfEllipse,
} from "./common/assets/signIn-images";
import { ExternalAuthLoginButton } from "./common/ExternalAuthLoginButton";
import { URLParams } from "../AccountRoutes/TemplateRoutes/TemplateColumns/types";

const Wrapper = styled(Div)`
  ${(props) => {
    const c = props.theme.css;
    return [c.grow, c.justifyCenter, c.yscroll, c.pRelative];
  }};
`;

const PreContentContainer = styled(Div)`
  position: relative;
  margin: 80px 160px;
  min-height: 740px;
  max-height: 740px;
  max-width: 1120px;
  width: 100%;
  @media all and (max-width: 980px) {
    margin: 80px;
    max-height: unset;
    overflow-y: hidden;
  }
  @media all and (max-width: 840px) {
    margin: 24px;
  }
`;

const ContentContainer = styled(Div)`
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 40px rgba(135, 70, 70, 0.08);
`;

const TransparentBackground = styled(Div)`
  margin: -24px;
  min-width: auto;
  background: linear-gradient(
    59.64deg,
    #ffffff -7.55%,
    rgba(255, 255, 255, 0) 42.54%
  );
  mix-blend-mode: overlay;
  border-radius: 32px;
  transform: rotate(-180deg);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  @media all and (max-width: 1330px) {
    display: none;
  }
`;

const FormContentWrapper = styled(Div)`
  padding: 64px;
  max-width: 560px;
  @media all and (max-width: 1330px) {
    max-width: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media all and (max-width: 840px) {
    padding: 24px;
  }
`;

const LogoWrapper = styled(Div)`
  width: 100%;
  @media all and (max-width: 1330px) {
    display: flex;
    justify-content: center;
  }
`;

const FormContentItem = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media all and (max-width: 720px) {
    max-width: unset;
    display: block;
  }
`;

const FormikSumbitButton = styled(FormikSumbitButtonBase)`
  margin-right: 16px;
  @media all and (max-width: 900px) {
    margin-right: unset;
    margin-bottom: 16px;
  }
`;

const StyledExternalAuthLoginButton = styled(ExternalAuthLoginButton)`
  margin-left: 16px;
  @media all and (max-width: 900px) {
    margin-left: unset;
    margin-top: 16px;
  }
`;

const FormContentButtonsWrapper = styled(Div)`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Title = styled(Div)`
  margin-top: 40px;
  ${(props) => props.theme.textStyles.wh1};
  @media all and (max-width: 1330px) {
    text-align: center;
  }
`;

const Subtitle = styled(Div)`
  margin-top: 12px;
  ${(props) => props.theme.textStyles.wbody1};
  @media all and (max-width: 1330px) {
    text-align: center;
  }
`;

const ForgotPasswordLink = styled(Div)`
  color: ${(props) => props.theme.colors.pink500};
  ${(props) => props.theme.textStyles.wlink};
`;

const TextSignUp = styled(Div)`
  color: ${(props) => props.theme.colors.gray700};
  ${(props) => props.theme.textStyles.wlink};
  :hover {
    cursor: text;
    text-decoration-line: none;
    opacity: unset;
  }
  @media all and (max-width: 720px) {
    justify-content: center;
  }
`;

const TextLink = styled(Div)`
  margin: 0 5px;
  ${(props) => {
    const c = props.theme.css;
    return [c.link, c.justifyEnd];
  }};
  color: ${(props) => props.theme.colors.pink500};
  ${(props) => props.theme.textStyles.wlink};
`;

const ImagesContainer = styled(Div)`
  @media all and (max-width: 1330px) {
    display: none;
  }
`;

const ContainerBackgroundImage = styled(Div)`
  position: absolute;
  top: 0;
  right: 0;
`;

const ContainerHalfEllipse = styled(Div)`
  position: absolute;
  height: 240px;
  top: 250px;
  right: 0;
`;

const ContainerSmallCircle = styled(Div)`
  position: absolute;
  height: 64px;
  top: 458px;
  right: -32px;
`;

const BackgroundImagesContainer = styled(Div)`
  @media all and (max-width: 1330px) {
    display: none;
  }
`;

const BackgroundImage = styled(Div)`
  position: absolute;
  bottom: -64px;
  right: -140px;
`;

const BackgroundCircles = styled(Div)`
  position: absolute;
  height: 240px;
  top: 250px;
  right: -120px;
`;

const BackgroundEllipse = styled(Div)`
  position: absolute;
  height: 260px;
  top: 240px;
  right: -130px;
`;

const BackgroundQuarterEllipse = styled(Div)`
  position: absolute;
  height: 120px;
  top: 250px;
  right: -120px;
`;

const BackgroundYellowHalfEllipse = styled(Div)`
  position: absolute;
  height: 120px;
  top: 370px;
  right: -120px;
`;

const fields = [
  {
    label: "Email Address",
    name: "email",
    placeholder: "Enter your email address",
    initialValue: "",
    validation: yupEmail,
  },
  {
    label: "Password",
    name: "password",
    placeholder: "Enter Password",
    initialValue: "",
    inputProps: {
      type: "password",
    },
    validation: yupRequired,
  },
];

const fieldsUI = (
  <>
    {fields.map(({ name, label, placeholder, inputProps }, index) => (
      <FormContentItem key={name} mt={index === 0 ? 40 : 24} maxh={96}>
        <FormikTextInput
          {...{ label, placeholder, name }}
          type={inputProps?.type || "text"}
        />
      </FormContentItem>
    ))}
  </>
);

const SignIn = (): JSX.Element => {
  const history = useHistory();
  const { signIn, githubSignIn, externalAuthSignIn } = useUserContext();
  const {
    isLoadingApp: isLoading,
    setIsLoadingApp: setIsLoading,
  } = useAppContext();
  const { code: authCode, state: authState } = useQueryParams([
    "code",
    "state",
  ]);
  const { auth_provider: authProvider } = useParams<URLParams>();

  useEffect(() => {
    if (!authCode) return;
    (async () => {
      setIsLoading(true);
      try {
        if (authProvider) {
          await externalAuthSignIn(authProvider, authCode, authState);
        } else {
          await githubSignIn(authCode);
        }
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    })();
  }, [authCode, authProvider]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    const { email, password } = values;
    try {
      await signIn(email, password);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const forgotPasswordUI = (
    <ForgotPasswordLink
      data-cy="forget-password-link"
      mt={24}
      onClick={() => history.push(FORGOT_PASSWORD_ROUTE)}
    >
      Forgot your password?
    </ForgotPasswordLink>
  );

  const formUI = (
    <FormContentWrapper>
      <LogoWrapper>{flatironsFuseLogo}</LogoWrapper>
      <Title data-cy="sign-in-title">Sign In</Title>
      <Subtitle data-cy="sign-in-sub-title">
        Data onboarding made fast, easy, and painless.
      </Subtitle>
      <Form fields={fields} onSubmit={onSubmit}>
        <>
          {fieldsUI}
          {forgotPasswordUI}
          <FormContentButtonsWrapper mt={40}>
            <FormikSumbitButton isDisabled={isLoading} data-cy="SignIn">
              Sign In
            </FormikSumbitButton>
            Or
            <StyledExternalAuthLoginButton
              provider={authProvider}
              isDisabled={isLoading}
            />
          </FormContentButtonsWrapper>
          <TextSignUp data-cy="not-have-account" mt={24} dflex>
            Don’t have an account?
            <TextLink
              data-cy="sign-up"
              disabledWhen={isLoading}
              onClick={() => {
                const route = authProvider
                  ? `${SIGN_UP_ROUTE}/${authProvider}`
                  : SIGN_UP_ROUTE;
                history.push(route);
              }}
            >
              Sign Up
            </TextLink>
          </TextSignUp>
        </>
      </Form>
    </FormContentWrapper>
  );

  const imagesUI = (
    <ImagesContainer>
      <ContainerBackgroundImage>{containerBackground}</ContainerBackgroundImage>
      <ContainerHalfEllipse>{containerHalfEllipse}</ContainerHalfEllipse>
      <ContainerSmallCircle>{containerSmallCircle}</ContainerSmallCircle>
    </ImagesContainer>
  );

  return (
    <>
      <Helmet>
        <title>Sign In - Flatirons Fuse</title>
        <meta name="title" content="Sign In - Flatirons Fuse" />
        <meta
          name="description"
          content="Sign In to Flatirons Fuse to start making beautiful, custom branded CSV and spreadsheet imports within minutes."
        />
      </Helmet>
      <Wrapper>
        <PreContentContainer>
          <ContentContainer br={16}>
            <TransparentBackground />
            {formUI}
            {imagesUI}
          </ContentContainer>
          <BackgroundImagesContainer>
            <BackgroundImage>{background}</BackgroundImage>
            <BackgroundCircles>{backgroundCircles}</BackgroundCircles>
            <BackgroundEllipse>{backgroundEllipse}</BackgroundEllipse>
            <BackgroundQuarterEllipse>
              {backgroundQuarterEllipse}
            </BackgroundQuarterEllipse>
            <BackgroundYellowHalfEllipse>
              {backgroundYellowHalfEllipse}
            </BackgroundYellowHalfEllipse>
          </BackgroundImagesContainer>
        </PreContentContainer>
      </Wrapper>
    </>
  );
};

export default SignIn;

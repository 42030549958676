import { Div } from "../../styled";
import { Button, Modal } from "../../common";
import { errorIcon } from "./common/icons";
import { useTranslation } from "react-i18next";

type UnmatchedHeadersModalProps = {
  isUnmatchedHeadersModalOpen: boolean;
  unmatchedHeadersCount: number;
  onSetStep: () => void;
  onCloseUnmatchedHeadersModal: () => void;
};

export const UnmatchedHeadersModal = ({
  isUnmatchedHeadersModalOpen,
  unmatchedHeadersCount,
  onSetStep,
  onCloseUnmatchedHeadersModal,
}: UnmatchedHeadersModalProps) => {
  const skipStep = () => {
    onSetStep();
    onCloseUnmatchedHeadersModal();
  };

  const { t } = useTranslation("general");

  return (
    <Modal
      isOpen={isUnmatchedHeadersModalOpen}
      onClose={() => onCloseUnmatchedHeadersModal()}
    >
      <Div p="17px 20px">
        <Div textAlignCenter mb={24} mt={5}>
          {errorIcon}
        </Div>
        <Div textAlignCenter h5>
          {t("unmatched_headers_modal.title")}
        </Div>
        <Div w={448} mt={12} textAlignCenter body1>
          {unmatchedHeadersCount === 1
            ? t("unmatched_headers_modal.description_singular", {
                count: unmatchedHeadersCount,
              })
            : t("unmatched_headers_modal.description_plural", {
                count: unmatchedHeadersCount,
              })}
        </Div>
        <Div dflex centered mt={40}>
          <Button
            variant="secondary"
            onClick={() => onCloseUnmatchedHeadersModal()}
            w={160}
            mr={16}
          >
            {t("unmatched_headers_modal.assign_headers")}
          </Button>
          <Button data-cy="skip" variant="primary" onClick={skipStep}>
            {t("unmatched_headers_modal.skip")}
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};

import { FormikCheckbox, FormikTextInput } from "components/common/form/fields";
import { Div } from "fuse-shared-ui";
import { columnField } from "../../fields";
import ColumnTypeSelect from "../ColumnTypeSelect";
import DropdownValueFields from "../DropdownValueFields";
import Header from "../../../../components/Header";
import PatternField from "../PatternField";
import { FieldTypes } from "fuse-importer";

type Props = {
  isEditing: boolean;
  showPatternField: boolean;
  columnType: string;
};

const RequiredCheckboxField = () => (
  <Div mt={24} data-cy="requiredField">
    <FormikCheckbox name="required" label="Mark column as required" />
  </Div>
);

const ColumnFields = () => (
  <>
    {columnField.map((f, index) => {
      const { label, placeholder, name, labelTooltip } = f;
      return (
        <Div key={index} mb={24}>
          <FormikTextInput
            {...{ label, placeholder, name, labelTooltip }}
            type={"text"}
          />
        </Div>
      );
    })}
  </>
);

const ColumnForm = ({ isEditing, columnType, showPatternField }: Props) => {
  const title = isEditing ? "Edit a Column" : "Add a Column";
  const subTitle = isEditing
    ? "Edit a Column in your importer by changing the fields below"
    : "Add a Column to your importer by filling the required fields";

  return (
    <>
      <Header title={title} subTitle={subTitle} />
      <ColumnFields />
      <ColumnTypeSelect />
      <RequiredCheckboxField />
      {columnType === FieldTypes.enum && <DropdownValueFields />}
      {showPatternField && <PatternField columnType={columnType} />}
    </>
  );
};

export default ColumnForm;

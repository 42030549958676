import { useHistory, useRouteMatch } from "react-router";
import {
  ACCOUNT_TEAM_ROUTE,
  ACCOUNT_TEMPLATES_ROUTE,
  DEVELOPERS_ROUTE,
  IMPORTER_STYLE_PREFERENCES_ROUTE,
  IMPORTS_ROUTE,
} from "../../..";
import { useFuseDocumentation } from "../../../../hooks";
import {
  brandingIcon,
  brandingIconActive,
  developerIcon,
  developerIconActive,
  documentationIcon,
  importsIcon,
  importsIconActive,
  teamIcon,
  teamIconActive,
  templatesIcon,
  templatesIconActive,
} from "../icons";

export const useNavigation = () => {
  const history = useHistory();

  const isAccountTeamRoute = !!useRouteMatch(ACCOUNT_TEAM_ROUTE);
  const isTemplatesRoute = !!useRouteMatch(ACCOUNT_TEMPLATES_ROUTE);
  const isImportsRoute = !!useRouteMatch(IMPORTS_ROUTE);
  const isDevelopersRoute = !!useRouteMatch(DEVELOPERS_ROUTE);
  const isImporterStylePreferenceRoute = !!useRouteMatch(
    IMPORTER_STYLE_PREFERENCES_ROUTE
  );

  const documentation = useFuseDocumentation();

  const navItems = [
    {
      dataCy: "importersLink",
      label: "Importers",
      path: ACCOUNT_TEMPLATES_ROUTE,
      icon: isTemplatesRoute ? templatesIconActive : templatesIcon,
      onClick: () => history.push(ACCOUNT_TEMPLATES_ROUTE),
    },
    {
      dataCy: "importsLink",
      label: "File Imports",
      path: IMPORTS_ROUTE,
      icon: isImportsRoute ? importsIconActive : importsIcon,
      onClick: () => history.push(IMPORTS_ROUTE),
    },
    {
      dataCy: "teamLink",
      label: "Team",
      path: ACCOUNT_TEAM_ROUTE,
      icon: isAccountTeamRoute ? teamIconActive : teamIcon,
      onClick: () => history.push(ACCOUNT_TEAM_ROUTE),
    },
    {
      path: IMPORTER_STYLE_PREFERENCES_ROUTE,
      label: "Branding",
      dataCy: "brandingLink",
      icon: isImporterStylePreferenceRoute ? brandingIconActive : brandingIcon,
      onClick: () => history.push(IMPORTER_STYLE_PREFERENCES_ROUTE),
    },
    {
      path: DEVELOPERS_ROUTE,
      label: "Developers",
      dataCy: "developerLink",
      icon: isDevelopersRoute ? developerIconActive : developerIcon,
      onClick: () => history.push(DEVELOPERS_ROUTE),
    },
    {
      path: "/testpath",
      label: "Documentation",
      icon: documentationIcon,
      onClick: documentation.open,
    },
  ];

  return { navItems };
};

import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { SpreadsheetOptions } from "fuse-importer";
import { Field, RecordDataSet } from "fuse-importer";
import { SpreadsheetProps } from "../common/types";
import { useFilteredDataContext } from "../FilteredDataContextProvider";
import { useValidationContext } from "../ValidationContextProvider";
import { CellSelectionState, useCellSelection } from "./useCellSelection";

export type OnDataSetChanged = (dataSet: RecordDataSet) => void;

type State = {
  filteredDataIds: string[];
  fields: Field[];
  isReadOnly?: boolean;
  options: SpreadsheetOptions;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  lastClickedIndexRef: React.MutableRefObject<number | null>;
  checkboxColumnWidth: number;
  inefficientRerender: boolean;
  setInefficientRerender: React.Dispatch<React.SetStateAction<boolean>>;
} & CellSelectionState;

const SpreadsheetContext = createContext<State>({} as State);

type Props = SpreadsheetProps & {
  children: JSX.Element;
};

export const SpreadsheetContextProvider = ({
  fields,
  options,
  isReadOnly,
  children,
  onValidateRecord,
  selectedRows,
  setSelectedRows,
}: Props) => {
  const [inefficientRerender, setInefficientRerender] = useState(false);
  const { filteredDataIds } = useFilteredDataContext();

  const {
    calculateColumnErrorCounts,
    calculateColumnWarningCounts,
    warnings,
    errors,
    ...validationState
  } = useValidationContext();

  const lastClickedIndexRef = useRef<number | null>(null);

  const checkboxColumnWidth = useMemo(() => {
    const lastRowIndexLength = filteredDataIds.length.toString().length;
    const singleLetterWidth = 15;
    const horizontalSpacing = 8;
    const baseCheckboxColumnWidth =
      lastRowIndexLength * singleLetterWidth + horizontalSpacing;
    return baseCheckboxColumnWidth < 40 ? 40 : baseCheckboxColumnWidth;
  }, [filteredDataIds.length]);

  const value = {
    filteredDataIds,
    fields,
    ...validationState,
    isReadOnly,
    options,
    onValidateRecord,
    selectedRows,
    setSelectedRows,
    lastClickedIndexRef,
    checkboxColumnWidth,
    inefficientRerender,
    setInefficientRerender,
    ...useCellSelection(fields),
  };

  return (
    <SpreadsheetContext.Provider value={value}>
      {children}
    </SpreadsheetContext.Provider>
  );
};

export const useSpreadsheetContext = () => useContext(SpreadsheetContext);

import { FormikTextInput } from "components/common/form/fields";
import { Div } from "fuse-shared-ui";
import { useTransformationForm } from "../../hooks";
import TransformationTypeSelect from "../TransformationTypeSelect";
import TransformationOptions from "./TransformationOption";

type TransformationFormProps = {
  name: string;
  columnType: string;
};

const TransformationForm = ({ name, columnType }: TransformationFormProps) => {
  const { transformation, hasOptions } = useTransformationForm({ name });

  return (
    <Div w100 flexColumn>
      <Div w100 dflex alignStart mb={16}>
        <Div grow>
          <TransformationTypeSelect name={name} type={columnType} />
        </Div>
      </Div>
      {hasOptions ? (
        <TransformationOptions
          name={`${name}.options`}
          options={transformation.options}
        />
      ) : null}
    </Div>
  );
};

export default TransformationForm;

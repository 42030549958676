import { useReviewContext } from "../../../Importer";
import { useValidationContext } from "../../../Importer/common/Spreadsheet/ValidationContextProvider";
import { CustomActions } from "./CustomActions";
import { DeleteSelectedRows } from "./DeleteSelectedRows";
import { ExportData } from "./ExportData";
import { FilterByErrors } from "./FilterByErrors/FilterByErrors";
import { RowFilters } from "./RowFilters";
import { SearchRows } from "./SearchRows";
import * as S from "./TableActions.styles";
import { TransformedRecordsCount } from "./TransformedRecordsCount";
import ValidationProgress from "./ValidationProgress";
import { defaultTableActionsHeightPx } from "./common";

export type TableActionsContainerProps = {
  height: number;
};

type TableActionsProps = {
  tableActionsHeight?: number;
};

export const TableActions = ({
  tableActionsHeight = defaultTableActionsHeightPx,
  ...props
}: TableActionsProps) => {
  const {
    hasTransformedRecords,
    setHasTransformedRecords,
  } = useReviewContext();
  const { isValidationCompleted } = useValidationContext();

  const onCloseTransformsTooltip = () => {
    setHasTransformedRecords(false);
  };

  const shouldShowTransformedCount =
    hasTransformedRecords && isValidationCompleted;

  return (
    <S.TableActionsContainer height={tableActionsHeight} {...props}>
      <S.TableActionsContent>
        <SearchRows />
        <RowFilters />
        <FilterByErrors />
        <S.ButtonActionsContainer>
          <DeleteSelectedRows />
          <ExportData />
          <CustomActions />
        </S.ButtonActionsContainer>
      </S.TableActionsContent>

      <S.TableActionsInformations>
        <ValidationProgress />
        {shouldShowTransformedCount && (
          <TransformedRecordsCount onClose={onCloseTransformsTooltip} />
        )}
      </S.TableActionsInformations>
    </S.TableActionsContainer>
  );
};

import { Plan } from "components/UserContextProvider";
import { Div, Modal, theme } from "fuse-shared-ui";
import { useCallback, useState } from "react";
import { TemplateItem } from "../types";
import * as Styles from "./DowngradePlanModal.styles";
import { TemplateList } from "./TemplateList/TemplateList";

type DowngradePlanModalProps = {
  isOpen: boolean;
  plan: Plan;
  templates: TemplateItem[];
  hide: () => void;
  onDowngrade: (templatesToDelete: TemplateItem[]) => void;
};

export const DowngradePlanModal = (props: DowngradePlanModalProps) => {
  const { isOpen, plan, templates: allTemplates, hide, onDowngrade } = props;
  const [templates, setTemplates] = useState<TemplateItem[]>(allTemplates);
  const [templatesToDelete, setTemplatesToDelete] = useState<TemplateItem[]>(
    []
  );

  const handleRemove = useCallback((template: TemplateItem) => {
    setTemplatesToDelete((data) => [...data, template]);
    setTemplates((data) => data.filter((t) => t.id !== template.id));
  }, []);

  const isDowngradePlanButtonDisabled =
    templates.length > plan.allowed_templates;

  return (
    <Modal isOpen={isOpen} onClose={hide}>
      <Styles.ModalContent>
        <Div h5 data-cy="downgradePlanPopup" m={16}>
          Downgrade Your Plan
        </Div>
        <Div
          w={400}
          wbody3
          mt={8}
          data-cy="downgradePlanPopupText"
          textAlignCenter
          color={theme.colors.gray700}
        >
          To be on the Basic plan, you can only have one importer. Delete the
          importers you no longer want.
        </Div>
        <Div
          data-cy="your-template"
          label3
          color={theme.colors.gray700}
          mt={24}
          w100
          textAlignLeft
        >
          Your Importers
        </Div>
        <TemplateList templates={templates} onDelete={handleRemove} />
        <Div dflex centered mt={40}>
          <Styles.CancelButton
            data-cy="cancel"
            variant="secondary"
            mr={16}
            onClick={hide}
          >
            Cancel
          </Styles.CancelButton>
          <Styles.DowngradeButton
            isDisabled={isDowngradePlanButtonDisabled}
            onClick={() => onDowngrade(templatesToDelete)}
            data-cy="downgradePlanButton"
          >
            Apply changes and downgrade plan
          </Styles.DowngradeButton>
        </Div>
      </Styles.ModalContent>
    </Modal>
  );
};

export default DowngradePlanModal;

import { useSpreadsheetContext } from "../../../Importer/common/Spreadsheet/SpreadsheetContextProvider";
import { useValidationContext } from "../../../Importer/common/Spreadsheet/ValidationContextProvider";
import { Div, Span } from "../../../styled/utils";

const ValidationProgress = () => {
  const { isReadOnly } = useSpreadsheetContext();
  const { validationPercentage } = useValidationContext();
  const showValidationProgress = !isReadOnly && validationPercentage < 100;

  return (
    <>
      {showValidationProgress ? (
        <Div w={250} textAlignRight>
          Processing Data...
          <Span bold ml={5}>
            {validationPercentage}%
          </Span>
        </Div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ValidationProgress;

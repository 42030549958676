import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import styled, { css } from "styled-components";

import { addToast } from "../../common/Toast";
import { Div, DivCSSProps } from "../../styled/utils";
import { Pagination } from "./Pagination";
import { useTableContext } from "./TableDataProvider";
import { TableView as TableViewBase } from "./TableView";
import { useReorderRows } from "./useReorderRows";

const TableContainer = styled(Div)`
  overflow-x: auto;
  width: calc(100% + 2px);
  ${(p) => p.theme.css.scrollbarDark};
`;

const TableWrapper = styled(Div)`
  min-width: 1000px;

  ${({ withoutBorders }: any) =>
    !withoutBorders &&
    css`
      border: solid 1px ${(p) => p.theme.colors.gray300};
    `}
`;

type TableViewProps = {
  withPagination?: boolean;
};

const TableView = styled(TableViewBase)<TableViewProps>`
  ${(p) =>
    p.withPagination &&
    css`
      border-radius: 0px;
    `}
`;

type Props = {
  blankState?: JSX.Element | null;
  columns: any[];
  onRowClick?: (row: any) => void;
  templateSlug?: string;
  isDraggable?: boolean;
  rowHeight?: number;
  disableSorting?: boolean;
  withoutBorders?: boolean;
  paginationProps?: DivCSSProps;
  alignColumnsWithHeader?: boolean;
} & DivCSSProps;

const Table = ({
  columns,
  onRowClick = null,
  blankState = null,
  templateSlug,
  isDraggable = false,
  rowHeight,
  disableSorting = false,
  withoutBorders = false,
  paginationProps = {},
  alignColumnsWithHeader,
  ...props
}: Props): JSX.Element => {
  const { data, isLoading, sortBy, setSortBy, totalPages } = useTableContext();
  const { reorderRows } = useReorderRows(templateSlug);
  const withPagination = data.length > 0 && totalPages > 1;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const paginationUI = (
    <>
      {withPagination && (
        <Div dflex justifyCenter pb={50} {...paginationProps}>
          <Pagination />
        </Div>
      )}
    </>
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    const overIndex = over?.data?.current?.sortable?.index;

    const overIndexIsFalsyButNotZero = !overIndex && overIndex !== 0;

    if (!active?.id || overIndexIsFalsyButNotZero || active.id === over.id) {
      return;
    }

    try {
      await reorderRows(active.id, overIndex + 1);
    } catch (error) {
      addToast("Failed to reorder rows", "error");
    }
  };

  const dndContextValues = {
    sensors: isDraggable ? sensors : undefined,
    collisionDetection: isDraggable ? closestCenter : undefined,
    onDragEnd: isDraggable ? handleDragEnd : undefined,
    modifiers: isDraggable
      ? [restrictToVerticalAxis, restrictToWindowEdges]
      : undefined,
  };

  const Container = data.length > 0 ? TableContainer : Div;
  const Wrapper: any = data.length > 0 ? TableWrapper : Div;
  const sortingProps = disableSorting ? {} : { sortBy, setSortBy };

  const hasContent = data.length > 0 || isLoading;

  const conditionalProps = {};

  if (!hasContent) {
    conditionalProps["h100"] = true;
  }

  return (
    <Container {...conditionalProps}>
      <Wrapper withoutBorders={withoutBorders} {...conditionalProps}>
        <Div {...props} {...conditionalProps}>
          {hasContent ? (
            <DndContext
              sensors={dndContextValues.sensors}
              collisionDetection={dndContextValues.collisionDetection}
              onDragEnd={dndContextValues.onDragEnd}
              modifiers={dndContextValues.modifiers}
            >
              <TableView
                rowCount={data.length}
                pageSize={data.length}
                withPagination={withPagination}
                isDraggable={isDraggable}
                rowHeight={rowHeight}
                {...{
                  isLoading,
                  data,
                  columns,
                  onRowClick,
                  alignColumnsWithHeader,
                  ...sortingProps,
                }}
              />
            </DndContext>
          ) : (
            blankState
          )}
          {paginationUI}
        </Div>
      </Wrapper>
    </Container>
  );
};

export * from "./TableDataProvider";
export * from "./TableView";
export { Table };

import { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Spinner } from "../../../common";
import {
  defaultTableHeaderHeightPx,
  TableView,
} from "../../../common/Table/TableView";
import { colors } from "../../../styled/theme";
import { Div } from "../../../styled/utils";
import { getTableHeight } from "../../common/index";
import { useImporterContext } from "../../contexts/ImporterContextProvider";
import { MAX_VISIBLE_ROWS } from "../common";
import { MatcherStatus } from "../common/types";
import { tableColumns } from "./tableColumns";

const CardContainer = styled(Div)`
  flex-direction: row;
`;

const HeaderMatcherTable = ({
  templateHeaderLabels,
  templateHeaderMatchings,
  data,
  tableRowHeight,
  tableHeight,
}) => {
  const dataSortingOrderRef = useRef(
    templateHeaderLabels.map((templateHeaderLabel, index) => ({
      index,
      isMatched: !!templateHeaderMatchings[templateHeaderLabel],
    }))
  );

  const sortedData = dataSortingOrderRef.current.map(
    (sorting) => data[sorting.index]
  );

  return (
    <TableView
      rowCount={sortedData.length}
      h={tableHeight}
      data={sortedData}
      rowHeight={tableRowHeight}
      columns={tableColumns}
      bg={colors.white}
      style={{ height: "100%" }}
    />
  );
};

const HeaderMatcher = () => {
  const { t } = useTranslation("match");
  const {
    templateHeaders,
    templateHeaderMatchings,
    headerMappingsLoaded,
  } = useImporterContext();

  const templateHeaderLabels = Object.keys(templateHeaderMatchings || {});

  const numberOfMatchedHeaders = templateHeaderLabels.filter(
    (templateHeaderLabel) => templateHeaderMatchings[templateHeaderLabel]
  ).length;

  const data = templateHeaderLabels.map((templateHeaderLabel) => {
    return {
      ourColumns: templateHeaderLabel,
      ourColumnsDescription: templateHeaders.find(
        (th) => th.label === templateHeaderLabel
      )?.description,
      yourColumns: templateHeaderMatchings[templateHeaderLabel],
      status: templateHeaderMatchings[templateHeaderLabel]
        ? MatcherStatus.matched
        : MatcherStatus.unmatched,
    };
  });

  const tableRowHeight = 100;
  const tableHeight = getTableHeight({
    headerHeight: defaultTableHeaderHeightPx,
    rowHeight: tableRowHeight,
    dataCount: data.length,
    maxVisibleRows: MAX_VISIBLE_ROWS,
  });

  return (
    <>
      <Div flexColumn h100>
        <Div ml={32} mr={32} dflex flexColumn gap={16} mt={24} mb={24}>
          <Div data-cy="match-title" h2>
            {t("title")}
          </Div>
          {headerMappingsLoaded && (
            <Div data-cy="match-description" body1>
              {t("description", {
                numberOfMatchedHeaders,
                totalNumberOfHeaders: templateHeaders.length,
              })}
            </Div>
          )}
        </Div>
        <CardContainer dflex centered alignStart w100 h100>
          {headerMappingsLoaded ? (
            <Div w100 h100>
              <HeaderMatcherTable
                templateHeaderLabels={templateHeaderLabels}
                templateHeaderMatchings={templateHeaderMatchings}
                data={data}
                tableRowHeight={tableRowHeight}
                tableHeight={tableHeight}
              />
            </Div>
          ) : (
            <Div w100 h100 centered h={400} pAbsolute>
              <Spinner />
            </Div>
          )}
        </CardContainer>
      </Div>
    </>
  );
};

export { HeaderMatcher };

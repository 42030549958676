import { Record, RecordDataSet } from "fuse-importer";

export const useBatchProcessing = (batchPageSize = 10000) => {
  const processAnythingInBatches = async <T>(records: T[], handleBatch) => {
    const batchDataSet = records;
    const dataSetLength = batchDataSet.length;
    const batchSize =
      Math.min(Math.floor(batchPageSize), dataSetLength) || dataSetLength;
    const batchCount = Math.ceil(dataSetLength / batchSize);

    for (let i = 0; i < batchCount; i++) {
      const idx = i * batchSize;
      const batch = records.slice(idx, idx + batchSize);
      await handleBatch(batch);
    }
  };

  const processRecordsInBatches = (records: Record[], handleBatch) =>
    processAnythingInBatches(records, handleBatch);

  const processDataSetInBatches = (dataSet: RecordDataSet, handleBatch) =>
    processRecordsInBatches(Object.values(dataSet), handleBatch);

  return {
    processAnythingInBatches,
    processDataSetInBatches,
    processRecordsInBatches,
  };
};

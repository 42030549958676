import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Div } from "../styled/utils";
import { closeIcon } from "./icons";

type CloseIconSizeType = "none" | "sm" | "lg";

type ModalCloseIconProps = {
  closeIconSize: CloseIconSizeType;
};

const getCloseIconSize = (size: CloseIconSizeType = "none") => {
  switch (size) {
    case "lg":
      return "80px";
    case "sm":
      return "72px";
    default:
      return "0px";
  }
};

export const ModalContainer = styled(Div)`
  top: 0px;
  left: 0px;
  margin: 0px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  div {
    pointer-events: auto;
  }
`;

export const ModalBackdrop = styled(Div)<{ opacity: number }>`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: black;
  opacity: ${({ opacity }) => opacity};
`;

export const ModalWrapper = styled(Div)`
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
  padding: 24px 26px 22px 26px;
  position: relative;
`;

export const ModalCloseIcon = styled(Div)<ModalCloseIconProps>`
  height: ${({ closeIconSize }) => getCloseIconSize(closeIconSize)};
  position: absolute;
  top: 12px;
  right: 12px;
`;

type ModalProps = {
  isOpen?: boolean;
  onClose?: () => void | null;
  children?: React.ReactNode;
  withCloseIcon?: boolean;
  withBackdropClose?: boolean;
  className?: string;
  testId?: string;
  backdropOpacity?: number;
  closeIconSize?: CloseIconSizeType;
};

export const Modal = ({
  isOpen = false,
  onClose = null,
  children,
  withCloseIcon = true,
  withBackdropClose = true,
  backdropOpacity = 0.38,
  className = "",
  testId,
  closeIconSize = "none",
}: ModalProps) => {
  function renderModal() {
    return (
      <Div className={className} data-testid={testId}>
        <ModalBackdrop
          onClick={withBackdropClose ? onClose : null}
          opacity={backdropOpacity}
          data-testid={`${testId}-backdrop`}
        />
        <ModalContainer>
          <ModalWrapper>
            {withCloseIcon && (
              <ModalCloseIcon pRelative closeIconSize={closeIconSize}>
                <Div clickable w="25px" h="25px" onClick={onClose}>
                  {closeIcon}
                </Div>
              </ModalCloseIcon>
            )}
            {children}
          </ModalWrapper>
        </ModalContainer>
      </Div>
    );
  }

  return <>{isOpen && renderModal()}</>;
};

export const TallModal = styled(Modal)(
  ({ theme: { colors, css: c } }) => css`
    ${ModalWrapper} {
      min-width: calc(640px - 112px);
      padding: 24px 0px 0px 0px;
      max-height: 864px;
      height: 80vh;
      @media all and ${(props) => props.theme.media.mobile} {
        min-width: auto;
        min-height: auto;
      }
    }
  `
);

export const TallModalLayout = styled(Div)`
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr auto;
`;

const ScrollAreaContainer = styled(Div)<ScrollAreaContainerProp>`
  ${(p) => p.theme.css.scrollbarDark};
  overflow-y: auto;
  scrollbar-width: auto !important;
  padding: 0 30px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  transition: box-shadow 0.3s;
  ${(p) =>
    p.showScrollShadow
      ? css`
          box-shadow: inset 0 8px 16px -8px rgba(0, 0, 0, 0.1);
        `
      : css`
          box-shadow: none;
        `}
`;

type ScrollAreaContainerProp = {
  withShadow?: boolean;
  showScrollShadow: boolean;
};
export const ModalScrollArea = ({ children, withShadow = true }) => {
  const [showScrollShadow, setShowScrollShadow] = useState(false);
  useEffect(() => {
    if (showScrollShadow) setTimeout(() => setShowScrollShadow(false), 300);
  }, [showScrollShadow]);

  return (
    <ScrollAreaContainer
      onScroll={() => setShowScrollShadow(true)}
      showScrollShadow={withShadow && showScrollShadow}
    >
      {children}
    </ScrollAreaContainer>
  );
};

import { FieldTypes, Record } from "fuse-importer";

export const AVERAGE_CHAR_SIZE = 11;

interface FieldProps {
  record: Record;
  name: string;
  fieldType: FieldTypes;
  isTooltipOpened: boolean;
  avgCharSize?: number;
}

const isFullUppercase = /^[A-Z]*$/;

export const getFieldContentWidth = ({
  record,
  fieldType,
  isTooltipOpened,
  name,
  avgCharSize = AVERAGE_CHAR_SIZE,
}: FieldProps) => {
  let additionalWidth = 5;

  if (fieldType === FieldTypes.url) {
    // assuming 90px as an average size of the url prefix
    // 'https://' have around 50px, but assuming more to prevent some issues
    additionalWidth += 90;
  }

  const transformedRow = record._meta?.transformedRow?.[name];

  const transformedLength = String(transformedRow?.transformed)?.length;
  const originalLength = String(transformedRow?.original)?.length;
  const transformedHasHigherLength = transformedLength >= originalLength;

  const transformedIsUppercase = isFullUppercase.test(
    transformedRow?.transformed
  );
  const originalIsUppercase = isFullUppercase.test(transformedRow?.original);
  if (transformedHasHigherLength) {
    additionalWidth += transformedIsUppercase ? transformedLength : 0;
  } else {
    additionalWidth += originalIsUppercase ? originalLength : 0;
  }

  return isTooltipOpened
    ? Math.max(transformedLength, originalLength) * avgCharSize +
        additionalWidth
    : 0 || 0;
};

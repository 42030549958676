import { debounce, get } from "lodash";
import { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useEventManagerContext } from "../../../../../common/EventManagerContextProvider";
import { TooltipProvider } from "../../../../../common/TooltipProvider";
import { Div } from "../../../../../styled/utils";
import { FieldTypes, Record } from "fuse-importer";
import { useDataSetContext } from "../../DataSetContextProvider";
import { useFilteredDataContext } from "../../FilteredDataContextProvider";
import { useSpreadsheetContext } from "../../SpreadsheetContextProvider";
import { useDataSetManipulation } from "../../SpreadsheetContextProvider/useDataSetManipulation";
import { useValidationContext } from "../../ValidationContextProvider";
import { parseValue, spreadsheetRowHeightPx } from "../../common";
import { useCellContext } from "../SpreadsheetData/CellContextProvider";

export const useSpreadsheetField = (
  name: string,
  record: Record,
  fieldType: FieldTypes
) => {
  const { appendNewRecordToSpreadsheet } = useDataSetManipulation();
  const { lastFilteredRecordId, spreadsheetFilter } = useFilteredDataContext();
  const {
    updateRecordAndRevalidate,
    warnings,
    errors,
  } = useValidationContext();
  const { getRecord } = useDataSetContext();
  const [recordState, setRecordState] = useState(record);
  const [value, setValue] = useState(record[name]);
  const { on, off } = useEventManagerContext();

  const [validationError, setValidationError] = useState(
    get(errors.current, `${record._meta.id}.${name}`, null)
  );
  const [validationWarning, setValidationWarning] = useState(
    get(warnings.current, `${record._meta.id}.${name}`, null)
  );

  useEffect(() => {
    const listener = (newRecord) => {
      const _record = getRecord(record._meta.id);
      setValidationError(
        get(errors.current, `${_record._meta.id}.${name}`, null)
      );
      setValidationWarning(
        get(warnings.current, `${_record._meta.id}.${name}`, null)
      );
      setRecordState(_record);
    };

    on(record._meta.id, listener);

    return () => {
      off(record._meta.id, listener);
    };
  }, []);

  const updateData = useCallback(
    debounce(async (value) => {
      // it's important that this is a copy for uniqueness calculaions
      const _record = { ...getRecord(record._meta.id) };
      _record[name] = parseValue(fieldType, value);
      updateRecordAndRevalidate(_record);
      setRecordState(_record);

      if (
        record._meta.id === lastFilteredRecordId.current &&
        spreadsheetFilter.byRow === "all"
      ) {
        appendNewRecordToSpreadsheet();
      }
    }, 200),
    [record]
  );

  useEffect(() => {
    if (recordState[name] !== value) {
      setValue(recordState[name]);
    }
  }, [recordState[name], recordState]);

  const updateValue = useCallback(
    (value) => {
      setValue(value);
      updateData(value);
    },
    [record, recordState, updateData]
  );

  return {
    value,
    setValue: updateValue,
    validationError,
    validationWarning,
  };
};

type ContentContainerProps = {
  hasError?: boolean;
  hasWarning?: boolean;
  isReadOnly?: boolean;
  isHighlighted?: boolean;
  expandedFieldWidth?: string;
  shouldExpandInput?: boolean;
  defaultFieldWidth?: number;
  isSelected?: boolean;
  multiline?: boolean;
  fieldHeight?: number;
};
export const ContentContainer = styled(Div)<ContentContainerProps>`
  height: calc(100% - 1px);
  width: ${({ defaultFieldWidth }) =>
    defaultFieldWidth ? `${defaultFieldWidth}px` : "100%"};
  overflow: hidden;

  ${({ expandedFieldWidth }) =>
    expandedFieldWidth &&
    css`
      position: absolute;
    `}

  &:focus-within {
    ${({ multiline, fieldHeight, defaultFieldWidth }) =>
      multiline &&
      css`
        height: ${fieldHeight || "auto"};
        max-height: ${10 * spreadsheetRowHeightPx};
        max-width: ${defaultFieldWidth * 2};
        overflow: visible;

        input,
        textarea {
          padding: 4px 12px;
          line-height: 20px;
        }
      `}
  }

  &:focus-within,
  &:hover {
    ${({
      hasError,
      hasWarning,
      isHighlighted,
      theme,
      shouldExpandInput,
      isSelected,
    }) =>
      shouldExpandInput &&
      !hasError &&
      !hasWarning &&
      !isSelected &&
      css`
        background: ${!isHighlighted
          ? theme.colors.white
          : theme.colors.blue100};
        box-shadow: 0px 0px 0px 2px ${(p) => p.theme.colors.blue500} inset;
      `}
  }

  &:focus-within,
  &:hover {
    ${({ expandedFieldWidth, shouldExpandInput, isHighlighted }) =>
      shouldExpandInput &&
      css`
        width: ${expandedFieldWidth || "auto"};

        .suffix {
          right: 12px;
        }

        z-index: ${!isHighlighted && 2};
      `}
  }

  ${(p) => p.theme.css.alignCenter};
  ${(p) =>
    p.hasWarning &&
    css`
      color: ${(p) => p.theme.colors.orange1200};
      background: ${(p) => p.theme.colors.orange1100};
      /* internal border */
      box-shadow: 0px 0px 0px 1px ${(p) => p.theme.colors.orange500} inset;
      ${p.isSelected &&
      css`
        filter: brightness(90%);
      `}
    `}
  ${(p) =>
    p.hasError &&
    css`
      color: ${(p) => p.theme.colors.error};
      background: ${(p) => p.theme.colors.red100};
      /* internal border */
      box-shadow: 0px 0px 0px 1px ${(p) => p.theme.colors.error} inset;
      ${p.isSelected &&
      css`
        filter: brightness(90%);
      `}
    `}
  ${(p) =>
    p.isHighlighted &&
    css`
      background: ${(p) => p.theme.colors.blue100};
      /* internal border */
      box-shadow: 0px 0px 0px 2px ${(p) => p.theme.colors.blue500} inset;
      z-index: 99;
      color: ${(p) => p.theme.colors.gray1000};

      &:hover {
        z-index: 100;
      }

      input,
      textarea {
        color: ${(p) => p.theme.colors.gray1000};
      }
    `}



  ${(p) =>
    p.isReadOnly &&
    css`
      pointer-events: none;
    `}
`;

type SpreadsheetFieldProps = {
  validationError: string | JSX.Element;
  validationWarning: string | JSX.Element;
  children: React.ReactNode;
  isHighlighted?: boolean;
  expandedFieldWidth?: string;
  shouldExpandInput?: boolean;
  defaultFieldWidth?: number;
  multiline?: boolean;
  fieldHeight?: number;
};

export const SpreadsheetField = ({
  validationError,
  validationWarning,
  children,
  isHighlighted,
  expandedFieldWidth,
  shouldExpandInput,
  defaultFieldWidth,
  multiline,
  fieldHeight,
}: SpreadsheetFieldProps) => {
  const { isReadOnly } = useSpreadsheetContext();
  const { selectedCellStyles, selectionProps, isSelected } = useCellContext();

  return (
    <Div dim="100%" {...selectionProps}>
      <TooltipProvider tooltip={validationError || validationWarning}>
        <Div dim="100%">
          <ContentContainer
            css={selectedCellStyles}
            hasError={validationError !== null}
            hasWarning={validationWarning !== null}
            isSelected={isSelected}
            isHighlighted={isHighlighted}
            expandedFieldWidth={expandedFieldWidth}
            defaultFieldWidth={defaultFieldWidth}
            shouldExpandInput={shouldExpandInput}
            multiline={multiline}
            fieldHeight={fieldHeight}
            isReadOnly={isReadOnly}
            className="input-content"
          >
            {children}
          </ContentContainer>
        </Div>
      </TooltipProvider>
    </Div>
  );
};

import {
  ChevronRightIcon,
  Div,
  DivCSSProps,
  MenuItem as MenuItemBase,
} from "fuse-shared-ui";
import styled from "styled-components";
import { selectedIcon } from "../../icons";

export const MenuItem = styled(MenuItemBase)`
  border-radius: 8px;
`;

type DropdownItemProps = {
  icon?: any;
  label: string;
  subMenu?: JSX.Element;
  isSelected?: boolean;
} & DivCSSProps;

export const DropdownItem = ({
  icon = null,
  subMenu = null,
  isSelected = false,
  label,
  ...props
}: DropdownItemProps) => {
  const rigthIconUI = (
    <Div alignCenter>
      {subMenu && (
        <Div pRelative>
          <Div mr={-3} alignCenter>
            <ChevronRightIcon />
          </Div>
          {subMenu}
        </Div>
      )}
    </Div>
  );

  const labelUI = (
    <Div dflex>
      {icon && (
        <Div dim={28} ml={-3} centered>
          {icon}
        </Div>
      )}
      <Div wbody1 ml={10}>
        {label}
      </Div>
    </Div>
  );

  return (
    <MenuItem {...props}>
      <Div spaceBetween w100>
        <Div dflex alignCenter w100 spaceBetween>
          {labelUI}
          <Div alignCenter>{isSelected && selectedIcon}</Div>
        </Div>
        {rigthIconUI}
      </Div>
    </MenuItem>
  );
};

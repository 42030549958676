import { useMemo } from "react";
import {
  AutoCompleteOption,
  SpreadsheetAutocomplete,
  SpreadsheetAutocompleteProps,
} from "../fields/SpreadsheetAutocomplete";

export type EnumInputProps = { options: any } & SpreadsheetAutocompleteProps;
export const EnumInput: React.FC<EnumInputProps> = ({ options, ...props }) => {
  const mapped: AutoCompleteOption[] = useMemo(
    () => options?.map((o) => ({ label: o, value: o })),
    [options]
  );
  return <SpreadsheetAutocomplete {...props} options={mapped} />;
};

import { Button, Div, useTableContext } from "fuse-shared-ui";
import { useState } from "react";
import CreateTemplateFormModal from "../../../Templates/CreateTemplateFormModal";
import { AddColumnButton, blankStateIcon } from "../../styles";

type Props = { onAddColumnClick?: () => void; templateSlug: string };

const BlankState = ({ onAddColumnClick, templateSlug }: Props) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const { getData } = useTableContext();

  const toggleUploadModal = () => {
    setIsUploadModalOpen((oldValue) => !oldValue);
  };

  return (
    <>
      <Div flexColumn centered mt={150}>
        <Div mb={24}>{blankStateIcon}</Div>
        <Div h5 mb={12} data-cy="startAddingColumnsTitle" textAlignCenter>
          Start Adding Columns
        </Div>
        <Div wbody1 mb={40} textAlignCenter maxw={400}>
          Add columns to your importer to define the structure you want incoming
          data to take on.
        </Div>
        <Div>
          <AddColumnButton onClick={onAddColumnClick} />
        </Div>

        <Div pt={6} pb={6} mt={24} mb={12}>
          Or
        </Div>
        <Div>
          <Button
            variant="link"
            w={"fit-content"}
            p={12}
            isSmall
            onClick={toggleUploadModal}
          >
            Upload a file to generate the columns
          </Button>
        </Div>
      </Div>

      {isUploadModalOpen && (
        <CreateTemplateFormModal
          onSubmit={getData}
          isOpen={isUploadModalOpen}
          hideModal={toggleUploadModal}
          templateId={templateSlug}
        />
      )}
    </>
  );
};

export default BlankState;

import styled from "styled-components";
import { useFormikContext } from "formik";
import { Div } from "fuse-shared-ui";
import { checkIcon, checkSuccessIcon } from "../common/icons";
import { passwordValidationsList } from "./fields";

const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media all and (max-width: 720px) {
    max-width: unset;
    display: block;
  }
`;

const FormContentPasswordValidation = styled(Div)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.textSecondary};
  ${(props) => props.theme.textStyles.wbody3};
  & > svg {
    margin-right: 12px;
  }
`;

export const PasswordValidation = () => {
  const { values } = useFormikContext();

  return (
    <Wrapper w100 mt={24} data-cy="password-validation-list">
      {passwordValidationsList.map((item, index) => (
        <FormContentPasswordValidation key={index} mt={index === 0 ? 0 : 8}>
          {values["password"] &&
          item?.rule &&
          item.rule.test(values["password"])
            ? checkSuccessIcon
            : checkIcon}
          {item.message}
        </FormContentPasswordValidation>
      ))}
    </Wrapper>
  );
};

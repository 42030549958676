import { Div } from "fuse-shared-ui";
import {
  ItemLabel,
  LinkEl,
  ListItem,
  PrivacyAndTermsWrapper,
  Section,
} from "./common";

const TermsAndConditions = () => (
  <PrivacyAndTermsWrapper>
    <Div wh3 mb={20}>
      Flatirons Fuse, Inc. Terms and Conditions
    </Div>

    <Section title="Introduction">
      These Terms and Conditions (&quot;Terms&quot;) govern the use of Flatirons
      Fuse, Inc. services (&quot;Service&quot;), accessible via{" "}
      <LinkEl>https://fuse.flatirons.com</LinkEl>. By accessing the Service,
      clients (&quot;You&quot;) agree to be bound by these Terms, which are
      enforceable like any written contract signed by You. If You are using the
      Service on behalf of an organization, You are agreeing to these Terms for
      that organization and promising that You have the authority to bind that
      organization to these Terms.
    </Section>

    <Section title="Order Form and Precedence">
      &quot;For services procured via a separate Order Form, the terms and
      conditions stated in the Order Form shall take precedence over any
      conflicting terms and conditions in this document. The Order Form, signed
      by both parties, may specify details such as the scope of services, fees,
      payment terms, and any special conditions or obligations specific to the
      service arrangement. In the event of any inconsistency or conflict between
      the terms of an Order Form and this Terms and Conditions document, the
      terms outlined in the Order Form will supersede and govern the
      parties&apos; respective rights and obligations concerning the subject
      matter of the Order Form.&quot;
    </Section>

    <Section title="Modification of Terms">
      Flatirons Fuse, Inc. reserves the right to update and change the Terms by
      posting updates and changes. It is advised to check the Terms regularly
      for updates. Continued use of the Service after such changes shall
      constitute your consent to such changes.
    </Section>

    <Section title="Account Terms">
      <ol>
        <ListItem>
          You must provide a valid email address and any other information
          needed to complete the signup process.
        </ListItem>

        <ListItem>
          You are responsible for maintaining the security of your account and
          password. Flatirons Fuse, Inc. cannot and will not be liable for any
          loss or damage from your failure to comply with this security
          obligation.
        </ListItem>

        <ListItem>
          You are responsible for all content posted and activity that occurs
          under your account.
        </ListItem>
      </ol>
    </Section>

    <Section title="Use of Service">
      <ol>
        <ListItem>
          You shall use the Service in compliance with all applicable laws,
          rules, and regulations.
        </ListItem>

        <ListItem>
          You shall not use the Service to store, host, or send unsolicited
          email (spam) or SMS messages.
        </ListItem>

        <ListItem>
          You shall not transmit any worms or viruses or any code of a
          destructive nature.
        </ListItem>
      </ol>
    </Section>

    <Section title="License, Restrictions, Responsibilities">
      <Div mb={8}>
        <ItemLabel label="License Grant:" isLabelBold />
        Subject to the terms of this Agreement and, where applicable, those of
        any separate Order Form, Flatirons Fuse, Inc. grants to the Customer a
        non-exclusive, non-transferable, non-sublicensable, limited right to
        access and use the Flatirons Fuse services (&quot;Services&quot;) solely
        for the Customer&quot;s internal business operations. This license is
        granted for the term of the Agreement and is subject to the
        Customer&quot;s compliance with its terms and conditions, including
        payment obligations.
      </Div>

      <Div mb={8}>
        <ItemLabel label="Usage Restrictions:" isLabelBold />
        The Customer agrees not to (and will not permit any third party to):
        reverse engineer, decompile, or otherwise attempt to discover the source
        code or underlying ideas or algorithms of the Services; modify,
        translate, or create derivative works based on the Services; use the
        Services for timesharing, service bureau purposes, or otherwise for the
        benefit of a third party; or remove any proprietary notices or labels on
        the Services.
        <Div>
          Furthermore, the Customer shall not use the Services to: store,
          process, or transmit any content that is unlawful, infringing,
          defamatory, harmful, or in violation of third-party privacy rights;
          send spam or otherwise duplicative or unsolicited messages in
          violation of applicable laws; or conduct or promote any illegal
          activities.
        </Div>
      </Div>

      <Div mb={8}>
        <ItemLabel label="Responsibilities:" isLabelBold />
        The Customer is responsible for all activities conducted under their
        user logins and for their users&apos; compliance with this Agreement.
        The Customer shall (i) have sole responsibility for the accuracy,
        quality, integrity, legality, reliability, and appropriateness of all
        data they provide to the Services; (ii) prevent unauthorized access to,
        or use of, the Services, and notify Flatirons Fuse, Inc. promptly of any
        such unauthorized access or use; and (iii) comply with all applicable
        local, state, national, and foreign laws in using the Services.
      </Div>

      <Div mb={8}>
        <ItemLabel label="Ownership:" isLabelBold />
        Flatirons Fuse, Inc. and its licensors retain all rights, title, and
        interest in and to the Services, including all related intellectual
        property rights. The Services are offered as a subscription, and no
        ownership rights of any sort are transferred to the Customer. The
        Customer grants Flatirons Fuse, Inc. a worldwide, royalty-free license
        to use, reproduce, distribute, modify, and display the data and content
        provided by the Customer to the extent necessary to provide the
        Services. Except as provided in this Agreement, the license granted to
        the Customer does not convey any rights in the Services, express or
        implied, or ownership in the Services or any intellectual property
        rights thereto.
      </Div>
    </Section>

    <Section title="Data Ownership, Protection and Privacy">
      <ol>
        <ListItem>
          Flatirons Fuse, Inc. will maintain appropriate administrative,
          physical, and technical safeguards for the protection of the security,
          confidentiality, and integrity of Your data. We will not modify your
          data or disclose it except as compelled by law.
        </ListItem>

        <ListItem>
          Both parties will comply with all applicable requirements of data
          protection and privacy laws relating to the personal information of
          users of the Service.
        </ListItem>

        <ListItem>
          Any data, information, or material transmitted through the use of the
          Services by the Customer shall remain the sole property of the
          Customer, subject to the rights granted to Flatirons Fuse, Inc. to
          operate the Services. The Customer represents and warrants that they
          have the necessary rights to provide such data, information, or
          materials for processing and use as contemplated under this Agreement.
        </ListItem>

        <ListItem>
          Flatirons Fuse, Inc. is committed to maintaining the highest levels of
          data protection and security. Our Services are designed with a strong
          emphasis on protecting the confidentiality, integrity, and
          availability of Customer data. To this end, Flatirons Fuse, Inc.
          adheres to HIPAA-compliant policies and procedures to ensure we meet
          and exceed the standards required for protecting sensitive health
          information.
        </ListItem>

        <ListItem>
          <ItemLabel label="Privacy:" isLabelBold />
          Flatirons Fuse, Inc. is dedicated to maintaining the privacy of the
          data processed through our Services. We do not use, disclose, or
          access such data except as necessary to provide the Services, or as
          required by law. Our privacy practices are designed to provide a high
          level of protection for your data, in compliance with applicable laws
          and regulations, including HIPAA.
        </ListItem>
      </ol>
    </Section>

    <Section title="Intellectual Property">
      <ol>
        <ListItem>
          Flatirons Fuse, Inc. owns all rights, title, and interest in and to
          the Service, including all related intellectual property rights. These
          Terms do not grant You any rights to use the Flatirons Fuse, Inc.
          trademarks, logos, domain names, or other brand features.
        </ListItem>
      </ol>
    </Section>

    <Section title="Confidentiality">
      <ol>
        <ListItem>
          Each party agrees not to disclose the other party&apos;s Confidential
          Information without the other&apos;s prior written consent.
        </ListItem>

        <ListItem>
          &quot;Confidential Information&quot; includes any business or
          technical information that one party discloses to the other during the
          term of this agreement, but does not include information that is
          publicly known, independently developed, or rightfully obtained from
          third parties.
        </ListItem>
      </ol>
    </Section>

    <Section title="Warranties and Disclaimers">
      The Service is provided on an &quot;as is&quot; basis. Flatirons Fuse,
      Inc. and its suppliers and licensors hereby disclaim all warranties of any
      kind, express or implied, including, without limitation, the warranties of
      merchantability, fitness for a particular purpose, and non-infringement.
      Neither Flatirons Fuse, Inc. nor its suppliers and licensors make any
      warranty that the Service will be error-free or that access thereto will
      be continuous or uninterrupted.
    </Section>

    <Section title="Limitation of Liability">
      In no event will Flatirons Fuse, Inc., or its suppliers or licensors, be
      liable with respect to any subject matter of these Terms under any
      contract, negligence, strict liability, or other legal or equitable theory
      for: (i) any indirect, exemplary, special, incidental, or consequential
      damages; (ii) the cost of procurement of substitute products or services;
      (iii) for interruption of use or loss or corruption of data.
    </Section>

    <Section title="General Conditions">
      <ol>
        <ListItem>Your use of the Service is at your sole risk.</ListItem>

        <ListItem>Technical support is only available via email.</ListItem>

        <ListItem>
          You understand that Flatirons Fuse, Inc. uses third-party vendors and
          hosting partners to provide the necessary hardware, software,
          networking, storage, and related technology required to run the
          Service.
        </ListItem>
      </ol>
    </Section>

    <Section title="Mutual Indemnification">
      <ol>
        <ListItem isLabelBold label="Indemnification by the Customer:">
          The Customer agrees to indemnify, defend, and hold harmless Flatirons
          Fuse, Inc., its affiliates, officers, directors, employees,
          consultants, agents, suppliers, and resellers from any and all
          third-party claims, liability, damages, and/or costs (including, but
          not limited to, attorneys&apos; fees) arising from the Customer&apos;s
          use of the Services, violation of these Terms, infringement or
          infringement by any other user of the Customer&apos;s account, of any
          intellectual property or other rights of any person or entity, or
          failure to comply with applicable laws including but not limited to
          HIPAA.
        </ListItem>

        <ListItem isLabelBold label="Indemnification by Flatirons Fuse, Inc.:">
          Flatirons Fuse, Inc. agrees to indemnify, defend, and hold harmless
          the Customer, its affiliates, officers, directors, employees,
          consultants, agents, from any and all third-party claims, liability,
          damages, and/or costs (including, but not limited to, attorneys&apos;
          fees) arising from the Services&apos; infringement of any intellectual
          property rights of any third party, Flatirons Fuse, Inc.&apos;s breach
          of these Terms, or violation of applicable laws.
        </ListItem>

        <ListItem isLabelBold label="Notification and Cooperation:">
          Each party shall promptly notify the other party of any claim for
          which it seeks indemnification and will afford the other party the
          opportunity to participate in the defense of such claim. The
          indemnifying party shall not enter into any settlement that affects
          the indemnified party’s rights or interests without the indemnified
          party&apos;s prior written approval, which shall not be unreasonably
          withheld or delayed. The indemnified party reserves the right, at its
          own expense, to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by the indemnifying party, in
          which event the indemnifying party will cooperate with the indemnified
          party in asserting any available defenses.
        </ListItem>

        <ListItem isLabelBold label="Limitation:">
          This mutual indemnification obligation will survive the termination or
          expiration of the use of the Services and these Terms.
        </ListItem>
      </ol>
    </Section>

    <Section title="Termination">
      Flatirons Fuse, Inc. may terminate or suspend any and all Services and
      access to the website immediately, without prior notice or liability, for
      any reason whatsoever, including, without limitation, if You breach the
      Terms.
    </Section>

    <Section title="Governing Law">
      These Terms shall be governed by and interpreted in accordance with the
      laws of Delaware, United States, without regard to its conflict of law
      provisions.
    </Section>

    <Section title="Changes to Terms">
      Flatirons Fuse, Inc. reserves the right, at its sole discretion, to modify
      or replace the Terms at any time. If a revision is material, we will
      provide at least 30 days&apos; notice before any new terms take effect.
    </Section>

    <Section title="Miscellaneous">
      <ol>
        <ListItem isLabelBold label="Entire Agreement:">
          These Terms, together with any Order Forms, represent the entire
          agreement between Flatirons Fuse, Inc. and the Customer regarding the
          subject matter hereof, and supersede all prior and contemporaneous
          understandings or agreements between the parties regarding such
          subject matter. The Terms can only be modified by a written amendment
          signed by an authorized executive of Flatirons Fuse, Inc., or by the
          posting by Flatirons Fuse, Inc. of a revised version.
        </ListItem>

        <ListItem isLabelBold label="Severability:">
          If any provision of these Terms is found to be unenforceable or
          invalid, that provision will be limited or eliminated to the minimum
          extent necessary so that the Terms otherwise remain in full force and
          effect and enforceable.
        </ListItem>

        <ListItem isLabelBold label="Assignment:">
          Neither these Terms nor any rights or obligations under them may be
          assigned or transferred by the Customer without the prior written
          consent of Flatirons Fuse, Inc. Flatirons Fuse, Inc. may assign its
          rights and obligations under these Terms in its sole discretion to an
          affiliate, or in connection with an acquisition, sale, or merger.
        </ListItem>

        <ListItem isLabelBold label="Notices:">
          All notices under these Terms will be in writing and will be deemed to
          have been duly given when received, if personally delivered or sent by
          certified or registered mail, return receipt requested; when receipt
          is electronically confirmed, if transmitted by facsimile or email; or
          the day after it is sent, if sent for next-day delivery by recognized
          overnight delivery service.
        </ListItem>

        <ListItem isLabelBold label="Waiver:">
          No waiver will be implied from conduct or failure to enforce or
          exercise rights under these Terms, nor will any waiver be effective
          unless in a writing signed by a duly authorized representative on
          behalf of the party claimed to have waived.
        </ListItem>

        <ListItem isLabelBold label="Independent Contractors:">
          The relationship between Flatirons Fuse, Inc. and the Customer is that
          of independent contractors, and no agency, partnership, joint venture,
          or employee-employer relationship is intended or created by these
          Terms.
        </ListItem>

        <ListItem isLabelBold label="Force Majeure:">
          Neither party shall be liable for any failure to perform its
          obligations hereunder where such failure results from any cause beyond
          such party&apos;s reasonable control, including, without limitation,
          mechanical, electronic, or communications failure or degradation.
        </ListItem>

        <ListItem isLabelBold label="Headings:">
          The section and paragraph headings in these Terms are for convenience
          only and shall not affect their interpretation.
        </ListItem>
      </ol>
    </Section>

    <Section title="GDPR Compliance">
      Flatirons Fuse, Inc. is committed to complying with the General Data
      Protection Regulation (GDPR) and ensuring the protection and privacy of
      all personal data collected from individuals residing in the European
      Economic Area (EEA). This section outlines specific rights granted under
      the GDPR and how we comply with those requirements.
      <Div mt={8}>
        <ItemLabel label="Lawful Basis for Processing:" isLabelBold />
        We process personal data based on one or more of the following lawful
        bases: consent, contractual necessity, legal obligations, and our
        legitimate interests. The specific basis for processing your data will
        be identified at the time of collection or before we use the data for a
        new purpose.
      </Div>
      <Div mt={8}>
        <ItemLabel label="Data Subject Rights:" isLabelBold />
        Under the GDPR, you have several rights regarding your personal data,
        including:
        <ol>
          <ListItem isLabelBold label="The Right to Access:">
            You have the right to access your personal data and obtain a copy of
            the personal data we hold about you.
          </ListItem>

          <ListItem isLabelBold label="The Right to Rectification:">
            You have the right to request that we correct any inaccuracies in
            your personal data.
          </ListItem>

          <ListItem isLabelBold label="The Right to Erasure:">
            You have the right to request the deletion of your personal data
            when it is no longer necessary for us to retain it.
          </ListItem>

          <ListItem isLabelBold label="The Right to Restriction of Processing:">
            You have the right to request that we restrict the processing of
            your personal data under certain circumstances.
          </ListItem>

          <ListItem isLabelBold label="The Right to Data Portability:">
            You have the right to receive your personal data in a structured,
            commonly used, and machine-readable format, and to transmit that
            data to another controller.
          </ListItem>

          <ListItem isLabelBold label="The Right to Object:">
            You have the right to object to the processing of your personal data
            based on our legitimate interests, including profiling.
          </ListItem>

          <ListItem isLabelBold label="The Right to Withdraw Consent:">
            Where processing is based on consent, you have the right to withdraw
            your consent at any time.
          </ListItem>
        </ol>
      </Div>
      <Div mt={8}>
        <ItemLabel label="Data Protection Officer (DPO):" isLabelBold />
        Flatirons Fuse, Inc. has appointed a Data Protection Officer to ensure
        our continued commitment to privacy and data protection. If you have any
        questions or concerns about our data protection practices, or if you
        wish to exercise any of your rights as described in this section, please
        contact our DPO at <LinkEl>mike@flatirons.com</LinkEl>.
      </Div>
      <Div mt={8}>
        <ItemLabel label="Data Transfers:" isLabelBold />
        Personal data may be transferred to, and stored at, a destination
        outside the European Economic Area (EEA). It may also be processed by
        staff operating outside the EEA who work for us or for one of our
        suppliers. We will take all steps reasonably necessary to ensure that
        your data is treated securely and in accordance with this privacy
        policy.
      </Div>
    </Section>

    <Section title="Self-Hosting">
      <ol>
        <ListItem isLabelBold label="Eligibility for Self-Hosting:">
          The rights and responsibilities outlined in this Self-Hosting section
          apply exclusively to Customers who have an active subscription that
          expressly includes self-hosting privileges (”Self-hosting Customers”)
          for the Flatirons Fuse software (&quot;Software&quot;). Access to
          self-hosting capabilities is contingent upon maintaining such a
          qualifying subscription. Customers without this specific subscription
          tier are not authorized to install, host, or use the Software on their
          own or third-party servers.
        </ListItem>

        <ListItem isLabelBold label="Grant of License for Self-Hosting:">
          Subject to the terms of a qualifying subscription, Flatirons Fuse,
          Inc. grants Self-Hosting Customers a non-exclusive, non-transferable,
          revocable license to install, host, and use the Software solely for
          their internal business operations, in accordance with the following
          terms:
          <Div bold mt={8}>
            Customer Responsibilities:
          </Div>
          <ul>
            <ListItem isLabelBold label="a. Compliance & Security:">
              Self-hosting customers must ensure their hosting environment
              complies with Flatirons Fuse’s specifications and maintain robust
              security measures to protect the Software and data.
            </ListItem>

            <ListItem isLabelBold label="b. Backups:">
              Responsibility for data backups rests with the Customer, along
              with any resulting liability for data loss or software
              non-functionality.
            </ListItem>
          </ul>
          <Div>
            <ItemLabel label="Restrictions:" isLabelBold mt={8} />
            Self-hosting Customers shall not sublicense, modify, reverse
            engineer, or otherwise misuse the Software, nor use it to provide
            services to third parties.
          </Div>
          <Div>
            <ItemLabel label="Support and Maintenance:" isLabelBold mt={8} />
            Flatirons Fuse, Inc. typically does not provide direct support for
            self-hosted installations unless specified in the subscription
            agreement. Self-hosting Customers are responsible for their own
            software management.
          </Div>
          <Div>
            <ItemLabel label="Intellectual Property:" isLabelBold mt={8} />
            All rights in the Software remain with Flatirons Fuse, Inc., with no
            transfer of ownership implied by self-hosting rights.
          </Div>
          <Div>
            <ItemLabel label="Termination:" isLabelBold mt={8} />
            Self-hosting rights cease upon subscription termination, requiring
            the cessation of Software use and destruction of all copies.
          </Div>
          <Div>
            <ItemLabel label="Liability:" isLabelBold mt={8} />
            Flatirons Fuse, Inc. disclaims liability for issues arising from
            self-hosted Software use, beyond the obligations agreed in the
            qualifying subscription.
          </Div>
        </ListItem>

        <ListItem>
          <ItemLabel label="Verification of Self-Hosting Rights:" isLabelBold />
          Flatirons Fuse, Inc. reserves the right to verify compliance with
          self-hosting eligibility requirements and may request documentation or
          perform audits to ensure adherence to these terms.
        </ListItem>
      </ol>
    </Section>
  </PrivacyAndTermsWrapper>
);

export default TermsAndConditions;

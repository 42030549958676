import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Button, Div } from "fuse-shared-ui";
import { useUserContext } from "components/UserContextProvider";
import { useSegment } from "components/SegmentContextProvider";
import {
  flatironsFuseLogo,
  checkYourEmailBackgroundPart1,
  checkYourEmailBackgroundPart2,
  checkYourEmailBackgroundPart3,
} from "./common/assets/images";
import { checkBigIcon } from "./common/icons";
import { SIGN_IN_ROUTE } from ".";
import { useMediaQuery } from "utils";
import ImproveYourExperience from "./ImproveYourExperience";

const Wrapper = styled(Div)`
  width: 100vw;
  ${(props) => {
    const c = props.theme.css;
    return [c.grow, c.justifyCenter, c.yscroll, c.pRelative];
  }};
`;

const Container = styled(Div)`
  margin: 80px 160px;
  min-height: 740px;
  max-height: 740px;
  height: 100%;
  max-width: 1120px;
  width: 100%;
  position: relative;
  @media all and (max-width: 980px) {
    margin: 80px;
  }
  @media all and (max-width: 840px) {
    margin: 24px;
  }
`;

const ContentWrapper = styled(Div)`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 40px ${(props) => props.theme.colors.red2000};
  border-radius: 16px;
  position: relative;
  z-index: 10;
`;

const Content = styled(Div)`
  padding: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > svg:not(:first-child) {
    margin-top: 150px;
  }
  @media all and (max-width: 840px) {
    padding: 24px;
  }
`;

const TextWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextTitle = styled(Div)`
  margin-top: 32px;

  ${(props) => props.theme.css.DM4}
  font-size: 48px;
  line-height: 72px;
  @media all and (max-width: 1220px) {
    text-align: center;
  }
`;

const TextSubtitle = styled(Div)`
  margin-top: 12px;
  color: ${(props) => props.theme.colors.gray700};
  ${(props) => props.theme.textStyles.wbody1}
  display: flex;
`;

const BackgroundPart1 = styled(Div)`
  margin-top: -46px;
  margin-bottom: -33.58px;
  margin-left: -126px;
  max-width: 698px;
  min-width: 698px;
  width: 100%;
  pointer-events: none;
  position: absolute;
  z-index: 1;
  left: 0;
  @media all and (max-width: 840px) {
    display: none;
  }
`;

const BackgroundPart2 = styled(Div)`
  margin-top: 371.19px;
  max-width: 534px;
  min-width: 534px;
  width: 100%;
  max-height: 373px;
  min-height: 373px;
  pointer-events: none;
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  @media all and (max-width: 840px) {
    display: none;
  }
`;

const BackgroundPart3 = styled(Div)`
  margin-top: 557px;
  margin-left: 262.88px;
  max-width: 907px;
  width: 100%;
  opacity: 80%;
  display: flex;
  pointer-events: none;
  position: absolute;
  z-index: 30;
  left: 0;
  top: 0;
  @media all and (max-width: 1240px) {
    display: none;
  }
`;

const backgroundImagesList = [
  <BackgroundPart1 key={1}>{checkYourEmailBackgroundPart1}</BackgroundPart1>,
  <BackgroundPart2 key={2}>{checkYourEmailBackgroundPart2}</BackgroundPart2>,
  <BackgroundPart3 key={3}>{checkYourEmailBackgroundPart3}</BackgroundPart3>,
];

const EmailConfirmed = () => {
  const { confirmationToken } = useParams<any>();
  const { confirmUserEmail } = useUserContext();
  const history = useHistory();
  const { track } = useSegment();
  const isMobileScreen = useMediaQuery("(max-width: 480px)");

  const [isMobileAlertOnScreen, setIsMobileAlertOnScreen] = useState(false);

  useEffect(() => {
    if (confirmationToken) {
      (async () => {
        const user = await confirmUserEmail(confirmationToken);
        const userObj = {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          company_name: user.active_organization?.name,
        };

        track("user_confirmed_email_address", userObj);
      })();
    }
  }, [confirmationToken]);

  const handleSignInButtonAction = () => {
    if (isMobileScreen) setIsMobileAlertOnScreen(true);
    else history.push(SIGN_IN_ROUTE);
  };

  const textUI = (
    <TextWrapper>
      <TextTitle data-cy="email-confirmed-title">Email confirmed!</TextTitle>
      <TextSubtitle data-cy="email-confirmed-sub-title">
        Your email address was confirmed! Sign in to start using Fuse.
      </TextSubtitle>
      <Button data-cy="sign-in" mt={40} onClick={handleSignInButtonAction}>
        Sign In
      </Button>
    </TextWrapper>
  );

  return (
    <>
      {isMobileAlertOnScreen && <ImproveYourExperience />}

      {!isMobileAlertOnScreen && (
        <>
          <Helmet>
            <title>Flatirons Fuse - Email confirmed</title>
          </Helmet>
          <Wrapper>
            <Container>
              {backgroundImagesList.map((item) => item)}
              <ContentWrapper>
                <Content>
                  {flatironsFuseLogo}
                  {checkBigIcon}
                  {textUI}
                </Content>
              </ContentWrapper>
            </Container>
          </Wrapper>
        </>
      )}
    </>
  );
};

export default EmailConfirmed;

import { useTranslation } from "react-i18next";
import { Button } from "../..";
import { Div } from "../../styled/utils";
import { useReviewContext } from "./ReviewContextProvider";
import { submitIcon } from "../Stepper/common/icons";
import { colorLabels } from "../../styled/theme/colorPalette";

interface AppResponseModalProps {
  onCloseModal: () => void;
}

export const SuccessAppResponseModal = ({
  onCloseModal,
}: AppResponseModalProps) => {
  const { appResponse } = useReviewContext();
  const { t } = useTranslation("review");
  const defaultMessage = t("persistence.response_modal.success_default");

  return (
    <Div centered flexColumn w={590} textAlignCenter>
      <Div mt={50}>{submitIcon}</Div>
      <Div h5 mt={20}>
        {t("persistence.response_modal.success_header")}
      </Div>
      <Div w={450} mt={12} c={colorLabels.textSecondary} body1>
        {appResponse?.message || defaultMessage}
      </Div>
      <Div dflex mt={40} w100 centered mb={60}>
        <Button onClick={onCloseModal} data-cy="app-response-btn">
          {t("persistence.response_modal.close_modal_button")}
        </Button>
      </Div>
    </Div>
  );
};

export const ErrorAppResponseModal = ({
  onCloseModal,
}: AppResponseModalProps) => {
  const { appResponse } = useReviewContext();
  const { t } = useTranslation("review");
  const defaultMessage = t("persistence.response_modal.error_default");

  return (
    <Div centered flexColumn p={40}>
      <Div h6 w={300} textAlignCenter mt={20}>
        {appResponse?.message || defaultMessage}
      </Div>
      <Div dflex mt={40} w100 centered>
        <Button ml={10} onClick={onCloseModal} data-cy="app-response-btn">
          OK
        </Button>
      </Div>
    </Div>
  );
};

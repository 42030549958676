export const getHeaders = (data: string[][]) => {
  const headers = data[0]?.map((ud) =>
    typeof ud === "number" ? (ud as number).toString() : ud
  );
  return headers;
};

export const getValuesOnArrayPosition = (
  data: string[][],
  position: number
) => {
  const newArray = [];
  for (let j = 0; j < data.length; j++) {
    const currentValue = data?.[j]?.[position];
    if (data[j] && currentValue) {
      newArray.push(currentValue);
    }
  }
  return newArray;
};

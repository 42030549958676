import { colors, Div, Table, TableDataProvider } from "fuse-shared-ui";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import BlankState from "./components/BlankState";
import Header from "./components/Header";
import { useTemplateColumns } from "./hooks";
import { URLParams } from "./types";

const TemplateColumns = (): JSX.Element => {
  const { slug } = useParams<URLParams>();

  return (
    <TableDataProvider dataUrl={`/api/v1/templates/${slug}/columns`}>
      <TemplateColumnsWithContext />
    </TableDataProvider>
  );
};

const TemplateColumnsWithContext = () => {
  const {
    data,
    getData,
    isLoadingApp,
    tableColumns,
    isLoading,
    template,
    showColumnFormModal,
    launchTemplate,
    updateTemplate,
  } = useTemplateColumns();

  const title = `Flatirons Fuse - ${template?.name || "Importer"} Columns`;
  const hasData = data.length > 0;

  return (
    <Div hidden={isLoadingApp} m={5} c={colors.gray800} p="40px 80px">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        hasColumns={hasData}
        template={template}
        isLoading={isLoading}
        onAddColumnClick={showColumnFormModal}
        onLaunchTemplate={launchTemplate}
        getData={getData}
        updateTemplate={updateTemplate}
      />
      <Div>
        <Table
          templateSlug={template?.slug}
          columns={tableColumns}
          blankState={
            <BlankState
              onAddColumnClick={showColumnFormModal}
              templateSlug={template?.slug}
            />
          }
          isDraggable
          rowHeight={96}
          disableSorting
        />
      </Div>
    </Div>
  );
};

export default TemplateColumns;

import { Options, Placement } from "@popperjs/core";
import { useEffect, useLayoutEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled, { css } from "styled-components";
import { Div, DivCSSProps } from "../../styled/utils";

export const SelectMenuBackdrop = styled(Div)`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  display: flex;
`;

export const SelectMenuDiv = styled(Div)`
  background-color: white;
  border: solid 1px ${(p) => p.theme.colors.gray5};
  position: absolute;
  border-radius: 16px;
  overflow: hidden;
  z-index: 10000000000;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
`;

export const SelectMenuScrollArea = styled(Div)(
  ({ theme: { colors, css: c } }) => css`
    padding: 16px;
    overflow-y: auto;
    max-height: 250px;
    ${(p) => p.theme.css.scrollbarDark};
  `
);

type Props = {
  isOpen?: boolean;
  onClose?: any;
  children: any;
  placement?: Placement;
  referenceElement: any;
  selectMenuClassName?: string;
} & DivCSSProps;

const isFirefox = navigator.userAgent.indexOf("Firefox") > -1;

export const SelectMenu = ({
  isOpen = false,
  onClose = null,
  placement = "auto",
  referenceElement,
  children,
  selectMenuClassName,
  ...props
}: Props) => {
  const [popperElement, setPopperElement] = useState(null);
  const [width, setWidth] = useState(null);

  const options: Options = {
    placement,
    // On Firefox we use absolute positioning because fixed positioning
    // doesn't seem to work well with the select menu (more info in PR)
    // may be related to https://github.com/floating-ui/floating-ui/issues/1729
    strategy: isFirefox ? "absolute" : "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [2, 3],
        },
      },
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          boundary: "viewport",
        },
      },
    ],
  };

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    options
  );

  useEffect(() => {
    if (update) update();
  }, [isOpen]);

  const referenceElementWidth = referenceElement?.getBoundingClientRect()
    ?.width;
  useLayoutEffect(() => {
    if (referenceElement) setWidth(referenceElementWidth);
  }, [referenceElementWidth]);

  return (
    <>
      {isOpen && (
        <Div {...props}>
          <SelectMenuBackdrop onClick={onClose} />
          <SelectMenuDiv
            ref={setPopperElement}
            style={{ ...styles.popper, width }}
            className={selectMenuClassName}
            {...attributes.popper}
          >
            <SelectMenuScrollArea>{children}</SelectMenuScrollArea>
          </SelectMenuDiv>
        </Div>
      )}
    </>
  );
};

import { addToast, api } from "fuse-shared-ui";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getFormData } from "../../../../utils";
import { useSegment } from "../../../SegmentContextProvider";
import { BILLING_ROUTE } from "../common";
import { Organization } from "fuse-importer";

export const useImporterStylePreferences = (
  activeOrg: Organization,
  canOrganizationCustomize: boolean
) => {
  const history = useHistory();

  const [importerStylePreferences, setImporterStylePreferences] = useState(
    null
  );

  const { track } = useSegment();

  const goToBilling = (): void => {
    history.push(BILLING_ROUTE);
  };

  const getImporterStylePreferences = async () => {
    const { data }: any = await api.get(
      `/api/v1/organizations/${activeOrg.id}/importer_style_preferences`,
      {
        params: { organization_id: activeOrg.id },
      }
    );

    if (data && Object.getOwnPropertyDescriptor(data, "id")?.configurable) {
      delete data.id;
    }

    setImporterStylePreferences({
      ...data,
      logo: data?.logo || null,
      logo_url: data?.logo_url || null,
    });
  };

  useEffect(() => {
    getImporterStylePreferences();
  }, []);

  const redirectAfterSubmit = (): void => {
    if (canOrganizationCustomize) {
      addToast("Importer style preferences updated successfully", "success");
    } else {
      addToast(
        "To enable your Branded importer, you must upgrade to a paid plan.",
        "success"
      );
      goToBilling();
    }
  };

  const onSubmit = async (values) => {
    try {
      delete values["logo_url"];
      const formData = getFormData(values);
      await api.put(
        `/api/v1/organizations/${activeOrg.id}/importer_style_preferences`,
        formData
      );
      track("user_customized_importer", {
        company: activeOrg?.name,
      });
      redirectAfterSubmit();
    } catch (error) {
      console.error(error);
    }
  };

  return {
    onSubmit,
    importerStylePreferences,
  };
};

import { Div } from "fuse-shared-ui";
import { useState } from "react";
import styled from "styled-components";
import { CloseIcon } from "../../../../../common/NavigationLayout/Navigation/icons";
import { FilterComponentProps } from "../../interfaces";
import { FilterIcon } from "../icons";
import { MobileDropdown } from "./MobileDropdown";

const MobileFilterModal = styled(Div)`
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
  left: 0;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  &,
  & * {
    box-sizing: border-box;
  }
`;

export const MobileFilters = ({ filters }: FilterComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Div id="mobile-filter" dInline>
      <Div
        dflex
        clickable
        alignCenter
        buttonTextSmall
        gap={12}
        p="6px 28px 6px 10px"
        onClick={() => setIsOpen(true)}
      >
        <FilterIcon />
        Filters
      </Div>

      {isOpen && (
        <MobileFilterModal>
          <Div mb={38} dflex alignCenter spaceBetween>
            <Div h6>Filter by</Div>

            <Div
              clickable
              dflex
              w={16}
              h={16}
              alignCenter
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon />
            </Div>
          </Div>

          <Div dflex flexColumn gap={32}>
            {filters.map((filter) => (
              <MobileDropdown
                key={filter.label}
                icon={filter.icon}
                data={filter.data}
                keyToRender={filter.keyToRender}
                onFilter={(data) => {
                  filter.onFilter(data);
                  setIsOpen(false);
                }}
                activeFilter={filter.activeFilter}
                filterType={filter.filterType}
                groupLabel={filter.groupLabel}
                label={filter.label}
              />
            ))}
          </Div>
        </MobileFilterModal>
      )}
    </Div>
  );
};

import { useState } from "react";
import styled from "styled-components";
import { Button, Div, Modal } from "fuse-shared-ui";
import { deleteImageIcon } from "../../../TeamMembers/common/icons";

const Content = styled(Div)`
  width: 448px;
  height: 280px;
  margin: 48px 40px;
`;

const Text = styled(Div)`
  margin-top: 12px;
  ${(p) => p.theme.textStyles.wbody1};
  justify-content: center;
`;

const FormButtonsWrapper = styled(Div)`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

type DeleteModalProps = {
  isOpen: boolean;
  onDelete: () => void;
  onCancel: () => void;
};

const DeleteModal = ({ isOpen, onDelete, onCancel }: DeleteModalProps) => {
  const contentUI = (
    <Content data-cy="delete-credential-pop-up">
      <Div data-cy="delete-credential-image" textAlignCenter>
        {deleteImageIcon}
        <Div data-cy="delete-credential-title" mt={20} h5>
          Delete Credentials
        </Div>
        <Text data-cy="delete-credential-description">
          Are you sure you want delete this set of API credentials? The Fuse API
          will no longer accept request using any deleted credentials.
        </Text>
      </Div>
      <FormButtonsWrapper>
        <Button
          data-cy="delete-credential-cancel"
          mr={16}
          w={104}
          variant="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          data-cy="delete-credential-confirmed"
          w={135}
          onClick={onDelete}
        >
          Yes, delete
        </Button>
      </FormButtonsWrapper>
    </Content>
  );

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      {contentUI}
    </Modal>
  );
};

export default DeleteModal;

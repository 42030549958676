import { useAppContext } from "components/AppContextProvider";
import { useAppModalContext } from "components/AppModalContextProvider";
import { Div, api } from "fuse-shared-ui";
import { useQueryParams } from "hooks";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { SIGN_IN_ROUTE } from "../..";
import AcceptInvitationWithData from "../AcceptInvitationWithData";
import RevokedInvitationModal from "../RevokedInvitationModal";

const AcceptInvitationGeneral = () => {
  const history = useHistory();
  const { setIsLoadingApp } = useAppContext();
  const { showModal } = useAppModalContext();

  const {
    has_active_organization: hasActiveOrganization,
    invited_by_id,
    user_id,
    organization_id,
  } = useQueryParams([
    "has_active_organization",
    "invited_by_id",
    "user_id",
    "organization_id",
  ]);

  const acceptInvitationUrl =
    "/api/v1/organizations/users/existing_users_invitations";

  const acceptInvitation = async () => {
    try {
      setIsLoadingApp(true);
      await api.put(
        acceptInvitationUrl,
        {
          invited_by_id: +invited_by_id,
          user_id: +user_id,
          organization_id: +organization_id,
        },
        { successMsg: "Invitation Accepted!" }
      );
    } catch (error) {
      showModal(({ isOpen, hideModal }) => (
        <RevokedInvitationModal isOpen={isOpen} hide={hideModal} />
      ));
    } finally {
      setIsLoadingApp(false);
      history.replace(SIGN_IN_ROUTE);
    }
  };

  useEffect(() => {
    if (hasActiveOrganization) {
      acceptInvitation();
    }
  }, []);

  return (
    <Div grow>{!hasActiveOrganization && <AcceptInvitationWithData />}</Div>
  );
};

export default AcceptInvitationGeneral;

import * as yup from "yup";

const isRunningTest = (window as any).Cypress || process.env.REACT_APP_CYPRESS;

export const yupRequired = yup
  .string()
  .nullable()
  .required("This is a required field");

export const yupNonEmptyArray = (requiredErrorMsg = "value is required") =>
  yup.array().of(yup.string().required(requiredErrorMsg));

export const requiredString = (fieldLabel = ""): any =>
  yup.string().nullable().required().label(fieldLabel);

export const yupEmail = yup
  .string()
  .nullable()
  .email("Invalid email")
  .required("Email is required");

export const yupCompanyName = yup
  .string()
  .min(2, "Company name should have at least 2 characters")
  .required("Company name is required");

export const yupRequiredWithLabel = (label: string): any =>
  yup.string().required(`${label} is required`);

export const yupPassword = yup
  .string()
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,128}$/,
    "Passwords must contain at least 6 characters and maximum of 128 characters, including at least 1 letter, 1 number and 1 special character."
  )
  .required("Password is required");

export const yupPasswordConfirmation = yup
  .string()
  .oneOf([yup.ref("password")], "Passwords do not match")
  .required("Password confirmation is required");

export const yupWebsite = requiredString("Website").matches(
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  "Website is not valid"
);

export const yupChecked = yup
  .boolean()
  .oneOf([true], "You must accept the terms and conditions");

const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
export const yupPhone = yup
  .string()
  .matches(phoneRegExp, "Phone number is invalid")
  .required("Phone number is required");

export const yupCardHolder = yup
  .string()
  .matches(/^[aA-zZ\s]+$/, "Only alphabetical characters")
  .required("Card Holder is required");

const defaultUrlRegex = /https?:\/\/(?:www\.)?((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?!0(?:\.0){3})((?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}))(?::\d{2,5})?(\/[^\s]*)?/g;
const cypressUrlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}(\.[a-z]{2,4})?(:\d{2,5})?(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/g;

export const yupRequiredUrl = yup
  .string()
  .matches(
    isRunningTest ? cypressUrlRegex : defaultUrlRegex,
    "Must be a valid URL or IP address containing http:// or https://"
  )
  .nullable()
  .required("Please enter a URL");

import { replaceColor } from "lottie-colorify";
import { useLottie } from "lottie-react";
import { CSSProperties, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Div, colors } from "../../styled";
import { usePersistenceContext } from "../common/Spreadsheet/PersistenceContextProvider";
import persistenceLoader from "../common/assets/persistence_loader.json";
import { useImporterContext } from "../contexts/ImporterContextProvider";
import { useReviewContext } from "./ReviewContextProvider";
import { MODAL_STEPS } from "./SpreadsheetSubmissionModal";

const animationStyle: CSSProperties = {
  height: "100px",
};

const formatPercentage = (number: number) => Math.round(number * 100);

const ProgressBar = styled(Div)<{ progress: number }>`
  align-items: center;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  display: flex;
  height: 8px;
  width: 100%;

  ::after {
    background: ${({ theme }) => theme.colors.black};
    border-radius: 16px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    content: "";
    display: block;
    height: 100%;
    margin-left: -2px;
    width: ${({ progress }) => progress + "%"};
  }
`;

const isFalsyButNotZero = (value: any) => value || value === 0;

interface PersistenceLoaderModalProps {
  callNextModal: () => void;
  callSpecificModal: (step: string) => void;
}

export const PersistenceLoaderModal = ({
  callNextModal,
  callSpecificModal,
}: PersistenceLoaderModalProps) => {
  const {
    persistenceProgress,
    isRetryModalOpen,
    isCloseRetryState,
    validatingPersistence,
    setValidatingPersistence,
    persisting,
  } = usePersistenceContext();
  const { theme } = useImporterContext();
  const { webhooksProcessing } = useReviewContext();

  const updatedAnimationData = replaceColor(
    [14, 190, 190],
    theme.colors.pink500,
    persistenceLoader
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: updatedAnimationData,
  };

  const { View } = useLottie(defaultOptions, animationStyle);
  const { t } = useTranslation("review");

  const progress =
    (isFalsyButNotZero(persistenceProgress) &&
      formatPercentage(persistenceProgress)) ||
    0;

  useEffect(() => {
    if (isRetryModalOpen) {
      return callSpecificModal(MODAL_STEPS.RETRY_MODAL);
    }

    if (isCloseRetryState.current) {
      return callSpecificModal(MODAL_STEPS.SUBMIT_MODAL);
    }

    if (!persisting && !webhooksProcessing && validatingPersistence) {
      callNextModal();
      setValidatingPersistence(false);
    }

    const handleBeforeUnload = (event) => {
      if (persisting || webhooksProcessing || validatingPersistence) {
        const shouldNavigate = confirm(t("persistence.leave_warning"));
        if (!shouldNavigate) {
          event.preventDefault();
          event.returnValue = "";
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [
    isRetryModalOpen,
    progress,
    callNextModal,
    validatingPersistence,
    webhooksProcessing,
    persisting,
  ]);

  return (
    <>
      <Div w={448} h={288} p="48px 60px">
        {View}

        <Div flexColumn alignCenter>
          <Div data-cy="persistence-title" h5 mt={1} mb={8}>
            {t("persistence.loader_modal.title")}
          </Div>
          <Div body3 style={{ color: colors.textDisabled }}>
            {t("persistence.loader_modal.subtitle")}
          </Div>

          <Div mt={32} w100 flexColumn alignCenter>
            <Div body3 mb={12}>
              {progress}%
            </Div>
            <ProgressBar progress={progress} />
          </Div>
        </Div>
      </Div>
    </>
  );
};

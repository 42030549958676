import { useCallback } from "react";
import { RecordDataSet } from "fuse-importer";
import Papa from "papaparse";

import { useImporterContext } from "../contexts/ImporterContextProvider";
import { ExportDataType } from "../Review/ReviewContextProvider";

type RecordObject = {
  [key: string]: any;
};

function downloadCsv(data: BlobPart, fileName: string) {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}

function csvExport(data: RecordObject[]) {
  const valuesWithQuotesWhenNecessary = data.map((obj) => {
    const newObj = {};

    for (const key in obj) {
      const value = obj[key];
      newObj[key] =
        typeof value === "string" && value.includes(",") ? `"${value}"` : value;
    }

    return newObj;
  });

  const csv = Papa.unparse(valuesWithQuotesWhenNecessary);

  downloadCsv(csv, "export.csv");
}

export const useExportDataToFile = () => {
  const { selectedRows, templateHeaders } = useImporterContext();

  const onExport = useCallback(
    (dataSet: RecordDataSet, dataType: ExportDataType) => {
      if (!dataSet) return;
      const templateHeadersKeys = templateHeaders
        .sort((a, b) => a.position - b.position)
        .map(({ internal_key }) => internal_key);

      const dataSetValues = Object.values(dataSet);

      if (dataType === "csv") {
        const valuesWithoutMeta = dataSetValues.map(({ _meta, ...rest }) => {
          const orderedRecord = {};
          templateHeadersKeys.forEach((header) => {
            orderedRecord[header] = rest[header] || "";
          });
          return orderedRecord;
        });

        csvExport(valuesWithoutMeta);
      }
    },
    [selectedRows]
  );

  return {
    onExport,
  };
};

import { useUserContext } from "components/UserContextProvider";
import { useFormikContext } from "formik";
import { Div, api } from "fuse-shared-ui";
import { useEffect, useState } from "react";
import Form, {
  FormikSumbitButton as FormikSumbitButtonBase,
} from "../../../common/form/Form";
import { FormikTextInput } from "../../../common/form/fields";
import { ContentSectionContainer } from "../common/components/ContentSectionContainer";
import { passwordFields } from "./common/fields";
import { getObjectDiff } from "./common/utils";

const passwordFieldsUI = (
  <>
    {passwordFields.map(({ label, placeholder, name, inputProps }, index) => (
      <Div key={index} m="20px 0">
        <FormikTextInput
          {...{ label, placeholder, name }}
          type={inputProps?.type || "text"}
        />
      </Div>
    ))}
  </>
);

const FormikSumbitButton = () => {
  const { values } = useFormikContext();

  const valuesDiff = getObjectDiff(
    {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
    values
  );

  return (
    <FormikSumbitButtonBase
      mt={24}
      w={160}
      isDisabled={Object.keys(valuesDiff).length === 0}
      isButtonElement
    >
      Reset Password
    </FormikSumbitButtonBase>
  );
};

const PasswordInformationForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, setUser, refreshUser } = useUserContext();

  useEffect(() => {
    refreshUser();
  }, []);

  const onSubmitPassword = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await api.put("/users/password", values, {
        successMsg: "Password reset successfully.",
      });

      setUser({ ...user, ...data.user });
      refreshUser();
    } catch (err) {
      console.error("Err ", err);
    }

    setIsLoading(false);
  };

  return (
    <ContentSectionContainer title="Password Information">
      <Form fields={passwordFields} onSubmit={onSubmitPassword}>
        <>
          {passwordFieldsUI}
          <FormikSumbitButton />
        </>
      </Form>
    </ContentSectionContainer>
  );
};

export default PasswordInformationForm;

import styled, { css } from "styled-components";
import { Div, HTMLTextAreaCSSProps } from "../../../../../../styled";

type SuffixTextProps = {
  fieldWidth: number;
};

type TextAreaProps = {
  hasError: boolean;
  hasWarning?: boolean;
  fitContent?: boolean;
} & HTMLTextAreaCSSProps;

export const SuffixText = styled(Div)<SuffixTextProps>`
  color: ${(props) => props.theme.colors.gray1000};
  ${(props) => props.theme.css.body3};

  position: absolute;
  left: ${({ fieldWidth }) => fieldWidth}px;
  text-decoration: line-through;
  white-space: nowrap;
`;

export const TextArea = styled.textarea<TextAreaProps>`
  background: transparent;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  height: calc(100% - 10px);
  margin: 0px;
  outline: none;
  width: 100%;
  overflow: inherit;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;

  padding: 0 12px;
  ${(props) => props.theme.css.body3};
  color: ${(props) => props.theme.colors.gray1000};
  ::placeholder {
    color: ${(props) => props.theme.colors.placeholder};
  }

  /* Textarea specific */
  resize: none;

  ${(p) =>
    p.hasWarning &&
    css`
      color: ${(p) => p.theme.colors.orange1200};
    `}

  ${(p) =>
    p.hasError &&
    css`
      color: ${(p) => p.theme.colors.error};
    `}
`;

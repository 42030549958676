import { Div } from "fuse-shared-ui";
import styled from "styled-components";

export const InputWrapper = styled(Div)`
  width: 448px;
`;

export const IncognitoWrapper = styled(Div)`
  gap: 16px;
  width: 448px;
`;

export const TextWrapper = styled(Div)`
  gap: 4px;
`;

export const IncognitoSwitch = styled(Div)`
  width: 28px;
  height: 28px;
`;

export const ContentWrapper = styled(Div)`
  gap: 32px;
`;

export const Tag = ({ children, ...props }: any) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7104 1.86829C8.52823 1.83409 9.32252 2.15876 9.90128 2.76382L14.4117 7.47928C15.557 8.67659 15.557 10.6178 14.4117 11.8151L11.3445 15.0217C10.1993 16.2191 8.34243 16.2191 7.19717 15.0218L2.68674 10.3063C2.10798 9.70123 1.79743 8.87084 1.83014 8.01583L2.02024 3.04734C2.04061 2.515 2.44873 2.08832 2.95792 2.06703L7.7104 1.86829Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M6.10156 6.3335L6.73924 7.00016"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { useUserContext } from "components/UserContextProvider";
import { useFormikContext } from "formik";
import { Div, api } from "fuse-shared-ui";
import { useEffect, useState } from "react";
import Form, {
  FormikSumbitButton as FormikSumbitButtonBase,
} from "../../../common/form/Form";
import { FormikTextInput } from "../../../common/form/fields";
import { ContentSectionContainer } from "../common/components/ContentSectionContainer";
import { emailField, nameFields } from "./common/fields";
import { getObjectDiff, getUserValues, successMessage } from "./common/utils";

const nameFieldsUI = (
  <Div dflex>
    {nameFields.map(({ label, placeholder, name }, index) => (
      <Div key={index} m={index === 0 ? "20px 20px 20px 0" : "20px 0"} w50>
        <FormikTextInput {...{ label, placeholder, name }} type={"text"} />
      </Div>
    ))}
  </Div>
);

const fieldsUI = (
  <Div m="20px 0">
    <FormikTextInput
      {...{
        label: emailField.label,
        placeholder: emailField.placeholder,
        name: emailField.name,
      }}
      type={"text"}
    />
  </Div>
);

const FormikSumbitButton = () => {
  const { user } = useUserContext();
  const { values } = useFormikContext();

  const valuesDiff = getObjectDiff(getUserValues(user), values);

  return (
    <FormikSumbitButtonBase
      mt={24}
      w={160}
      isDisabled={Object.keys(valuesDiff).length === 0}
      isButtonElement
    >
      Save Changes
    </FormikSumbitButtonBase>
  );
};

const PersonalInformationForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, setUser, refreshUser } = useUserContext();

  useEffect(() => {
    refreshUser();
  }, []);

  const onSubmitBasicInfo = async (values) => {
    setIsLoading(true);

    const valuesDiff = getObjectDiff(getUserValues(user), values);
    const successMsg = successMessage(valuesDiff);

    try {
      const { data } = await api.put(`/users/${user.id}`, valuesDiff, {
        successMsg,
      });

      setUser({ ...user, ...data.user });
      refreshUser();
    } catch (err) {
      console.error("Err", err);
    }

    setIsLoading(false);
  };

  const basicInfoFields = [emailField, ...nameFields].map((a) => {
    a.initialValue = user[a.name];
    return a;
  });

  return (
    <ContentSectionContainer title="Personal Information">
      <Form fields={basicInfoFields} onSubmit={onSubmitBasicInfo}>
        <>
          {nameFieldsUI}
          {fieldsUI}
          <FormikSumbitButton />
        </>
      </Form>
    </ContentSectionContainer>
  );
};

export default PersonalInformationForm;

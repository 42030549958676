import { Div } from "fuse-shared-ui";
import styled from "styled-components";
import { OptionsUI } from "./OptionsUI";
import { UserMenu } from "./UserMenu";

const Container = styled(Div)`
  ${(p) => p.theme.css.scrollbarDark};
`;

const Wrapper = styled(Div)`
  position: relative;
  color: ${(props) => props.theme.colors.white};
  ${(p) => p.theme.css.scrollbarDark};
  height: 100%;
  box-shadow: 1px 0px 0px 0px #e5dbdb;
`;

const NavContainer = styled(Div)`
  @media all and (max-height: 760px) {
    overflow-y: auto;
    ${({ theme }) => theme.css.scrollbarDark};
  }

  @media all and (${({ theme }) => theme.media.xs}) {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.12);
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
`;

const UserMenuWrapper = styled(Div)`
  margin-bottom: 12px;

  @media all and (${({ theme }) => theme.media.xs}) {
    margin-bottom: 24px;
  }
`;

type NavigationProps = {
  isOnMobileDevice: boolean;
  isOpen: boolean;
};

export const Navigation = ({ isOnMobileDevice, isOpen }: NavigationProps) => {
  return isOnMobileDevice && !isOpen ? null : (
    <Container>
      <Wrapper>
        <NavContainer spaceBetween flexColumn w100 h100>
          <OptionsUI />

          <UserMenuWrapper>
            <UserMenu />
          </UserMenuWrapper>
        </NavContainer>
      </Wrapper>
    </Container>
  );
};

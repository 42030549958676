import Analytics from "components/Analytics";
import { AppContextProvider } from "components/AppContextProvider";
import { AppModalContextProvider } from "components/AppModalContextProvider";
import Inspectlet from "components/Inspectlet";
import Routes from "components/Routes";
import SegmentContextProvider from "components/SegmentContextProvider";
import { UserContextProvider } from "components/UserContextProvider";
import { Toast, theme } from "fuse-shared-ui";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "./App.css";

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <SegmentContextProvider>
          <Toast zIndex={101} />
          <UserContextProvider>
            <AppContextProvider>
              <AppModalContextProvider>
                <Routes />
              </AppModalContextProvider>
            </AppContextProvider>
          </UserContextProvider>
        </SegmentContextProvider>
      </ThemeProvider>
      <Inspectlet />
      <Analytics />
    </Router>
  );
};

export default App;

import React, { createContext, useContext, useState } from "react";

type State = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavigationContext = createContext<State>({} as State);

type Props = {
  children: JSX.Element;
};

export const NavigationContextProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(() => window.innerWidth > 599);
  const value = { isOpen, setIsOpen };

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => useContext(NavigationContext);

import { addToast, api, Div, useTableContext } from "fuse-shared-ui";
import toast from "react-hot-toast";
import * as S from "./styles";
import { UseDeleteTableRowProps } from "./interfaces";
import { useState } from "react";

export const useDeleteTableRow = ({
  deleteUrl,
  resourceLabel,
  customMessage,
  confirmation = false,
  hardDelete = false,
}: UseDeleteTableRowProps) => {
  const { getData } = useTableContext();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const deleteTableRow = async () => {
    let confirmationToast = null;

    const undoDeleteRow = async () => {
      try {
        await api.put(deleteUrl, {
          archived: false,
        });
        getData();
        toast.dismiss(confirmationToast);
        confirmationToast = addToast(undoSuccessfulMessage, "success", {
          duration: 2000,
        });
      } catch (error) {
        console.error(error);
      }
    };

    const defaultMessage = (
      <Div alignCenter>
        <Div wbody1 fontweight_800 mr={5}>
          {resourceLabel}
        </Div>
        <Div>deleted successfully</Div>
      </Div>
    );

    const confirmationMessage = (
      <Div spaceBetween alignCenter>
        {customMessage || defaultMessage}
        {!hardDelete && (
          <S.Link onClick={undoDeleteRow} clickable>
            Undo
          </S.Link>
        )}
      </Div>
    );

    const undoSuccessfulMessage = <Div alignCenter>Undo successful</Div>;

    try {
      if (hardDelete) {
        await api.delete(deleteUrl);
      } else {
        await api.put(deleteUrl, {
          archived: true,
        });
      }
      confirmationToast = addToast(confirmationMessage, "success");
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  return { deleteTableRow };
};

import { CSSProps, Div } from "fuse-shared-ui";
import styled from "styled-components";

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

interface LinkElProps {
  children: React.ReactNode;
}

interface ItemLabelProps extends CSSProps {
  label?: string;
  isLabelBold?: boolean;
}

interface ListItemProps extends ItemLabelProps {
  children: React.ReactNode;
}

export const PrivacyAndTermsWrapper = styled(Div)`
  line-height: 1.6;
  margin: 5% auto;
  max-width: 814px;
  padding: 0 40px;

  ${({ theme }) => theme.textStyles.body1}
`;

export const Link = styled(Div)`
  color: ${({ theme }) => theme.colors.pink500};
  word-break: break-all;

  ${({ theme }) => theme.css.wlink};
  font-size: 16px;
  font-weight: 700;
`;

export const Section = ({ title, children }: SectionProps) => (
  <>
    <Div h5 mb={20}>
      {title}
    </Div>
    <Div mb={20}>{children}</Div>
  </>
);

export const LinkEl = ({ children }: LinkElProps) => {
  const stringifiedChildren = children.toString();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isEmail = emailRegex.test(stringifiedChildren);

  return (
    <Link
      dInline
      onClick={() =>
        window.open(
          isEmail ? `mailto:${stringifiedChildren}` : stringifiedChildren
        )
      }
    >
      {children}
    </Link>
  );
};

export const ItemLabel = ({ label, isLabelBold, ...props }: ItemLabelProps) => (
  <>
    {label && (
      <>
        <Div dInline {...props} {...(isLabelBold && { bold: true })}>
          {label}
        </Div>{" "}
      </>
    )}
  </>
);

export const ListItem = ({ label, children, isLabelBold }: ListItemProps) => (
  <li>
    <ItemLabel label={label} isLabelBold={isLabelBold} />

    {children}
  </li>
);

import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";

type IdentifierProp = {
  name?: string;
  email: string;
  company_name?: string;
};

type UserIdProp = number | string | null;

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

export const useIsMobile = () => {
  const {
    media: { mobile },
  } = useContext(ThemeContext);
  return useMediaQuery(mobile);
};

export const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const currentTime = () => new Date().getTime();

export const stringifyBoolean = (value: boolean): string => {
  if (value === true) {
    return "Yes";
  } else {
    return "No";
  }
};

export const getNumberLocaleString = (v) => {
  const res =
    typeof v === "number"
      ? v.toFixed(2).toLocaleString()
      : parseFloat(v).toFixed(2).toLocaleString();
  return res === "NaN" ? "-" : res;
};

export const isObjectEmpty = (obj = {}) => {
  return Object.keys(obj).length === 0;
};

export const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));

export const getFormData = (values) => {
  const formData = new FormData();
  Object.keys(values)
    .filter((k) => values[k] !== null)
    .forEach((key) => {
      let fdKey = key;
      const value = values[key];
      if (
        typeof value === "object" &&
        Object.keys(value).filter((key) =>
          ["display_name", "city", "country"].includes(key)
        ).length === 3
      ) {
        const attributes = values[key];
        Object.keys(attributes).forEach((item) =>
          formData.append(`address_attributes[${item}]`, attributes[item])
        );

        return;
      }

      let fdValue = typeof value === "boolean" ? value : value || "";
      if (Array.isArray(value)) {
        fdValue = value.join(",");
      }
      if (key.endsWith("_url") && typeof value !== "string") {
        fdKey = key.replace("_url", "");
      }
      formData.append(fdKey, fdValue);
    });
  return formData;
};

export const capitalize = (s) =>
  s.replace(/(^\w{1})|(\s+\w{1})/g, (l) => l.toUpperCase());

export const identifyInspectlet = (
  identifier: IdentifierProp,
  userId?: UserIdProp
) => {
  (window as any)?.__insp?.push([
    "tagSession",
    {
      ...(userId && { userId }),
      ...identifier,
    },
  ]);
  (window as any)?.__insp?.push(["identify", identifier.email]);
};

export const sleep = (n: number) =>
  new Promise((resolve) => setTimeout(resolve, n));

export const headerIcon = (
  <svg
    width="80"
    height="60"
    viewBox="0 0 80 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "darken" }}>
      <path
        d="M0.753483 2.56391C0.377062 1.28305 1.3373 0 2.67234 0H43.2884C44.0316 0 44.7135 0.412127 45.0591 1.07013L54.5117 19.0701C54.8174 19.6523 54.8174 20.3477 54.5117 20.9299L45.0591 38.9299C44.7135 39.5879 44.0316 40 43.2884 40H2.67234C1.33731 40 0.377062 38.717 0.753483 37.4361L5.71188 20.5639C5.82007 20.1958 5.82007 19.8042 5.71188 19.4361L0.753483 2.56391Z"
        fill="url(#paint0_linear_2083_495)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M79.2465 57.4361C79.6229 58.717 78.6627 60 77.3277 60L36.7116 60C35.9684 60 35.2865 59.5879 34.9409 58.9299L25.4883 40.9299C25.1826 40.3476 25.1826 39.6523 25.4883 39.0701L34.9409 21.0701C35.2865 20.4121 35.9684 20 36.7116 20L77.3277 20C78.6627 20 79.6229 21.283 79.2465 22.5639L74.2881 39.4361C74.1799 39.8042 74.1799 40.1958 74.2881 40.5639L79.2465 57.4361Z"
        fill="url(#paint1_linear_2083_495)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2083_495"
        x1="27.5"
        y1="-1.93586e-07"
        x2="19.138"
        y2="64.9759"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24D2EA" />
        <stop offset="1" stopColor="#0DDFD3" stopOpacity="0.760417" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2083_495"
        x1="66.2934"
        y1="22.0309"
        x2="25.5743"
        y2="55.91"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0A617" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
    </defs>
  </svg>
);

export const chevronUpIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 5L16.9632 11.9632L10 18.9263"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const actionSuccessIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM8.70711 10.2426C8.31658 9.85208 7.68342 9.85208 7.29289 10.2426C6.90237 10.6331 6.90237 11.2663 7.29289 11.6568L9.41421 13.7781L10.1213 14.4852L10.8284 13.7781L15.0711 9.53549C15.4616 9.14497 15.4616 8.5118 15.0711 8.12128C14.6805 7.73076 14.0474 7.73076 13.6569 8.12128L10.1213 11.6568L8.70711 10.2426Z"
      fill="#0EBEBE"
    />
  </svg>
);

export const greenCheckIcon = (
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33301 6.26147L6.04705 10.9755L15.4751 1.54743"
      stroke="#02AC45"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const blueCheckIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <circle cx="9" cy="9.00002" r="7.33333" stroke="#597ACD" strokeWidth="2" />
    <path
      d="M7 8.96649L8.41421 10.3807L11.2426 7.55228"
      stroke="#597ACD"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const confirmationDeleteIcon = (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="68" height="68" fill="white" />
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M8.79639 4.52687C8.79639 3.70291 9.73698 3.23251 10.3962 3.72676L33.7964 21.2699C33.9246 21.3661 34.0001 21.517 34.0001 21.6772L34.0001 46.015C34.0001 46.4345 33.5212 46.674 33.1856 46.4224L9.59669 28.7376C9.0929 28.3599 8.79639 27.7671 8.79639 27.1374L8.79639 4.52687Z"
        fill="url(#paint0_linear_2363_5281)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M8.7959 63.1264C8.7959 63.9504 9.73649 64.4208 10.3957 63.9266L33.7959 46.3834C33.9241 46.2873 33.9996 46.1363 33.9996 45.9761L33.9996 21.6383C33.9996 21.2188 33.5207 20.9794 33.1851 21.231L9.5962 38.9157C9.09241 39.2934 8.7959 39.8863 8.7959 40.5159L8.7959 63.1264Z"
        fill="url(#paint1_linear_2363_5281)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M59.2039 4.87257C59.2039 4.04862 58.2633 3.57821 57.604 4.07246L34.1528 21.6539C34.0567 21.726 34.0002 21.839 34.0002 21.9591L34.0002 46.6159C34.0002 46.9302 34.359 47.1097 34.6104 46.9211L58.4036 29.0833C58.9073 28.7056 59.2039 28.1128 59.2039 27.4831L59.2039 4.87257Z"
        fill="url(#paint2_linear_2363_5281)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M59.2041 63.4722C59.2041 64.2961 58.2635 64.7665 57.6043 64.2723L34.1531 46.6908C34.057 46.6188 34.0004 46.5057 34.0004 46.3856L34.0004 21.7288C34.0004 21.4145 34.3592 21.2351 34.6107 21.4236L58.4038 39.2614C58.9076 39.6391 59.2041 40.232 59.2041 40.8616L59.2041 63.4722Z"
        fill="url(#paint3_linear_2363_5281)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2363_5281"
        x1="28.828"
        y1="2.27186"
        x2="10.8931"
        y2="6.63443"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF710A" />
        <stop offset="1" stopColor="#FD9827" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2363_5281"
        x1="28.8275"
        y1="65.3815"
        x2="10.8926"
        y2="61.0189"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF710A" />
        <stop offset="1" stopColor="#FD9827" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2363_5281"
        x1="41.7498"
        y1="25.1258"
        x2="70.7926"
        y2="29.9741"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDD172" />
        <stop offset="1" stopColor="#D0A617" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2363_5281"
        x1="41.7501"
        y1="43.2189"
        x2="70.7929"
        y2="38.3706"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDD172" />
        <stop offset="1" stopColor="#D0A617" />
      </linearGradient>
    </defs>
  </svg>
);

export const copyIcon = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="11.5"
      y="10"
      width="12"
      height="13"
      rx="3"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M13.5 4.71429V4C13.5 2.34315 12.1569 1 10.5 1H4.5C2.84315 1 1.5 2.34315 1.5 4V11C1.5 12.6569 2.84315 14 4.5 14H6.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const warningIcon = (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3852_5793)">
      <rect width="68" height="68" fill="white" />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M28.5667 8.05781C29.6942 6.10503 32.5127 6.10503 33.6402 8.05781L59.1465 52.236C60.2739 54.1888 58.8646 56.6298 56.6098 56.6298H5.59714C3.34227 56.6298 1.93297 54.1888 3.06041 52.236L28.5667 8.05781Z"
          fill="url(#paint0_linear_3852_5793)"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M34.4372 9.68867C35.5647 7.73589 38.3833 7.73589 39.5107 9.68867L65.8157 55.2503C66.9432 57.2031 65.5339 59.6441 63.279 59.6441H10.6689C8.41407 59.6441 7.00478 57.2031 8.13221 55.2503L34.4372 9.68867Z"
          fill="url(#paint1_linear_3852_5793)"
        />
      </g>
      <rect
        x="29.0371"
        y="21.2637"
        width="6.43183"
        height="21.3972"
        rx="2.92916"
        fill="#FFFDF8"
      />
      <rect
        x="29.0371"
        y="44.7891"
        width="6.43183"
        height="5.61436"
        rx="2.80718"
        fill="#FFFDF8"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3852_5793"
        x1="10.0097"
        y1="28.8128"
        x2="30.2645"
        y2="54.4815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD5C39" />
        <stop offset="1" stopColor="#FFB869" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3852_5793"
        x1="18.8004"
        y1="74.0811"
        x2="85.2564"
        y2="33.8684"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E7C551" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
      <clipPath id="clip0_3852_5793">
        <rect width="68" height="68" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const userIntentIcon = (
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "darken" }}>
      <path
        d="M89.25 12.4833V61.8333C89.25 64.8492 86.7825 67.3167 83.7667 67.3167H29.3475C29.0823 67.3167 28.828 67.422 28.6404 67.6096L8.70711 87.5429C8.07714 88.1729 7 87.7267 7 86.8358V12.4833C7 9.4675 9.4675 7 12.4833 7H83.7667C86.7825 7 89.25 9.4675 89.25 12.4833Z"
        fill="url(#paint0_linear_4250_81455)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M22.75 28.2333V77.5833C22.75 80.5992 25.2175 83.0667 28.2333 83.0667H82.6525C82.9177 83.0667 83.172 83.172 83.3596 83.3596L103.293 103.293C103.923 103.923 105 103.477 105 102.586V28.2333C105 25.2175 102.533 22.75 99.5167 22.75H28.2333C25.2175 22.75 22.75 25.2175 22.75 28.2333Z"
        fill="url(#paint1_linear_4250_81455)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4250_81455"
        x1="48.125"
        y1="7"
        x2="34.2356"
        y2="114.44"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6BEEFF" />
        <stop offset="1" stopColor="#08CABE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4250_81455"
        x1="84.5024"
        y1="100.824"
        x2="9.07335"
        y2="55.1815"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0A617" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
    </defs>
  </svg>
);

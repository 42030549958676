import { TransformationOptionKeys, TransformationTypes } from "fuse-importer";

export type TransformationOption = {
  type: "text" | "number";
  key: TransformationOptionKeys;
  tooltip: string;
};

export type Transformation = {
  value: TransformationTypes;
  label: string;
  options?: TransformationOption[];
  isDefaultTransformation?: boolean;
};

const commonDateAndTimeTransformations: Transformation[] = [
  {
    value: "autoformat",
    label: "Autoformat (e.g. 02-13-2020 -> 02/13/2020)",
    isDefaultTransformation: true,
  },
];

const stringTransformations: Transformation[] = [
  {
    value: "sentence_case",
    label: "Sentence Case (e.g. hello world -> Hello world)",
  },
  {
    value: "capitalize",
    label: "Capitalize (e.g. hello world -> Hello World)",
  },
  {
    value: "uppercase",
    label: "Uppercase (e.g. hello world -> HELLO WORLD)",
  },
  {
    value: "lowercase",
    label: "Lowercase (e.g. Hello World -> hello world)",
  },
  {
    value: "trim_whitespace",
    label: "Trim Whitespace (e.g. '  hello world ' -> 'hello world')",
  },
];

const urlTransformations: Transformation[] = [
  {
    value: "prefix",
    label: "Prefix",
    options: [
      {
        key: "prefix",
        type: "text",
        tooltip:
          "Add Prefix (e.g., 'example.com' becomes 'https://example.com')",
      },
    ],
  },
];

const floatTransformations: Transformation[] = [
  {
    value: "number_of_decimals",
    label: "Number of Decimals",
    options: [
      {
        key: "number_of_decimals",
        type: "number",
        tooltip:
          "Set Number of Decimals (e.g., '10.567' becomes '10.57' with 2 decimals)",
      },
    ],
  },
];

const integerTransformations: Transformation[] = [
  {
    value: "round",
    label: "Round (e.g. 3.19 -> 3)",
  },
  {
    value: "floor",
    label: "Floor (e.g. 3.99 -> 3)",
  },
  {
    value: "ceiling",
    label: "Ceiling (e.g. 3.01 -> 4)",
  },
];

export const transformationsByColumnType: {
  [key: string]: Transformation[];
} = {
  string: stringTransformations,
  integer: integerTransformations,
  float: floatTransformations,
  url: urlTransformations,
  date: commonDateAndTimeTransformations,
  datetime: commonDateAndTimeTransformations,
  time: commonDateAndTimeTransformations,
};

// the final object will look like this:
// { autodetect: { value: "autodetect", label: "Autodetect" }, ... }
export const transformationsByType = Object.values(transformationsByColumnType)
  .reduce((acc, arr) => acc.concat(arr), [])
  .reduce((result, p) => {
    result[p.value] = p;
    return result;
  }, {});

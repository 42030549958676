import { useRef } from "react";
import { TableView } from "../../../../common/Table/TableView";
import { colors } from "../../../../styled/theme/colorPalette";
import { Div } from "../../../../styled/utils";
import { defaultTableHeaderHeightPx } from "../../../../common/Table/TableView/common";
import { getTableHeight } from "../../../common";
import { useImporterContext } from "../../../contexts/ImporterContextProvider";
import { MAX_VISIBLE_ROWS } from "../../common";
import { MatcherStatus } from "../../common/types";
import { EnumFieldMatcherContextProvider } from "../EnumFieldMatcherContext";
import { tableColumns } from "./tableColumns";
import { useTranslation } from "react-i18next";

export const EnumFieldMatcher = ({ templateHeaderLabel }) => {
  const { t } = useTranslation("match");
  const { enumFieldValueMatchings, templateHeaders } = useImporterContext();

  const isRequired = templateHeaders.find(
    (th) => th.label === templateHeaderLabel
  )?.required;

  const enumFieldValueMatching = enumFieldValueMatchings[templateHeaderLabel];
  const uploadedDataValues = Object.keys(enumFieldValueMatching || {});

  const data = uploadedDataValues.map((uploadedDataValue) => ({
    uploadedDataValue,
    uploadedDataValueMatching: enumFieldValueMatching[uploadedDataValue],
    status: enumFieldValueMatching[uploadedDataValue]
      ? MatcherStatus.matched
      : MatcherStatus.unmatched,
  }));

  const dataSortingOrderRef = useRef(
    uploadedDataValues
      .map((uploadedDataValue, index) => ({
        index,
        isMatched: !!enumFieldValueMatching[uploadedDataValue],
      }))
      .sort((a, b) => {
        if (a.isMatched === b.isMatched) {
          return a.index - b.index;
        } else if (a.isMatched) {
          return -1;
        } else {
          return 1;
        }
      })
  );

  //sort data based on automatched items
  const sortedData = dataSortingOrderRef.current.map(
    (sorting) => data[sorting.index]
  );

  const tableRowHeight = 100;
  const tableHeight = getTableHeight({
    headerHeight: defaultTableHeaderHeightPx,
    rowHeight: tableRowHeight,
    dataCount: data.length,
    maxVisibleRows: MAX_VISIBLE_ROWS,
  });

  return (
    <Div data-cy="value-mappings">
      <Div h5 mb={16}>
        {t("columns.values_for_the_column", {
          column: `${templateHeaderLabel}${isRequired ? "*" : ""}`,
        })}
      </Div>
      <Div mb={40}>
        <EnumFieldMatcherContextProvider
          templateHeaderLabel={templateHeaderLabel}
        >
          <TableView
            rowCount={sortedData.length}
            h={tableHeight}
            rowHeight={tableRowHeight}
            data={sortedData}
            columns={tableColumns}
            bg={colors.white}
          />
        </EnumFieldMatcherContextProvider>
      </Div>
    </Div>
  );
};

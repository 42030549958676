import React from "react";
import {
  SpreadsheetAutocomplete,
  SpreadsheetAutocompleteProps,
} from "../fields/SpreadsheetAutocomplete";

const booleanOptions = [
  { label: "True", value: true },
  { label: "False", value: false },
];

export const BooleanInput: React.FC<SpreadsheetAutocompleteProps> = (props) => (
  <SpreadsheetAutocomplete
    isInputDisabled={true}
    {...props}
    options={booleanOptions}
  />
);

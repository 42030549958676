import { addToast } from "..";
import { api } from "../../api";
import { Div } from "../../styled/utils";
import { useTableContext } from "./TableDataProvider";
import { UniqueIdentifier } from "@dnd-kit/core";

export const useReorderRows = (
  templateSlug: string,
  customMessage?: string | JSX.Element
) => {
  const { getData } = useTableContext();

  const reorderRows = async (columnId: UniqueIdentifier, position: number) => {
    const apiUrl = `/api/v1/templates/${templateSlug}/columns/${columnId}`;

    const defaultMessage = (
      <Div alignCenter>
        <Div>Columns reordered successfully</Div>
      </Div>
    );

    const confirmationMessage = (
      <Div spaceBetween alignCenter>
        {customMessage || defaultMessage}
      </Div>
    );

    try {
      await api.patch(apiUrl, {
        position,
      });
      addToast(confirmationMessage, "success");
      getData();
    } catch (error) {
      addToast("Failed to reorder rows", "error");
    }
  };

  return { reorderRows };
};

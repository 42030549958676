import { useState } from "react";
import { Div, api } from "fuse-shared-ui";
import styled from "styled-components";
import Form, {
  FormikSumbitButton as FormikSubmitButton,
} from "components/common/form/Form";
import { FormikTextInput } from "components/common/form/fields";
import fields from "./fields";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useBillingContext } from "../../BillingContextProvider";
import CardField from "./CardField";
import { useUserContext } from "components/UserContextProvider";
import { AppLoading } from "components/AppLoading";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

interface PropsTypes {
  headerText?: string;
  buttonLabel: string;
  onClose: () => void;
  onPaymentMethodCreated?: () => void;
}

const Wrapper = styled(Div)`
  ${({ theme }) => theme.css.justifyCenter};
  ${({ theme }) => theme.css.alignCenter};
  width: 600px;
  @media all and (max-width: 1051px) {
    width: 100%;
  }
`;

const Container = styled(Div)`
  width: 480px;
  @media all and (max-width: 1051px) {
    width: 70vw;
  }
`;

const PaymentMethodsForm = (props: PropsTypes): JSX.Element => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc:
              "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600",
          },
        ],
      }}
    >
      <PaymentMethodsFormWithStripe {...props} />
    </Elements>
  );
};

const PaymentMethodsFormWithStripe = ({
  headerText,
  buttonLabel,
  onClose,
  onPaymentMethodCreated = null,
}: PropsTypes): JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const { getPaymentMethods } = useBillingContext();
  const [isCardFieldComplete, setIsCardFieldComplete] = useState(false);
  const [cardError, setCardError] = useState<any>(null);
  const {
    user: { active_organization },
  } = useUserContext();

  const handleSubmit = async (values) => {
    const card = elements.getElement(CardElement);
    const params = {
      type: "card",
      card,
      billing_details: {
        name: values.cardHolder,
        email: values.email,
        phone: values.phone,
      },
      customer_balance: null,
    };

    const createPaymentMethod = async () => {
      setIsLoading(true);
      const paymentMethodRes = await stripe.createPaymentMethod(params);
      const { paymentMethod, error: paymentMethodError } =
        paymentMethodRes || {};

      if (paymentMethod) {
        try {
          await api.post(
            `/api/v1/organizations/payment_methods?organization_id=${active_organization.id}`,
            { payment_method: { id: paymentMethod.id } },
            { successMsg: "Payment Method successfully changed" }
          );
          setCardError(null);
          await getPaymentMethods();
          if (onPaymentMethodCreated) onPaymentMethodCreated();
          onClose();
        } catch (err) {
          setIsLoading(false);
        }
      } else if (paymentMethodError) {
        setIsLoading(false);
        setCardError(paymentMethodError);
      }
    };

    await createPaymentMethod();
  };

  const handleCardBlur = () => {
    if (!isCardFieldComplete) {
      setCardError({
        code: "incomplete",
        type: "incomplete",
        message: "Card information required",
      });
    }
  };

  const handleCardChange = (e) => {
    if (e.error) setCardError(e.error);
    else if (!e.complete) {
      setCardError({
        code: "incomplete",
        type: "incomplete",
        message: "Card information required",
      });
    } else {
      setCardError(null);
      setIsCardFieldComplete(true);
    }
  };

  return (
    <Wrapper>
      <Form fields={fields} onSubmit={handleSubmit}>
        <Container>
          {headerText && (
            <Div mb={30} style={{ textAlign: "center" }}>
              <Div h5>{headerText}</Div>
            </Div>
          )}
          {fields.map((field, idx) => {
            return (
              <Div key={idx} m="16px 0">
                <FormikTextInput withDebounce={false} {...field} />
              </Div>
            );
          })}
          <CardField
            onChange={handleCardChange}
            onBlur={handleCardBlur}
            error={cardError}
          />
          <Div mt={10} h={50} alignCenter>
            {isLoading && <AppLoading />}
            <FormikSubmitButton
              disableOnErrors={true}
              isDisabled={!!cardError || isLoading || !isCardFieldComplete}
            >
              {buttonLabel}
            </FormikSubmitButton>
          </Div>
        </Container>
      </Form>
    </Wrapper>
  );
};

export default PaymentMethodsForm;

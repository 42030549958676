import { useState } from "react";
import styled from "styled-components";
import {
  Button,
  Div,
  Modal,
  addToast,
  api,
  useTableContext,
} from "fuse-shared-ui";
import { deleteImageIcon } from "../../TeamMembers/common/icons";
import {
  User,
  useUserContext,
} from "../../../../../components/UserContextProvider";
import { useMemo } from "react";

const Content = styled(Div)`
  width: 448px;
  height: 280px;
  margin: 48px 40px;
`;

const Text = styled(Div)`
  margin-top: 12px;
  ${(p) => p.theme.textStyles.wbody1};
  justify-content: center;
`;

const TextInner = styled(Div)`
  margin: 0 5px;
  ${(p) => p.theme.textStyles.wbody2};
`;

const FormButtonsWrapper = styled(Div)`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

type DeleteModalProps = {
  isOpen: boolean;
  slug: string;
  onClose: () => void;
};

const DeleteImportModal = ({ isOpen, slug, onClose }: DeleteModalProps) => {
  const {
    user: { active_organization },
  } = useUserContext();
  const { getData } = useTableContext();

  const deleteTableRow = async () => {
    try {
      await api.delete(
        `/api/v1/organizations/${active_organization.id}/imports/${slug}`
      );
      addToast("The import has been deleted", "success");
      getData();
    } catch (e) {
      console.error(e);
    }
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDelete = async () => {
    setIsLoading(true);

    await deleteTableRow();

    setIsLoading(false);
    onClose();
  };

  const contentUI = (
    <Content>
      <Div textAlignCenter>
        {deleteImageIcon}
        <Div mt={20} h5>
          Delete Import
        </Div>
        <Text data-cy="delete-import-pop-up-description">
          Are you sure you want delete this import? The associated data will be
          removed from the system.
        </Text>
      </Div>
      <FormButtonsWrapper>
        <Button
          mr={16}
          w={104}
          variant="secondary"
          onClick={onClose}
          isDisabled={isLoading}
        >
          Cancel
        </Button>
        <Button w={135} onClick={onDelete} isDisabled={isLoading}>
          Yes, delete
        </Button>
      </FormButtonsWrapper>
    </Content>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {contentUI}
    </Modal>
  );
};

export default DeleteImportModal;

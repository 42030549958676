import React from "react";
import styled, { css } from "styled-components";
import { Div, DivCSSProps } from "../../styled/utils";

interface ContainerProps {
  isDisabled: boolean;
}

const Container = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
`;

const IconContainer = styled.div<ContainerProps>`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray400};
  background-color: ${(props) => props.theme.colors.white};
  ${(props: any) =>
    (props.isChecked || props.isIndeterminate) &&
    css`
      border: 1px solid white;
      background-color: ${props.theme.colors.pink500};
    `};

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.6;

      :hover {
        cursor: not-allowed;
      }
    `}
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

type Props = {
  isChecked: boolean;
  isIndeterminate?: boolean;
  icon?: any;
  onChange?: (isChecked: boolean) => void;
  onShiftKeyPressed?: () => void;
  isDisabled?: boolean;
  label?: string;
} & DivCSSProps;

const Checkbox = ({
  isChecked,
  isIndeterminate,
  icon = null,
  onChange,
  onShiftKeyPressed,
  isDisabled = false,
  label = null,
  ...props
}: Props) => {
  const handleOnChange = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (!onChange) return;

    if ((event.shiftKey || event.nativeEvent.metaKey) && onShiftKeyPressed) {
      onShiftKeyPressed();
      return;
    }

    const newValue = !isChecked || isIndeterminate;
    onChange(newValue);
  };

  return (
    <Container data-test-id="checkbox" onClick={handleOnChange}>
      <IconContainer
        {...{
          isChecked,
          isDisabled,
          isIndeterminate,
          ...props,
        }}
      >
        {isIndeterminate ? (
          <IndeterminateIcon />
        ) : (
          <Div centered>{isChecked && (icon || checkIcon)}</Div>
        )}
      </IconContainer>
      {label && <Label>{label}</Label>}
    </Container>
  );
};

const checkIcon = (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.10254 4.74355L5.2051 8.84612L12.8974 1.15381"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const IndeterminateIcon = () => (
  <svg
    width="14"
    height="2"
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1H13" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export { Checkbox };

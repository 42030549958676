import { Table, TableDataProvider, useTableContext } from "fuse-shared-ui";
import { Title, Wrapper } from "../common/styles";
import { tableColumns } from "./TableColumns";
import { useBillingContext } from "../BillingContextProvider";
import { useEffect, useRef } from "react";

const InvoicesWithContext = () => {
  const { data, getData } = useTableContext();
  const { subscriptionData } = useBillingContext();
  const subscriptionRef = useRef(subscriptionData);

  useEffect(() => {
    if (subscriptionData !== subscriptionRef.current) {
      const ref = setTimeout(() => getData(), 500);
      return () => clearTimeout(ref);
    }
  }, [subscriptionData]);

  return data.length ? (
    <Wrapper flexColumn gap={20}>
      <Title data-cy="invoices">Invoices</Title>
      <Table
        withoutBorders
        columns={tableColumns}
        disableSorting
        paginationProps={{ pb: 0 }}
      />
    </Wrapper>
  ) : null;
};

export const InvoicesTable = () => {
  return (
    <TableDataProvider
      dataUrl={"/api/v1/organizations/invoices"}
      defaultPageSize={10}
    >
      <InvoicesWithContext />
    </TableDataProvider>
  );
};

import { Plan as PlanType } from "components/UserContextProvider";
import { Div, P } from "fuse-shared-ui";
import styled from "styled-components";
import { BillingSection } from "../common/index";
import ChangePlanButton from "./components/ChangePlanButton";
import PlanSpecs from "./components/PlanSpecs";
import { PlanOptions } from "./specs";

const PlanWrapper = styled(BillingSection)`
  border: 1px solid;
  background: ${(p) => p.theme.colors.white};
  border-color: ${(p) => p.theme.colors.outlineInputs};
  border-radius: 8px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
`;

const Monthly = styled(Div)`
  color: ${(p) => p.theme.colors.textSecondary};
`;

const Title = styled(Div)`
  text-transform: capitalize;
  ${(p) => p.theme.css.h5};
`;

const MostPopularLabel = styled(Div)`
  padding: 5px 8px;
  border-radius: 4px;
  background: ${(p) => p.theme.colors.blue100};
  color: ${(p) => p.theme.colors.blue900};
  ${(p) => p.theme.textStyles.wlabel3};
`;

type PlanProps = {
  plan: PlanType;
  testId?: string;
};

const Plan = ({ plan, testId }: PlanProps) => {
  const { title, description, format_monthly_price, recurrence_period } = plan;
  const formattedPlanTitle = title.split("_")[0].toLowerCase();
  const isEnterprise = formattedPlanTitle === PlanOptions.enterprise;

  const titleUI = (
    <Div spaceBetween alignCenter data-cy="plan-title">
      <Title label1>{formattedPlanTitle}</Title>
      {formattedPlanTitle === PlanOptions.professional && (
        <MostPopularLabel data-cy="most-popular">MOST POPULAR</MostPopularLabel>
      )}
    </Div>
  );

  const recurrencePeriodDescription =
    recurrence_period === "year" ? "/month paid annually" : "/month";

  const priceUI = (
    <Div dflex mt={16}>
      {isEnterprise ? (
        <P data-cy="custom-plan" h3 m={0}>
          Custom
        </P>
      ) : (
        <>
          <Div h3 extraBold data-cy={`${plan.title.toLowerCase()}-price`}>
            ${format_monthly_price}
          </Div>
          <Monthly data-cy="recurrence-period" wbody1 alignEnd>
            {recurrencePeriodDescription}
          </Monthly>
        </>
      )}
    </Div>
  );

  const descriptionUI = (
    <Div dflex alignCenter mt={12}>
      <Div
        data-cy="description"
        maxw={isEnterprise ? 274 : "auto"}
        body3
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Div>
  );

  return (
    <PlanWrapper data-testid={testId}>
      <Div>
        {titleUI}
        {priceUI}
        {descriptionUI}
        <PlanSpecs data-cy="spec-plan" plan={plan} />
      </Div>
      <Div mt={40}>
        <ChangePlanButton plan={plan} />
      </Div>
    </PlanWrapper>
  );
};

export default Plan;

import { Log } from "../interfaces";

export const between = (x: number, min: number, max: number) => {
  return x >= min && x <= max;
};

export const translatedStatus = (isSuccess: boolean) => {
  return isSuccess
    ? {
        status: "SUCCESS",
        variant: "success",
      }
    : {
        status: "FAILED",
        variant: "error",
      };
};

export const getResponseColor = (status: number) => {
  if (between(status, 200, 299)) return "success";
  if (between(status, 500, 599)) return "error";
  return "warning";
};

export const getErrorDetails = (log: Log) => {
  if (log.exception_message) return log.exception_message;
  if (log.response_body) return log.response_body;

  return null;
};

import { useRef } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { Button, Div, useDragAndDrop } from "fuse-shared-ui";

import Header from "../../../../components/Header";
import * as S from "./styles";
import { DragAndDropSvg, UploadingIndicator } from "./icons";

interface DragAndDropFormProps {
  onSelectManual: () => void;
  handleOnComplete?: (data: any, fileName: string) => void;
  isLoading?: boolean;
  shouldShowManualCreationButton?: boolean;
}

const DropzoneSkeleton = () => {
  return (
    <S.SkeletonContainer dflex flexColumn alignCenter>
      <Skeleton height={31} width={436} />
      <Skeleton height={22} width={214} />

      <S.BottomSkeletons dflex flexColumn>
        <Skeleton height={21} width={517} />
        <Skeleton height={23} width={517} />
      </S.BottomSkeletons>
    </S.SkeletonContainer>
  );
};

const DropzoneLoadingUI = () => {
  return (
    <S.DropzoneLoadingContainer centered flexColumn>
      <DropzoneSkeleton />

      <S.SpinnerContainer mt={126} mb={110}>
        <S.Spinner />
        <S.SpinnerText wbody3>Uploading...</S.SpinnerText>
      </S.SpinnerContainer>
    </S.DropzoneLoadingContainer>
  );
};

const DropzoneUploadingUI = () => {
  return (
    <S.DropzoneUploadingContainer centered>
      <Div>
        <Div centered mb={12}>
          <UploadingIndicator />
        </Div>
        <Div h5> Drop your file here to upload!</Div>
      </Div>
    </S.DropzoneUploadingContainer>
  );
};

const DragAndDropComponent = ({ openUploadFileDialog }) => {
  return (
    <Div centered flexColumn>
      <Div mb={12}>
        <DragAndDropSvg />
      </Div>
      <Div h6>Drag & drop your file here</Div>
      <Button
        mt={20}
        variant="secondary"
        w={"fit-content"}
        p={12}
        isSmall
        onClick={openUploadFileDialog}
      >
        Browse files
      </Button>
    </Div>
  );
};

const Dropzone = ({ isDragActive, getRootProps, getInputProps, open }) => {
  return (
    <S.DragAndDropCard
      isDragActive={isDragActive}
      centered
      pRelative
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive && <DropzoneUploadingUI />}
      <DragAndDropComponent openUploadFileDialog={open} />
    </S.DragAndDropCard>
  );
};

const DragAndDropForm = ({
  handleOnComplete,
  onSelectManual,
  isLoading,
  shouldShowManualCreationButton,
}: DragAndDropFormProps) => {
  const fileNameRef = useRef("");

  const onComplete = (data: any) => {
    handleOnComplete(data, fileNameRef.current);
  };

  const saveFileName = (fileName: string) => {
    fileNameRef.current = fileName;
  };

  const { getInputProps, getRootProps, isDragActive, open } = useDragAndDrop({
    onComplete,
    saveFileName,
  });

  return (
    <>
      {isLoading && <DropzoneLoadingUI />}

      {!isLoading && (
        <>
          <Header
            mt={0}
            mb={72}
            title="Upload a clean CSV file to create an importer"
            subTitle={
              "Upload any .csv, .xls, .xlsx spreadsheet file with any set of columns as long as it has one record per row."
            }
            subTitleWidth={405}
          />

          <S.Container
            isDragActive={isDragActive}
            shouldHaveNegativeMargin={shouldShowManualCreationButton}
          >
            <Dropzone
              getInputProps={getInputProps}
              getRootProps={getRootProps}
              isDragActive={isDragActive}
              open={open}
            />

            {!isDragActive && shouldShowManualCreationButton && (
              <>
                <Div>Or</Div>
                <Div>
                  <Button
                    data-cy="add-column-manually"
                    variant="link"
                    w={"fit-content"}
                    p={12}
                    isSmall
                    onClick={onSelectManual}
                  >
                    Add columns manually
                  </Button>
                </Div>
              </>
            )}
          </S.Container>
        </>
      )}
    </>
  );
};

export default DragAndDropForm;

import { FormikAutocomplete } from "components/common/form/fields";
import { useMemo } from "react";
import { transformationsByColumnType } from "fuse-shared-ui";

type Props = {
  name: string;
  type: string;
  isDisabled?: boolean;
  includeDefaultOptions?: boolean;
};

const TransformationTypeSelect = ({
  name,
  type,
  isDisabled,
  includeDefaultOptions,
}: Props) => {
  const options = useMemo(() => transformationsByColumnType[type] ?? [], [
    type,
    includeDefaultOptions,
  ]);

  return (
    <FormikAutocomplete
      name={`${name}.transformation_type`}
      label="Select Transformations"
      placeholder="Select option"
      isDisabled={isDisabled}
      options={options}
    />
  );
};

export default TransformationTypeSelect;

export const containerBackground = (
  <svg
    width="393"
    height="740"
    viewBox="0 0 393 740"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "darken" }}>
      <path
        d="M393 16C393 7.16344 385.837 -1.90735e-05 377 -1.90735e-05L159.798 -1.90735e-05L0.00012207 740H393V16Z"
        fill="url(#paint0_linear_2290_48903)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2290_48903"
        x1="294.504"
        y1="1.66184e-05"
        x2="-222.749"
        y2="1431.08"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8CFFDD" />
        <stop offset="1" stopColor="#02FFF0" stopOpacity="0.760417" />
      </linearGradient>
    </defs>
  </svg>
);

export const containerHalfEllipse = (
  <svg
    width="120"
    height="240"
    viewBox="0 0 120 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M120 240C104.241 240 88.6372 236.896 74.0781 230.866C59.519 224.835 46.2903 215.996 35.1473 204.853C24.0043 193.71 15.1651 180.481 9.13458 165.922C3.10401 151.363 0.000116136 135.759 0.000116825 120C0.000117514 104.241 3.10402 88.637 9.13458 74.0779C15.1651 59.5189 24.0043 46.2902 35.1473 35.1471C46.2903 24.0041 59.519 15.165 74.0781 9.13442C88.6372 3.10385 104.242 -3.78294e-05 120 -3.57629e-05L120 120L120 240Z"
        fill="url(#paint0_linear_2290_48905)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2290_48905"
        x1="39.6733"
        y1="-17.4184"
        x2="175.776"
        y2="355.453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const containerSmallCircle = (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "overlay" }} opacity="0.6">
      <path
        d="M64.0001 32C64.0001 49.6731 49.6732 64 32.0001 64C14.327 64 0.00012207 49.6731 0.00012207 32C0.00012207 14.3269 14.327 0 32.0001 0C49.6732 0 64.0001 14.3269 64.0001 32Z"
        fill="url(#paint0_linear_2290_48904)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2290_48904"
        x1="-4.64478"
        y1="53.4205"
        x2="94.7876"
        y2="17.1264"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const background = (
  <svg
    width="532"
    height="732"
    viewBox="0 0 532 732"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "darken" }}>
      <path
        d="M532 -1.52588e-05L138 -2.24159e-06L1.53882 684.176C-3.39685 708.922 15.5328 732 40.7661 732H492C514.091 732 532 714.091 532 692L532 -1.52588e-05Z"
        fill="url(#paint0_linear_2290_48883)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2290_48883"
        x1="287.86"
        y1="-248.332"
        x2="519.28"
        y2="925.18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6BEEFF" />
        <stop offset="1" stopColor="#0DDFD3" stopOpacity="0.760417" />
      </linearGradient>
    </defs>
  </svg>
);

export const backgroundCircles = (
  <svg
    width="240"
    height="240"
    viewBox="0 0 240 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M239.75 120C239.75 186.136 186.136 239.75 120 239.75C53.864 239.75 0.250122 186.136 0.250122 120C0.250122 53.8639 53.864 0.249969 120 0.249969C186.136 0.249969 239.75 53.8639 239.75 120Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M231.082 120C231.082 181.349 181.349 231.082 120 231.082C58.6513 231.082 8.91815 181.349 8.91815 120C8.91815 58.651 58.6513 8.91791 120 8.91791C181.349 8.91791 231.082 58.651 231.082 120Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M222.414 120C222.414 176.562 176.562 222.414 120 222.414C63.4384 222.414 17.586 176.562 17.586 120C17.586 63.4382 63.4384 17.5858 120 17.5858C176.562 17.5858 222.414 63.4382 222.414 120Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M213.746 120C213.746 171.774 171.775 213.746 120 213.746C68.2256 213.746 26.254 171.774 26.254 120C26.254 68.2253 68.2256 26.2538 120 26.2538C171.775 26.2538 213.746 68.2253 213.746 120Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M205.066 120C205.066 166.981 166.981 205.066 120 205.066C73.0194 205.066 34.9341 166.981 34.9341 120C34.9341 73.0193 73.0194 34.9339 120 34.9339C166.981 34.9339 205.066 73.0193 205.066 120Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M196.398 120C196.398 162.193 162.194 196.398 120 196.398C77.8066 196.398 43.6021 162.193 43.6021 120C43.6021 77.8064 77.8066 43.6018 120 43.6018C162.194 43.6018 196.398 77.8064 196.398 120Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M187.73 120C187.73 157.406 157.406 187.73 120 187.73C82.5938 187.73 52.27 157.406 52.27 120C52.27 82.5938 82.5938 52.27 120 52.27C157.406 52.27 187.73 82.5938 187.73 120Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M121.021 60.9198C153.64 61.2459 179.819 87.9534 179.492 120.573C179.166 153.192 152.459 179.37 119.84 179.044C87.2204 178.718 61.0418 152.01 61.368 119.391C61.6942 86.7722 88.4017 60.5936 121.021 60.9198Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M120.118 69.6048C147.943 69.6728 170.444 92.2846 170.376 120.11C170.308 147.935 147.697 170.437 119.871 170.369C92.0462 170.301 69.5446 147.689 69.6126 119.864C69.6806 92.0384 92.2924 69.5368 120.118 69.6048Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M161.713 119.933C161.749 142.971 143.103 161.676 120.065 161.712C97.0265 161.749 78.3212 143.102 78.285 120.064C78.2488 97.0258 96.8955 78.3205 119.933 78.2843C142.972 78.2481 161.677 96.8948 161.713 119.933Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M153.052 119.79C153.161 138.04 138.453 152.923 120.203 153.031C101.952 153.14 87.0695 138.433 86.9612 120.182C86.8529 101.931 101.56 87.0487 119.811 86.9404C138.061 86.8321 152.944 101.539 153.052 119.79Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M144.377 119.747C144.516 133.21 133.714 144.236 120.251 144.375C106.788 144.513 95.7617 133.712 95.623 120.249C95.4844 106.786 106.286 95.7594 119.749 95.6208C133.212 95.4821 144.238 106.284 144.377 119.747Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M135.699 119.769C135.829 128.438 128.907 135.571 120.238 135.701C111.569 135.831 104.436 128.91 104.306 120.241C104.176 111.572 111.098 104.439 119.767 104.309C128.436 104.179 135.569 111.101 135.699 119.769Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M127.03 119.855C127.108 123.737 124.024 126.947 120.142 127.025C116.26 127.103 113.05 124.019 112.972 120.137C112.894 116.255 115.978 113.045 119.86 112.967C123.742 112.889 126.952 115.973 127.03 119.855Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export const backgroundEllipse = (
  <svg
    width="130"
    height="260"
    viewBox="0 0 130 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "overlay" }} opacity="0.5">
      <path
        d="M0.00012207 -3.05176e-05C17.072 -3.0314e-05 33.9767 3.36252 49.749 9.89564C65.5213 16.4288 79.8524 26.0045 91.924 38.0761C103.996 50.1477 113.571 64.4788 120.104 80.2512C126.638 96.0235 130 112.928 130 130C130 147.072 126.638 163.977 120.104 179.749C113.571 195.521 103.996 209.852 91.924 221.924C79.8523 233.996 65.5212 243.571 49.7489 250.104C33.9765 256.637 17.0719 260 1.52588e-05 260L0.00012207 130V-3.05176e-05Z"
        fill="url(#paint0_linear_2290_48884)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2290_48884"
        x1="-148.87"
        y1="217.021"
        x2="255.074"
        y2="69.576"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const backgroundQuarterEllipse = (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M120 -2.90866e-05L120 120L0.000120639 120C0.0119713 88.1484 12.9424 57.6053 35.9482 35.0869C58.9539 12.5685 87.471 -3.15881e-05 120 -2.90866e-05Z"
        fill="#76ECE5"
        fillOpacity="0.8"
      />
    </g>
  </svg>
);

export const backgroundYellowHalfEllipse = (
  <svg
    width="240"
    height="120"
    viewBox="0 0 240 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M240 -3.05176e-05C240 15.7586 236.896 31.3629 230.866 45.922C224.835 60.481 215.996 73.7097 204.853 84.8528C193.71 95.9958 180.481 104.835 165.922 110.866C151.363 116.896 135.759 120 120 120C104.241 120 88.6372 116.896 74.0781 110.866C59.519 104.835 46.2903 95.9958 35.1473 84.8528C24.0043 73.7097 15.1651 60.481 9.13457 45.922C3.10401 31.3629 0.000120693 15.7586 0.00012207 -3.8147e-05L120 -3.05176e-05H240Z"
      fill="url(#paint0_linear_2290_48900)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2290_48900"
        x1="0.000116264"
        y1="241.368"
        x2="316.976"
        y2="11.9488"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0A617" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
    </defs>
  </svg>
);

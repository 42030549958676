import React from "react";

export const chevronDown = (
  <svg
    width="9"
    height="5"
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.31848 4.93945L0.421366 0.439453L8.2156 0.439454L4.31848 4.93945Z"
      fill="#102622"
    />
  </svg>
);

export const chevronRight = (
  <svg
    width="6"
    height="8"
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.31848 4.06738L0.818482 7.9645L0.818482 0.170269L5.31848 4.06738Z"
      fill="#102622"
    />
  </svg>
);

export const spreedSheetIcon = (
  <svg
    width="376"
    height="256"
    viewBox="0 0 376 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_4260_84132)">
      <rect x="12" y="8" width="352" height="231.997" rx="4" fill="white" />
      <g clipPath="url(#clip0_4260_84132)">
        <path
          d="M364 40.2222H12V236C12 238.209 13.7909 240 16 240H360C362.209 240 364 238.209 364 236V40.2222Z"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1463 224.239H364.146"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1463 208.937H364.146"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1463 193.655H364.146"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1463 178.355H364.146"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1463 163.052H364.146"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1463 147.752H364.146"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1463 132.47H364.146"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1463 117.169H364.146"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1463 101.867H364.146"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1463 86.5674H364.146"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1463 71.2844H364.146"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M12.1463 55.9827H364.146"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M305.004 40.6831V239.54"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M246.511 40.6831V239.54"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M188 40.6831V239.54"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M129.489 40.6831V239.54"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M70.9966 40.6831V239.54"
          stroke="#E6DBDB"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <rect
          x="17.2543"
          y="61.252"
          width="49.9104"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="75.045"
          y="61.252"
          width="49.9105"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="75.045"
          y="92.7949"
          width="49.9105"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="75.045"
          y="105.938"
          width="49.9105"
          height="5.2573"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="75.045"
          y="137.482"
          width="49.9105"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="75.045"
          y="153.254"
          width="49.9105"
          height="5.25734"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="17.2543"
          y="137.482"
          width="49.9104"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="17.2543"
          y="153.254"
          width="49.9104"
          height="5.25734"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="17.2543"
          y="169.026"
          width="49.9104"
          height="5.25732"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="17.2543"
          y="184.798"
          width="49.9104"
          height="5.25732"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="17.2543"
          y="197.942"
          width="49.9104"
          height="5.25729"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="17.2543"
          y="213.713"
          width="49.9104"
          height="5.25729"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="17.2543"
          y="229.485"
          width="49.9104"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="193.253"
          y="121.71"
          width="49.9104"
          height="5.25733"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="193.253"
          y="182.169"
          width="49.9104"
          height="5.25733"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="251.044"
          y="182.169"
          width="49.9105"
          height="5.25733"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="251.044"
          y="121.71"
          width="49.9105"
          height="5.25733"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="251.044"
          y="58.623"
          width="49.9105"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="193.253"
          y="137.482"
          width="49.9104"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="193.253"
          y="197.942"
          width="49.9104"
          height="5.25729"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="251.044"
          y="197.942"
          width="49.9105"
          height="5.25729"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="251.044"
          y="137.482"
          width="49.9105"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="251.044"
          y="74.3953"
          width="49.9105"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="193.253"
          y="153.254"
          width="49.9104"
          height="5.25734"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="193.253"
          y="213.713"
          width="49.9104"
          height="5.25729"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="251.044"
          y="213.713"
          width="49.9105"
          height="5.25729"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="251.044"
          y="153.254"
          width="49.9105"
          height="5.25734"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="251.044"
          y="90.1672"
          width="49.9105"
          height="5.2573"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="193.253"
          y="169.026"
          width="49.9104"
          height="5.25732"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="193.253"
          y="229.485"
          width="49.9104"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="251.044"
          y="229.485"
          width="49.9105"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="251.044"
          y="169.026"
          width="49.9105"
          height="5.25732"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="251.044"
          y="105.938"
          width="49.9105"
          height="5.2573"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="17.2543"
          y="45.48"
          width="49.9104"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="75.045"
          y="45.48"
          width="49.9105"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="75.045"
          y="77.0229"
          width="49.9105"
          height="5.25731"
          rx="1"
          fill="#E6DBDB"
        />
        <rect
          x="17.2543"
          y="121.71"
          width="49.9104"
          height="5.25733"
          rx="1"
          fill="#E6DBDB"
        />
      </g>
    </g>
    <path
      d="M12 12.0015C12 9.79233 13.7909 8.00146 16 8.00146H360C362.209 8.00146 364 9.79233 364 12.0015V40.2237H12V12.0015Z"
      fill="#00D085"
    />
    <circle cx="306.77" cy="24.0002" r="6" fill="#025F40" />
    <circle cx="324.77" cy="24.0002" r="6" fill="#025F40" />
    <circle cx="342.77" cy="24.0002" r="6" fill="#025F40" />
    <defs>
      <filter
        id="filter0_d_4260_84132"
        x="0"
        y="0"
        width="376"
        height="256"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4260_84132"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4260_84132"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_4260_84132">
        <path
          d="M12 40.2222H364V236C364 238.209 362.209 240 360 240H16C13.7909 240 12 238.209 12 236V40.2222Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

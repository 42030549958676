import { useEffect, useState } from "react";
import { Modal, RetryModal } from "../../common";
import { RecordDataSet } from "fuse-importer";
import { useDataSetContext } from "../common/Spreadsheet/DataSetContextProvider";
import { useFilteredDataContext } from "../common/Spreadsheet/FilteredDataContextProvider";
import { usePersistenceContext } from "../common/Spreadsheet/PersistenceContextProvider";
import { useImporterContext } from "../contexts/ImporterContextProvider";
import {
  ErrorAppResponseModal,
  SuccessAppResponseModal,
} from "./AppResponseModal";
import { PersistenceLoaderModal } from "./PersistenceLoaderModal";
import { useReviewContext } from "./ReviewContextProvider";
import { SubmitModal } from "./SubmitModal";

export type SpreadsheetSubmissionModalProps = {
  shouldOpenModals: boolean;
  onCloseModal: () => void;
  onSubmit: (dataSet: RecordDataSet, filteredDataIds: string[]) => void;
};

export const MODAL_STEPS = {
  SUBMIT_MODAL: "SUBMIT_MODAL",
  PROCESSING_PERSISTENCE_MODAL: "PROCESSING_PERSISTENCE_MODAL",
  BACKEND_RESPONSE_MODAL: "BACKEND_RESPONSE_MODAL",
  RETRY_MODAL: "RETRY_MODAL",
};

export const SpreadsheetSubmissionModal = ({
  shouldOpenModals,
  onCloseModal,
  onSubmit,
}: SpreadsheetSubmissionModalProps) => {
  const [modalStep, setModalStep] = useState(MODAL_STEPS.SUBMIT_MODAL);
  const { dataSet } = useDataSetContext();
  const { filteredDataIds } = useFilteredDataContext();

  const {
    isCloseRetryState,
    importer,
    setIsRetryModalOpen,
    playQueue,
    isRetryModalOpen,
    stopQueue,
  } = usePersistenceContext();

  const { onClose: onCloseImporter } = useImporterContext();
  const { importWasSuccessful, isSubmitting } = useReviewContext();
  const [
    isWaitingForIncognitoModeResponse,
    setIsWaitingForIncognitoModeResponse,
  ] = useState(false);

  useEffect(() => {
    if (isWaitingForIncognitoModeResponse && !isSubmitting) {
      setIsWaitingForIncognitoModeResponse(false);
      callNextModal();
    }
  }, [isSubmitting]);

  const reset = () => {
    onCloseModal();
    setModalStep(MODAL_STEPS.SUBMIT_MODAL);
  };

  const handleCloseBackendResponseModal = () => {
    if (importWasSuccessful) {
      onCloseImporter();
      setModalStep(null);
    }

    reset();
  };

  const handleCloseRetryModal = () => {
    stopQueue();
    reset();
  };

  const handleSubmitRetryModal = () => {
    setIsRetryModalOpen(false);
    const status = importer?.current?.status;
    if ("failed" === status) {
      handleOnSubmit();
    } else {
      playQueue();
    }
    callSpecificModal(MODAL_STEPS.PROCESSING_PERSISTENCE_MODAL);
  };

  const handleOnSubmit = async () => {
    const isIncognitoModeImporter = !importer?.current?.persistence;
    if (isIncognitoModeImporter) setIsWaitingForIncognitoModeResponse(true);
    onSubmit(dataSet.current, filteredDataIds);
  };

  const callNextModal = () => {
    switch (modalStep) {
      case MODAL_STEPS.SUBMIT_MODAL:
        if (importer?.current?.persistence) {
          setModalStep(MODAL_STEPS.PROCESSING_PERSISTENCE_MODAL);
        } else {
          setModalStep(MODAL_STEPS.BACKEND_RESPONSE_MODAL);
        }
        break;
      case MODAL_STEPS.PROCESSING_PERSISTENCE_MODAL:
        setModalStep(MODAL_STEPS.BACKEND_RESPONSE_MODAL);
        break;
    }
  };

  const callSpecificModal = (modalStep: string) => {
    setModalStep(modalStep);
  };

  const modalProps = {
    PROCESSING_PERSISTENCE_MODAL: {
      isOpen: modalStep === MODAL_STEPS.PROCESSING_PERSISTENCE_MODAL,
      withCloseIcon: false,
      withBackdropClose: false,
      backdropOpacity: 0.5,
    },
    SUBMIT_MODAL: {
      isOpen:
        modalStep === MODAL_STEPS.SUBMIT_MODAL || isCloseRetryState.current,
      onClose: reset,
    },
    BACKEND_RESPONSE_MODAL: {
      isOpen: modalStep === MODAL_STEPS.BACKEND_RESPONSE_MODAL,
      onClose: handleCloseBackendResponseModal,
    },
    RETRY_MODAL: {
      isOpen: modalStep === MODAL_STEPS.RETRY_MODAL && isRetryModalOpen,
      onClose: handleCloseRetryModal,
    },
  };

  if (!modalStep || !shouldOpenModals) return <></>;

  return (
    <Modal {...modalProps[MODAL_STEPS[modalStep]]} testId="submission-modal">
      {modalStep === MODAL_STEPS.SUBMIT_MODAL && (
        <SubmitModal
          onSubmit={handleOnSubmit}
          onCloseModal={onCloseModal}
          callNextModal={callNextModal}
        />
      )}

      {modalStep === MODAL_STEPS.PROCESSING_PERSISTENCE_MODAL && (
        <PersistenceLoaderModal
          callNextModal={callNextModal}
          callSpecificModal={callSpecificModal}
        />
      )}

      {modalStep === MODAL_STEPS.BACKEND_RESPONSE_MODAL &&
        (importWasSuccessful ? (
          <SuccessAppResponseModal
            onCloseModal={handleCloseBackendResponseModal}
          />
        ) : (
          <ErrorAppResponseModal
            onCloseModal={handleCloseBackendResponseModal}
          />
        ))}

      {modalStep === MODAL_STEPS.RETRY_MODAL && (
        <RetryModal
          onSubmit={handleSubmitRetryModal}
          handleClose={handleCloseRetryModal}
        />
      )}
    </Modal>
  );
};

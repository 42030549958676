import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSpreadsheetContext } from "../../../Importer";
import { useDataSetContext } from "../../../Importer/common/Spreadsheet/DataSetContextProvider";
import { useFilteredDataContext } from "../../../Importer/common/Spreadsheet/FilteredDataContextProvider";
import { Button } from "../../../common/buttons";
import { Div, colors } from "../../../styled";
import { truncateString } from "../../../utils";
import { CheckIcon } from "./icons";

const formatToLocaleString = (number: number) =>
  number.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const RecordsCountContainer = styled(Div)`
  position: relative;

  * {
    box-sizing: border-box;
  }
`;

const Counter = styled(Div)`
  align-items: center;
  background: ${colors.blue100};
  border-radius: 35px;
  display: flex;
  padding: 0px 18px;
  position: relative;
  user-select: none;
  z-index: 101;
`;

const TooltipContainer = styled(Div)`
  background: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 3.53px 63.58px 0px #b7a6a640;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 16px 32px 24px 24px;
  position: absolute;
  right: 0px;
  top: 66px;
  width: clamp(150px, 379px, 379px);
  z-index: 101;

  &::before {
    border-color: transparent transparent #ffffff transparent;
    border-radius: 4px;
    border-style: solid;
    border-width: 0 14.5px 24px 14.5px;
    content: "";
    height: 0;
    position: absolute;
    right: -4px;
    top: -6px;
    transform: rotate(120deg);
    width: 0;
  }
`;

const ExampleCode = styled(Div)`
  align-items: center;
  background: ${colors.blue100};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 4px;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  height: 36px;
  padding: 0px;
  width: 61px;
`;

export const TooltipBackdrop = styled(Div)`
  background: rgba(0, 0, 0, 0.37);
  height: 100vh;
  left: 0px;
  margin: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 99;
`;

interface TooltipProps {
  recordsCount: number;
  onClose: () => void;
}

const Tooltip = ({ recordsCount, onClose }: TooltipProps) => {
  const { getRecord } = useDataSetContext();
  const { t } = useTranslation("review");
  const { filteredDataIds } = useFilteredDataContext();
  const [exampleTransform, setExampleTransform] = useState(null);

  useEffect(() => {
    const foundId = filteredDataIds.find(
      (id) => Object.keys(getRecord(id)?._meta?.transformedRow).length > 0
    );
    if (foundId)
      setExampleTransform(
        Object.values(getRecord(foundId)._meta.transformedRow)[0]
      );
  }, [filteredDataIds]);

  return (
    <>
      <TooltipBackdrop
        data-cy="transformations-tooltip-backdrop"
        onClick={onClose}
      />
      <TooltipContainer data-cy="transformations-tooltip">
        <Div>
          <CheckIcon />
        </Div>
        <Div subtitle>
          {t("table_actions.transformed_records.auto_corrections_amount", {
            amount: formatToLocaleString(recordsCount),
          })}
        </Div>
        <Div>
          <Div mb={5} label1 style={{ color: colors.gray700 }}>
            {t("table_actions.transformed_records.example")}
          </Div>
          {exampleTransform && (
            <ExampleCode>
              <Div body1>
                {truncateString(exampleTransform?.transformed, 15)}
              </Div>
              <Div body1 style={{ textDecoration: "line-through" }}>
                {truncateString(exampleTransform?.original, 15)}
              </Div>
            </ExampleCode>
          )}
        </Div>
        <StyledButton
          data-cy={"auto-corrected-ok-btn"}
          buttonTextSmall
          uppercase
          onClick={onClose}
        >
          OK
        </StyledButton>
      </TooltipContainer>
    </>
  );
};

type TransformedRecordsCountProps = {
  onClose: () => void;
};
export const TransformedRecordsCount = ({
  onClose,
}: TransformedRecordsCountProps) => {
  const { filteredDataIds } = useSpreadsheetContext();
  const { getRecord } = useDataSetContext();
  const { t } = useTranslation("review");

  const transformedRecordsCount = useMemo(() => {
    let counter = 0;
    for (let i = 0; i < filteredDataIds.length; i++) {
      const currentData = getRecord(filteredDataIds[i]);
      const transformedObj = currentData?._meta?.transformedRow;
      counter += Object.keys(transformedObj).length;
    }

    return counter;
  }, [filteredDataIds]);

  return (
    <RecordsCountContainer>
      <Counter body1>
        <Div bold mr={4} data-cy="transformations-counter">
          {formatToLocaleString(transformedRecordsCount)}{" "}
        </Div>
        {t("table_actions.transformed_records.auto_corrections_done")}
      </Counter>
      <Tooltip recordsCount={transformedRecordsCount} onClose={onClose} />
    </RecordsCountContainer>
  );
};

import { Div, Divider, colors } from "fuse-shared-ui";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import styled from "styled-components";
import {
  ACCOUNT_ROUTE,
  API_CREDENTIALS_ROUTE,
  BILLING_ROUTE,
  ORGANIZATION_SETTINGS_ROUTE,
} from "../../../..";
import { useUserContext } from "../../../../../../../UserContextProvider";
import { AvatarPlaceholder } from "../../../../../../../common/AvatarPlaceholder";
import { useNavigationContext } from "../../../NavigationContextProvider";
import {
  CloseIcon,
  buildingIcon,
  cashMoneyIcon,
  logOutIcon,
  settingsIcon,
} from "../../icons";
import { DropdownItem } from "./DropdownItem";
import { OrganizationsListing } from "./OrganizationsListing";

type DropdownMobileUIProps = {
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileBackdrop = styled(Div)`
  background: rgba(25, 22, 22, 0.82);
  bottom: 0;
  height: 100vh;
  position: fixed;
  width: 100vw;
`;

const Container = styled(Div)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px 2px 8px 0px #0000001e;
  box-sizing: border-box;
  padding: 16px;
  width: 100%;
`;

type Dropdowns = "main" | "organizations";

type MainDropdownProps = {
  handleCloseDropdown: () => void;
  setCurrentDropdown: React.Dispatch<React.SetStateAction<Dropdowns>>;
  handleItemClick: (route: string) => void;
};

const MainDropdown = ({
  handleCloseDropdown,
  setCurrentDropdown,
  handleItemClick,
}: MainDropdownProps) => {
  const { user, signOut } = useUserContext();

  return (
    <>
      <Div w100 dflex justifyEnd onClick={handleCloseDropdown} mb={8}>
        <CloseIcon />
      </Div>

      <DropdownItem
        onClick={() => {
          setCurrentDropdown("organizations");
        }}
        label="Switch Organization"
        icon={buildingIcon}
      />
      <DropdownItem
        label="Organization Settings"
        icon={settingsIcon}
        onClick={() => handleItemClick(ORGANIZATION_SETTINGS_ROUTE)}
        isSelected={
          !!useRouteMatch({
            path: ORGANIZATION_SETTINGS_ROUTE,
            exact: true,
          })
        }
      />
      <DropdownItem
        data-cy="billingPlansText"
        label="Billing & Plans"
        icon={cashMoneyIcon}
        onClick={() => handleItemClick(BILLING_ROUTE)}
        isSelected={
          !!useRouteMatch({
            path: BILLING_ROUTE,
            exact: true,
          })
        }
      />
      <Divider bgColor="#E5DBDB" m="16px 0" />
      <DropdownItem
        label="My Account"
        icon={<AvatarPlaceholder user={user} />}
        onClick={() => handleItemClick(ACCOUNT_ROUTE)}
        isSelected={
          !!useRouteMatch({
            path: ACCOUNT_ROUTE,
            exact: true,
          })
        }
      />
      <Divider bgColor="#E5DBDB" m="16px 0" />
      <DropdownItem
        label="Log out"
        icon={logOutIcon}
        onClick={() => signOut()}
      />
    </>
  );
};

type OrganizationsDropdownProps = {
  handleCloseDropdown: () => void;
};

const OrganizationsDropdown = ({
  handleCloseDropdown,
}: OrganizationsDropdownProps) => {
  return (
    <Div dflex flexColumn>
      <Div>
        <Div dflex alignCenter spaceBetween>
          <Div label2 c={colors.text} pl={18} mb={10}>
            Organizations
          </Div>
          <Div w100 dflex justifyEnd onClick={handleCloseDropdown} mb={8}>
            <CloseIcon />
          </Div>
        </Div>
        <Divider bgColor="#E5DBDB" m="5px 0" />
        <OrganizationsListing />
      </Div>
    </Div>
  );
};

export const DropdownMobileUI = ({
  setIsDropdownOpen,
}: DropdownMobileUIProps) => {
  const history = useHistory();
  const { setIsOpen } = useNavigationContext();
  const [currentDropdown, setCurrentDropdown] = useState<Dropdowns>("main");

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleItemClick = (route: string) => {
    setIsOpen(false);
    handleCloseDropdown();
    history.push(route);
  };

  useEffect(() => {
    return () => {
      setCurrentDropdown("main");
    };
  }, []);

  const handleOpenMainDropdown = () => {
    setCurrentDropdown("main");
  };

  return (
    <>
      <MobileBackdrop onClick={handleCloseDropdown} />

      <Container pAbsolute bottom={0}>
        {currentDropdown === "main" && (
          <MainDropdown
            handleCloseDropdown={handleCloseDropdown}
            handleItemClick={handleItemClick}
            setCurrentDropdown={setCurrentDropdown}
          />
        )}
        {currentDropdown === "organizations" && (
          <OrganizationsDropdown handleCloseDropdown={handleOpenMainDropdown} />
        )}
      </Container>
    </>
  );
};

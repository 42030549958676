import { Div, DivCSSProps } from "../../../../styled/utils";
import { TemplateHeader } from "fuse-importer";
import { useTranslation } from "react-i18next";
import * as S from "./ImporterSectionHeader.styles";

type HeaderCardProps = {
  templateHeaders: TemplateHeader[];
} & DivCSSProps;

type HeaderListProps = {
  title: string;
  headers: string[];
} & DivCSSProps;

const HeadersList = ({ title, headers, ...props }: HeaderListProps) => {
  return (
    <>
      <Div label3 {...props}>
        {title}
      </Div>
      {headers.map((header, index) => {
        const isLast = index === headers.length - 1;
        return (
          <S.HeaderListItem
            body1
            data-cy={header.replace("*", "-asterisk")}
            key={index}
            isLast={isLast}
            mt={16}
          >
            {header}
          </S.HeaderListItem>
        );
      })}
    </>
  );
};

export const HeaderCard = ({ templateHeaders, ...props }: HeaderCardProps) => {
  const requiredHeaders = templateHeaders
    .filter((h) => h.required)
    .map((h) => `${h.label}*`);
  const optionalHeaders = templateHeaders
    .filter((h) => !h.required)
    .map((h) => h.label);

  const { t } = useTranslation("upload");

  return (
    <S.HeaderCardWrapper>
      <S.HeaderCardContainer {...props}>
        <Div data-cy="header_title" body3 mb={24}>
          {t("header.title")}
        </Div>
        {requiredHeaders.length > 0 && (
          <HeadersList
            data-cy="required_header"
            title={t("header.required_headers")}
            headers={requiredHeaders}
          />
        )}
        {optionalHeaders.length > 0 && (
          <HeadersList
            data-cy="optional_header"
            title={t("header.optional_headers")}
            headers={optionalHeaders}
            mt={30}
          />
        )}
      </S.HeaderCardContainer>
    </S.HeaderCardWrapper>
  );
};

export const mediaQueries = {
  // TODO: this may be updated to have the newest breakpoints from Figma
  // https://figmashort.link/zxb4XC
  desktop: "(min-width: 1051px)",
  mobile: "(max-width: 1050px)",

  // Newest breakpoints
  xs: "(max-width: 599px)",
  md: "(max-width: 1023px)",
  lg: "(max-width: 1279px)",
  xl: "(min-width: 1280px)",
};

import { useField, useFormikContext } from "formik";
import { Div, DivCSSProps } from "fuse-shared-ui";
import { useCallback } from "react";
import styled from "styled-components";

export const FormikFieldErrorMessage = styled(Div)`
  color: ${(props) => props.theme.colors.error};
`;

export function useFormikField<T = any>(name) {
  const [field, meta, helpers] = useField<T>(name);
  const { setValue, setTouched } = helpers;
  const { error, touched } = meta;
  const hasError = error && error !== "" && touched;

  const setValueAndTouch = useCallback(
    (value) => {
      if (!touched) setTouched(true);
      setValue(value);
    },
    [touched]
  );

  return {
    value: field.value,
    setValue: setValueAndTouch,
    setTouched,
    hasError,
    errorMsg: error,
    touched,
  };
}

type FieldWrapperProps = {
  name: string;
};

const FieldWrapper = styled(Div)<FieldWrapperProps>`
  :focus {
    outline: none;
  }
`;

export type FormikFieldProps = DivCSSProps & FieldWrapperProps;

export const FormikField = ({
  name,
  children,
  className = "",
  w100 = true,
  helperText,
  ...props
}: FormikFieldProps) => {
  const { hasError, errorMsg } = useFormikField(name);

  return (
    <FieldWrapper
      w100={w100}
      name={name}
      tabIndex={-1}
      className={className}
      {...props}
    >
      {children}
      {hasError && (
        <FormikFieldErrorMessage mt={5} helperText>
          {errorMsg}
        </FormikFieldErrorMessage>
      )}
      {helperText && (
        <Div mt={5} helperText>
          {helperText}
        </Div>
      )}
    </FieldWrapper>
  );
};

export const FormikErrorFocus = () => {
  const { isSubmitting, isValidating, errors } = useFormikContext();
  const isObject = (value) => {
    return value && typeof value === "object" && value.constructor === Object;
  };

  const getKeysRecursively = (object, depth = 0) => {
    let currentKey;
    let arrayIndex;
    if (Array.isArray(object)) {
      const firstValue = object.find((el, index) => {
        if (el) {
          arrayIndex = index;
          return el;
        }
        return -1;
      });
      return `[${arrayIndex}]` + getKeysRecursively(firstValue, depth + 1);
    }
    if (!isObject(object)) return "";
    else currentKey = Object.keys(object)[0];
    if (!getKeysRecursively(object[currentKey], depth + 1)) {
      return `${depth > 0 ? `.${currentKey}` : currentKey}`;
    }
    return currentKey + getKeysRecursively(object[currentKey], depth + 1);
  };

  const keys = Object.keys(errors);
  if (keys.length > 0 && isSubmitting && !isValidating) {
    const selectorKey = getKeysRecursively(errors);
    const selector = `[id="${selectorKey}"], [name="${selectorKey}"] `;
    const errorElement = document.querySelector(selector) as any;
    if (errorElement) {
      setTimeout(() => {
        errorElement?.focus();
      }, 50);
    }
  }
  return <></>;
};

import { css } from "styled-components";

export const getScrollBarCss = (color = "#9ca4a8") => css`
  scrollbar-width: thin;
  scrollbar-color: ${color} transparent;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${color};
    border-radius: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    border-radius: 3px;
    background-color: ${color};
  }
`;

import * as yup from "yup";
import { yupRequired } from "../../../../../common/form/validations";
import { ColumnValidation } from "./types";
import {
  ValidationOption,
  datePatterns,
  dateTimePatterns,
  timePatterns,
  validationsByType,
} from "fuse-shared-ui";

export const columnField = [
  {
    label: "Internal key*",
    name: "internal_key",
    placeholder: "E.g. first_name",
    initialValue: "",
    validation: yupRequired,
    labelTooltip:
      "This is the JSON key used to submit to your website, or the key passed to any javascript callbacks or hooks you setup.",
  },
  {
    label: "Column label*",
    name: "label",
    placeholder: "E.g. First Name",
    initialValue: "",
    validation: yupRequired,
    labelTooltip: "The column label shown in the importer interface",
  },
  {
    label: "Description",
    name: "description",
    placeholder: "E.g. The first name of the user",
    initialValue: "",
    labelTooltip: "The description shown in the importer matching step",
  },
];

export const otherFields = [
  {
    name: "column_type",
    initialValue: "",
    validation: yupRequired,
  },
  {
    name: "required",
    initialValue: false,
  },
  {
    name: "unique",
    initialValue: false,
  },
];

const structuredDataValuesField = {
  name: "values",
  initialValue: [],
  validation: yup.mixed().when("column_type", (type: string) => {
    return type === "enum"
      ? yup
          .array()
          .of(yup.string().required("Dropdown value is required"))
          .min(1)
      : yup.array().of(yup.string()).nullable().notRequired();
  }),
};

const allowedSchemaByTypes = {
  text: () => yup.string().required("Value required"),
  number: () => yup.string().required("Value required"),
  date: () => yup.string().required("Value required"),
};

const getOptionSchema = (option: ValidationOption) => {
  return {
    [option.key]: allowedSchemaByTypes[option.type](),
  };
};

const lazyValidation = yup.lazy((val: ColumnValidation) => {
  const { validation_type } = val;
  const validation = validationsByType[validation_type];
  let schema = {};
  if (validation && validation.options) {
    validation.options?.forEach((option) => {
      schema = { ...schema, ...getOptionSchema(option) };
    });
  }
  return yup.object({
    validation_type: yup.string().nullable(),
    message: validation_type
      ? yup.string().required("message is required")
      : yup.string().nullable(),
    options: yup.object(schema).nullable(),
  });
}) as any;

const validationsField = {
  name: "validations",
  initialValue: [],
  validation: yup.array().of(lazyValidation),
};

const transformationsField = {
  name: "transformations",
  initialValue: [],
  // TODO: verify the need to add validation to this property
};

const patternValidation = yup
  .string()
  .nullable()
  .when("column_type", (type) => {
    if (patternsColumnTypes.includes(type)) {
      return yupRequired;
    }
  });

const patternField = {
  name: "pattern",
  initialValue: null,
  validation: patternValidation,
};

export const columnTypesLabel = {
  string: "String",
  integer: "Integer",
  float: "Float",
  boolean: "Boolean",
  email: "Email",
  url: "Url",
  enum: "Dropdown (select one)",
  date: "Date",
  datetime: "Date Time",
  time: "Time",
};

export const columnTypes = Object.keys(columnTypesLabel).map((key) => ({
  value: key,
  label: columnTypesLabel[key],
}));

export const allowedPatterns = {
  date: datePatterns,
  datetime: dateTimePatterns,
  time: timePatterns,
};

export const patternsColumnTypes = Object.keys(allowedPatterns);

export const fields = [
  ...columnField,
  ...otherFields,
  structuredDataValuesField,
  patternField,
  validationsField,
  transformationsField,
];

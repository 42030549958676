import { Div } from "fuse-shared-ui";

import { Header } from "components/common/Header";
import { SectionContainer } from "components/common/SectionContainer";
import {
  BillingContextProvider,
  useBillingContext,
} from "./BillingContextProvider";
import CancellationSection from "./CancellationSection";
import CurrentPlan from "./CurrentPlan/CurrentPlan";
import { InvoicesTable } from "./Invoices";
import PaymentMethods from "./PaymentMethods";
import Plans from "./Plans/Plans";
import { PlanCancellationBanner } from "./common/PlanCancellationBanner";

type BillingWithContextProps = {
  paymentMethodsId?: string;
};

const Billing = (): JSX.Element => {
  return (
    <BillingContextProvider>
      <BillingWithContext />
    </BillingContextProvider>
  );
};

export const BillingWithContext = ({
  paymentMethodsId,
}: BillingWithContextProps): JSX.Element => {
  const { currentPlan } = useBillingContext();

  return (
    <SectionContainer>
      <Header title="Billing & Plans" testID="billingPlanTitle" />

      <Div mt={40}>
        <CurrentPlan />
      </Div>

      <PlanCancellationBanner />

      <PaymentMethods testId={paymentMethodsId} />

      <InvoicesTable />

      <Div mt={40}>
        <Plans />
      </Div>
      {currentPlan && (
        <Div mt={16}>
          <CancellationSection />
        </Div>
      )}
    </SectionContainer>
  );
};

export default Billing;

import { Div, Span } from "fuse-shared-ui";
import styled, { css } from "styled-components";
import { BillingSection } from "../../common/index";
import {
  Amex,
  DinersClub,
  Discover,
  JCB,
  MasterCard,
  UnionPay,
  Visa,
} from "../../icons";

export const cardBrandsIcons = {
  visa: Visa,
  mastercard: MasterCard,
  discover: Discover,
  diners: DinersClub,
  jcb: JCB,
  unionpay: UnionPay,
  amex: Amex,
};

export const Wrapper = styled(BillingSection)`
  padding: 24px;
  margin-top: 20px;
  min-height: 88px;
  ${(p) => p.theme.css.spaceBetween};
`;

export const Item = styled(Div)`
  ${(p) => p.theme.css.flexColumn};
`;

export const NextBillingDate = styled(Span)`
 ${(p) => p.theme.css.body3}
 border-color: ${(p) => p.theme.colors.gray700};
 color: ${(p) => p.theme.colors.gray700};
`;

export const PaymentButton = styled(Div)`
  text-decoration: none;
  color: ${(p) => p.theme.colors.pink500};
`;

export const Label = styled(Div)(
  ({ theme }) => css`
    ${[theme.css.label2, theme.css.alignCenter]}
  `
);

export const CardHolder = styled(Div)`
  ${(props) => props.theme.textStyles.wh6};
`;

export const Divider = styled(Div)`
  color: ${(props) => props.theme.colors.gray700};
  margin: 0 8px;
  width: 4px;
  height: 100%;
  align-items: center;
  display: flex;
`;

export const CardDigits = styled(Span)`
  ${(props) => props.theme.textStyles.wbody3}
  color: ${(props) => props.theme.colors.gray700};
`;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReviewContext, useSpreadsheetContext } from "../../../../Importer";
import { useFilteredDataContext } from "../../../../Importer/common/Spreadsheet/FilteredDataContextProvider";
import { useValidationContext } from "../../../../Importer/common/Spreadsheet/ValidationContextProvider";
import { useEventManagerContext } from "../../../EventManagerContextProvider";
import * as S from "./FilterByErrors.styles";
import { addToast } from "../../../../common/Toast";

export const FilterByErrors = () => {
  const {
    spreadsheetFilter,
    resetSpreadsheetFilter,
    filterRecordsByFieldErrors,
  } = useFilteredDataContext();
  const { on, off, EVENTS } = useEventManagerContext();
  const { fields } = useSpreadsheetContext();
  const { hasTransformedRecords } = useReviewContext();

  const { columnsWithErrorCounts } = useValidationContext();
  const { t } = useTranslation("review");

  const [errorArray, setErrorArray] = useState([]);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  useEffect(() => {
    const listener = () => {
      const getErrorArray = () => {
        const errorArray = [];
        for (const [key, value] of Object.entries(
          columnsWithErrorCounts.current
        )) {
          if (value > 0) {
            const field = fields.find((f) => f.name === key);

            if (field) {
              errorArray.push({ label: field.label, value: field.name });
            }
          }
        }
        return errorArray;
      };
      setErrorArray(getErrorArray());
    };

    on(EVENTS.UPDATE_COLUMN_ERROR_COUNT, listener);

    return () => {
      off(EVENTS.UPDATE_COLUMN_ERROR_COUNT, listener);
    };
  }, []);

  const prefix = t("table_actions.filter_by_errors.filter_by_errors");
  const search_or_select = t("table_actions.filter_by_errors.search_or_select");
  const selectedErrorToFilter = spreadsheetFilter?.byColumn?.field || null;

  const getSelectedErrorLabel = (selectedFieldName: string) => {
    const selectedField = fields.filter(
      (field) => field?.name === selectedFieldName
    );
    const label = selectedField?.[0]?.label || selectedFieldName;

    return label;
  };

  const onChange = (selected: any) => {
    if (selected) {
      const label = getSelectedErrorLabel(selected);
      filterRecordsByFieldErrors(selected);
      addToast(`Showing rows with an error in the ${label} column`, "success");
    } else {
      resetSpreadsheetFilter();
    }
  };

  // the average size of each character in the input (in px)
  const widthPerCharacter = 5.8;
  const amountOfCharacters =
    prefix.length + getSelectedErrorLabel(selectedErrorToFilter)?.length;
  // 24 -> padding + 64px of the X icon area
  const totalWidth = widthPerCharacter * amountOfCharacters + 24 + 64;
  const inputWidth = selectedErrorToFilter ? `${totalWidth}px` : "fit-content";

  const inputText = selectedErrorToFilter
    ? `${prefix}: `
    : isMenuOpened
    ? search_or_select
    : prefix;

  const isDisabled = errorArray.length === 0 || hasTransformedRecords;

  return (
    <S.StyledAutocomplete
      placement="bottom"
      placeholder={inputText}
      onToggle={(v) => !isDisabled && setIsMenuOpened(v)}
      onChange={onChange}
      value={selectedErrorToFilter}
      options={errorArray}
      isDisabled={isDisabled}
      isInputDisabled={isDisabled}
      inputWidth={inputWidth}
      data-testid="ta-filter-by-errors"
      prefix={inputText}
    />
  );
};

import { BlankState } from "components/common/Table/BlankState";
import {
  Button,
  Div,
  P,
  Table,
  TableDataProvider,
  colors,
} from "fuse-shared-ui";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DEVELOPERS_ROUTE, WEBHOOK_LOGS_ROUTE } from "../../common";
import CreateModal from "./CreateModal";
import { BlankStateIcon } from "./icons";
import { tableColumns } from "./tableColumns";

const ButtonGroup = styled(Div)`
  display: flex;
  gap: 12px;

  @media all and (${({ theme }) => theme.media.xs}) {
    flex-direction: column;
    width: 100%;

    button,
    [role="button"] {
      width: 100%;
    }
  }
`;

const Webhooks = ({ testId }: { testId?: string }) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const history = useHistory();

  const navigateToLogs = () => {
    history.push(WEBHOOK_LOGS_ROUTE);
  };

  useEffect(() => {
    if (history.location.pathname.includes("/logs")) {
      history.push(DEVELOPERS_ROUTE);
    }
  }, []);

  const headerUI = (
    <Div>
      <Div dflex spaceBetween flexWrap gap={8}>
        <P data-cy="webhooks-text" h5 c={colors.textPrimary} m={0}>
          Webhooks
        </P>

        <ButtonGroup dflex gap={12}>
          <Button
            w={153}
            maxh={36}
            data-cy="view-webhook-logs-header-button"
            onClick={navigateToLogs}
            variant="secondary"
          >
            View Logs
          </Button>

          <Button
            w={153}
            maxh={36}
            onClick={() => setCreateModalOpen(true)}
            data-cy="create-webhook-header-button"
          >
            Create Webhook
          </Button>
        </ButtonGroup>
      </Div>

      <Div>
        <P data-cy="description" body1 m={0} mt={8}>
          Create a new Webhook to receive data or notifications about your
          imports from Fuse.
        </P>
      </Div>
    </Div>
  );

  const blankStateUI = (
    <Div data-cy="text-info" dflex mt={120} w100>
      <BlankState
        title="No webhooks created"
        description="You have not created any Webhooks yet"
        icon={<BlankStateIcon />}
        button={
          <Button
            variant="secondary"
            data-cy="createWebhook"
            w={158}
            onClick={() => setCreateModalOpen(true)}
          >
            Create Webhook
          </Button>
        }
      />
    </Div>
  );

  return (
    <Div p={32}>
      <Helmet>
        <title>Webhooks</title>
      </Helmet>
      {headerUI}
      <Div my={40} h100>
        <TableDataProvider dataUrl={`/api/v1/organizations/webhooks`}>
          <Table
            columns={tableColumns}
            data-testid={testId}
            blankState={blankStateUI}
          />
          {createModalOpen && (
            <CreateModal
              isOpen={createModalOpen}
              onClose={() => setCreateModalOpen(false)}
            />
          )}
        </TableDataProvider>
      </Div>
    </Div>
  );
};

export default Webhooks;

import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  api,
  Button,
  colors,
  Div,
  Modal,
  useTableContext,
} from "fuse-shared-ui";
import { FormikTextInput } from "components/common/form/fields";
import Form, { FormikSumbitButton } from "components/common/form/Form";
import { yupEmail } from "components/common/form/validations";
import { useUserContext } from "components/UserContextProvider";
import { greenCheckIcon } from "./common/icons";

const emailField = {
  label: "Email Address",
  name: "email",
  placeholder: "E.g. john.doe@gmail.com",
  initialValue: "",
  validation: yupEmail,
};

const FormButtonsWrapper = styled(Div)`
  margin-top: 28px;
  display: flex;
  justify-content: center;
`;

type InviteUserModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const InviteUserModal = ({ isOpen, onClose }: InviteUserModalProps) => {
  const { user } = useUserContext();
  const { getData } = useTableContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isGreenCheckIconDisplayed, setIsGreenCheckIconDisplayed] = useState(
    false
  );

  useEffect(() => {
    setIsGreenCheckIconDisplayed(false);
  }, [isOpen]);

  const onSubmit = async (values) => {
    setIsLoading(true);

    const { email } = values;
    try {
      const { data } = await api.post(
        "/users/invitation",
        {
          email,
          organization_id: user?.active_organization?.id,
        },
        { successMsg: `Invitation sent to ${email}` }
      );

      if (data) {
        setIsLoading(false);
        setIsGreenCheckIconDisplayed(false);
        getData();
        onClose();
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onErrors = (hasError, value) => {
    if (!hasError && value) setIsGreenCheckIconDisplayed(true);
  };

  const emailFieldUI = (
    <Div w={485}>
      <FormikTextInput
        onErrors={onErrors}
        withDebounce={false}
        icon={isGreenCheckIconDisplayed && greenCheckIcon}
        {...emailField}
      />
    </Div>
  );

  const contentUI = (
    <>
      <Div textAlignCenter mb={30}>
        <Div data-cy="invite-pop-up-title" h5 c={colors.textPrimary}>
          Invite Team Member
        </Div>
        <Div data-cy="invite-pop-up-sub-title" wbody3>
          Send an invitation link to your team members via email
        </Div>
      </Div>
      <Form fields={[emailField]} onSubmit={onSubmit}>
        <>
          {emailFieldUI}
          <FormButtonsWrapper>
            <Button
              data-cy="cancel-button"
              mr={16}
              w={104}
              variant="secondary"
              onClick={() => {
                setIsGreenCheckIconDisplayed(false);
                onClose();
              }}
            >
              Cancel
            </Button>
            <FormikSumbitButton
              w={200}
              isDisabled={isLoading}
              disableOnErrors={true}
            >
              <Div data-cy="send-invitation-button">Send Invitation</Div>
            </FormikSumbitButton>
          </FormButtonsWrapper>
        </>
      </Form>
    </>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Div p={22}>{contentUI}</Div>
    </Modal>
  );
};

export default InviteUserModal;

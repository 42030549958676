import React from "react";
import { useImporterContext } from "../contexts/ImporterContextProvider";
import { HeaderMatcher } from "./HeaderMatcher";
import { EnumFieldsMatchers } from "./EnumFieldsMatchers";
import { areEqual } from "react-window";

const Matcher = () => {
  const { matcherSubstep } = useImporterContext();

  return (
    <>
      {matcherSubstep === 0 && <HeaderMatcher />}
      {matcherSubstep === 1 && <EnumFieldsMatchers />}
    </>
  );
};

export default React.memo(Matcher, areEqual);

import { FieldTypes } from "fuse-importer";
import { BaseInput } from "./BaseInput";
import { BooleanInput } from "./Boolean";
import { EnumInput } from "./EnumInput";

export const inputTypes = {
  [FieldTypes.boolean]: BooleanInput,
  [FieldTypes.email]: BaseInput,
  [FieldTypes.enum]: EnumInput,
  [FieldTypes.float]: BaseInput,
  [FieldTypes.integer]: BaseInput,
  [FieldTypes.string]: BaseInput,
  [FieldTypes.url]: BaseInput,
  [FieldTypes.date]: BaseInput,
  [FieldTypes.datetime]: BaseInput,
  [FieldTypes.time]: BaseInput,
};

import { Div, DivCSSProps } from "fuse-shared-ui";
import styled, { css } from "styled-components";

export const ToggleContainer = styled(Div)`
  width: 34px;
  height: 20px;
  background: #0ebebe;
  border: 2px solid #0ebebe;
  border-radius: 40px;
  user-select: none;
  ${(props: any) =>
    !props.value &&
    css`
      background: #909090;
      border: 2px solid #909090;
    `};
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  ${(props: any) =>
    props.isDisabled &&
    css`
      pointer-events: none;
    `}
  ${(p) => {
    const c = p.theme.css;
    return [c.dflex, c.spaceBetween, c.alignCenter];
  }};
`;

export const ToggleCircle = styled(Div)`
  width: 16px;
  height: 16px;
  border: solid 2px white;
  background: #ffffff;
  border-radius: 100%;
`;

export const ToggleLabel = styled(Div)`
  color: white;
  font-size: 14px;
  font-weight: 600;
`;

type ToggleProps = {
  value: any;
  setValue: any;
  displayText?: boolean;
  isDisabled?: boolean;
} & DivCSSProps;
const Toggle = ({
  value,
  setValue,
  displayText = false,
  isDisabled = false,
  ...props
}: ToggleProps) => {
  return (
    <ToggleContainer
      {...{ value, isDisabled, ...props }}
      onClick={() => {
        if (setValue) setValue(!value);
      }}
    >
      {value && <ToggleLabel ml={7}>{displayText && "Yes"}</ToggleLabel>}
      <ToggleCircle />
      {!value && <ToggleLabel mr={7}>{displayText && "No"}</ToggleLabel>}
    </ToggleContainer>
  );
};

export default Toggle;

import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { Fragment, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid as Grid, areEqual } from "react-window";
import styled, { css } from "styled-components";
import { Div } from "../../../styled/utils";
import { Spinner } from "../../Spinner";
import { TableHeaders } from "./TableHeaders";
import { Row } from "./TableRows";
import { defaultTableHeaderHeightPx, defaultTableRowHeightPx } from "./common";
import { TableViewProps } from "./types";
export * from "./TableHeaders";
export * from "./TableRows/TableRow";
export * from "./common";

type TableViewWrapperProps = {
  isLoading: boolean;
  checkboxColumnWidth?: number;
};

export const TableViewWrapper = styled(Div)<TableViewWrapperProps>`
  ${(p: any) =>
    p.isLoading &&
    css`
      opacity: 0.5;
    `}
  ${(p) =>
    !p.isLoading &&
    css`
      box-shadow: 0 0 0 1px ${(p) => p.theme.colors.gray300};
    `}
  overflow: hidden;

  ${({ checkboxColumnWidth }) =>
    checkboxColumnWidth &&
    css`
      max-width: calc(100vw - ${checkboxColumnWidth}px);
    `}

  // prevents clipped dropdowns
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
  .ReactVirtualized__Table__row {
    overflow: visible !important;
  }
`;

const SpinnerWrapper = styled(Div)`
  pointer-events: none;
  z-index: 100;
`;

const tableHeaderId = "tableHeaderId";

const VirtualizedGrid = styled(Grid)`
  ${(p) => p.theme.css.scrollbarDark};
`;

const loadingUI = (
  <SpinnerWrapper pRelative>
    <Div w100 h100 centered h={400} pAbsolute>
      <Spinner />
    </Div>
  </SpinnerWrapper>
);

const buildGridStyles = (rowWidthScalingFactor) => {
  /* using style={{ willChange: "unset" }} is important for having items
            like backdrops with position fixed inside of the table working as
            expected. Required for dropdowns in cells */
  let gridStyles: React.CSSProperties = {
    outline: "none",
    willChange: "unset",
    boxSizing: "border-box",
  };
  if (rowWidthScalingFactor === 1)
    gridStyles = {
      ...gridStyles,
      overflowX: "hidden",
      overflowY: "auto",
    };
  else if (rowWidthScalingFactor > 1)
    gridStyles = {
      ...gridStyles,
      overflowX: "auto",
    };
  return gridStyles;
};

export const TableView = React.memo(
  ({
    data = null,
    dataRefIds = null,
    columns,
    pageSize,
    onRowClick = null,
    sortBy = { field: null, direction: null },
    isLoading = false,
    setSortBy = null,
    rowWidthScalingFactor = 1,
    tableHeadersHeight = defaultTableHeaderHeightPx,
    rowHeight = defaultTableRowHeightPx,
    h = 500,
    onTableScroll = null,
    getItemKey = null,
    isDraggable = false,
    checkboxColumnWidth,
    isFiltering = false,
    shouldHideTable = false,
    alignColumnsWithHeader = false,
    ...props
  }: TableViewProps) => {
    const headerContainerRef = useRef<HTMLDivElement>();
    const rowCount = dataRefIds ? dataRefIds.ids.length : data.length;

    const onScroll = ({ scrollTop, scrollLeft }) => {
      if (headerContainerRef.current && headerContainerRef.current.scrollTo) {
        requestAnimationFrame(() => {
          headerContainerRef?.current?.scrollTo({
            left: scrollLeft,
          });
        });
      }
      if (onTableScroll) {
        onTableScroll(scrollTop);
      }
    };

    const tableHeight = pageSize
      ? pageSize * rowHeight + tableHeadersHeight
      : h;

    const canRenderTableHeaders = isFiltering ? true : rowCount > 0;
    return (
      <TableViewWrapper
        data-test-id="table-view-wrapper"
        isLoading={isLoading}
        checkboxColumnWidth={checkboxColumnWidth}
        h={tableHeight}
        {...props}
      >
        {isLoading && loadingUI}
        <AutoSizer>
          {({ width: autosizerWidth, height: autosizerHeight }) => {
            // using Math.floor to avoid floating point errors with the grid and table cell widths
            const rowWidth = Math.floor(autosizerWidth * rowWidthScalingFactor);

            const gridProps = {
              itemData: {
                items: data,
                dataRefIds,
                isDraggable,
                rowWidth,
                onRowClick,
                columns,
                rowHeight,
                getItemKey,
                alignColumnsWithHeader,
              },
              style: buildGridStyles(rowWidthScalingFactor),
              width: autosizerWidth,
              columnCount: 1,
              onScroll,
              columnWidth: shouldHideTable ? 0 : rowWidth,
              height: autosizerHeight - tableHeadersHeight,
              rowHeight,
              rowCount,
            };

            const GridContainer: React.ComponentType<any> = isDraggable
              ? SortableContext
              : Fragment;
            const gridContainerProps = isDraggable
              ? { items: data, strategy: verticalListSortingStrategy }
              : {};

            return (
              <>
                {canRenderTableHeaders && (
                  <TableHeaders
                    rowWidth={rowWidth}
                    containerWidth={autosizerWidth}
                    id={tableHeaderId}
                    containerRef={headerContainerRef}
                    height={tableHeadersHeight}
                    rowHeight={rowHeight}
                    scrollLeft={0}
                    columns={columns}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                  />
                )}
                <GridContainer {...gridContainerProps}>
                  <VirtualizedGrid {...gridProps}>{Row}</VirtualizedGrid>
                </GridContainer>
              </>
            );
          }}
        </AutoSizer>
      </TableViewWrapper>
    );
  },
  areEqual
);

import { PlanIds } from "__data__";
import { Div, P, ToggleGroup, colors } from "fuse-shared-ui";
import styled from "styled-components";
import { useBillingContext } from "../BillingContextProvider";
import Plan from "./Plan";

const Wrapper = styled(Div)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
`;

type PlansType = {
  testIds?: PlanIds;
};

const filterPlansByRecurrence = (plans: any, recurrencePeriod) => {
  return plans?.filter(
    (plan: any) => plan.recurrence_period === recurrencePeriod
  );
};

const PlansInfo = ({ testIds }: PlansType) => {
  const { plans, selectedRecurrencePeriod } = useBillingContext();

  const filteredPlans = filterPlansByRecurrence(
    plans,
    selectedRecurrencePeriod
  );

  return (
    <>
      {filteredPlans?.map((plan) => {
        return <Plan plan={plan} key={plan.id} testId={testIds?.planCardId} />;
      })}
    </>
  );
};

const PaymentIntervalSelector = () => {
  const {
    selectedRecurrencePeriod,
    setSelectedRecurrencePeriod,
  } = useBillingContext();

  const isAnnualSelected = selectedRecurrencePeriod === "year";

  const onSelectRecurrencePeriod = (selected: "Annual" | "Monthly") => {
    const newValue = selected === "Annual" ? "year" : "month";
    setSelectedRecurrencePeriod(newValue);
  };

  return (
    <Div flexColumn>
      <Div dflex justifyEnd mb={10}>
        <ToggleGroup
          items={["Annual", "Monthly"]}
          value={isAnnualSelected ? "Annual" : "Monthly"}
          setValue={onSelectRecurrencePeriod}
        />
      </Div>

      <P data-cy="discount-description" body3 m={0} c={colors.gray700}>
        Save 25% with annual billing
      </P>
    </Div>
  );
};

const TitleSection = () => (
  <Div flexColumn spaceBetween>
    <P data-cy="plan-heading-text" h3 m={0}>
      Plans
    </P>
    <P data-cy="plan-text-info" body3 m={0} c={colors.gray700}>
      Choose a plan to suit your business needs
    </P>
  </Div>
);

const PlansHeader = () => {
  return (
    <Div dflex spaceBetween mb={32}>
      <TitleSection />
      <PaymentIntervalSelector />
    </Div>
  );
};

const Plans = ({ testIds }: PlansType): JSX.Element => {
  return (
    <>
      <PlansHeader />

      <Wrapper>
        <PlansInfo testIds={testIds} />
      </Wrapper>
    </>
  );
};

export default Plans;

import { useTranslation } from "react-i18next";
import { Autocomplete } from "../../../../common/inputs/Autocomplete";
import { Div } from "../../../../styled/utils";
import { useImporterContext } from "../../../contexts/ImporterContextProvider";
import { MatchedIndicatorUI, UnmatchedIndicatorUI } from "../../common";
import { MatcherStatus } from "../../common/types";
import { useEnumFieldMatcherContext } from "../EnumFieldMatcherContext";

export const tableColumns = [
  {
    header: "match:columns.uploaded_values",
    width: 1,
    Content: ({ uploadedDataValue }) => {
      return <Div body1> {uploadedDataValue} </Div>;
    },
  },
  {
    header: "match:columns.supported_values",
    width: 1,
    Content: ({ uploadedDataValue, uploadedDataValueMatching }) => {
      const { t } = useTranslation("match");

      const {
        templateHeaders,
        enumFieldValueMatchings,
        setEnumFieldValueMatchings,
      } = useImporterContext();
      const { templateHeaderLabel } = useEnumFieldMatcherContext();

      const valueOptions = templateHeaders.find(
        (th) => th.label === templateHeaderLabel
      )?.values;

      const onChange = (selected) => {
        const newEnumFieldValueMatchings = { ...enumFieldValueMatchings };
        newEnumFieldValueMatchings[templateHeaderLabel][
          uploadedDataValue
        ] = selected;

        setEnumFieldValueMatchings(newEnumFieldValueMatchings);
      };

      return (
        <Div w100>
          <Autocomplete
            w="90%"
            placeholder={t("columns.placeholder")}
            onChange={onChange}
            placement="bottom"
            value={uploadedDataValueMatching}
            options={valueOptions.map((vo) => ({
              value: vo,
              label: vo,
            }))}
          />
        </Div>
      );
    },
  },
  {
    header: "match:columns.status",
    width: 1,
    Content: ({ status }) => {
      return (
        <>
          {status === MatcherStatus.matched ? (
            <MatchedIndicatorUI />
          ) : (
            <UnmatchedIndicatorUI />
          )}
        </>
      );
    },
  },
];

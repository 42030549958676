import { Div } from "fuse-shared-ui";
import styled from "styled-components";

const AvatarPlaceholderWrapper = styled(Div)`
  ${(props) => props.theme.css.centered};
  background: ${(props) => props.theme.colors.blue500};
  color: ${(props) => props.theme.colors.white};
  font-size: 10.15px;
  border-radius: 100%;
  font-weight: 700;
  width: 28px;
  height: 28px;
  letter-spacing: 0.362px;
  line-height: 11.6px;
`;

export const AvatarPlaceholder = ({ user }) => {
  const initial = (s) => s.charAt(0).toUpperCase();

  return (
    <AvatarPlaceholderWrapper>
      {initial(user?.first_name)}
      {initial(user?.last_name)}
    </AvatarPlaceholderWrapper>
  );
};

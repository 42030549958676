import { useMemo } from "react";
import { columnTypesLabel } from "../../ColumnFormModal/fields";
import { UseTableColumnsProps } from "./interfaces";
import { CellContent } from "./components/CellContent";
import { LabelCellContent } from "./components/LabelCellContent";
import { PillListingContent } from "./components/PillListingContent";
import { TemplateTableActions } from "./components/TemplateTableActions";

export const useTableColumns = ({
  onEditRow,
  template,
}: UseTableColumnsProps) => {
  // width values calculated based on the design
  // 0.22 (254px / 1154px) + 0.13 (148px / 1154px)
  // + 0.38 (439px / 1154px) + 0.27 (313px / 1154px)
  // + 0.001 = 1 (1154px total width)
  // ref https://figmashort.link/PtNteX

  return useMemo(
    () => [
      {
        header: "Label",
        width: 0.22,
        Content: ({ internal_key, label, required }) => {
          return (
            <LabelCellContent
              internalKey={internal_key}
              label={label}
              isRequired={required}
            />
          );
        },
      },
      {
        header: "Type",
        width: 0.13,
        Content: ({ column_type }) => {
          return (
            <CellContent
              value={columnTypesLabel[column_type] || column_type}
              dataCy="column_type"
            />
          );
        },
      },
      {
        header: "Description",
        width: 0.3,
        Content: ({ description }) => {
          return (
            <CellContent
              value={columnTypesLabel[description] || description}
              dataCy="description"
            />
          );
        },
      },
      {
        header: "Validations",
        width: 0.38,
        preventRowClick: false,
        Content: ({ validations }) => {
          return (
            <PillListingContent
              values={validations}
              listingType="validations"
            />
          );
        },
      },
      {
        header: "Transformations",
        width: 0.27,
        preventRowClick: false,
        Content: ({ transformations }) => {
          return (
            <PillListingContent
              values={transformations}
              listingType="transformations"
            />
          );
        },
      },
      {
        header: "",
        width: 0.001,
        preventRowClick: false,
        Content: (row: any) => {
          return (
            <TemplateTableActions
              row={row}
              template={template}
              onEditRow={onEditRow}
            />
          );
        },
      },
    ],
    [onEditRow, template]
  );
};

export const checkSuccessIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99996 15.3334C12.05 15.3334 15.3333 12.0502 15.3333 8.00008C15.3333 3.94999 12.05 0.666748 7.99996 0.666748C3.94987 0.666748 0.666626 3.94999 0.666626 8.00008C0.666626 12.0502 3.94987 15.3334 7.99996 15.3334ZM6.70707 7.25945C6.31654 6.86892 5.68338 6.86892 5.29285 7.25945C4.90233 7.64997 4.90233 8.28313 5.29285 8.67366L6.70707 10.0879L7.41417 10.795L8.12128 10.0879L10.9497 7.25945C11.3402 6.86892 11.3402 6.23576 10.9497 5.84523C10.5592 5.45471 9.92602 5.45471 9.53549 5.84523L7.41417 7.96655L6.70707 7.25945Z"
      fill="#0EBEBE"
    />
  </svg>
);

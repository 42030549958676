import { useHistory } from "react-router";
import styled from "styled-components";
import { Button, Div } from "fuse-shared-ui";
import { SIGN_IN_ROUTE } from "..";
import {
  flatironsFuseLogo,
  checkYourEmailBackgroundPart1,
  checkYourEmailBackgroundPart2,
  checkYourEmailBackgroundPart3,
} from "../common/assets/images";
import { checkBigIcon } from "../common/icons";

const BackgroundPart1 = styled(Div)`
  margin-top: -46px;
  margin-bottom: -33.58px;
  margin-left: -126px;
  max-width: 698px;
  min-width: 698px;
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  @media all and (max-width: 840px) {
    display: none;
  }
`;

const BackgroundPart2 = styled(Div)`
  margin-top: 371.19px;
  max-width: 534px;
  min-width: 534px;
  width: 100%;
  max-height: 373px;
  min-height: 373px;
  pointer-events: none;
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  @media all and (max-width: 1240px) {
    display: none;
  }
`;

const BackgroundPart3 = styled(Div)`
  margin-top: 557px;
  margin-left: 262.88px;
  max-width: 907px;
  width: 100%;
  opacity: 80%;
  display: flex;
  pointer-events: none;
  position: absolute;
  z-index: 30;
  left: 0;
  top: 0;
  @media all and (max-width: 1240px) {
    display: none;
  }
`;

const Wrapper = styled(Div)`
  width: 100vw;
  overflow-x: hidden;
  ${(props) => {
    const c = props.theme.css;
    return [c.grow, c.justifyCenter, c.yscroll, c.pRelative];
  }};
`;

const Container = styled(Div)`
  margin: 80px 160px;
  min-height: 740px;
  max-height: 740px;
  height: 100%;
  max-width: 1120px;
  width: 100%;
  position: relative;
  @media all and (max-width: 980px) {
    margin: 80px;
  }
  @media all and (max-width: 840px) {
    margin: 24px;
  }
`;

const ContentWrapper = styled(Div)`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 40px ${(props) => props.theme.colors.red2000};
  border-radius: 16px;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentHeader = styled(Div)`
  padding: 64px 80px 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media all and (max-width: 1240px) {
    padding: 64px 0 80px;
  }
  @media all and (max-width: 768px) {
    padding: 40px 54px 58px 53px;
  }
`;

const Content = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  @media all and (max-width: 840px) {
    padding: 24px;
  }
`;

const TextWrapper = styled(Div)`
  margin: 24px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextTitle = styled(Div)`
  ${(props) => props.theme.textStyles.wh1}
  @media all and (max-width: 1220px) {
    text-align: center;
  }
  @media all and (max-width: 768px) {
    ${(props) => props.theme.textStyles.wh2}
  }
`;

const TextSubtitle = styled(Div)`
  margin-top: 12px;
  max-width: 400px;
  color: ${(props) => props.theme.colors.gray700};
  ${(props) => props.theme.textStyles.wbody1}
  display: flex;
  text-align: center;
`;

export const PasswordChanged = () => {
  const history = useHistory();

  const backgroundImagesListUI = (
    <>
      <BackgroundPart1 key={1}>{checkYourEmailBackgroundPart1}</BackgroundPart1>
      <BackgroundPart2 key={2}>{checkYourEmailBackgroundPart2}</BackgroundPart2>
      <BackgroundPart3 key={3}>{checkYourEmailBackgroundPart3}</BackgroundPart3>
    </>
  );

  return (
    <Wrapper>
      <Container>
        {backgroundImagesListUI}
        <ContentWrapper>
          <ContentHeader>{flatironsFuseLogo}</ContentHeader>
          <Content data-cy="check-big-icon">
            {checkBigIcon}
            <TextWrapper>
              <TextTitle data-cy="password-changed-title">
                Password changed!
              </TextTitle>
              <TextSubtitle data-cy="password-changed-sub-title">
                We successfully updated your password.
              </TextSubtitle>
            </TextWrapper>
            <Button
              data-cy="sign-in-button"
              onClick={() => history.push(SIGN_IN_ROUTE)}
            >
              Sign In
            </Button>
          </Content>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

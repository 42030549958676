import { useFormikContext } from "formik";
import { addToast, Button, colors, Div } from "fuse-shared-ui";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { logoIcon } from "./icons";
import { FormValues } from "./ImporterStylePreferences";

const FileDetailsWrapper = styled(Div)`
  background: ${colors.white};
  border: 1px solid #cac4bf;
  border-radius: 8px;
  width: 400px;
  height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FileName = styled(Div)`
  overflow-wrap: anywhere;
`;

type ImageFileInputProps = {
  disableButton?: boolean;
};

const ImageFileInput = ({ disableButton }: ImageFileInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (values.logo_url) {
      setImageFile(values.logo_url);
    }
  }, []);

  useEffect(() => {
    if (file && !values.logo && !values.logo_url) {
      deleteFile();
    }
  }, [file, values]);

  const setImageFile = (url) => {
    return new Promise(() => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const imageFile = new File([blob], "image.jpeg", {
            type: blob.type,
          });
          setFile(imageFile);
          setFieldValue("logo", imageFile);
        });
    });
  };

  const uploadImage = (event) => {
    // 1MB
    const sizeLimit = 1_000_000;
    const file = event.target.files[0];

    if (file.size >= sizeLimit) {
      addToast("File size cannot be more than 1MB", "error");
      return;
    }

    event.target.value = null;

    setFile(file);
    setFieldValue("logo", file);
  };

  const deleteFile = () => {
    setFile(null);
    setFieldValue("logo", null);
  };

  const fileDetails = (
    <FileDetailsWrapper>
      <Div dflex>
        <Div mr={8} mt={2} alignCenter>
          {logoIcon}
        </Div>
        <FileName data-cy="uploaded-file-name" wbody1>
          {file && file.name}
        </FileName>
      </Div>
      <Div data-cy="remove" link c={colors.pink500} onClick={deleteFile}>
        Remove
      </Div>
    </FileDetailsWrapper>
  );

  return (
    <>
      {values && file && fileDetails}
      <Button
        data-cy="upload-image"
        w={136}
        variant={"secondary"}
        mt={18}
        onClick={() => {
          fileInputRef.current?.click();
        }}
        disabledWhen={disableButton}
        isDisabled={disableButton}
      >
        Upload Image
        <input
          ref={fileInputRef}
          type="file"
          id="logo"
          name="logo"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          onChange={(event) => uploadImage(event)}
        ></input>
      </Button>
    </>
  );
};

export default ImageFileInput;

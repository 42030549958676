import { DownloadButton } from "components/common/DownloadButton";
import { StatusBadge, StatusBadgeType } from "components/common/StatusBadge";
import { FloatingTableActions } from "components/common/Table/TableActions";
import { Button, Div, TooltipProvider, addToast, colors } from "fuse-shared-ui";
import { DateTime } from "luxon";
import { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import TableDeleteIcon from "../../TemplateRoutes/TemplateColumns/components/TableColumns/components/TableDeleteIcon";
import { copyIcon } from "../../TemplateRoutes/Templates/common/icons";
import { WEBHOOK_LOGS_ROUTE } from "../../common";
import DeleteImportModal from "./DeleteImportModal";

const translatedStatus = (status): StatusBadgeType => {
  switch (status) {
    case "submitted":
    case "submitting": {
      return "PROCESSING";
    }
    case "completed": {
      return "COMPLETED";
    }
    case "failed": {
      return "FAILED";
    }
    case "completed_with_issues": {
      return "COMPLETED WITH ISSUES";
    }
    default: {
      return "DRAFT";
    }
  }
};

const StyledButton = styled(Button)`
  width: max-content;
`;

const ActionWrapper = styled(Div)`
  border-radius: 4px;
  margin-right: 8px;
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.blue100};
  }
`;

export const tableColumns = [
  {
    header: "File Name",
    width: 0.6,
    Content: ({ original_file_name }) => {
      const fileName = original_file_name || "N/A";
      return (
        <Div dflex truncateText title={fileName}>
          {fileName}
        </Div>
      );
    },
  },
  {
    header: "Slug",
    width: 0.5,
    preventRowClick: true,
    Content: ({ slug }) => {
      const onClick = () => {
        addToast(`Copied ${slug} to clipboard`, "notification", {
          position: "bottom-left",
        });
        navigator.clipboard.writeText(slug);
      };
      return (
        <TooltipProvider
          alignCenter
          tooltip="Copy to clipboard"
          placement="auto"
        >
          <ActionWrapper w100 onClick={onClick}>
            <Div spaceBetween>
              <Div c={colors.text} wbody3 w="10px" grow>
                <Div truncateText data-cy="import-slug">
                  {slug}
                </Div>
              </Div>
              <Div centered ml={8}>
                {copyIcon}
              </Div>
            </Div>
          </ActionWrapper>
        </TooltipProvider>
      );
    },
  },
  {
    header: "Importer",
    width: 0.5,
    Content: ({ template }) => (
      <Div dflex truncateText title={template}>
        {template}
      </Div>
    ),
  },
  {
    header: "Created",
    field: "created_at",
    width: 0.5,
    Content: ({ created_at }) => {
      const formatted = DateTime.fromISO(created_at).toFormat(
        "MM/dd/yy hh:mma"
      );
      return (
        <Div dflex truncateText title={formatted}>
          {formatted}
        </Div>
      );
    },
  },
  {
    width: 0.6,
    header: "Import Status",
    Content: ({ status }) => <StatusBadge status={translatedStatus(status)} />,
  },
  {
    header: "Rows",
    width: 0.5,
    Content: ({ row_count }) => (
      <Div dflex truncateText title={row_count}>
        {row_count}
      </Div>
    ),
  },
  {
    header: "",
    width: 0.01,
    Content: ({ resulting_file_url, slug, webhook_logs_count }) => {
      const [deleteModalOpen, setDeleteModalOpen] = useState(false);
      const history = useHistory();
      const handleViewLog = () => {
        history.push(WEBHOOK_LOGS_ROUTE, {
          from: "imports",
          payload: slug,
        });
      };

      return (
        <FloatingTableActions>
          <Div pr={16} textAlignRight alignCenter w100>
            {webhook_logs_count > 0 && (
              <StyledButton
                variant="tertiary"
                isSmall
                data-cy="view-logs"
                onClick={handleViewLog}
              >
                View Logs
              </StyledButton>
            )}

            <DeleteImportModal
              isOpen={deleteModalOpen}
              slug={slug}
              onClose={() => setDeleteModalOpen(false)}
            />
            {resulting_file_url && (
              <DownloadButton
                data-cy="download"
                href={resulting_file_url}
                download={`${slug}.csv`}
              >
                Download
              </DownloadButton>
            )}
            <TableDeleteIcon onClick={() => setDeleteModalOpen(true)} />
          </Div>
        </FloatingTableActions>
      );
    },
  },
];

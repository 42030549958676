import { colors, Div } from "../../styled";
import { StepIndex } from "./StepIndex";

export const Step = ({ index, label, isSelected, isCompleted }) => {
  const isActive = isCompleted || isSelected;

  return (
    <Div centered buttonText c={isActive ? colors.text : colors.gray400}>
      <StepIndex
        index={index + 1}
        isSelected={isSelected}
        isCompleted={isCompleted}
      />
      {label}
    </Div>
  );
};

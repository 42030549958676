import { Redirect, Route, Switch } from "react-router";
import SignIn from "components/Routes/SessionRoutes/SignIn";
import SignUp from "components/Routes/SessionRoutes/SignUp";
import EmailConfirmed from "components/Routes/SessionRoutes/EmailConfirmed";
import { useUserContext } from "components/UserContextProvider";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";
import AcceptInvitationForm from "./AcceptInvitation";
import { SessionLayout } from "./common/Layout";
import ExternalAuthAcceptInvitation from "./ExternalAuthAcceptInvitation";

export const SESSION_ROUTES = {
  SIGN_UP: "/sign-up",
  SIGN_IN: "/sign-in",
  EXTERNAL_AUTH_SIGN_UP: "/sign-up/:auth_provider",
  EXTERNAL_AUTH_SIGN_IN: "/sign-in/:auth_provider",
  EMAIL_CONFIRMED: "/email_confirmed/:confirmationToken",
  ACCOUNT_CONFIRMATION: "/email_confirmations/:confirmationToken",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_PASSWORD: "/reset_password_form",
  ACCEPT_INVITATION: "/invitation/accept",
  EXTERNAL_AUTH_ACCEPT_INVITATION: "/invitation/accept/:auth_provider",
};

export const SESSION_VIEW_PATH = "/users";
const p = (path) => `${SESSION_VIEW_PATH}${path}`;

export const SIGN_IN_ROUTE = p(`${SESSION_ROUTES.SIGN_IN}`);
export const SIGN_UP_ROUTE = p(`${SESSION_ROUTES.SIGN_UP}`);
export const EXTERNAL_AUTH_SIGN_UP_ROUTE = p(
  `${SESSION_ROUTES.EXTERNAL_AUTH_SIGN_UP}`
);
export const EXTERNAL_AUTH_SIGN_IN_ROUTE = p(
  `${SESSION_ROUTES.EXTERNAL_AUTH_SIGN_IN}`
);
export const EMAIL_CONFIRMED_ROUTE = p(`${SESSION_ROUTES.EMAIL_CONFIRMED}`);
export const FORGOT_PASSWORD_ROUTE = p(`${SESSION_ROUTES.FORGOT_PASSWORD}`);
export const CHANGE_PASSWORD_ROUTE = p(`${SESSION_ROUTES.CHANGE_PASSWORD}`);
export const ACCEPT_INVITATION_ROUTE = p(`${SESSION_ROUTES.ACCEPT_INVITATION}`);
export const EXTERNAL_AUTH_ACCEPT_INVITATION_ROUTE = p(
  `${SESSION_ROUTES.EXTERNAL_AUTH_ACCEPT_INVITATION}`
);

const SessionRoutes = () => {
  const { isAuthenticated } = useUserContext();

  return (
    <SessionLayout>
      <Switch>
        <Route exact path={SIGN_IN_ROUTE}>
          {isAuthenticated && <Redirect to="/" />}
          <SignIn />
        </Route>
        <Route exact path={SIGN_UP_ROUTE}>
          {isAuthenticated && <Redirect to="/" />}
          <SignUp />
        </Route>
        <Route exact path={EXTERNAL_AUTH_SIGN_IN_ROUTE}>
          {isAuthenticated && <Redirect to="/" />}
          <SignIn />
        </Route>
        <Route exact path={EXTERNAL_AUTH_SIGN_UP_ROUTE}>
          {isAuthenticated && <Redirect to="/" />}
          <SignUp />
        </Route>
        <Route exact path={EMAIL_CONFIRMED_ROUTE}>
          <EmailConfirmed />
        </Route>
        <Route path={FORGOT_PASSWORD_ROUTE}>
          <ForgotPassword />
        </Route>
        <Route path={CHANGE_PASSWORD_ROUTE}>
          <ChangePassword />
        </Route>
        <Route path={EXTERNAL_AUTH_ACCEPT_INVITATION_ROUTE}>
          <ExternalAuthAcceptInvitation />
        </Route>
        <Route path={ACCEPT_INVITATION_ROUTE}>
          <AcceptInvitationForm />
        </Route>
        <Route component={() => <Redirect to={"/"} />} />
      </Switch>
    </SessionLayout>
  );
};

export default SessionRoutes;

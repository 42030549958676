import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemeProvider, css } from "styled-components";
import { Toast } from "../common";
import { AppLoadingContextProvider } from "../common/AppLoadingContextProvider";
import { EventManagerContextProvider } from "../common/EventManagerContextProvider";
import { Div } from "../styled";
import Matcher from "./Matcher";
import Review from "./Review";
import {
  ReviewContextProvider,
  useReviewContext,
} from "./Review/ReviewContextProvider";
import { Stepper } from "./Stepper/index";
import Uploader from "./Uploader";
import { WarningsBanner } from "./WarningsBanner";
import { AppLoading } from "./common/AppLoading";
import { GlobalStyle } from "./common/GlobalStyle";
import { ImporterFonts } from "./common/ImporterFonts";
import { DataSetContextProvider } from "./common/Spreadsheet/DataSetContextProvider";
import { DuplicateDataContextProvider } from "./common/Spreadsheet/DuplicateDataContextProvider";
import { FilteredDataContextProvider } from "./common/Spreadsheet/FilteredDataContextProvider";
import { KeyboardEventContextProvider } from "./common/Spreadsheet/KeyboardEventContextProvider";
import { PersistenceContextProvider } from "./common/Spreadsheet/PersistenceContextProvider";
import { SearchContextProvider } from "./common/Spreadsheet/SearchContextProvider";
import { UndoRedoContextProvider } from "./common/Spreadsheet/UndoRedoContextProvider";
import { ValidationContextProvider } from "./common/Spreadsheet/ValidationContextProvider";
import { useFieldSorting } from "./common/Spreadsheet/useFieldSorting";
import { useImporterContext } from "./contexts/ImporterContextProvider";

interface ContentRendererProps {
  previewMode: boolean;
  stepLabels: string[];
  logoURL?: string;
  currentStepIndex: number;
  templateHeaders?: any;
}

type LayoutProps = {
  zIndex: number;
  isModal?: boolean;
};

export const Layout = styled(Div)<LayoutProps>`
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: ${(p) => p.zIndex};
  ${(p) => p.theme.css.scrollbarDark};
  ${(p) =>
    p.isModal
      ? css`
          top: 27px;
          bottom: 27px;
          left: 29px;
          right: 29px;
          border-radius: 4px;
          overflow: hidden;
        `
      : css`
          height: 100vh;
          width: 100vw;
          top: 0;
          left: 0;
          overflow-y: auto;
        `}
`;

export const Backdrop = styled.div`
  background: rgba(0, 0, 0, 0.75); // Opaque black
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;

const Content = styled(Div)<{ inTrialMode?: boolean }>`
  ${({ theme: { colors }, inTrialMode }) => css`
    background-color: ${colors.backgroundPrimary};
    flex: ${inTrialMode ? "none" : 1};
  `}
`;

const toastStyle: React.CSSProperties = {
  pointerEvents: "auto",
};

const ContentRenderer = ({
  previewMode,
  stepLabels,
  logoURL,
  currentStepIndex,
  templateHeaders,
}: ContentRendererProps) => {
  const {
    previewInitialDataSet,
    reviewInitialDataSet,
    options,
    onValidateRecord,
    reviewFields: fields,
  } = useReviewContext();

  const { uploadedData } = useImporterContext();
  const isReadOnly = currentStepIndex === 0;

  const getInitialDataSet = () => {
    switch (currentStepIndex) {
      case 0: {
        return uploadedData ? previewInitialDataSet : {};
      }
      case 1: {
        return previewInitialDataSet;
      }
      default: {
        return reviewInitialDataSet;
      }
    }
  };
  const initialDataSet = getInitialDataSet();

  const { getDefaultSortCompareFn } = useFieldSorting();

  return (
    <DataSetContextProvider initialDataSet={initialDataSet || {}}>
      <UndoRedoContextProvider>
        <DuplicateDataContextProvider fields={fields} isReadOnly={isReadOnly}>
          <ValidationContextProvider
            fields={fields}
            isReadOnly={isReadOnly}
            options={{
              ...options,
              showCheckboxColumn: true,
            }}
            onValidateRecord={onValidateRecord}
          >
            <FilteredDataContextProvider
              getSortCompareFn={getDefaultSortCompareFn}
            >
              <SearchContextProvider>
                <KeyboardEventContextProvider>
                  <Div>
                    <WarningsBanner />
                    <Stepper stepLabels={stepLabels} logoURL={logoURL} />
                  </Div>
                  <Content>
                    {currentStepIndex === 0 && <Uploader />}
                    {currentStepIndex === 1 && <Matcher />}
                    {currentStepIndex === 2 && templateHeaders && <Review />}
                  </Content>
                </KeyboardEventContextProvider>
              </SearchContextProvider>
            </FilteredDataContextProvider>
          </ValidationContextProvider>
        </DuplicateDataContextProvider>
      </UndoRedoContextProvider>
    </DataSetContextProvider>
  );
};

export const ImporterWithContext = () => {
  const {
    inTrialMode,
    currentStepIndex,
    matcherSubstep,
    templateHeaders,
    theme,
    logoURL,
    initialRecords,
    importerOptions: { zIndex, previewMode, modal: isModal },
    layoutRef,
  } = useImporterContext();

  const { t } = useTranslation();

  useEffect(() => {
    layoutRef?.current?.scrollTo(0, 0);
  }, [currentStepIndex, matcherSubstep, layoutRef.current]);

  const stepLabels = [
    t("general:steps.upload"),
    t("general:steps.match"),
    t("general:steps.review"),
  ];

  const contentRendererProps = {
    previewMode,
    stepLabels,
    logoURL,
    currentStepIndex,
    templateHeaders,
  };

  return (
    <ThemeProvider theme={theme}>
      <AppLoadingContextProvider>
        <EventManagerContextProvider>
          <PersistenceContextProvider>
            <Toast zIndex={zIndex + 1} toastStyle={toastStyle} />
            <GlobalStyle />
            <ImporterFonts />
            {templateHeaders ? (
              <ReviewContextProvider initialRecords={initialRecords}>
                <>
                  {isModal && <Backdrop />}
                  <Layout zIndex={zIndex} ref={layoutRef} isModal={isModal}>
                    <ContentRenderer {...contentRendererProps} />
                  </Layout>
                </>
              </ReviewContextProvider>
            ) : (
              <AppLoading />
            )}
          </PersistenceContextProvider>
        </EventManagerContextProvider>
      </AppLoadingContextProvider>
    </ThemeProvider>
  );
};

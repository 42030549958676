import { Button, Div } from "fuse-shared-ui";
import { capitalize } from "utils";
import { auth0Icon, disneyIcon, githubIcon } from "./icons";

type ExternalLoginButtonProps = {
  provider?: string;
  text?: string;
  type?: string;
  className?: string;
  isButtonElement?: boolean;
  isDisabled?: boolean;
};

export const ExternalAuthLoginButton = ({
  provider,
  type = "sign-in",
  className,
  isButtonElement,
  isDisabled,
  text,
}: ExternalLoginButtonProps) => {
  let icon;
  let authorizeUri;
  let providerName;

  if (provider) {
    authorizeUri = `${process.env.REACT_APP_EXTERNAL_AUTH_BASE_URL}/${provider}`;
    providerName = capitalize(provider);
  }

  switch (provider) {
    case "auth0":
      icon = auth0Icon;
      break;
    case "disney":
      icon = disneyIcon;
      break;
    default:
      providerName = "Github";
      icon = githubIcon;
      authorizeUri = `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&scope=user`;
      break;
  }

  const typeText = type === "sign-in" ? "Sign in" : "Sign up";
  const displayText = text || `${typeText} with ${providerName}`;

  return (
    <Button
      {...{ className, variant: "secondary", isButtonElement }}
      isDisabled={isDisabled}
      onClick={() => window.open(authorizeUri, "_self")}
    >
      <Div mr={10}>{displayText}</Div>
      {icon}
    </Button>
  );
};

import { useTranslation } from "react-i18next";
import { Spinner } from "../../../common";
import { Div } from "../../../styled/utils";
import { useImporterContext } from "../../contexts/ImporterContextProvider";
import { EnumFieldMatcher } from "./EnumFieldMatcher";

export const EnumFieldsMatchers = () => {
  const { t } = useTranslation("match");
  const { enumFieldValueMatchings, valueMappingsLoaded } = useImporterContext();

  return (
    <>
      <Div flexColumn ml={32} dflex gap={16} mb={24} mt={24}>
        <Div h2>{t("title")}</Div>
        {valueMappingsLoaded && (
          <Div body1 maxw={928}>
            {t("enum_description")}
          </Div>
        )}
      </Div>
      {valueMappingsLoaded ? (
        <Div flexColumn alignCenter mb={20} ml={32} mr={32} centered>
          {Object.keys(enumFieldValueMatchings).map(
            (templateHeaderLabel, index) => {
              return (
                <Div key={index} w={`100%`}>
                  <EnumFieldMatcher templateHeaderLabel={templateHeaderLabel} />
                </Div>
              );
            }
          )}
        </Div>
      ) : (
        <Div w100 h100 centered h={400} pAbsolute>
          <Spinner />
        </Div>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { TooltipProvider } from "../../../../common";
import { useEventManagerContext } from "../../../../common/EventManagerContextProvider";
import { colors } from "../../../../styled/theme/colorPalette";
import { Div } from "../../../../styled/utils";
import { Field } from "fuse-importer";
import { useFilteredDataContext } from "../FilteredDataContextProvider";
import { useValidationContext } from "../ValidationContextProvider";
import { SortOrder } from "../common/types";

const ErrorCount = styled(Div)(
  ({ theme: { colors, css: c } }) => css`
    color: ${colors.error};
    background: ${colors.red100};
    border: solid 1px ${colors.error};
    padding: 4px 7px;
    border-radius: 56px;
    min-width: 24px;
    ${c.centered};
  `
);

type SortIndicatorProps = {
  direction: SortOrder;
};
export const SortIndicator = styled(Div)<SortIndicatorProps>`
  ${(p) =>
    p.direction === SortOrder.ASC &&
    css`
      transform: rotate(180deg);
    `}
  svg {
    path {
      fill: ${(p) => p.theme.colors.gray300};
    }
  }
`;

export type FieldHeaderProps = {
  field: Field;
};

export const FieldHeader: React.FC<FieldHeaderProps> = ({ field }) => {
  const {
    columnsWithErrorCounts,
    columnsWithWarningCounts,
  } = useValidationContext();

  const {
    handleSortByFieldErrors,
    handleSortByFieldWarnings,
    sortBy,
    setSortBy,
    spreadsheetFilter,
    filteredInputValue,
  } = useFilteredDataContext();

  const { on, off, EVENTS } = useEventManagerContext();
  const [fieldErrorCount, setFieldErrorCount] = useState(
    columnsWithErrorCounts.current[field.name] || null
  );
  const [fieldWarningCount, setFieldWarningCount] = useState(
    columnsWithWarningCounts.current[field.name] || null
  );

  useEffect(() => {
    const listener = () => {
      setFieldErrorCount(columnsWithErrorCounts.current[field.name] || null);
    };

    on(EVENTS.UPDATE_COLUMN_ERROR_COUNT, listener);

    return () => {
      off(EVENTS.UPDATE_COLUMN_ERROR_COUNT, listener);
    };
  }, []);

  const shouldRenderErrorCount =
    fieldErrorCount !== null &&
    fieldErrorCount > 0 &&
    filteredInputValue === "" &&
    spreadsheetFilter.type === "rowBased" &&
    spreadsheetFilter.byRow === "all";

  const onHeaderClick = () => {
    if (sortBy?.field === field.name && sortBy?.direction === SortOrder.ASC) {
      setSortBy({
        field: field.name,
        direction: SortOrder.DESC,
      });
    } else {
      setSortBy({
        field: field.name,
        direction: SortOrder.ASC,
      });
    }
  };

  return (
    <Div
      data-cy="column-header"
      data-test-id={`column-header-${field.name}`}
      p="0 16px"
      spaceBetween
      w100
      alignCenter
      onClick={onHeaderClick}
    >
      <TooltipProvider tooltip={field?.description}>
        <Div dflex>
          <Div truncateText selectNone>
            {field.label}
            {field.isRequired && "*"}
          </Div>
          <Div ml={8} centered>
            {sortBy?.field === field.name && (
              <SortIndicator direction={sortBy?.direction}>
                {chevronDown}
              </SortIndicator>
            )}
          </Div>
        </Div>
      </TooltipProvider>
      {shouldRenderErrorCount && (
        <TooltipProvider
          mr={3}
          w={35}
          data-cy="tool-tip-message"
          tooltip={`Sort by ${field.label} errors`}
        >
          <ErrorCount
            data-cy="column-error"
            data-test-id={`column-errors-count-${field.name}`}
            onClick={(ev) => {
              ev.stopPropagation();
              handleSortByFieldErrors(field.name);
            }}
          >
            <Div centered>
              <Div w={35} textAlignCenter label3 truncateText>
                {fieldErrorCount}
              </Div>
            </Div>
          </ErrorCount>
        </TooltipProvider>
      )}

      {!fieldErrorCount && fieldWarningCount !== null && fieldWarningCount > 0 && (
        <TooltipProvider
          mr={3}
          w={35}
          tooltip={`Sort by ${field.label} errors`}
        >
          <ErrorCount
            style={{
              color: colors.orange1200,
              background: colors.orange1100,
              boxShadow: `0px 0px 0px 1px ${colors.orange500} inset`,
            }}
            data-test-id={`column-errors-count-${field.name}`}
            onClick={(ev) => {
              ev.stopPropagation();
              handleSortByFieldWarnings(field.name);
            }}
          >
            <Div centered>
              <Div w={35} textAlignCenter label3 truncateText>
                {fieldWarningCount}
              </Div>
            </Div>
          </ErrorCount>
        </TooltipProvider>
      )}
    </Div>
  );
};

export const chevronDown = (
  <svg
    width="9"
    height="5"
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.31848 4.93945L0.421366 0.439453L8.2156 0.439454L4.31848 4.93945Z"
      fill="#102622"
    />
  </svg>
);

import React, { useMemo } from "react";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid as Grid } from "react-window";
import styled, { css } from "styled-components";
import { Checkbox } from "../../../../common/inputs/Checkbox";
import { Div, DivCSSProps } from "../../../../styled/utils";
import { useReviewContext } from "../../../Review/ReviewContextProvider";
import { useImporterContext } from "../../../contexts/ImporterContextProvider";
import { useDataSetContext } from "../DataSetContextProvider";
import { useSpreadsheetContext } from "../SpreadsheetContextProvider";
import { rowIsEmpty } from "../SpreadsheetContextProvider/utils";
import { spreadsheetRowHeightPx } from "../common";
import { spreadsheetHeaderHeightPx } from "../common/index";

interface CheckboxesContainerProps {
  columnWidth: number;
}

const StyledGrid = styled(Grid)`
  background: ${({ theme }) => theme.colors.white50};
  overflow-y: scroll;
`;

const gridStyles: React.CSSProperties = {
  outline: "none",
  willChange: "unset",
  overflowY: "hidden",
  overflowX: "scroll",
};

type Props = {
  height?: number;
  gridRef: React.MutableRefObject<any>;
  isLoadingData?: boolean;
} & DivCSSProps;

export const SpreadsheetRowCheckboxes = ({
  height,
  gridRef,
  isLoadingData,
  ...props
}: Props) => {
  const {
    selectedRows,
    options,
    filteredDataIds,
    lastClickedIndexRef,
    setSelectedRows,
    checkboxColumnWidth,
  } = useSpreadsheetContext();
  const { hasTransformedRecords } = useReviewContext();
  const { getRecord } = useDataSetContext();
  const { currentStepIndex } = useImporterContext();
  const nonEmptyRows = useMemo(
    () => filteredDataIds.filter((rowId) => !rowIsEmpty(getRecord(rowId))),
    [filteredDataIds]
  );

  const isIndeterminate =
    selectedRows.length > 0 && selectedRows.length < nonEmptyRows.length;

  const handleRowSelect = (
    checked: boolean,
    rowIndex: number,
    rowMetaId: string
  ) => {
    if (hasTransformedRecords) return;
    const recordId = filteredDataIds[rowIndex];
    const record = getRecord(recordId);

    const lastRowRecordId = filteredDataIds[filteredDataIds.length - 1];
    const isLastRow = rowMetaId === lastRowRecordId;
    const isLastRowAndEmpty =
      isLastRow && rowIsEmpty(getRecord(lastRowRecordId));
    if (isLastRowAndEmpty) return;

    if (options.withSingleRowSelect) {
      if (!rowIsEmpty(record)) setSelectedRows([record?._meta?.id]);
      return;
    }

    if (checked) {
      setSelectedRows((prev) => [...prev, record?._meta?.id]);
    } else {
      setSelectedRows((prev) =>
        prev.filter((row) => row !== record?._meta?.id)
      );
    }

    lastClickedIndexRef.current = rowIndex;
  };

  const handleSelectMultipleRows = (rowIndex: number) => {
    if (hasTransformedRecords) return;
    const rowId = filteredDataIds[rowIndex];
    const record = getRecord(rowId);

    const clickedRowId = record?._meta?.id;

    if (selectedRows.includes(clickedRowId)) {
      // The clicked row is already selected, so we have to remove it
      const clickedRowIndex = selectedRows.indexOf(clickedRowId);
      const lastClickedIndex = lastClickedIndexRef.current;

      const rangeStartIndex = Math.min(clickedRowIndex, lastClickedIndex);
      const rangeEndIndex = Math.max(clickedRowIndex, lastClickedIndex);

      const lastRowId = filteredDataIds[filteredDataIds.length - 1];

      const updatedSelectedRows = selectedRows.filter(
        (value, index) =>
          index < rangeStartIndex ||
          index > rangeEndIndex ||
          (value === lastRowId && rowIsEmpty(getRecord(lastRowId)))
      );

      setSelectedRows(updatedSelectedRows);
    } else {
      // The clicked row is not selected, so we have to add it
      const lastSelectedRowIndex = selectedRows.length - 1;
      const lastSelectedRowId = selectedRows[lastSelectedRowIndex];

      const lastSelectedRow = filteredDataIds.find(
        (id) => id === lastSelectedRowId
      );
      const clickedRow = filteredDataIds[rowIndex];

      const rangeStartIndex = filteredDataIds.indexOf(lastSelectedRow);
      const rangeEndIndex = filteredDataIds.indexOf(clickedRow);
      const range = filteredDataIds.slice(
        Math.min(rangeStartIndex, rangeEndIndex),
        Math.max(rangeStartIndex, rangeEndIndex) + 1
      );

      const rangeIds = range.map((row) => row);
      const lastRowId = filteredDataIds[filteredDataIds.length - 1];
      const isLastRowEmpty = rowIsEmpty(getRecord(lastRowId));

      const updatedSelectedRows = [...selectedRows, ...rangeIds].filter(
        (value, index, array) =>
          array.indexOf(value) === index &&
          (value !== lastRowId || (value === lastRowId && !isLastRowEmpty))
      );

      setSelectedRows(updatedSelectedRows);
      lastClickedIndexRef.current = rowIndex;
    }
  };

  const handleSelectAllRows = (checked: boolean) => {
    if (hasTransformedRecords) return;

    if (checked) {
      const allRows = filteredDataIds.filter((recordId) => {
        const isLastRow =
          recordId === filteredDataIds[filteredDataIds.length - 1];
        const isLastRowAndEmpty = isLastRow && rowIsEmpty(getRecord(recordId));
        return !isLastRowAndEmpty;
      });
      setSelectedRows(allRows);
    } else {
      setSelectedRows([]);
    }

    lastClickedIndexRef.current = null;
  };

  const CheckboxCell = ({ rowIndex }) => {
    const rowMetaId = filteredDataIds[rowIndex];

    return (
      <Div className="checkbox-cell" data-test-id={`checkbox-cell-${rowIndex}`}>
        <Checkbox
          isChecked={rowMetaId && selectedRows.includes(rowMetaId)}
          onChange={(checked) =>
            !isLoadingData && handleRowSelect(checked, rowIndex, rowMetaId)
          }
          onShiftKeyPressed={() => {
            if (!options.withSingleRowSelect)
              handleSelectMultipleRows(rowIndex);
          }}
          style={{ cursor: "pointer" }}
          isDisabled={isLoadingData}
        />
      </Div>
    );
  };

  const RowNumbersCell = ({ rowIndex }) => {
    return (
      <Div className="row-numbers-cell" textAlignCenter body4>
        {rowIndex + 1}
      </Div>
    );
  };

  const Cell = ({ rowIndex, style }) => {
    return (
      <RowCheckbox
        style={style}
        key={rowIndex}
        alignCenter
        justifyCenter
        isLast={rowIndex === filteredDataIds.length - 1}
      >
        <CheckboxCell rowIndex={rowIndex} />

        <RowNumbersCell rowIndex={rowIndex} />
      </RowCheckbox>
    );
  };

  const isSelectAllRowsChekboxChecked =
    selectedRows?.length > 0 &&
    filteredDataIds?.length > 0 &&
    selectedRows?.length >= nonEmptyRows?.length;

  return (
    <SpreadsheetRowCheckboxesContainer
      className="spreadsheet-row-container"
      columnWidth={checkboxColumnWidth}
      {...props}
      h100
    >
      <SpreadsheetRowCheckboxesHeader>
        {currentStepIndex === 2 && (
          <Checkbox
            isChecked={isSelectAllRowsChekboxChecked}
            onChange={(checked) =>
              !isLoadingData && handleSelectAllRows(checked)
            }
            isIndeterminate={isIndeterminate}
            isDisabled={isLoadingData}
          />
        )}
      </SpreadsheetRowCheckboxesHeader>
      <ReactVirtualizedAutoSizer>
        {({ height }) => (
          <StyledGrid
            ref={gridRef}
            style={gridStyles}
            width={checkboxColumnWidth}
            columnCount={1}
            columnWidth={checkboxColumnWidth}
            rowHeight={spreadsheetRowHeightPx}
            rowCount={filteredDataIds.length}
            height={height - spreadsheetHeaderHeightPx}
          >
            {Cell}
          </StyledGrid>
        )}
      </ReactVirtualizedAutoSizer>
    </SpreadsheetRowCheckboxesContainer>
  );
};

type RowCheckboxProps = {
  isLast: boolean;
};

const RowCheckbox = styled(Div)<RowCheckboxProps>`
  background-color: ${(p) => p.theme.colors.tableHeader};
  ${(p) => p.theme.css.label3};
  ${(p) =>
    !p.isLast &&
    css`
      box-shadow: inset 0 -1px 0 ${(p) => p.theme.colors.gray300};
    `}
  display: flex;
  justify-content: center;
  position: relative;
  user-select: none;

  .checkbox-cell {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    z-index: 1;
  }

  .row-numbers-cell {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const SpreadsheetRowCheckboxesHeader = styled(Div)`
  align-items: center;
  height: ${spreadsheetRowHeightPx}px;
  background-color: ${(p) => p.theme.colors.tableHeader};
  box-shadow: inset 0 -1px 0 ${(p) => p.theme.colors.gray300};
  display: flex;
  justify-content: center;
`;

const SpreadsheetRowCheckboxesContainer = styled(Div)<CheckboxesContainerProps>`
  border-right: solid 1px ${(p) => p.theme.colors.gray300};
  border-top-left-radius: 8px;
  width: ${({ columnWidth }) => columnWidth || 40}px;

  .ReactVirtualized__Grid {
    /* Scrollbar */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background-color: ${(p) => p.theme.colors.tableHeader} !important;
    }
  }
`;

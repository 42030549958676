type AutocompleteProps = {
  hasError?: boolean;
  hasWarning?: boolean;
};

import styled, { css } from "styled-components";
import {
  HTMLInput,
  SelectMenuDiv,
  SelectMenuScrollArea,
  TextInputIcon,
} from "../../../../../../common";
import { Autocomplete as AutocompleteBase } from "../../../../../../common/inputs/Autocomplete";
import { spreadsheetRowHeightPx } from "../../../common";

export const Autocomplete = styled(AutocompleteBase)<AutocompleteProps>`
  ${HTMLInput} {
    border: none;
    border-radius: 0;
    margin: 0;
    background: transparent;
    height: ${spreadsheetRowHeightPx - 5}px;
    padding: 0 12px;
    ${(props) => props.theme.css.body3};

    ${(p) =>
      p.hasWarning &&
      css`
        color: ${(p) => p.theme.colors.orange1200};
      `}

    ::placeholder {
      ${(p) =>
        p.hasWarning &&
        css`
          color: ${(p) => p.theme.colors.orange1200};
        `}

      ${(p) =>
        p.hasError &&
        css`
          color: ${(p) => p.theme.colors.error};
        `}

        ::placeholder {
        ${(p) =>
          p.hasError &&
          css`
            color: ${(p) => p.theme.colors.error};
          `}
      }
    }

    :focus {
      border: none;
      box-shadow: none;
    }

    :hover {
      background: transparent;
    }
  }
  ${TextInputIcon} {
    right: 6px;
  }
  ${SelectMenuDiv} {
    border-radius: 7px;
  }
  ${SelectMenuScrollArea} {
    padding: 4px;
  }
`;

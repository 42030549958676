import * as S from "../styles";
import {
  PillListingContentProps,
  Transformation,
  Validation,
} from "../interfaces";
import {
  mountTransformationsListing,
  mountValidationsListing,
} from "../functions";

export const PillListingContent = ({
  values,
  listingType,
}: PillListingContentProps) => {
  const valuesToRender =
    listingType === "validations"
      ? mountValidationsListing(values as Validation[])
      : mountTransformationsListing(values as Transformation[]);

  return (
    <S.PillListingContainer dflex flexColumn data-cy={`${listingType}-listing`}>
      {valuesToRender.map((value, i) => (
        <S.GenericPill key={`${value}-${i}`} maxw={260}>
          {value}
        </S.GenericPill>
      ))}
    </S.PillListingContainer>
  );
};

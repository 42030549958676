export const checkIcon = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="11.0001" r="7.33333" stroke="#CAC4BF" strokeWidth="2" />
    <path
      d="M7 10.9666L8.41421 12.3808L11.2426 9.55234"
      stroke="#CAC4BF"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const checkSuccessIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99996 15.3334C12.05 15.3334 15.3333 12.0502 15.3333 8.00008C15.3333 3.94999 12.05 0.666748 7.99996 0.666748C3.94987 0.666748 0.666626 3.94999 0.666626 8.00008C0.666626 12.0502 3.94987 15.3334 7.99996 15.3334ZM6.70707 7.25945C6.31654 6.86892 5.68338 6.86892 5.29285 7.25945C4.90233 7.64997 4.90233 8.28313 5.29285 8.67366L6.70707 10.0879L7.41417 10.795L8.12128 10.0879L10.9497 7.25945C11.3402 6.86892 11.3402 6.23576 10.9497 5.84523C10.5592 5.45471 9.92602 5.45471 9.53549 5.84523L7.41417 7.96655L6.70707 7.25945Z"
      fill="#0EBEBE"
    />
  </svg>
);

export const hideIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.67969 3L21.6797 19"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M22.2679 13.2302C22.4458 12.5885 22.3647 11.8927 22.0246 11.3003C20.3114 8.31648 17.8496 6.09905 15 5.11633M19.6278 16.9999C17.4379 19.2279 14.6784 20.5564 11.6798 20.5564C7.44377 20.5564 3.68497 17.9054 1.33497 13.8124C0.888345 13.0345 0.888342 12.0783 1.33497 11.3004C2.93979 8.50518 5.20165 6.38253 7.82381 5.31743"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5892 11.8251C14.748 12.457 14.6968 13.1233 14.4434 13.7235C14.1899 14.3236 13.748 14.825 13.1844 15.1517C12.6208 15.4785 11.9662 15.6129 11.3194 15.5347C10.6726 15.4564 10.0689 15.1698 9.59949 14.7181C9.13007 14.2663 8.82045 13.6741 8.71743 13.0308C8.61441 12.3875 8.7236 11.7282 9.02848 11.1524C9.33337 10.5767 9.81738 10.1159 10.4074 9.83956C10.9973 9.56327 11.6612 9.48653 12.2987 9.62095"
      stroke="#333333"
      strokeWidth="2"
    />
  </svg>
);

export const viewIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33497 13.2561C0.888345 12.4782 0.888342 11.522 1.33497 10.7441C3.68496 6.65097 7.44378 4 11.6798 4C15.9158 4 19.6746 6.65094 22.0246 10.744C22.4712 11.5219 22.4712 12.4781 22.0246 13.256C19.6746 17.3491 15.9158 20 11.6798 20C7.44377 20 3.68497 17.3491 1.33497 13.2561Z"
      stroke="#333333"
      strokeWidth="2"
    />
    <circle cx="11.6797" cy="12" r="3" stroke="#333333" strokeWidth="2" />
  </svg>
);

export const errorIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 5C11.5523 5 12 5.44772 12 6V11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11V6C10 5.44772 10.4477 5 11 5ZM11 14C11.5523 14 12 14.4477 12 15V15.5C12 16.0523 11.5523 16.5 11 16.5C10.4477 16.5 10 16.0523 10 15.5V15C10 14.4477 10.4477 14 11 14Z"
      fill="#CF4520"
    />
  </svg>
);

export const githubIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.47833 0 0 4.4775 0 10C0 14.4183 2.865 18.1667 6.83917 19.4892C7.33833 19.5817 7.5 19.2717 7.5 19.0083V17.1467C4.71833 17.7517 4.13917 15.9667 4.13917 15.9667C3.68417 14.8108 3.02833 14.5033 3.02833 14.5033C2.12083 13.8825 3.0975 13.8958 3.0975 13.8958C4.10167 13.9658 4.63 14.9267 4.63 14.9267C5.52167 16.455 6.96917 16.0133 7.54 15.7575C7.62917 15.1117 7.88833 14.67 8.175 14.4208C5.95417 14.1667 3.61917 13.3092 3.61917 9.47833C3.61917 8.38583 4.01 7.49417 4.64917 6.79417C4.54583 6.54167 4.20333 5.52417 4.74667 4.1475C4.74667 4.1475 5.58667 3.87917 7.4975 5.1725C8.295 4.95083 9.15 4.84 10 4.83583C10.85 4.84 11.7058 4.95083 12.505 5.1725C14.4142 3.87917 15.2525 4.1475 15.2525 4.1475C15.7967 5.525 15.4542 6.5425 15.3508 6.79417C15.9925 7.49417 16.38 8.38667 16.38 9.47833C16.38 13.3192 14.0408 14.165 11.8142 14.4125C12.1725 14.7225 12.5 15.3308 12.5 16.2642V19.0083C12.5 19.2742 12.66 19.5867 13.1675 19.4883C17.1383 18.1642 20 14.4167 20 10C20 4.4775 15.5225 0 10 0Z"
      fill="#214BA7"
    />
  </svg>
);

export const auth0Icon = (
  <svg
    fill="#000000"
    width="20"
    height="20"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M29.307 9.932l-3.146-9.932h-20.365l-3.104 9.932c-1.802 5.75 0.042 12.271 5.089 16.021l8.229 6.047 8.208-6.068c5.005-3.75 6.911-10.25 5.089-16.021l-8.214 6.104 3.12 9.938-8.208-6.13-8.208 6.104 3.141-9.911-8.25-6.063 10.177-0.063 3.146-9.891 3.141 9.87z" />
  </svg>
);

export const disneyIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 64 64.005"
  >
    <path d="M49.922 54.154c-7.96 2.43-15.473 1.304-19.566.225-.112 1.664-.292 2.36-.562 2.63-.315.36-2.924 1.844-4.363-.27-.63-1.012-.945-2.834-1.124-4.498-9.22-4.183-13.493-10.345-13.628-10.57-.225-.225-2.316-2.406-.225-5.105 1.957-2.43 8.433-4.88 14.236-5.847.225-4.948.765-8.77 1.462-10.457.832-2.024 1.9-.225 2.834 1.124.765 1.012 1.237 5.352 1.282 8.816 3.778-.18 6.072.1 10.278.9 5.532.945 9.22 3.778 8.928 6.972-.27 3.148-3.148 4.453-4.273 4.543s-2.924-.742-2.924-.742c-1.26-.585-.112-1.124 1.35-1.754 1.62-.787 1.26-1.574 1.26-1.574-.585-1.8-7.76-3-14.888-3 0 3.936.157 10.457.27 14.258 4.993.945 8.726.742 8.726.742s18.216-.517 18.733-12.144C58.3 26.763 39.512 15.61 25.68 12.1c-13.808-3.62-21.635-1.057-22.3-.72-.742.36-.067.495-.067.495s.742.112 2.1.562.27 1.124.27 1.124c-2.36.8-4.993.337-5.5-.742-.517-1.057.337-2.024 1.35-3.44.945-1.462 2.024-1.417 2.024-1.417 17.08-5.963 37.95 4.72 37.95 4.72 19.52 9.85 22.85 21.432 22.5 25.93-.315 4.43-2.024 11.92-14.056 15.563M12.365 38.074c-1.934.9-.585 2.34-.585 2.34 3.643 3.9 8.096 6.342 12.37 7.87.495-6.747.45-9.153.45-12.55-6.612.45-10.435 1.53-12.234 2.34" />
  </svg>
);

export const mailIcon = (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12.6667C0 11.5622 0.895431 10.6667 2 10.6667H62C63.1046 10.6667 64 11.5622 64 12.6667V54.0001C64 55.1046 63.1046 56.0001 62 56.0001H2C0.895431 56.0001 0 55.1046 0 54.0001V12.6667Z"
      fill="url(#paint0_linear_1507_39309)"
    />
    <path
      d="M1.42131 11.9696C0.91709 11.5074 1.24409 10.6667 1.9281 10.6667H62.0719C62.7559 10.6667 63.0829 11.5074 62.5787 11.9696L34.0272 38.1418C32.8802 39.1932 31.1198 39.1932 29.9728 38.1418L1.42131 11.9696Z"
      fill="url(#paint1_linear_1507_39309)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1507_39309"
        x1="32"
        y1="10.6667"
        x2="32"
        y2="56.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#006BA8" />
        <stop offset="1" stopColor="#00D4B3" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1507_39309"
        x1="32"
        y1="10.6667"
        x2="32"
        y2="40.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B6FFFB" />
        <stop offset="1" stopColor="#00D1FF" />
      </linearGradient>
    </defs>
  </svg>
);

export const checkBigIcon = (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" fill="white" />
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M49.3059 6.19506C50.0417 5.37122 51.306 5.29983 52.1299 6.0356L62.5085 15.3048C63.3323 16.0405 63.4037 17.3049 62.6679 18.1287L28.1482 56.7802C27.4124 57.604 26.1481 57.6754 25.3242 56.9397L14.9456 47.6705C14.1218 46.9347 14.0504 45.6704 14.7862 44.8466L49.3059 6.19506Z"
        fill="url(#paint0_linear_1507_39154)"
      />
    </g>
    <g style={{ mixBlendMode: "darken" }}>
      <rect
        x="38.7271"
        y="44.8708"
        width="17.9152"
        height="35.8777"
        rx="2"
        transform="rotate(131.582 38.7271 44.8708)"
        fill="url(#paint1_linear_1507_39154)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1507_39154"
        x1="56.9917"
        y1="9.50414"
        x2="2.83703"
        y2="20.1429"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#006BA8" />
        <stop offset="1" stopColor="#00D5B3" stopOpacity="0.895833" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1507_39154"
        x1="47.2264"
        y1="44.8708"
        x2="27.8081"
        y2="96.8634"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6BEEFF" />
        <stop offset="1" stopColor="#0DDFD3" stopOpacity="0.760417" />
      </linearGradient>
    </defs>
  </svg>
);

export const laptopIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
  >
    <g style={{ mixBlendMode: "darken" }}>
      <rect
        x="9.75"
        y="15"
        width="60.5"
        height="38.5"
        rx="2"
        fill="url(#paint0_linear_4209_75672)"
      />
    </g>
    <rect x="12.5" y="17.7495" width="55" height="33" rx="1.24" fill="white" />
    <path
      d="M9.19721 54.6061C9.536 53.9285 10.2285 53.5005 10.9861 53.5005H69.0139C69.7715 53.5005 70.464 53.9285 70.8028 54.6061L77.0528 67.1061C77.7177 68.4359 76.7507 70.0005 75.2639 70.0005H4.73607C3.24931 70.0005 2.28231 68.4359 2.94721 67.1061L9.19721 54.6061Z"
      fill="url(#paint1_linear_4209_75672)"
    />
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M12.5 33.0737C12.5 32.5095 12.8808 32.0165 13.4266 31.8739L65.9466 18.1551C66.7323 17.9498 67.5 18.5427 67.5 19.3548V49.5095C67.5 50.1943 66.9448 50.7495 66.26 50.7495H13.74C13.0552 50.7495 12.5 50.1943 12.5 49.5095V33.0737Z"
        fill="url(#paint2_linear_4209_75672)"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M12.5 32.8755L30.375 50.7505H13.74C13.0552 50.7505 12.5 50.1953 12.5 49.5105V32.8755Z"
        fill="#6FE7E2"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4209_75672"
        x1="38.4526"
        y1="15"
        x2="31.0124"
        y2="77.6921"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6BEEFF" />
        <stop offset="1" stopColor="#0DDFD3" stopOpacity="0.760417" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4209_75672"
        x1="40"
        y1="53.5005"
        x2="40"
        y2="70.0005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#006BA8" />
        <stop offset="1" stopColor="#00D4B3" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4209_75672"
        x1="15.8833"
        y1="49.1626"
        x2="46.9579"
        y2="7.79901"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0A617" />
        <stop offset="1" stopColor="#FEE18F" />
      </linearGradient>
    </defs>
  </svg>
);

import { FormikTextInput } from "components/common/form/fields";
import { nameFields } from "./fields";
import { FormContentItem, FormContentNameFieldsWrapper } from "./styles";

const NameFieldsUI = () => (
  <FormContentNameFieldsWrapper mt={40}>
    <FormContentItem w50 mr={12}>
      <FormikTextInput
        {...{
          label: nameFields[0].label,
          placeholder: nameFields[0].placeholder,
          name: nameFields[0].name,
        }}
      />
    </FormContentItem>
    <FormContentItem w50 ml={12}>
      <FormikTextInput
        {...{
          label: nameFields[1].label,
          placeholder: nameFields[1].placeholder,
          name: nameFields[1].name,
        }}
      />
    </FormContentItem>
  </FormContentNameFieldsWrapper>
);

export default NameFieldsUI;

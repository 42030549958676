import { Div, DivCSSProps, Span } from "fuse-shared-ui";
import React from "react";
import styled from "styled-components";

export const Container = styled(Div)`
  display: flex;
  flex-direction: column;
`;

type BaseButtonProps = DivCSSProps & {
  isDisabled?: boolean;
  icon: React.ReactNode;
  text: string;
};

export const BaseButton = ({
  onClick,
  disabledWhen,
  icon,
  text,
  isDisabled,
  ...props
}: BaseButtonProps) => {
  return (
    <Div dflex {...props}>
      <Div bold clickable onClick={onClick} centered disabledWhen={isDisabled}>
        {icon} <Span ml={9}>{text}</Span>
      </Div>
    </Div>
  );
};

export const StyledBaseButton = styled(BaseButton)`
  ${(props) =>
    props.isDisabled &&
    `
      div {
        opacity: 0.4 !important;
        cursor: not-allowed !important;
      }
    `}
`;

type ButtonProps = {
  isDisabled?: boolean;
} & DivCSSProps;

export const AddValidationButton = (props: ButtonProps) => {
  return (
    <StyledBaseButton
      data-cy="addAnotherValidation"
      icon={AddIcon}
      text={"Add another validation"}
      {...props}
    />
  );
};

export const DeleteValidationButton = (props: ButtonProps) => {
  return (
    <StyledBaseButton
      data-cy="delete-validation"
      icon={RemoveIcon}
      text={"Delete validation"}
      {...props}
    />
  );
};

export const Divider = () => <Div mt={32} mb={32} divider />;

const AddIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 1V13"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 7L13 7"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RemoveIcon = (
  <svg
    width="14"
    height="2"
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L13 1"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

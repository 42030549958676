import styled from "styled-components";
import { Div } from "../../../styled/utils";
import { TableActionsContainerProps } from "./TableActions";

export const TableActionsContainer = styled(Div)<TableActionsContainerProps>`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.gray300};
  display: flex;
  justify-content: space-between;
  padding: 8px 32px;
  width: 100%;
`;

export const ButtonActionsContainer = styled(Div)`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const TableActionsContent = styled(Div)`
  align-items: center;
  display: flex;
  gap: 16px;
`;

export const TableActionsInformations = styled(Div)`
  align-items: center;
  display: flex;
  gap: 16px;
  position: relative;
`;

export const ActionButton = styled.button`
  align-items: center;
  background: transparent;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  padding: 0px;
  width: 36px;

  :disabled {
    opacity: 0.6;

    :hover {
      background-color: transparent !important;
      cursor: not-allowed;
    }
  }
`;
